

//
// GLOBAL
$invisible: rgba(0,0,0,0);
$tempr: rgba(255,0,0,0.25);
$tempb: rgba(0,0,255,0.25);
$tempg: rgba(0,255,0,0.25);

// PART REFACTOR INTO GREY SCALE
$white: rgb(255,255,255); // I think e.g. for dark buttons we want to keep them white
$black: rgb(0,0,0);

@import 'Colors-PrimaryColor';
@import 'Colors-SpecialColors'; // red, green, etc.
@import 'Colors-BG';
@import 'Colors-Text';
@import 'Colors-Lines';
@import 'Colors-Grays';
@import 'Colors-AccentColors';

@import 'Colors-TextSelections';
@import 'Colors-TeamPanel';
@import 'Colors-OpenDocMenu';

@import 'Colors-RichText'; // code, strikethrough, gray text

@import 'Colors-Messages';

@import 'Colors-TextSelections';
@import 'Colors-Dropdowns';

@import 'Colors-Whiteboards';
@import 'Colors-Onboarding';

//
// CUSTOM
// maybe refactor some of these for consistency later
@import 'Colors-LibraryViews'; 
@import 'Colors-TabBar'; 
@import 'Colors-Tooltips';
@import 'Colors-FloatingMenus'; // text-selection, over doc objects
@import 'Colors-LibrarySideNav'; 
@import 'Colors-SideMenu'; 

@import 'Colors-MeetingTypes';

@import 'Colors-LinearYarnMap'; 
@import 'Colors-LinearStatus'; // for project and todo bits

@import 'Colors-Custom'; // for components without their own definitions file

//
// APP BG COLORING
// we put the app background color on a separate div instead of on the app div itself to prevent issues with backdrops from radix

.app-bg{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;

  //transition: background 50ms linear;
}


.app-bg{
  background: var(--appBG-sideMenuMode);
}

.app--sideMenuHidden .app-bg{
  background: var(--appBG-sideMenuHidden);
}




















// VLIGHT COLORS OPAUE
$blue-extraLight--opaque: #EDF5FE;

// GRAYS
$gray-800: rgb(236,239,243);
$gray-900: rgb(252,252,252); // lightest gray



//


//

$lightGray-bg: rgb(250,248,248);

//





///
//// REFACTOR OUT
$accentColor-purple: 	rgb(181,120,221);










// OLD
// $accentColor-blue:  	rgb(54,153,249);
// $accentColor-red:     rgb(243,127,130);
// $accentColor-orange: 	rgb(255,170,112);
// $accentColor-green: 	rgb(49,201,157);
// $accentColor-sky:   	rgb(88,199,249);
// $accentColor-pink:    rgb(247,145,201);
// $accentColor-teal:    rgb(0,191,205);
// $accentColor-grey:    rgb(128,129,142);
// $darkPurple:            rgb(40,38,54);
//$blue: rgb(0,114,255);




// OTHER BLUES
// background: darken($blue, 4%); // hover
// background: transparentize($blue, 0.88);
// background: transparentize($blue, 0.85);
// background: transparentize($blue, 0.9);
// background: transparentize($blue, 0.9);
// background: transparentize($blue, 0.95);
// background: transparentize($blue, 0.1); 
// background: transparentize($blue, 0.9);
// 	color: darken($blue, 5%);
// 	color: darken($blue, 10%);
// 	color: darken($blue, 15%);


// background: transparentize($blue, 0.5); // divider

// RADIO CHECKED
// BG $blue
// BG darken($blue, 5%)

//
// DEFAULT BLUE BUTTON

	// DEFAULT
		// BORDER - darken($blue, 5%)
		// BG - $blue

	// HOVER
		// border: 1px solid darken($blue, 10%);
		// background: saturate($blue, 5%);

	// ACTIVE
		// border: 1px solid darken($blue, 15%);
		// background: darken($blue, 10%);

//
// EXTRA LIGHT BLUE BUTTON
// background: transparentize($blue, 0.8); // active
// background: transparentize($blue, 0.85); // hover
// background: transparentize($blue, 0.95); // default

// fill: darken($blue, 10%); hover SVG
// fill: darken($blue, 12%); active SVG

//
// REACTIONS
// work across comments - inline reaction
// image - reactionBar
// doc - inline reactionbar
// later reaction hover paragraph

