
//
// SECTION HEADERS

.projectsPage-sectionHeader{
	display: flex;
	align-items: flex-end;
	position: relative;

	margin-top: 34px;
	

	box-sizing: border-box;
	height: 56px;
	
	position: sticky;
	top: 0px;


	margin-bottom: 8px;

	user-select: none;
	cursor: default;

	background: var(--bg-docEditor);
}

.projectsPage-sectionHeader--status--started{
	z-index: 6;
	margin-top: 24px;
}

.projectsPage-sectionHeader--status--planned{
	z-index: 7;
}

.projectsPage-sectionHeader--status--backlog{
	z-index: 8;
}

.projectsPage-sectionHeader--status--completed{
	z-index: 9;
}

.projectsPage-sectionHeader-leftContainer{
	display: flex;
	height: 32px;
	margin-bottom: 12px;
	
	align-items: center;
	position: relative;
	z-index: 10; // above gradient
}

.projectsPage-sectionHeader-statusMarkerContainer{
	width: 18px;
	height: 18px;

	position: relative;
	top: 1px;

	margin-right: 19.5px;
	margin-left: 6.5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.projectsPage-sectionHeader-statusMarkerContainer
.linearProjectStatusIcon{
	width: 20px;
	height: 20px;
	border-width: 2px;
}

.projectsPage-sectionHeader-statusMarkerContainer
.linearProjectStatusIcon
.linearProjectStatusIcon--started-dot{
	width: 8px;
	height: 8px;
}

.projectsPage-sectionHeader-statusMarkerContainer
.linearProjectStatusIcon--completed svg{
	width: 14px;
	height: 14px;
	stroke-width: 12px;
}


.projectsPage-sectionHeader-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: var(--text500);
}


[data-theme="dark"]{
	.projectsPage-sectionHeader-title{
		letter-spacing: 0.0045em;
	}
}



.projectsPage-sectionHeader-rightContainer{
	margin-left: auto;
	display: flex;
}

.projectsPage-sectionHeader-sortOrderContainer{
	display: flex;
	// background: $tempr;
	padding-bottom: 9px;
	margin-right: -3px;
}

.projectsPage-sectionHeader-sortOrderBtn{
	padding-left: 10px;
	padding-right: 10px;

	height: 30px;
	// background: $tempr;
	border-radius: 5px;

	display: flex;

	opacity: 0.5;

	position: relative;
}

.projectsPage-sectionHeader-sortOrderBtn-label{
	font-size: 15px;
	// text-transform: uppercase;
	font-weight: $lato-semibold;
	// letter-spacing: 0.02em;
	color: var(--text500);

	margin-right: 7px;
	margin-top: -1px;
}


.projectsPage-sectionHeader-sortOrderBtn-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.8;

	position: relative;
	top: 1px;
}

.projectsPage-sectionHeader-sortOrderBtn:hover{
	opacity: 0.8;
	background: var(--253gray);
}

.projectsPage-sectionHeader-sortOrderBtn[data-state='open'],
.projectsPage-sectionHeader-sortOrderBtn[data-state='open']:hover{
	opacity: 1;
	background: var(--253gray);
}

.projectsPage-sectionHeader-sortOrderBtn-tooltip{
	position: absolute;
	right: 100%;

	font-size: 15px;
	width: 200px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: var(--text500);

	margin-top: -1px;
	margin-right: 4px;
}

.projectsPage-sectionHeader-sortOrderBtn-tooltip{
	opacity: 0;
	transition: all 100ms;
	transform: translateX(0px);
}

.projectsPage-sectionHeader-sortOrderBtn:hover .projectsPage-sectionHeader-sortOrderBtn-tooltip{
	opacity: 0.6;
	transform: translateX(-4px);
	transition: all 100ms;
	transition-delay: 400ms;
}

.projectsPage-sectionHeader-sortOrderBtn[data-state='open'] .projectsPage-sectionHeader-sortOrderBtn-tooltip,
.projectsPage-sectionHeader-sortOrderBtn[data-state='open']:hover .projectsPage-sectionHeader-sortOrderBtn-tooltip{
	opacity: 0;
	transition: all 50ms;
	transition-delay: 0ms;
}



// .projectsPage-sectionHeader-actionBtn{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 32px;
// 	height: 32px;

// }

// .projectsPage-sectionHeader-actionBtn svg{
// 	height: 20px;
// 	width: 20px;
// 	fill: var(--text600);
// 	opacity: 0.25;
// }

// .projectsPage-sectionHeader-actionBtn:hover svg{
// 	opacity: 0.75;
// }


.projectsPage-sectionHeader-bottomBorder{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 1px;
	background: var(--line300);
	opacity: 0.8;
}

// .projectsPage-sectionHeader-gradientTop{
// 	position: absolute;
// 	top: -36px;
// 	bottom: 24px;
// 	left: 0px;
// 	right: 0px;	

// 	background: linear-gradient(
// 		rgba(0,0,0)
// 	);
	
// 	// background: linear-gradient(
//   //   to bottom,
//   //   hsla(0, 0%, 100%, 0) 0%,
//   //   hsla(0, 0%, 100%, 0.013) 8.1%,
//   //   hsla(0, 0%, 100%, 0.049) 15.5%,
//   //   hsla(0, 0%, 100%, 0.104) 22.5%,
//   //   hsla(0, 0%, 100%, 0.175) 29%,
//   //   hsla(0, 0%, 100%, 0.259) 35.3%,
//   //   hsla(0, 0%, 100%, 0.352) 41.2%,
//   //   hsla(0, 0%, 100%, 0.45) 47.1%,
//   //   hsla(0, 0%, 100%, 0.55) 52.9%,
//   //   hsla(0, 0%, 100%, 0.648) 58.8%,
//   //   hsla(0, 0%, 100%, 0.741) 64.7%,
//   //   hsla(0, 0%, 100%, 0.825) 71%,
//   //   hsla(0, 0%, 100%, 0.896) 77.5%,
//   //   hsla(0, 0%, 100%, 0.951) 84.5%,
//   //   hsla(0, 0%, 100%, 0.987) 91.9%,
//   //   hsl(0, 0%, 100%) 100%
//   // );	
// }

// .projectsPage-sectionHeader-fullBlockTop{
// 	position: absolute;
// 	bottom: 0px;
// 	height: 24px;
// 	left: 0px;
// 	right: 0px;
// 	background: hsl(0, 0%, 100%);
// }