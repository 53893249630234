
//
// DOC IMAGE

$innerMarginForUI-docMedia: 6px;

.doc-imageWrapper{
	// nothing in here at the moment		
}


.doc-image--selectionBG{
	position: absolute;
	z-index: 0;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;

	border-radius: 7px;

	pointer-events: none;
	background: var(--mediaNodeSelectedBG);

	opacity: 0;
}

.doc-imageWrapper--selected .doc-image--selectionBG{
	opacity: 0.5;
}



.doc-imageContainer{
	user-select: none;
	margin-left: calc((#{$textWidth-doc} - #{$fullWidth-doc}) / 2);  // not sure this is right
	

	margin-top: 16px;
	margin-bottom: 16px;

	position: relative;
	z-index: 5;
}

.doc-imageContainer--inline{
	cursor: pointer;
}

.doc-image{	
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
}


img.doc-image-image{	
  width: 100%;
  // visibility: hidden;
  border-radius: 6px;

  position: relative;
  z-index: 5
}

.doc-image-underlayBG{
	position: absolute;
	z-index: 1;

	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	border-radius: 6px;
	background: var(--250gray);

}

.doc-image-overlayBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border: 1px solid rgba(0,0,0,0.1);

	border-radius: 6px;

	pointer-events: none;

	display: none; // not sure about it

	z-index: 10;
}

@import 'Doc-Image-ReactionBar';

// WHEN IN DRAFT STATE, ONLY SHOW ELEMENTS ON HOVER
// ALSO PUT REACTIONS INSIDE IMAGE
// ALSO SHOW COMMENT MENU IN FEEDBACK MODE ON HOVER TOO

.doc-imageContainer--defaultMode
.doc-image-reactionBarContainer{
	position: absolute;
	bottom: $innerMarginForUI-docMedia;
}

.doc-imageContainer .doc-media-topRightMenu--hover{
	opacity: 0;	
	transition: opacity 100ms linear;
}

.doc-imageContainer .doc-media-topRightMenu--static{
	opacity: 1;	
	transition: opacity 100ms linear;
}

.doc-imageContainer.doc-imageContainer--mouseMoved:hover .doc-media-topRightMenu--hover,
.doc-imageContainer .doc-media-topRightMenu--hover:hover{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 200ms;
}

.doc-imageContainer.doc-imageContainer--mouseMoved:hover .doc-media-topRightMenu--static,
.doc-imageContainer .doc-media-topRightMenu--static:hover{
	opacity: 0;
	transition: opacity 50ms linear;
	transition-delay: 200ms;
}




//
//
// ZOOMED IMAGE MODAL
// some hacky stuff for drawing modal

@import 'Doc-Image-ZoomedImageModal';


// 
//
// PLACEHOLDER
//
// PLACEHOLDER

.doc-imageContainer--placeholder{
	height: 300px;
	width: 720px;
}

.doc-image--placeholder{
	//background: var(--primary50);
	background: var(--250gray);
	
	width: 100%;
	height: 100%;
	
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;
}

.doc-image--placeholder-imageIconContainer{
	height: 64px;
	width: 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: -32px;
	margin-bottom: 14px;
}

.doc-image--placeholder-imageLogo{

}

.doc-image--placeholder-imageLogo svg{
	width: 48px;
	height: 48px;
	fill: var(--primary400);
	fill: var(--125gray);
}


.doc-image--placeholder-loadingBar{
	width: 300px;
	height: 6px;

	border-radius: 10px;

	position: relative;

	background: $tempr;
	background: rgba(0,0,0,0.08);
	background: var(--bgSideMenuTabActive);
	background: var(--primary100);

	background: var(--246gray);
	// background: var(--250gray);
	
	//background: hsla(213, 100%, 50%,0.15);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.doc-image--placeholder-loadingBar-ball{
	width: 120px;
	height: 6px;
	border-radius: 4px;

	background: $tempr;
	background: var(--125gray);	
	//background: var(--primary400);
	//background: var(--primary500);
}

