span.doc-paragraph-selection--userColor--teal,
span.doc-paragraph-selection--userColor--teal *{
	// background: transparentize($accentColor-teal, 0.7);	
	background: var(--accentTeal100);
}

span.doc-paragraph-selection--userColor--blue,
span.doc-paragraph-selection--userColor--blue *{
	// background: transparentize($accentColor-blue, 0.7);
	background: var(--accentBlue100);
}

span.doc-paragraph-selection--userColor--purple,
span.doc-paragraph-selection--userColor--purple *{
	// background: transparentize($accentColor-purple, 0.7);
	background: var(--accentPurple100);
}

span.doc-paragraph-selection--userColor--red,
span.doc-paragraph-selection--userColor--red *{
	// background: transparentize($accentColor-red, 0.7);
	background: var(--accentRed100);
}

span.doc-paragraph-selection--userColor--orange,
span.doc-paragraph-selection--userColor--orange *{
	// background: transparentize($accentColor-orange, 0.7);
	background: var(--accentOrange100);
}

span.doc-paragraph-selection--userColor--green,
span.doc-paragraph-selection--userColor--green *{
	// background: transparentize($accentColor-green, 0.7);
	background: var(--accentGreen100);	
}

span.doc-paragraph-selection--userColor--sky,
span.doc-paragraph-selection--userColor--sky *{
	// background: transparentize($accentColor-sky, 0.7);	
	background: var(--accentSky100);
}

span.doc-paragraph-selection--userColor--pink,
span.doc-paragraph-selection--userColor--pink *{
	// background: transparentize($accentColor-pink, 0.7);
	background: var(--accentPink100);
}


//
// When overlaps with comment, then color the comment
span.doc-paragraph-selection--userColor--teal.comment {
	// border-bottom-color: $accentColor-teal;	
	// background: transparentize($accentColor-teal, 0.6);	
	border-bottom-color: var(--accentTeal500);
	background: var(--accentTeal150);
}

span.doc-paragraph-selection--userColor--blue.comment{
	// border-bottom-color: $accentColor-blue;
	// background: transparentize($accentColor-blue, 0.6);
	border-bottom-color: var(--accentBlue500);
	background: var(--accentBlue150);
}

span.doc-paragraph-selection--userColor--purple.comment{
	// border-bottom-color: $accentColor-purple;
	// background: transparentize($accentColor-purple, 0.6);
	border-bottom-color: var(--accentPurple500);
	background: var(--accentPurple150);
}

span.doc-paragraph-selection--userColor--red.comment{
	// border-bottom-color: $accentColor-red;
	// background: transparentize($accentColor-red, 0.6);
	border-bottom-color: var(--accentRed500);
	background: var(--accentRed150);
}

span.doc-paragraph-selection--userColor--orange.comment{
	// border-bottom-color: $accentColor-orange;
	// background: transparentize($accentColor-orange, 0.6);
	border-bottom-color: var(--accentOrange500);
	background: var(--accentOrange150);
}

span.doc-paragraph-selection--userColor--green.comment{
	// border-bottom-color: $accentColor-green;
	// background: transparentize($accentColor-green, 0.6);
	border-bottom-color: var(--accentGreen500);
	background: var(--accentGreen150);
}

span.doc-paragraph-selection--userColor--sky.comment{
	// border-bottom-color: $accentColor-sky;
	// background: transparentize($accentColor-sky, 0.6);
	border-bottom-color: var(--accentSky500);
	background: var(--accentSky150);
}

span.doc-paragraph-selection--userColor--pink.comment{
	// border-bottom-color: $accentColor-pink;
	// background: transparentize($accentColor-pink, 0.6);	
	border-bottom-color: var(--accentPink500);
	background: var(--accentPink150);
}


//
// When overlaps with comment, then color the comment
[data-theme="dark"] {

	span.doc-paragraph-selection--userColor--teal,
	span.doc-paragraph-selection--userColor--teal *{		
		background: var(--accentTealSelection);
	}

	span.doc-paragraph-selection--userColor--blue,
	span.doc-paragraph-selection--userColor--blue *{		
		background: var(--accentBlueSelection);
	}

	span.doc-paragraph-selection--userColor--purple,
	span.doc-paragraph-selection--userColor--purple *{		
		background: var(--accentPurpleSelection);
	}

	span.doc-paragraph-selection--userColor--red,
	span.doc-paragraph-selection--userColor--red *{		
		background: var(--accentRedSelection);
	}

	span.doc-paragraph-selection--userColor--orange,
	span.doc-paragraph-selection--userColor--orange *{		
		background: var(--accentOrangeSelection);
	}

	span.doc-paragraph-selection--userColor--green,
	span.doc-paragraph-selection--userColor--green *{		
		background: var(--accentGreenSelection);	
	}

	span.doc-paragraph-selection--userColor--sky,
	span.doc-paragraph-selection--userColor--sky *{		
		background: var(--accentSkySelection);
	}

	span.doc-paragraph-selection--userColor--pink,
	span.doc-paragraph-selection--userColor--pink *{		
		background: var(--accentPinkSelection);
	}

}

[data-theme="dark"] {

	span.doc-paragraph-selection--userColor--teal.comment{			
		border-bottom-color: var(--accentTeal400);
		background: var(--accentTealSelection);
	}

	span.doc-paragraph-selection--userColor--blue.comment{			
		border-bottom-color: var(--accentBlue400);
		background: var(--accentBlueSelection);
	}

	span.doc-paragraph-selection--userColor--purple.comment{			
		border-bottom-color: var(--accentPurple400);
		background: var(--accentPurpleSelection);
	}

	span.doc-paragraph-selection--userColor--red.comment{			
		border-bottom-color: var(--accentRed400);
		background: var(--accentRedSelection);
	}

	span.doc-paragraph-selection--userColor--orange.comment{			
		border-bottom-color: var(--accentOrange400);
		background: var(--accentOrangeSelection);
	}

	span.doc-paragraph-selection--userColor--green.comment{			
		border-bottom-color: var(--accentGreen400);
		background: var(--accentGreenSelection);
	}

	span.doc-paragraph-selection--userColor--sky.comment{			
		border-bottom-color: var(--accentSky400);
		background: var(--accentSkySelection);
	}

	span.doc-paragraph-selection--userColor--pink.comment{			
		border-bottom-color: var(--accentPink400);
		background: var(--accentPinkSelection);
	}
}
