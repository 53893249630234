

.meetingContentAddProjectPopoverContainer{	
	
}

.meetingContentAddProjectPopoverContainer:focus{
	outline: none;
}

.meetingContentAddProjectPopoverContainer--hidden,
.meetingContentAddProjectPopoverContainer--hidden *{
	pointer-events: none !important;
}


.meetingContentAddProjectPopover{
	width: 295px;
	width: 380px;
	width: 404px;
	
	// min-height: 440px;
	// min-height: 280px;
	// max-height: 380px;
	
	// overflow-y: scroll;

	// height: 400px;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 0px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;

	display: flex;
	flex-direction: column;
}

//

.meetingContentAddProjectPopover-searchContainer{
	width: calc(100% - 10px);
	margin-left: 5px;
	height: 28px;
	position: relative;

	flex-grow: 0;
	flex-shrink: 0;

	margin-bottom: 6px;
}

input.meetingContentAddProjectPopover-search{
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	padding-right: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	box-sizing: border-box;

	padding-left: 23px;

	position: absolute;
	border: none;
	border-radius: 4px;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text600);

	border: 1px solid $invisible;
	background: var(--250gray);
}

.meetingContentAddProjectPopover-search::placeholder{
	color: var(--toDoItem-placeholderColor);
}

.meetingContentAddProjectPopover-search:focus{
	background: var(--bg-docEditor);
	border: 1px solid var(--primary400);
	outline: 2px solid var(--primary25);
}

.meetingContentAddProjectPopover-searchBarUI-searchBarIcon{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	height: 100%;

	width: 25px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	box-sizing: border-box;
	padding-left: 2px;
}

.meetingContentAddProjectPopover-searchBarUI-searchBarIcon svg{
	width: 16px;
	height: 16px;
	fill: var(--toDoItem-placeholderColor);
	opacity: 0.8;
}

//
//
.meetingContentAddProjectPopover-scrollListContainer{
	width: 100%;
	min-height: 240px;
	max-height: 440px;
	flex-grow: 1;
	flex-shrink: 1;
}

.meetingContentAddProjectPopover-scrollList{
	width: 100%;
	// overflow-y: scroll;
	
	padding-bottom: 12px;

	display: flex;
	flex-direction: column;
}






// //


// .docEditor-meetingContent-projectList-addProject{
// 	width: 320px;
// 	height: 30px;

	
// 	position: relative;

// 	margin-top: 2px;
// 	margin-left: -8px;
// 	margin-bottom: -12px;

	
// }

// // .docEditor-meetingContent-projectList:hover .docEditor-meetingContent-projectList-addProject,
// // .docEditor-meetingContent-projectList-addProject.docEditor-meetingContent-projectList-addProject--focused{
// // 	opacity: 1;

// // 	transition: 100ms linear;
// // 	transition-delay: 50ms;
// // }

// .docEditor-meetingContent-projectList-addProject-input{
// 	width: 100%;
// 	height: 100%;
// 	position: relative;
// }

// input.docEditor-meetingContent-projectList-addProject-input-input{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	width: 100%;
// 	height: 100%;

// 	box-sizing: border-box;

// 	padding-top: 0px;
// 	padding-bottom: 0px;
// 	padding-left: 30px;

// 	font-size: 15.75px;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.001em;
// 	color: var(--text500);
// 	position: relative;

// 	border: 1px solid $invisible;	
// 	border-radius: 4px;

// 	cursor: pointer;
// }

// .docEditor-meetingContent-projectList-addProject-input-input:focus{
// 	cursor: text;

// 	outline: none;
// 	border: 1px solid var(--line300);
// 	background: var(--253gray);
// }

// .docEditor-meetingContent-projectList-addProject-input-ui{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	right: 0px;
// 	width: 100%;
// 	height: 100%;

// 	pointer-events: none;
// }

// .docEditor-meetingContent-projectList-addProject-input-ui-magnifyingGlass,
// .docEditor-meetingContent-projectList-addProject-input-ui-plus{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	width: 32px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .docEditor-meetingContent-projectList-addProject-input-ui-magnifyingGlass svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text500);

// 	opacity: 0.5;
// }

// .docEditor-meetingContent-projectList-addProject-input-ui-plus svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text500);

// 	opacity: 0.4;
// }

// .docEditor-meetingContent-projectList-addProject-input-ui-label{
// 	position: absolute;
// 	top: 0px;
// 	bottom: 0px;

// 	left: 30px;
	

// 	display: flex;
// 	align-items: center;

// 	font-size: 15.75px;
// 	font-weight: $lato-medium;
// 	letter-spacing: 0.001em;
// 	color: var(--text500);

// 	opacity: 0.5;
// }

// .docEditor-meetingContent-projectList-addProject-input:hover
// .docEditor-meetingContent-projectList-addProject-input-ui-label{
// 	opacity: 0.7;
// }

// .docEditor-meetingContent-projectList-addProject-input:hover
// .docEditor-meetingContent-projectList-addProject-input-ui-plus svg{
// 	opacity: 0.6;
// }

// // Hide and show UI bits

// .docEditor-meetingContent-projectList-addProject-input-input::placeholder{
// 	color: rgba(0,0,0,0);
// }

// .docEditor-meetingContent-projectList-addProject-input-input:focus::placeholder{
// 	color: var(--text300);
// }

// .docEditor-meetingContent-projectList-addProject-input-ui-magnifyingGlass{
// 	opacity: 0;
// }

// .docEditor-meetingContent-projectList-addProject-input-input:focus ~
// .docEditor-meetingContent-projectList-addProject-input-ui{

// 		.docEditor-meetingContent-projectList-addProject-input-ui-magnifyingGlass{
// 			opacity: 1;
// 		}
// 		.docEditor-meetingContent-projectList-addProject-input-ui-plus{
// 			opacity: 0 !important;
// 		}
// 		.docEditor-meetingContent-projectList-addProject-input-ui-label{
// 			opacity: 0 !important;
// 		}
// }


// //
// //

// .docEditor-meetingContent-projectList-addProject-resultsDropdownContainer{
// 	position: absolute;
// 	left: 0px;
// 	top: 100%;
// 	margin-top: 4px;

// }