$leftOverhang-meetingRow: 12px;
$rightOverhang-meetingRow: 10px;





//
// DOC ROW AND TITLE


.meetingNotesPage-meetingRow{
	width: calc(100% + #{$leftOverhang-meetingRow} + #{$rightOverhang-meetingRow});
	display: flex;
	align-items: center;

	margin-left: calc(-1 * #{$leftOverhang-meetingRow});

	height: 32px;
	border-radius: 4px;

	cursor: pointer;
	position: relative;


}


.meetingNotesPage-meetingRow:hover{
	background: var(--docTableItemHoverBG);
	z-index: 10;
}

.meetingNotesPage-meetingRow[data-state="open"]{
	background: var(--docTableItemContextOpenBG);
}

.meetingNotesPage-meetingRow--GCalEvent{
	// background: $tempb;
}

// .meetingNotesPage-meetingRow--GCalEvent
// .meetingNotesPage-meetingRow-title{
// 	color: var(--text300);
// }


// .meetingNotesPage-meetingRow--GCalEvent:hover
// .meetingNotesPage-meetingRow-title{
// 	opacity: 0.9;
// 	color: var(--text500);
// }



.meetingNotesPage-meetingRow-startSpacer{
	width: $leftOverhang-meetingRow;
	flex-grow: 0;
	flex-shrink: 0;
}

.meetingNotesPage-meetingRow-endSpacer{
	width: $rightOverhang-meetingRow;
	flex-grow: 0;
	flex-shrink: 0;
}

.meetingNotesPage-meetingRow-thumbnailPreviewContainer{
	height: 24px;
	aspect-ratio: 4/3;
	background: var(--246gray);
	margin-right: 8px;	
}

img.meetingNotesPage-meetingRow-thumbnailPreview{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 2px;
}

.meetingNotesPage-meetingRow-iconContainer{
	width: 30px;
	height: 32px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	margin-left: -8px;
}

.meetingNotesPage-meetingRow-iconContainer svg{
	width: 17px;
	height: 17px;	
}


.meetingNotesPage-meetingRow-GCalNew{
	width: 18px;
	height: 18px;
	background: var(--250gray);

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	flex-grow: 0;
	flex-shrink: 0;

	margin-left: -1px;
	margin-right: 5px;

	opacity: 0.75;
}

.meetingNotesPage-meetingRow-GCalNew svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.5;
}


.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-GCalNew{
	background: var(--240gray);	
	opacity: 1;
}

.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-GCalNew svg{
	opacity: 0.8;
}

//

.meetingNotesPage-meetingRow-recordingIcon{
	height: 100%;
	width: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 8px;
}

.meetingNotesPage-meetingRow-recordingIcon svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.6;
}

//

.meetingNotesPage-meetingRow-title{
	display: flex;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;

	font-size: 16.25px;
	font-weight: $lato-bold;
	color: var(--text500);

	letter-spacing: 0.001em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  //padding-right: 10px;

  position: relative;

}
//

.meetingNotesPage-meetingRow-timestampContainer{
	display: flex;
	justify-content: flex-start;

	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	margin-left: 6px;
}

.meetingNotesPage-meetingRow-timestamp{
	font-size: 15.75px;
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.5;
	text-align: left;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//

.meetingNotesPage-meetingRow-meetingTypeLabelContainer{
	width: 120px;
	display: flex;
	justify-content: flex-end;
}

.meetingNotesPage-meetingRow-meetingTypeLabel{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: var(--text300);
	background: var(--253gray);

	display: flex;
	align-items: center;
	justify-content: center;

	height: 24px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 3px;
}

.meetingNotesPage-upcoming-pane
.meetingNotesPage-meetingRow-meetingTypeLabelContainer{
	display: none;
}


.meetingNotesPage-meetingRow-participantsContainer{
	max-width: 70px;

	padding-left: 5px;

	flex-shrink: 1;
	display: flex;
	justify-content: flex-end;

}

.meetingNotesPage-meetingRow-participantsContainer
.meetingParticipantListBtn{
	height: 24px;
	padding-right: 4px;
	border: none;
	cursor: pointer;
}

.meetingNotesPage-meetingRow-participantsContainer
.meetingParticipantListBtn-count{
	font-size: 13.5px;
	display: none;
}

.meetingNotesPage-meetingRow:hover 
.meetingParticipantListBtn
.meetingParticipantListBtn-avatarContainer{
	outline: 2px solid var(--docTableItemHoverBG);
}

[data-theme="dark"]{
	.meetingNotesPage-meetingRow:hover 
	.meetingParticipantListBtn
	.meetingParticipantListBtn-avatarContainer{	
		outline: 2px solid #2E2C31;	 // hacky, need opaque
	}	
}



//

.meetingNotesPage-meetingRow-ignoreBtn{
	height: 32px;
	width: 32px;

	opacity: 0;
	transition: opacity 50ms;
}

.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-ignoreBtn{
	opacity: 0.8;
}

.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-ignoreBtn:hover{
	opacity: 1;
}

.meetingNotesPage-meetingRow-ignoreBtn-inner{
	width: 18px;
	height: 18px;
	border-radius: 50%;

	background: rgba(10,10,20,0.14);

	display: flex;
	align-items: center;
	justify-content: center;
}

.meetingNotesPage-meetingRow-ignoreBtn-inner-line{
	width: 10px;
	height: 2px;
	border-radius: 1px;
	background: var(--text500);
	opacity: 0.7;
}


//

.meetingNotesPage-meetingRow-lastUpdated{
	width: 120px;

	flex-grow: 0;
	flex-shrink: 0;

	font-size: 12px;
	font-weight: $lato-regular;
	letter-spacing: 0.04em;

	//color: rgb(125,124,124);
	color: var(--text200);

	box-sizing: border-box;
	padding-left: 10px;
	padding-top: 2px;

	text-align: right;



}


//

.meetingNotesPage-meetingRow-hideBtn{
	width: 60px;
	height: 26px;
	border-radius: 3px;

	background: var(--251gray);

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: var(--text500);
	

	margin-right: 8px;
}

.meetingNotesPage-meetingRow
.meetingNotesPage-meetingRow-hideBtn{
	opacity: 0.6;
}

.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-hideBtn{
	opacity: 0.8;	
	background: rgba(0,0,0,0.06);
}

.meetingNotesPage-meetingRow:hover
.meetingNotesPage-meetingRow-hideBtn:hover{
	opacity: 1;
	background: rgba(0,0,0,0.09);
}

// .meetingNotesPage-meetingRow-ignoreBtn-inner svg{
// 	width: 13px;
// 	height: 13px;
// 	fill: var(--text500);
// 	opacity: 0.5;

// 	position: relative;
// 	top: 1px;
// }




// .meetingNotesPage-meetingRow-participantsContainer
// .meetingParticipantList-avatarContainer{
// 	width: 18px;
// 	height: 18px;
// }

// .meetingNotesPage-meetingRow-participantsContainer
// .meetingParticipantList-avatar{
// 	width: 18px;
// 	height: 18px;
// }