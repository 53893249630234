// SPAN

.doc-span--searchResult,
.doc-span--searchResult--active{	
	border-radius: 1px;
}

.doc-span--style--light .doc-span--searchResult--active,
.doc-span--style--light .doc-span--searchResult{
	color: var(--text600);
}

.doc-span--searchResult{
	background: var(--searchResultBG);
	box-shadow: 0px 0px 0px 2px var(--searchResultBG);
	//box-shadow: 0px 0px 0px 2px ;
}




.doc-span--searchResult--active{
  animation: activeResultAnimation 220ms ease-in forwards;
}





//

.docEditor-inDocSearch{
	position: absolute;
	top: 6px;
	right: 6px;

	height: 32px;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $lato-semibold;

	background: var(--bg-dropdown);
	
	border-radius: 4px;
	
	border: 1px solid var(--outsideLineSearchMenu);
	border-top: 1px solid var(--outsideTopLineSearchMenu);
	border-bottom: 1px solid var(--outsideBottomLineSearchMenu);

	box-shadow: var(--boxShadowFloatingMenu);

	padding-right: 2px;

	-webkit-app-region: no-drag;

}


//

.docEditor-inDocSearch-inputContainer{
	position: relative;
	width: 240px;
	height: 100%;

	margin-right: 6px;

	display: flex;
}

.docEditor-inDocSearch-input{
	// position: absolute;
	// top: 0px;
	// left: 0px;
	// right: 0px;	
	// bottom: 0px;
	// height: 100%;
	// width: 100%;

	background: $tempg;
	height: 100%;
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text600);

	border: none;
	outline: none;
	background: none;

	box-sizing: border-box;
	// padding-right: 80px;
	padding-left: 12px;


}

input.docEditor-inDocSearch-input::selection{
	background: var(--searchResultBGPlaceholder) !important;
}


//

// .docEditor-inDocSearch-inputUI{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 74px;	
// 	bottom: 0px;
// 	height: 100%;
// 	width: 100%;

// 	pointer-events: none;
// 	z-index: 5;
// 	background: $tempr;
// }

.docEditor-inDocSearch-inputUI-resultsLabel{
	// position: absolute;
	// right: 4px;
	// top: 0px;
	// bottom: 0px;
	height: 100%;

	min-width: auto;
	white-space: nowrap;

	flex-grow: 0;
	flex-shrink: 0;

	letter-spacing: 0.008em;

	// background: $tempb; 
	padding-right: 4px;
	padding-left: 4px;

	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.4;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.docEditor-inDocSearch-inputUI-resultsLabel--noResults{
	opacity: 0.8;
}


//


.docEditor-inDocSearch-navigateBtn{
	width: 34px;
	height: 28px;
	border-radius: 4px;

	//background: var(--250gray);
	border: 1px solid var(--line200);
	opacity: 0.8;

	border-radius: 3px;
}

.docEditor-inDocSearch-navigateBtn:hover{
	background: var(--250gray);
	border: 1px solid var(--line300);
	opacity: 1;
}

.docEditor-inDocSearch-navigateBtn:active{
	background: var(--246gray);
	border: 1px solid var(--line300);
	opacity: 1;
}

.docEditor-inDocSearch-navigateBtn:disabled{
	opacity: 0.4;
	pointer-events: none;
}

.docEditor-inDocSearch-navigateBtn svg{
	width: 17px;
	height: 17px;

	opacity: 0.8;

	fill: var(--text600);
}

.docEditor-inDocSearch-navigateBtn:hover svg{
	opacity: 1;
}


.docEditor-inDocSearch-navigateBtn--up{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.docEditor-inDocSearch-navigateBtn--up svg{
	position: relative;
	
}

.docEditor-inDocSearch-navigateBtn--down svg{
	position: relative;
}

.docEditor-inDocSearch-navigateBtn--down{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-left: -1px;
	border-left: none !important;
}
//

.docEditor-inDocSearch-separator{
	width: 1px;
	height: 100%;
	background: var(--insideLineFloatingMenu);
	margin-right: 4px;
	margin-left: 3px;
}

//

.docEditor-inDocSearch-doneBtn{	
	background: var(--text400);
	border: 1px solid var(--text500);
	border-radius: 3px;

	margin-left: 4px;

	font-size: 14.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.02em;
	color: $white;

	width: 80px;
	height: 28px;
}

.docEditor-inDocSearch-doneBtn:hover{	
	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]
.docEditor-inDocSearch-doneBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.docEditor-inDocSearch-doneBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}