$recordingPlaybackModal-sideMargin: 20px;
$recordingPlaybackModal-topMargin: 30px;
$recordingPlaybackModal-bottomMargin: 20px;
//$headerHeight-recordingPlaybackModal: 40px;
$headerHeight-recordingPlaybackModal: 38px;

$highlightYellow: #F7CE46;

.recordingPlaybackModalContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

}

.recordingPlaybackModalContainer:focus{
	outline: none;
}

.recordingPlaybackModalContainer--hidden,
.recordingPlaybackModalContainer--hidden *{
	pointer-events: none !important;
}



//

.recordingPlaybackModal{	

	box-sizing: border-box;
	
	margin-top: 10px;
	width: calc(100vw - #{$recordingPlaybackModal-sideMargin} - #{$recordingPlaybackModal-sideMargin});
	height: calc(100vh - #{$recordingPlaybackModal-topMargin} - #{$recordingPlaybackModal-bottomMargin});
	//max-width: 2200px;
	max-width: 1700px;
	max-height: 1800px;
	background: rgb(0,0,0);

	border-radius: 4px;
	border-radius: 5px;
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 8px 16px 0px rgba(0,0,0,0.12),
	;

	position: relative;

	background: rgb(0,0,0);
	//background: rgb(255,255,255);

	display: flex;
	flex-direction: column;

	z-index: 5000;

	border-top: 1px solid rgba(255,255,255,0.12);
	border-bottom: 1px solid rgba(255,255,255,0.12);
	border-left: 1px solid rgba(255,255,255,0.12);
	border-right: 1px solid rgba(255,255,255,0.12);
}

[data-theme="dark"]{
	.recordingPlaybackModal{
		border-top: 1px solid rgba(255,255,255,0.12);
		border-bottom: 1px solid rgba(255,255,255,0.12);
		border-left: 1px solid rgba(255,255,255,0.12);
		border-right: 1px solid rgba(255,255,255,0.12);

	}	
}


.recordingPlaybackModalUnderlay{
	background: var(--bg-zoomedMediaModals);

	position: fixed;
	top: -100vh;
	left: -100vw;
	right: -100vw;
	bottom: -100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 2000;
}

.recordingPlaybackModalUnderlay--show{
	opacity: 1;
	transition: 250ms linear;
	transition-delay: 0ms;
}

.recordingPlaybackModalUnderlay--hide{
	opacity: 0;
	transition: 100ms linear;
}

.recordingPlaybackModal-splitPanelContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}



.recordingPlaybackModal-transcriptContainer{
	position: absolute;
	// top: $headerHeight-recordingPlaybackModal;
	// height: calc(100% - #{$headerHeight-recordingPlaybackModal});
	top: 0px;
	height: 100%;
	left: 0px;


	box-sizing: border-box;
	padding-right: 16px;
	padding-right: 4px;
	padding-top: 12px;
	padding-bottom: 12px;

	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 12px;
	padding-left: 32px;

	z-index: 100; // above video for highlight panel etc.

	// background: $tempg;
}


.recordingPlaybackModal-headerContainer{
	position: absolute;
	top: 0px;
	height: $headerHeight-recordingPlaybackModal;
	//left: 0px;
	right: 0px;
	width: 100%;
}

.recordingPlaybackModal-mediaContainer{
	position: absolute;
	top: $headerHeight-recordingPlaybackModal;
	height: calc(100% - #{$headerHeight-recordingPlaybackModal});
	right: 0px;
	//left: 0px;

	//background: $tempb;
}

.recordingPlaybackModal-splitPanelGutterInvisible{
	//background: $invisible;	
	z-index: 50;
	cursor: default !important;
	// background: $tempr;
}

.recordingPlaybackModal-splitPanelGutterInvisible-hoverCursor{
	position: absolute;
	top: 0px;
	right: 0px;
	height: 0px;
	width: 100%;		
}

.recordingPlaybackModal-splitPanelGutterInvisible:hover 
.recordingPlaybackModal-splitPanelGutterInvisible-hoverCursor{
	height: 100%;
	transition: height 0ms linear;
	transition-delay: 250ms;
	cursor: col-resize !important;
}



.recordingPlaybackModal-splitPanelGutterHandle{
	position: absolute;
	height: 100%;
	
	z-index: 20;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;	
}

.recordingPlaybackModal-splitPanelGutterHandle-handle{
	height: 300px;
	width: 8px; 
	border-radius: 2px;
	background: rgb(255,255,255);
	opacity: 0;
	transition: all 100ms linear;
	transition-delay: 50ms;

	pointer-events: none;
}

.recordingPlaybackModal-splitPanelGutterInvisible:hover ~ 
.recordingPlaybackModal-splitPanelGutterHandle
.recordingPlaybackModal-splitPanelGutterHandle-handle{
	opacity: 0.2;
}

.recordingPlaybackModalContainer--isResizeDragging
.recordingPlaybackModal-splitPanelGutterHandle
.recordingPlaybackModal-splitPanelGutterHandle-handle,
.recordingPlaybackModalContainer--isResizeDragging
.recordingPlaybackModal-splitPanelGutterInvisible:hover ~ 
.recordingPlaybackModal-splitPanelGutterHandle
.recordingPlaybackModal-splitPanelGutterHandle-handle{
	opacity: 0.6;
	transform: scale(1.02);
	transition-delay: 0ms;
}

.recordingPlaybackModal-splitPanelGutterInvisible-handle{
	cursor: default !important;
}

.recordingPlaybackModalContainer--isResizeDragging,
.recordingPlaybackModalContainer--isResizeDragging .recordingPlaybackModal-splitPanelGutterInvisible,
.recordingPlaybackModalContainer--isResizeDragging .recordingPlaybackModal-splitPanelGutterInvisible-handle{
	cursor: col-resize !important;
}


.recordingPlaybackModal-splitPanelMeasurer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

@import 'RecordingPlaybackModal-Header';
@import 'RecordingPlaybackModal-Media';
@import 'RecordingPlaybackModal-Transcript';
@import 'RecordingPlaybackModal-IdentifySpeakerPanel';