.playground-linearYarnPaletteMap{
	width: 80vw;

	display: flex;
	flex-wrap: wrap;
}

.playgroundPage-linearPalette{
	margin: 20px;
}

.playgroundPage-linearPalette-title{
	width: 100%;
	text-align: center;
	margin-bottom: 4px;
}
.playgroundPage-linearPalette-swatchesContainer{
	display: flex;
	align-items: center;
}

.playgroundPage-linearPalette-swatch{
	width: 60px;
	height: 40px;

	background: blue;

	margin: 5px;
}



.playgroundPage-linearPalette--color--grey
.playgroundPage-linearPalette-swatch--linear{
	background: var(--grey--linear);
}

.playgroundPage-linearPalette--color--grey
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--grey--yarn);
}


.playgroundPage-linearPalette--color--lightGrey
.playgroundPage-linearPalette-swatch--linear{
	background: var(--lightGrey--linear);
}

.playgroundPage-linearPalette--color--lightGrey
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--lightGrey--yarn);
}


.playgroundPage-linearPalette--color--darkGrey
.playgroundPage-linearPalette-swatch--linear{
	background: var(--darkGrey--linear);
}

.playgroundPage-linearPalette--color--darkGrey
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--darkGrey--yarn);
}


.playgroundPage-linearPalette--color--purple
.playgroundPage-linearPalette-swatch--linear{
	background: var(--purple--linear);
}

.playgroundPage-linearPalette--color--purple
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--purple--yarn);
}


.playgroundPage-linearPalette--color--teal
.playgroundPage-linearPalette-swatch--linear{
	background: var(--teal--linear);
}

.playgroundPage-linearPalette--color--teal
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--teal--yarn);
}

.playgroundPage-linearPalette--color--darkTeal
.playgroundPage-linearPalette-swatch--linear{
	background: var(--darkTeal--linear);
}

.playgroundPage-linearPalette--color--darkTeal
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--darkTeal--yarn);
}


.playgroundPage-linearPalette--color--green
.playgroundPage-linearPalette-swatch--linear{
	background: var(--green--linear);
}

.playgroundPage-linearPalette--color--green
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--green--yarn);
}

.playgroundPage-linearPalette--color--darkGreen
.playgroundPage-linearPalette-swatch--linear{
	background: var(--darkGreen--linear);
}

.playgroundPage-linearPalette--color--darkGreen
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--darkGreen--yarn);
}

.playgroundPage-linearPalette--color--yellow
.playgroundPage-linearPalette-swatch--linear{
	background: var(--yellow--linear);
}

.playgroundPage-linearPalette--color--yellow
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--yellow--yarn);
}

.playgroundPage-linearPalette--color--brown
.playgroundPage-linearPalette-swatch--linear{
	background: var(--brown--linear);
}

.playgroundPage-linearPalette--color--brown
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--brown--yarn);
}


.playgroundPage-linearPalette--color--orange
.playgroundPage-linearPalette-swatch--linear{
	background: var(--orange--linear);
}

.playgroundPage-linearPalette--color--orange
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--orange--yarn);
}

.playgroundPage-linearPalette--color--darkOrange
.playgroundPage-linearPalette-swatch--linear{
	background: var(--darkOrange--linear);
}

.playgroundPage-linearPalette--color--darkOrange
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--darkOrange--yarn);
}


.playgroundPage-linearPalette--color--pink
.playgroundPage-linearPalette-swatch--linear{
	background: var(--pink--linear);
}

.playgroundPage-linearPalette--color--pink
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--pink--yarn);
}



.playgroundPage-linearPalette--color--red
.playgroundPage-linearPalette-swatch--linear{
	background: var(--red--linear);
}

.playgroundPage-linearPalette--color--red
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--red--yarn);
}



.playgroundPage-linearPalette--color--darkRed
.playgroundPage-linearPalette-swatch--linear{
	background: var(--darkRed--linear);
}

.playgroundPage-linearPalette--color--darkRed
.playgroundPage-linearPalette-swatch--yarn{
	background: var(--darkRed--yarn);
}

