.doc-para-dateContainer{
	
	white-space: pre-wrap;
	word-break: break-word;

	cursor: pointer;

	position: relative;

	margin-left: 0.1em;
}


.doc-para-date{
	border-radius: 1px;
	-webkit-appearance: unset;
}

.doc-para-date:hover{
	transition: all 0ms linear;
	transition-delay: 50ms;
}

.doc-para-date[data-state="open"]{
	pointer-events: none;
}

.doc-para-dateContainer.ProseMirror-nodeInSelection .doc-para-date,
.doc-para-dateContainer.ProseMirror-nodeInSelection .doc-para-date:hover{
	background: var(--textSelectionBlue);
	box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);
}	


.doc-para-date:hover,
.doc-para-dateContainer.ProseMirror-selectednode .doc-para-date,
.doc-para-dateContainer.ProseMirror-selectednode .doc-para-date:hover,
.doc-para-dateContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-para-date,
.doc-para-dateContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-para-date:hover,{
	background: var(--250grayTransparent);
	box-shadow: 0px 0px 0px 3px var(--250grayTransparent);
	border-radius: 1px;
}



.doc-para-dateContainer.ProseMirror-nodeInSelection .doc-para-date{
	border-radius: 0px;
}

[data-theme="dark"]{
	.doc-para-date:hover,
	.doc-para-dateContainer.ProseMirror-nodeInSelection.ProseMirror-selectednode .doc-para-date{
		background: var(--246gray);
		box-shadow: 0px 0px 0px 3px var(--246gray);
	}
}

.doc-para-date:active{
	background: var(--246gray);
}

.doc-para-date-spacer{
	display: inline-block;
	white-space: nowrap;
	width: 0.01em;
}

.doc-para-date-spacer::before{
	content: "\200B";
}


.doc-para-date-iconContainer{
	white-space: nowrap;

}

.doc-para-date svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.5;	

	position: relative;
	top: 0.19em;
	//vertical-align: -0.12em;

}


//

.doc-para-date-label{
	// white-space: pre-wrap; 
	// word-break: break-word;


	margin-right: 0.2em;
	margin-left: 0.18em;
	
	font-size: 16.5px;	

	font-weight: $lato-regular;
	letter-spacing: 0.005em;

	color: var(--text500);
	
	line-height: 1.45;

	opacity: 0.65;
}

.doc-para-date:hover,
.doc-para-dateContainer.ProseMirror-selectednode .doc-para-date,
.doc-para-dateContainer.ProseMirror-selectednode .doc-para-date:hover,
.doc-para-dateContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-para-date,
.doc-para-dateContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-para-date:hover{

	svg{
		opacity: 0.8;
	}	

	.doc-para-date-label{
		opacity: 0.95;
	}
}

.doc-para-date:hover svg,
.doc-para-date:hover .doc-para-date-label{
	transition: all 0ms linear;
	transition-delay: 50ms;
}


// HEADER STYLING
.doc-h1 .doc-para-date-label{		
	
	font-weight: $lato-heavy;
	
	font-size: 28.75px;		
	line-height: 1.2;
}


.doc-h1 .doc-para-date svg{
	width: 30px;
	height: 30px;

	top: 0.16em;
}



.doc-h2 .doc-para-date-label{			
	font-size: 21px;
	font-weight: $lato-heavy;
}


.doc-h2 .doc-para-date svg{
	width: 24px;
	height: 24px;
	
	top: 0.18em;
}

// span.doc-span--style--light
// .doc-para-date-title{
// 	color: var(--text200);	
// }

// span.doc-span--style--light .doc-para-date:hover .doc-para-date-title,
// span.doc-span--style--light .doc-para-dateContainer.ProseMirror-selectednode .doc-para-date-title{
// 	color: var(--text500); // turn back normal on hover
// }


// .doc-para-date:hover  .doc-para-date-title,
// .doc-para-dateContainer.ProseMirror-selectednode .doc-para-date .doc-para-date-title,
// .doc-para-dateContainer.ProseMirror-selectednode .doc-para-date:hover .doc-para-date-title{
// 	border-bottom: 1px solid var(--210gray); 	
// }

//
// POPOVER



.docDatePopover{
	transform-origin: top center;
	// width: 400px;
	// height: 400px;
}


