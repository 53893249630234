//
// ICONS AND EMOJI

$width-editProjectIcon-iconBtn: 32px;
$iconsPerRow-editProjectIcon-iconBtn: 11;

$sidePadding-editProjectIconPopover: 10px;

$projectIconPopoverCalculatedWidth: calc((#{$width-editProjectIcon-iconBtn} * #{$iconsPerRow-editProjectIcon-iconBtn}) + #{$sidePadding-editProjectIconPopover} + #{$sidePadding-editProjectIconPopover});

.editMetadataPopover--type--projectIcon{
	width: auto;
	padding-bottom: 0px;
}

.editMetadataPopover--type--projectIcon-content{
	width: 100%;
	display: flex;
	flex-direction: column;
}


.editMetadataPopover--type--projectIcon-content-colorRowContainer,
.editMetadataPopover--type--projectIcon-content-emojiSearchBarContainer{
	width: $projectIconPopoverCalculatedWidth;
	height: 42px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 2px;
}



//
// Color Row and Search Bar

.editMetadataPopover--type--projectIcon-content-colorRow{
	display: flex;

	//align-items: flex-end;
	align-items: center;
	justify-content: center;

	flex-wrap: wrap;
}


.editMetadataPopover--type--projectIcon-content-colorBtn{
	width: 21px;
	height: 32px;
}

.editMetadataPopover--type--projectIcon-content-colorBtn
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	width: 18px;
	height: 18px;
	border-radius: 4px;
}

.editMetadataPopover--type--projectIcon-content-colorBtn
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	transform: scaleY(1);
	transform-origin: center center;
}

.editMetadataPopover--type--projectIcon-content-colorRow:hover
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	transition: transform 200ms ease-in-out;
}

.editMetadataPopover--type--projectIcon-content-colorRow:hover
.editMetadataPopover--type--projectIcon-content-colorBtn:hover
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	transition: transform 20ms ease-in-out;
	transform: scaleY(1.25);
}

.editMetadataPopover--type--projectIcon-content-colorRow:not(:hover)
.editMetadataPopover--type--projectIcon-content-colorBtn--active
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	transition: transform 50ms ease-in-out;
	transform: scaleY(1.25);
}



.editMetadataPopover--type--projectIcon-content-switchBtnContainer{
	flex-grow: 0;
	flex-shrink: 0;
	width: 24px;
	margin-left: 2px;
	margin-right: 0px;
}

.editMetadataPopover--type--projectIcon-content-colorRowContainer
.editMetadataPopover--type--projectIcon-content-switchBtnContainer{
	margin-right: -3px;
	margin-left: 6px;
}

.editMetadataPopover--type--projectIcon-content-switchBtn{
	width: 28px;
	height: 28px;
	border-radius: 4px;

	border: 1px solid var(--line200);
	//background: var(--253gray);

	//font-size: 16px;
}

.editMetadataPopover--type--projectIcon-content-switchBtn:hover{
	border: 1px solid var(--line400);
}

.editMetadataPopover--type--projectIcon-content-switchBtn svg{
	width: 16px;
	height: 16px;
}

.editMetadataPopover--type--projectIcon-content-switchBtn svg.icon--name--emoji{
	width: 18px;
	height: 18px;
	transform: scale(0.88);
	// position: relative;
	// left: 0.03em;
}

// .editMetadataPopover--type--projectIcon-content-switchBtn:not(:hover) svg.icon--name--emoji circle{
// 	fill: var(--235gray);
// }

.editMetadataPopover--type--projectIcon-content-switchBtn svg.icon--name--linearIcon{
	fill: var(--text500);
	opacity: 0.5;
	transform: scale(0.94);
	// position: relative;
	// left: -0.02em;
}

.editMetadataPopover--type--projectIcon-content-switchBtn:hover svg.icon--name--linearIcon{
	opacity: 0.8;
}

//

.editMetadataPopover--type--projectIcon-content-emojiSearchBarContainer{
	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;
}

.editMetadataPopover--type--projectIcon-content--emoji-searchBar{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 10px;

	margin-right: 3px;

	height: 34px;

	position: relative;
	
}

.editMetadataPopover--type--projectIcon-content--emoji-searchBar-input{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-left: 10px;

	font-size: 15.5px;
	font-weight: $lato-regular;
	border-radius: 4px;

	color: var(--text600);

	border: 1px solid $invisible;


	background: none;

	background: var(--250gray);
}

.editMetadataPopover--type--projectIcon-content--emoji-searchBar-input:focus{
	background: var(--bg-dropdown);
	outline: 2px solid var(--primary50);
	border: 1px solid var(--primary600);
}

[data-theme="dark"] .editMetadataPopover--type--projectIcon-content--emoji-searchBar-input:focus{
	outline: 0px solid var(--primary600);
}



//
// Grid

.editMetadataPopover--type--projectIcon-content-iconGrid,
.editMetadataPopover--type--projectIcon-content-emojiGrid{
	height: 400px;
	overflow-y: scroll;

	width: 100%;
	box-sizing: border-box;
	

	padding-bottom: 18px;
}

.editMetadataPopover--type--projectIcon-content-iconGrid{
	padding-left: $sidePadding-editProjectIconPopover;
	padding-right: $sidePadding-editProjectIconPopover;

	display: flex;
	flex-wrap: wrap;
	width: $projectIconPopoverCalculatedWidth;
}


.editMetadataPopover--type--projectIcon-content-emojiGrid-section-content{
	display: flex;
	flex-wrap: wrap;
	width: $projectIconPopoverCalculatedWidth;
	padding-left: $sidePadding-editProjectIconPopover;
	padding-right: $sidePadding-editProjectIconPopover;
	box-sizing: border-box;
}

//
// Btns

.editMetadataPopover--type--projectIcon-content-iconBtn,
.editMetadataPopover--type--projectIcon-content-emojiBtn{
	width: $width-editProjectIcon-iconBtn;
	height: $width-editProjectIcon-iconBtn;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	cursor: pointer;
}

.editMetadataPopover--type--projectIcon-content-emojiBtn{
	font-family: "Apple Color Emoji";	
	font-size: 22px;
}


.editMetadataPopover--type--projectIcon-content-iconBtn svg{
	width: 18px;
	height: 18px;
	transition: fill 200ms linear;
}

@for $row from 0 through 11 {
  @for $column from 0 through 10 {
    $index: ($row * 11) + $column + 1;
    $delay: ($row + $column) * 20ms;
    .editMetadataPopover--type--projectIcon-content-iconBtn:nth-child(#{$index}) svg {
      transition-delay: $delay;
    }
  }
}


.editMetadataPopover--type--projectIcon-content-iconBtn:hover,
.editMetadataPopover--type--projectIcon-content-emojiBtn:hover{
	background: var(--248gray);
}

.editMetadataPopover--type--projectIcon-content-iconBtn--active,
.editMetadataPopover--type--projectIcon-content-emojiBtn--active{
	background: var(--240gray);
}


//
// Emoji Section Headers

.editMetadataPopover--type--projectIcon-content-emojiGrid-section-header{
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;

	height: 34px;

	padding-top: 6px;
	padding-left: 14px;

	text-transform: capitalize;

	color: var(--text500);
	font-size: 13px;
	font-weight: $lato-bold;

	user-select: none;

	position: sticky;
	top: 0px;
	background: var(--bg-dropdown);
}


//
// Footer

.editMetadataPopover--type--projectIcon-content-footer{
	border-top: 1px solid var(--line200);
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	height: 48px;	

	width: $projectIconPopoverCalculatedWidth;
}

.editMetadataPopover--type--projectIcon-content-footer-hoverPreview{
	display: flex;
	align-items: center;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 4px;
}

.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon{
	width: 40px;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 24px;

	margin-right: 2px;

	flex-grow: 0;
	flex-shrink: 0;

	font-family: "Apple Color Emoji";	
}

.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon svg{
	width: 24px;
	height: 24px;
}

.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text600);
	opacity: 0.75;

	min-width: 10px;

	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.editMetadataPopover--type--projectIcon-content-footer-switchBtn{

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text600);

	
	
	height: 36px;
	border-radius: 4px;

	padding-right: 16px;
	padding-left: 16px;

	width: 100%;
	//margin-left: -3px;

	opacity: 0.6;

	//background: var(--250gray);
}

.editMetadataPopover--type--projectIcon-content-footer-switchBtn:hover{
	background: var(--250gray);
	opacity: 1;
}



@import 'EditMetadataPopover-ProjectIcon-IconColors';


