

//


.messageContainer{
	width: 100%;

	box-sizing: border-box;

	padding-left: 10px;
	padding-right: 14px;
	padding-top: 10px;
	padding-bottom: 8px;

	background: var(--bg-docEditor);

	position: relative;
}

.message{
	display: flex;
	position: relative;	
}


@keyframes highlightedMessageFlash { 
  0%, 100% { 
  	background: var(--bg-docEditor);
  } 
  20% {
  	background: var(--235gray);
 	}
}

.messageContainer--highlighted{
	animation: highlightedMessageFlash;
	animation-iteration-count: 1;
	animation-duration: 900ms;
	animation-fill-mode: both;
}

// .messageContainer--highlighted,
// .messageContainer--highlighted:hover{
// 	background: var(--highlightedMessageBG);
// }



.messageContainer:hover{	
	background: var(--messageHoverBG);
}


// TOP MESSAGE SHOULD HAVE TALLER TOP PADDING
.docEditor-messagePanel-scrollContents
.messageContainer:nth-last-child(2){
	padding-top: 19px;
}

//

.message-leftContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 9px;
	user-select: none;
}

.message-avatarContainer{
	width: 32px;
	height: 32px;
	border-radius: 4px;

	user-select: none !important;
}

img.message-avatar{
	width: 100%;
	height: 100%;

	border-radius: 4px;

	object-fit: cover;
}

//

.message-rightContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;
}

.message-header{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 1px;

	cursor: default;
	user-select: none;
}

.message-header-name{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-right: 6px;
}

.message-header-timestamp{
	font-size: 12px;
	font-weight: $lato-medium;
	color: var(--text200);
}


//
// Followon (when you chain messages)
.messageContainer.messageContainer--followOnMessage{
	padding-top: 4px;
	padding-bottom: 4px;
}

.messageContainer.messageContainer--followOnMessage .message-leftContainer{
	height: 0px;
}

.messageContainer.messageContainer--followOnMessage .message-avatarContainer{
	visibility: hidden;
}
.messageContainer.messageContainer--followOnMessage img.message-avatar{
	display: none;
}
.messageContainer.messageContainer--followOnMessage .message-header{
	display: none;
}








// // if one emoji, then boost size
// .message-content--singleEmojiSizeBoost p{
// 	font-size: 28px;
// 	margin-top: -4px;
// 	margin-bottom: -6px;
// }


@import 'Messages-Message-HoverMenu';
@import 'Messages-Message-ReactionsPanel';
@import 'Messages-Message-OpenThreadBtn';