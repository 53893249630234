$defaultHeight-messagePanel-inputBox: 190px;
$maxHeight-messagePanel-inputBox: 400px;
$defaultTopInternalPadding-messagePanel-inputBox: 10px;

@import 'Messages-MessageContent';

@import 'Messages-Message';

@import 'Messages-Panel';

@import 'Messages-InputBox'; 