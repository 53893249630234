$width-messageInsertMentionsMenu: 380px;


.messageInsertMentionsMenu{	
	width: 100%;
	
	box-sizing: border-box;
	
	padding-top: 6px;
	padding-bottom: 10px;
	
	

	background: var(--bg-dropdown);

	border-radius: 5px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;
	user-select: none;
}

// .insertMentionsMenu--nearBottom{
// 	background: red !important;
// }

// .insertMentionsMenu--nearTop{
// 	background: green !important;
// }


.messageInsertMentionsMenu:focus{
	outline: none;
}

.messageInsertMentionsMenu-segmentHeader{
	width: 100%;
	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 10px;
	margin-bottom: 6px;
	padding-left: 13px;

	box-sizing: border-box;
}
	
.messageInsertMentionsMenu-dividerContainer{
	width: 100%;
	height: 1px;
	margin-top: 8px;
	margin-bottom: 13px;

	box-sizing: border-box;
	padding-left: 13px;
	padding-right: 0px;
}

.messageInsertMentionsMenu-divider{
	background: var(--line400);
	opacity: 0.5;
	height: 100%;
	width: 100%;
}




//

@import 'MessageInsertMentionsMenu-Item';

//
