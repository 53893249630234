.recordingPlaybackModal-transcript{
	// height: calc(100% - #{$headerHeight-recordingPlaybackModal});
	// margin-top: $headerHeight-recordingPlaybackModal;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	overflow: hidden;

	//background: $tempr;
	

	
	//background: rgb(12,10,13);
	background: rgb(18,16,22);
	border: 1px solid rgba(255,255,255,0.03);
	//background: rgba(255,255,255,0.03);
	border-radius: 6px;

	// border: 1px solid rgba(255,255,255,0.03);
	// border-top: 1px solid rgba(255,255,255,0.05);
	//border-right: 1px solid rgb(255,255,255,0.09);
	// padding-right: 20px;

	

	

}


//
// highlight
.recordingPlaybackModal-transcript-newHighlightDialogContainer{

}


.recordingPlaybackModal-transcript-highlightModeTooltip{
	position: absolute;
	bottom: 26px;	
	height: 29px;

	cursor: default;
	user-select: none;

	border-radius: 3px;

	z-index: 50;

	left: 50%;
	transform: translateX(-50%);

	background: lighten(rgb(20,18,22), 3%);
	
	border: 1px solid rgba(255,255,255,0.18); 
	border-top: 1px solid rgba(255,255,255,0.20); 
	border-bottom: 1px solid rgba(255,255,255,0.11); 



	color: var(--text500);

	font-weight: $lato-medium;
	font-size: 15.5px;
	color: var(--text300);
	letter-spacing: 0.006em;


	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 24px;
	padding-right: 24px;
	border-radius: 3px;

	padding-bottom: 1px;

	// background: linear-gradient(darken($highlightYellow, 0%), darken($highlightYellow, 16%));

	will-change: transform;

}

.recordingPlaybackModal-transcript-highlightModeTooltip--hold{
	color: rgba(0,0,0,0.95);
	background: $highlightYellow;
	background: darken($highlightYellow, 16%);
}

.recordingPlaybackModal-transcript-highlightModeTooltip--drag{
	background: lighten(rgb(20,18,22), 8%);
	color: var(--text500);
}

// .recordingPlaybackModal-transcript-highlightModeTooltip--resetAutoscroll{
// 	bottom: unset;
// 	//top: 65px;
// 	top: 28px;
// 	transform: unset;
// 	left: unset;
// 	right: 10px;

// 	z-index: 50;
// 	margin-left: 80px;
// 	// background: lighten(rgb(20,18,22), 8%);
// 	// color: var(--text500);
// 	//color: rgba(0,0,0,0.95);
// 	// color: var(--text500);
// 	// background: var(--primary500);

// 	background: lighten(rgb(20,18,22), 8%);
// 	color: var(--text500);
// 	border: none !important;

// 	background: $tempr;

// 	background: none;

// 	cursor: pointer;
// 	pointer-events: auto;

// 	// transform: unset;
// 	// top: unset;
// 	// right: 10px;
// 	// r: 24px;
// 	// border-radius: 4px;
// 	// width: 30px;

// }

// .recordingPlaybackModal-transcript-highlightModeTooltip--resetAutoscroll:hover{
// 	background: lighten(rgb(20,18,22), 4%);
// 	color: var(--text600);
// }


.recordingPlaybackModal-transcript-highlightModeTooltip-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;

	margin-right: 6px;
	margin-left: -4px;
}

.recordingPlaybackModal-transcript-highlightModeTooltip-iconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.6;
}

.recordingPlaybackModal-transcript-highlightModeTooltip-label-icon {
	// padding-left: 6px;
	// padding-right: 6px;
	// padding-top: 3px;
	// padding-bottom: 3px;
	//background: lighten(rgb(20,18,22), 9%);
	//background: darken($highlightYellow, 22%);
	//background: darken($highlightYellow, 18%);
	// // background: rgba(0,0,0,0.2);
	// border-radius: 3px;
	// //color: var(--text600);
	// font-weight: $lato-semibold;

	// position: relative;
	// // top: 2px;
}

.recordingPlaybackModal-transcript-highlightModeTooltip-label-icon svg{
	fill: var(--text500);
	fill: black;
	height: 15px;
	width: 15px;

	position: relative;
	top: 2px;

}

.recordingPlaybackModal-transcript-bottomGradient{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;

	bottom: 18px;
	left: 32px;
	width: calc(100% - 32px - 4px);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	z-index: 10;
	//background: linear-gradient(rgba(12,10,13, 0) 0%, rgba(12,10,13, 0.66) 33%, rgba(12,10,13, 1) 100%);
	background: linear-gradient(rgba(0,0,0, 0) 0%, rgba(0,0,0, 0.66) 50%, rgba(0,0,0, 1) 100%);
	background: linear-gradient(rgba(18,16,22, 0) 0%, rgba(18,16,22, 0.50) 75%, rgba(18,16,22, 1) 100%);
	

	pointer-events: none;
	border-bottom-left-radius: 7px;

	display: none;
}



// .recordingPlaybackModal-transcript-newHighlightDialog{
// 	position: absolute;
// 	left: 100%;
// 	margin-left: 12px;

// 	top: 50%;
// 	transform: translateY(-50%);

// 	height: 300px;
// 	width: 300px;
// 	// background: $tempr;

// 	z-index: 100;

// 	background: rgb(12,10,13);	
// 	border-top: 1px solid var(--line300);
// 	border: 1px solid var(--line300);
// 	border-bottom: 1px solid var(--line300);
// 	//border-top: var(--outsideTopLine-dropdown-dropdown);
// 	// border-bottom: var(--outsideBottomLine-dropdown);

// 	border-radius: 6px;

// 	//box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.12);
// 	box-shadow: 0px 2px 8px 0px red;
// }


//

@import 'RecordingPlaybackModal-Transcript-Header';
//

.recordingPlaybackModal-transcript-contentsContainer{
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;	
	// background: $tempg;


	box-sizing: border-box;
	
	overflow: hidden;
	//padding-right: 18px;
}

.recordingPlaybackModal-transcript-contentsInnerContainer{
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	//padding-right: 17px;
	box-sizing: content-box;

	position: relative;
	padding-top: 12px;
	//scroll-behavior: smooth; // comment this for smooth scrolling
}

//

.recordingPlaybackModal-transcript-highlightPanel-cancelButton{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 5;
	
	cursor: pointer;
}

.recordingPlaybackModal-transcript-speakerChunk{
	width: 100%;

	width: calc(100% - 16px);
	margin-left: 8px;



	box-sizing: border-box;

	//padding-left: calc(10px + 41px); // 41 is for the avatar
	padding-left: calc(4px + 50px); // 41 is for the avatar
	//padding-left: calc(4px + 64px); // 41 is for the avatar
	//padding-left: calc(16px + 25px); // 41 is for the avatar
	padding-right: 14px;
	padding-right: 28px;
	//padding-right: 28px;  // for highlight gutter
	//padding-right: 64px;  // for highlight gutter
	padding-top: 10px;
	padding-bottom: 8px;

	padding-top: 12px;
	padding-bottom: 10px;

	border-top-right-radius: 2px;
	border-bottom-left-radius: 2px;

	border-radius: 4px;

	background: none;

	position: relative;

	cursor: pointer;
}

.recordingPlaybackModal-transcript-speakerChunk--active{
	background: rgba(255,255,255,0.06);
	background: $tempr;
}

// .recordingPlaybackModal-transcript-speakerChunk:hover{	
// 	background: var(--messageHoverBG);
// 	background: rgba(255,255,255,0.02);
// }


.recordingPlaybackModal-transcript-speakerChunk-avatarContainer{
	position: absolute;
	//left: 10px; // messy
	left: 4px; // messy
	//left: -2px; // messy
	left: 14px; // messy
	//left: 30px; // messy
	top: 10px;
	
}

.recordingPlaybackModal-transcript-speakerChunk-avatar{
	width: 30px;
	height: 30px;
	border-radius: 4px;
}

.recordingPlaybackModal-transcript-speakerChunk-header{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 3px;

	cursor: pointer;
	user-select: none;
}

.recordingPlaybackModal-transcript-speakerChunk-header-name{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);
	margin-right: 6px;
	opacity: 0.75;
}


.recordingPlaybackModal-transcript-speakerChunk-header-subtitle{
	font-size: 12px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.65;
}

.recordingPlaybackModal-transcript-speakerChunk:hover
.recordingPlaybackModal-transcript-speakerChunk-header-subtitle{
	opacity: 0.8;
}

.recordingPlaybackModal-transcript--jumpMode
.recordingPlaybackModal-transcript-speakerChunk
.message-para{
	cursor: pointer;
	user-select: none;
}


// .recordingPlaybackModal-transcript--jumpMode
// .message-para-transcriptWord{
// 	border-radius: 1px;
// 	background: rgb(12,10,13);
//    //outline: 2px solid var(--activeTranscriptWordBG)
// 	box-shadow: 0px 0px 0px 2px rgb(12,10,13);
// }

.recordingPlaybackModal-transcript--jumpMode
.message-para-transcriptWord:hover{
	border-radius: 1px;
	background: var(--hoverTranscriptWordBG);
   //outline: 2px solid var(--activeTranscriptWordBG)
	box-shadow: 0px 0px 0px 2px var(--hoverTranscriptWordBG);	
	color: var(--text500);
}



.recordingPlaybackModal-transcript--highlightMode
.message-para-transcriptWord{
	// border-radius: 1px;
	// background: rgb(12,10,13);
  //  //outline: 2px solid var(--activeTranscriptWordBG)
	// box-shadow: 0px 0px 0px 2px rgb(12,10,13);
}

.recordingPlaybackModal-transcript--highlightMode
.message-para-transcriptWord:hover{
	// border-radius: 1px;
	// background: var(--hoverTranscriptWordBG);
  //  //outline: 2px solid var(--activeTranscriptWordBG)
	// box-shadow: 0px 0px 0px 2px var(--hoverTranscriptWordBG);	
}




.recordingPlaybackModal-transcript-speakerChunk .message-para{
	// cursor: pointer;
	// cursor: text;
	//font-size: 15.75px;

	font-size: 17.75px;
	font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;
	line-height: 1.44;
	color: var(--text400);
	


  //cursor: url(../transcriptCursor.png) 16 16, auto;

}

.recordingPlaybackModal-transcript-newHighlightDialog-cancelButton{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	//background: $tempr;
	z-index: 20;
	cursor: pointer;
}

.recordingPlaybackModal-transcript--jumpMode .message-para{
	user-select: none;
}


// .recordingPlaybackModal-transcript-speakerChunk .message-para *::selection{
// 	//background-color: var(--textSelectionBlue);
// 	//background-color: var(--primary500) !important;	
// 	//background: var(--accentOrange700) !important;
// 	// background: hsla(24, 70%, 40%) !important;
// 	//background: rgba(255,217,89,0.3);
// 	background: $invisible  !important;
// }


// .recordingPlaybackModal-transcript--jumpMode
// .recordingPlaybackModal-transcript-speakerChunk .message-para *::selection{
// 	//background-color: var(--textSelectionBlue);
// 	//background-color: var(--primary500) !important;	
// 	//background: var(--accentOrange700) !important;
// 	// background: hsla(24, 70%, 40%) !important;
// 	background: $invisible;
// }



.doc-span--transcriptHighlight{
	background: rgba(255,217,89,0.6);
	box-shadow: 0px 0px 0px 2px rgba(255,217,89,0.6);
	//box-shadow: 0px 0px 0px 2px ;
}



// .recordingPlaybackModal-transcript-speakerChunk transcriptword:hover{
// 	background: $tempr;
// 	border-radius: 1px;
// 	background: rgb(66,62,70);
// 	box-shadow: 0px 0px 0px 2px rgb(66,62,70);
// }
// //


.recordingPlaybackModal-transcript-speakerChunk-appendHighlightCheckboxContainer{
	position: absolute;
	top: 4px;
	right: 4px;
}


.recordingPlaybackModal-transcript-speakerChunk-highlightButtonContainer{
	position: absolute;
	top: 8px;
	right: 8px;
}



.recordingPlaybackModal-transcript-speakerChunk-highlightButton{
	width: 32px;
	height: 30px;
	border-radius: 4px;

	opacity: 0.1;
	transition: opacity 50ms linear;
}


.recordingPlaybackModal-transcript-speakerChunk:hover
.recordingPlaybackModal-transcript-speakerChunk-highlightButton{
	opacity: 0.2;
}

.recordingPlaybackModal-transcript-speakerChunk:hover
.recordingPlaybackModal-transcript-speakerChunk-highlightButton:hover{
	background: var(--250gray);
	opacity: 1;
}

.recordingPlaybackModal-transcript-speakerChunk-highlightButton svg{
	width: 17px;
	height: 17px;
	fill: var(--text500);
}



// SPAN

.recordingPlaybackModal-transcript-speakerChunk-transcriptWord,
.recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active{	
	border-radius: 1px;
}

// .doc-span--style--light .doc-span--searchResult--active,
// .doc-span--style--light .doc-span--searchResult{
// 	color: var(--text600);
// }

.recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active{
	// background: var(--primary600);
	// box-shadow: 0px 0px 0px 2px var(--primary600);
	background: var(--253gray);
	//box-shadow: 0px 0px 0px 2px ;
}

.recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText{
	position: absolute;
	box-sizing: border-box;

	top: 0px;

	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 18px;
	padding-right: 10px;

	height: 100%;

	left: calc(100% - 36px);	
	width: 36px;	

	cursor: pointer;

	z-index: 20;

	transition: all 50ms linear;
}

.recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText-inner{
	width: 100%;
	height: 100%;

	background: var(--accentOrange500);
	border-radius: 3px;
}


.recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText{
	opacity: 0;
}

.recordingPlaybackModal-transcript-speakerChunk:hover
.recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText{
	opacity: 0.15;
}

.recordingPlaybackModal-transcript-speakerChunk:hover
.recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText:hover{
	opacity: 0.7;
}

//
// HIGHIGHTED

.doc-span--transcriptHighlight{	
	background: rgba(255,212,0,0.32);
	box-shadow: none;
	padding-bottom: 1px;
	// box-shadow: 0px 0px 0px 1px rgba(255,212,0,0.32);
	// border-radius: 1px;
	border-bottom: 1px solid rgba(255,212,0,0.9);
	//box-shadow: 0px 0px 0px 0.10em red;
	// background: var(--searchResultBG);
	// box-shadow: 0px 0px 0px 2px var(--searchResultBG);
	// //background: lighten(#FDE9AE, 10%); // similar to google docs
	// background: rgba(255,212,0,0.12);
}



.recordingPlaybackModal-transcript-speakerChunk .message-para *::selection{
	//background-color: var(--textSelectionBlue);
	//background-color: var(--primary500) !important;	
	//background: var(--accentOrange700) !important;
	// background: hsla(24, 70%, 40%) !important;
	//background: rgba(255,217,89,0.3);
	background: rgba(255,212,0,0.32) !important;
	background: rgba(255,212,0,0.24) !important;
	box-shadow: none !important;
	//padding-bottom: 1px !important;
	//border-bottom: 1px solid rgba(255,212,0,0.9) !important;
	//border-bottom: 1px solid rgba(255,212,0,0.9) !important;
}





//

@keyframes activeTranscriptWordAnimation {
  0% {
    background: var(--activeTranscriptWordBG);
    //outline: 2px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 2px var(--activeTranscriptWordBG);
  }
  50% {
    background: var(--activeTranscriptWordBG);
    //outline: 5px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 3px var(--activeTranscriptWordBG);
  }
  100% {
    background: var(--activeTranscriptWordBG);
    //outline: 3px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 2px var(--activeTranscriptWordBG);
  }
}

//$activeTranscriptHighlightWordBG: rgba(255,212,0,0.56);
$activeTranscriptHighlightWordBG: hsl(48,60%,35%);
// $activeTranscriptHighlightWordBG: blue;

@keyframes activeHighlightTranscriptWordAnimation {
  0% {
    background: $activeTranscriptHighlightWordBG;
    //outline: 2px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 2px $activeTranscriptHighlightWordBG;
  }
  50% {
    background: $activeTranscriptHighlightWordBG;
    //outline: 5px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 3px $activeTranscriptHighlightWordBG;
  }
  100% {
    background: $activeTranscriptHighlightWordBG;
    //outline: 3px solid var(--activeTranscriptWordBG)
		box-shadow: 0px 0px 0px 2px $activeTranscriptHighlightWordBG;
  }
}



// @keyframes activeTranscriptWordAnimationHighlight {
//   0% {
//     background: var(--activeTranscriptHighlightWordBG);
//     //outline: 2px solid var(--activeTranscriptWordBG)
// 		box-shadow: 0px 0px 0px 2px var(--activeTranscriptHighlightWordBG);
//   }
//   50% {
//     background: var(--activeTranscriptHighlightWordBG);
//     //outline: 5px solid var(--activeTranscriptWordBG)
// 		box-shadow: 0px 0px 0px 3px var(--activeTranscriptHighlightWordBG);
//   }
//   100% {
//     background: var(--activeTranscriptHighlightWordBG);
//     //outline: 3px solid var(--activeTranscriptWordBG)
// 		box-shadow: 0px 0px 0px 2px var(--activeTranscriptHighlightWordBG);
//   }
// }
//.recordingPlaybackModal-transcript--jumpMode


.recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active{
  animation: activeTranscriptWordAnimation 220ms ease-in forwards;
  color: white;
}


.recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active.doc-span--transcriptHighlight{
	animation: activeHighlightTranscriptWordAnimation 220ms ease-in forwards;
	// border-bottom: none;
  color: white;
  position: relative;
  z-index: 10;
}

// .recordingPlaybackModal-transcript--highlightMode
// .recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active{
//   animation: activeTranscriptWordAnimationHighlight 220ms ease-in forwards;
//   color: white;
// }




//
//

@import 'RecordingPlaybackModal-Transcript-HighlightGutter';
@import 'RecordingPlaybackModal-Transcript-Scrollbar';
@import 'RecordingPlaybackModal-Transcript-HighlightPanel';

.recordingPlaybackModal-transcript-header--highlight{
	color: var(--text500);
	height: 54px;
	background: $highlightYellow;
}



.recordingPlaybackModal-transcript-highlightPanel-tooltip{
	width: 100%;
	height: 100%;
	color: var(--text500);
}


//
//

.message-para-highlightStartMarker{
	width: 20px;
	height: 20px;
	background: $tempr;
	position: relative;

	margin-left: 2px;

	display: none;

}

.message-para-highlightStartMarker-stem{
	position: absolute;
	right: 1px;
	bottom: -2px;
	height: 22px;
	width: 3px;
	border-radius: 3px;
	background: $highlightYellow;
}

.message-para-highlightStartMarker-flag{
	position: absolute;
	top: -5px;
	width: 12px;
	height: 8px;
	right: 1px;

	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;

	background: $highlightYellow;

}