.editMetadataPopoverWrapperContainer{	
	
}

.editMetadataPopoverWrapperContainer:focus{
	outline: none !important;
}

.editMetadataPopoverWrapperContainer--hidden,
.editMetadataPopoverWrapperContainer--hidden *{
	pointer-events: none !important;
}


//

.editMetadataPopover{
	width: 240px;
	// min-height: 440px;
	// max-height: 80vh;

	// height: 400px;

	box-sizing: border-box;
	padding-top: 4px;
	padding-bottom: 8px;
	//padding-bottom: 12px;
	//padding-bottom: 14px;
	border-radius: 5px;

	padding-left: 0px;
	padding-right: 0px;

	display: flex;
	flex-direction: column;
}

.editMetadataPopover--type--projectStatus{
	width: 180px;
}

.editMetadataPopover--type--projectTargetDate{
	width: auto;
	padding-bottom: 0px;
}

.editMetadataPopover--type--projectMembers{
	width: 240px;
}

.editMetadataPopover--type--projectLead{
	width: 220px;
}

.editMetadataPopover--type--projectTeams{
	width: 200px;
}


.editMetadataPopover--layout--editProjectModal.editMetadataPopover--type--projectLead,
.editMetadataPopover--layout--editProjectModal.editMetadataPopover--type--projectMembers{
	max-height: calc(50vh - 120px);	
	overflow-y: scroll;
}

.editMetadataPopover--layout--roadmapProjectRow.editMetadataPopover--type--projectLead{
	max-height: calc(50vh - 120px);	
	overflow-y: scroll;
}


@import 'EditMetadataPopover-ProjectIcon';

.editMetadataPopover--type--projectTargetDate-clearBtnContainer{
	margin-top: -14px;
	width: 100%;
	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;
	padding-bottom: 6px;

	display: flex;
	justify-content: flex-start;
}

.editMetadataPopover--type--projectTargetDate-clearBtn{
	// width: 100%;
	height: 34px;
	box-sizing: border-box;
	padding-right: 12px;
	padding-left: 12px;
	//background: $tempr;
	border-radius: 5px;
	cursor: pointer;

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text600);
	letter-spacing: 0.008em;
	opacity: 0.5;

	
}

.editMetadataPopover--type--projectTargetDate-clearBtn:hover{
	opacity: 1;
	background: var(--250gray);
}
