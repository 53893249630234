.docEditor-meetingTopInfo-dateContainer{
	width: 280px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	// background: $tempr;
	height: calc(100% + 8px); // big touch area
	cursor: text;

	margin-left: -6px;
	padding-left: 6px;

	position: relative;
}

.docEditor-meetingTopInfo-dateContainer-popoverTrigger{
	position: absolute;
	width: 100%;
	height: calc(100% - 12px);
	pointer-events: none;
}



//

.docEditor-meetingTopInfo-dynamicDateLabel{
	display: flex;
	justify-content: flex-start;
	align-items: center;

	flex-grow: 1;
	flex-shrink: 1;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn{	
	height: 28px;	
	border-radius: 3px;	
	cursor: pointer;
	
	color: var(--text500);
	//border: 1px solid var(--line300);

	// background: $tempr;
}



.docEditor-meetingTopInfo-dynamicDateLabel-btn-label{

}


.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover)
.docEditor-meetingTopInfo-dynamicDateLabel-btn{
	background: none;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn{
	// background: var(--252gray);
}



.docEditor-meetingTopInfo-dynamicDateLabel-btn--noDate{
	// nasty i know
	
	// padding-left: 6px !important;
	// padding-right: 10px !important;
	transition: all 0ms linear !important;
	transition-delay: 0ms !important;
	// // margin-left: -4px;
	// transform: translateX(4px);

	// opacity: 0.75 !important;
	// // background: red !important;
	// //background: var(--250gray) !important;
}

// .docEditor-meetingTopInfo-dynamicDateLabel-btn--noDate
.docEditor-meetingTopInfo-dynamicDateLabel-btn--noDate:hover{
	opacity: 1 !important;
	// background: var(--250gray) !important;
}


.docEditor-meetingTopInfo-dynamicDateLabel-btn--noDate:hover{
	background: var(--252gray);
	transition-delay: 0ms !important;
}

//

.docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime{
	// nasty i know
	background: none;
	padding-left: 6px !important;
	padding-right: 10px !important;
	transition: all 50ms linear !important;
	transition-delay: 0ms !important;
	margin-left: 0px;

	opacity: 0 !important;
	// background: red !important;
	// background: var(--250gray) !important;
	background: none;
}

.docEditor-meetingTopInfo:hover 
.docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime{
	opacity: 0.6 !important;

}


.docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime:hover,
.docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime[data-state="open"]{
	opacity: 1 !important;
	transition: all 0ms linear !important;
	background: var(--250gray) !important;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime:hover{
	background: var(--252gray);
	transition-delay: 0ms !important;
}

//

.docEditor-meetingTopInfo-dynamicDateLabel-btn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
	margin-left: -3px;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.75px;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn-iconContainer svg.icon--name--clock{
	width: 15px;
	height: 15px;
	position: relative;
	right: -1px;
}

//

.docEditor-meetingTopInfo-dynamicDateLabel-btn[data-state="open"]{
	pointer-events: none;
	background: var(--252gray);
	transition-delay: 50ms;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn:hover{	
	background: var(--252gray);
	transition-delay: 50ms;
}

.docEditor-meetingTopInfo-dateContainer--anyPopoverOpen{
	cursor: pointer;
}

// .docEditor-meetingTopInfo-dynamicDateLabel-btn:hover{	
// 	background: var(--250gray);
// }

.docEditor-meetingTopInfo-dynamicDateLabel-btn--date{
	padding-left: 8px;
	padding-right: 8px;
	margin-left: 0px;	
}

.docEditor-meetingTopInfo--noTypeTag
.docEditor-meetingTopInfo-dynamicDateLabel-btn--date{
	margin-left: -6px;
}

.docEditor-meetingTopInfo--hasTypeTag
.docEditor-meetingTopInfo-dateContainer--hasDate.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover)
.docEditor-meetingTopInfo-dynamicDateLabel-btn--date,
.docEditor-meetingTopInfo--noTypeTag
.docEditor-meetingTopInfo-dateContainer--hasTimestamp.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover)
.docEditor-meetingTopInfo-dynamicDateLabel-btn--date{
	padding-left: 3px;
	padding-right: 3px;
	transform: translateX(4px);
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn--time{
	padding-left: 6px;
	padding-right: 6px;
}

.docEditor-meetingTopInfo-dynamicDateLabel-arrow{
	height: 28px;
	display: flex;
	align-items: center;	
	margin-left: 2px;
	margin-right: 2px;
}

.docEditor-meetingTopInfo-dynamicDateLabel-arrow svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.5;
}

// .docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime span{
// 	margin-left: 3px;
// 	opacity: 0.75;
// }

.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover){
	opacity: 0.6;	
}

.docEditor-meetingTopInfo-dateContainer{
	opacity: 1;
	transition: all 100ms;
	transition-delay: 50ms;
}

.docEditor-meetingTopInfo-dynamicDateLabel-btn,
.docEditor-meetingTopInfo-dynamicDateLabel-arrow{
	transition: all 100ms;
	transition-delay: 50ms;
}

.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover)
.docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime,
.docEditor-meetingTopInfo-dateContainer--noPopoverOpen:not(:hover)
.docEditor-meetingTopInfo-dynamicDateLabel-arrow{
	opacity: 0;	
}

.docEditor-meetingTopInfo-dateContainer
.docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime,
.docEditor-meetingTopInfo-dateContainer
.docEditor-meetingTopInfo-dynamicDateLabel-arrow{
	opacity: 1;
}


//
//

.docEditor-meetingTopInfo-dateContainer--viewOnlyMode{
	cursor: default;
}

.docEditor-meetingTopInfo-dateContainer--viewOnlyMode
button.docEditor-meetingTopInfo-dynamicDateLabel-btn{
	pointer-events: none;
	background: none;

	padding-left: 3px;
	padding-right: 3px;
}

.docEditor-meetingTopInfo
.docEditor-meetingTopInfo-dateContainer--viewOnlyMode
.docEditor-meetingTopInfo-dynamicDateLabel-arrow,
.docEditor-meetingTopInfo
.docEditor-meetingTopInfo-dateContainer--viewOnlyMode
.docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime{
	opacity: 0;
	transform: translateX(-4px);
}

.docEditor-meetingTopInfo-dateContainer--viewOnlyMode:hover
.docEditor-meetingTopInfo-dynamicDateLabel-arrow,
.docEditor-meetingTopInfo-dateContainer--viewOnlyMode:hover
.docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime{
	opacity: 1;
	transform: translateX(0px);
}

.docEditor-meetingTopInfo-dateContainer--viewOnlyMode:hover
.docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime{
	opacity: 0.8;
}

// .docEditor-meetingTopInfo-dateContainer--viewOnlyMode:hover
// .docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime{
// 	transition-delay: 340ms;
// }


//
.editMeetingEditDatePopoverContainer,
.editMeetingEditTimePopoverContainer{	
	
}

.editMeetingEditDatePopoverContainer:focus,
.editMeetingEditTimePopoverContainer:focus{
	outline: none;
}

.editMeetingEditDatePopoverContainer--hidden,
.editMeetingEditDatePopoverContainer--hidden *,
.editMeetingEditTimePopoverContainer--hidden,
.editMeetingEditTimePopoverContainer--hidden *{
	pointer-events: none !important;
}

//

.editMeetingEditDatePopover{
	width: 256px;

	box-sizing: border-box;
	padding-top: 4px;
	padding-bottom: 2px;
	padding-left: 2px;
	padding-right: 2px;

	border-radius: 5px;
}

//


.editMeetingEditTimePopover{
	width: 256px;	

	
	// min-height: 440px;
	// max-height: 50vh;

	// height: 400px;

	box-sizing: border-box;
	
	
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;

	display: flex;
	flex-direction: column;
}

.editMeetingDateTimePopover-startEndTime{
	width: 100%;
	overflow-y: scroll;

	height: 400px;

	padding-top: 6px;
	padding-bottom: 14px;
}



//
//











// .editMeetingDateTimePopover-startEndTime{
	
// 	display: flex;


// 	width: calc(100% - 20px);
// 	margin-left: 10px;	

// 	height: 32px;

// 	padding-top: 10px;
// 	border-top: 1px solid var(--line200);
// }

// .editMeetingDateTimePopover-startEndTime-arrow{
// 	width: 20px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

// .editMeetingDateTimePopover-startEndTime-arrow svg{
// 	width: 15px;
// 	height: 15px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .editMeetingDateTimePopover-startEndTime-inputContainer{
	
// 	flex-grow: 0;
// 	flex-shrink: 0;


// 	width: 100px;

// 	position: relative;
// }

// .editMeetingDateTimePopover-startEndTime-inputTrigger{
// 	top: 0%;
// 	left: 0%;
// 	width: 100%;
// 	height: 100%;
// 	position: absolute;
// 	pointer-events: none;
// }


// input.editMeetingDateTimePopover-startEndTime-input{		
	
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	width: 100%;
// 	height: 100%;

// 	box-sizing: border-box;

// 	padding-top: 0px;
// 	padding-bottom: 0px;
// 	padding-left: 10px;

// 	font-size: 16px;
// 	font-weight: $lato-regular;
// 	color: var(--text500);

// 	border: 1px solid $invisible;
// 	background: var(--253gray);
// 	border-radius: 3px;
// }

// input.editMeetingDateTimePopover-startEndTime-input:hover{
// 	background: var(--252gray);
// 	border: 1px solid var(--line300);
// }
// //

// input.editMeetingDateTimePopover-startEndTime-input:focus{
// 	border: 1px solid var(--primary500);
// 	outline: 2px solid var(--primary50);
// 	background: var(--bg-docEditor);
// }


//

.editMeetingEditTimePopover{
	width: unset;

	max-height: 340px;

	box-sizing: border-box;

	padding-top: 0px;
	padding-bottom: 0px;
	
	border-radius: 4px;

	padding-left: 0px;
	padding-right: 0px;

	display: flex;
	flex-direction: column;

	overflow-y: scroll;
}

.editMeetingDateTimePopover-list{
	
	padding-top: 8px;
	padding-bottom: 14px;

	box-sizing: border-box;
	padding-left: 4px;
	padding-right: 4px;
	
}

.editMeetingDateTimePopover-list--startTime{
	width: 180px;
}

.editMeetingDateTimePopover-list--endTime{
	width: 210px;
}


.popover-item--editMeetingTime{
	width: 100%;
	border-radius: 3px;
}

.popover-item--editMeetingTime .popover-item-label{
	margin-left: 8px;
}

.popover-item--editMeetingTime--active,
.popover-item--editMeetingTime--active:hover{
	background: var(--primary500);
	// background: var(--text500);
}

[data-theme="dark"]{
	.popover-item--editMeetingTime--active,
	.popover-item--editMeetingTime--active:hover{
		background: rgba(230,230,255,0.18);
	}	
}


.popover-item--editMeetingTime--active .popover-item-label,
.popover-item--editMeetingTime--active:hover .popover-item-label{
	color: white;
	//color: var(--text600);
	font-weight: $lato-semibold;
}

.popover-item--editMeetingTime--inactive .popover-item-label{
	font-weight: $lato-regular;
	font-weight: var(--text500);
}


.popover-item--editMeetingTime-label-duration{
	opacity: 0.65;
	font-weight: $lato-regular;
	margin-left: 1px;
}

// .docEditor-meetingTopInfo-dateContainer
// .docEditor-meetingTopInfo-staticDateLabel{
// 	display: flex;
// }

// .docEditor-meetingTopInfo-dateContainer
// .docEditor-meetingTopInfo-dynamicDateLabel{
// 	display: none;
// }

// .docEditor-meetingTopInfo-dateContainer:hover
// .docEditor-meetingTopInfo-staticDateLabel{
// 	display: none;
// }

// .docEditor-meetingTopInfo-dateContainer:hover
// .docEditor-meetingTopInfo-dynamicDateLabel{
// 	display: flex;
// }


// .docEditor-meetingTopInfo-staticDateLabel{
// 	height: 28px;

// 	// display: flex;
// 	justify-content: flex-start;
// 	align-items: center;

// 	flex-grow: 1;
// 	flex-shrink: 1;

// 	padding-left: 3px;
// 	padding-right: 8px;

// 	border-radius: 4px;

// 	font-weight: var(--text500);
// 	opacity: 0.75;

// 	cursor: pointer;

// }

// .docEditor-meetingTopInfo-staticDateLabel span{
// 	margin-left: 5px;
// 	opacity: 0;
// 	transition: opacity 75ms linear;
// }

// .docEditor-meetingTopInfo-staticDateLabel:hover span{
// 	opacity: 0.6;
// 	transition: opacity 75ms linear;
// 	transition-delay: 150ms;
// }

// .docEditor-meetingTopInfo-staticDateLabel[data-state="open"]{
// 	pointer-events: none;
// 	background: var(--253gray);
// }

// .docEditor-meetingTopInfo-staticDateLabel[data-state="open"] span{
// 	opacity: 0.6;
// }