
//dndSideMenu-tabList-tabGroup --> sideMenu-tabGroup
.sideMenu-tabGroup{
	width: 100%;
	// padding-top: 14px;
	min-height: 50px;

	padding-top: 8px;
	cursor: default !important;
}

//
// HEADER

.sideMenu-tabGroup-header{
	width: calc(100% - 6px);
	margin-left: 3px;
	border-radius: 4px;

	//margin-left: 18px;

	height: 24px;
	// background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	//padding-left: 18px;
	padding-left: 14px;
	// outline: 1px solid red;

	color: var(--text500);

	font-size: 11.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	// margin-bottom: 8px;

	user-select: none;

	cursor: default !important;
	pointer-events: none;
	
}

.sideMenu-tabGroup-header-label{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideMenu-tabGroup-header:hover{
	opacity: 0.6;
}

.sideMenu-tabGroup-header[data-state="open"]{
	background: var(--bgSideMenuTabHover);
	opacity: 0.9;
}

//
// POPOVER

.sideMenu-tabGroup-header-editPopoverOuterContainer{
	// margin-left: 92px;
	// margin-left: 68px;
	margin-top: 4px;
	margin-left: 4px;
}


.sideMenu-tabGroup-header-editPopover{
	width: 240px;

	padding-top: 10px;
	padding-bottom: 12px;
}

.sideMenu-tabGroup-header-editPopover-inputContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 8px;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	position: relative;
}

.sideMenu-tabGroup-header-editPopover-input:focus ~ 
.sideMenu-tabGroup-header-editPopover-inputContainer-ui{
	opacity: 1;
}

.sideMenu-tabGroup-header-editPopover-inputContainer-ui{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;

	// background: $tempr;

	padding-right: 15px;

	pointer-events: none;

	opacity: 0;
	transition: opacity 50ms linear;
}

.sideMenu-tabGroup-header-editPopover-inputContainer-ui-enterKeyTag{
	margin-left: auto;

	height: 22px;
	padding-left: 6px;
	padding-right: 6px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;

	background: var(--bgSideMenuTabLight);
}

.sideMenu-tabGroup-header-editPopover-inputContainer-ui-enterKeyTag-label{
	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;
	color: var(--textColorSideMenuTabHover);

	margin-right: 3px;
	opacity: 0.6;
}

.sideMenu-tabGroup-header-editPopover-inputContainer-ui-iconContainer{
	width: 14px;
	height: 14px;
	height: 100%;

	display: flex;	
	align-items: center;
}

.sideMenu-tabGroup-header-editPopover-inputContainer-ui-iconContainer svg{
	width: 14px;
	height: 14px;

	fill: var(--textColorSideMenuTabHover);
	opacity: 0.6;
}


//

.sideMenu-tabGroup-header-editPopover-input{
	width: 100%;
	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 72px; // for the enter key tag

	border-radius: 3px;

	height: 32px;

	
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text600);

	box-shadow: none;

	background: $white;
	border: 1px solid var(--line400);

}

.sideMenu-tabGroup-header-editPopover-input:focus{
	background: $white;
	outline: none;
	border: 1px solid var(--primary500);
	outline: 2px solid var(--primary50);
}

.sideMenu-tabGroup-header-editPopover-input::placeholder{
	color: var(--text100);
}