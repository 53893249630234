.doc-mediaModalCommentInputContainer{
	height: 100%;
	display: flex;
	width: 500px;	
	flex-grow: 0;
	flex-shrink: 1;

	align-items: center;

	transition: opacity 50ms linear;
}



.doc-mediaModalCommentInput{
	
	width: 100%;
	height: 34px;

	position: relative;
}

.doc-mediaModalCommentInput-input{
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	width: 100%;

	background: rgba(255,255,255,0.06);
	border: 1px solid rgba(255,255,255,0.15);

	font-size: 15px;
	font-weight: $lato-regular;

	color: $white;

	border-radius: 4px;

	padding-right: 50px;
	padding-left: 10px;

	box-sizing: border-box;
	padding-bottom: 3px;

	transition: opacity 0.5 linear;
}

.doc-mediaModalCommentInputContainer--video--showTimestampButton
.doc-mediaModalCommentInput-input{
	padding-right: 120px;
}


.doc-mediaModalCommentInputContainer--empty.doc-mediaModalCommentInputContainer--video--playing{
	opacity: 0.5;
	transition: opacity 200ms linear;
}

// .doc-mediaModalCommentInputContainer--empty
// .doc-mediaModalCommentInput-input:not(:focus){
// 	opacity: 0.65;
// }

// .doc-zoomedImageModal-footer:hover
// .doc-mediaModalCommentInputContainer--empty
// .doc-mediaModalCommentInput-input{
// 	opacity: 0.9;
// }

// .doc-zoomedImageModal-footer:hover
// .doc-mediaModalCommentInputContainer--empty
// .doc-mediaModalCommentInput-input{
// 	opacity: 1;
// }


.doc-mediaModalCommentInput-input:hover{
	background: rgba(255,255,255,0.05);
	border: 1px solid rgba(255,255,255,0.20);
	outline: none;
}

.doc-mediaModalCommentInput-input:focus{
	// background: rgba(255,255,255,0.04);
	// border: 1px solid rgba(255,255,255,0.35);
	// outline: none;

	// lighten because autofocused

	background: rgba(255,255,255,0.04);
	border: 1px solid rgba(255,255,255,0.15);
	outline: none;
}


.doc-mediaModalCommentInput-input::placeholder{
	color: rgba(255,255,255,0.4);
	user-select: none;
}

.doc-mediaModalCommentInput-input:hover::placeholder{
	color: rgba(255,255,255,0.5);	
}

.doc-mediaModalCommentInput-input:focus::placeholder{
	color: rgba(255,255,255,0.25);
	color: rgba(255,255,255,0.35);
}

//
// TIMESTAMP

.doc-mediaModalCommentInput-UI-timestampButton{
	position: absolute;
	right: 50px;
	top: 5px;
	bottom: 5px;
	min-width: 64px;

	padding-left: 8px;
	padding-right: 8px;

	background: rgba(255,255,255,0.08);

	font-size: 14px;
	font-weight: $lato-medium;
	color: #81b0ff;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	border-radius: 4px;

	transition: all 100ms linear;

	user-select: none;
	pointer-events: none; // doesn't do anything atm
}

.doc-mediaModalCommentInput-UI-timestampButton-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;

	margin-left: 3px;
	margin-right: -1px;

	opacity: 1;
}
.doc-mediaModalCommentInput-UI-timestampButton-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: #81b0ff;

	position: relative;
	// top: -0.5px;
}

// .doc-mediaModalCommentInput-UI-timestampButton--active:hover{
// 	opacity: 0.75;
// 	transform: scale(0.95);	
// }

.doc-mediaModalCommentInput-UI-timestampButton--inactive{
	opacity: 0.5;
	transform: scale(0.95);
	color: $white;
}

.doc-mediaModalCommentInput-UI-timestampButton--inactive
.doc-mediaModalCommentInput-UI-timestampButton-iconContainer svg{
	fill: $white;
}

.doc-mediaModalCommentInput-UI-timestampButton--inactive:hover{
	opacity: 0.75;
	transform: scale(1);	
}

.doc-mediaModalCommentInput-UI-timestampButton{
	opacity: 0;
}

.doc-mediaModalCommentInput-input:focus ~
.doc-mediaModalCommentInput-UI
.doc-mediaModalCommentInput-UI-timestampButton{
	opacity: 1;
}
// .doc-mediaModalCommentInput-UI-timestampButton--inactive
// .doc-mediaModalCommentInput-UI-timestampButton-label{
// 	opacity: 0;
// }

//
//

.doc-mediaModalCommentInput-UI-sendBtn{
	width: 44px;
	position: absolute;
	top: 1px;
	bottom: 1px;
	right: 1px;

	border-radius: 4px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

	//background: rgba(255,255,255,0.05);

	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-mediaModalCommentInputContainer--empty
.doc-mediaModalCommentInput-UI-sendBtn{
	background: none;
}

.doc-mediaModalCommentInputContainer--nonEmpty
.doc-mediaModalCommentInput-UI-sendBtn:hover{
	background: rgba(255,255,255,0.12);
}

.doc-mediaModalCommentInput-UI-sendBtn svg{
	width: 16px;
	height: 16px;


	fill: $white;
}

.doc-mediaModalCommentInputContainer--empty
.doc-mediaModalCommentInput-UI-sendBtn svg{
	opacity: 0.3;
}

.doc-mediaModalCommentInputContainer--empty:hover
.doc-mediaModalCommentInput-UI-sendBtn svg,
.doc-mediaModalCommentInputContainer--empty
.doc-mediaModalCommentInput-input:focus ~ 
.doc-mediaModalCommentInput-UI
.doc-mediaModalCommentInput-UI-sendBtn svg{
	opacity: 0.6;
}

.doc-mediaModalCommentInputContainer--nonEmpty
.doc-mediaModalCommentInput-UI-sendBtn svg{
	opacity: 1;
}