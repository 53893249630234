//
//

.popoverMenu-item--newContact{
	display: flex;
	height: 52px;
	background: var(--hoverBG-item-dropdown) !important; // dangerous;
	padding-left: 12px;
	// background: $tempr;
}

.popoverMenu-item--newContact-iconOuterContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 6px;
}

.popoverMenu-item--newContact-iconInnerContainer{
	width: 28px;
	height: 30px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	//background: var(--235gray);
}

.popoverMenu-item--newContact-iconInnerContainer svg{
	width: 24px;
	height: 24px;
	fill: var(--text500);
	opacity: 0.65;
}

.popoverMenu-item--newContact-labelContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;
}

.popoverMenu-item--newContact-labelContainer-primary{
	line-height: unset;
	margin-top: 2px;
	margin-bottom: 2px;
}

.popoverMenu-item--newContact-labelContainer-secondary{
	font-size: 14px;
	height: 18px;
	opacity: 0.7;
	line-height: unset;
	margin-top: -10px;
}

.popoverMenu-item--newContact-rightContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	padding-left: 5px;
}

.popoverMenu-item--newContact-rightContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text600);
	opacity: 0.5;
}

//
.editMeetingParticipantsPopover-scrollList-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
	min-height: 10px;
}



.editMeetingParticipantsPopover-newContactInfo{
	width: 100%;
	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;

	font-size: 13.75px;
	text-align: center;
	font-weight: $lato-regular;
	color: var(--text500);
	
	letter-spacing: 0.012em;

	user-select: none;
	cursor: default;
}

.editMeetingParticipantsPopover-newContactInfo-emoji{
	font-size: 32px;
	margin-bottom: 12px;
}

.editMeetingParticipantsPopover-newContactInfo-description-para:last-child{
	margin-top: 4px;
	opacity: 0.75;
	margin-bottom: 4px;
}

//

.editMeetingParticipantsPopover-newContact{
	width: 100%;
	height: 100%;

	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 6px;
	padding-bottom: 2px;
}

//

.editMeetingParticipantsPopover-newContact-header{
	display: flex;
	align-items: center;
		
	margin-bottom: 10px;
	
}

.editMeetingParticipantsPopover-newContact-header-backButton{
	width: 24px;
	height: 24px;

	background: var(--252gray);
	border-radius: 4px;

	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 2px;
}

.editMeetingParticipantsPopover-newContact-header-backButton svg{
	transform: scaleX(-100%);

	width: 14px;
	height: 14px;

	fill: var(--text600);
	opacity: 0.5;
}

.editMeetingParticipantsPopover-newContact-header-backButton:hover{
	background: var(--250gray);
}

.editMeetingParticipantsPopover-newContact-header-backButton:hover svg{
	opacity: 1;
}

.editMeetingParticipantsPopover-newContact-header-label{
	font-weight: $lato-bold;
	font-size: 16px;
	color: var(--text500);

	cursor: default;
	user-select: none;
}



//

// .editMeetingParticipantsPopover-newContact-vSpacer{
// 	min-height: 10px;
// 	flex-grow: 1;
// 	flex-shrink: 1;
// }

.editMeetingParticipantsPopover-newContact-submitBtnContainer{
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 10px;
	margin-top: 8px;
}

.editMeetingParticipantsPopover-newContact-submitBtn{
	width: 100%;
	height: 40px;

	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.010em;
	border-radius: 4px;

	color: $white;
	background: var(--text400);
	border: 1px solid var(--text500);

	position: relative;
}

.editMeetingParticipantsPopover-newContact-submitBtn:disabled{
	opacity: 0.5;
	pointer-events: none;
}

.editMeetingParticipantsPopover-newContact-submitBtn:hover{
	background: var(--text500);
	border: 1px solid var(--text600);
}

.editMeetingParticipantsPopover-newContact-submitBtn:focus,
.editMeetingParticipantsPopover-newContact-submitBtn--submitLoading{
	background: var(--text600) !important; // just faster
	border: 1px solid var(--text600) !important; 	
}

:root[data-theme="dark"]{
	.editMeetingParticipantsPopover-newContact-submitBtn{
		background: var(--250gray);
		border: 1px solid var(--246gray);
	}	
}

:root[data-theme="dark"]{
	.editMeetingParticipantsPopover-newContact-submitBtn:hover{
		background: var(--248gray);
		border: 1px solid var(--246gray);
	}	
}

:root[data-theme="dark"]{
	.editMeetingParticipantsPopover-newContact-submitBtn:focus,
	.editMeetingParticipantsPopover-newContact-submitBtn--submitLoading{
		background: var(--243gray) !important; // just faster;
		border: 1px solid var(--235gray) !important; // just faster;
	}	
}



.editMeetingParticipantsPopover-newContact-submitBtn-loadingBar{
	right: 18px;
	left: 18px;

	top: 50%;
	transform: translateY(-50%);

	height: 3px;

	border-radius: 10px;

	position: absolute;

	background: var(--246gray);
	background: rgba(255,255,255,0.2);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.editMeetingParticipantsPopover-newContact-submitBtn-loadingBar-ball{
	width: 40px;
	height: 3px;
	border-radius: 4px;

	// background: red;
	background: rgba(255,255,255,0.8);	

}




//

.editMeetingParticipantsPopover-newContact-input{
	margin-bottom: 10px;
}

.editMeetingParticipantsPopover-newContact-input-label{
	font-size: 13.75px;
	font-weight: $lato-regular;
	color: var(--text500);
	letter-spacing: 0.01em;
	opacity: 0.6;

	padding-left: 7px;

	margin-bottom: 4px;
}

.editMeetingParticipantsPopover-newContact-input:focus-within
.editMeetingParticipantsPopover-newContact-input-label{
	opacity: 1;
}

.editMeetingParticipantsPopover-newContact-input-inputContainer{
	position: relative;
	width: 100%;

	height: 32px;
}


input.editMeetingParticipantsPopover-newContact-input-input{
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	padding-right: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	box-sizing: border-box;

	padding-left: 7px;

	position: absolute;
	border: none;
	border-radius: 3px;

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text600);

	border: 1px solid var(--line400);
	background: var(--254gray);
}

.editMeetingParticipantsPopover-newContact-input-input::placeholder{
	color: var(--toDoItem-placeholderColor);
}

.editMeetingParticipantsPopover-newContact-input-input:focus{
	background: var(--bg-docEditor);
	border: 1px solid var(--primary400);
	outline: 2px solid var(--primary25);
}
