
.roadmapPage-headerContainer{
	width: 100%;
	//background: $tempr;
}


.roadmapPage-headerCoverImageContainer{
	width: 100%;
	height: 340px;
	//background: $tempg;
	margin-bottom: 40px;

	box-sizing: border-box;
	// padding-top: 14px;
	// padding-left: 14px;
	// padding-right: 14px;	
}

.roadmapPage-headerCoverImage{
	width: 100%;
	height: 100%;

	overflow: hidden;


	//background: radial-gradient(100% 60% at 0% 0%,  lighten(#AAD1FF, 3%), darken(#AAD1FF, 3%));

	// border-radius: 8px;
}

.roadmapPage-headerCoverImage-image{
	width: 100%;
	height: 100%;
	object-fit: cover;

	object-position: left top;
}

.roadmapPage-headerTopSpacer{
	height: 84px;
	height: 96px;
	width: 100%;
	//background: $tempr;
}



//

.roadmapPage-header{

	display: flex;

	user-select: none;
	margin-bottom: 24px;
}

//

.roadmapPage-header-leftSpacer{
	// width set in corelayout
}

.roadmapPage-header-backBtnContainer{
	// background: $tempr;
	
	height: calc(100% + 64px);
	margin-top: -40px;

	box-sizing: border-box;
	padding-right: 24px;

	width: 100%;
}

.roadmapPage-header-backBtn{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	box-sizing: border-box;
	padding-top: 40px;
	padding-right: 12px;

	// background: $tempr;
}

.roadmapPage-header-backBtn svg{
	transform: scaleX(-100%);
	position: relative;
	top: 10px;

	width: 26px;
	height: 26px;
	fill: var(--text500);
	opacity: 0.3;
}

.roadmapPage-header-backBtn:hover svg{
	opacity: 0.8;
}

.roadmapPage-header-backBtn:active svg{
	opacity: 1;
	fill: var(--text600);
}

.roadmapPage-header-rightSpacer{
	width: 100px;
}

.roadmapPage-header-centerContainer{
	width: 100%;
	max-width: $maxWidth-centerArea-roadmapPage;
	display: flex;
	flex-direction: column;
}



//
// TOP ROW 

.roadmapPage-header-topRow{
	display: flex;	
}

.roadmapPage-header-topRow-iconContainer{
	margin-right: 12px;
	margin-left: -4px;

	width: 38px;
	// background: $tempr;
	// margin-left: -68px;
}

.roadmapPage-header-topRow-iconContainer svg{
	width: 40px;
	height: 40px;
	fill: var(--text500);
	opacity: 0.3;
	position: relative;
	top: 3px;
}

.roadmapPage-header-topRow-title{		
	font-size: 39px;
	//font-size: 36px;
	font-weight: $lato-black;
	color: var(--text600);
	letter-spacing: 0.001em;

	cursor: default;
	user-select: none;

	margin-bottom: 12px;
}

[data-theme="dark"] {
	.roadmapPage-header-topRow-title{
		font-weight: $lato-heavy;
		letter-spacing: 0.005em;
	}
}



.roadmapPage-header-topRow-rightContainer{
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.roadmapPage-header-topRow-btn{
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	border-radius: 6px;

	opacity: 0.6;
}

.roadmapPage-header-topRow-btn:hover{
	opacity: 1;
	background: var(--250gray);
}



.roadmapPage-header-topRow-btn svg{
	width: 22px;
	height: 22px;
	fill: var(--text500);

	opacity: 0.8;
}

.roadmapPage-header-topRow-btn--star svg{
	width: 24px;
	height: 24px;
}


//
// SUB ROW

.roadmapPage-header-subRow-description{
	font-size: 19px;
	font-weight: $lato-regular;
	letter-spacing: 0.002em;
	color: var(--text500);
	opacity: 0.6;
	box-sizing: border-box;
	padding-right: 100px;
	margin-top: -6px;
	line-height: 1.4;
}


//
// COVER IMAGE PLACEHOLDER



.roadmapPage-headerCoverImage--placeholder{
	background: var(--248gray);

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	box-sizing: border-box;
	padding-right: 14px;
	padding-bottom: 14px;
}

.roadmapPage-headerCoverImage-uploadingSpinnerContainer{
	width: 32px;
	height: 32px;
	background: var(--240gray);
	border-radius: 6px;

	position: relative;
}


@keyframes spinnerRotateAnimation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.roadmapPage-headerCoverImage-uploadingSpinner{
	position: absolute;

	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	right: 0px;
	left: 0px;
}




.roadmapPage-headerCoverImage-uploadingSpinner-spinner{
	height: 16px;
	width: 16px;
	
  border-radius: 16px;

  border: 2px var(--210gray) solid;
  border-top: 2px var(--125gray) solid;

  opacity: 0.7;

  animation: spinnerRotateAnimation .6s infinite linear;
}





// // .roadmapPage-header-title-iconContainer{
// // 	width: 38px;
// // 	height: 38px;
// // 	//background: $tempr;

// // 	margin-right: 10px;

// // 	display: flex;
// // 	align-items: center;
// // 	justify-content: center;
// // }

// // .teamPage-header-title-markerContainer
// // .linearMarker svg{
// // 	width: 34px;
// // 	height: 34px;
// // }

// // .roadmapPage-header-title-iconContainer,
// // .privateDocsPage-header-title-iconContainer{
// // 	width: 40px;
// // 	height: 40px;

// // }

// // .roadmapPage-header-title-iconContainer{
// // 	margin-left: -1px;
// // 	margin-right: 13px;
// // }

// // .privateDocsPage-header-title-iconContainer{
// // 	margin-left: -6px;
// // 	margin-right: 8px;
// // }

// .roadmapPage-header-title-iconContainer svg,
// .privateDocsPage-header-title-iconContainer svg{
// 	width: 40px;
// 	height: 40px;
// 	fill: var(--text500);
// 	opacity: 0.4;
// }

// .roadmapPage-header-title-iconContainer svg{
// 	position: relative;
// 	top: 2px;
// }


