
.sideMenu-appTabsContainer{
	// thing like library and drafts
	position: relative;
	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;
	margin-bottom: 6px;
	//background: $tempb;
}

//
// TEMP - PROJECTS

.sideMenu-projectsBottomSpacer{
	height: 0px;
}



//
//

.sideMenu-scrollableTabContainer{
	// contains docs and channels
	width: 100%;

	//height: 300px;
	min-height: 10px;
	//max-height: 600px;

	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;

	display: flex;
	flex-direction: column;

	position: relative;

	//background: $tempb;
}

.sideMenu-tabSectionSpacer{
	min-height: 10px;
	width: 100%;

	flex-grow: 1;
	flex-shrink: 1;
}

//

.sideMenu-docTabsContainer{
	flex-shrink: 0;
	flex-grow: 1;
	min-height: 50px;
	//background: $tempg;
	width: 100%;

	display: flex;
	flex-direction: column;
}

.sideMenu-docTabsGroup--independent{
	width: 100%;
	// background: $tempg;	
	flex-grow: 1;

	box-sizing: border-box;
}

.sideMenu-docTabsGroup--independent-header{
	width: 100%;
	height: 26px;
	//background: $tempr;
	display: flex;
	align-items: center;

}

.sideMenu-docTabsGroup--independent-header-bar{
	height: 3px;
	width: 20px;

	margin-left: 18px;


	border-radius: 2px;
	background: var(--bgSideMenuTabActiveHover);
}

//
// dndSideMenu-tabList-tabGroup-tabList --> .sideMenu-tabGroup-tabList


.sideMenu-tabGroup-tabList{
	min-height: 30px;
}

.sideMenu-tabGroup-tabList--independentTabs{
	height: 100%;
}



//


.sideMenu-bottomActionsContainer{
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;

	padding-top: 20px;
	
	padding-bottom: 8px;
	position: relative;
	// background: $tempr;
}

// .sideMenu-bottomActionsContainer-header{ // maybe reuse same as doc tab groups
// 	display: flex;
// 	align-items: center;
	
// 	width: 100%;

// 	padding-top: 8px;
// 	padding-bottom: 6px;

// 	user-select: none;
// 	cursor: default;
// }

// .sideMenu-bottomActionsContainer-header-label{

// 	margin-left: 18px;

// 	color: var(--text500);

// 	font-size: 11.5px;
// 	font-weight: $lato-bold;
// 	text-transform: uppercase;
// 	letter-spacing: 0.025em;
// 	opacity: 0.5;

// 	// font-size: 12.75px;
// 	// font-weight: $lato-bold;
// 	// letter-spacing: 0.002em;
// 	// opacity: 0.5;
// }


// .sideMenu-bottomActionsContainer .sideMenu-tab:nth-child(n+5){
// 	display: none
// }

// .sideMenu-bottomActionsContainer .sideMenu-tab:nth-child(3){
// 	display: none
// }


//






//
// DOCS TABS

// .sideMenu-docTabsContainer{
// 	width: 100%;
// 	background: $tempb;
// 	margin-top: 12px;
// }

// .sideMenu-bottomActionsContainer{
// 	width: 100%;
// 	background: $tempr;
// 	padding-bottom: 12px;
// }


// //
// //
// //
// // DRAGGABLE
// .sideMenu-tabSections-divider--openDocs{
	
// }

// //

// .sideMenu-tabSections-divider--channels{
// 	margin-top: 16px;
// }

// .sideMenu-tabSections-divider{
// 	display: flex;
// 	align-items: center;
	
// 	width: 100%;

// 	padding-top: 8px;
// 	padding-bottom: 6px;

// 	user-select: none;
// 	cursor: default;
// }

// .sideMenu-tabSections-divider-label{

// 	margin-left: 18px;

// 	color: var(--text500);

// 	font-size: 11.5px;
// 	font-weight: $lato-bold;
// 	text-transform: uppercase;
// 	letter-spacing: 0.025em;
// 	opacity: 0.5;

// 	// font-size: 12.75px;
// 	// font-weight: $lato-bold;
// 	// letter-spacing: 0.002em;
// 	// opacity: 0.5;
// }

// .sideMenu-tabSections-orgName{	
// 	font-size: 13.25px;
// 	color: var(--text400);
// 	font-weight: $lato-bold;
// 	height: 30px;
// 	display: flex;
// 	align-items: flex-end;

// 	box-sizing: border-box;
// 	padding-bottom: 6px;

// 	letter-spacing: 0.0005em;

// 	// margin-bottom: 6px;
// 	margin-left: 17px;

// 	user-select: none;
// 	cursor: pointer;

// 	opacity: 0.7;
// }