.setupTeamSyncModalContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.setupTeamSyncModalContainer:focus{
	outline: none;
}

.setupTeamSyncModalContainer--hidden,
.setupTeamSyncModalContainer--hidden *{
	pointer-events: none !important;
}


//

.setupTeamSyncModal{
	margin-top: -20vh; // we need this for enough space for the dropdowns

	//min-width: 640px;
	height: 360px;
	width: 640px;

	box-sizing: border-box;
	//padding-top: 26px;
	padding-top: 20px;
	padding-bottom: 24px;
	padding-left: 34px;
	padding-right: 34px;

	border-radius: 6px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;
}

//
// TOP ROW
.setupTeamSyncModal-topRow{
	width: 100%;
	display: flex;
	align-items: center;

	box-sizing: border-box;
}

.setupTeamSyncModal--new
.setupTeamSyncModal-topRow{
	padding-right: 20px;
}

.setupTeamSyncModal--edit
.setupTeamSyncModal-topRow{
	padding-right: 40px;
}

.setupTeamSyncModal-topRow-modalLabel-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.setupTeamSyncModal-topRow-modalLabel{
	display: flex;
	align-items: center;
}

.setupTeamSyncModal-topRow-modalLabel-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.setupTeamSyncModal-topRow-modalLabel-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.65;
}

.setupTeamSyncModal-topRow-modalLabel-label{
	font-size: 16.25px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 0.7;
}

.setupTeamSyncModal-topRow-participantList{
	display: flex;
	align-items: center;
	justify-content: flex-end;

}

.setupTeamSyncModal-topRow-participantList-container
.meetingParticipantList[data-state="open"]{
	position: relative;
	z-index: 10;
}

//

.setupTeamSyncModal-titleRow{
	width: 100%;
	height: 64px;
	
	margin-top: 24px;
	margin-bottom: 2px;
	//margin-bottom: 36px;
	position: relative;
}

$setupTeamSyncModal-gutterOverhang: 12px;

input.setupTeamSyncModal-titleRow-input{
	position: absolute;
	top: 0px;
	height: 100%;

	left: calc(#{$setupTeamSyncModal-gutterOverhang} * -1);
	right: calc(#{$setupTeamSyncModal-gutterOverhang} * -1);
	
	width: calc(100% + (2 * #{$setupTeamSyncModal-gutterOverhang}));

	box-sizing: border-box;
	
	padding-left: $setupTeamSyncModal-gutterOverhang;
	padding-right: $setupTeamSyncModal-gutterOverhang;

	border: none;
	outline: none;
	box-shadow: none;
	background: none;

	border-radius: 3px;


	// font-size: 20.75px;
	
	font-size: 26px;
	font-weight: $lato-bold;
	// font-size: 28px;


	font-weight: $lato-heavy;
	//letter-spacing: 0.002em;
	color: var(--text600);
}

input.setupTeamSyncModal-titleRow-input::placeholder{
	color: var(--text200);
	opacity: 0.5;
}

//

.setupTeamSyncModal-dateTimeRow-label,
.setupTeamSyncModal-docTemplateRow-label,
.setupTeamSyncModal-topRow-participantList-label{
	font-size: 15.25px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.7;

	cursor: default;
	user-select: none;
}

.setupTeamSyncModal-dateTimeRow-label{
	margin-bottom: 8px;
	display: none;
}

.setupTeamSyncModal-docTemplateRow-label,
.setupTeamSyncModal-topRow-participantList-label{
	margin-right: 10px;
}


//
.setupTeamSyncModal-topRow-dateTimeRow-dayOfWeekToggle{
	display: flex;
	margin-top: 6px;
	margin-bottom: 8px;
}

.setupTeamSyncModal-topRow-dateTimeRow-dayOfWeekToggle,
.setupTeamSyncModal-topRow-dateTimeRow-time-btn:first-child{
	margin-left: -2px;
}

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn,
.setupTeamSyncModal-topRow-dateTimeRow-time-btn,
.setupTeamSyncModal-docTemplateRow-btn{
	height: 29px;
	
	position: relative;

	cursor: pointer;

	font-size: 15.25px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
}

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn{
	width: 48px;
	border-radius: 0px;
	margin-right: -1px;
}

.setupTeamSyncModal-topRow-dateTimeRow-time-btn,
.setupTeamSyncModal-docTemplateRow-btn{
	padding-left: 5px;
	padding-right: 11px;
	border-radius: 3px;

	margin-right: 6px;
}

.setupTeamSyncModal-docTemplateRow-btn{
	height: 31px;
	padding-left: 3px;
	
	justify-content: flex-start;
}


.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

//

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--inactive,
.setupTeamSyncModal-topRow-dateTimeRow-time-btn,
.setupTeamSyncModal-docTemplateRow-btn{
	border: 1px solid var(--line300);
}
.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--inactive:hover,
.setupTeamSyncModal-topRow-dateTimeRow-time-btn:hover,
.setupTeamSyncModal-docTemplateRow-btn:hover{
	border: 1px solid var(--line400);
	z-index: 5;	
}

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--active{
	background: var(--text400);
	border: 1px solid var(--text500);
	
	color: $white;
	z-index: 10;
}

.setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--active:hover{
	background: var(--text500);
	border: 1px solid var(--text600);
}


.setupTeamSyncModal-topRow-dateTimeRow-time-btn-iconContainer,
.setupTeamSyncModal-docTemplateRow-btn-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2px;
}

.setupTeamSyncModal-docTemplateRow-btn-iconContainer{
	margin-right: 1px;
}

.setupTeamSyncModal-topRow-dateTimeRow-time-btn-iconContainer svg,
.setupTeamSyncModal-docTemplateRow-btn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.4;
}

.setupTeamSyncModal-topRow-dateTimeRow-time-btn-iconContainer svg{
	width: 15px;
	height: 15px;
}


//
//


.setupTeamSyncModal-topRow-dateTimeRow-time{
	display: flex;
}

//

.setupTeamSyncModal-docTemplateRow{
	display: flex;
	align-items: center;
}



//@import 'setupTeamSyncModal-FooterBtns';

//

.setupTeamSyncModal-vSpacer{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

//



@import 'SetupTeamSyncModal-SubmitBtn';



//
// CLOSE BUTTON
.setupTeamSyncModal-closeBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	
	width: 56px;
	height: 56px;

	box-sizing: border-box;

	display: flex;
	align-items: flex-end;
	justify-content: start;

	padding-bottom: 12px;
	padding-left: 12px;

	z-index: 40;
}

.setupTeamSyncModal-closeBtn svg{
	width: 24px;
	height: 24px;
	fill: var(--text600);
	opacity: 0.25;

	transition: all 50ms linear;
}

.setupTeamSyncModal-closeBtn:hover svg{
	opacity: 0.75;
	transform: scale(1.05);

	transition: all 50ms linear;
}


.setupTeamSyncModal-cancelBtn{
	// background: $tempr;

	position: absolute;
	top: 14px;
	right: 20px;
	
	//width: 56px;
	height: 34px;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 12px;
	padding-left: 12px;

	// background: $tempr;

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text400);

	opacity: 0.6;

	border-radius: 4px;

	z-index: 40;
}

.setupTeamSyncModal-cancelBtn:hover{
	background: var(--253gray);
	opacity: 1;
}


//
// DIALOG OVERLAY


.setupTeamSyncModalOverlay{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	//background: $tempr;
	
	background: rgba(0,0,0,0.12);
	background: rgba(0,0,0,0.12);
}

.setupTeamSyncModalTestOverlay{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	background: $tempr;
}


[data-theme="dark"]{
	.setupTeamSyncModalOverlay{
		background: rgba(0,0,0,0.24);	
	}
}