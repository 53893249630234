.dndSideMenu-tabList-tabGroup-header{
	//margin-left: 18px;

	height: 24px;
	//background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 18px;
	// outline: 1px solid red;

	color: var(--text500);

	font-size: 11.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	// margin-bottom: 8px;

	user-select: none;
}

.dndSideMenu-tabList-tabGroup-header-editPopoverOuterContainer{
	// margin-left: 92px;
	// margin-left: 68px;
	margin-top: 4px;
	margin-left: -8px;
}


.dndSideMenu-tabList-tabGroup-header-editPopover{
	width: 240px;

	padding-top: 10px;
	padding-bottom: 12px;
}

.dndSideMenu-tabList-tabGroup-header-editPopover-inputContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 8px;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
}

.dndSideMenu-tabList-tabGroup-header-editPopover-input{
	width: 100%;
	box-sizing: border-box;
	padding-left: 8px;

	border-radius: 3px;

	height: 34px;

	
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text600);

	box-shadow: none;

	background: $white;
	border: 1px solid var(--line400);

}

.dndSideMenu-tabList-tabGroup-header-editPopover-input:focus{
	background: $white;
	outline: none;
	border: 1px solid var(--primary500);
	outline: 2px solid var(--primary50);
}

.dndSideMenu-tabList-tabGroup-header-editPopover-input::placeholder{
	color: var(--text100);
}