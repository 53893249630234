

//
// POPOVER SETUP

.messageHighlightTranscriptPopoverContainer{	
	
}

.messageHighlightTranscriptPopoverContainer:focus{
	outline: none;
}

.messageHighlightTranscriptPopoverContainer--hidden,
.messageHighlightTranscriptPopoverContainer--hidden *{
	pointer-events: none !important;
}

.messageHighlightTranscriptPopover.popover{
	width: 350px;
	
	box-sizing: border-box;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;	
	
	border-radius: 6px;
}

//

.message-content-highlight-video-transcriptPopoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.message-content-highlight-zoomedTranscriptContainer{
	position: absolute;

	// background: $tempr;

	box-sizing: border-box;
	padding-right: 14px; // distance from transcript to video
}

.message-content-highlight-zoomedTranscriptInnerContainer{
	background: rgb(20,20,30);
	border-radius: 6px;
	border: 1px solid rgba(255,255,255,0.03);
	box-shadow: var(--messageMediaZoomedBoxShadow);
	// background: var(--bg-docEditor);
	// border-radius: 6px;
	// border: 1px solid rgba(255,255,255,0.03);
	// box-shadow: var(--messageMediaZoomedBoxShadow);
}

//

.message-content-highlight-transcript--inline{
	min-height: 280px;
	max-height: 450px;
}



.message-content-highlight-transcript{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;

	width: 100%;
	
	border-radius: 4px;


	box-sizing: border-box;	
}

.message-content-highlight-transcript-header{
	width: 100%;
	height: 40px;
	border-radius: 4px;

	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-top: 8px;
	padding-left: 12px;
	padding-right: 12px;

	justify-content: flex-start;
	align-items: center;
}

.message-content-highlight-transcript-header-iconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
}

.message-content-highlight-transcript-header-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
}

.message-content-highlight-transcript-header-label{
	font-size: 15.75px;
	font-weight: $lato-medium;

	color: var(--text500);
}

.message-content-highlight-transcript-header-hide{
	margin-left: auto;

	font-size: 14px;
	color: var(--text500);
	letter-spacing: 0.01em;
	font-weight: $lato-regular;

	opacity: 0.5;
	transition: opacity 50ms linear;
}

.message-content-highlight-transcript-header:hover
.message-content-highlight-transcript-header-hide{
	opacity: 0.9;
}

.message-content-highlight-transcript-contentsContainer{
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;
}

.message-content-highlight-transcript-chunkExample{
	width: 100%;
	margin-bottom: 20px;
	background: $tempr;
}

.message-content-highlight-transcript-chunkExample:nth-child(1){
	height: 140px;	
}
.message-content-highlight-transcript-chunkExample:nth-child(2){
	height: 90px;	
}
.message-content-highlight-transcript-chunkExample:nth-child(3){
	height: 100px;	
}