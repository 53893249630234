.doc-video-zoomedUI-closeBtnHoverContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 80px;
	height: 80px;
	z-index: 40;
	// background: $tempr;

}

.doc-video-zoomedUI-closeBtnOuterContainer{
	position: absolute;
	top: 0px;
	right: 0px;

	padding: 4px;

	// background: $tempr;
}

.doc-video-zoomedUI-closeBtn{
	width: 34px;
	height: 34px;

	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	background: rgba(3,0,5,0.5);
	backdrop-filter: blur(10px);


}

.doc-video-zoomedUI-closeBtn svg{
	width: 14px;
	height: 14px;
	fill: $white;

	// opacity: 0.5;
}


.doc-video-zoomedUI-closeBtn:hover svg{
	// opacity: 1;
}


.doc-video-zoomedUI-closeBtnHoverContainer
.doc-video-zoomedUI-closeBtn{
	opacity: 0;
	transition: opacity 500ms linear;
	transition-delay: 1000ms;
}

.doc-video-zoomedUI-closeBtnHoverContainer:hover
.doc-video-zoomedUI-closeBtn{
	opacity: 1;
	transition: opacity 50ms linear;
}

.doc-video-zoomedUI-closeBtnHoverContainer:hover
.doc-video-zoomedUI-closeBtn:hover{
	background: rgba(3,0,5,0.9);
}

.doc-video-zoomedUI-closeBtnHoverContainer:hover
.doc-video-zoomedUI-closeBtn:hover svg{
	transition: all 50ms linear;
	transform: scale(1.1);
}


//
// MAYBE DO THIS LATER

.doc-video-zoomedUI-titleBarContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;

	box-sizing: border-box;

	// padding-left: 4px;
	// padding-right: 4px;
	// padding-top: 4px;

	height: 120px;
}

.doc-video-zoomedUI-titleBar{
	opacity: 0;
}

.doc-video-zoomedUI-titleBarContainer:hover
.doc-video-zoomedUI-titleBar{
	opacity: 1;
}

.doc-video-zoomedUI-titleBar{
	width: 100%;
	height: 46px;
	flex-grow: 0;
	flex-shrink: 0;

	background: $black;
	// background: $tempb;

	display: flex;

	// background: $tempr;

	cursor: default;

	
}


.doc-video-zoomedUI-titleBar-leftContainer,
.doc-video-zoomedUI-titleBar-rightContainer{
	
	display: flex;
	align-items: center;
}

.doc-video-zoomedUI-titleBar-rightContainer{
	margin-left: auto;
	justify-content: flex-end;
	padding-right: 6px;
}


.doc-video-zoomedUI-titleBar-leftContainer{
	padding-left: 8px;

	// padding-right: 4px;
	// border-radius: 7px;
	// // background: rgba(3,0,5,0.8);
	// // backdrop-filter: blur(10px);
}

.doc-video-zoomedUI-titleBar-menuBtn{
	width: 24px;
	height: 24px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	opacity: 0.5;

	margin-right: 2px;
}

.doc-video-zoomedUI-titleBar-menuBtn:hover{
	opacity: 1;
	background: rgb(255,255,255,0.25);
}

.doc-video-zoomedUI-titleBar-menuBtn svg{
	width: 14px;
	height: 14px;
	fill: $white;
}

input.doc-video-zoomedUI-titleBar-videoTitleInput{
	height: 28px;
	width: 240px;
	background: $tempr;
	border: none;
	padding-left: 8px;
	border-radius: 4px;
	background: none;

	color: rgba(255,255,255,0.7);

	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.003em;
}

input.doc-video-zoomedUI-titleBar-videoTitleInput::placeholder{
	color: rgba(255,255,255,0.3);
}

input.doc-video-zoomedUI-titleBar-videoTitleInput:hover{
	background: rgba(255,255,255,0.08);
	outline: none;
	border: none;
}

input.doc-video-zoomedUI-titleBar-videoTitleInput:hover::placeholder{
	color: rgba(255,255,255,0.5);
}

input.doc-video-zoomedUI-titleBar-videoTitleInput:focus{
	background: rgba(255,255,255,0.12);
	color: rgb(255,255,255,1);
	outline: none;
	border: none;
}
