


.linearProjectStatusTag{
	padding-left: 7px;
	padding-right: 8px;
	height: 28px;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	user-select: none;
}

.linearProjectStatusTag-markerContainer{
	width: 16px;
	height: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 5;

	margin-right: 6px;
}

.linearProjectStatusTag-label{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	text-transform: capitalize;
	position: relative;
	z-index: 5;
	letter-spacing: 0.008em;
	position: relative;
	top: -0.5px;
}

.linearProjectStatusTag-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	border-radius: 5px;
	opacity: 0.08;
}




//
// COLORS

.linearProjectStatusTag--status--backlog
.linearProjectStatusTag-label{
	color: var(--red--yarn);
}

.linearProjectStatusTag--status--backlog
.linearProjectStatusTag-bg{
	background: var(--red--yarn);
}


//


.linearProjectStatusTag--status--planned
.linearProjectStatusTag-label{
	color: var(--text500);
	opacity: 0.8;
}


.linearProjectStatusTag--status--planned
.linearProjectStatusTag-bg{
	background: var(--grey--yarn);
}

//



.linearProjectStatusTag--status--started
.linearProjectStatusTag-label{
	color: var(--yellow--yarn);
	//color: hsl(28,87%,49%); //dirty
	color: hsl(35,75%,50%); //dirty
	color: #ff990c;
	color: #d87d00;
	
	// color: $white;
	// color: var(--text500);
}


.linearProjectStatusTag--status--started
.linearProjectStatusTag-bg{
	background: var(--yellow--yarn);
}

//

.linearProjectStatusTag--status--completed
.linearProjectStatusTag-label{
	color: var(--purple--yarn);	
}


.linearProjectStatusTag--status--completed
.linearProjectStatusTag-bg{
	background: var(--purple--yarn);
}

//

.linearProjectStatusTag--status--canceled
.linearProjectStatusTag-label{
	color: var(--text500);
	opacity: 0.7;
}


.linearProjectStatusTag--status--canceled
.linearProjectStatusTag-bg{
	background: var(--darkGrey--yarn);
}

//

.linearProjectStatusTag--status--paused
.linearProjectStatusTag-label{
	color: var(--text500);
	opacity: 0.7;
}


.linearProjectStatusTag--status--paused
.linearProjectStatusTag-bg{
	background: var(--darkGrey--yarn);
}