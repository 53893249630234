
.message{

}

.messageContainer--autoGenerated .message-para{
	opacity: 0.55;
}

.message-para,
.message-blockQuote,
.message-docQuote,
.messagePanel-preloadMessage-para,
.docEditor-messagePanel-inputBox-placeholderText{
	font-weight: $lato-regular;
	font-size: 15.25px;
	color: var(--text500);
	letter-spacing: 0.003em;

	line-height: 1.4;

	min-height: 12px;

	cursor: text;
	word-break: break-word; // I think this is right
	// overflow-wrap: break-word;
	// white-space: pre-wrap;

}

.docEditor-messagePanel-inputBox-ghostSpacer{

}

//
// BLOCK QUOTE
// this is triggered by > in message input box

.message-blockQuote{
	margin-top: 3px;
	margin-bottom: 3px;
	

	display: flex;
	width: 100%;

	position: relative;
}

.message-blockQuote-divider{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 4px;
	bottom: 0px;

	border-radius: 2px;
	background: var(--messageBlockQuoteDividerBG);
}

.message-blockQuote-content{
	box-sizing: border-box;
	padding-left: 15px;	
}


//
// EMOJI

.message-para-emoji{
	//height: auto;
	height: 0.8em;
	width: 1.01em;
	margin-left: 0.03em;
	margin-right: 0.03em;

	display: inline-block;
	overflow: visible;

	object-fit: contain;
	//vertical-align: top;
	vertical-align: -0.3em;

	position: relative;
	top: -0.19em;

	// background: $tempr;

	font-size: 1.36em;
}

.message-para-emoji::after{
	content: "\200B";
}

.message-para-emoji--singleEmoji{
	font-size: 2.2em;
	vertical-align: -0.4em;

	// background: $tempr;
}




//
// DOC QUOTE
//

.message-docQuote{
	// margin-top: 3px;
	margin-bottom: 6px;
	
	display: flex;
	width: calc(100% + 8px); // for the over-margin

	border-radius: 5px;
	box-sizing: border-box;
	
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 8px;
	padding-right: 8px;

	margin-left: -4px;

	position: relative;
	
	background: rgba(255,212,0,0.12);
	// background: rgba(5,0,5,0.03);

	border: 1px solid $invisible;

  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  position: relative;

  user-select: none;
}

.message-docQuote-deleteBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	
	width: 24px;
	height: 24px;

	box-sizing: border-box;
	padding-top: 2px;
	padding-right: 2px;
	// background: $tempr;

	display: flex;
	align-items: top;
	justify-content: flex-end;


}

.message-docQuote-deleteBtn-inner{
	border-radius: 50%;
	width: 18px;
	height: 18px;
	//background: transparentize(#D5BF6E, 0.8);
	background: lighten(#D5BF6E, 12%);
	
	// background: $white;
	// border: 1px solid darken(#D5BF6E, 0%);

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-docQuote-deleteBtn-inner:hover{
	background: lighten(#D5BF6E, 2%);
	// transform: scale(1.05);
}

.message-docQuote-deleteBtn-inner svg{
	width: 12px;
	height: 12px;
	//fill: #FFFAE7; // same yellow as underlying
	//fill: darken(#D5BF6E, 10%);
	fill: $white;
}


// sent message
.message .message-docQuote{
	margin-top: 5px;
	margin-bottom: 4px;	
}

//
//input box
.docEditor-messagePanel-inputBox-input .message-docQuote{
	margin-bottom: 8px;
	padding-right: 24px; // need space for delete button
}

//
// HOVER STATES
.messageContainer:hover .message-docQuote{
	background: rgba(0,0,0,0.03);
}

.messageContainer:hover .message-docQuote:hover{
	//background: rgba(255,212,0,0.24);	
	background: #FFF5CF; //opaque version of above
	border: 1px solid #ffd400;

	background: rgba(0,0,0,0.04);
	background: $white;
	border: 1px solid rgba(0,0,0,0.14);
	border-bottom: 1px solid rgba(0,0,0,0.2);
}

.messageContainer:hover .message-blockQuote
.message-blockQuote-content .message-para{
	color: var(--text400);
	opacity: 1;
}

.messageContainer:hover
.message-blockQuote:hover
.message-blockQuote-content .message-para{
	color: var(--text600);
	opacity: 1;
}

.message-docQuote:active{
	background: rgba(255,212,0,0.36);	
	background: #FFEFB7;//opaque version of above
	border: 1px solid #ffd400;
	transition: background-color 0ms linear;
}

.messageContainer:hover
.message-docQuote:active{
	// background: rgba(255,212,0,0.36);	
	// background: #FFEFB7;//opaque version of above
	// border: 1px solid #ffd400;
	background: $white;
	background: rgb(253,253,253);
	border: 1px solid rgba(0,0,0,0.2);
	border-bottom: 1px solid rgba(0,0,0,0.24);

	// background: #FFF5CF; //opaque version of above
	// border: 1px solid #ffd400;

	transition: all 0ms linear;


}

.message-docQuote:not(:active) {
	transition: all 150ms linear;
}	


// INLINE CODE
.message-para .doc-span--style--code{
	font-size: 13px;
}



// .message-blockQuote-divider{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 4px;
// 	bottom: 0px;

// 	border-radius: 2px;
// 	background: var(--messageBlockQuoteDividerBG);
// }

.message-blockQuote-content{
	box-sizing: border-box;
	
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  pointer-events: none;	
  user-select: none;
}

.message-blockQuote
.message-blockQuote-content .message-para{
	color: darken(#FDE9AE, 70%);
	//color: var(--text400);
}

@import 'Messages-MessageContent-StaticMedia';
@import 'Messages-MessageContent-Video';


@import 'Messages-MessageContent-Highlights';
@import 'Messages-MessageContent-Recording';

@import 'Messages-MessageContent-Selections'; // doc selections, message selections, image selections, video selections 

@import 'Messages-MessageContent-InlineMentionSearchBar';

@import 'Messages-MessageContent-UserMention';
@import 'Messages-MessageContent-InternalLink';
@import 'Messages-MessageContent-InlineLinearIssue';
@import 'Messages-MessageContent-LinearProject';

