.doc-toDoItem-assignedContainer{
	
	position: absolute;
	z-index: 5000;
	cursor: pointer;

	// background: $tempr;
	width: 60px;
	top: 0px;
	bottom: 0px;
	left: -62px;

	user-select: none;
	justify-content: flex-end;

	display: flex;
	// visibility: hidden;
}

.todoItem-assigned{
	display: flex;
	
	height: $minHeight-checkBox-toDoItem;
	min-width: 80px;
	// margin-left: 32px;
	// margin-right: -28px;


	align-items: center;
	justify-content: flex-end;

	user-select: none;
	position: relative;
}

.todoItem-assigned-popoverBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none;

	z-index: 20;

	// background: $tempr;
}

.todoItem-assigned-assigneeList{
	position: relative;
	height: 100%;
	// background: $tempr;
}


.todoItem-assigned-listContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 80px;

	// background: $tempr;
	
}

.todoItem-assigned-listContainer--mouseOver{
	// background: $tempb;
}


.todoItem-assigned-listContainer--menuOpen{
	pointer-events: none;
}



@import 'Doc-ToDoItem-Assignment-Avatar';

//
//


.todoItem-assigned-addButton{
	width: 20px;
	height: 20px;
	margin-right: 1px; // so aligned with assignee list
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	position: relative;
	z-index: 10;

	// background: $tempr;
	position: absolute;

}

.todoItem-assigned-addButton svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.6;
}


.todoItem-assigned--hasAssignees
.todoItem-assigned-addButton{
	pointer-events: none;
}


.todoItem-assigned-addButton{
	opacity: 0;	

	transition: opacity 0ms linear;
	transition-delay: 0ms;	
}





.todoItem-assigned--noAssignees
.todoItem-assigned-addButton:hover,
.doc-toDoItem--cursorInside
.todoItem-assigned--noAssignees
.todoItem-assigned-addButton{
	opacity: 1;	

	transition: opacity 50ms linear;
	transition-delay: 50ms;	
}

.todoItem-assigned--noAssignees
.todoItem-assigned-addButton:hover{
	transition-delay: 50ms;
}


.todoItem-assigned--noAssignees
.todoItem-assigned-addButton.todoItem-assigned-addButton--menuOpen{
	pointer-events: none;

	opacity: 0.5;
	transition: opacity 75ms linear;
	transition-delay: 300ms;
}

// .todoItem-assigned--showPlusButton
// .todoItem-assigned-addButton.todoItem-assigned-addButton--menuClosed:hover{
// 	transform: scale(1.1);
// 	transition-property: transform;
// 	transition-delay: 0ms;
// 	transition-duration: 100ms;
// }


.doc-toDoItem:hover
.todoItem-assigned--showPlusButton
.todoItem-assigned-addButton:hover svg{
	opacity: 0.8;
}




//


.todoItem-assigned-plus-tooltip,
.todoItem-assigned-addButton--menuOpen:hover
.todoItem-assigned-plus-tooltip{
	position: absolute;
	right: 26px;	
	top: -2px;

	opacity: 0;
	transition: opacity 50ms linear;

	transition-delay: 0ms;

	z-index: 200;

	pointer-events: none;
}


.todoItem-assigned-addButton:hover 
.todoItem-assigned-plus-tooltip{
	opacity: 1;
	transition: opacity 70ms linear;
	// transition-delay: 0ms;
	transition-delay: 850ms;
}

.doc-toDoItem--cursorInside
.todoItem-assigned-addButton:hover 
.todoItem-assigned-plus-tooltip{
	opacity: 1;
	transition: opacity 70ms linear;
	// transition-delay: 0ms;
	transition-delay: 800ms;
}




// .todoItem-assigned-user--multi{
// 	// width: 40px;
// 	width: auto;
// 	padding-right: 4px;
// 	padding-left: 4px;
// 	letter-spacing: 0.03em;
// 	//letter-spacing: -0.1em;
// 	// padding-right: 1px;

// 	// background: var(--primary25);
// 	// color: var(--primary500);

// 	background: var(--accentPurple50);
// 	color: var(--accentPurple700);

// 	// background: var(--accentBlue400);
// 	// color: $white;
// 	// opacity: 0.75;

// 	font-size: 14.5px;
// 	height: 20px;

// }

// .todoItem-assigned-multipleUserContainer{
// 	// background: $tempr;
// 	width: auto;
// 	padding-left: 4px;

// 	display: flex;

// }

// .todoItem-assigned-multipleUserContainer
// .todoItem-assigned-user{
// 	// margin-left: -10px;
// }

// .todoItem-assigned-multipleUserContainer
// .todoItem-assigned-user:first-child{	
// 	position: relative;
// 	z-index: 20;
// 	outline: 2px solid var(--bg-docEditor);
// 	transform: translateX(10px);
// }



// .todoItem-assigned-multipleUserContainer:hover
// .todoItem-assigned-user:first-child{
// 	transform: translateX(0px);
// }



// .todoItem-assigned-user--n{
	

// 	background: var(--accentBlue50);
// 	color: var(--accentBlue700);

// 	// background: var(--accentPurple400);
// 	// color: $white;
// }

// .todoItem-assigned-user--j{
// 	background: var(--accentRed50);
// 	color: var(--accentRed600);

// 	// background: var(--accentOrange50);
// 	// color: var(--accentOrange700);

// 	// background-opacity: 0.5;
// 	// background: var(--accentRed400);
// 	// color: $white;
// }


// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user{	
// 	// opacity: 0.55;
// 	// color: rgba(255,255,255,0);
// 	//background: rgb(252,252,255);
// 	//transform: scaleY(0.5) scaleX(0.75);
// 	transform: scale(0.5);
// 	transform-origin: 100% 50%;
	
// 	// transition-property: color, transform;
// 	// transition-duration: 0ms, 0ms;	
// 	// transition-delay: 0ms, 0ms;
// 	// transition-timing-function: linear;
// }


// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user{	
// 	opacity: 0.5;
// }


// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-multipleUserContainer
// .todoItem-assigned-user:first-child{
// 	margin-right: -8px;
// }

// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user
// .avatarImage-doodleAvatar-img{
// 	opacity: 0;
// }

// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user--j{
// 	background: var(--accentRed100);
// }

// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user--multi{
// 	background: var(--accentBlue100);
// }

// .doc-toDoItem[data-is-checked='true']
// .todoItem-assigned-user--n{
// 	background: var(--accentPurple100);
// }


//

// .doc-toDoItem[data-is-checked='false']
// .todoItem-assigned-user{
// 	// opacity: 1;
// 	// transition-property: color, background;
// 	// transition-duration: 200ms, 200ms;	
// 	// transition-delay: 0ms, 0ms;
// }
