.message-para-internalLinkContainer{
	// margin-left: 2px;
	// margin-right: 2px;


	height: 22.5px;
	
	position: relative;
	top: 6px;
	margin-top: -7px;


	cursor: pointer;
	
	overflow: hidden;

	display: inline-block;

	// background: $tempr;
	border-radius: 2px;

	position: relative;
	//background: rgb(248,245,252);


}


.message-para-internalLink{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	padding-left: 1px;
	padding-right: 1px;
}

.message-para-internalLinkContainer.selection-span,
.message-para-internalLinkContainer.selection-span:hover{	
	background: var(--textSelectionBlue);
	border-radius: 0px;
}



.message-para-internalLink:hover{
	background: var(--internalLinkHoverBG);
}

.message-para-internalLinkContainer.ProseMirror-selectednode,
.message-para-internalLinkContainer.ProseMirror-selectednode:hover,
.message-para-internalLinkContainer.ProseMirror-selectednode .message-para-internalLink:hover{
	background: var(--250gray);
	background: var(--primary25);
	border-radius: 3px;
}


span.message-span--style--light
.message-para-internalLink:hover{
	color: var(--text500); // turn back normal on hover
}

.message-para-internalLink:active{
	background: var(--internalLinkActiveBG);
}

.message-para-internalLink-iconContainer{
	width: 20px;
	height: 20px;
	// background: $tempr;
	border-radius: 5px;

	font-size: 17.5px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	margin-right: 2px;

}

.message-para-internalLink-innerIconContainer{
	width: 13px;
	height: 13px;
	position: absolute;
	bottom: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-para-internalLink-innerIconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);	
}

.message-para-internalLink-innerIconContainer svg #outline{
	fill: var(--bg-docEditor);
	stroke: var(--bg-docEditor);
  stroke-width: 2px;
}

.message-para-internalLink-innerIconContainer svg #core{
	fill: var(--text600);
}

// span.message-span--style--light
// .message-para-internalLink{
// 	opacity: 0.75;
// }

span.message-span--style--light
.message-para-internalLink-innerIconContainer svg #core{
	fill: var(--text200)
}

span.message-span--style--light
.message-para-internalLink:hover
.message-para-internalLink-innerIconContainer svg #core{
	fill: var(--text600)
}


// NO BOARD

.message-para-internalLink-iconContainer--noBoard{
	width: 24px;
	height: 24px;
	// background: $tempr;
}

.message-para-internalLink-iconContainer--noBoard-inner{
	width: 18px;
	height: 18px;
	border-radius: 3px;
	background: rgb(245,245,250);
	background: var(--primary25);

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-para-internalLinkContainer.ProseMirror-selectednode .message-para-internalLink-iconContainer--noBoard-inner,
.message-para-internalLinkContainer.ProseMirror-selectednode:hover .message-para-internalLink-iconContainer--noBoard-inner{
	background: var(--primary100);
	border-radius: 3px;
}



.message-para-internalLink-iconContainer--noBoard-inner svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	fill: var(--primary400);
}

[data-theme="dark"] {
	// to do - make dark mode blue
	.message-para-internalLink-iconContainer--noBoard-inner{
		background: var(--240gray);
	}
	.message-para-internalLink-iconContainer--noBoard-inner svg{
		fill: var(--text300);
		opacity: 0.5;
	}

}

//

.message-para-internalLink-title{
	font-weight: $lato-bold;
	border-bottom: 1px solid rgb(220,220,227); 	

	height: 21px;
	margin-bottom: 0px;

	margin-right: 4px;
}

.message-para-internalLink:hover 
.message-para-internalLink-title{
	border-bottom: 1px solid rgb(200,200,207); 	
}