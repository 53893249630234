

@keyframes activeResultAnimation {
  0% {
    background: var(--searchResultActiveBG);
    //outline: 2px solid var(--searchResultActiveBG)
		box-shadow: 0px 0px 0px 3px var(--searchResultActiveBG);
  }
  50% {
    background: var(--searchResultActiveBG);
    //outline: 5px solid var(--searchResultActiveBG)
		box-shadow: 0px 0px 0px 4px var(--searchResultActiveBG);
  }
  100% {
    background: var(--searchResultActiveBG);
    //outline: 3px solid var(--searchResultActiveBG)
		box-shadow: 0px 0px 0px 3px var(--searchResultActiveBG);
  }
}

:root{	
	
	
	--textSelectionBlue: #C3D7FF;
	// --textSelectionBlue: red;


	--textSelectionBGColor: red; // textBlackAbsolute
	--activeCommentTextSelectionBGColor:	green; // textBlack
	--completedCommentTextBGColor: blue; // textDark



	//
	// DISCUSSION
	//

	// normal state
	--indocSpan--discussion--default-bg: rgba(240,228,248,0.34);
	--indocSpan--discussion--default-borderColor: darken(rgb(240,228,248), 58%);
	--indocSpan--discussion--default-textColor: darken(rgb(240,228,248), 74%);

	// // when thread panel is open
	// --indocSpan--discussion--active-bg:
	// --indocSpan--discussion--active-border:
	// --indocSpan--discussion--active-textColor:

	// // draft normal
	// --indocSpan--discussion--draft-bg:
	// --indocSpan--discussion--draft-border:
	// --indocSpan--discussion--draft-textColor:

	// // active draft, also on creation
	// --indocSpan--discussion--activeDraft-bg:
	// --indocSpan--discussion--activeDraft-border:
	// --indocSpan--discussion--activeDraft-textColor:

	
	//
	// SEARCH RESULTS
	// --searchResultBG: 	rgba(55,195,140,0.3);	
	// --searchResultActiveBG: 	rgba(55,195,140,0.6);	

	--searchResultBG: 	rgba(112,2,195,0.12);	
	--searchResultBGPlaceholder: 	rgba(112,2,195,0.22);	
	--searchResultActiveBG: 	rgba(112,2,195,0.32);	

	--activeTranscriptWordBG: 	hsla(213, 100%, 45%, 1);
	//--hoverTranscriptWordBG: 	hsla(213, 100%, 45%, 0.75);
	--hoverTranscriptWordBG: 	hsla(213, 0%, 45%, 0.5);
	//--activeTranscriptHighlightWordBG: hsla(46,92%,62%,0.7);
	--activeTranscriptHighlightWordBG: hsla(46,100%,62%,0.7);
	//--activeTranscriptWordBG: 	rgb(255,255,255,0.26);

}

// DARK THEME
// goes brighter as you go up the stack, i.e. 600 is brightest white, 000 is pure black

[data-theme="dark"], .forceDarkTheme{

	--textSelectionBlue: #1F3650;


	// --searchResultBG: 	rgba(94,114,235,0.3);	
	// --searchResultActiveBG: 	rgba(94,114,235,0.75);

	--searchResultBG: 	rgba(168,123,255,0.3);	
	--searchResultBGPlaceholder: rgba(168,123,255,0.4);	
	--searchResultActiveBG: 	rgba(168,123,255,0.75);

	// --activeTranscriptWordBG: 	hsl(213, 100%, 45%);


}