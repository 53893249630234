
// TEAM PANEL
// background: rgb(217,216,216);

:root{		
	--teamPanel-bg: rgb(253,252,253);	
	--teamPanel-borderLeftColor: rgb(232,232,232);
}

[data-theme="dark"]{
	--teamPanel-bg: rgb(33,30,37);
	--teamPanel-borderLeftColor: rgb(42,40,46);
}