.docEditor-addLinearLinkBtnContainer,
.docEditor-addLinearLinkToastContainer{
	position: absolute;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);

	z-index: 50;

}


.docEditor-addLinearLinkBtn{
  padding-left: 15px;
  padding-right: 15px;


  display: flex;
  align-items: center;

  height: 34px;
  border-radius: 4px;
  background: rgb(40,38,54);
  
  border: 1px solid rgb(0,0,0);
  border: 1px solid var(--line400);
  border-bottom: 1px solid var(--line500);

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);  

  user-select: none;

  background: var(--253gray);
  background: var(--bg-docEditor);

  position: relative;
}

[data-theme="dark"] .docEditor-addLinearLinkBtn{
  border: 1px solid var(--line200);
  background: var(--253gray);
  border-bottom: 1px solid var(--line300);
}

.docEditor-addLinearLinkBtn:hover{
	border: 1px solid var(--line500);
  border-bottom: 1px solid var(--line600);
}

[data-theme="dark"] .docEditor-addLinearLinkBtn:hover{
  border: 1px solid var(--line300);
  border-bottom: 1px solid var(--line400);
}



.docEditor-addLinearLinkBtn-iconContainer{
  width: 22px;
  height: 22px;
  border-radius: 4px;

  margin-right: 3px;
  margin-left: -7px;

  background: rgba(255,255,255,0.05);

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.6;
  transition: opacity 50ms linear;

}

.docEditor-addLinearLinkBtn-iconContainer svg{
  fill: var(--bg-docEditor);
  fill: var(--text600);
  
  width: 14.5px;
  height: 14.5px;

  margin-top: -1px;
  position: relative;
  //top: -0.5px;
}

.docEditor-addLinearLinkBtn:hover
.docEditor-addLinearLinkBtn-iconContainer{
	opacity: 0.7;
	transition: opacity 50ms linear;
}

.docEditor-addLinearLinkBtn-iconContainer svg.icon--name--jump{
  position: relative;
  right: -1px;
}

.docEditor-addLinearLinkBtn-label{
  color: rgba(255,255,255,1);
  color: var(--text500);
  font-size: 15.5px;
  font-weight: $lato-semibold;
  letter-spacing: 0.008em;
  opacity: 0.9;



  position: relative;
  top: -1px;
  transition: opacity 50ms linear;
}

.docEditor-addLinearLinkBtn:hover
.docEditor-addLinearLinkBtn-label{
	opacity: 1;
	transition: opacity 50ms linear;
}

.docEditor-addLinearLinkToast-label,
.docEditor-addLinearLinkToast-linkBtn{
  color: rgba(255,255,255,0.75);
  font-size: 15px;
  font-weight: $lato-semibold;
  color: rgb(255,255,255);
  letter-spacing: 0.008em;

  position: relative;
  top: -1px;
}
  

.docEditor-addLinearLinkToast-linkBtn{
  color: rgba(255,255,255,0.75);
  padding-left: 4px;
  padding-right: 4px;
  font-weight: $lato-regular;
  height: 100%;

}


// // Checkbox

// .docEditor-addLinearLinkToast-checkbox{
//   width: 14px;
//   height: 14px;
//   background: rgb(111, 173, 249);
//   border: 1px solid rgb(97, 152, 220);

//   border-radius: 4px;

//   margin-right: 8px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .element {
//   animation: animatedCheckBox 5s infinite;
// }

// @keyframes animatedCheckBox {
//   0% {
//     background-color: #001F3F;
//   }
//   100% {
//     background-color: #FF4136;
//   }
// }



// .docEditor-addLinearLinkToast-checkbox svg{
//   pointer-events: none;

//   width: 11px;
//   height: 11px;
//   stroke: var(--toDoItem-checkIconColor);


//   z-index: 10;
//   position: relative; 

//   stroke-width: 10px;
//   stroke-dasharray: 90;
//   stroke-dashoffset: 90;
// }

// .docEditor-addLinearLinkToast-checkbox svg{

//   transition: stroke-dashoffset 240ms linear; 
//   stroke-dashoffset: 0;
// }

// .doc-toDoItem-checkbox--state--unchecked svg{
//   stroke-dashoffset: $dashoffset;

//   transition: stroke-dashoffset 0ms linear;
// }










//
// INFO 

// .docEditor-addLinearLinkBtn-label-tooltipBtnContainer{
//   width: 28px;
//   height: 100%;
//   padding-right: 6px;
//   padding-left: 2px;

//   // background: $tempr;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   margin-left: 0px;

//   position: relative;
// }

// .docEditor-addLinearLinkBtn-label-tooltipBtnContainer svg{
//   width: 18px;
//   height: 18px;

//   fill: var(--text500);

//   opacity: 0.5;
// }


// .docEditor-addLinearLinkBtn-label-tooltipBtnContainer:hover svg{
//   opacity: 0.8;
// }



.docEditor-addLinearLinkBtn-label-tooltipContainer{
  position: absolute;
  bottom: 36px;
  width: 500px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  opacity: 0;
  transition: opacity 0ms linear;
}


.docEditor-addLinearLinkBtn:hover
.docEditor-addLinearLinkBtn-label-tooltipContainer{  
  opacity: 1;
  transition: opacity 0ms linear;
  transition-delay: 0ms;
}

.docEditor-addLinearLinkBtn-label-tooltipContainer .tooltip{
  padding-left: 8px;
  padding-right: 8px;
  max-width: 400px;
}

//


