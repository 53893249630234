.docEditor-issuePanelContainer{
	position: absolute;

	right: 0px;
	top: 0px;
	bottom: 0px;

	background: var(--bg-docEditor);
	border-left: 1px solid var(--sideBorder-messagePanel);	

	flex-grow: 0;
	flex-shrink: 0;

	z-index: 8; // above messages

	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	
	opacity: 1;
	
	background: var(--bg-docEditor);	
}

.docEditor-issuePanelContainer--independentLayout{
	position: relative;
	flex-grow: 0;
	flex-shrink: 0;
}


.docEditor-issuePanel{	
	height: 100%;
	display: flex;
	flex-direction: column;
}

.docEditor-issuePanel-header{
	height: 37px;
	width: 100%;
	display: flex;
	
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	
	// border-bottom: 1px solid $invisible;
	border-bottom: 1px solid var(--bottomBorder-header-messagePanel);
	box-shadow: var(--boxShadow-header-messagePanel);

	padding-left: 4px;
	
	user-select: none;

	position: relative;
	z-index: 10;


}



.docEditor-issuePanel-header-openLinearBtn{
	flex-grow: 0;
	flex-shrink: 0;

	height: 31px;
	width: 31px;
	margin-left: -1px;
	border-radius: 4px;

	margin-right: 3px;
	
	// background: $tempr;

	box-sizing: border-box;

	cursor: pointer;
}

.docEditor-issuePanel-header-openLinearBtn svg{
	width: 17px;
	height: 17px;
	fill: var(--text500);
	position: relative;
	top: 1px;

	opacity: 0.5;
}

.docEditor-issuePanel-header-openLinearBtn:hover{
	background: var(--250gray);
}

.docEditor-issuePanel-header-openLinearBtn:hover svg{
	opacity: 0.8;
}

//

.docEditor-issuePanel-header-openLinearBtn--avatar{
	// background: $tempr;
	margin-right: 6px;
	margin-left: 2px;
}

.docEditor-issuePanel-header-openLinearBtn-avatarContainer{
	width: 25px;
	height: 25px;
	background: $tempr;
	border-radius: 3px;
	pointer-events: none;
}

.docEditor-issuePanel-header-openLinearBtn-avatar{
	border-radius: 4px;
}


//

.docEditor-issuePanel-header-label{
	flex-grow: 1;
	flex-shrink: 1;

	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 15.75px;
	font-weight: $lato-semibold;
	//letter-spacing: 0.001em;
	color: var(--text400);
	opacity: 1;

	color: var(--text500);
	// color: $white;

	cursor: default;
	

	// white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  // opacity: 0.75;
}

span.docEditor-issuePanel-header-label-project-title-bold{	
	// font-weight: $lato-bold;
	// font-size: 16px;
}

span.docEditor-issuePanel-header-label-project-title-light{
	opacity: 0.5;
	font-weight: $lato-medium;
	margin-left: 2px;
}

.docEditor-issuePanel-header-label-project{
	display: flex;
}

.docEditor-issuePanel-header-label-primary-markerContainer{
	font-size: 15px;
	margin-right: 4px;
}

.docEditor-issuePanel-header-label-primary-markerContainer svg{
	width: 15px;
	height: 15px;
}

.docEditor-issuePanel-header-label-milestone{
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 100%;
	padding-right: 6px;
}

.docEditor-issuePanel-header-label-milestone-iconContainer{
	margin-right: 6px;
}

.docEditor-issuePanel-header-label-milestone-label{
	height: 100%;
	display: flex;
	align-items: center;

	font-size: 14.75px;
	font-weight: $lato-medium;
	opacity: 0.75;
	//letter-spacing: 0.001em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	


}



// .docEditor-issuePanel-header-openLinearBtn-hint{
// 	height: 100%;
// 	display: flex;
// 	align-items: center;

// 	box-sizing: border-box;
// 	padding-left: 6px;

// 	position: relative;
// 	// top: 1px;

// 	margin-left: auto;

// 	opacity: 0;
	
// 	transition: all 100ms;
// 	// transform: translateX(-4px);
// }

// .docEditor-issuePanel-header-openLinearBtn:hover
// .docEditor-issuePanel-header-openLinearBtn-hint{
// 	opacity: 0.5;

	
// 	transition: 50ms linear;

// 	transition-delay: 200ms;
// }


// .docEditor-issuePanel-header-openLinearBtn-hint-label{
// 	font-size: 14.5px;
// 	font-weight: $lato-regular;
// 	letter-spacing: 0.01em;
// }

.docEditor-issuePanel-header-openLinearBtn-hint-iconContainer{
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-issuePanel-header-openLinearBtn-hint-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.75;
	margin-top: 1px;
}




//

.docEditor-issuePanel-header-closeBtn{
	// background: $tempr;
	// width: 38px;
	height: 100%;		
	padding-left: 6px;
	padding-right: 3px;

	//border-left: 1px solid var(--sideBorder-header-messagePanel-dndButton);
}


.docEditor-issuePanel-header-closeBtn-inner{
	width: 33px;
	height: 30px;
	border-radius: 5px;
	// background: $tempb;
	
	display: flex;
	align-items: center;
	justify-content: center;

	

	//background: var(--253gray);
}

.docEditor-issuePanel-header-closeBtn:hover
.docEditor-issuePanel-header-closeBtn-inner{
	background: var(--250gray);
}

.docEditor-issuePanel-header-closeBtn svg {
	width: 20px;
	height: 20px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0.3;
	transition: opacity 50ms linear;
}

.docEditor-issuePanel-header-closeBtn:hover svg{
	opacity: 0.8;
}

// ISSUE PANEL COUNTER
.docEditor-issuePanel-header-issueCounter{
	display: flex;
	align-items: center;
	pointer-events: none;
}

.docEditor-issuePanel-header-issueCounter-counter{
	display: flex;
	height: 100%;
	align-items: center;
	margin-left: 8px;
}

.docEditor-issuePanel-header-issueCounter-counter svg{
	width: 14px !important;
	height: 14px !important;
}

.docEditor-issuePanel-header-issueCounter-counter-label{
	margin-left: 2px;
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 0.6;
}



//

.docEditor-issuePanel-list{
	width: 100%;
	overflow-y: scroll;
	padding-bottom: 48px;
}

// emptystate
.docEditor-issuePanel-list-emptyState{
	width: 100%;
	
	padding-top: 30vh;
	
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;

	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text600);
	opacity: 0.3;
}



//
// STATUS DIVIDER

.docEditor-issuePanel-statusDivider{
	width: 100%;
	height: 38px;
	display: flex;
	align-items: flex-end;
	padding-bottom: 7px;

	box-sizing: border-box;
	padding-left: 12px;

	color: var(--text500);

	font-size: 15px;
	font-weight: $lato-semibold;

	// background: var(--253gray);
	
	letter-spacing: 0.003em;
	opacity: 0.65;

	user-select: none;
	cursor: default;

	// border-bottom: 1px solid var(--line300);
}

//
// MILESTONE DIVIDER

.docEditor-issuePanel-milestoneDivider{
	width: 100%;
	
	height: 38px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-left: 14px;
	padding-right: 12px;
	padding-bottom: 8px;

	box-sizing: border-box;
	// padding-left: 12px;
	

	color: var(--text500);

	font-size: 15px;
	font-weight: $lato-semibold;
	// font-weight: $lato-medium;

	// background: var(--253gray);
	
	letter-spacing: 0.006em;
	opacity: 0.65;
	opacity: 0.85;

	user-select: none;
	cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	border-top: 1px solid var(--line300);

	margin-top: 12px;
}



[data-theme="dark"]{
	.docEditor-issuePanel-milestoneDivider{
		border-top: 1px solid var(--line200);
	}
} 




.docEditor-issuePanel
.docEditor-issuePanel-milestoneDivider:first-child{
	border-top: none;	
	margin-top: 0px;
}

.docEditor-issuePanel-milestoneDivider-iconContainer{
	margin-right: 6px;	
	position: relative;
	top: -0.07em;

	flex-grow: 0;
	flex-shrink: 0;
}

.docEditor-issuePanel-milestoneDivider-nameContainer{
	

	
}

.docEditor-issuePanel-milestoneDivider-name{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-issuePanel-milestoneDivider-totalIssueCount{
	flex-grow: 0;
	flex-shrink: 0;

	margin-left: 6px;
	
	font-size: 14px;
	font-weight: $lato-regular;

	opacity: 0.75;


}

.docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer{
	margin-left: auto;
	// height: 100%;
	// display: flex;
	// align-items: center;
}

.docEditor-issuePanel-milestoneDivider-collapseStateLabel{
	font-size: 14px;
	font-weight: $lato-regular;
	letter-spacing: 0.01em;
	color: var(--text600);
	opacity: 0;
	transition: opacity 50ms linear;
}

.docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer--hide
.docEditor-issuePanel-milestoneDivider-collapseStateLabel{
	opacity: 0;
}

.docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer--show
.docEditor-issuePanel-milestoneDivider-collapseStateLabel{
	opacity: 0.4;
}

.docEditor-issuePanel-milestoneDivider:hover .docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer--show .docEditor-issuePanel-milestoneDivider-collapseStateLabel,
.docEditor-issuePanel-milestoneDivider:hover .docEditor-issuePanel-milestoneDivider-collapseStateLabel{
	opacity: 0.8;	
	transition: opacity 50ms linear;
}


// PROJECT DIVIDER

.docEditor-issuePanel-projectDivider{
	width: 100%;
	
	height: 38px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-left: 14px;
	padding-right: 12px;
	padding-bottom: 5px;

	box-sizing: border-box;
	
	user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	border-top: 1px solid var(--line300);

	margin-top: 15px;
}

[data-theme="dark"]{
	.docEditor-issuePanel-projectDivider{
		border-top: 1px solid var(--line200);
	}
} 

.docEditor-issuePanel-projectDivider:first-child{
	border-top: none;
	margin-top: 0px;
}

.docEditor-issuePanel-projectDivider-markerContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 16px;

	margin-left: -1px;

	// background: $tempr;

	margin-right: 4px;
	position: relative;
	top: 2px;
}

.docEditor-issuePanel-projectDivider-markerContainer svg{
	width: 16px;
	height: 16px;
}

.docEditor-issuePanel-projectDivider-label{
	font-size: 15.75px;
	font-weight: $lato-bold;
	letter-spacing: 0.002em;
	color: var(--text500);

	// font-weight: $lato-medium;
}

.docEditor-issuePanel-projectDivider-label--none{
	opacity: 0.4;
	margin-left: 2px;
}
 


//


// empty state for milestone

.docEditor-issuePanel-issue,
.docEditor-issuePanel-newIssue
.docEditor-issuePanel-noIssuesInMilestoneLabel{
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: 100%;

	height: 34px;
	//box-shadow: 0px 0.5px 0px 0px var(--248gray);

	padding-left: 12px;
	padding-right: 8px;

	cursor: default;
}

.docEditor-issuePanel-issue{
	cursor: pointer;
}

.docEditor-issuePanel-issue:hover{
	background: var(--250gray);
}

.docEditor-issuePanel-list-lastIssueSpacer{
	height: 14px;
	width: calc(100% - 14px);
	margin-left: 14px;
	border-bottom: 1px solid var(--line200);
	opacity: 0.75;
}

//

.docEditor-issuePanel-issue-assigneeIDAvatarContainer{
	flex-grow: 0;
	flex-shrink: 0;

	width: 22px;
	height: 22px;
	padding-right: 4px;
}

.docEditor-issuePanel-issue-assigneeIDAvatar{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

//

.docEditor-issuePanel-issue-statusIconContainer{
	padding-left: 3px;
	padding-right: 3px;

	flex-grow: 0;
	flex-shrink: 0;
}


.docEditor-issuePanel-issue-tooltip{
	width: 500px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	margin-top: -24px; // seems to work better than offset
}



//

.docEditor-issuePanel-issue-label,
.docEditor-issuePanel-newIssue-label,
.docEditor-issuePanel-noIssuesInMilestoneLabel{
	box-sizing: border-box;
	padding-left: 4px;

	// font-size: 16.75px;		
	// font-weight: $lato-regular;
	// letter-spacing: 0.005em;	
	// color: var(--text500);
	// line-height: 1.45;

	font-weight: $lato-regular;
	font-size: 15.25px;
	color: var(--text500);
	letter-spacing: 0.003em;

	font-size: 15px;

	line-height: 1.4;

//	cursor: default;
	word-break: break-word; // I think this is right

	margin-right: auto;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-issuePanel-issue--status--completed{
	opacity: 0.75;
}

.docEditor-issuePanel-noIssuesInMilestoneLabel{
	opacity: 0.5;
	padding-left: 33.5px; // bit  messy
}

//

.docEditor-issuePanel-issue-identifier{
	font-size: 12.5px;
	font-weight: $lato-regular;
	text-transform: uppercase;
	letter-spacing: 0.008em;
	color: var(--text100);

	box-sizing: border-box;
	padding-right: 6px;
	padding-left: 6px;

	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	cursor: pointer;	

	flex-grow: 0;
	flex-shrink: 0;
}

.docEditor-issuePanel-issue-identifier:hover{
	color: var(--text500);
}

.tooltip--docEditorIssuePanelIssue[data-side="top"]{
	margin-top: 5px;
}

//

.docEditor-issuePanel-issue-priorityContainer{
	height: 100%;
	display: flex;
	align-items: center;
	padding-right: 3px;
	margin-right: 3px;
	padding-left: 3px;
}

.docEditor-issuePanel-issue-priorityContainer--user{
	padding-left: 0px;
	padding-right: 0px;
	margin-right: 1px;
}

//

.docEditor-issuePanel-newIssue{
	opacity: 0.5;
}

.docEditor-issuePanel-newIssue-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -3px;
	margin-right: 0px;
}

.docEditor-issuePanel-newIssue-iconContainer svg{
	width: 16px;
	height: 16px;

	fill: var(--text600);
	opacity: 0.5;
}