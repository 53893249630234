.dialogOverlay{
	background: rgba(0,0,0,0.5);

	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 2000;

}

.dialogOverlay--invisible{
	background: none;
}

.dialogOverlay--recordingPlaybackModal{
	background: rgba(0,0,0,0.75);
}


.dialog{
	width: 500px;
	height: 500px;

	background: var(--bg-dialog);

	z-index: 5000;
}