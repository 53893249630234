
//
// TOOLTIPS
:root{	
	--shadowTooltip: 0px 1px 2px 0px rgba(0,0,0,0.12);
	--textTooltip: rgb(255,255,255);
	--borderColorTooltip: rgb(35,33,44); // maybe refactor
	--bgTooltip: rgb(40,38,54); //maybe refactor

	//--bgKeyboardShortcutTooltip: rgb(65,58,74); //maybe refactor
	--bgKeyboardShortcutTooltip: rgb(70,64,80); //maybe refactor
	--textKeyboardShortcutTooltip: rgb(255,255,255); // refactor
	//--bgKeyboardShortcutTooltip: hsl(213, 100%, 50%); //blue
	//--bgKeyboardShortcutTooltip: hsl(276, 46%, 60%); //purple
	//--bgKeyboardShortcutTooltip: hsl(24, 100%, 67%); //orange 
	//--bgKeyboardShortcutTooltip: hsl(163, 61%, 44%)
	
}

[data-theme="dark"]{
	--bgTooltip: rgb(60,58,70); //maybe refactor	
	--shadowTooltip: none;
	--borderColorTooltip: rgb(60,58,70); // maybe refactor

	--textTooltip: rgb(250,250,250);

	--bgKeyboardShortcutTooltip: rgb(100,96,104); //maybe refactor
	--textKeyboardShortcutTooltip: rgb(255,255,255); // refactor
}
