.doc-zoomedFigmaModal-closeBtnOuterContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	height: $headerHeight-zoomedFigmaModal;
	width: $closeBtnContainerWidth-zoomedFigmaModal;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	z-index: 100;

}


.doc-zoomedFigmaModal-header{
	width: 100%;
	height: 100%;
	// background: $tempb;

	

	display: flex;

	box-sizing: border-box;
	padding-bottom: 1px; // calcs don't account for border on modal
	padding-right: 6px;

	// cursor: pointer;

	position: relative;
	z-index: 80;
}


.doc-zoomedFigmaModal-headerContainer--panelClosed
.doc-zoomedFigmaModal-header{
	padding-right: $closeBtnContainerWidth-zoomedFigmaModal;
}

.doc-zoomedFigmaModal-headerContainer--panelOpen
.doc-zoomedFigmaModal-header{
	padding-right: 6px;
}


.doc-zoomedFigmaModal-footer{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	z-index: 80;
}



$width-centerContainer-zoomedFigmaModalHeader: 300px;
$width-centerContainer-zoomedFigmaModalFooter: 600px;

.doc-zoomedFigmaModal-header-leftContainer,
.doc-zoomedFigmaModal-header-centerContainer,
.doc-zoomedFigmaModal-header-rightContainer,
.doc-zoomedFigmaModal-footer-leftContainer,
.doc-zoomedFigmaModal-footer-centerContainer,
.doc-zoomedFigmaModal-footer-rightContainer{
	display: flex;
	align-items: center;
}


.doc-zoomedFigmaModal-header-leftContainer,
.doc-zoomedFigmaModal-header-rightContainer{
	width: calc(50% - (0.5 * $width-centerContainer-zoomedFigmaModalHeader));
	flex-grow: 0;
	flex-shrink: 0;
	
	box-sizing: border-box;	
}

.doc-zoomedFigmaModal-header-leftContainer{
	padding-left: 16px;
}
.doc-zoomedFigmaModal-footer-leftContainer{
	padding-left: 8px;
}

.doc-zoomedFigmaModal-header-rightContainer,
.doc-zoomedFigmaModal-footer-rightContainer{
	justify-content: flex-end;
}


.doc-zoomedFigmaModal-footer-leftContainer,
.doc-zoomedFigmaModal-footer-rightContainer{
	width: calc(50% - (0.5 * #{$width-centerContainer-zoomedFigmaModalFooter}));
	flex-grow: 0;
	flex-shrink: 0;
	
	box-sizing: border-box;	
}

//

.doc-zoomedFigmaModal-header-leftContainer--fade,
.doc-zoomedFigmaModal-header-rightContainer--fade,
.doc-zoomedFigmaModal-footer-leftContainer--show,
.doc-zoomedFigmaModal-footer-rightContainer--show{
	opacity: 1;
	transition: all 50ms ease-in-out;
}

.doc-zoomedFigmaModal-header-leftContainer--fade,
.doc-zoomedFigmaModal-header-rightContainer--fade,
.doc-zoomedFigmaModal-footer-leftContainer--fade,
.doc-zoomedFigmaModal-footer-rightContainer--fade{
	opacity: 0.4;
	transition: all 200ms ease-in-out;

	pointer-events: none;
}

//

.doc-zoomedFigmaModal-footer-centerContainer{
	// background: $tempr;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-zoomedFigmaModal-footer-activeCropBtnContainer,
.doc-zoomedFigmaModal-footer-effectsToolbarOuterContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-zoomedFigmaModal-footer-effectsToolbarOuterContainer
.doc-figma-effectsToolbarContainer{
	margin-top: 0px;
}

//
// DONE BUTTON
.doc-zoomedFigmaModal-footer-activeCropBtn{
	width: 200px;
	height: 34px;
	margin-top: -12px;
	background: var(--primary500);
	border: 1px solid var(--primary400);
	color: $white;

	font-size: 15px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;

	border-radius: 3px;
}

.doc-zoomedFigmaModal-footer-activeCropBtn:hover{
	background: var(--primary400);
}

// .doc-zoomedFigmaModal-footer-activeCropBtn{
// 	width: 200px;
// 	height: 38px;

// 	margin-top: -12px;

// 	font-size: 15.75px;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.008em;

// 	color: $white;

// 	border-radius: 5px;

// 	background: var(--primary600);
// 	border: 1px solid var(--primary700);
// 	border-top: 1px solid var(--primary500);
// }

.doc-zoomedFigmaModal-footer-activeCropBtnContainer--show{
	opacity: 1;
	transition: all 100ms linear;
}

.doc-zoomedFigmaModal-footer-activeCropBtnContainer--hide{
	opacity: 0;
	transition: all 0ms linear;
	pointer-events: none;
}


//

.doc-zoomedFigmaModal-footer-centerContainer{
	width: $width-centerContainer-zoomedFigmaModalFooter;
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempr;
}


.doc-zoomedFigmaModal-header-centerContainer{
	width: $width-centerContainer-zoomedFigmaModalHeader;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}



.doc-zoomedFigmaModal-footer-rightContainer{
	padding-right: 14px;
}

//

.doc-zoomedFigmaModal-footer-commentInputContainer{
	position: relative;
	z-index: 10;
}

.doc-zoomedFigmaModal-footer-commentInputContainer--show{
	opacity: 1;
	transition: opacity 200ms linear;
}

.doc-zoomedFigmaModal-footer-commentInputContainer--hide{
	opacity: 0;
	transition: opacity 100ms linear;
	pointer-events: none;
}


//
// STOP CROP BUTTON

.doc-zoomedFigmaModal-header--closeCropBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	z-index: 20;
	width: calc(100% - #{$closeBtnContainerWidth-zoomedFigmaModal});
}



//
// CUSTOM BUTTON STUFF

.doc-zoomedFigmaModal-headerContainer .doc-zoomedImageModal-btn,
.doc-zoomedFigmaModal-footerContainer .doc-zoomedImageModal-btn{
	height: 32px;
}

.doc-zoomedImageModal-btn--figmaTitle{
	padding-right: 12px;	
}

.doc-zoomedImageModal-btn--figmaTitle
.doc-zoomedImageModal-btn-iconContainer{
	//background: rgba(255,255,255,0.08);

	height: 20px;
	width: 16px;

	box-sizing: border-box;
	padding-left: 0.5px;

	border-radius: 4px;
	margin-right: 0px;
}

.doc-zoomedImageModal-btn--figmaTitle
.doc-zoomedImageModal-btn-iconContainer svg{
	height: 14px;
	width: 14px;
}

.doc-zoomedImageModal-btn--figmaTitle
.doc-zoomedImageModal-btn-label{
	opacity: 0.9;
	font-weight: $lato-semibold;
}

//

// .doc-zoomedImageModal-btn--name--thread
// .doc-zoomedImageModal-btn-iconContainer{
// 	margin-right: 2px;
// }

//
// 



//
// STATUS

