
//
// VIDEO

$innerMargin-video-message: 10px;
$bottomMargin-video-message: calc(#{$innerMargin-video-message} + 0px); // it's off for some reason, maybe a rounding error
//$bgColor-participant-video-ui: rgba(10,0,10,0.6);
$bgColor-participant-video-ui: rgba(10,0,10,0.3);

.message-content-videoContainer{
	width: 100%;
	height: 100%;

	margin-top: 5px;	
}

.message-content-video{
	width: 100%;
	height: 100%;

	position: relative;
}

.message-content-video-ui{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 50;

	// background: $tempr;
}

// PLAY

button.message-content-video-ui-playBtn{
	position: absolute;
	bottom: $bottomMargin-video-message;
	left: $innerMargin-video-message;
	
	width: 62px; 
	height: 42px;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;	

	border-radius: 8px;
}

button.message-content-video-ui-playBtn svg{
	width: 20px;
	height: 20px;
	fill: $white;
	margin-right: -4px; // optical centering
}


button.message-content-video-ui-playBtn:hover svg {
	transform: scale(1.05);
}


.message-content-video .message-content-video-ui-playBtn{
	opacity: 0.95;
	transition: opacity 50ms;
}

.message-content-video .message-content-video-ui-restartBtn{
	transition: opacity 200ms;
}




// DURATION

.message-content-video-ui-duration{
	position: absolute;
	bottom: $bottomMargin-video-message;
	right: $innerMargin-video-message;

	padding-right: 6px;
	padding-left: 6px;

	height: 24px;

	font-size: 15px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.025em;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	border-radius: 5px;

	pointer-events: none;
}


.message-content-video-ui-duration--show{
	opacity: 0.95;
	transition: opacity 150ms linear;
}

.message-content-video-ui-duration--hide{
	opacity: 0;
	transition: opacity 400ms linear;
}



//

button.message-content-video-ui-playBtn,
.message-content-video-ui-duration{
	

	background: $bgColor-participant-video-ui;
	backdrop-filter: blur(10px);

	z-index: 10;
}

//
//

.message-content-video:hover .message-content-video-ui-playBtn,
.message-content-video:hover .message-content-video-ui-duration--show{
	opacity: 1;
	transition: opacity 50ms;
}

.message-content-video 				button.message-content-video-ui-playBtn--hide,
.message-content-video:hover 	button.message-content-video-ui-playBtn--hide{
	opacity: 0;
	transition: opacity 200ms;
	pointer-events: none;
}

//


.message-content-video-ui-seekBar{
	position: absolute;
	bottom: $bottomMargin-video-message;
	// left: 50%;
	// transform: translateX(-50%);

	// // width: 100px;

	// width: 180px;
	left: 20px;
	right: 20px;

	border-radius: 8px;

	

	height: 24px;

	display: flex;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	background: $bgColor-participant-video-ui;
	backdrop-filter: blur(10px);

	z-index: 10;

	cursor: pointer;

}

.message-content-video-ui-seekBar--show{
	opacity: 1;
	transition: opacity 50ms linear;
}

.message-content-video-ui-seekBar--hide{
	opacity: 0;
	transition-delay: 500ms;
	transition: opacity 400ms linear;
}

.message-content-video-ui-seekBar-seekBarContainer{
	height: 32px;
	// background: $tempr;
	margin-top: -4px;

	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

/// SLIDER

.message-content-video-ui-seekBar-seekbar{
	height: 100%;
}

.message-content-video-ui-seekBar-seekbar-track{
	height: 5px;
	margin-top: 13px;	
	// background: $green;
}

.message-content-video-ui-seekBar-seekbar-track-0{
	background: $white;
	border-top-left-radius: 2.5px;
	border-bottom-left-radius: 2.5px;

	z-index: 10;
}

.message-content-video-ui-seekBar-seekbar-track-1{	
	border-radius: 2.5px;
	background: rgb(86,99,114);
}

.message-content-video-ui-seekBar-seekbar-thumb{
	display: none;
}

.message-content-video-ui-bgPlayPauseClick{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	
	background: $invisible;	
	z-index: 1;

	cursor: pointer;
}

.message-content-video-video{
	// border-radius: 5px;
	// overflow: hidden;
}