$height-doc-inlineIssue: 24px;

.doc-para-linearIssueOuterContainer--inline,
.doc-para-linearIssueOuterContainer--block{
	//display: inline-block;
	
	display: inline-block;
	
	white-space: pre-wrap; 
	word-break: break-word;

	// /background: none;
	// background: $tempr;

	

	// margin-right: 1px;
	// margin-left: 1px;
	position: relative;
}

// .doc-inlineLinearIssue{
// 	border-radius: 2px;
// }

// .doc-inlineLinearIssue:hover,
// .doc-inlineLinearIssue[data-state="open"],
// .doc-inlineLinearIssue[data-state="open"]:hover,
// .doc-para-linearIssueOuterContainer.ProseMirror-selectednode .doc-inlineLinearIssue{
// 	background: var(--bg-docEditor);
// 	background: var(--250gray);	
// 	transition: all 0ms linear;
// 	transition-delay: 100ms;
// 	box-shadow: 0px 0px 0px 3px var(--250gray);
// 	z-index: 20;
// }

.doc-inlineLinearIssue-linkBtn{
	border-radius: 2px;

	white-space: nowrap;
}



.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection .doc-inlineLinearIssue,
.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection .doc-inlineLinearIssue:hover{
	background: var(--textSelectionBlue);
	box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);
}	

.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection.ProseMirror-selectednode .doc-inlineLinearIssue,
.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection.ProseMirror-selectednode .doc-inlineLinearIssue:hover{
	background: none;
	box-shadow: none;
}	

.doc-para-linearIssueOuterContainer.ProseMirror-nodeSearchResult .doc-inlineLinearIssue,
.doc-para-linearIssueOuterContainer.ProseMirror-nodeSearchResult .doc-inlineLinearIssue:hover{
	background: var(--searchResultBG);
	box-shadow: 0px 0px 0px 2px var(--searchResultBG);
	border-radius: 1px;
}	

.doc-para-linearIssueOuterContainer.ProseMirror-nodeSearchResult--active .doc-inlineLinearIssue,
.doc-para-linearIssueOuterContainer.ProseMirror-nodeSearchResult--active .doc-inlineLinearIssue:hover{
	animation: activeResultAnimation 220ms ease-in forwards;
	border-radius: 1px;
}	



.doc-inlineLinearIssue-linkBtn:hover,
.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn,
.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn:hover,
.doc-para-linearIssueOuterContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-inlineLinearIssue-linkBtn,
.doc-para-linearIssueOuterContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-inlineLinearIssue-linkBtn:hover{	
	background: var(--250grayTransparent);	
	box-shadow: 0px 0px 0px 2px var(--250gray);
	z-index: 20;
}

.doc-inlineLinearIssue-linkBtn:hover{
	transition: all 0ms linear;
	transition-delay: 100ms;
}


.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection .doc-inlineLinearIssue,
.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection .doc-inlineLinearIssue:hover{
	background: var(--textSelectionBlue);
	box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);
}	




[data-theme="dark"]{
	.doc-inlineLinearIssue-linkBtn:hover,
	.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn,
	.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn:hover,
	.doc-para-linearIssueOuterContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-inlineLinearIssue-linkBtn,
	.doc-para-linearIssueOuterContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-inlineLinearIssue-linkBtn:hover{
		background: var(--246gray);
		box-shadow: 0px 0px 0px 3px var(--246gray);
	}
}


///


.doc-inlineLinearIssue-linkBtn{
	// background: $tempr;
	cursor: pointer;

}

// ASSIGNEE
.doc-inlineLinearIssue-assignee{
	width: 18px;
	height: 18px;

	margin-left: 0.2em;
	margin-right: 0.2em;
}

.doc-inlineLinearIssue-assignee img{
	
	display: inline-block;

	width: 18px;
	height: 18px;

	vertical-align: -0.20em;
	
	border-radius: 3px;
}

.doc-inlineLinearIssue-assignee svg{
	position: absolute;
	width: 18px;
	height: 18px;

	left: 0.2em;
	top: 0.1em;
}

.doc-inlineLinearIssue-assignee svg #box{
	fill: var(--240gray);
}

.doc-inlineLinearIssue-assignee svg #arrow{
	fill: var(--text300);	
	//opacity: 0.8;
}


.doc-inlineLinearIssue-linkBtn
.doc-inlineLinearIssue-assignee img{
	opacity: 1;
	transition: opacity 0ms linear;
}

.doc-inlineLinearIssue-linkBtn
.doc-inlineLinearIssue-assignee svg{
	opacity: 0;	
	transition: opacity 0ms linear;
}

.doc-inlineLinearIssue-linkBtn:hover
.doc-inlineLinearIssue-assignee img{
	opacity: 0;
	transition: opacity 0ms linear;
	transition-delay: 100ms;
}

.doc-inlineLinearIssue-linkBtn:hover
.doc-inlineLinearIssue-assignee svg{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 100ms;
}


// IDENTIFIER

.doc-inlineLinearIssue-identifer{
	font-size: 15px;
	
	color: var(--text500);
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	opacity: 0.9;

	margin-left: 0.2em;
	margin-right: 0.3em;

	white-space: nowrap;
}

.doc-inlineLinearIssueContainer:hover
.doc-inlineLinearIssue-identifer{
	opacity: 1;
}

span.doc-span--style--light .doc-inlineLinearIssue-identifer{
	color: var(--200);
}

.doc-inlineLinearIssue-identifer--light{
	opacity: 0.55;
}




// STATUS
.doc-inlineLinearIssue-statusContainer{
	display: inline-block;

	vertical-align: -0.3em;
	//margin-top: -0.3em;

	margin-left: 0.1em;
	
	position: relative;
	z-index: 5;
	margin-right: 0.1em;

	transform: scale(0.9);
	transform-origin: 50% 50%;

	width: 19px;
	height: 19px;

	// background: $tempr;

}

.doc-inlineLinearIssue-statusContainer
.linearIssueWorkflowStateIconContainer{
	position: absolute;
	
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;
}

.doc-inlineLinearIssue-statusContainer
.linearIssueWorkflowStateIcon{
	
}

// PRIORITY

.doc-inlineLinearIssue
.linearInlineIssuePriorityIcon{
	//vertical-align: -0.02em;
	position: relative;
	top: 0.015em;
	margin-left: 0.15em;
	margin-right: 0.3em;
}



// LABEL

.doc-inlineLinearIssue-title{

	white-space: pre-wrap; 
	word-break: break-word;

	margin-right: 0.2em;
	margin-left: 0.2em;

  cursor: text;	
	
	font-size: 16.5px;	

	font-weight: $lato-regular;
	letter-spacing: 0.005em;

	color: var(--text500);
	line-height: 1.45;

	border-bottom: 1px solid var(--220gray); 	
}

span.doc-span--style--light .doc-inlineLinearIssue-title{
	color: var(--200);
}

.doc-inlineLinearIssue:hover .doc-inlineLinearIssue-title,
.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn .doc-inlineLinearIssue-title,
.doc-inlineLinearIssue[data-state="open"] .doc-inlineLinearIssue-linkBtn:hover .doc-inlineLinearIssue-title,
.doc-para-linearIssueOuterContainer.ProseMirror-selectednode .doc-inlineLinearIssue-linkBtn .doc-inlineLinearIssue-title{
	border-bottom: 1px solid var(--210gray); 	
}

.doc-para-linearIssueOuterContainer.ProseMirror-nodeInSelection .doc-inlineLinearIssue-title{
	border-bottom: 1px solid rgba(0,0,0,0.2);	
}

.doc-para-linearIssueOuterContainer.doc-span--comment--active .doc-inlineLinearIssue-title{
	border-bottom: 1px solid rgba(255,212,0,0.9);	
}


//

.doc-inlineLinearIssue-popoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none !important;
}


//
//



// .doc-para-linearIssueOuterContainer--inline{
// 	display: inline-block;
	
// 	white-space: pre-wrap; 
// 	word-break: break-word;

// 	background: none;
// 	background: $tempr;

// 	border-radius: 4px;

// 	margin-right: 1px;
// 	margin-left: 1px;

// 	cursor: default;
// }



// .doc-para-linearIssueOuterContainer--inline:hover{
// 	background: var(--bg-docEditor);
// 	background: var(--250gray);	
// 	z-index: 20;
// }

// .doc-para-linearIssueOuterContainer.ProseMirror-selectednode{
// 	background: var(--bg-docEditor);
// 	background: var(--250gray);	
// }

// button.doc-inlineLinearIssue{
// 	display: inline-block;
// }

// // ASSIGNEE
// .doc-inlineLinearIssue-assigneeContainer{
// 	position: relative;
// }

// .doc-inlineLinearIssue-assignee{
// 	display: inline-block;
// 	width: 18px;
// 	height: 18px;
// }

// .doc-inlineLinearIssue-assignee img{
	
// 	display: inline-block;

// 	width: 18px;
// 	height: 18px;

// 	vertical-align: -0.15em;
// 	margin-left: 0.2em;
	
// 	border-radius: 3px;
// }

// .doc-inlineLinearIssue-assignee--unassigned{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .doc-inlineLinearIssue-assignee--unassigned svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text500);
// 	opacity: 0.5;
// }

// // ICON SHOWS ON HOVER
// .doc-inlineLinearIssue-hoverIconContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .doc-inlineLinearIssue-hoverIconContainer svg{
// 	width: 15px;
// 	height: 15px;
// 	fill: var(--text500);
// 	margin-left: 1px;
// }

// //

// .doc-inlineLinearIssueContainer
// .doc-inlineLinearIssue-assignee{
// 	opacity: 1;
// 	transition: opacity 0ms linear;
// }

// .doc-inlineLinearIssueContainer
// .doc-inlineLinearIssue-hoverIconContainer{
// 	opacity: 0;	
// 	transition: opacity 0ms linear;
// }

// .doc-inlineLinearIssueContainer:hover
// .doc-inlineLinearIssue-assignee{
// 	opacity: 0;
// 	transition: opacity 0ms linear;
// 	transition-delay: 50ms;
// }

// .doc-inlineLinearIssueContainer:hover
// .doc-inlineLinearIssue-hoverIconContainer{
// 	opacity: 1;
// 	transition: opacity 0ms linear;
// 	transition-delay: 50ms;
// }



// // IDENTIFIER

// .doc-inlineLinearIssue-identifer{
// 	font-size: 15px;
// 	margin-right: 3px;
// 	color: var(--text500);
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.01em;
// 	opacity: 0.8;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .doc-inlineLinearIssueContainer:hover
// .doc-inlineLinearIssue-identifer{
// 	opacity: 1;
// }

// .doc-inlineLinearIssue-identifer--light{
// 	opacity: 0.6;
// }


// // STATUS

// .doc-inlineLinearIssue-statusContainer{
// 	transform: scale(0.9);
// }
// .doc-inlineLinearIssue
// .linearIssueWorkflowStateIcon--started{
// 	background: $white; // not sure about this
// }


// // LABEL
// .doc-inlineLinearIssue-titleContainer{
// 	padding-left: 1px;

//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .doc-inlineLinearIssue-title{

// 	white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

// 	position: relative;
	
// 	flex-grow: 1;
// 	flex-shrink: 1;
	
// 	font-size: 16.5px;	

// 	font-weight: $lato-regular;
// 	letter-spacing: 0.005em;

// 	color: var(--text500);
// 	line-height: 1.45;

// 	position: relative;
// 	margin-block-start: 0em;
// 	margin-block-end: 0em;

// 	//min-height: 25px; (I'm not sure need this)
// 	//margin-bottom: 15px; not sure about this

	
// 	-webkit-font-smoothing: antialiased;
// 	-moz-font-smoothing: antialiased;
// 	-o-font-smoothing: antialiased;

// 	box-sizing: border-box;

// 	//border-bottom: 1px solid var(--220gray); 	


// }

// .doc-inlineLinearIssue:hover
// .doc-para-internalLink-title{
// 	border-bottom: 1px solid var(--210gray); 	
// }

// .doc-inlineLinearIssue-titleEndSpacer{
// 	width: 8px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// //

// .doc-inlineLinearIssue-popoverTrigger{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	pointer-events: none !important;
// }