.moveDocDialogContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

}


.moveDocDialogContainer:focus{
	outline: none;
}

.moveDocDialogContainer--hidden,
.moveDocDialogContainer--hidden *{
	pointer-events: none !important;
}

//

.moveDocDialog{
	width: 520px;
	min-height: 530px;

	box-sizing: border-box;
	padding-top: 36px;
	padding-bottom: 24px;
	padding-left: 48px;
	padding-right: 48px;

	border-radius: 12px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;
}

//
// LIST

.moveDocDialog-header{
	font-size: 26px;
	font-weight: $lato-bold;
}

// SEARCH

.moveDocDialog-searchContainer{
	width: 100%;
	height: 30px;
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
}

.moveDocDialog-search-input{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

// MOVE ITEM ROW

.moveDocDialog-row{
	height: 30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.moveDocDialog-row:hover{
	background: var(--246gray);
}

//
// CLOSE BUTTON
.moveDocDialog-closeBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	
	width: 56px;
	height: 56px;

	box-sizing: border-box;

	display: flex;
	align-items: flex-end;
	justify-content: start;

	padding-bottom: 12px;
	padding-left: 12px;
}

.moveDocDialog-closeBtn svg{
	width: 24px;
	height: 24px;
	fill: var(--text600);
	opacity: 0.25;

	transition: all 50ms linear;
}

.moveDocDialog-closeBtn:hover svg{
	opacity: 0.75;
	transform: scale(1.05);

	transition: all 50ms linear;
}





// @import 'moveDocDialog-Header';


//
//
