//
// PROJECT LEAD
.tooltip--projectLead,
.roadmapsProjectRow-statusContainer{
  border-radius: 5px;

}

.tooltip--projectLead-avatarContainer{
  width: 32px;
  height: 32px;
  padding-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  //margin-left: -1px;
}

.tooltip--projectLead-avatar{
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
}

.tooltip--projectLead-rightContainer{
  height: 100%;
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tooltip--projectLead-displayName{
  font-size: 14.5px;
  font-weight: $lato-semibold;
  color: var(--text600);
  letter-spacing: 0.008em;
  margin-bottom: 1px;
  margin-top: -0.5px;
}


.tooltip--projectLead-attribute,
.tooltip--projectStatus-attribute{
  font-size: 13px;
  font-weight: $lato-regular;
  color: var(--text500);
  opacity: 0.7;
  letter-spacing: 0.008em;
}


//

.tooltip--projectStatus{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 3px;
}

.tooltip--projectStatus-title{
  font-size: 13.25px;
  opacity: 1;
  font-weight: $lato-medium;
  margin-bottom: 1px;
  text-transform: capitalize;
}