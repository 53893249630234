




.recordingPlaybackModal-media-playbackControls-seekBarContainer{
	// position: absolute;
	// // top: -9px;
	// left: 0px;
	// right: 0px;
	// height: 24px;
	// //background: $tempb;

	cursor: pointer;

	z-index: 50;
}

.recordingPlaybackModal-media-playbackControls-seekBarInnerContainer{
	width: 100%;
	height: 100%;
	position: relative;
}

.recordingPlaybackModal-media-playbackControls-seekBar{
	height: 100%;
	// background: $green;
}

.recordingPlaybackModal-media-playbackControls-seekBar-track{
	height: 10px;
	margin-top: 4px;
}

.recordingPlaybackModal-media-playbackControls-seekBar-track-0{
	//background: rgb(139,129,255);
	//background: #81b0ff;
	background: rgb(255,255,255,0.5);
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.recordingPlaybackModal-media-playbackControls-seekBar-track-1{	
	border-radius: 3px;
	background: rgba(75,75,89,0.5);
}

.recordingPlaybackModal-media-playbackControls-seekBar-thumb{
	
	width: 9px;
	height: 24px;
	background: $white;
	border-radius: 3px;

	// opacity: 0.5;
	
	margin-top: -4px;
	//margin-left: -5px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 0px 2px 0px rgba(0,0,0,0.12),
		0px 0px 0px 1px rgba(0,0,0,0.12),

		;	
}

.recordingPlaybackModal-media-playbackControls-seekBar-thumb:focus{
	outline: none;
}

.recordingPlaybackModal-media-playbackControls-seekBar-thumb-thumb{
	position: relative;
}

.recordingPlaybackModal-media-playbackControls-seekBar-thumb-thumb-line{
	width: 1px;
	height: 66px;
	height: 70px; // find less hacky way
	position: absolute;
	top: 12px;
	left: 4px;
	background: rgb(255,255,255,0.5);


}



//
// HOVER PREVIEW

// SEEK BAR PREVIEW

.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview{
	position: absolute;

	background: $white;
	
	pointer-events: none;
	border-radius: 2px;

	outline: 1px solid rgba(2,2,5,0.2);
	border: none;
	overflow: hidden;
}


.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-storyboardImageOuterContainer{
	width: 100%;
	height: 100%;
	border-radius: 2px;

	overflow: hidden;
}


.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-storyboardImage-image{
	transform-origin: 0% 0%;
}

.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-storyboardImage-image{
	background-image: url('https://res.cloudinary.com/yarn/image/upload/v1667235735/dummydata/examplestoryboard_a1ttwy.png');
	background-position: 100px 200px;
	background-repeat: 'no repeat';
}

///

.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-timestampContainer{
	position: absolute;
	bottom: 2px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-timestamp{
	width: 44px;
	height: 20px;

	background: rgb(60,60,70);
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: $white;
	font-size: 13px;
	font-weight: $lato-semibold;

	letter-spacing: 0.005em;
}

.recordingPlaybackModal-media-playbackControls-seekBar-hoverMarker{
	position: absolute;
	top: 0px;

	width: 2px;
	height: 6px;
	background: $white;
	border-radius: 0px;
	transform: translateX(-1px);

	pointer-events: none;
}









