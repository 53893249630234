

//

.docEditor-meetingTemplateSelectionContainer{	
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.docEditor-meetingTemplateSelection{
	height: 100%;

	display: flex;
	flex-direction: column;

	// min-height: 400px;
	
	background: var(--bg-docEditor); // temporary cover up
	// background: $tempr;

	z-index: 50;
}

button.docEditor-meetingTemplate-button{
	 width: 460px;
	 width: 100%;
	 // width: calc(100% - 80px);
	 height: 34px;	 
	 
	 border-radius: 4px;	 

	 padding-left: 12px;	 
	 box-sizing: border-box;
	 padding-right: 12px;

	 justify-content: flex-start;
}

.docEditor-meetingTemplate-button-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
	margin-left: -4px; // optical center icons
}

.docEditor-meetingTemplate-button-iconContainer svg{
	width: 16px;
	height: 16px;	
}

//


button.docEditor-meetingTemplate-button
.docEditor-meetingTemplate-button-label{
	// same as body – in doc.scss
	font-weight: $lato-medium;
	opacity: 0.9;
}

//


.docEditor-meetingTemplate-button-enterIcon{
	display: flex;
	margin-left: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingTemplate-button-enterIcon svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.8;
}


.docEditor-meetingTemplate-button--active{
	background: var(--248gray);
}

.docEditor-meetingTemplate-button--inactive{
	opacity: 0.8;
}


//

.docEditor-meetingTemplate-button
.docEditor-meetingTemplate-button-iconContainer
.meetingTypeIconContainer svg #bg{	
	fill: var(--text500) !important;
	opacity: 0.6; 
}


//
//

.docEditor-meetingTemplateSelection-vSpacer{
	min-height: 10px;

	flex-grow: 1;
	flex-shrink: 1;
}


.docEditor-meetingTemplateSelection-addLinkBtnContainer{
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingTemplateSelection-addLinkBtn{
	// background: $tempr;

	padding-left: 14px;
	padding-right: 14px;

	padding-top: 10px;
	padding-bottom: 10px;

	border-radius: 5px;

	margin-bottom: 14px;
	cursor: pointer;

	opacity: 0.8;
	transition: opacity 50ms linear;
}

.docEditor-meetingTemplateSelection-addLinkBtn[data-state='checked']{
	opacity: 1;
}

.docEditor-meetingTemplateSelection-addLinkBtn:hover[data-state='unchecked']{
	opacity: 0.9;
}

.docEditor-meetingTemplateSelection-addLinkBtn-checkBoxContainer{
	margin-right: 7px;
	position: relative;
	top: -1px;
}

.docEditor-meetingTemplateSelection-addLinkBtn-checkBoxContainer svg{
	position: relative;
	top: 1px; // todo fix
}

.docEditor-meetingTemplateSelection-addLinkBtn-label{
	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	opacity: 0.8;
}