//
// MENU CONTAINER

@import 'Doc-FAQ'; 

// LAYOUT STUFF
.selection-span{

}

.comment-active, .comment{	
	padding-left: 0.15em;
	margin-left: -0.15em;
	padding-right: 0.15em;
	margin-right: -0.15em;
	padding-top: 0.089em;
	padding-bottom: 0.18em;
	border-radius: 3px;
}

.comment{
	cursor: pointer;
}

// .doc .comment:nth-of-type(1){
// 	background: red !important;
// }

// This is when you are currently writing a comment
span.selection-span{
	// background: transparentize($blue, 0.9);
	//background: #D3E2FF; // opaque version of transparentized blue
	//background-color: var(--textSelectionBlue); // similar to google docs	
	// background-color: $tempr;
	// color: var(--text600);
}

// span.selection-span::selection{
// 	background-color: var(--textSelectionBlue);		
// }

.doc::selection, .doc *::selection,
.messageContainer ::selection, .messageContainer  *::selection{
	background-color: var(--textSelectionBlue);		
}

span.comment{
	// background: transparentize($blue, 0.85);
	// background: #F0E4F8;
	background-color: lighten(#FDE9AE, 10%); // similar to google docs
	background: rgba(255,212,0,0.12);
	color: darken(#FDE9AE, 78%);
	border-bottom: 1px solid #ffd400; // notion
	padding-bottom: 1px;
}

// //
// // // DISCUSSION 
// span.doc-span--discussion{
// 	background: rgba(240,228,248,0.34);
// 	color: darken(rgb(240,228,248), 74%);
// 	border-bottom: 1px solid darken(rgb(240,228,248), 58%); // notion

// 	// maybe make 2px for headers
// 	padding-bottom: 1px;
// }

// span.doc-span--preDiscussionSelection{
// 	background: rgba(240,228,248,0.65);
// 	color: darken(rgb(240,228,248), 85%);
// 	//border-bottom: 1px solid darken(rgb(240,228,248), 58%); // notion
// 	//padding-bottom: 1px;
// }


//
// NEW SELECTION SPAN STYLING

// COMMENT DEFAULT
span.doc-span--comment--default{		
	background: rgba(255,212,0,0.12);	
	// color: darken(#FDE9AE, 72%);
	border-bottom: 1px solid #ffd400;
	padding-bottom: 1px;
}

span.doc-span--comment--default:hover{		
	background: rgba(255,212,0,0.24);	
	// color: darken(#FDE9AE, 72%);
	border-bottom: 1px solid #ffd400;
	padding-bottom: 1px;
}


.doc-h1 span.doc-span--comment--default{
	border-bottom-width: 2px;
	padding-bottom: 2px;
}


// COMMENT ACTIVE
// all comments are active now
span.doc-span--comment--active{		
	// notion
	//background: rgba(255,212,0,0.56);
	//border-bottom: 2px solid rgb(255,212,0);
		
	background: rgba(255,212,0,0.32);
	// box-shadow: 0px 0px 0px 1px rgba(255,212,0,0.32);
	// border-radius: 1px;
	border-bottom: 1px solid rgba(255,212,0,0.9);
	padding-bottom: 1px;
}

span.doc-para-linearIssueOuterContainer.doc-span--comment--active,
span.doc-para-projectLinkContainer.doc-span--comment--active{
	border-bottom: 0px solid red;
	box-shadow: none;
	padding-bottom: 0px;
}




span.doc-span--comment--parentDraftActive,
span.doc-span--comment--newEmpty{				
	background: rgba(255,212,0,0.32);	
	padding-bottom: 1px;
}


// // DISCUSSION DEFAULT
// span.doc-span--discussion--default {	
// 	background: rgba(240,228,248,0.30);
// 	border-bottom: 1px solid darken(rgb(240,228,248), 58%);
// 	color: darken(rgb(240,228,248), 74%);
// 	padding-bottom: 1px;
// }

// span.doc-span--discussion--default:hover{
// 	background: rgba(240,228,248,0.75);
// }

// .doc-h1 span.doc-span--discussion--default{
// 	border-bottom-width: 2px;
// 	padding-bottom: 2px;
// }


// // DISCUSSION ACTIVE
// span.doc-span--discussion--active {	
// 	background: darken(rgb(240,228,248), 2%);
// 	border-bottom: 2px solid darken(rgba(240,228,248,0.5), 50%);
// 	// color: darken(rgb(240,228,248), 74%);	
// 	padding-bottom: 2px;
// }

// span.doc-span--discussion--parentDraftActive,
// span.doc-span--discussion--newEmpty{
// 	background: darken(rgb(240,228,248), 2%);		
// 	padding-bottom: 1px;
// }


//
// DEFAULT DRAFT
span.doc-span--comment--parentDraft,
span.doc-span--discussion--parentDraft{
	border-bottom: none;
	background: rgba(210,218,218,0.2);
	border-bottom: 1px solid rgba(210,218,218,0.8);

	padding-bottom: 1px;
}

//
// COMMENTS/DISCUSSIONS IN LIGHT TEXT IN DEFAULT STATE SHOULD ALSO BE LIGHT?
span.doc-span--style--light span.doc-span--comment--default,
span.doc-span--style--light span.doc-span--discussion--default{		
		background: rgba(210,218,218,0.35);
		border-bottom: 1px solid rgba(200,208,208,1);
		padding-bottom: 1px;
}




