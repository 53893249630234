//
// SPAN
.doc-textLink{
	position: relative;
	color: var(--text200);
	text-decoration: underline;
	text-decoration-color: var(--line500);

	cursor: pointer;
}

// // .doc-textLink:focus{
// // 	outline: 2px solid green;
// // }

// // .doc-textLink-content{
// // 	color: var(--text200);
// // 	text-decoration: underline;
// // 	text-decoration-color: var(--line500);

// // 	cursor: pointer;
// // }

// .doc-textLink-content:focus{
// 	outline: 2px solid red;
// }

.doc-textLink-popoverAnchor{
	left: 0px;
	right: 0px;
	width: 100%;
	bottom: 0px;
	top: 0px;
	height: 100%;
	
	// background: $tempr;
	position: absolute;
}

.doc-textLink-popoverAnchor-anchor{
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;
	top: 0px;
	bottom: 0px;
	height: 100%;

	// background: $tempb;
}

.doc-textLink-popoverAnchor-anchorBtn{
	position: absolute;

	left: 0px;
	right: 0px;
	width: 100%;
	top: 0px;
	bottom: 0px;
	height: 100%;

	// background: $tempg;

	z-index: 10;
}

.doc-para-newTextLinkSelection{
	background: var(--textSelectionBlue);
	border-top: 1px solid var(--textSelectionBlue);
	border-bottom: 2px solid var(--textSelectionBlue);
	// border-radius: 1px;
	// box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);

	//background: red;
	//border-radius: 1px;
	//box-shadow: 0px 0px 0px 2px red;
}

.doc-textLink-newPopoverAnchor{
	position: absolute;
	// background: $tempr;

	z-index: 99999;

	pointer-events: none;
}


.doc-textLink-newPopoverAnchor--hidden,
.doc-textLink-newPopoverAnchor--hidden *{
	opacity: 0;
	pointer-events: none !important;
}



@import 'Doc-TextLink-HoverMenu';


//EDIT MENU MENU

@import 'Doc-TextLink-Popover';
