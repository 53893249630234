
//
//
// PRIORITY

.linearIssuePriorityIconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}


.linearIssuePriorityIcon{
	width: 16px;
	height: 16px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.linearIssuePriorityIcon--priority--1 svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.4;
}

.linearIssuePriorityIcon--priority--0-nullBar{
	width: 9px;
	height: 2px;
	background: var(--text500);
	border-radius: 2px;

	opacity: 0.3;
}


.linearIssuePriorityIcon-bars{
	width: 13px;
	height: 12px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	opacity: 0.4;
}

.linearIssuePriorityIcon-bar{
	width: 3px;
	background: var(--text500);
	border-radius: 2px;
}

.linearIssuePriorityIcon-bar:nth-child(1){	
	height: 6px;
}

.linearIssuePriorityIcon-bar:nth-child(2){
	height: 9px;
}

.linearIssuePriorityIcon-bar:nth-child(3){
	height: 12px;
}


.linearIssuePriorityIcon--priority--4{
	.linearIssuePriorityIcon-bar:nth-child(1){	
		opacity: 1;
	}

	.linearIssuePriorityIcon-bar:nth-child(2){
		opacity: 0.5;
	}

	.linearIssuePriorityIcon-bar:nth-child(3){
		opacity: 0.5;
	}
}

.linearIssuePriorityIcon--priority--3{
	.linearIssuePriorityIcon-bar:nth-child(1){	
		opacity: 1;
	}

	.linearIssuePriorityIcon-bar:nth-child(2){
		opacity: 1;
	}

	.linearIssuePriorityIcon-bar:nth-child(3){
		opacity: 0.5;
	}
}

.linearIssuePriorityIcon--priority--2{
	.linearIssuePriorityIcon-bar:nth-child(1){	
		opacity: 1;
	}

	.linearIssuePriorityIcon-bar:nth-child(2){
		opacity: 1;
	}

	.linearIssuePriorityIcon-bar:nth-child(3){
		opacity: 1;
	}
}


//
// INLINE 

.linearInlineIssuePriorityIcon svg.linearInlineIssuePriorityIcon-bars,
.linearInlineIssuePriorityIcon svg.linearInlineIssuePriorityIcon-nullBar{
	fill: var(--text500);
	opacity: 0.4;	
	width: 12px;
}

.linearInlineIssuePriorityIcon svg.linearInlineIssuePriorityIcon-nullBar{
	opacity: 0.3;
}

.linearInlineIssuePriorityIcon svg.icon--name--linearUrgentPriorityIcon{
	fill: var(--text500);
	opacity: 0.6;
	width: 14px;
	height: 14px;
	margin-left: -0.05em;
	margin-right: -0.07em;
}

.linearInlineIssuePriorityIcon--priority--4 svg.linearInlineIssuePriorityIcon-bars{
	#firstBar{	
		opacity: 1;
	}

	#secondBar{
		opacity: 0.5;
	}

	#thirdBar{
		opacity: 0.5;
	}
}


.linearInlineIssuePriorityIcon--priority--3 svg.linearInlineIssuePriorityIcon-bars{
	#firstBar{	
		opacity: 1;
	}

	#secondBar{
		opacity: 1;
	}

	#thirdBar{
		opacity: 0.5;
	}
}


.linearInlineIssuePriorityIcon--priority--2 svg.linearInlineIssuePriorityIcon-bars{
	#firstBar{	
		opacity: 1;
	}

	#secondBar{
		opacity: 1;
	}

	#thirdBar{
		opacity: 1;
	}
}


