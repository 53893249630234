
.message-reactionsPanel{
	display: flex;

	padding-left: 40px;

	margin-top: 4px;
	margin-bottom: 2px;

	user-select: none;
	// background: $tempr;
}

button.message-reactionsPanel-reaction{

	width: 40px;
	height: 24px;
	border-radius: 10px;	

	display: flex;
	align-items: center;
	justify-content: center;	

	border: 1.5px solid $invisible;
	background: var(--message-reactionBtnBG);

	cursor: pointer;
	user-select: none;

	margin-right: 4px;	
}


// // THESE ARE IF YOU'VE REACTED
button.message-reactionsPanel-reaction.message-reactionsPanel-reaction--userHasReacted{
	// border: 1.5px solid transparentize($blue, 0.15);
	// background: transparentize($blue, 0.95);

	// // need to make opaque for hover effect
	// border: 1.5px solid lighten($blue, 5%);
	// background: lighten($blue, 48%);

	border: 1.5px solid var(--primary400); // inexact match, 
	background: var(--primary25);
	
}

[data-theme="dark"] button.message-reactionsPanel-reaction.message-reactionsPanel-reaction--userHasReacted{
	border: 1px solid $invisible;
	background: transparentize(#005CCC, 0.5);
}

// button.message-reactionsPanel-reaction:hover{
// 	//border: 1.5px solid transparentize($blue, 0.2);
// 	//background: transparentize($blue, 0.9);
// 	border: 1.5px solid var(--primary400);
// 	background: var(--primary50);
// }

// button.message-reactionsPanel-reaction:active{
// 	//border: 1.5px solid transparentize($blue, 0.15);
// 	//background: transparentize($blue, 0.8);	
// 	border: 1.5px solid var(--primary450); // inexact match
// 	background: var(--primary100);
// }


// button.doc-inlineReactionBar-reaction--userHasReacted
// .doc-inlineReactionBar-reaction-count{
// 	//color: darken($blue, 10%);
// 	color: var(--primary700);
// }

button.doc-inlineReactionBar-reaction:hover
.message-reactionsPanel-reaction-count{
	//color: darken($blue, 15%)
	color: var(--primary800);
}

.message-reactionsPanel-reaction-emoji{
	margin-right: 3px;
	font-size: 15px;
}

.message-reactionsPanel-reaction-count{
	font-weight: $lato-semibold;
	font-size: 12.5px;
	color: var(--text400);
	opacity: 0.8;
}

button.message-reactionsPanel-reaction.message-reactionsPanel-reaction--userHasReacted
.message-reactionsPanel-reaction-count{
	color: var(--primary600);
	opacity: 1;
}


[data-theme="dark"] button.message-reactionsPanel-reaction.message-reactionsPanel-reaction--userHasReacted
.message-reactionsPanel-reaction-count{	
	color: $white;
	opacity: 1;
}

button.doc-inlineReactionBar-reaction:hover
.message-reactionsPanel-reaction-count{	
	//color: darken($blue, 10%);
	color: var(--primary700);
}

//
// STILL TO ADD
button.message-reactionsPanel-addEmojiBtn{
	width: 34px;
	height: 25px;

	padding: 0px;

	border-radius: 12.5px;

	background: none;
	border: none;
	box-shadow: none;

	background: rgb(244,244,244);

	margin-left: 5px;

	cursor: pointer;

	opacity: 0;
}

.message:hover
button.message-reactionsPanel-addEmojiBtn{
	opacity: 1;
}

button.message-reactionsPanel-addEmojiBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text400);	
	margin-top: 2px;

}

button.message-reactionsPanel-addEmojiBtn:hover{
	//background: transparentize($blue, 0.9);
	background: var(--primary50)
}

button.message-reactionsPanel-addEmojiBtn:hover svg{
	//fill: darken($blue, 10%);
	fill: var(--primary700);
}