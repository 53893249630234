$zoomedImageModal-sideMargin: 20px;
$zoomedImageModal-topMargin: 30px;
$zoomedImageModal-bottomMargin: 20px;
$headerHeight-zoomedImageModal: 46px;
$footerHeight-zoomedImageModal: 56px;


.doc-zoomedImageModalContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	z-index: 50;

	background: var(--bg-zoomedMediaModals);

	// padding-top: 10px;
	// padding-left: 10px;
	// padding-right: 10px;
	// padding-bottom: 10px;

	overflow-y: hidden;
	overflow-x: hidden;
}

.doc-zoomedImageModalContainer:focus{
	outline: none;
}


.doc-zoomedImageModal-captureOutsideClick{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $invisible;
}


.doc-zoomedImageModal{
	margin-top: 10px;
	width: calc(100vw - #{$zoomedImageModal-sideMargin} - #{$zoomedImageModal-sideMargin});
	height: calc(100vh - #{$zoomedImageModal-topMargin} - #{$zoomedImageModal-bottomMargin});
	max-width: 1800px;
	max-height: 1200px;
	background: rgb(0,0,0);

	border-radius: 4px;
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 8px 16px 0px rgba(0,0,0,0.12),
	;

	display: flex;
	flex-direction: column;

	position: relative;
	z-index: 50;
}

.doc-zoomedImageModal:focus{
	outline: none;
}

//
//

.doc-zoomedImageModal-header{
	width: 100%;
	height: $headerHeight-zoomedImageModal;
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempb;

	display: flex;
}

//
//

.doc-zoomedImageModal-footer{
	width: 100%;
	height: $footerHeight-zoomedImageModal;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
}



$width-centerContainer-zoomedImageModalHeader: 300px;
$width-centerContainer-zoomedImageModalFooter: 600px;

.doc-zoomedImageModal-header-leftContainer,
.doc-zoomedImageModal-header-centerContainer,
.doc-zoomedImageModal-header-rightContainer,
.doc-zoomedImageModal-footer-leftContainer,
.doc-zoomedImageModal-footer-centerContainer,
.doc-zoomedImageModal-footer-rightContainer{
	display: flex;
	align-items: center;
}


.doc-zoomedImageModal-header-leftContainer,
.doc-zoomedImageModal-header-rightContainer{
	width: calc(50% - (0.5 * $width-centerContainer-zoomedImageModalHeader));
	flex-grow: 0;
	flex-shrink: 0;
	
	box-sizing: border-box;	
}

.doc-zoomedImageModal-header-leftContainer{
	padding-left: 16px;
}
.doc-zoomedImageModal-footer-leftContainer{
	padding-left: 8px;
}

.doc-zoomedImageModal-header-rightContainer,
.doc-zoomedImageModal-footer-rightContainer{
	justify-content: flex-end;
}


.doc-zoomedImageModal-footer-leftContainer,
.doc-zoomedImageModal-footer-rightContainer{
	width: calc(50% - (0.5 * #{$width-centerContainer-zoomedImageModalFooter}));
	flex-grow: 0;
	flex-shrink: 0;
	
	box-sizing: border-box;	
}


.doc-zoomedImageModal-footer-centerContainer{
	width: $width-centerContainer-zoomedImageModalFooter;
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}




.doc-zoomedImageModal-header-centerContainer{
	width: $width-centerContainer-zoomedImageModalHeader;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-zoomedImageModal-footer-rightContainer{
	padding-right: 14px;
}


//
//




//
//

.doc-zoomedImageModal-header-menuBtn{
	width: 24px;
	height: 24px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	opacity: 0.5;

	margin-right: 2px;
	pointer-events: none; // nothing for now
}

.doc-zoomedImageModal-header-menuBtn:focus{
	outline: none;
}

.doc-zoomedImageModal-header-menuBtn:hover{
	opacity: 1;
	background: rgb(255,255,255,0.25);
}

.doc-zoomedImageModal-header-menuBtn svg{
	width: 14px;
	height: 14px;
	fill: $white;
}

input.doc-zoomedImageModal-header-imageTitleInput{
	height: 28px;
	width: 240px;
	background: $tempr;
	border: none;
	padding-left: 8px;
	border-radius: 4px;
	background: none;

	color: rgba(255,255,255,0.7);

	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.003em;
}

input.doc-zoomedImageModal-header-imageTitleInput::placeholder{
	color: rgba(255,255,255,0.3);
}

input.doc-zoomedImageModal-header-imageTitleInput:hover{
	background: rgba(255,255,255,0.08);
	outline: none;
	border: none;
}

input.doc-zoomedImageModal-header-imageTitleInput:hover::placeholder{
	color: rgba(255,255,255,0.5);
}

input.doc-zoomedImageModal-header-imageTitleInput:focus{
	background: rgba(255,255,255,0.12);
	color: rgb(255,255,255,1);
	outline: none;
	border: none;
}

//


//
//


.doc-zoomedImageModal-imageContainer{
	width: 100%;
	height: calc(100% - #{$headerHeight-zoomedImageModal} - #{$footerHeight-zoomedImageModal});
	cursor: pointer;
	// background: $tempr;
}

img.doc-zoomedImageModal-image{
	width: 100%;
	height: 100%;
	object-fit: contain; // improve this, for one want to click on image to unzoom
	// will probably change with the new markup system
}

img.doc-zoomedImageModal-image:focus{
	outline: none;
}


.doc-zoomedImageModal-footer-commentInputContainer--show{
	opacity: 1;
	transition: opacity 200ms linear;
}

.doc-zoomedImageModal-footer-commentInputContainer--hide{
	opacity: 0;
	transition: opacity 100ms linear;
	pointer-events: none;
}


//
// BUTTON

.doc-zoomedImageModal-btnContainer{
	// margin: 4px;
}

.doc-zoomedImageModal-btnContainer:focus,
.doc-zoomedImageModal-btnContainer:focus * {
	outline: none;
}

.doc-zoomedImageModal-btnContainer--disabled
.doc-zoomedImageModal-btn-spotlight{
	opacity: 0;
}

.doc-zoomedImageModal-btn{
	width: 38px;
	height: 34px;
	border-radius: 5px;
	// background: rgba(255,255,255,0.2);

	position: relative;

	overflow: hidden;

	color: $white;
}

.doc-zoomedImageModal-btn:disabled{
	pointer-events: none;
}

button.doc-zoomedImageModal-btn:focus{
	outline: none;
}

.doc-zoomedImageModal-btn--captionIcon{
	width: 46px;
}

.doc-zoomedImageModal-btn--iconOnly
.doc-zoomedImageModal-btn-iconContainer{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-zoomedImageModal-btn--iconAndLabel{
	width: auto;
	padding-left: 8px;
	padding-right: 8px;
	min-width: 80px;
}

.doc-zoomedImageModal-btn--iconAndLabel
.doc-zoomedImageModal-btn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-zoomedImageModal-btn-label{
	margin-left: 4px;
	opacity: 0.5;

	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	transition: all 50ms linear;
}



.doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: $white;
	opacity: 0.5;
	transform: scale(1.05);
	transition: all 50ms linear;
}

// Custom Icon sizing

.doc-zoomedImageModal-btn svg.icon--name--drawingArrow,
.doc-zoomedImageModal-btn svg.icon--name--drawingSquare,
.doc-zoomedImageModal-btn svg.icon--name--drawingSticky{
	width: 28px;
	height: 28px;
}

.doc-zoomedImageModal-btn svg.icon--name--drawingText{
	width: 26px;
	height: 26px;
}

.doc-zoomedImageModal-btn svg.icon--name--imageCrop,
.doc-zoomedImageModal-btn svg.icon--name--imageSettings{
	width: 20px;
	height: 20px;
}

.doc-zoomedImageModal-btn svg.icon--name--slackIcon{
	width: 17px;
	height: 17px;
}

.doc-zoomedImageModal-btn svg.icon--name--link{
	width: 18px;
	height: 18px;
}

.doc-zoomedImageModal-btn svg.icon--name--plus{
	width: 20px;
	height: 20px;
}

.doc-zoomedImageModal-btn--iconAndLabel svg.icon--name--broadcast{
	width: 15px;
	height: 15px;
	position: relative;
	top: 0.5px;
}

.doc-zoomedImageModal-btn--iconOnly svg.icon--name--broadcast{
	width: 17px;
	height: 17px;
	position: relative;
	top: 0.5px;
}

.doc-zoomedImageModal-btn svg.icon--name--commentOutline{
	width: 16px;
	height: 16px;
	position: relative;
	top: 1px;
}

.doc-zoomedImageModal-btn svg.icon--name--unZoomMedia,
.doc-zoomedImageModal-btn svg.icon--name--expandVideo{
	width: 13px;
	height: 13px;
}

//
// CAPTIONS BUTTON

.doc-zoomedImageModal-btn-captionIcon{
	width: 28px;
	height: 18px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: $lato-bold;
	letter-spacing: 0.004em;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
	opacity: 0.5;
	transition: all 50ms linear;
}


.doc-zoomedImageModal-btn--active
.doc-zoomedImageModal-btn-captionIcon{
	background: $white;
	border: 0.5px solid $invisible;
	color: var(--text600);
}

.doc-zoomedImageModal-btn-captionIcon{
	background: rgba(255,255,255,0.12);
	border: 0.5px solid rgba(255,255,255,0.25);
	color: rgba(255,255,255,0.75);
}



//

.doc-zoomedImageModal-btn-spotlight{
	position: absolute;

	// border-radius: 50%;
	width: 80px;
	height: 80px;

	margin-top: -40px;
	margin-left: -40px;

	background: $white;
	background: radial-gradient(rgba(255,255,255,0.58) 0%, rgba(255,255,255,0.52) 30%, rgba(255,255,255,0.48) 100%);
	opacity: 0.2;
}

.doc-zoomedImageModal-btn--iconAndLabel .doc-zoomedImageModal-btn-spotlight,
.doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight,
.recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight,
.recordingPlaybackModal-media-playbackControls-buttonRow-speakerSeekBtnContainer .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight,
.recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight,
.recordingPlaybackModal-header-showTranscript .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight{
	width: 240px;
	height: 240px;
	margin-top: -120px;
	margin-left: -120px;
	background: radial-gradient(rgba(255,255,255,0.58) 0%, rgba(255,255,255,0.52) 60%, rgba(255,255,255,0.48) 100%);
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlightBtnContainer .doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-spotlight{
	width: 360px;
	height: 360px;
	margin-top: -180px;
	margin-left: -180px;
	background: radial-gradient(rgba(255,255,255,0.58) 0%, rgba(255,255,255,0.52) 60%, rgba(255,255,255,0.48) 100%);
}

.doc-zoomedImageModal-btn:hover svg,
.doc-zoomedImageModal-btn:hover .doc-zoomedImageModal-btn-captionIcon{
	opacity: 1;
	transform: scale(1.1);
	transition: all 150ms linear;
}

.doc-zoomedImageModal-btn:hover .doc-zoomedImageModal-btn-captionIcon{
	transform: scale(1.05);
}

.doc-zoomedImageModal-btn:hover 
.doc-zoomedImageModal-btn-label{
	opacity: 1;
	transition: all 150ms linear;
}



.doc-zoomedImageModal-header-centerContainer:hover .doc-zoomedImageModal-btn svg{
	opacity: 0.6;
}

.doc-zoomedImageModal-header-centerContainer:hover .doc-zoomedImageModal-btn:hover svg{
	opacity: 1;
}


//

.tooltip.tooltip--imageModal{
	background: rgb(27,26,30);
	border: 1px solid rgb(27,26,30);
	white-space: wrap;
	max-width: 500px;
}

.tooltip.tooltip--imageModal[data-side="bottom"]{
	margin-bottom: -2px;
}

.tooltip.tooltip--imageModal[data-side="top"]{
	margin-top: -2px;
}

//



.doc-zoomedImageModal-header-closeBtnOuterContainer{
	height: 100%;
	width: 48px;
	// margin-left: 8px;
	padding-right: 4px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

.doc-zoomedImageModal-header-closeBtnOuterContainer
.doc-zoomedImageModal-btn{
	// background: rgb(255,255,255,0.12);
	width: 34px;
	height: 34px;
}

.doc-zoomedImageModal-header-closeBtnOuterContainer
.doc-zoomedImageModal-btn svg{
	width: 14px;
	height: 14px;
}

.doc-zoomedFigmaModal-header-closeBtnOuterContainer
.doc-zoomedImageModal-btn svg{
	width: 14px;
	height: 14px;
}




.doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer
.doc-zoomedImageModal-btn{
	// background: $tempr;
	width: 52px;
	height: 38px;
}

.doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer
.doc-zoomedImageModal-btn svg{
	width: 20px;
	height: 20px;

}

.doc-zoomedImageModal-btn svg.icon--name--playbackPlay{
	position: relative;
	right: -1px;
	opacity: 1;
}

.doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer
.doc-zoomedImageModal-btn:hover svg{	
	transform: scale(1.08);	
}



//
//

.recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer
.doc-zoomedImageModal-btn{
	// background: $tempr;
	width: 100px;
	height: 54px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer
.doc-zoomedImageModal-btn svg{
	width: 26px;
	height: 26px;
}

recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer
.doc-zoomedImageModal-btn svg.icon--name--playbackPlay{
	position: relative;
	right: -1px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer
.doc-zoomedImageModal-btn:hover svg{	
	transform: scale(1.08);	
}

//

.recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer
.doc-zoomedImageModal-btn{
	width: 72px;
	height: 44px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer
.doc-zoomedImageModal-btn svg{
	width: 22px;
	height: 22px;
}


//

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlightBtnContainer
.doc-zoomedImageModal-btn{
	// background: $tempr;
	width: 180px;
	height: 40px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlightBtnContainer
.doc-zoomedImageModal-btn svg{
	width: 17px;
	height: 17px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlightBtnContainer
.doc-zoomedImageModal-btn .doc-zoomedImageModal-btn-label{
	font-size: 16px;
}




///



.doc-zoomedImageModal-imageCarousel{
	display: flex;
}

.doc-zoomedImageModal-imageCarousel-btn{
	
	height: 56px;
	width: 44px;

	display: flex;
	align-items: center;
	justify-content: center;

	
	//aspect-ratio: 4/3;
	// background: red;
	// padding-left: 0px;
	// padding-right: 0px;

	cursor: pointer;

	margin-left: 1px;
	margin-right: 1px;
}

.doc-zoomedImageModal-imageCarousel-btn--isActive{
	// opacity: 1;
	// // margin-left: 2px;
	// // margin-right: 2px;
	// // padding-left: 4px;
	// // padding-right: 4px;
}

.doc-zoomedImageModal-imageCarousel-btn-frame{
	width: 40px;
	height: 30px;
}

// .doc-zoomedImageModal-imageCarousel-btn{
// 	transition: opacity 0ms;
// }


.doc-zoomedImageModal-imageCarousel
.doc-zoomedImageModal-imageCarousel-btn--isActive{
	opacity: 0.75;
}

.doc-zoomedImageModal-imageCarousel:hover
.doc-zoomedImageModal-imageCarousel-btn--isActive{
	opacity: 1;
}

.doc-zoomedImageModal-imageCarousel:hover
.doc-zoomedImageModal-imageCarousel-btn--isActive:hover{
	opacity: 1;
}

.doc-zoomedImageModal-imageCarousel
.doc-zoomedImageModal-imageCarousel-btn--isInactive{
	opacity: 0.15;
	transition: 50ms linear;
}



.doc-zoomedImageModal-imageCarousel:hover
.doc-zoomedImageModal-imageCarousel-btn--isInactive{
	opacity: 0.5;
	transition: 50ms linear;
}

.doc-zoomedImageModal-footer:hover
.doc-zoomedImageModal-imageCarousel:hover
.doc-zoomedImageModal-imageCarousel-btn--isInactive:hover{
	opacity: 0.75;
}


.doc-zoomedImageModal-imageCarousel-btn--isInactive
.doc-zoomedImageModal-imageCarousel-btn-frame{
	transform: scale(0.9);
}

.doc-zoomedImageModal-imageCarousel-btn--isInactive:hover
.doc-zoomedImageModal-imageCarousel-btn-frame{
	transform: scale(0.95);
}

// .doc-zoomedImageModal-imageCarousel-btn--isInactive:hover{
// 	opacity: 0.9;
// }





// .doc-zoomedImageModal-imageCarousel-btn--isActive
// .doc-zoomedImageModal-imageCarousel-btn-frame{
// 	//outline: 2px solid rgba(255,255,255,0.25);
// 	//outline: 2px solid #63ABFF;
// }


img.doc-zoomedImageModal-imageCarousel-btn-frame-image{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 2px;
}