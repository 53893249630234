$createTemplateModal-gutterOverhang: 8px;

.docEditor-newTemplatePopoverTriggerContainer{
	position: absolute;
	top: calc(100vh - 16px);
	left: 0px;
	width: 100%;	

	background: $tempr;

	z-index: 10;
}

.docEditor-newTemplatePopoverTrigger{
	width: $textWidth-doc;	
	margin: 0 auto;
	// background: $tempr;

	pointer-events: none;
}

//

.docEditor-createTemplatePopoverContainer{	
	
}

.docEditor-createTemplatePopoverContainer:focus{
	outline: none;
}

.docEditor-createTemplatePopoverContainer--hidden,
.docEditor-createTemplatePopoverContainer--hidden *{
	pointer-events: none !important;
}


//

.docEditor-createTemplatePopover{
	width: 640px;	
	height: 326px;

	width: 620px;
	height: 260px;
	
	box-sizing: border-box;
	
	border-radius: 6px;

	box-sizing: border-box;
	padding-top: 18px;
	padding-bottom: 24px;
	padding-left: 32px;
	padding-right: 32px;

	display: flex;
	flex-direction: column;

	position: relative;
}


button.docEditor-createTemplatePopover-cancelBtn{
	position: absolute;
	top: 18px;
	right: 9px;

	width: 94px;
	height: 34px;

	border-radius: 4px;

	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text300);

	opacity: 0.8;

	//background: var(--253gray);
}

button.docEditor-createTemplatePopover-cancelBtn:hover{
	opacity: 1;
	color: var(--text400);
	background: var(--250gray);
}

.docEditor-createTemplatePopover-titleInputContainer{
	height: 40px;
	width: calc(100% - 100px);
	position: relative;
	margin-bottom: 6px;

	// background: $tempr;
}

input.docEditor-createTemplatePopover-titleInput{
	position: absolute;
	top: 0px;
	left: calc(#{$editProjectModal-gutterOverhang} * -1);
	right: calc(#{$editProjectModal-gutterOverhang} * -1);
	bottom: 0px;

	width: calc(100% + (2 * #{$editProjectModal-gutterOverhang}));

	box-sizing: border-box;
	padding-top: 8px;
	padding-left: $editProjectModal-gutterOverhang;
	padding-right: $editProjectModal-gutterOverhang;

	border: none;
	outline: none;
	box-shadow: none;
	background: none;

	border-radius: 3px;

	// background: $tempb;


	//font-size: 21.75px;
	font-size: 20.75px;
	font-weight: $lato-bold;
	//letter-spacing: 0.002em;
	color: var(--text600);
}

.docEditor-createTemplatePopover-descriptionTextAreaContainer{
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;

	width: 100%;

	// background: $tempg;
	resize: none;
}

textarea.docEditor-createTemplatePopover-descriptionTextArea{
	position: absolute;
	top: 0px;
	left: calc(#{$createTemplateModal-gutterOverhang} * -1);
	right: calc(#{$createTemplateModal-gutterOverhang} * -1);
	bottom: 0px;

	resize: none;

	width: calc(100% + (2 * #{$createTemplateModal-gutterOverhang}));

	box-sizing: border-box;
	padding-left: $createTemplateModal-gutterOverhang;
	padding-right: $createTemplateModal-gutterOverhang;


	border: none;
	outline: none;
	box-shadow: none;
	background: none;

	border-radius: 3px;

	// background: $tempb;

	line-height: 1.4;

	font-size: 16.25px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 1;
	letter-spacing: 0.008em;
}

//


.docEditor-createTemplatePopover-submitBtnContainer{
	width: 100%;
	display: flex;
	justify-content: flex-end;

	padding-top: 16px;
}

.docEditor-createTemplatePopover-submitBtn{
	

	background: var(--text400);

	width: 190px;
	height: 38px;

	background: var(--text500);
	border: 1px solid var(--text600);
	border-radius: 3px;

	color: $white;

	position: relative;
}


.docEditor-createTemplatePopover-submitBtn:focus{
	outline: 3px solid var(--line100);	
}

.docEditor-createTemplatePopover-submitBtn:disabled{
	pointer-events: none;
}

.docEditor-createTemplatePopover-submitBtn-iconContainer{
	width: 24px;
	height: 24px;

	margin-left: -8px;
	margin-right: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-createTemplatePopover-submitBtn-iconContainer svg{
	width: 20px;
	height: 120px;
	fill: $white;
	opacity: 0.75;
}

.docEditor-createTemplatePopover-submitBtn:hover 
.docEditor-createTemplatePopover-submitBtn-iconContainer svg{
	opacity: 1;
}

.docEditor-createTemplatePopover-submitBtn-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: $white;
}

.docEditor-createTemplatePopover-submitBtn-keyboardKey{
	background: rgba(255,255,255,0.07);
	border-radius: 3px;

	height: 19px;
	padding-left: 4px;
	padding-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 8px;
	margin-right: -5px;

	position: relative;
	top: 0.5px;
}

.docEditor-createTemplatePopover-submitBtnKeyboardHint-iconContainer svg{
	width: 13px;
	height: 13px;
	fill: $white;
	opacity: 0.8;
	position: relative;
	top: 1px;
}

:root[data-theme="dark"]
.docEditor-createTemplatePopover-submitBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.docEditor-createTemplatePopover-submitBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.docEditor-createTemplatePopover-submitBtn:focus{
	outline: none;
	background: var(--243gray);
	border: 1px solid var(--210gray);
}

.docEditor-createTemplatePopover-submitBtn-loadingBar{
	right: 18px;
	left: 18px;

	top: 50%;
	transform: translateY(-50%);

	height: 3px;

	border-radius: 10px;

	position: absolute;

	background: var(--246gray);
	background: rgba(255,255,255,0.2);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.docEditor-createTemplatePopover-submitBtn-loadingBar-ball{
	width: 40px;
	height: 3px;
	border-radius: 4px;

	// background: red;
	background: rgba(255,255,255,0.8);	

}


