//
// VOLUME SLIDER

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider{
	position: relative;
	
	height: 32px;

	width: 134px;

	// background: $tempr;
}


.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn{
	height: 100%;
	width: 34px;

	cursor: pointer;

	display: flex;
	
	position: relative;
	cursor: pointer;

	opacity: 0.5;	
	
	transition: opacity 150ms linear;	
	transition-delay: 200ms;

	// background: $tempr;
}


.doc-video-zoomedUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn,
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider:hover
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn{
	opacity: 1;
	transition: opacity 50ms linear;	
	transition-delay: 100ms;
}

// .doc-video-zoomedUI-controlBar-controlRow-volumeSlider:hover
// .doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn{
// 	transition-delay: 200ms;
// }


.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn svg{
	width: 24px;
	height: 24px;
	fill: $white;	
}



.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	position: absolute;
	top: 4px;
	bottom: 4px;

	left: 34px;
	right: 0px;
	
	box-sizing: border-box;
	
	z-index: 10;	

	cursor: pointer;
	opacity: 0.5;
	transition: opacity 150ms linear;
	transition-delay: 200ms;
	// background: blue;
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider--maxVolume
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	opacity: 0;
}


.doc-video-zoomedUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBarContainer,
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider:hover 
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}



.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar{
	height: 100%;
	margin-top: 2px;
	// background: $green;
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-track{
	height: 4px;
	margin-top: 8px;
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-track-0{
	background: white;	
	border-radius: 2px;
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-track-1{	
	border-radius: 2px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background: rgb(86,99,114);
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb{
	width: 10px;
	height: 10px;
	background: $white;
	border-radius: 5px;
	

	margin-top: 5px;
	margin-left: -1px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 0px 2px 0px rgba(0,0,0,0.12),
		0px 0px 0px 1px rgba(0,0,0,0.12),

		;	

	// opacity: 0;	
	// transition: opacity 150ms linear;
	// transition-delay: 200ms;
}

.doc-video-zoomedUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb,
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider:hover 
.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb{
	// opacity: 1;
	// transition: opacity 50ms linear;
	// transition-delay: 100ms;
}


.doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb:focus{
	outline: none;
	border: none;
}


.doc-video-zoomedUI-controlBar-controlBar-thumb-thumb{

}