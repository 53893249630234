$fontSize-topMenuBtn: 15.25px;

//
// TOP CENTER

.docEditor-topMenuContainer{
	position: absolute;

	// position: fixed;

	top: 0px;
	left: 0px;
	right: 0px;

	//right: calc(#{$windowMargin-sideMenuMode} + 0px);	
	// right: calc(#{$windowMargin-sideMenuMode} + #{$width-teamPanel--narrow});	
	// margin-left: 1px;


	border-top-left-radius: 7px;
	border-top-right-radius: 7px; // for when in full screen
	
	height: $height-docEditor-topMenu;

	display: flex;

	// z-index: 10000;

	background: var(--bg-docEditor);

	
	z-index: 10;

	-webkit-app-region: drag;


}

.docEditorContainer--figmaModalIsZoomed .docEditor-topMenuContainer,
.docEditorContainer--messageMediaIsZoomed .docEditor-topMenuContainer{
	z-index: 0;
}


// .docEditor-topMenu-leftSpacer{
// 	flex-grow: 0;
// 	flex-shrink: 0;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	box-sizing: border-box;
// 	padding-left: $interiorLeftMarginToMenus-docEditor;
// 	padding-left: 3px;
// }

.docEditor-topMenu-center{
	flex-grow: 0;
	flex-shrink: 1;
	width: $textWidth-doc;
	height: 100%;

	display: flex;
	align-items: center;

	position: absolute;
	top: 0px;
	bottom: 0px;

	// width: $textWidth-doc;
	// background: $tempr;

	transform: translateX(-50%);
	left: 50%;

	// background: $tempr;
}

// for clean support with back button
@media screen and (max-width: 1420px){
	.docEditorContainer--sideMenuState--visible
	.docEditor-topMenu-center{
		padding-left: 24px; 
	}
}


//
// DOC TITLE

.docEditor-topMenu-center-docTitle{
	font-size: $fontSize-topMenuBtn;
	font-weight: $lato-medium;
	color: var(--text500);
	user-select: none;
	cursor: default;

	-webkit-app-region: no-drag;
}

.docEditor-topMenu-center-docTitle--show{
	opacity: 0.7;
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.docEditor-topMenu-center-docTitle--hidden{
	opacity: 0;
	transition: opacity 50ms linear;
}

//
// SUB DOC BREADCRUMB

.docEditor-topMenu--subDoc-breadcrumb{
	display: flex;
	margin-left: -5px; // offset padding
	cursor: default;

	-webkit-app-region: no-drag;
}

.docEditor-topMenu--orgDoc-orgLink,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink:first-child
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add{
	margin-left: -7px;
}

.docEditor-topMenu--orgDoc-orgLink,
.docEditor-topMenu--rootDoc-parentRoadmapLink,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink,
.docEditor-topMenu--subDoc-breadcrumb-rootProject,
.docEditor-topMenu--subDoc-breadcrumb-currentDoc,
.docEditor-topMenu--subDoc-breadcrumb-slash,
.docEditor-topMenu--rootDoc-editRoadmapBtn{
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	-webkit-user-select: none;
	-webkit-app-region: no-drag;
}

.docEditor-topMenu--orgDoc-orgLink,
.docEditor-topMenu--rootDoc-parentRoadmapLink,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink,
.docEditor-topMenu--subDoc-breadcrumb-rootProject,
.docEditor-topMenu--subDoc-breadcrumb-currentDoc,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add .docEditor-topMenu--rootDoc-editRoadmapBtn{
	padding-left: 7px;
	padding-right: 7px;
}

.docEditor-topMenu--orgDoc-orgLink-label,
.docEditor-topMenu--rootDoc-parentRoadmapLink-label,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink-label,
.docEditor-topMenu--subDoc-breadcrumb-rootProject-label,
.docEditor-topMenu--subDoc-breadcrumb-currentDoc-label,
.docEditor-topMenu--subDoc-breadcrumb-slash,
.docEditor-topMenu--roadmapDoc-parentRoadmapLinkDashContainer,
.docEditor-topMenu--rootDoc-editRoadmapBtn-label{
	font-size: $fontSize-topMenuBtn;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;
}

.docEditor-topMenu--orgDoc-orgLink-image,
.docEditor-topMenu--subDoc-breadcrumb-rootProject-marker,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink-iconContainer,
.docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer,
.docEditor-topMenu--rootDoc-editRoadmapBtn-iconContainer{
	margin-right: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-topMenu--roadmapDoc-parentRoadmapLink-iconContainer,
.docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer{
	margin-right: 4px;
}

.docEditor-topMenu--orgDoc-orgLink-image{
	margin-right: 7px;
}

.docEditor-topMenu--teamDoc-teamLink-marker
.linearMarkerContainer--markerType--emoji,
.docEditor-topMenu--subDoc-breadcrumb-rootProject-marker
.linearMarkerContainer--markerType--emoji{
	font-size: 16px;
}

.docEditor-topMenu--teamDoc-teamLink-marker
.linearMarkerContainer--markerType--icon svg,
.docEditor-topMenu--subDoc-breadcrumb-rootProject-marker
.linearMarkerContainer--markerType--icon svg{
	width: 15px;
	height: 15px;
}

.docEditor-topMenu--subDoc-breadcrumb-slash,
.docEditor-topMenu--roadmapDoc-parentRoadmapLinkDashContainer{
	opacity: 0.5;
	user-select: none;
}

.docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer svg,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink-iconContainer svg{
	width: 16px;
	height: 16px;
	opacity: 0.6;
	fill: var(--text600);
}

.docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer{
	margin-right: 5px;
	position: relative;	
}

//.docEditor-topMenu--subDoc-breadcrumb-currentDoc:not(:hover)
.docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer .linearMilestoneIcon--incomplete svg #bg{
	stroke: var(--line600) !important;
}

//.docEditor-topMenu--subDoc-breadcrumb-currentDoc:not(:hover)
.docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer .linearMilestoneIcon--incomplete svg #progress{
	stroke: var(--line600) !important;
}

//.docEditor-topMenu--subDoc-breadcrumb-currentDoc:not(:hover)
.docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer .linearMilestoneIcon--completed svg #bg{
	// stroke: var(--line600) !important;	
	// fill: var(--line600) !important;

	// dirty, opaqure version of above
	stroke: #6E6D70 !important;	
	fill: #6E6D70 !important;
	
}

[data-theme="light"]{
	.docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer .linearMilestoneIcon--completed{
		opacity: 0.4;
	}
}


//

.docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer svg.icon--name--linkArrow{
	display: none;
}

.docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer svg.icon--name--roadmap{
	display: block;
}

.docEditor-topMenu--rootDoc-parentRoadmapLink:hover .docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer svg.icon--name--roadmap{
	display: none;
}

.docEditor-topMenu--rootDoc-parentRoadmapLink:hover .docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer svg.icon--name--linkArrow{
	display: block;
}


//

.docEditor-topMenu--rootDoc-parentRoadmapLink-label,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink-label{
	opacity: 0.75;
}

.docEditor-topMenu--roadmapDoc-parentRoadmapLinkDashContainer{
	cursor: default;
}

.docEditor-topMenu--orgDoc-orgLink-image-image{
	width: 14px;
	height: 14px;
	object-fit: cover;
	border-radius: 3px;
}

// .docEditor-topMenu--subDoc-breadcrumb-rootProject{
// 	background: $tempr;
// }

// .docEditor-topMenu--subDoc-breadcrumb-currentDoc{	
// 	background: $tempb;
// }


.docEditor-topMenu--subDoc-breadcrumb-currentDoc-label{
	opacity: 0.5;
}

.docEditor-topMenu--subDoc-breadcrumb-rootProject:hover
.docEditor-topMenu--subDoc-breadcrumb-rootProject-label,
.docEditor-topMenu--rootDoc-parentRoadmapLink:hover
.docEditor-topMenu--rootDoc-parentRoadmapLink-label,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink:hover
.docEditor-topMenu--roadmapDoc-parentRoadmapLink-label{
	opacity: 1;
}

.docEditor-topMenu--orgDoc-orgLink:hover,
.docEditor-topMenu--subDoc-breadcrumb-rootProject:hover,
.docEditor-topMenu--rootDoc-parentRoadmapLink:hover,
.docEditor-topMenu--roadmapDoc-parentRoadmapLink:hover{
	background: var(--253gray);
}

//
// Roadmaps
.docEditor-topMenu-roadmapsContainer{
	// background: $tempr;
	display: flex;
	margin-left: -7px;
}

.docEditor-topMenu-roadmapsContainer--popoverOpen
.docEditor-topMenu--rootDoc-parentRoadmapLink{
	opacity: 0.5;
}

// .docEditor-topMenu-roadmapsContainer--popoverClosed
// .docEditor-topMenu--rootDoc-parentRoadmapLink--editLabel{
// 	display: none;
// }

// .docEditor-topMenu-roadmapsContainer--popoverOpen
// .docEditor-topMenu--rootDoc-parentRoadmapLink--editLabel{
// 	opacity: 0.5;
// 	display: flex;
// }

// .docEditor-topMenu-roadmapsContainer--popoverOpen
// .docEditor-topMenu--rootDoc-parentRoadmapLink--editLabel{
	
// }




.docEditor-topMenu--rootDoc-editRoadmapBtnContainer{

}

.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit{	
	opacity: 0;
	transition: opacity 50ms linear;
	transition-delay: 200ms;
}

.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add{
	opacity: 0.75;
	transition: opacity 50ms linear;
}

.docEditor-topMenu--rootDoc-editRoadmapBtn{
	height: 30px;
	border-radius: 4px;	
}

.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit
.docEditor-topMenu--rootDoc-editRoadmapBtn{
	width: 44px;
}

.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit
.docEditor-topMenu--rootDoc-editRoadmapBtn{
	opacity: 0.5;
}

.docEditor-topMenu-roadmapsContainer--popoverOpen
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit
.docEditor-topMenu--rootDoc-editRoadmapBtn,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit:hover
.docEditor-topMenu--rootDoc-editRoadmapBtn{
	opacity: 1;
}

.docEditor-topMenu-roadmapsContainer:hover
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit:hover,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add:hover,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit[data-state="open"]{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add[data-state="open"]{
	opacity: 0.5;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.docEditor-topMenu--rootDoc-editRoadmapBtn:hover,
.docEditor-topMenu--rootDoc-editRoadmapBtnContainer[data-state="open"]
.docEditor-topMenu--rootDoc-editRoadmapBtn{
	background: var(--253gray);	
}

.docEditor-topMenu--rootDoc-editRoadmapBtn svg{
	width: 16px;
	height: 16px;	
	fill: var(--text600);
	opacity: 0.6;
}





//

.docEditor-topMenu-projectStatusTag{
	-webkit-app-region: no-drag;
	height: 30px;	
	border-radius: 4px;
}


.docEditor-topMenu-projectStatusTagContainer[data-state="open"] .docEditor-topMenu-projectStatusTag,
.docEditor-topMenuContainer--rootDoc-targetDateBtnContainer[data-state="open"] .docEditor-topMenuContainer--rootDoc-targetDateBtn{
	pointer-events: none;
	background: var(--250gray);
}

.docEditor-topMenu-projectStatusTag:hover,
.docEditor-topMenuContainer--rootDoc-targetDateBtn:hover{
	background: var(--250gray);
}

.docEditor-topMenu-projectStatusTag
.linearProjectStatusTag-bg{
	display: none; // experiment without it
}

.docEditor-topMenu-projectStatusTag
.linearProjectStatusIconContainer{
	position: relative;
	top: -1px;
}

.docEditor-topMenu-projectStatusTag
.linearProjectStatusTag-label{
	font-size: $fontSize-topMenuBtn;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
	color: var(--text500);
	opacity: 0.8;
}

.docEditor-topMenu-projectStatusTag[data-state="open"]
.linearProjectStatusTag-label,
.docEditor-topMenu-projectStatusTag:hover
.linearProjectStatusTag-label{
	opacity: 1;
	color: var(--text600);
}



// MEETING STUFF

.docEditor-topMenu--meetingDoc-meetingTypeIndicator{
	width: 26px;
	height: 26px;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--254gray);

	margin-right: 8px;
}

.docEditor-topMenu--meetingDoc-meetingTypeIndicator svg{
	width: 18px;
	height: 18px;
}

[data-theme="light"]{
	.docEditor-topMenu--meetingDoc-meetingTypeIndicator svg #core{
		fill: white;
	}

	.docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--standup svg #bg{
		fill: var(--accentOrange600);
	}

	.docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--teamSync svg #bg{
		fill: var(--primary600);
	}

	.docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--adhoc svg #bg{
		fill: var(--accentGreen600);
	}

	.docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--discovery svg #bg{
		fill: var(--accentPurple600);
	}

	.docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--sprintPlanning svg #bg{
		fill: var(--accentSky600);
	}	
}





.docEditor-topMenu--meetingDoc-meetingTypeIndicator--show,
.docEditor-topMenu--meetingDoc-participantIndicator--show{
	opacity: 1;
	transition: opacity 50ms linear;
	pointer-events: auto;
}

.docEditor-topMenu--meetingDoc-meetingTypeIndicator--hidden,
.docEditor-topMenu--meetingDoc-participantIndicator--hidden{
	opacity: 0;
	transition: opacity 50ms linear;
	pointer-events: none;
}


.docEditor-topMenu--meetingDoc-participantIndicator{
	//margin-right: 6px; // align to doc
}















//

.docEditor-topMenu-center-title{
	display: flex;
	align-items: center;
	height: 32px;

	user-select: none;

	-webkit-app-region: no-drag;
}


.docEditor-topMenuContainer--show
.docEditor-topMenu-center-title{
	display: none;
}

.docEditor-topMenuContainer--hide
.docEditor-topMenu-center-title{
	display: flex;
}

.docEditor-topMenu-center-title-label{
	font-size: 15.5px;
	font-weight: $lato-medium;
	font-weight: $lato-semibold;	
	color: var(--text500);

	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-topMenu-center-title-bucketIconContainer{
	width: 24px;
	height: 24px;

	//background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;

	flex-grow: 0;
	flex-shrink: 0;

	font-size: 20px;
}



//



.docEditor-topMenu-center-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 10px;
	height: 100%;	
	pointer-events: none;
}

.docEditor-topMenu-center .docEditor--draft-metadataBtn{
	left: -8px; // optical align
}

.docEditor-topMenuContainer--sideMenuState--hidden
.docEditor-topMenu-center .docEditor--draft-metadataBtn{
	left: -9px; // rough, not sure why
}


//
// RIGHT SECTION

.docEditor-topMenu-right{
	margin-left: auto;

	// min-width: 10px;
	// flex-grow: 1;
	// flex-shrink: 0;
	// background: $tempb;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	box-sizing: border-box;
	padding-right: $interiorRightMarginToMenus-docEditor;

	position: relative;
	z-index: 10;
}


.docEditor-topMenuContainer--sideMenuState--hidden
.docEditor-topMenu-button--docThread-label{
	opacity: 0;
	transition: opacity 200ms linear;
	transition-delay: 1000ms;
}

.docEditor-topMenuContainer--sideMenuState--hidden:hover
.docEditor-topMenu-button--docThread-label{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}



button.docEditor-topMenu-button{
	flex-grow: 0;
	flex-shrink: 0;

	min-width: 34px;

	position: relative;
	z-index: 50;

	height: 30px;

	cursor: pointer;

	pointer-events: auto;

	font-size: $fontSize-topMenuBtn;
	font-weight: $lato-semibold;
	color: var(--text350);

	border-radius: 5px;

	-webkit-app-region: no-drag;

	//background: $tempr;

}

button.docEditor-topMenu-button--textOnly{
	padding-left: 8px;
	padding-right: 8px;
}

button.docEditor-topMenu-button--textAndIcon{
	padding-left: 6px;
	padding-right: 8px;
}


button.docEditor-topMenu-button--iconOnly{
	width: 30px;
}

button.docEditor-topMenu-button--iconOnly svg{
	width: 18px;
	height: 18px;

	fill: var(--text350);
}

button.docEditor-topMenu-button--iconOnly.docEditor-topMenu-button--stashed svg{
	width: 20px;
	height: 20px;
}

button.docEditor-topMenu-button--textAndIcon 
.docEditor-topMenu-button-iconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text350);
}

button.docEditor-topMenu-button--textAndIcon 
.docEditor-topMenu-button-iconContainer{
	margin-right: 4px;
}

button.docEditor-topMenu-button:hover{
	background: var(--250gray);
	color: var(--text600);
}

button.docEditor-topMenu-button--active,
button.docEditor-topMenu-button--active:hover,
button.docEditor-topMenu-button[data-state="open"]{
	background: var(--246gray);
	color: var(--text600);	
}

button.docEditor-topMenu-button[data-state="open"]{
	pointer-events: none;
}


// MEETING TOP ROW

button.docEditor-topMenu-button--allMeetings,
button.docEditor-topMenu-button--docChat,
button.docEditor-topMenu-button--recordingUpload{
	opacity: 0.75;
}


button.docEditor-topMenu-button--allMeetings:hover,
button.docEditor-topMenu-button--docChat:hover,
button.docEditor-topMenu-button--recordingUpload:hover{
	opacity: 1;
}


.docEditor-topMenu-button--allMeetings .docEditor-topMenu-button-iconContainer,
.docEditor-topMenu-button--docChat .docEditor-topMenu-button-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}

button.docEditor-topMenu-button.docEditor-topMenu-button--textAndIcon.docEditor-topMenu-button--docChat .docEditor-topMenu-button-iconContainer svg{
	width: 19px;
	height: 19px;
	transform: scaleX(-1.00);
}
button.docEditor-topMenu-button.docEditor-topMenu-button--textAndIcon.docEditor-topMenu-button--docChat .docEditor-topMenu-button-iconContainer{
	margin-right: 5px;
}

button.docEditor-topMenu-button--docChat--hasUnreads,
button.docEditor-topMenu-button--docChat--hasUnreads:hover{
	opacity: 1;
	//background: var(--250gray);
}



.docEditor-topMenu-button--docChat--hasUnreadsIndicator{
	position: absolute;
	// bit nasty
	//left: 16px;
	left: 21px;
	//left: 4px;
	top: 8px;
	width: 6px;
	height: 6px;
	background: var(--unreadCountTextColor);
	
	// background: red;
	border-radius: 50%;
	outline: 2px solid var(--bg-docEditor);
	z-index: 5;
}


// DROPDOWN
button.docEditor-topMenu-button--dropdown{
	width: 30px;
	min-width: 30px;
}


button.docEditor-topMenu-button--star,
button.docEditor-topMenu-button--docLink{
	width: 29px;
	min-width: 29px;
}

button.docEditor-topMenu-button--docLink{
	padding-right: 2px;
	width: 31px;
	min-width: 31px;
}

button.docEditor-topMenu-button--docLink svg{
	width: 18px;
	height: 18px;
	opacity: 0.7;
	transform: scale(0.9);
}

button.docEditor-topMenu-button--star svg{
	width: 18.5px;
	height: 18.5px;
	position: relative;
	// top: -1px;
}

button.docEditor-topMenu-button--star.docEditor-topMenu-button--inactiveStar svg{
	opacity: 0.7;
}

button.docEditor-topMenu-button--star.docEditor-topMenu-button--activeStar svg{
	opacity: 0.9;
}



button.docEditor-topMenu-button--linearIcon svg{
	width: 15.5px;
	height: 15.5px;

	opacity: 0.7;
	fill: var(--primary500);
}

//
//


@import 'DocEditor-TopMenu-LinearSummary';

// button.docEditor-topMenuContainer--rootDoc-issuesBtn{
// 	display: flex;
// 	height: 30px;

// 	// background: $tempr;
// 	padding-right: 6px;
// 	padding-left: 6px;
// }



// button.docEditor-topMenuContainer--rootDoc-issuesBtn:hover{
// 	background: var(--250gray);
// }

// .docEditor-topMenuContainer--rootDoc-issue{
// 	display: flex;
// 	align-items: center;
// 	height: 30px;
// 	padding-left: 2px;
// 	padding-right: 5px;
// }

// .docEditor-topMenuContainer--rootDoc-issue-iconContainer{
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// }

// .docEditor-topMenuContainer--rootDoc-issue-icon{
// 	width: 5px;
// 	height: 5px;
// 	border-radius: 2px;
// 	margin-right: 4px;

// }

// .docEditor-topMenuContainer--rootDoc-issue-icon--scope{
// 	background: var(--text100);

// }

// .docEditor-topMenuContainer--rootDoc-issue-icon--started{
// 	background: var(--orange--yarn);
// }

// .docEditor-topMenuContainer--rootDoc-issue-icon--completed{
// 	background: var(--purple--yarn);
// }



// .docEditor-topMenuContainer--rootDoc-issue-label{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.01em;
// 	color: var(--text500);
// 	opacity: 0.6;

// 	position: relative;
// 	top: -1px;
// }


//
//

.docEditor-topMenuContainer--rootDoc-targetDateBtn{
	// background: $tempr;
	height: 30px;
	border-radius: 5px;

	padding-left: 5px;
	padding-right: 7px;

	//margin-top: 2px;
	// padding-bottom: 1px;

	// margin-left: 6px;

	user-select: none;
	-webkit-app-region: no-drag;
}

.docEditor-topMenuContainer--rootDoc-targetDateBtn-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 4px;
}

.docEditor-topMenuContainer--rootDoc-targetDateBtn-iconContainer svg{
	width: 17px;
	height: 17px;
	
	fill: var(--text300);
	opacity: 0.5;
	//margin-top: -2px;
}


.docEditor-topMenuContainer--rootDoc-targetDateBtn-label{
	font-size: 15.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: var(--text500);

	opacity: 0.6;
}

.docEditor-topMenuContainer--rootDoc-targetDateBtn--warning{
	background: hsla(359, 83%, 68%, 0.05); // need it to be good in dark
	opacity: 1;
}

.docEditor-topMenuContainer--rootDoc-targetDateBtn--warning 
.docEditor-topMenuContainer--rootDoc-targetDateBtn-iconContainer svg{
	fill: var(--accentRed700);
	opacity: 0.6;
}

.docEditor-topMenuContainer--rootDoc-targetDateBtn--warning
.docEditor-topMenuContainer--rootDoc-targetDateBtn-label{
	color: var(--accentRed700);
	opacity: 0.8;
}

//
// RECORDING FILE UPLOAD








// .docEditor-topMenuContainer--rootDoc-teamBtn--targetDate{
// 	opacity: 0.5;
// }




// .docEditor-topMenuContainer--rootDoc-teamBtn-markerContainer
// .linearMarker{
// 	font-size: 15px;
// }

// .docEditor-topMenuContainer--rootDoc-teamBtn-markerContainer
// .linearMarker svg{
// 	width: 15px;
// 	height: 15px;
// }

// //



// .docEditor-topMenuContainer--rootDoc-teamBtn-label-light{
// 	margin-left: 4px;
// 	font-weight: $lato-medium;
// 	opacity: 0.5;
// }




//
// MOVE DROPDOWN TRIGGER POSITION

.docEditor-topMenu-movePopoverAnchor{
	position: absolute;
	bottom: 4px;
	right: 10px;
	width: 1px;
	height: 1px;
	pointer-events: none;
}










//
// RIGHT EDGE SPACER

.docEditor-topMenuContainer-rightEdgeSpacer{	
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempg;

	display: flex;
}

.docEditor-topMenuContainer-rightEdgeSpacer-teamPanelSpacer{
	width: $width-teamPanel;	
}

.docEditor-topMenuContainer-rightEdgeSpacer-threadPanelSpacer{
	// width set in coreLayout

}

.docEditor-topMenuContainer-rightEdgeSpacer-teamPanelNarrowSpacer{
	width: $width-teamPanel--narrow;
}


//
//

//
//
