$borderRadius-btn-selectionMenu: 6px;
$border-btn-selectionMenu: 1px solid rgb(240,238,238);


.hoverMenu{
	position: absolute;

	//background: rgba(0,0,255,0.5);
	border-radius: 4px;
	z-index: 99999;

	display: flex;
	justify-content: center;

	// width: 400px;
}

.hoverMenu--hidden,
.hoverMenu--hidden *{
	opacity: 0;
	pointer-events: none !important;
}


// .hoverMenu * {
// 	display: none;
// }

.docEditor-selectionMenu{
	user-select: none;
	cursor: pointer;
	

	z-index: 10;


	height: 29px;

	font-size: 14.5px;
	font-weight: $lato-semibold;

	background: var(--bgFloatingMenu);
	
	border-radius: $borderRadius-btn-selectionMenu;
	
	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);

	box-shadow: var(--boxShadowFloatingMenu);

	display: flex;
}


.docEditor--showNewCommentInput .docEditor-selectionMenu{
	visibility: hidden;
	pointer-events: none;
}


button.docEditor-selectionMenu-btn{
	height: 100%;

	position: relative;

	background: var(--bgFloatingMenu);

	color: var(--text400);
}

button.docEditor-selectionMenu-btn:hover{
	background: var(--hoverBGFloatingMenu);
}

button.docEditor-selectionMenu-btn:disabled{
	opacity: 0.5;
	pointer-events: none;
}

// LABEL AND ICON
button.docEditor-selectionMenu-btn--iconAndLabel{
	padding-left: 8px;
	padding-right: 10px;
	// override for now as short menu
	padding-left: 10px;
	padding-right: 12px;	
}

button.docEditor-selectionMenu-btn--iconAndLabel
.docEditor-selectionMenu-btn-iconContainer{
	margin-right: 4px;
}

// ICON ONLY
button.docEditor-selectionMenu-btn--iconOnly{
	width: 38px;
}

// LABEL ONLY 
button.docEditor-selectionMenu-btn--labelOnly{
	padding-left: 8px;
	padding-right: 8px;
}

button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--discuss{
	padding-left: 10px;
	padding-right: 12px;

	// width: 160px;
}

// BORDERS

// refactor if start or end with a group

.docEditor-selectionMenu-btnGroup:first-child
button.docEditor-selectionMenu-btn:first-child{
	border-top-left-radius: calc(#{$borderRadius-btn-selectionMenu} - 1px);
	border-bottom-left-radius: calc(#{$borderRadius-btn-selectionMenu} - 1px);
	border-right: 1px solid var(--insideLineFloatingMenu);
	margin-left: 0px;
	border-left: none;
	// padding-left: 1px;
	box-sizing: content-box;
}


.docEditor-selectionMenu-btnGroup:last-child
button.docEditor-selectionMenu-btn:last-child{
	border-top-right-radius: calc(#{$borderRadius-btn-selectionMenu} - 1px);
	border-bottom-right-radius: calc(#{$borderRadius-btn-selectionMenu} - 1px);
	//border-left: 1px solid var(--insideLineFloatingMenu);
	border-right: none;
	padding-right: 1px;
	box-sizing: content-box;
}

button.docEditor-selectionMenu-btn{
	border-right: 1px solid var(--insideLineFloatingMenu);
	border-left: 1px solid var(--insideLineFloatingMenu);

	margin-left: -1px;
	margin-right: 0px;
}

button.docEditor-selectionMenu-btn:hover{
	z-index: 5;
	color: var(--text600);
}

// BUTTON GROUP
.docEditor-selectionMenu-btnGroup{
	display: flex;
	height: 100%;
	align-items: center;
}

.docEditor-selectionMenu-btnGroup
button.docEditor-selectionMenu-btn{
	border-left: none;
	border-right: none;
}

.docEditor-selectionMenu-btnGroup
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--iconOnly{
	width: 32px;
}

.docEditor-selectionMenu-btnGroup
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--iconOnly.docEditor-selectionMenu-btn--newThread{
	width: 36px;
}

.docEditor-selectionMenu-btnGroup
button.docEditor-selectionMenu-btn:first-child{
	border-left: 1px solid var(--insideLineFloatingMenu);
}

.docEditor-selectionMenu-btnGroup
button.docEditor-selectionMenu-btn:last-child{	
	border-right: 1px solid var(--insideLineFloatingMenu);
}




// ICONS

.docEditor-selectionMenu-btn-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 5;
}

button.docEditor-selectionMenu-btn 
.docEditor-selectionMenu-btn-iconContainer svg{
	width: 17px;
	height: 17px;
	fill: var(--text400);
}

.docEditor-selectionMenu-btn-iconContainer svg.icon--name--commentLayered #outline,
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--stashLayered #outline,
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #outline{
	fill: var(--text400);
}

.docEditor-selectionMenu-btn-iconContainer svg.icon--name--commentLayered #background,
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--stashLayered #background,
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #background{
	fill: var(--fillIconBGFloatingMenu);
}

.docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #main{
	fill: var(--fillIconBGFloatingMenu);
	stroke: var(--text400);
}

//

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--thread{	
	height: 18px;
	width: 18px;
}

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--linkArrow,
button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--commentLayered{	
	height: 16px;
	width: 16px;
}

//
// Refactor?
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #inner{
	fill: rgb(240,240,245);	
}

[data-theme="dark"] .docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #inner{
	fill: rgb(80,80,82);	
}
//

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA{	
	height: 19px;
	width: 19px;
	margin-left: 1px;
}



button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--codeBlock{	
	height: 15.5px;
	width: 15.5px;
}

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--reply{
	width: 16px;
	height: 16px;
	transform: scaleX(-1);
}

// Refactor?

// Refactor?
button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
	fill: var(--text100);
}
// [data-theme="dark"] button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
// 	fill: rgba(255,255,255,0.05);
// }

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #box{
	fill: rgba(0,0,0,0.05);
}
[data-theme="dark"] button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #box{
	fill: rgba(255,255,255,0.05);
}
//

button.docEditor-selectionMenu-btn 
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--suggest{
	width: 14px;
	height: 14px;	
}

button.docEditor-selectionMenu-btn 
.docEditor-selectionMenu-btn-iconContainer svg.icon--name--slackIcon{
	width: 14px;
	height: 14px;
	opacity: 0.8;
}

button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--broadcast{	
	height: 16px;
	width: 16px;
}


//
// NEW THREAD
button.docEditor-selectionMenu-btn--newThread{
	width: 122px;
	padding-left: 8px;
}

button.docEditor-selectionMenu-btn--addComment{
	padding-left: 5px;
}

//
// EMOJI 
button.docEditor-selectionMenu-btn--emoji{
	font-size: 16.25px;
}

button.docEditor-selectionMenu-btn--emoji:nth-child(2){
	font-size: 18.5px;
}

button.docEditor-selectionMenu-btn--emoji:nth-child(2)
.docEditor-selectionMenu-btn-label{
	position: relative;
	top: -1px;
}


button.docEditor-selectionMenu-btn--emoji:nth-child(3){
	font-size: 16px;
}

button.docEditor-selectionMenu-btn--emoji:nth-child(4){
	font-size: 19px;
}

button.docEditor-selectionMenu-btn--emoji:nth-child(5){
	// font-size: 22px;
}


//
// ACTIVE BG 

.docEditor-selectionMenu-btn-activeBG{
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	background: var(--primary500);
	background: var(--text300);
	opacity: 0.15;
	z-index: 0;

	border-radius: 3px;
}

button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatH1,
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatH2,
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--code,
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--codeBlock{
	fill: var(--text600);
	opacity: 1;
}

button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #inner{
	fill: darken(rgb(240,240,245),8%);	
}

[data-theme="dark"] 
button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--blockDecoration #inner{
	fill: rgb(255,255,255);
}


button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
	fill: var(--text300);
	//fill: rgb(255,255,255);
}
// [data-theme="dark"] button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
// 	fill: rgba(255,255,255,0.05);
// }

button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #box{
	fill: var(--210gray);
}

[data-theme="dark"] button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
	fill: rgba(255,255,255,0.5)
}

[data-theme="dark"] button.docEditor-selectionMenu-btn.docEditor-selectionMenu-btn--active .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #box{
	fill: rgba(255,255,255,0.15);
}


//
// TOOLTIP
// manually done as there were some problems with radix


	.tooltip.tooltip--textSelectionUI.tooltip--inlineSelect[data-side="top"]{
		margin-top: -2px;
	}



.docEditor-selectionMenu button.docEditor-selectionMenu-btn .docEditor-selectionMenu-btn-tooltip{
	opacity: 0;
}

.docEditor-selectionMenu:hover button.docEditor-selectionMenu-btn .docEditor-selectionMenu-btn-tooltip{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 500ms;
}



button.docEditor-selectionMenu-btn .docEditor-selectionMenu-btn-tooltip{
	visibility: hidden;
}

button.docEditor-selectionMenu-btn:hover .docEditor-selectionMenu-btn-tooltip{
	visibility: visible;
}

.docEditor-selectionMenu-btn-tooltip{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -25px;
	height: 16px; // same regardless of whether there's a shortcut
}

[data-theme="dark"]{
	.docEditor-selectionMenu-btn-tooltip{
		top: -28px;
	}	
}


.docEditor-selectionMenu .docEditor-selectionMenu-btn-tooltip .keyboardShortcutTagContainer:first-child{
	// when only keyboard shortcut tag, center it
	margin-left: -2px;
}