//
// MESSAGES
//

:root{	
	
	// Panel
	--sideBorder-messagePanel: rgb(232,232,238); // side borders
	--bottomBorder-header-messagePanel: rgb(236,236,242); // header bottom border
	--boxShadow-header-messagePanel: 0px 1px 2px 0px rgba(0,0,0,0.08);

	--sideBorder-header-messagePanel-dndButton: rgb(236,236,242); // header bottom border

	
	// Message
	//--messageHoverBG: rgb(250,248,250);
	--messageHoverBG: rgb(252,250,252);
	--highlightedMessageBG: rgb(247,245,250);

	// Thread Button BG
	--messageThreadBtnBG: rgb(250,250,252);
	--messageThreadBtnHoverBG: rgb(255,255,255);
	--messageThreadBtnHoverBorder: rgb(230,230,240);

	// Reply
	--messageReplyPreviewBG: rgb(247,245,250);
	--messageReplyPreviewHoverBG: rgb(244,242,247);

	//  Notification Red
	--unreadCountTextColor: rgb(224,29,90);
	--unreadCountBorderColor: rgb(240,143,172);

	//Reaction button
	--message-reactionBtnBG: rgb(250,248,250);

	//Selection Previews (messages/selections/image/video/etc)
	--messageSelectionPreview-hoverBG: rgba(33,30,64,0.04);		


	//Misc
	--messageBlockQuoteDividerBG: rgb(221,221,221);

	--messageMediaZoomedBG: rgba(0,0,0,0.5);
	--messageMediaZoomedBoxShadow: 		
		0px 1px 1px rgba(3, 7, 18, 0.02),
  	0px 5px 4px rgba(3, 7, 18, 0.03),
  	0px 12px 9px rgba(3, 7, 18, 0.05),
  	0px 20px 15px rgba(3, 7, 18, 0.06),
  	0px 32px 24px rgba(3, 7, 18, 0.08)
  ;

}

// DARK THEME

[data-theme="dark"]{
	
	// Panel
	--sideBorder-messagePanel: rgb(52,52,58);
	--bottomBorder-header-messagePanel: rgb(48,48,52);
	--boxShadow-header-threadsPanel: 0px 1px 2px 0px rgba(0,0,0,0.24);

	--sideBorder-header-messagePanel-dndButton: rgb(64,64,70); // header bottom border

	// Message
	--messageHoverBG: rgba(255,255,255,0.03);
	--highlightedMessageBG: rgba(255,255,255,0.4);

	// Reply
	--messageReplyPreviewBG: rgb(255,255,255,0.05);
	--messageReplyPreviewHoverBG: rgb(255,255,255,0.08);
	
	//  Notification Red
	--unreadCountTextColor: rgb(221,70,117);
	--unreadCountBorderColor: rgb(120,48,70);

	// Thread Button BG
	--messageThreadBtnBG: rgb(255,255,255,0.05);	
	--messageThreadBtnHoverBG: rgb(255,255,255,0.08);
	--messageThreadBtnHoverBorder: rgb(255,255,255,0.12);

	//Reaction button
	--message-reactionBtnBG: rgb(255,255,255,0.08);

	//Misc
	--messageBlockQuoteDividerBG: rgb(50,50,50);
}	