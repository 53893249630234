//
// DOC DRAWING

$innerMarginForUI-docMedia: 6px;

.doc-drawingContainer{
	// nothing in here at the moment		
}

.doc-drawingContainer{
	user-select: none;
	margin-left: calc((#{$textWidth-doc} - #{$fullWidth-doc}) / 2);  // not sure this is right
	

	margin-top: 16px;
	margin-bottom: 16px;

	position: relative;
}

.doc-drawingContainer--inline{
	cursor: pointer;
}

.doc-drawing,
.doc-drawing-svgContainer{	
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.doc-drawing-svgContainer svg{
	width: 100%;
	height: 100%;

	background: var(--bg-docEditor) !important;
}

// .doc-image{	
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   right: 0px;
//   bottom: 0px;
// }

// img.doc-image-image{	
//   width: 100%;
//   // visibility: hidden;
//   border-radius: 6px;
// }

// .doc-image-overlayBorder{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	right: 0px;
// 	border: 1px solid rgba(0,0,0,0.1);

// 	border-radius: 6px;

// 	pointer-events: none;

// 	display: none; // not sure about it
// }

//@import 'Doc-Image-ReactionBar';
//@import 'Doc-Image-TopRightMenu';



// WHEN IN DRAFT STATE, ONLY SHOW ELEMENTS ON HOVER
// ALSO PUT REACTIONS INSIDE IMAGE
// ALSO SHOW COMMENT MENU IN FEEDBACK MODE ON HOVER TOO

.doc-drawingContainer--defaultMode
.doc-image-reactionBarContainer{
	position: absolute;
	bottom: $innerMarginForUI-docMedia;
}

.doc-drawingContainer--defaultMode .doc-image-reactionBar,
.doc-drawingContainer--defaultMode .doc-media-topRightMenu,
.doc-drawingContainer--readOnlyMode .doc-media-topRightMenu{
	opacity: 0;
	transition: opacity 100ms linear;
}

.doc-drawingContainer--defaultMode.doc-drawingContainer--mouseMoved:hover .doc-image-reactionBar,
.doc-drawingContainer--defaultMode.doc-drawingContainer--mouseMoved:hover .doc-media-topRightMenu,
.doc-drawingContainer--readOnlyMode.doc-drawingContainer--mouseMoved:hover .doc-media-topRightMenu{
	opacity: 1;
	transition: opacity 50ms linear;
}






//
// INSERT MODAL

.docEditor-insertImageModal{
	width: calc(100vw - 60px);
	height: calc(100vh - 60px);
	margin-top: -20px;

	background: $white;
	z-index: 5000;

	border-radius: 12px;
	
	display: flex;
	align-items: center;
	justify-content: center;

}

.docEditor-insertImageModal .c-jOyZnp{
	border-radius: 12px;
}


//
// DRAWING CANVAS

.docEditor-drawingCanvasContainer{
	//width: calc(100% - 8px - 8px);// weird, seems like their UI elements are inset
	width: 100%;
	height: 100%;
	//height: calc(100% - 8px - 8px); 
	// background: $tempr;
	position: relative;
}

// .docEditor-drawingCanvasContainer #TD-MenuPanel{
// 	// don't think we want this?
// 	display: none;
// }

.docEditor-drawingCanvas-saveBtn{
	position: fixed;
	z-index: 50000;
	bottom: 5px;
	left: 50%;
	transform: translateX(-50%);
	width: 240px;

	background: var(--primary500);
	font-weight: $lato-semibold;
	background: rgba(20,20,20,0.8);
	color: $white;
	
	padding-top: 8px;
	padding-bottom: 8px;

	border-radius: 8px;
}

.docEditor-drawingCanvas-saveBtn:hover{
	background: rgba(20,20,20,1);
}

// ZOOMED MODAL
// COPIED FROM IMAGE



//
//
// ZOOMED IMAGE MODAL
// some hacky stuff for drawing modal

button.doc-zoomedDrawingModal-closeBtn{
	position: fixed;
	top: 0px;
	right: 0px;
	height: 28px;
	width: 28px;

	//z-index: 9000;
	// background: red;
}

.doc-zoomedDrawingModal-closeBtn-inner{
	position: absolute;
	bottom: 0px;
	left: 0px;

	border-radius: 50%;
	height: 24px;
	width: 24px;

	background: rgb(75,70,70);

	display: flex;
	align-items: center;
	justify-content: center;

	

	opacity: 0.95;
}

.doc-zoomedDrawingModal-closeBtn-inner svg{
	height: 14px;
	width: 14px;
	fill: $white;

	opacity: 0.8;
}

button.doc-zoomedDrawingModal-closeBtn:hover
.doc-zoomedDrawingModal-closeBtn-inner{
	background: rgb(45,40,40);
	opacity: 1;
}


button.doc-zoomedDrawingModal-closeBtn:hover
.doc-zoomedDrawingModal-closeBtn-inner svg{
	opacity: 1;
}


.doc-zoomedDrawingModalContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	
	justify-content: center;

	box-sizing: border-box;

	z-index: 2000;
	//background: rgb(0,0,0,0.25);
	background: rgb(0,0,0,0.5);
	// background: rgb(0,0,0,0.85);

	padding-top: 28px;
	padding-bottom: 14px;

	//pointer-events: none;

	overflow-y: hidden;
	overflow-x: hidden;

	// padding-left: 14px;
	// padding-right: 14px;
}

.doc-zoomedDrawingModal{
	position: absolute;
	top: 28px;	
	//bottom: 6px;
	bottom: 28px; // need more space to escape on drawing as escape is broken
	left: 14px;
	right: 14px;

	display: flex;
	//pointer-events: none;
}

.doc-zoomedDrawingModal-captureOutsideClick{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $invisible;
}


.doc-zoomedDrawingModal-contentsContainer{
	overflow-y: scroll;

	margin: 0 auto;

	position: relative;
	z-index: 10;

	height: 100%;

	box-sizing: border-box;
	// padding-top: 8px;
	// padding-left: 8px;
	// padding-right: 8px;
	padding-bottom: 48px;

	
	background: $lightGray-bg;
	border-radius: 10px;
	// border-radius: 18px;
	border: 1px solid $white;
	border: 1px solid rgb(60,60,60);
	border: 1px solid rgb(155,150,150);
	border: 1px solid rgba(0,0,0,0.5);

	box-shadow:
	  0px 2.8px 2.2px rgba(0, 0, 0, 0.028),
	  0px 6.7px 5.3px rgba(0, 0, 0, 0.04),
	  0px 12.5px 10px rgba(0, 0, 0, 0.05),
	  0px 22.3px 17.9px rgba(0, 0, 0, 0.06),
	  0px 41.8px 33.4px rgba(0, 0, 0, 0.072),
	  0px 100px 80px rgba(0, 0, 0, 0.1)
	;

	// pointer-events: none;


	// box-sizing: border-box;
	

	// min-width: 900px;

	// pointer-events: auto;
}




.doc-zoomedDrawingModal-canvasContainer{
	width: calc(100vw - 24px - 24px);
	height: calc(100vh - 91px);


	//max-height: 800px;

	position: relative;

	background: $tempb;
}


// .doc-zoomedDrawingModal-image{
// 	position: relative;
// 	min-width: 800px;
// 	width: auto;
// 	max-width: calc(100vw - 24px - 24px);

// 	// display: grid;

// }




// img.doc-zoomedDrawingModal-image-image{
// 	width: 100%;

// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	//visibility: hidden;
// 	// height: 100%;
// 	//object-fit: contain;
// 	border-radius: 8px;
// 	border-bottom-right-radius: 0px;
// 	border-bottom-left-radius: 0px;
// 	// border-radius: 12px;
// 	// position: relative;

// 	max-height: calc(100vh - 91px);

// 	border-bottom: 1px solid var(--line500);

// }

.doc-zoomedDrawingModal-header{
	width: $fullWidth-doc;
	display: flex;
	justify-content: center;
	margin: 0 auto;

	margin-top: 6px;

	padding-bottom: 10px;
	border-bottom: 1px solid var(--line500);	
}

.doc-zoomedDrawingModal-header-title{
	cursor: default;
	margin-right: auto;
}

.doc-zoomedDrawingModal-header-title-title{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);

	margin-bottom: 1px;
}

.doc-zoomedDrawingModal-header-title-commentCount{
	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text200);

	user-select: none;
}

.doc-zoomedDrawingModal
.doc-image-reactionBarContainer{
	margin-top: 0px;
	width: auto;
}

.doc-zoomedDrawingModal
.doc-image-reactionBar{
	border-radius: 6px;
}

.doc-zoomedDrawingModal-commentsContainer{
	width: $fullWidth-doc;
	
	height: 1000px;
	background: $tempb;

	margin: 0 auto;
	margin-top: 14px;

}

// TLDRAW CUSTOMIZATION
// might be hacky

.c-dwQeTN{
	box-sizing: border-box;
}
