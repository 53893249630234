.docEditor-messagePanel-inputBoxHintsBar{	
	height: 26px;

	width: 100%;
	box-sizing: border-box;
	padding-left: $height-messagePanel-inputBox-outerInset;
	padding-right: $height-messagePanel-inputBox-outerInset;	
	padding-bottom: 1px;

	display: flex;
	align-items: center;

	font-size: 12px;
	font-weight: $lato-regular;
	color: var(--text600);
}

.docEditor-messagePanel-inputBoxHintsBar-typingIndicator{
	padding-left: 10px;
}
.docEditor-messagePanel-inputBoxHintsBar-typingIndicator-user{
	font-weight: $lato-bold;
}

.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut{
	margin-left: auto;
	padding-right: 10px;

	transition: opacity 75ms linear;
}

.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut--show{
	opacity: 0.6;
}

.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut--hide{
	opacity: 0;
}

.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut-shortcut{
	font-weight: $lato-semibold;
}


.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut-shortcut-iconContainer{

}

.docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut-shortcut-iconContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text600);

	position: relative;
	top: 2px;
}
