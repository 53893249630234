//
// ALSO USED IN INPUT BOX
.message-content-selection,
.docEditor-messagePanel-inputBox-selectionPreview{
	display: flex;
	justify-content: flex-start;

	position: relative;
	//background: $tempr;

	box-sizing: border-box;

	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 4px;
	padding-right: 4px;

	border: 1px solid $invisible;
	border-radius: 4px;


}

// for overscroll on input box, want to block out text behind
.docEditor-messagePanel-inputBox-selectionPreview{
	
	//box-shadow: -7px -4px 0px 3px var(--bg-docEditor); // slightly hacky way to block text underneath
}

.docEditor-messagePanel-inputBox-selectionPreview-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 4px;
	z-index: 1;
}

.docEditor-messagePanel-inputBox-selectionPreview-backgroundBlock{
	position: absolute;
	bottom: -2px;
	left: -8px;
	top: -8px;
	right: 2px;
	z-index: -1px;
	background: var(--bg-docEditor);
}


.message-content-selection{
	margin-top: 5px;
	margin-bottom: 3px;
	width: calc(100% - 4px);
	left: 8px;
}
.docEditor-messagePanel-inputBox-selectionPreview{
	width: calc(100% - 19px);
	left: 14px;

	margin-top: 7px;

	cursor: pointer;

	z-index: 100;
}


// .docEditor-messagePanel-inputBox-selectionPreview--docSelection,
// .docEditor-messagePanel-inputBox-ghostSpacer .message-content-selection--docSelection{
// 	// this needs some extra work because it's embedded in prosemirror input box which has padding on it

// 	//padding-left: 12px;
// 	//padding-top: $defaultTopInternalPadding-messagePanel-inputBox;
// 	//padding-right: 12px;
// 	//padding-bottom: 8px;	

// 	width: calc(100% - 19px + 12px + 12px);
// 	margin-left: -12px;
// 	margin-top: calc((#{$defaultTopInternalPadding-messagePanel-inputBox} * -1) + 7px);

// 	margin-bottom: 7px;
// }

// .docEditor-messagePanel-inputBox-ghostSpacer .message-content-selection--docSelection{
// 	left: unset;
// }


$height-imageSelectionPreview: 		36px;
$height-videoSelectionPreview: 		46px;
$height-messageSelectionPreview: 	28px;
$height-todoItemSelectionPreview: 28px;

$height-messageSelectionPreview-docSelection1Line: 28px;
$height-messageSelectionPreview-docSelection2Lines: 49px;
$height-messageSelectionPreview-docSelection3Lines: 71px;

.docEditor-messagePanel-inputBox-selectionPreview--image{
	height: $height-imageSelectionPreview;
}

.docEditor-messagePanel-inputBox-selectionPreview--video{
	height: $height-videoSelectionPreview;
}

.docEditor-messagePanel-inputBox-selectionPreview--message{
	height: $height-messageSelectionPreview;
}

.docEditor-messagePanel-inputBox-selectionPreview--todoItem{
	height: $height-todoItemSelectionPreview;
}


// We add some padding to top of input box to make space for the selection previews
// It's a bit verbose so tidying it away
@import 'Messages-MessageContent-Selections-InputBoxPadding';


//
// Increase height and top padding of input box

.message-content-selection:hover,
.docEditor-messagePanel-inputBox-selectionPreview:hover .docEditor-messagePanel-inputBox-selectionPreview-bg{
	background: var(--messageSelectionPreview-hoverBG);	
}


.docEditor-messagePanel-inputBox-selectionPreview:hover .docEditor-messagePanel-inputBox-selectionPreview-bg{
	transition: background 0ms linear;
	transition-delay: 100ms;
}

.message-content-selection:hover{
	transition: background 0ms linear;
	transition-delay: 50ms;
}

.message-content-selection-bar,
.docEditor-messagePanel-inputBox-selectionPreview-bar{
	position: absolute;
	left: -7.5px;
	top: 0px;
	bottom: 0px;
	width: 3px;
	border-radius: 1px;
	background: var(--210gray);

	z-index: 10;
}

// .message-content-selection:hover
// .message-content-selection-bar{
// 	opacity: 0;
// }

.message-content-selection-content,
.docEditor-messagePanel-inputBox-selectionPreview-content{	
	display: flex;
	align-items: center;

	width: 100%;

	font-weight: $lato-regular;
	font-size: 15.25px;
	color: var(--text200);
	letter-spacing: 0.004em;
	text-align: left;
	line-height: 1.4;

  cursor: pointer;

  user-select: none;  

  word-break: break-word;


}

.docEditor-messagePanel-inputBox-selectionPreview-content{
	box-sizing: border-box;
	padding-right: 0px; // make space for delete button
	position: relative;
  z-index: 5;
}

.docEditor-messagePanel-inputBox-selectionPreview:hover 
.docEditor-messagePanel-inputBox-selectionPreview-content{	
	padding-right: 18px;
	transition: padding-right 0ms linear;
	transition-delay: 100ms;
}

//
// MESSAGE SELECTIONS AKA REPLY
.message-content-selection--message .message-content-selection-content,
.docEditor-messagePanel-inputBox-selectionPreview--message .docEditor-messagePanel-inputBox-selectionPreview-content{
	// opacity: 0.65;

	display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  font-weight: $lato-medium;
}

.message-content-selection--docSelection .message-content-selection-content,
.docEditor-messagePanel-inputBox-selectionPreview--docSelection .docEditor-messagePanel-inputBox-selectionPreview-content{
	// opacity: 0.65;

	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  font-weight: $lato-medium;
}

.message-content-selection--docSelection--ghost .message-content-selection-content,
.message-content-selection--docSelection--preview  .message-content-selection-content{
	display: block;
	-webkit-line-clamp: unset;
	-webkit-box-orient: unset;

	overflow: unset;
}


.docEditor-messagePanel-inputBox-selectionPreview--docSelection .docEditor-messagePanel-inputBox-selectionPreview-content,
.docEditor-messagePanel-inputBox-ghostSpacer .message-content-selection--docSelection .message-content-selection-content--docSelection,
.docEditor-messagePanel-inputBox-ghostSelectionMeasurement .docEditor-messagePanel-inputBox-selectionPreview-content{
	padding-right: 18px; // it's weird for new line break from hover...
  // although maybe should just show some sort of hover div
}

// Deleted message
.message-content-selection--message--deleted{
	pointer-events: none;
}

.message-content-selection--message--deleted
.message-content-selection-content{
	opacity: 0.5;
}



//
// MEDIA SELECTIONS

.docEditor-messagePanel-inputBox-selectionPreview-content-rightContainer{
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  box-sizing: border-box;

}


.message-content-selection-content-mediaTitle--untitled,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle--untitled{
	opacity: 0.75;
	font-weight: $lato-regular;
}

.message-content-selection-content-mediaTitle,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  

.message-content-selection-content-mediaContainer,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer{
	margin-right: 8px;
	flex-grow: 0;
	flex-shrink: 0;
}

.message-content-selection-content-mediaContainer--image,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--image,
.message-content-selection-content-mediaContainer--figmaEmbed,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--figmaEmbed{
	width: 40px;
	height: 30px;
}

.message-content-selection-content-mediaContainer--video,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--video{
	width: 70px;
	height: 40px;
}

.message-content-selection-content-media,
.docEditor-messagePanel-inputBox-selectionPreview-content-media{
	width: 100%;
	height: 100%;

	object-fit: cover;
	border-radius: 3px;
}


.message-content-selection-content-mediaDuration,
.message-content-selection-content-videoTimestamp,
.docEditor-messagePanel-inputBox-selectionPreview-content-mediaDuration,
.docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp{
	font-size: 13.75px;
	margin-top: -2px;
	opacity: 0.75;
}

.message-content-selection-content-videoTimestamp,
.docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp{
	display: flex;
	align-items: center;
	font-weight: $lato-semibold;
}

.message-content-selection-content-videoTimestamp-iconContainer,
.docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.message-content-selection-content-videoTimestamp-iconContainer svg,
.docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp-iconContainer svg{
	width: 14px;
	height: 14px;
	margin-top: -0.5px;
	fill: var(--text200);
}

//
// TO DO ITEM

.message-content-selection-content-toDoItemCheckboxContainer,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckboxContainer{
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 6px;
}

.message-content-selection-content-toDoItemCheckbox,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox{
	width: 14px;
	height: 14px;
	border-radius: 4px;
	border: 1px solid var(--line400);


	display: flex;
	align-items: center;
	justify-content: center;
}

.message-content-selection-content-toDoItemCheckbox--unchecked,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox--unchecked{
	border: 1px solid var(--line400);
}

.message-content-selection-content-toDoItemCheckbox--checked,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox--checked{
	border: 1px solid var(--line600);
	background: var(--line600);
}

.message-content-selection-content-toDoItemCheckbox svg,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox svg{
	width: 11px;
	height: 11px;
	stroke: white;
	z-index: 10;

	stroke-width: 10px;
}


.message-content-selection-content-toDoItemLabel,
.docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemLabel{
  overflow: hidden;
	text-overflow: ellipsis;
  white-space: nowrap;
}





//

.message-content-selection:hover .message-content-selection-content,
.docEditor-messagePanel-inputBox-selectionPreview:hover .docEditor-messagePanel-inputBox-selectionPreview-content{
	opacity: 1;
}

//
// Delete button on selection preview
.docEditor-messagePanel-inputBox-selectionPreview-deleteBtnContainer{
	//only used for doc selection
}

.docEditor-messagePanel-inputBox-selectionPreview-deleteBtn{
	position: absolute;
	right: 0px;
	top: 0px;
	width: 22px;
	height: 22px;
	z-index: 5;
	cursor: pointer;
}

.docEditor-messagePanel-inputBox-selectionPreview-deleteBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	opacity: 0.4;
}

.docEditor-messagePanel-inputBox-selectionPreview 
.docEditor-messagePanel-inputBox-selectionPreview-deleteBtn{
	opacity: 0;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}

.docEditor-messagePanel-inputBox-selectionPreview:hover 
.docEditor-messagePanel-inputBox-selectionPreview-deleteBtn{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}

.docEditor-messagePanel-inputBox-selectionPreview:hover 
.docEditor-messagePanel-inputBox-selectionPreview-deleteBtn:hover svg{
	opacity: 0.8;
}

//
// DOC SELECTION GHOSTS

.docEditor-messagePanel-inputBox-selectionPreview-content{
	// background: $tempb;
}

.docEditor-messagePanel-inputBox-selectionPreview-content--ghost{
	position: absolute;
	top: 2px;

	top: 80px;
	left: 4px;
	width: calc(100% - 26px); // messy

	pointer-events: none;
	visibility: hidden;
}



//
//

.message-content-selection--docSelection-container{
	position: relative;
	// background: $tempr;
}

.message-content-selection--docSelection-container--popoverOpen{
	pointer-events: none;
}

.message-content-selection--docSelection--ghost{
	position: absolute;
	top: 0px;
	left: 0px;
	height: auto;
	visibility: hidden;
	pointer-events: none;

}

.message-content-selection--docSelection-popoverFinder{
	position: absolute;
	top: 0px; // center alignment i think
	left: 0px;
	width: 1px;
	height: 1px;
	pointer-events: none;
	opacity: 0;
}

.message-content-selection--docSelection-popover{
	width: 400px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.message-content-selection--docSelection-popover-header{	
	font-size: 11.5px;
	font-weight: $lato-bold;
	color: var(--text400);
	text-transform: uppercase;
	letter-spacing: 0.025em;

	padding-left: 2px;
	margin-bottom: 3px;
	
	opacity: 0.5;
	user-select: none;
}

.message-content-selection--docSelection--preview,
.message-content-selection--docSelection--preview:hover{
	background: var(--doc-bgEditor);	
}


.message-content-selection--docSelection--preview .message-content-selection-content,
.message-content-selection--docSelection--preview .message-content-selection-content:hover{
	color: var(--text500);
	cursor: text;	
	user-select: auto;
}