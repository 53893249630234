// .sideMenu-activeViewStatus--doc-userList{
// 	margin-top: 6px;
// }

//
// VERY SIMILAR TO SIDE MENU TAB LIST

.sideMenu-activeViewStatus--doc-userRow{
	display: flex;
	background: $tempr;

	// width: calc(100% - 10px);
	// margin-left: 6px;

	width: calc(100% - 10px);
	margin-left: 6px;

	border-radius: 5px;

	height: 27px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	
	padding-left: 2px;
	padding-right: 6px;

	cursor: pointer;

	user-select: none;

	position: relative;
}

.sideMenu-activeViewStatus--doc-userList-syncActiveList{
	margin-bottom: 6px;
}

.sideMenu-activeViewStatus--doc-userRow{
	background: none;
}

.sideMenu-activeViewStatus--doc-userRow:hover{
	background: var(--bgSideMenuTabHover);
}

//
// AVATAR

.sideMenu-activeViewStatus--doc-userRow-avatarContainer{
	width: 21px;
	height: 100%;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	//margin-left: -1px;

	padding-left: 6px;
	padding-right: 6px;
	margin-right: 1px;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.sideMenu-activeViewStatus--doc-userRow-avatar{
	height: 21px;
	width: 21px;
}

.sideMenu-activeViewStatus--doc-userRow--syncState--offline
.sideMenu-activeViewStatus--doc-userRow-avatar{	
	opacity: 0.75;
}


img.sideMenu-activeViewStatus--doc-userRow-avatar-avatar{
	height: 100%;
	width: 100%;

	object-fit: contain;

	border-radius: 4px;
}

.sideMenu-activeViewStatus--doc-userRow--syncMode
img.sideMenu-activeViewStatus--doc-userRow-avatar-avatar{
	clip-path: url(#docUserRowStatusClip); // make space for dots
}



.sideMenu-activeViewStatus--doc-userRow-avatarStatus{


	position: absolute;

	width: 8px;
	height: 8px;
	border-radius: 50%;

	background: red;
	// bottom: -2px;
	// right: -4px;

	// bottom: -4px;
	// right: -5px;

	bottom: 1px;
	right: 3px;

	z-index: 5;
}

.sideMenu-activeViewStatus--doc-userRow--syncState--online
.sideMenu-activeViewStatus--doc-userRow-avatarStatus{
	background: red;
}

.sideMenu-activeViewStatus--doc-userRow--syncState--online
.sideMenu-activeViewStatus--doc-userRow-avatarStatus--accent--purple{
	background: var(--accentPurple500);
}

.sideMenu-activeViewStatus--doc-userRow--syncState--online
.sideMenu-activeViewStatus--doc-userRow-avatarStatus--accent--green{
	background: var(--accentGreen500);	
}

.sideMenu-activeViewStatus--doc-userRow--syncState--online
.sideMenu-activeViewStatus--doc-userRow-avatarStatus--accent--orange{
	background: var(--accentOrange500);	
}

//

.sideMenu-activeViewStatus--doc-userRow--syncState--offline
.sideMenu-activeViewStatus--doc-userRow-avatarStatus{
	background: $invisible;
	border: 1px solid rgba(0,0,0,0.26);

	width: 6px;
	height: 6px;

	
}


// USER NAME

.sideMenu-activeViewStatus--doc-userRow-usernameContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideMenu-activeViewStatus--doc-userRow-username{
	font-size: var(--fontSizeSideMenuTab);
	font-weight: var(--textWeightSideMenuTab);

	color: var(--textColorSideMenuTab);
	
	letter-spacing: var(--textSpacingSideMenuTab);

	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideMenu-activeViewStatus--doc-userRow-username{
	font-weight: $lato-medium;
}

.sideMenu-activeViewStatus--doc-userRow--syncActive
.sideMenu-activeViewStatus--doc-userRow-username{
	color: var(--textColorSideMenuTab);
	font-weight: $lato-semibold;
	opacity: 1;
}

.sideMenu-activeViewStatus--doc-userRow--syncState--online
.sideMenu-activeViewStatus--doc-userRow-username{
	font-weight: var(--textWeightSideMenuTabActive);
	letter-spacing: var(--textSpacingSideMenuTabActive);
	font-weight: $lato-semibold;
}

.sideMenu-activeViewStatus--doc-userRow-username-currentUser{
	font-weight: $lato-medium;
	opacity: 0.35;
	margin-left: 4px;
}

.sideMenu-activeViewStatus--doc-userRow--syncState--offline
.sideMenu-activeViewStatus--doc-userRow-username{
	opacity: 0.5;
}



//
// NOTIFICATIONS

.sideMenu-activeViewStatus--doc-userRow-notificationContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 4px;
}

.sideMenu-activeViewStatus--doc-userRow-notification{
	height: 18px;
	min-width: 25px;

	padding-left: 5px;
	padding-right: 5px;

	box-sizing: border-box;

	border-radius: 9px;	

	font-size: 12.5px;	
	font-weight: $lato-bold;

	color: var(--textColorNotificationSideMenuTab);	
	background: var(--bgNotificationSideMenuTab);

	display: flex;
	align-items: center;
	justify-content: center;
}


.sideMenu-activeViewStatus--doc-userRow-notification--accentColor--purple{
	background: var(--accentPurple500);
}

.sideMenu-activeViewStatus--doc-userRow-notification--accentColor--green{
	background: var(--accentGreen500);	
}

.sideMenu-activeViewStatus--doc-userRow-notification--accentColor--orange{
	background: var(--accentOrange500);	
}




//
// SEND BUTTON

.sideMenu-activeViewStatus--doc-userRow-sendBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;

	padding-left: 12px;
	padding-right: 10px;

	background: $tempr;

	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 13.5px;
	font-weight: $lato-medium;

	color: var(--text400);
}

.sideMenu-activeViewStatus--doc-userRow-sendBtn svg{
	width: 13px;
	height: 13px;

	fill: var(--text400);
	opacity: 0.6;

	margin-left: 6px;
}

.sideMenu-activeViewStatus--doc-userRow-avatarContainer ~
.sideMenu-activeViewStatus--doc-userRow-sendBtn{
	opacity: 0;
}

.sideMenu-activeViewStatus--doc-userRow-avatarContainer:hover ~
.sideMenu-activeViewStatus--doc-userRow-sendBtn{
	opacity: 1;
	background: $invisible;
}

// .sideMenu-activeViewStatus--doc-userRow:hover 
// .sideMenu-activeViewStatus--doc-userRow-sendBtn:hover{
// 	background: rgb(0,0,0,0.09);
// }

// .sideMenu-activeViewStatus--doc-userRow:hover 
// .sideMenu-activeViewStatus--doc-userRow-sendBtn:hover svg{
// 	opacity: 1;
// }