



.doc-whiteboardPreviewContainer{
	margin-top: 16px;
	margin-bottom: 16px;

	position: relative;
	z-index: 5;

	width: $fullWidth-doc;
	background: var(--background-whiteboardPreview);
	border-radius: 4px;
	margin-left: $negativeEdgeMarginTextToDoc;

	user-select: none;

	cursor: pointer;
}



.doc-whiteboardPreview-preview{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 720px;
	// height: 500px;

	position: relative;
	// cursor: pointer;
}

.doc-whiteboardPreview-preview-inner,
.doc-whiteboardPreview-preview-inner *{
 	pointer-events: none !important;
 	user-select: none !important;
}

.doc-whiteboardPreview-preview-loading{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;

	z-index: 100;

	//background: rgb(255, 50, 50);
	background: var(--250gray);	
	// background: blue;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

[data-theme="dark"]{
	.doc-whiteboardPreview-preview-loading{
		background: rgb(36,33,41);
	}
}

// .doc-whiteboardPreview-preview
// .tl-container{
// 	width: 100%;
// 	height: 100%;
// }
// .doc-whiteboardPreview-preview svg{
// 	width: 720px;
// 	max-height: 500px;
// }

// .doc-whiteboardPreview-preview svg,
// .doc-whiteboardPreview-preview svg * {
// 	user-select: none;
// 	pointer-events: none;
// }
.doc-whiteboardPreviewContainer .doc-whiteboardPreview-preview,
.doc-whiteboardPreviewContainer .tl-canvas,
.doc-whiteboardPreviewContainer .tl-background{
	background: var(--bg-docEditor) !important;
}

.doc-whiteboardPreviewContainer:hover .doc-whiteboardPreview-preview,
.doc-whiteboardPreviewContainer:hover .tl-canvas,
.doc-whiteboardPreviewContainer:hover .tl-background{
	background: $invisible !important;
}


.doc-whiteboardPreview--selectionBG,
.doc-whiteboardPreview--hoverBG{
	position: absolute;
	z-index: 0;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;

	border-radius: 7px;

	pointer-events: none;
	background: var(--mediaNodeSelectedBG);

	opacity: 0;
}

.doc-whiteboardWrapper.ProseMirror-nodeInSelection
.doc-whiteboardPreview--selectionBG{
	opacity: 0.5;
}

.doc-whiteboardWrapper.ProseMirror-nodeInSelection .doc-whiteboardPreviewContainer .doc-whiteboardPreview-preview,
.doc-whiteboardWrapper.ProseMirror-nodeInSelection .doc-whiteboardPreviewContainer .tl-canvas,
.doc-whiteboardWrapper.ProseMirror-nodeInSelection .doc-whiteboardPreviewContainer .tl-background{
	background: $invisible !important;
}


.doc-whiteboardPreview--hoverBG{
	background: var(--253gray);
	opacity: 0;
}

.doc-whiteboardPreview--hoverBG{
	// transition: opacity 50ms linear;
	// transition-delay: 200ms;
}

	


.doc-whiteboardPreviewContainer:hover
.doc-whiteboardPreview--hoverBG{
	opacity: 1;
	// transition: opacity 50ms linear;
	// transition-delay: 200ms;
}




.doc-whiteboardPreviewContainer:hover .doc-media-topRightMenu{
	opacity: 1;
	transition: opacity 50ms linear;
	//transition-delay: 200ms;
}

.doc-whiteboardPreviewContainer .doc-media-topRightMenu{
	// opacity: 1;
	opacity: 0;
	transition: opacity 50ms linear;
	//transition-delay: 200ms;

	z-index: 5000;
}



