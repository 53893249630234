.linearProjectPreviewPopoverContainer:focus{
	outline: none;
}

.linearProjectPreviewPopoverContainer,
.linearProjectPreviewPopoverContainer *{
	pointer-events: none !important;
}



.linearProjectPreviewPopover{
	width: 325px;

	//height: 400px;
	//min-height: 370px;

	box-sizing: border-box;

	padding-top: 14px;
	padding-bottom: 24px;

	padding-left: 20px;
	padding-right: 20px;

	cursor: default;
	user-select: none;

	border-radius: 6px;;
}

// 


.linearProjectPreviewPopover-topLineContainer{
	display: flex;
	align-items: center;
	width: 100%;
}

.linearProjectPreviewPopover-markerContainer{
	width: 36px;
	height: 36px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.linearProjectPreviewPopover-markerContainer
.linearMarkerContainer--markerType--emoji{
	font-size: 28px;
}

.linearProjectPreviewPopover-markerContainer
.linearMarkerContainer--markerType--icon svg{
	width: 28px;
	height: 28px;
}

//

.linearProjectPreviewPopover-projectStatusTagContainer{
	margin-left: auto;
	transform: scale(0.9);
	transform-origin: center right;
}

//

.linearProjectPreviewPopover-title{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: var(--text600);

	margin-top: 9px;
	//margin-bottom: 3px;
	margin-bottom: 8px;
	line-height: 1.2;
}

//

.linearProjectPreviewPopover-teamsContainer{
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	margin-bottom: 7px;
}

.linearProjectPreviewPopover-teamTag{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 22px;
	padding-left: 5px;
	padding-right: 6px;

	//border: 1px solid var(--line300);
	background: var(--250gray);
	border-radius: 4px;

}

.linearProjectPreviewPopover-teamTag-markerContainer{
	margin-right: 2px;
	margin-top: 1px;
	display: flex;
	align-items: center;
	
}

.linearProjectPreviewPopover-teamTag-markerContainer
.linearMarkerContainer{
	font-size: 13px;	
}

.linearProjectPreviewPopover-teamTag-markerContainer
.linearMarkerContainer svg{
	width: 13px;
	height: 13px;
}

.linearProjectPreviewPopover-teamTag-label{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text400);
	margin-left: 2px;
	opacity: 0.75;
}

//


.linearProjectPreviewPopover-descriptionContainer{
	min-height: 20px;
}

.linearProjectPreviewPopover-description{
	font-size: 14.5px;
	font-weight: $lato-regular;
	color: var(--text500);
	letter-spacing: 0.008em;
	opacity: 0.75;

  //line-height: 1.3;
	line-height: 1.25;
  text-align: left;
}

.linearProjectPreviewPopover-descriptionContainer--noDescription
.linearProjectPreviewPopover-description{
	opacity: 0.6;
}

//

.linearProjectPreviewPopover-divider{
	width: 100%;
	height: 1px;
	background: var(--line300);

	margin-top: 11px;
	margin-bottom: 11px;
}

//

.linearProjectPreviewPopover-projectLead-title,
.linearProjectPreviewPopover-members-title{
	color: var(--text500);

	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	margin-bottom: 6px;
}


.linearProjectPreviewPopover-projectLead-user,
.linearProjectPreviewPopover-member{
	display: flex;
	align-items: center;
}

.linearProjectPreviewPopover-projectLead-user-avatarContainer,
.linearProjectPreviewPopover-member-avatarContainer{
	width: 24px;
	height: 24px;

	margin-right: 8px;
	border-radius: 4px;
	overflow: hidden;
}

.linearProjectPreviewPopover-projectLead-user-avatar,
.linearProjectPreviewPopover-member-avatar{
	width: 24px;
	height: 24px;
}

.linearProjectPreviewPopover-projectLead-user-name,
.linearProjectPreviewPopover-member-name{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//

.linearProjectPreviewPopover-members{
	margin-top: 12px;
}

.linearProjectPreviewPopover-member--noMember{
	opacity: 0.5;
	font-weight: $lato-medium;
}

.linearProjectPreviewPopover-member{
	margin-bottom: 6px;
}

// .linearProjectPreviewPopover-projectTagContainer{
// 	display: flex;

// 	width: 100%;

// 	margin-bottom: 4px;	
// }

// .linearProjectPreviewPopover-projectTag{
// 	display: flex;
// 	align-items: center;

// 	background: var(--253gray);
	
// 	padding-left: 6px;
// 	padding-right: 6px;
// 	border-radius: 4px;

// 	height: 22px;

// 	white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;


// }

// .linearProjectPreviewPopover-projectTag-markerContainer{
// 	font-size: 12px;
// 	margin-right: 5px;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .linearProjectPreviewPopover-projectTag-markerContainer svg{
// 	width: 12px;
// 	height: 12px;
// }

// .linearProjectPreviewPopover-projectTag-label{
// 	font-size: 14px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);
// 	opacity: 0.8;
// 	letter-spacing: 0.008em;

// 	white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

// }

// //

// .linearProjectPreviewPopover-identifier{
// 	margin-top: 7px;
// 	margin-bottom: 9px;

// 	font-size: 13.75px;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.01em;
// 	color: var(--primary600);
// }

// [data-theme="dark"] .linearProjectPreviewPopover-identifier{
// 	color: rgb(111, 173, 249); // to do, check on primary 600 in dark
// }


// //

// .linearProjectPreviewPopover-title{
// 	font-size: 18px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.005em;
// 	color: var(--text600);

// 	text-align: left;

// 	overflow: hidden;

// 	display: -webkit-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;  

//   line-height: 1.2;
// }

// //

// .linearProjectPreviewPopover-summaryRow{
// 	display: flex;
// 	align-items: center;
// 	width: 100%;

// 	margin-top: 18px;
// }

// //

// .linearProjectPreviewPopover-summaryRow-assignee,
// .linearProjectPreviewPopover-summaryRow-status,
// .linearProjectPreviewPopover-summaryRow-priority{
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;
// 	margin-right: 11px;
	
// }

// .linearProjectPreviewPopover-summaryRow-assignee-avatar{
// 	width: 20px;
// 	height: 20px;
// 	margin-right: 4px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .linearProjectPreviewPopover-summaryRow-assignee-avatar img{
// 	width: 20px;
// 	height: 20px;
// 	border-radius: 3px;
// }

// .linearProjectPreviewPopover-summaryRow-assignee-avatar svg{
// 	width: 20px;
// 	height: 20px;
// 	fill: var(--text500);
// 	opacity: 0.5;
// }

// //

// .linearProjectPreviewPopover-summaryRow-status-iconContainer{
// 	transform: scale(0.9);
// }


// //
// .linearProjectPreviewPopover-summaryRow-assignee-name,
// .linearProjectPreviewPopover-summaryRow-status-label,
// .linearProjectPreviewPopover-summaryRow-priority-label{
// 	font-size: 14px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);
// 	opacity: 0.9;
// 	margin-left: 2px;
// }

// .linearProjectPreviewPopover-summaryRow-assignee--assigned
// .linearProjectPreviewPopover-summaryRow-assignee-name{
// 	font-weight: $lato-semibold;
// 	opacity: 1;
// }

// .linearProjectPreviewPopover-summaryRow-assignee--unassigned
// .linearProjectPreviewPopover-summaryRow-assignee-name{
// 	font-weight: $lato-semibold;
// 	opacity: 0.65;
// 	margin-left: -1px;
// }



// // 


// }

// //

// .linearProjectPreviewPopover-descriptionContainer{
// 	max-height: 211px;
// 	overflow: hidden;
// 	position: relative;
// 	width: 100%;

// 	min-height: 50px;
// }

// .linearProjectPreviewPopover-descriptionContainer--noDescription{
	
// }

// .linearProjectPreviewPopover-description{
	
// 	font-size: 14.5px;
// 	font-weight: $lato-regular;
// 	color: var(--text500);
// 	letter-spacing: 0.008em;
// 	opacity: 0.75;
	
// 	// display: -webkit-box;
//   // -webkit-line-clamp: 8;
//   // -webkit-box-orient: vertical;

//   line-height: 1.3;
//   text-align: left;
// }


// .linearProjectPreviewPopover-descriptionContainer--noDescription{
// 	opacity: 0.6;
// }


// .linearProjectPreviewPopover-description-para{
// 	margin-bottom: 10px;
// }

// .linearProjectPreviewPopover-descriptionOverflowGradient{
	
// 	background: $tempr;

// 	position: absolute;
// 	top: 211px; // only show if overflow
// 	left: 0px;
// 	right: 0px;
	
// 	margin-top: -50px;
// 	height: 50px;

// 	background: linear-gradient(
//     to bottom,
//     hsla(0, 0%, 100%, 0) 0%,
//     hsla(0, 0%, 100%, 0.013) 8.1%,
//     hsla(0, 0%, 100%, 0.049) 15.5%,
//     hsla(0, 0%, 100%, 0.104) 22.5%,
//     hsla(0, 0%, 100%, 0.175) 29%,
//     hsla(0, 0%, 100%, 0.259) 35.3%,
//     hsla(0, 0%, 100%, 0.352) 41.2%,
//     hsla(0, 0%, 100%, 0.45) 47.1%,
//     hsla(0, 0%, 100%, 0.55) 52.9%,
//     hsla(0, 0%, 100%, 0.648) 58.8%,
//     hsla(0, 0%, 100%, 0.741) 64.7%,
//     hsla(0, 0%, 100%, 0.825) 71%,
//     hsla(0, 0%, 100%, 0.896) 77.5%,
//     hsla(0, 0%, 100%, 0.951) 84.5%,
//     hsla(0, 0%, 100%, 0.987) 91.9%,
//     hsl(0, 0%, 100%) 100%
//   );

// }

// [data-theme="dark"] .linearProjectPreviewPopover-descriptionOverflowGradient{
// 	background: linear-gradient(
//     to bottom,
//     rgba(36,31,38, 0) 0%,
//     rgba(36,31,38, 0.013) 8.1%,
//     rgba(36,31,38, 0.049) 15.5%,
//     rgba(36,31,38, 0.104) 22.5%,
//     rgba(36,31,38, 0.175) 29%,
//     rgba(36,31,38, 0.259) 35.3%,
//     rgba(36,31,38, 0.352) 41.2%,
//     rgba(36,31,38, 0.45) 47.1%,
//     rgba(36,31,38, 0.55) 52.9%,
//     rgba(36,31,38, 0.648) 58.8%,
//     rgba(36,31,38, 0.741) 64.7%,
//     rgba(36,31,38, 0.825) 71%,
//     rgba(36,31,38, 0.896) 77.5%,
//     rgba(36,31,38, 0.951) 84.5%,
//     rgba(36,31,38, 0.987) 91.9%,
//     rgba(36,31,38, 1) 100%
//   );

// }

// //

// .linearProjectPreviewPopover-labelsContainer{
// 	display: flex;
// 	flex-wrap: wrap;
// 	align-items: center;	

// 	margin-top: 12px;
// }

// .linearProjectPreviewPopover-labels-label{
// 	height: 23px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	border-radius: 4px;
// 	padding-left: 7px;
// 	padding-right: 10px;

// 	background: var(--250gray);

// 	margin-right: 8px;
// 	margin-bottom: 4px;
// 	position: relative;
// }

// .linearProjectPreviewPopover-labels-label-dot{
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 50%;

// 	margin-right: 5px;

// 	position: relative;
// 	z-index: 5;
// }

// .linearProjectPreviewPopover-labels-label-name{
// 	font-size: 14px;
// 	font-weight: $lato-semibold;
// 	color: var(--text500);
// 	opacity: 0.8;

// 	position: relative;
// 	top: -0.5px;
// 	z-index: 5;
// }
