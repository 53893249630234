.doc-zoomedFigmaModal-canvasOuterContainer{
	background: black;
	cursor: pointer;
}

.doc-zoomedFigmaModal-canvasContainerMeasurer{
	position: absolute;
	width: 100%;
	height: 100%;
}

//

.doc-zoomedFigmaModal-canvasInnerContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	// outline: 1px solid rgba(255,255,255,0.2);

	background: black;	

	width: 100%;
	height: 100%;

	overflow: hidden;	
}

.doc-zoomedFigmaModal-canvas{
	background: $tempb;
	background: black;
	
	position: relative;
	//will-change: transform;
	cursor: pointer;
}

//
//
.doc-zoomedFigmaModal-cropContainer{
	position: absolute;
	z-index: 100;
	will-change: transform;
}

//
// BLACK IMAGE BLOCK
.doc-zoomedFigmaModal-cropContainer-imageBlockerContainer{
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.5;

	z-index: 50;
	pointer-events: none;
}

.doc-zoomedFigmaModal-cropContainer-imageBlocker{
	position: absolute;	
	background: black;
}

.doc-zoomedFigmaModal-cropContainer-imageBlocker--top{
	bottom: 100%;
	height: 9999px;
	left: -9999px;
	right: -9999px;
}

.doc-zoomedFigmaModal-cropContainer-imageBlocker--centerLeft{
	right: 100%;
	width: 9999px;
	top: 0px;
	bottom: 0px;	

	bottom: -1px;
	top: -1px;
}

.doc-zoomedFigmaModal-cropContainer-imageBlocker--centerRight{
	left: 100%;
	width: 9999px;
	top: 0px;
	bottom: 0px;	

	bottom: -1px;
	top: -1px;
}


.doc-zoomedFigmaModal-cropContainer-imageBlocker--bottom{
	top: 100%;
	height: 9999px;
	left: -9999px;
	right: -9999px;
}

//

.doc-zoomedFigmaModal-croppedImageContainer{
	position: absolute;
	// left, top, height, width set in JS
}

img.doc-zoomedFigmaModal-image{
	width: 100%;
	height: 100%;
	// object-fit: contain;
}

.doc-zoomedFigmaModal-canvas-bgCloseCropBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	// background: $tempr;

	cursor: pointer;
}


//
// CROP UI CONTAINER
// the handles and things

.doc-zoomedFigmaModal-canvas-cropUIContainer{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);

	z-index: 500;	
	cursor: pointer;
}

.doc-zoomedFigmaModal-canvas-cropUIContainer--show{
	opacity: 1;
	transition: opacity 100ms linear;
}

.doc-zoomedFigmaModal-canvas-cropUIContainer--hide{
	opacity: 0;
	transition: opacity 0ms linear;
	pointer-events: none;
}

.doc-zoomedFigmaModal-canvas-cropUI{
	position: absolute;

}

//
// CROP OUTLINE
// dashed outline around crop UI

$dashWidth-cropOutline: 1px;
$dashLength-cropOutline: 10px;

.doc-zoomedFigmaModal-cropOutline{    		
	position: absolute;

	width: 100%;
	height: 100%;

	z-index: 500;

	pointer-events: none;

  background: linear-gradient(90deg, black 50%, transparent 50%), 
              linear-gradient(90deg, black 50%, transparent 50%), 
              linear-gradient(0deg, black 50%, transparent 50%), 
              linear-gradient(0deg, black 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: $dashLength-cropOutline $dashWidth-cropOutline, $dashLength-cropOutline $dashWidth-cropOutline, $dashWidth-cropOutline $dashLength-cropOutline, $dashWidth-cropOutline $dashLength-cropOutline;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  border-radius: 0px;
  padding: 0px;

  //outline: 100vw solid rgba(0,0,0,.5);
  //animation: dash 50s linear infinite reverse;
}



//
// DEV

.doc-zoomedFigmaModal-cropToggle{
	position: fixed;	

	z-index: 9999;

	background: rgba(255,255,255,0.2);
	width: 180px;
	height: 40px;

	border-radius: 4px;

	color: $white;
}

.doc-zoomedFigmaModal-cropToggle--active{
	background: var(--primary500);

	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
}

.doc-zoomedFigmaModal-cropToggle--crop{
	top: 20px;
	right: 20px;
}

//
// HANDLES // reFACTOR NAME


.doc-zoomedFigmaModal-canvas-cropUI-cornerHandle{
	position: absolute;
	transform: scale(0.3);
	border-radius: 3px;
	background: $white;
	// box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.35);
	box-shadow: 0px 0px 0px 4px black;
	
}

.doc-zoomedFigmaModal-canvas-cropUI-cornerHandle--topLeft{
	top: -6px;
	left: -6px;
	cursor: nwse-resize;
}

.doc-zoomedFigmaModal-canvas-cropUI-cornerHandle--topRight{
	top: -6px;
	right: -6px;
	cursor: nesw-resize;
}

.doc-zoomedFigmaModal-canvas-cropUI-cornerHandle--bottomLeft{
	bottom: -6px;
	left: -6px;
	cursor: nesw-resize;
}

.doc-zoomedFigmaModal-canvas-cropUI-cornerHandle--bottomRight{
	bottom: -6px;
	right: -6px;
	cursor: nwse-resize;
}

.doc-zoomedFigmaModal-canvas-cropUI-edgeContainer{
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;	
}

.doc-zoomedFigmaModal-canvas-cropUI-edge{
	
	cursor: grab;

	
	//box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.25);
	//box-shadow: 0px 0px 0px 1px #0D8CE9;
	
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 8px;
	padding-right: 8px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.doc-zoomedFigmaModal-canvas-cropUI-edge-inner{
	border-radius: 1px;
	background: $white;
	box-shadow: 0px 0px 0px 1px black;
}

.doc-zoomedFigmaModal-canvas-cropUI-edge--topBottom
.doc-zoomedFigmaModal-canvas-cropUI-edge-inner{
	width: 24px;
	height: 4px;
}



.doc-zoomedFigmaModal-canvas-cropUI-edge--leftRight
.doc-zoomedFigmaModal-canvas-cropUI-edge-inner{
	width: 4px;
	height: 24px;
}
