//$signInBlue: #3d4fdb; // more linear purple
$signInBlue: #0B99FF; // more linear purple

$signInPageBounds: 20px;

// .appLoginMidStatePage{
// 	width: 100%;
// 	height: 100%;
// 	background: rgba(252,252,255,0.8);
// }

.signInPageContainer-tempButton{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 50px;
	height: 50px;
	z-index: 50;
	background: $invisible;
}


// .signInPanel-iconOuterAnimateContainer,
// .signInPanel-titleOuterAnimateContainer{
// 	position: absolute;
// 	top: $signInPageBounds;
// 	left: $signInPageBounds;
// 	right: $signInPageBounds;
// 	bottom: $signInPageBounds;
// }

.signInPanel_OuterAnimateContainer,
.signInPanel-iconOuterAnimateContainer,
.signInPanel-titleOuterAnimateContainer{
	z-index: 50;
}

//
// SYSTEM LIGHT


:root{	
//--login-panelBG: rgb(252,252,255);
// --login-bg-gradientCenter: rgb(244, 244, 248);
// --login-bg-gradientEdge: rgb(238, 238, 250);

--login-panelBG: rgba(252,252,255,0.8);

// --login-bg-gradientCenter: rgba(2, 2, 5, 0.02);
// --login-bg-gradientEdge: rgba(3, 2, 5, 0.05);

--login-bg-gradientCenter: rgb(255, 255, 255);
--login-bg-gradientEdge: rgb(210, 210, 230);

--login-panelGlowBoxShadow: 0px 0px 90px -20px rgba(22,123,240,0.3);

--login-iconFill: rgb(90,90,100);

--login-titleColor: rgb(50,50,70);
--login-descriptionColor: rgb(140,140,150);

--login-buttonTextColor: rgb(50,50,65);
--login-buttonBoxShadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
--login-buttonBg: rgb(255,255,255);
--login-buttonBorder: rgb(170,170,190);
--login-buttonFontWeight: 700;

--login-buttonIconFill: #3d4fdb;

--login-buttonHoverBg: rgb(255,255,255);
--login-buttonHoverBorder: rgb(180,180,190);
}


// //
// // MAC DARK - YARN LIGHT 
// // opaque
// @media (prefers-color-scheme: dark){
// :root[data-theme="light"] {
// 	--appBG-sideMenuMode: rgb(243,242,246);
// 	// --appBG-sideMenuMode: rgb(246,242,242);
// 	--appBG-sideMenuHidden: rgb(244,242,244);
// 	}
// }


// //
// // SYSTEM DARK

// @media (prefers-color-scheme: dark){
// :root{
// 	--login-panelBG: rgb(12,12,17);
// 	--login-bg-gradientCenter: rgb(18, 20, 28);
// 	--login-bg-gradientEdge: rgb(19, 16, 24);

// 	--login-iconFill: rgba(255,255,255,0.8);

// 	--login-panelBG: rgb(2,2,5,0.4);
// 	--login-bg-gradientCenter: rgba(2, 2, 5, 0.34);
// 	--login-bg-gradientEdge: rgba(3, 2, 5, 0.44);

// 	--login-panelGlowBoxShadow: 0px 0px 90px -20px #3d4fdb;

// 	--login-titleColor: rgb(255,255,255);
// 	--login-descriptionColor: #9fa8f3;

// 	--login-buttonTextColor: rgb(255,255,255);
// 	--login-buttonBg: #3d4fdb;
// 	--login-buttonBorder: #2138E8;
// 	--login-buttonFontWeight: 600;
// 	--login-buttonBoxShadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

// 	--login-buttonIconFill: rgb(255,255,255);
	
// 	--login-buttonHoverBg: #475BF5;
// 	--login-buttonHoverBorder: #475BF5;
// 	}
// }



.signInPageContainer{
	position: absolute;
	width: 100%;
	height: 100%;

	// background: radial-gradient(
  //   circle at 40% 30%,
  //   var(--login-bg-gradientCenter),  // center
  //   var(--login-bg-gradientEdge) 80vw
  // );

  background: radial-gradient(
    circle at 50% 50%,
    var(--login-bg-gradientCenter),  // center
    var(--login-bg-gradientEdge) 60vw
  );
}

.signInPage{
	position: absolute;
	width: 100%;
	height: 100%;
	transform-origin: 50% 50%;
	// transform: scale(0.5);
	will-change: transform;

	transition: all 200ms linear;
}

.signInPage.signInPage--enter{
	opacity: 1;
	transform: scale(1);
}

.signInPage.signInPage--pre{
	opacity: 0;	
	transform: scale(0.8);
}



.signInPageBounds{
	position: absolute;
	top: $signInPageBounds;
	left: $signInPageBounds;
	bottom: $signInPageBounds;
	right: $signInPageBounds;
}

.signInPanel{
	position: absolute;
	cursor: default;
}

.signInPanel-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	background: var(--login-panelBG);
	cursor: default;

	border: 1px solid $signInBlue;
}

.signInPanel-glow{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $tempr;

	box-shadow: var(--login-panelGlowBoxShadow);
	opacity: 0.25;
}




.signInPanel-titleContainer{
	position: absolute;

	z-index: 10;

	// background: $tempb;
	cursor: default;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid $signInBlue;
}

.signInPanel-title-sizeTag{
	position: absolute;
	
	height: 17px;

	bottom: -24px;

	border-radius: 3px;

	background: $signInBlue;
	left: 50%;
	transform: translateX(-50%);
	color: $white;

	display: flex;
	align-items: center;

	font-size: 11.5px;
	font-weight: $lato-semibold;
	padding-left: 6px;
	padding-right: 6px;

}

.signInPanel-titleContainer textarea{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	box-sizing: border-box;
	// color: $tempg;
	
	text-align: center;

	z-index: 20;

	resize: none;

	color: var(--login-titleColor);

	background: none;
	border: none;
}

.signInPanel-titleContainer textarea:focus{
	outline: none;
}

.signInPanel-titleContainer textarea.signInPanel-title,
.signInPanel-titleContainer .signInPanel-titleInnerGhost{
	padding-top: 5px;
	font-size: 44px;
	font-weight: $lato-heavy;
}

.signInPanel-titleInnerGhostContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	pointer-events: none;
}

span.signInPanel-titleInnerGhost{
	//color: red !important;
	position: relative;
	// background: $tempr;
	z-index: 50;
	width: auto;
	color: $invisible !important;
}

.signInPanel-titleInnerGhost-line{
	position: absolute;
	left: 0px;
	top: 50px;
	right: 0px;

	height: 1px;
	background: $signInBlue;
}

.signInPanel-iconContainer{
	// background: $tempg;
	z-index: 50;
	cursor: default;
	padding: 8px;
	box-sizing: border-box;
	border: 1px solid $signInBlue;
	transform-origin: 50% 50%;
}


.signInPanel-icon{
	width: 100%;
	aspect-ratio: 55/41;
	// background: $tempr;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.signInPanel-icon-bar{		
	height: 22%;
	flex-grow: 0;
	flex-shrink: 0;
	background: var(--login-iconFill);

	border-radius: 2px;
}

.signInPanel-icon-bar--one{
	width: 100%;
}

.signInPanel-icon-bar--two{
	width: 100%;	
}

.signInPanel-icon-bar--three{	
	width: 50%;

}


.signInPanel-buttonOuterContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 10%;
	z-index: 100;

	display: flex;
	align-items: center;
	justify-content: center;
}

.signInPanel-buttonContainer{
	
	cursor: pointer;
	user-select: none;

}


button.signInPanel-button{
	width: 280px;
	height: 44px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 7px;
	border: 1px solid var(--login-buttonBorder);

	background: var(--login-buttonBg);

	box-shadow: var(--login-buttonBoxShadow);
	user-select: none;
}

button.signInPanel-button:hover{
	background: var(--login-buttonHoverBg);
	border: 1px solid var(--login-buttonHoverBorder);
}

.signInPanel-button-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -6px;
	margin-right: 8px;
}

.signInPanel-button-iconContainer svg{
	width: 18px;
	height: 18px;
}

.signInPanel-button-iconContainer--white svg{
	fill: var(--login-buttonIconFill);
}

// @media (prefers-color-scheme: light){
// 	.signInPanel-button-iconContainer--color{
// 		display: block;
// 	}
// 	.signInPanel-button-iconContainer--white{
// 		display: none;
// 	}
// }

// @media (prefers-color-scheme: dark){
// 	.signInPanel-button-iconContainer--color{
// 		display: none;
// 	}
// 	.signInPanel-button-iconContainer--white{
// 		display: block;
// 	}
// }


.signInPanel-button-label{
	font-size: 16.5px;
	font-weight: var(--login-buttonFontWeight);
	letter-spacing: 0.006em;
	color: var(--login-buttonTextColor);
	margin-top: 0px;
}

.signInPanel-buttonDescription{
	margin-top: 10px;
	font-size: 14.25px;
	font-weight: $lato-regular;
	//color: $white;

	color: var(--login-descriptionColor);
	letter-spacing: 0.008em;
	opacity: 1;
	user-select: none;	
	text-align: center;

	transition: all 500ms linear;
}

.signInPanel-buttonDescription--show{
	opacity: 1;
	transform: translateY(0px);
	transition-delay: 2000ms;
}


.signInPanel-buttonDescription--hide{
	opacity: 0;
	transform: translateY(-2px);
}

//
//

.signInPage-dragHandle{
	width: 18px;
	height: 18px;
	//background: $tempr;
	z-index: 200;	
	position: absolute;
}

.signInPage-dragHandle-inner{
	position: absolute;
	width: 6px;
	height: 6px;

	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	background: white;
	border: 1px solid $signInBlue;
}

//
// //
// .signInPanel--animate{
// 	position: absolute;
// }

// .signInPanel-dragHandleContainer{
// 	width: 18px;
// 	height: 18px;

// 	position: absolute;
// }

// .signInPanel-dragHandleContainer--topLeft{
// 	position: absolute;
// 	top: -9px;
// 	left: -9px;
// }

// .signInPanel-dragHandleContainer--topRight{
// 	position: absolute;
// 	top: -9px;
// 	right: -9px;
// }

// .signInPanel-dragHandleContainer--bottomLeft{
// 	position: absolute;
// 	bottom: -9px;
// 	left: -9px;
// }

// .signInPanel-dragHandleContainer--bottomRight{
// 	position: absolute;
// 	bottom: -9px;
// 	right: -9px;
// }

// .signInPanel{
// 	transition: opacity 500ms linear;
// }

// .signInPanel--animate{
// 	transition: opacity 500ms linear;
// 	transition-delay: 250ms;
// }


// .signInPage--showDraggable .signInPanel--animate{
// 	opacity: 0;	
// }
// .signInPage--showDraggable .signInPanel--draggable{
// 	opacity: 1;
// }
// .signInPage--hideDraggable .signInPanel--animate{
// 	opacity: 1;
// }
// .signInPage--hideDraggable .signInPanel--draggable{
// 	opacity: 0;
// }

// button.signInPanel-button{
// 	transition: opacity 750ms linear;
// 	transition-delay: 1600ms;

// 	z-index: 500;
// }

// .signInPage--hideDraggable button.signInPanel-button{
// 	opacity: 0;
// }

// .signInPage--showDraggable button.signInPanel-button{
// 	opacity: 1;
// }


// .signInPanel-buttonDescription{
// 	transition: opacity 1300ms linear;
// 	transition-delay: 2000ms;

// 	z-index: 500;
// }

// .signInPage--hideDraggable .signInPanel-buttonDescription{
// 	opacity: 0;
// }

// .signInPage--showDraggable .signInPanel-buttonDescription{
// 	opacity: 0.6;
// }


// .signInPanel-iconContainer{
// 	transition: opacity 600ms linear;
// 	transition-delay: 100ms;
// }

// .signInPage--hideDraggable .signInPanel-iconContainer{
// 	opacity: 0;
// }

// .signInPage--showDraggable .signInPanel-iconContainer{
// 	opacity: 1;
// }


// .signInPanel-titleContainer{
// 	transition: opacity 800ms linear;
// 	transition-delay: 800ms;
// }

// .signInPage--hideDraggable .signInPanel-titleContainer{
// 	opacity: 0;
// }

// .signInPage--showDraggable .signInPanel-titleContainer{
// 	opacity: 1;
// }


// .signInPanel-glow{
// 	transition: all 1000ms linear;
// 	transition-delay: 1000ms;
// }

// .signInPage--hideDraggable .signInPanel-glow{
// 	opacity: 0;
// 	transform: scale(0.85);
// }

// .signInPage--showDraggable .signInPanel-glow{
// 	opacity: 0.25;	
// 	transform: scale(1);
// }









.signInPage-versionNumber{
	position: absolute;

	left: 50%;
	transform: translateX(-50%);
	font-size: 15px;
	letter-spacing: 0.05em;

	font-weight: $lato-bold;
	color: var(--login-titleColor);

	opacity: 0.25;


	bottom: 40px;

	z-index: 10000;

	user-select: none;

}