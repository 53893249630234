.popoverContainer{

}

.popoverContainer:focus{
	outline: none;
}

.popoverContainer--hidden,
.popoverContainer--hidden *{
	pointer-events: none !important;
}

.popover{
	display: flex;
	flex-direction: column;

	align-items: flex-start;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);

	border-radius: 5px;
}

.popover:focus{
	outline: none;
}

//
// POPOVER ITEM
// maybe should refactor into dropdown stuff

.popover-itemList{
	width: 100%;
	box-sizing: border-box;
	padding-left: 4px;
	padding-right: 4px;
}

.popover-item{
	width: 100%;

	justify-content: flex-start;

	background: var(--bg-dropdown);
	height: 28px;
	box-sizing: border-box;
	padding: 0px;
	
	padding-left: 8px;
	padding-left: 6px;

	
	user-select: none;

	display: flex;
	position: relative;

	cursor: pointer;

	border-radius: 5px;	
}




.popover-item::disabled{
	opacity: 0.5;
	pointer-events: none;
}

.popover-item:hover{
	background: var(--hoverBG-item-dropdown);
	color: var(--text600);
}

.popover-item-iconContainer{
	width: 24px;
	height: 24px;
	
	border-radius: 4px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.popover-item-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text400);
	opacity: 0.75;
}


.popover-item-noIconSpacer{
	width: 6px;
}

.popover-item-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text400);
	line-height: 1.8;
}


// .teamPanel-userPopover-actionBtn:hover
// .teamPanel-userPopover-actionBtn-iconContainer svg{
// 	opacity: 1;
// }


.popover-item-iconContainer svg.icon--name--headphones,
.popover-item-iconContainer svg.icon--name--hash,
.popover-item-iconContainer svg.icon--name--forwardArrow{
	height: 14px;
	width: 14px;
}

.popover-item-iconContainer svg.icon--name--forwardArrow{
	height: 13px;
	width: 13px;
}

//


.popover-divider{
	width: calc(100% - 12px);
	margin-left: 8px;

	height: 1px;
	margin-top: 4px;
	margin-bottom: 4px;

	background: var(--text400);
	opacity: 0.1;	
}


//
// CHANNEL


.popoverContainer--addToChannel{
	margin-left: -2px;
}

.popover--addToChannel{
	width: 220px;
	padding-top: 6px;
	padding-bottom: 8px;
}

.popover-item--channel{
	height: 30px;
}
.popover-item--channel
.popover-item-label{
	font-size: 15px;
	margin-top: -1px;
}

.popover-item--channel .popover-item-iconContainer svg{
	width: 13px;
	height: 13px;
	opacity: 0.5;
}

.popover-item--channel--isCurrentChannel{
	display: none;
}

.popover-item--channel--private{
	opacity: 0.5;
	padding-left: 12px;
}

.popover-item--channel--private:hover{
	opacity: 1;
}