.onboardingModalTempBtn{
	position: fixed;
	z-index: 99999;
	background: $tempr;
	top: 100px;
	right: 0px;
	width: 100px;
	height: 100px;
}

.onboardingModalTempBtn:hover{

}

.onboardingModalContainer{	
	position: fixed;
	z-index: 9000;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--background-onboarding);

}

.onboardingModalContainer:focus{
	outline: none;
}

.onboardingModalContainer--hidden,
.onboardingModalContainer--hidden *{
	pointer-events: none !important;
}


.onboardingModal{
	width: 100%;
	height: 100%;

	cursor: default;
	user-select: none;
	position: relative;
}

.onboardingModal-devControls{
	position: absolute;
	bottom: 20px;
	right: 20px;

	color: var(--text600);
	display: flex;
	align-items: center;

	z-index: 100;

	opacity: 0;
}

.onboardingModal-devControls:hover{
	opacity: 1;
}

.onboardingModal-devControls-label{
	display: flex;
	width: 120px;
	align-items: center;
	justify-content: center;
}

.onboardingModal-devControls button{
	color: var(--text600);
	padding-left: 5px;
	padding-right: 5px;

	height: 30px;
	opacity: 0.5;
}

@import 'Onboarding-LoginProto';
@import 'Onboarding-LoadingProto';
@import 'Onboarding-ProgressBar';
@import 'Onboarding-TopBar';

//
//

.onboardingModal-stage-contentContainer{
	position: absolute;

	display: flex;
	flex-direction: column;
	height: 100%;
}


.onboardingModal-stage-contentContainer--leftAlign{	
	top: 0px;
	left: 0px;

	height: 100%;
	width: 50%;

	box-sizing: border-box;
	padding-left: 280px;
}


.onboardingModal-stage-contentContainer--centerAlign{
	align-items: center;

	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;	
}



.onboardingModal-stage-visualOuterContainer{
	position: absolute;
	right: 0px;
	width: 50%;
	height: 100%;

	top: 0px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	overflow-x: hidden;
}

.onboardingModal-stage-visualInnerContainer{
	width: 1280px;
	margin-left: 160px;
}

//

.onboardingModal-stage--centerAlign
.onboardingModal-stage-visualOuterContainer{
	width: 100%;
	flex-shrink: 1;
	flex-grow: 1;
	height: 50vh;
	align-items: flex-start;
	justify-content: center;
}

.onboardingModal-stage--centerAlign
.onboardingModal-stage-visualInnerContainer{
	
	margin-left: 0px;

	display: flex;
	align-items: flex-start;

	height: 800px;
	overflow-x: hidden;
}


//
.onboardingModal-stage-topSpacer{
	height: 200px;
}

.onboardingModal-stage--centerAlign
.onboardingModal-stage-topSpacer{
	height: 200px;
}

.onboardingModal-stage-h1{
	font-size: 36px;

	margin-bottom: 32px;
	line-height: 1.3;
}

[data-theme="light"]{
	.onboardingModal-stage-h1{
		color: var(--text500);	
		//font-weight: $lato-black;
		font-weight: $lato-heavy;
		letter-spacing: -0.008em;
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-h1{
		color: var(--text600);	
		font-weight: $lato-heavy;
	}
}

.onboardingModal-stage--centerAlign
.onboardingModal-stage-h1{
	text-align: center;
}

[data-theme="light"]{
	.onboardingModal-stage-h1-light{
		opacity: 0.8;
		color: var(--text400);
		font-weight: $lato-heavy;
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-h1-light{
		opacity: 0.5;
	}
}


.onboardingModal-stage-h1-underline{
	text-decoration: underline;
}

.onboardingModal-stage--workspaceReady
.onboardingModal-stage-h1{
	font-size: 56px;
	margin-bottom: 36px;	
}

.onboardingModal-stage-h2{
	font-size: 26px;
	font-weight: $lato-semibold;
	color: var(--text600);

	margin-bottom: 8px;

}

.onboardingModal-stage-h2--light{
	opacity: 0.75;
}




.onboardingModal-stage-keyHints{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text600);
	letter-spacing: 0.012em;
	opacity: 0.5;

	margin-bottom: 18px;
}

.onboardingModal-stage-vSpacer{
	min-height: 10px;
	flex-grow: 1; 
	flex-shrink: 1;
}

//

.onboardingModal-stage-skipBtnContainer{
	position: absolute;
	top: 0px;
	right: 0px;	

	z-index: 50;
}


.onboardingModal-stage-skipBtn{
	// background: $tempr;
	padding-left: 24px;
	padding-right: 32px;

	height: 64px;

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text600);
	letter-spacing: 0.012em;

	border-radius: 4px;

	transition: 50ms linear;

	cursor: pointer;
}


[data-theme="light"]{
	.onboardingModal-stage-skipBtn{
		opacity: 0.7;
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-skipBtn{
		opacity: 0.3;
	}
}

.onboardingModal-stage-skipBtn:hover{
	opacity: 1;
}

//

.onboardingModal-stage-bottomSpacer{
	height: 120px; // adjusting to fit the device
}

.onboardingModal-stage--theme-selectBtnContainer{
	display: flex;
	column-gap: 10px;
}

.onboardingModal-stage-continueBtn{
	width: 475px;
	height: 60px;

	background: var(--primary500);
	font-size: 23px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;
	color: $white;

	border-radius: 5px;
}

.onboardingModal-stage-continueBtn-keyHint{
	display: flex;

	margin-top: 12px;

	opacity: 0.5;
}

.onboardingModal-stage-continueBtn-keyHint-iconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	fill: var(--text500);

	margin-left: 4px;
}

.onboardingModal-stage-continueBtn-keyHint-iconContainers svg{
	width: 8px;
	height: 8px;

	fill: var(--text600);
	opacity: 0.75;
}

.onboardingModal-stage-continueBtn-keyHint-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text600);
}

//

.onboardingModal-stage--project-selectBtnContainer--visible{
	opacity: 1;
	transform: translateY(0px);
	transition: all 500ms linear;
	transition-delay: 100ms;
}

.onboardingModal-stage--project-selectBtnContainer--hidden{
	transform: translateY(10px);
	opacity: 0;
}

//

.onboardingModal-stage-selectBtn{
	width: 100%;
	max-width: 475px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 44px;

	box-sizing: border-box;
	padding-left: 10px;

	margin-bottom: 12px;
	margin-bottom: 6px;
	border-radius: 4px;

	margin-left: -12px;
}

.onboardingModal-stage-selectBtn[data-state="active"]{
	background: rgba(2,2,6,0.07);
	// background: var(--primary500);
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn[data-state="active"]{
		background: var(--246gray);
	}
}


.onboardingModal-stage-selectBtn[data-state="inactive"]{
	//background: var(--250gray);
	opacity: 0.6;
}


.onboardingModal-stage-selectBtn[data-state="inactive"]:hover{
	background: rgba(2,2,6,0.04);
	opacity: 1;
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn[data-state="inactive"]:hover{
		background: var(--248gray);		
	}
}

.onboardingModal-stage-selectBtn-iconContainer{
	width: 32px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 6px;

	
}

.onboardingModal-stage-selectBtn-iconContainer
.linearMarker{
	font-size: 19px; // add a real marker later
}

.onboardingModal-stage-selectBtn-iconContainer
.linearMarker svg{
	width: 19px;
	height: 19px;
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn--template[data-state="active"]
	.onboardingModal-stage-selectBtn-iconContainer
	svg.companyIcon path {
		filter: brightness(10);
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn--template[data-state="active"]
	.onboardingModal-stage-selectBtn-iconContainer
	svg.companyIcon circle {
		opacity: 0.2;
		filter: brightness(0);
	}
}


[data-theme="light"]{
	.onboardingModal-stage-selectBtn--template[data-state="inactive"]
	.onboardingModal-stage-selectBtn-iconContainer
	svg.companyIcon path {
		filter: grayscale(100%);
	}
}


.onboardingModal-stage-selectBtn--template
.onboardingModal-stage-selectBtn-iconContainer
svg.companyIcon--linear{
	transform: scale(0.83);
}

.onboardingModal-stage-selectBtn--template
.onboardingModal-stage-selectBtn-iconContainer
svg.companyIcon--mixpanel{
	transform: scale(0.85);
}

.onboardingModal-stage-selectBtn--template
.onboardingModal-stage-selectBtn-iconContainer
svg.companyIcon--react{
	//transform: scale(0.8);
}


//

.onboardingModal-stage-selectBtn-label{
	flex-grow: 1;
	flex-shrink: 1;

	text-align: left;

	font-size: 19.5px;
	font-weight: $lato-bold;
	color: var(--text600);
	letter-spacing: 0.012em;

	position: relative;
	// top: -1px;
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn-label{
		font-weight: $lato-semibold;
		letter-spacing: 0.014em;
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn[data-state="active"]
	.onboardingModal-stage-selectBtn-label{
		color: white;
	}
}

//

.onboardingModal-stage-selectBtn-enterKeyContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	padding-right: 14px;
}

.onboardingModal-stage-selectBtn-enterKeyContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;
}

//

.onboardingModal-stage-selectBtn--theme{
	width: 400px;
	height: 280px;
	background: var(--250gray);
	border-radius: 8px;	
	padding-left: 0px;
	padding-right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	position: relative;
}


.onboardingModal-stage-selectBtn--theme{
	background: none !important;
	opacity: 1 !important;
}

.onboardingModal-stage-selectBtn--theme-onboardingContainer{
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	will-change: transform;

	transform-origin: 50% 80%;
}


.onboardingModal-stage-selectBtn--theme-label{
	width: 100%;
	
	padding-top: 16px;
	padding-bottom: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 19px;
	font-weight: $lato-bold;
	color: var(--text600);
}


[data-theme="dark"]{
	.onboardingModal-stage-selectBtn--theme-label{
		opacity: 0.8;
	}
}

[data-theme="light"]{
	.onboardingModal-stage-selectBtn--theme[data-state="active"]{
		.onboardingModal-stage-selectBtn--theme-label{
			opacity: 0.8;
		}
	}
	.onboardingModal-stage-selectBtn--theme[data-state="inactive"]{
		.onboardingModal-stage-selectBtn--theme-label{
			opacity: 0.5;
		}
	}
}

[data-theme="dark"]{
	.onboardingModal-stage-selectBtn--theme[data-state="active"]{
		.onboardingModal-stage-selectBtn--theme-label{
			opacity: 0.8;
		}
	}
	.onboardingModal-stage-selectBtn--theme[data-state="inactive"]{
		.onboardingModal-stage-selectBtn--theme-label{
			opacity: 0.5;
		}
	}
}


// .onboardingModal-stage-selectBtn--theme[data-state="active"]{
// 	background: var(--243gray);
// }

// .onboardingModal-stage-selectBtn--theme[data-state="inactive"]{
// 	background: var(--254gray);	
// 	opacity: 1;
// }

// .onboardingModal-stage-selectBtn--theme[data-state="inactive"]:hover{
// 	background: var(--248gray);
// 	opacity: 1;
// }


@import 'Onboarding-ThemeDevice';

@import 'Onboarding-Device';

.onboardingModal-stage--workspaceReady-checkBadgeContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: -64px;
	padding-bottom: 48px;
}

.onboardingModal-stage--workspaceReady-checkBadge{
	width: 74px;
	height: 74px;
	border-radius: 50%;


	border: 3px solid var(--green-workspaceReady);
	background: transparentize(#33C99D, 0.9);

	display: flex;
	align-items: center;
	justify-content: center;
}

.onboardingModal-stage--workspaceReady-checkBadge svg{
	width: 40px;
	height: 40px;
	stroke: var(--green-workspaceReady);
	stroke-width: 8px;

	position: relative;
	top: 1px;
}

.onboardingModal-stage--workspaceReady-workspaceBtnContainer,
.onboardingModal-stage--theme-continueBtnContainer{
	margin-top: 96px;

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.onboardingModal-stage--workspaceReady-workspaceBtn,
button.onboardingModal-stage--theme-continueBtn{
	width: 475px;
	height: 74px;

	border-radius: 4px;
	background: var(--primary500);
	border: 1px solid var(--primary600);
	
	flex-direction: column;
}

.onboardingModal-stage--workspaceReady-workspaceBtn-primaryLabel,
.onboardingModal-stage--theme-continueBtn-primaryLabel{
	font-size: 23px;
	font-weight: $lato-bold;
	color: white;

	margin-top: 3px;
}

.onboardingModal-stage--workspaceReady-workspaceBtn-sub,
.onboardingModal-stage--theme-continueBtn-sub{
	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0.5;

	margin-top: 0px;
	margin-bottom: -4px;
}

.onboardingModal-stage--workspaceReady-workspaceBtn-sub-label,
.onboardingModal-stage--theme-continueBtn-sub-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: white;
}

.onboardingModal-stage--workspaceReady-workspaceBtn-sub-iconContainer,
.onboardingModal-stage--theme-continueBtn-sub-iconContainer{
	width: 24px;
	height: 24px;
	margin-left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.onboardingModal-stage--workspaceReady-workspaceBtn-sub-iconContainer svg,
.onboardingModal-stage--theme-continueBtn-sub-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: white;
	opacity: 0.8;

	position: relative;
	top: 1px;
}



.onboardingModal-stage--workspaceReady-halfVSpacer{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}
