$leftOverhang-docTableHeader: 14px;
$rightOverhang-docTableHeader: 14px;


.library-content-channel-frame-docTable-header{
	width: calc(100% + #{$leftOverhang-docTableHeader} + #{$leftOverhang-docTableHeader});

	margin-left: calc(-1 * #{$leftOverhang-docTableHeader});
	box-sizing: border-box;
	padding-left: $leftOverhang-docTableHeader;

	border-bottom: 1px solid var(--line300);

	margin-top: 32px;
	padding-bottom: 4px;

	display: flex;
	align-items: bottom;

	cursor: default;
	user-select: none;
}

.library-content-channel-frame-docTable-header-title{
	font-size: 24px;
	font-weight: $lato-heavy;

	color: var(--text600);

	margin-right: 8px;

	user-select: none;
	cursor: default;
}

button.library-content-channel-frame-docTable-header-btn{
	height: 32px;
	padding-left: 8px;
	padding-right: 8px;

	cursor: pointer;

	pointer-events: auto;

	font-size: 15px;
	font-weight: $lato-semibold;
	//color: lighten(rgb(85,83,79), 10%);
	color: var(--text200);

	cursor: pointer;

	padding-left: 6px;
	padding-right: 10px;

	border-radius: 4px;
	border-radius: 6px;

	
}

button.library-content-channel-frame-docTable-header-btn--sort{
	margin-left: 6px;
}

button.library-content-channel-frame-docTable-header-btn:hover{
	background: $lightGray-bg;
	background: rgb(248,245,245);
	background: var(--docTableHeaderBtnHoverBG);
	color: var(--text600);
}

.library-content-channel-frame-docTable-header-btn-iconContainer{
	margin-right: 4px;

	height: 100%;
	display: flex;
	align-items: center;
}

button.library-content-channel-frame-docTable-header-btn--filter
.library-content-channel-frame-docTable-header-btn-iconContainer{
	margin-right: 2px;
}

.library-content-channel-frame-docTable-header-btn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: rgb(85,83,79);
	fill: var(--text500);
	margin-top: 1px;
	opacity: 0.4;
}

button.library-content-channel-frame-docTable-header-btn:hover
.library-content-channel-frame-docTable-header-btn-iconContainer svg{
	opacity: 0.6;
}
