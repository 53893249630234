.avatarImage{
	width: 100%;
	height: 100%;

	object-fit: cover;
	
	background: white;
	position: relative;

	user-select: none !important;
	cursor: default;
}

//
// CONTACT

.contactAvatar{
	//width and height passed as props
	overflow: hidden; 

	color: $white;

	// display: flex;
	// align-items: center;
	// justify-content: center;

	// position: relative;

	font-size: 22px; // at 32px
	font-weight: $lato-bold;
	text-transform: uppercase;

	background: var(--235gray);

	user-select: none !important;
	cursor: default;

	text-align: center;
	line-height: 1.5;
}


.contactAvatar--bgColor--blue{
	background: var(--accentBlue500);	
}
.contactAvatar--bgColor--orange{
	background: var(--accentOrange500);	
}
.contactAvatar--bgColor--purple{
	background: var(--accentPurple500);	
}
.contactAvatar--bgColor--red{
	background: var(--accentRed500);	
}
.contactAvatar--bgColor--green{
	background: var(--accentGreen500);	
}
.contactAvatar--bgColor--sky{
	background: var(--accentSky500);	
}
.contactAvatar--bgColor--pink{
	background: var(--accentPink500);	
}
.contactAvatar--bgColor--teal{
	background: var(--accentTeal500);	
}
.contactAvatar--bgColor--grey{
	background: var(--accentGrey500);	
}



// //

// .avatarImage-doodleAvatarContainer{
// 	overflow: hidden;
// 	user-select: none;
// }

// .avatarImage-doodleAvatarContainer * {
// 	user-select: none !important;
// }

// .avatarImage-doodleAvatar{
// 	width: 100%;
// 	height: 100%;
// 	// width: 200px;
// 	// height: 200px;

// 	position: relative;
// 	overflow: hidden;

// 	user-select: none;
// }

// img.avatarImage-doodleAvatar-img{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	width: 100%;
// 	height: 100%;

// 	z-index: 5;

// 	object-fit: contain;
// }

// .avatarImage-doodleAvatar-bg{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	z-index: 0;
// }

// .avatarImage-doodleAvatar-bg--blue{
// 	background: hsl(212, 99%, 70%);
// 	//background: hsl(212, 90%, 85%); // pastel
// }

// .avatarImage-doodleAvatar-bg--purple{
// 	background: hsl(281, 75%, 79%);
// 	//background: hsl(281, 70%, 79%); // pastel
// }

// .avatarImage-doodleAvatar-bg--green{
// 	background: hsl(162, 38%, 70%);
// 	//background: hsl(162, 62%, 70%); // pastel
// }

// .avatarImage-doodleAvatar-bg--red{
// 	background: hsl(358, 97%, 79%);
// 	//background: hsl(358, 85%, 85%); // pastel
// }

// .avatarImage-doodleAvatar-bg--orange{
// 	background: hsl(24, 100%, 82%);
// 	//background: hsl(24, 100%, 82%); // pastel
// }

// .avatarImage-doodleAvatar-bg--sky{
// 	background: hsl(199, 100%, 73%);
// 	//background: hsl(199, 100%, 73%); // pastel
// }

// // not including , .forceDarkTheme atm
// [data-theme="dark"], :root .tabBar--yarnTheme--dark{
// 	.avatarImage-doodleAvatar-bg--blue{
// 		background: hsl(212, 99%, 70%);
// 		background: hsl(212, 90%, 85%);
// 		background: hsl(212, 35%, 35%);
// 	}

// 	.avatarImage-doodleAvatar-bg--purple{
// 		background: hsl(281, 75%, 79%);
// 		background: hsl(281, 70%, 79%);
// 		background: hsl(281, 25%, 30%);
// 	}

// 	.avatarImage-doodleAvatar-bg--green{
// 		background: hsl(162, 62%, 70%);
// 		background: hsl(162, 62%, 70%);
// 		background: hsl(162, 14%, 30%);
// 	}

// 	.avatarImage-doodleAvatar-bg--red{
// 		background: hsl(358, 97%, 79%);
// 		background: hsl(358, 85%, 85%);
// 		background: hsl(358, 30%, 35%);
// 	}

// 	.avatarImage-doodleAvatar-bg--orange{
// 		background: hsl(24, 100%, 82%);
// 		background: hsl(24, 100%, 82%);
// 		background: hsl(24, 30%, 30%);
// 	}

// 	.avatarImage-doodleAvatar-bg--sky{
// 		background: hsl(199, 100%, 73%);
// 		background: hsl(199, 100%, 73%);
// 		background: hsl(199, 25%, 30%);
// 	}	
// }





// purple
// blue
// green
// red
// orange
// sky	

// // TEMP REMOVE EMOJI FROM METADATA BUTTON
// .docEditor--draft-metadataBtn--bucket-iconContainer{
// 	display: none !important;
// }