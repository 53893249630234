
// variables

:root{
	--background-onboarding: rgb(242,240,245);	
	--green-workspaceReady: #33C99D;
}


[data-theme="dark"], .forceDarkTheme{	
	//--background-onboarding: rgb(19,18,21);		
	--background-onboarding: rgb(28,26,32);		
	
}


