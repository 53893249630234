$bg-loginProto: rgb(21,20,23);
.loginProto{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	//background: var(--background-onboarding);
	// background: none;
	background: var(--background-onboarding);
	
	//background: radial-gradient(60% 60% at 50% 50%,  lighten($bg-loginProto, 5%), darken($bg-loginProto, 5%));

	//background: radial-gradient(100% 100% at 50% 100%, lighten($bg-loginProto, 0%), darken($bg-loginProto, 4%));
	//background: radial-gradient(100% 100% at 50% 100%, red, blue);


}

.loginProto-box{
	background: rgb(32,30,36);
	border: 1px solid rgb(255,255,255,0.08);
	border-top: 1px solid rgb(255,255,255,0.14);
	border-bottom: 1px solid rgb(255,255,255,0.06);

	background: radial-gradient(
		500px 500px at 20% 50%,
		darken(rgb(32,30,39),0%) 0%,
		darken(rgb(32,30,39),1%) 70%,
		darken(rgb(32,30,39),3%) 100%,
	);

// 	box-shadow: 0px 12px 16px 0px rgba(0,0,0,0.24);
// /* https://smoothshadows.com/#djEsMSw1LDAuMTgsMjQsMjMsMCwjMDMwNzEyLCNmM2Y0ZjYsI2ZmZmZmZiwy */
box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.04),
  0px 4px 4px rgba(3, 7, 18, 0.07),
  0px 8px 9px rgba(3, 7, 18, 0.11),
  0px 15px 15px rgba(3, 7, 18, 0.14),
  0px 23px 24px rgba(3, 7, 18, 0.18);


	//backdrop-filter: blur(20px);

	//height: 470px;
	width: 398px;
	height: 478px;

	margin-top: -40px;

	// width: 404px;
	// height: 484px;

	border-radius: 5px;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	z-index: 500;


}

.loginProto-box-logo{
	margin-top: 72px;

	width: 32px;
	
	display: flex;
	flex-direction: column;
}

.loginProto-box-logo-bar{
	width: 100%;

	height: 5px;
	margin-bottom: 3px;

	background: var(--text500);
	border-radius: 1px;
}

.loginProto-box-logo-bar:last-child{
	width: 50%;
}

//

.loginProto-box-brand{
	margin-top: 24px;
}

.loginProto-box-brand svg{
	fill: white;
}

//

.loginProto-box-versionNumber{
	margin-top: 18px;

	font-family: 'Menlo', 'Consolas' !important;
	color: var(--text400);
	font-size: 14px;
	opacity: 0.65;
}

.loginProto-box-loginBtn,
.loginProto-box-openSlackBtn{

	width: 280px;
	height: 44px;

	border-radius: 4px;

	color: var(--text600);
	font-size: 17px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;

	box-sizing: border-box;
	padding-bottom: 1px;
}



.loginProto-box-openSlackBtn{
	background: none;
	opacity: 0.35;
	font-weight: $lato-medium;
	font-size: 15.25px;
	letter-spacing: 0.012em;

	margin-top: 24px; 
}

.loginProto-box-openSlackBtn:hover{
	//background: rgba(255,255,255,0.03);
	opacity: 0.8;
}





// .docEditor-messagePanel-header-alphaTag{
// 	text-transform: uppercase;
	
// 	position: relative;

// 	font-size: 11.25px;
// 	padding-left: 8px;
// 	padding-right: 8px;
// 	margin-right: 4px;

// 	top: 0.5px;

// 	height: 22px;
	
// 	margin-left: 6px;

// 	font-weight: $lato-medium;
// 	letter-spacing: 0.02em;

// 	overflow: hidden;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	color: var(--aiPurple500);
// 	color: var(--text300);
// 	// background: var(--aiPurple500);
// }

.loginProto-box-loginBtn{	
	margin-top: 150px;

	//border: 1px solid lighten(#6365BA, 2%);

	//background: linear-gradient(#5B5EF3, darken(#5458F1, 2%));
	
	position: relative;
	overflow: hidden;
	//box-shadow: 0px 0px 14px 1px transparentize(saturate(#5458F1, 10%), 0.9);

	

	background: none;
	// color: #5458F1;
	background: rgba(255,255,255,0.01);
	background: linear-gradient(darken(#5458F1, 2%), #5B5EF3);
	border: 1px solid lighten(#5458F1, 2%);
	// color: color(display-p3 0.19 0.39 0.98 / 1);
	//border: 1px solid #5458F1;
	color: white;

}

.loginProto-box-loginBtn:hover{
	background: linear-gradient(darken(#5458F1, 3%), darken(#5B5EF3, 1%));
	border: 1px solid lighten(#5458F1, 4%);
}

.loginProto-box-loginBtn-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -4px;
	margin-right: 5px;



	// background: $tempr;
}

.loginProto-box-loginBtn-iconContainer svg{
	fill: white;
	width: 18px;
	height: 18px;
	opacity: 0.9;
	position: relative;


	fill: white;

	transform-origin: 50% 50%;
	transform: scale(0.9);
}



//
//

.loginProto-background{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;

  opacity: 1;

  pointer-events: none;
  
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Crect width='100%25' height='100%25' fill='rgba(255,255,255,0.02)' stroke='rgb(15,13,17)'/%3E%3C/svg%3E"); 
  background-size: 40px 40px;
  background-position: calc(50% - 20px) calc(50% - 20px); /* Adjusted */

}

.loginProto-background-box{
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  //border: 1px solid $bg-loginProto;
  opacity: 0;
  animation: fade 24s ease-in-out infinite;
}


@keyframes fade {
  0% { opacity: 0.0; }
  10% { opacity: 0.04; }
  20% { opacity: 0; }
  100% { opacity: 0; }
}

//
//


.loginProto-particleContainer{
	position: absolute;
	width: 100%;
	height: 100%;
}

.loginProto-particleContainer-particles{
	position: absolute;
	width: 100%;
	height: 100%;

	z-index: 50;
}

.loginProto-particleContainer-overlayBG{
	position: fixed;
	width: 200vw;
	left: -50vw;
	height: 200vh;
	top: -50vh;

	transform: rotateZ(-24deg);
	z-index: 200;

	background: radial-gradient(
		750px 400px at calc(50% - 100px) calc(50% - 50px),
		rgba(28,26,32,0) 0%,
		rgba(28,26,32,1) 100%,
	);

	opacity: 1;
}


.loginProto-particleContainer-svgContainer{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) rotateZ(-19deg);

	z-index: 100;

	opacity: 0.25;
}