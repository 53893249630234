.openDocMenu-docPreview,
.openDocMenu-pagePreview{
	position: fixed;
	top: $height-tabBar;
	left: 0px;
	right: 0px;
	bottom: 0px;	
	background: var(--bg-docEditor);
	
	z-index: 500;

	display: flex;
	user-select: none;
	cursor: text;

	//margin-left: 35px; // not sure why necessary
	margin-left: 1px;
	padding-left: 35px;
	box-sizing: border-box;

	border-top-left-radius: 7px;
	border-top-right-radius: 7px; 

	//opacity: 0.5; // temproary
}

.openDocMenu-docPreview--sideMenuState--hidden,
.openDocMenu-pagePreview--sideMenuState--hidden{
	left: $windowMargin-sideMenuMode;
	right: $windowMargin-sideMenuMode;
	bottom: $windowMargin-sideMenuMode;
	top: $windowMargin-sideMenuMode;
	margin-left: 0px;
}

.openDocMenu-docPreview--sideMenuState--visible,
.openDocMenu-pagePreview--sideMenuState--visible{
	// left set in core layout
	right: $windowMargin-sideMenuMode;
	bottom: $windowMargin-sideMenuMode;
	top: $windowMargin-sideMenuMode;	

	display: flex;
}

// .openDocMenu-docPreview-leftSpacer{
// 	height: 100%;
// 	// background: $tempr;
// }

.openDocMenu-docPreview-docContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	justify-content: center;


}

.openDocMenu-docPreview-doc{
	// width: $fullWidth-doc;
	// margin-left: calc((#{$fullWidth-doc} - #{$textWidth-doc})/2);
	width: $textWidth-doc;
	height: 100%;
	
	// background: $tempg;

	padding-top: 134px; // hacky
}

.openDocMenu-docPreview-doc-title{
	width: $textWidth-doc;
}

.openDocMenu-docPreview-doc-innerWrapper{
	width: $textWidth-doc;
}

.openDocMenu-docPreview-teamPanelSpacer{
	width: $width-teamPanel--narrow;
	height: 100%;
}

//
// PAGE
// e.g. roadmaps


.roadmapPageContainer.roadmapPageContainer--searchPreview{
	position: absolute;

	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	margin-top: -1px;
	margin-left: -1px;
	
	overflow-x: hidden;
	overflow-y: hidden;
	
	z-index: 10;

	background: none;
}