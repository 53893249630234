.suggestionMenu-item--mention--docLink--emojiContainer{
	font-size: 16.5px;
}

.suggestionMenu-item--mention{
	position: unset;
}

$docOriginalScaleWidth-suggestionMenu-mentionDocLinkPreview: 860px;
$docScale-suggestionMenu-mentionDocLinkPreview: 0.345;

$width-suggestionMenu-mentionDocLinkPreview: 278px;


.suggestionMenu-item--mention--docLink--previewContainer{
	position: absolute;
	// top: 0px;
	// right: -270px;
	// height: 339px;
	// width: 260px;

	top: 50%;
	transform: translateY(-50%);
	right: calc(-1 * (#{$width-suggestionMenu-mentionDocLinkPreview} + 6px));
	height: 320px;
	width: $width-suggestionMenu-mentionDocLinkPreview;

	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-bottom: 0px;

	overflow: hidden;

	cursor: pointer;
}

.suggestionMenu-item--mention
.suggestionMenu-item--mention--docLink--previewContainer{
	visibility: hidden;
}

.suggestionMenu-item--active
.suggestionMenu-item--mention--docLink--previewContainer{
	visibility: visible;
}


.suggestionMenu-item--mention--docLink--preview{
	transform: scale($docScale-suggestionMenu-mentionDocLinkPreview);
	transform-origin: top left;
	width: $docOriginalScaleWidth-suggestionMenu-mentionDocLinkPreview;
	display: flex;
	justify-content: center;

	pointer-events: none;	
}

.suggestionMenu-item--mention--docLink--preview-doc{
	width: $fullWidth-doc;

	height: 100%;
	// background: $tempg;

	padding-top: 32px; // hacky
}

.suggestionMenu-item--mention--docLink--preview-doc-title{
	width: $textWidth-doc;
}

.suggestionMenu-item--mention--docLink--preview-doc-innerWrapper{
	width: $textWidth-doc;
}

//
