//
// FOR PROJECTS AND TEAMS

.linearMarkerContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}


.linearMarker{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.teamPage-header-title-markerContainer{
	width: 38px;
	height: 38px;
}

.teamPage-header-title-markerContainer
.linearMarkerContainer{
	width: 38px;
	height: 38px;

	// background: $tempr;
}

.teamPage-header-title-markerContainer
.linearMarker{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.teamPage-header-title-markerContainer
.linearMarker svg{
	width: 38px;
	height: 38px;
}


//
// INLINE

//
// FOR PROJECTS AND TEAMS

.inlineLinearMarkerContainer{
	
}


.inlineLinearMarker{
	
}


@import 'LinearMarkers-Colors';

