//
// ACTIVE VIEW STATUS
// also contains code for full screen variant
// 



.sideMenu-activeViewStatus-header,
.fullScreenActiveViewStatus-topLeftHeader{
	width: 100%;
	height: 40px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	// background: $tempg;

	margin-bottom: 21px;

	padding-top: 10px;
	padding-right: 6px;
}

.fullScreenActiveViewStatus-topLeftHeader{
	position: fixed;
	top: 0px;
	left: 11px;

	top: 4px;
	left: 14px;
 
	width: auto;

	z-index: 1000;

}

.sideMenu-activeViewStatus-header-dragWindowArea{
	height: 100%;
	min-width: 10px;

	flex-grow: 1;
	flex-shrink: 1;

	// background: $tempr;

	-webkit-user-select: none;
	-webkit-app-region: drag;
}

button.sideMenu-activeViewStatus-header-toggleMenuBtn,
button.fullScreenActiveViewStatus-topLeftHeader-toggleMenuBtn{
	flex-grow: 0;
	flex-shrink: 0;

	width: 38px;
	height: 28px;

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	// margin-right: 6px;
	//margin-top: 1px;

	// background: rgba(0,0,0,0.02);
}


button.sideMenu-activeViewStatus-header-toggleMenuBtn svg.icon,
button.fullScreenActiveViewStatus-topLeftHeader-toggleMenuBtn svg.icon{
	width: 22px;
	height: 22px;
	fill: var(--text500);
	opacity: 0.5;
}

.sideMenu-activeViewStatus:hover button.sideMenu-activeViewStatus-header-toggleMenuBtn svg.icon{
	opacity: 0.7;
}

button.sideMenu-activeViewStatus-header-toggleMenuBtn:hover,
button.fullScreenActiveViewStatus-topLeftHeader-toggleMenuBtn:hover{
	background: rgb(0,0,0,0.04);
}

.sideMenu:hover button.sideMenu-activeViewStatus-header-toggleMenuBtn:hover svg.icon,
button.fullScreenActiveViewStatus-topLeftHeader-toggleMenuBtn:hover svg.icon{
	opacity: 1;
}

button.sideMenu-activeViewStatus-header-toggleMenuBtn:active,
button.fullScreenActiveViewStatus-topLeftHeader-toggleMenuBtn:active{
	background: rgba(0,0,0,0.06);
}


// STATUS BUTTONS

.sideMenu-activeViewStatus-header-statusBtn,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn{
	width: 27px;
	height: 27px;
	border-radius: 50%;
}

.sideMenu-activeViewStatus-header-statusBtn{
	margin-right: 5px;
}

.fullScreenActiveViewStatus-topLeftHeader-statusBtn{
	margin-left: 5px;
}

.sideMenu-activeViewStatus-header-statusBtn svg,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn svg{
	width: 17px;
	height: 17px;
}

.sideMenu-activeViewStatus-header-statusBtn svg.icon--name--broadcast,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn svg.icon--name--broadcast{
	position: relative;
	top: 0.5px;
}

.sideMenu-activeViewStatus-header-statusBtn--inactive,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn--inactive{
	pointer-events: none;
	background: $invisible !important;
	opacity: 0.5 !important;
}

// .sideMenu-activeViewStatus:hover
// .sideMenu-activeViewStatus-header-statusBtn--inactive{
// 	background: rgba(0,0,0,0.03);	
// }

.sideMenu-activeViewStatus-header-statusBtn--inactive svg,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn--inactive svg{
	fill: var(--text400);
	opacity: 0.5;
}

// .sideMenu-activeViewStatus:hover 
// .sideMenu-activeViewStatus-header-statusBtn--inactive svg{
// 	opacity: 0.75;
// }

// .sideMenu-activeViewStatus-header-statusBtn--inactive:hover,
// .sideMenu-activeViewStatus-header-statusBtn--inactive[data-state="open"]{
// 	background: rgba(0,0,0,0.05);	
// }

// .sideMenu-activeViewStatus:hover
// .sideMenu-activeViewStatus-header-statusBtn--inactive:hover svg,
// .sideMenu-activeViewStatus:hover
// .sideMenu-activeViewStatus-header-statusBtn--inactive[data-state="open"] svg,
// .sideMenu-activeViewStatus-header-statusBtn--inactive[data-state="open"] svg{
// 	opacity: 1;
// }

.sideMenu-activeViewStatus-header-statusBtn--active svg,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn--active svg{
	fill: white;	
}

.sideMenu-activeViewStatus-header-statusBtn--type--sync.sideMenu-activeViewStatus-header-statusBtn--active,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn--type--sync.fullScreenActiveViewStatus-topLeftHeader-statusBtn--active{
	background: var(--accentGreen500);
}

.sideMenu-activeViewStatus-header-statusBtn--type--feedback.sideMenu-activeViewStatus-header-statusBtn--active,
.fullScreenActiveViewStatus-topLeftHeader-statusBtn--type--feedback.fullScreenActiveViewStatus-topLeftHeader-statusBtn--active{
	background: var(--accentPurple500);
}







