

.teamPanelContainer{
	// position: fixed;
	// right: $windowMargin-sideMenuMode;
	// top: $windowMargin-sideMenuMode;
	// bottom: $windowMargin-sideMenuMode;

	height: 100%;
	width: $width-teamPanel;	

	z-index: 4000;

	// overflow-y: scroll;
	// overflow-x: hidden;

	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;

	background: var(--teamPanel-bg);
	border-left: 1px solid var(--teamPanel-borderLeftColor);

	user-select: none;

	// opacity: 0.5; // temp
}

.teamPanelContainer--narrowLayout{
	width: $width-teamPanel--narrow;	
}

.docEditorContainer--sideMenuState--hidden
.teamPanelContainer{
	display: none;
}

.docEditorContainer--videoPlayerZoomed
.teamPanelContainer{
	z-index: 0;
}


.teamPanel{	
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;	

	box-sizing: border-box;

	padding-left: 3px;
	padding-right: 3px;

	padding-top: 18px;

	position: relative;
}

.teamPanel-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	background: red;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;

	pointer-events: none;

	background: darken(rgb(253,252,253), 2%);

	// display: none;
}

.teamPanelContainer--narrowLayout .teamPanel{
	padding-left: 1px;
	padding-right: 1px;
}

.teamPanel-user{
	display: flex;
	height: 31px;

	align-items: center;

	width: 100%;

	box-sizing: border-box;

	padding-left: 9px;
	padding-right: 9px;

	// background: $tempr;
	border-radius: 5px;

	cursor: pointer;
	position: relative;
	z-index: 5;
}

.teamPanel--dndActive
.teamPanel-user{
	cursor: default;
}

.teamPanel-user:hover{
	background: var(--bgSideMenuTabHover);
}

.teamPanel-user[data-state="open"]{
	background: var(--bgSideMenuTabActive);	
}

.teamPanel-user--narrowLayout{
	padding-left: 0px;
	padding-right: 0px;
}

.teamPanel-avatarList{
	position: relative;
	z-index: 5;
}

//

.teamPanel-user-avatar{
	width: 26px;
	height: 26px;
	background: $tempr;
	border-radius: 5px;

	position: relative;

	margin-right: 8px;
}

.teamPanel-user--narrowLayout
.teamPanel-user-avatar{
	margin-right: 0px;
}

.teamPanel-user-avatar-avatar{
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.teamPanel-user-avatar-statusIndicator{
	position: absolute;
	bottom: -1px;
	right: -1px;

	border-radius: 50%;

	width: 8px;
	height: 8px;

	outline: 2px solid var(--teamPanel-bg);	
	background: red;

	z-index: 20;
}

.teamPanel-user-avatar-statusIndicator--status--active{
	background: var(--accentGreen500);
}

.teamPanel-user-avatar-statusIndicator--status--away{
	background: var(--teamPanel-bg);
	border: 1px solid rgb(120,120,120);
	width: 6px;
	height: 6px;
}

.teamPanel-user-avatar-statusIndicator{
	opacity: 1;
	transition: opacity 200ms linear;
}

.teamPanel--dndActive
.teamPanel-user-avatar-statusIndicator{
	opacity: 0;
}


.teamPanel-user:hover
.teamPanel-user-avatar-statusIndicator{
	outline: 2px solid #F3F2F3; // same as hover bg
}

.teamPanel-user:hover
.teamPanel-user-avatar-statusIndicator--status--away{
	background: #F3F2F3;
}


.teamPanel-user-name{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	user-select: none;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text600);
	
	letter-spacing: 0.007em;

	opacity: 0.8;
}

.teamPanel-user:hover .teamPanel-user-name{	
	opacity: 1;
}

.teamPanel-user--narrowLayout .teamPanel-user-name{
	display: none;
}

//
//

.teamPanel-groupWrapper-title{
	display: flex;
	align-items: center;
	margin-top: 18px;
	margin-left: 9px;
	margin-bottom: 6px;

	user-select: none;
	cursor: default;

	opacity: 0.75;
}

.teamPanel:hover 
.teamPanel-groupWrapper-title{
	opacity: 1;
}

.teamPanel-groupWrapper-title-iconContainer{
	margin-right: 4px;
}

.teamPanel-groupWrapper-title-iconContainer svg{
	width: 11px;
	height: 11px;
	// position: relative;
	// top: -2px;
	fill: var(--text300);
}

.teamPanel-groupWrapper-title-label{
	font-size: 13.25px;
	color: var(--text300);
	font-weight: $lato-semibold;
	letter-spacing: 0.0005em;
}

// .teamPanel-groupWrapper:first-child
// .teamPanel-groupWrapper-title-label{
// 	font-weight: $lato-bold;
// }


//
//

@import 'TeamPanel-Popover'; 
@import 'TeamPanel-dndButton'