
// TOOLBAR

.whiteboard-toolbar{
	background: var(--background-whiteboardsToolbar);
	border-radius: var(--borderRadius-whiteboardsToolbar);	
	border: var(--border-whiteboardsToolbar);
	border-top: var(--borderTop-whiteboardsToolbar);
	border-bottom: var(--borderBottom-whiteboardsToolbar);
	box-shadow: var(--boxShadow-whiteboardsToolbar);

	

	//flex-wrap: wrap;

	padding-left: 10px;
	padding-right: 10px;
	

	
}

// .whiteboard-toolbar--minimized{
// 	width: 40px; // always border box from above
// 	padding-left: 4px;
// 	padding-right: 4px;
// }

// .whiteboard-toolbar--expanded{
// 	width: 74px;
// 	padding-left: 4px;
// 	padding-right: 4px;
// }

.whiteboard-toolbar-toolRow{
	display: flex;
	align-items: center;
	height: 42px;
}




.whiteboard-toolbar-divider{
	//width: calc(100% - 2px);
	width: 1px;
	height: calc(100% - 10px);
	margin-left: 6px;
	margin-right: 6px;
	background: var(--line200);
}

[data-theme="dark"]{
	.whiteboard-toolbar-divider{
		background: var(--line100);
	}
}

//


.whiteboard-toolbar-btn{
	//width: 32px;
	width: 40px;
	height: 32px;
	//height: 32px;
	border-radius: 3px;

	position: relative;
}


.whiteboard-toolbar-btn-simpleShortcut{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 100%;
	background: $tempr;

	width: 18px;
	height: 18px;
	border-radius: 3px;
	background: var(--240gray);
	background: var(--text500);


	pointer-events: none;
	margin-left: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 13.5px;
	font-weight: $lato-medium;
	color: var(--text400);
	color: white;

	opacity: 0.35;
}

.whiteboard-toolbar-btn svg{
	width: 32px;
	height: 32px;
	// width: 34px;
	// height: 34px;
	transform-origin: center center;
}

.whiteboard-toolbar-btn[data-state="active"]{
	background: var(--primary500);
}

.whiteboard-toolbar-btn[data-state="active"] svg{
	fill: white;
}

.whiteboard-toolbar-btn[data-state="inactive"] svg{
	fill: var(--text600);
	opacity: 0.75;
}

.whiteboard-toolbar-btn[data-state="inactive"]:hover{
	background: var(--248gray);
}

.whiteboard-toolbar-btn[data-state="inactive"]:hover svg{
	opacity: 1;
}


// CUSTOM ICON

.whiteboard-toolbar-btn svg.icon--name--drawingHandAlt{
	transform: scale(0.75);
}
.whiteboard-toolbar-btn svg.icon--name--drawingHandAlt #bg{
	fill: white;
}

// [data-theme="dark"]{
// 	.whiteboard-toolbar-btn svg.icon--name--drawingHandAlt #bg{
// 		//fill: rgba(0,0,0,0.25);
// 		fill: none;
// 	}
// }

.whiteboard-toolbar-btn svg.icon--name--drawingHandAlt #handOutline,
.whiteboard-toolbar-btn svg.icon--name--drawingHandAlt #handLines{
	stroke: var(--text600);
	stroke: black;
	fill: none;
	stroke-width: 1.25px;
}



.whiteboard-toolbar-btn svg.icon--name--drawingCursor{
	transform: scale(0.73);
}

.whiteboard-toolbar-btn svg.icon--name--plus{
	transform: scale(0.73);
}

.whiteboard-toolbar-btn svg.icon--name--code{
	transform: scale(0.65);
}

.whiteboard-toolbar-btn svg.icon--name--deviceiPhone{
	transform: scale(0.95);
}

.whiteboard-toolbar-btn svg.icon--name--deviceDesktop{
	transform: scale(0.95);
}

.whiteboard-toolbar-btn svg.icon--name--drawingHand{
	transform: scale(0.95);
}

.whiteboard-toolbar-btn[data-state="inactive"] svg.icon--name--drawingHand{
	opacity: 0.5;
}

.whiteboard-toolbar-btn svg.icon--name--drawingPencil{
	transform: scale(1.04);
}

.whiteboard-toolbar-btn svg.icon--name--drawingEraser{
	transform: scale(0.97);
}

.whiteboard-toolbar-btn svg.icon--name--drawingText{
	transform: scale(1.02);
}

.whiteboard-toolbar-btn svg.icon--name--insertBarImage{
	transform: scale(0.82);
}

.whiteboard-toolbar-btn svg.icon--name--drawingText #box{
	opacity: 0.3;
}

.whiteboard-toolbar-btn svg.icon--name--drawingSticky{
	transform: scale(0.97);
}


//

.whiteboard-toolbar-switchToolbarType{
	width: calc(100% + 10px);
	height: 24px;
	margin-left: -4px;
	margin-right: -4px;
	margin-top: 2px;

	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;

	border-top: 1px solid var(--line300);
	background: var(--253gray);
}

.whiteboard-toolbar-switchToolbarType svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.6;
	transform-origin: center center
}

.whiteboard-toolbar-switchToolbarType:hover{
	//border-top: 1px solid var(--line400);
	background: var(--248gray);
}

.whiteboard-toolbar-switchToolbarType:hover svg{
	opacity: 1;
}


//
//

@import 'Whiteboard-Toolbar-PropertiesRow';

