$topPadding-tableContainer: 12px;
$bottomPadding-tableContainer: 12px;


.doc-tableContainer{
	position: relative;
	width: 650px;
	caret-color: rgba(255,255,255,0); // to prevent weird cursor round
	// background: $tempb;
}

.doc-tableContainer.doc-tableContainer--focused{
	caret-color: initial;
}

.doc-tableInnerContainer{
	position: relative;
	padding-top: $topPadding-tableContainer;
	padding-bottom: $bottomPadding-tableContainer;

	// background: $tempr;
}

// .doc-tableContainer--selected
// .doc-tableInnerContainer{
// 	background: var(--primary25);
// }

.doc-tableInnerContainer{
	width: $fullWidth-doc;
	cursor: default; 
}

.doc-tableInnerContainer::after{	
	z-index: 0;
  position: absolute;
  content: '';
  top: 8px;
	left: -8px;	
	width: calc(100% + 16px);
	height: calc(100% - 20px);	
  
  pointer-events: none;
  border-radius: 4px;

  opacity: 0;
}

.doc-tableContainer--selected
.doc-tableInnerContainer::after{
	background: var(--primary25);		
	opacity: 1;
}

[data-theme="dark"]{
	.doc-tableContainer--selected
	.doc-tableInnerContainer::after{
		background: #1F3650;
	}
}




.doc-table-cell-content-bgTrigger{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}


//

.doc-table{
	// background: $tempr;
	// outline: 2px solid red;
	padding-left: 48px;
	padding-right: 48px;
	position: relative;
	z-index: 2;
}

.staticHTML
.doc-table{
	padding-left: 0px;
}

[data-theme="dark"]{

	.doc-tableInnerContainer::-webkit-scrollbar {
	  // width: 5px;
	  height: 8px;
	  background-color: var(--bg-docEditor);
	  opacity: 0;
	}

	/* Add a thumb */
	.doc-tableInnerContainer::-webkit-scrollbar-thumb {
	  // background: blue;
	  background: rgba(0,0,0,0.5);
	  border-radius: 4px;
	}

	.doc-tableInnerContainer::-webkit-scrollbar-thumb{
		background: rgba(255,255,255,0);		
	}

	.doc-tableContainer:hover
	.doc-tableInnerContainer::-webkit-scrollbar-thumb {
		background: rgba(255,255,255,0.5);			
	}


}


.doc-tableContainer.doc-table--resizing,
.doc-tableContainer.doc-table--resizing *{
  cursor: ew-resize;
  cursor: col-resize !important;
}


//
// ALTERNATE ROW HIGHLIGHTS

.doc-tableContainer--alternatingRowHighlight tr:nth-child(even) td{
	background: var(--254gray);
}

.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(odd) td{
	background: var(--254gray);
}

.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(even) td{
	background: unset;
}

[data-theme="dark"]{
	.doc-tableContainer--alternatingRowHighlight tr:nth-child(even) td{
		background: rgba(244,240,255,0.025);
	}

	.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(odd) td{
		background: rgba(244,240,255,0.025);
	}

	.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(even) td{
		background: unset;
	}
}


//
// HEADER ROW AND COLUMN

.doc-tableContainer--hasHeaderRow tr:first-child td,
.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(1) td{
	background: var(--250gray);
}

.doc-tableContainer--hasHeaderColumn tr td:first-child,
.doc-tableContainer--hasHeaderColumn.doc-tableContainer--alternatingRowHighlight tr td:first-child{
	background: var(--250gray);
}

[data-theme="dark"]{
	.doc-tableContainer--hasHeaderRow tr:first-child td,
	.doc-tableContainer--hasHeaderRow.doc-tableContainer--alternatingRowHighlight tr:nth-child(1) td{
		background: var(--251gray);
	}

	.doc-tableContainer--hasHeaderColumn tr td:first-child{
		background: var(--251gray);
	}
}

//
//

// resize cursor edge color

.ProseMirror .column-resize-handle {
  // position: absolute;
  // right: -2px;
  // top: 0;
  // bottom: 0;
  // width: 4px;
  //z-index: 20;
  background-color: var(--primary100);
  //pointer-events: none;
}

[data-theme="dark"]{
	.ProseMirror .column-resize-handle {
		background: transparentize(#8FC4FF, 0.5);
	}	
}



//
// CELL COLORING

.doc-table-cell.doc-table-cell--bg--green:after,
.doc-table-cell.doc-table-cell--bg--purple:after,
.doc-table-cell.doc-table-cell--bg--red:after,
.doc-table-cell.doc-table-cell--bg--blue:after,
.doc-table-cell.doc-table-cell--bg--orange:after,
.doc-table-cell.doc-table-cell--bg--sky:after,
.doc-table-cell.doc-table-cell--bg--pink:after,
.doc-table-cell.doc-table-cell--bg--teal:after,
.doc-table-cell.doc-table-cell--bg--grey:after{
	z-index: 0;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.doc-table-cell.doc-table-cell--bg--green,
.doc-table-cell.doc-table-cell--bg--green.doc-table-cell--selectedCell{
  background: var(--accentGreen50) !important; // override for header row/col
}


.doc-table-cell.doc-table-cell--bg--purple,
.doc-table-cell.doc-table-cell--bg--purple.doc-table-cell--selectedCell{
	background: var(--accentPurple50) !important; // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--red,
.doc-table-cell.doc-table-cell--bg--red.doc-table-cell--selectedCell{
	background: var(--accentRed50) !important; // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--blue,
.doc-table-cell.doc-table-cell--bg--blue.doc-table-cell--selectedCell{
	background: var(--accentBlue50) !important;  // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--orange,
.doc-table-cell.doc-table-cell--bg--orange.doc-table-cell--selectedCell{
	background: var(--accentOrange50) !important;  // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--sky,
.doc-table-cell.doc-table-cell--bg--sky.doc-table-cell--selectedCell{
	background: var(--accentSky50) !important;  // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--pink,
.doc-table-cell.doc-table-cell--bg--pink.doc-table-cell--selectedCell{
	background: var(--accentPink50) !important;  // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--teal,
.doc-table-cell.doc-table-cell--bg--teal.doc-table-cell--selectedCell{
	background: var(--accentTeal50) !important;  // override for header row/col
}

.doc-table-cell.doc-table-cell--bg--grey,
.doc-table-cell.doc-table-cell--bg--grey.doc-table-cell--selectedCell{
	background: var(--accentGrey50) !important;  // override for header row/col
}

// td.doc-table-cell.doc-table-cell--bg--green.doc-table-cell--selectedCell:after{
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// }






//
// Selected State

.doc-table td,
.doc-table th{
	position: relative;
	cursor: text; 
}

.doc-table td.doc-table-cell--cursorInside:after,
.doc-table th.doc-table-cell--cursorInside:after{
  z-index: 2;
  position: absolute;
  content: '';
  top: -1px;
  bottom: -1px;
	left: -1px;
	right: -1px;
  
  border: 2px solid var(--primary400);
  border-radius: 2px;
  
  pointer-events: none;
}

$tableSelectBorder-doc-table: 2px solid var(--primary400);

.doc-table td.doc-table-cell--selectedCell:after,
.doc-table th.doc-table-cell--selectedCell:after{
  z-index: 2;
  position: absolute;
  content: '';  
  top: -1px;
  bottom: -1px;
	left: -1px;
	right: -1px;
    
  // border-radius: 2px;
  
  pointer-events: none;
}

.doc-table td.doc-table-cell--selectedCell--nw:after,
.doc-table th.doc-table-cell--selectedCell--nw:after{
	border-top: $tableSelectBorder-doc-table;	
	border-left: $tableSelectBorder-doc-table;
	top: -1px;
	left: -1px;

	border-top-left-radius: 2px;
}

.doc-table td.doc-table-cell--selectedCell--n:after,
.doc-table th.doc-table-cell--selectedCell--n:after{
	border-top: $tableSelectBorder-doc-table;

	top: -1px;	
}

.doc-table td.doc-table-cell--selectedCell--ne:after,
.doc-table th.doc-table-cell--selectedCell--ne:after{
	border-top: $tableSelectBorder-doc-table;	
	border-right: $tableSelectBorder-doc-table;

	border-top-right-radius: 2px;

	top: -1px;
	right: -1px;
}

.doc-table td.doc-table-cell--selectedCell--e:after,
.doc-table th.doc-table-cell--selectedCell--e:after{	
	border-right: $tableSelectBorder-doc-table;

	right: -1px;
}

.doc-table td.doc-table-cell--selectedCell--se:after,
.doc-table th.doc-table-cell--selectedCell--se:after{	
	border-bottom: $tableSelectBorder-doc-table;
	border-right: $tableSelectBorder-doc-table;

	border-bottom-right-radius: 2px;

	right: -1px;
	bottom: -1px;
}

.doc-table td.doc-table-cell--selectedCell--s:after,
.doc-table th.doc-table-cell--selectedCell--s:after{	
	border-bottom: $tableSelectBorder-doc-table;	

	bottom: -1px;
}

.doc-table td.doc-table-cell--selectedCell--sw:after,
.doc-table th.doc-table-cell--selectedCell--sw:after{	
	border-bottom: $tableSelectBorder-doc-table;
	border-left: $tableSelectBorder-doc-table;

	border-bottom-left-radius: 2px;	

	bottom: -1px;
	left: -1px;
}

.doc-table td.doc-table-cell--selectedCell--w:after,
.doc-table th.doc-table-cell--selectedCell--w:after{	
	border-left: $tableSelectBorder-doc-table;	

	left: -1px;
}





.doc-table-selectionBox{
	//margin-top: $topPadding-tableContainer;
	position: absolute;

	border: 2px solid var(--primary400);
	border-radius: 2px;
	
	z-index: 3;
	pointer-events: none;
}






.doc-table-selectionBoxResizableContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 0px;
	height: 0px;
	overflow: visible;

	z-index: 100;
}

.doc-table-selectionBoxResizableHandle{
	//background: red;
}

.doc-table-selectionBoxResizableHandle--topLeft{
	
	z-index: 100;
}

.doc-table-selectionBoxResizableHandle--bottomRight{
	
	z-index: 100;
}


//

// Example Wide Table
// // WIDE
// .doc-table{
// 	width: 720px;
// 	width: 1000px;
// 	margin-left: -240px;
// 	padding-left: 240px;
// 	// background: $tempr;
// 	overflow-x: scroll;
// 	overflow-y: hidden;

// 	padding-bottom: 24px;
// 	padding-top: 36px;
// }

// .doc-table-content{
// 	width: 2000px;
// }



// BORDER

.doc-table td,
.doc-table th{
	//border: 1px solid rgb(222,220,225);
	border: 1px solid rgb(228,226,232);
}

[data-theme="dark"]{
	.doc-table td,
	.doc-table th{
		border: 1px solid rgb(60,60,70);
	}
}




//

.doc-table td .doc-para,
.doc-table th .doc-para,
.doc-table td .doc-para-date-label{
	//font-size: 14.75px;
	font-size: 15px;
	line-height: 1.29;
	letter-spacing: 0.008em;

	position: relative;
	z-index: 5; // above bg background
}

// Force TH
.doc-table th .doc-para{
	text-align: left;
	//font-weight: $lato-semibold;
}


.doc-table td.doc-table-cell--leftAlign .doc-para{
	text-align: left;
	//font-weight: $lato-semibold;
}

.doc-table td.doc-table-cell--centerAlign .doc-para{
	text-align: center;
	//font-weight: $lato-semibold;
}

.doc-table td.doc-table-cell--rightAlign .doc-para{
	text-align: right;
	//font-weight: $lato-semibold;
}


// Headers



//

// tables
.doc-table td .doc-inlineLinearIssue .doc-inlineLinearIssue-identifer {
	font-size: 14.00px;
	line-height: 1.29;
	letter-spacing: 0.008em;
}

.doc-table td .doc-inlineLinearIssue .doc-inlineLinearIssue-assignee-img{
	width: 17px;
	height: 17px;
}


.doc-table td .doc-para-date-iconContainer svg{
	width: 16px;
	height: 16px;
}


// .doc-table th .doc-para{
// 	min-height: 40px;
// }

.doc-table table{
	padding-top: 24px;
	padding-left: 24px;
}

//

.doc-table .tableWrapper{
	margin: 0px !important; // override prosemirror
}

.doc-table .tableWrapper,
.doc-table table,
.doc-table table td{
	overflow: unset;
}

.doc-table td,
.doc-table th{
	position: relative;
	padding-left: 9px;
	padding-right: 9px;
	padding-top: 7px;
	padding-bottom: 7px;
}

.doc-table td .doc-table-cell-content{
	width: 100%;
	height: 100%;

	//background: $tempr;
}


.doc-table td.selectedCell{
	//background:red;
}

.doc-table td.selectedCell::after,
.doc-table th.selectedCell::after{
	background: none;
}

//
// curved border



// .doc-table td::selection{
// 	background-color: rgba(0,0,0,0) !important;
// }

// .doc-table td.activeCursorCell::selection{
// 	background-color: var(--textSelectionBlue);
// }

// .doc-table td.selectedCell::selection,
// .doc-table td.selectedCell *::selection{
// 	background-color: rgba(0,0,0,0) !important;
// }

.doc-table td.selectedCell,
.doc-table th.selectedCell{
	caret-color: rgba(0,0,0,0);
}

td::selection,
td * ::selection,
th::selection,
th * ::selection{
	background-color: rgba(0,0,0,0) !important;
}

td.doc-table-cell--cursorInside ::selection,
td.doc-table-cell--cursorInside * ::selection,
th.doc-table-cell--cursorInside ::selection,
th.doc-table-cell--cursorInside * ::selection{
	background: var(--textSelectionBlue)  !important;
}

//

@import 'Doc-Tables-EditRowColBtn';







// .doc-table tr:first-child td:nth-child(1) .doc-table-editRowColBtn--col{
// 	border-top-left-radius: 3px;
// 	border-bottom-left-radius: 3px;
// }

// .doc-table tr:first-child td:last-child .doc-table-editRowColBtn--col{
// 	border-top-right-radius: 3px;
// 	border-bottom-right-radius: 3px;
// }

// .doc-table tr:nth-child(1) td .doc-table-editRowColBtn--row{
// 	border-top-left-radius: 3px;
// 	border-top-right-radius: 3px;
// }

// .doc-table tr:last-child td .doc-table-editRowColBtn--row{
// 	border-bottom-left-radius: 3px;
// 	border-bottom-right-radius: 3px;
// }




//
//

@import 'Doc-Tables-ActionMenu';

@import 'Doc-Tables-RichText';

//
//

@import 'Doc-Tables-NewRowColBtn';



//
// CELL SELECT COLOR

.contextMenu-menu-cellColorSelect{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;

	padding-left: 10px;
	padding-right: 8px;
	padding-top: 2px;
}

.contextMenu-menu-cellColorSelect-btn{
	width: 32px;
	height: 24px;
	padding: 3px;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	position: relative;
}

.contextMenu-menu-cellColorSelect-btn:hover{
	background: var(--250gray);
	transition: background 50ms linear;
}

.contextMenu-menu-cellColorSelect-btn-inner{
	width: 28px;
	height: 20px;
	border-radius: 2px;

	opacity: 0.4;
	transition: opacity 50ms linear;
}

.contextMenu-menu-cellColorSelect-btn:hover
.contextMenu-menu-cellColorSelect-btn-inner{
	opacity: 0.5;
	transition: opacity 50ms linear;
}

.contextMenu-menu-cellColorSelect-btn--color--white
.contextMenu-menu-cellColorSelect-btn-inner,
.contextMenu-menu-cellColorSelect-btn.contextMenu-menu-cellColorSelect-btn--color--white:hover
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	opacity: 1;

}


.contextMenu-menu-cellColorSelect-btn-border{
	position: absolute;
	top: 4px;
	left: 4px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border-radius: 3px;
	border: 1px solid var(--line300);

	opacity: 0.75;
	transition: opacity 50ms linear;
}

.contextMenu-menu-cellColorSelect-btn:hover
.contextMenu-menu-cellColorSelect-btn-border{
	opacity: 1;
	transition: opacity 50ms linear;
}


.contextMenu-menu-cellColorSelect-btn--color--green
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentGreen600);
}

.contextMenu-menu-cellColorSelect-btn--color--purple
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentPurple600);
}

.contextMenu-menu-cellColorSelect-btn--color--red
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentRed600);
}

.contextMenu-menu-cellColorSelect-btn--color--blue
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentBlue600);
}

.contextMenu-menu-cellColorSelect-btn--color--orange
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentOrange600);
}

.contextMenu-menu-cellColorSelect-btn--color--sky
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentSky600);
}

.contextMenu-menu-cellColorSelect-btn--color--pink
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentPink600);
}

.contextMenu-menu-cellColorSelect-btn--color--teal
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentTeal600);
}

.contextMenu-menu-cellColorSelect-btn--color--grey
.contextMenu-menu-cellColorSelect-btn-inner{
	background: var(--accentGrey600);
}

.contextMenu-menu-cellColorSelect-btn--color--green
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentGreen600);
}

.contextMenu-menu-cellColorSelect-btn--color--purple
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentPurple600);
}



.contextMenu-menu-cellColorSelect-btn--color--red
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentRed600);
}

.contextMenu-menu-cellColorSelect-btn--color--blue
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentBlue600);
}

.contextMenu-menu-cellColorSelect-btn--color--orange
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentOrange600);
}

.contextMenu-menu-cellColorSelect-btn--color--sky
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentSky600);
}

.contextMenu-menu-cellColorSelect-btn--color--pink
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentPink600);
}

.contextMenu-menu-cellColorSelect-btn--color--teal
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentTeal600);
}

.contextMenu-menu-cellColorSelect-btn--color--grey
.contextMenu-menu-cellColorSelect-btn-border{
	border-color: var(--accentGrey600);
}





// .doc-table td:after{
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	pointer-events: none;
// 	background: red;
// }

// // .doc-table td.doc-table-cell--cursorInside::after{
// // 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	pointer-events: none;
// 	outline: 2px solid red;
// }

// .doc-table-singleSelectionBox{
// 	//margin-top: $topPadding-tableContainer;
// 	position: absolute;

// 	border: 2px solid var(--primary400);
// 	border-radius: 2px;
	
// 	z-index: 3;
// 	pointer-events: none;

// }


//




// .doc-table-selectionBox-dot{
// 	position: absolute;
// 	width: 5px;
// 	height: 5px;
// 	border-radius: 50%;

// 	border: 2px solid red;
// 	background: var(--bg-docEditor);
// 	pointer-events: auto;

// 	display: none;
// 	// pointer-events: none;
// }

// .doc-table-selectionBox-dot--topLeft{
// 	top: -4px;
// 	left: -4px;
// 	// top: -2px;
// 	// left: -2px;
// 	cursor: nwse-resize;
// }

// .doc-table-selectionBox-dot--bottomRight{
// 	// bottom: -4px;
// 	// right: -4px;
// 	bottom: -4px;
// 	right: -4px;
	
// 	cursor: nwse-resize;
// }



//
//



// .doc-table-selectionBoxResizableContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: 100%;

// 	background: $tempg;

// 	z-index: 20;
	
// }

// .doc-table-selectionBoxResizable{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: 100%;

// 	border: 1px solid blue;
// 	pointer-events: none;
// }

// .doc-table-selectionBoxResizable div{
// 	pointer-events: auto;
// }