//

$height-inlineVideoUI-commentRow: 16px; 

.doc-video-inlineUI-controlBar{
	position: absolute;
	bottom: $innerMargin-participant-video-ui;
	left: 50%;
	transform: translateX(-50%);

	width: 340px;
	width: 360px;
	width: 400px;
	width: 490px;

	height: $height-startedControls-inlineUI;
	cursor: default;
}


.doc-video-inlineUI-controlBar.doc-video-inlineUI-controlBar--hasTimestampedComments{
	height: calc(#{$height-startedControls-inlineUI} + #{$height-inlineVideoUI-commentRow})
}


.doc-video-inlineUI-controlBar--show{
	opacity: 1;
	transition: opacity 50ms linear;
}

.doc-video-inlineUI-controlBar--hide:not(:hover){
	opacity: 0;
	pointer-events: none;
	transition: opacity 150ms linear;
}


//

.doc-video-inlineUI-controlBar-seekBarContainer{
	position: absolute;
	
	left: 0px;
	right: 0px;
	bottom: 40px;
	
	height: 28px;

	box-sizing: border-box;

	padding-left: 12px;
	padding-right: 12px;

	z-index: 10;	

	cursor: pointer;

	// background: red;
}

/// SLIDER

.doc-video-inlineUI-controlBar-seekBar{
	height: 100%;
	// background: $green;
}

.doc-video-inlineUI-controlBar-seekBar-track{
	height: 4px;
	margin-top: 12px;
}

.doc-video-inlineUI-controlBar-seekBar-track-0{
	background: white;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.doc-video-inlineUI-controlBar-seekBar-track-1{	
	border-radius: 2px;
	background: rgb(86,99,114);
}

.doc-video-inlineUI-controlBar-seekBar-thumb{
	width: 5px;
	height: 16px;
	background: $white;
	border-radius: 3px;
	
	margin-top: 6px;
	// margin-left: -6px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 0px 2px 0px rgba(0,0,0,0.12),
		0px 0px 0px 1px rgba(0,0,0,0.12),

		;	
}

.doc-video-inlineUI-controlBar-seekBar-thumb:focus{
	outline: none;
}

.doc-video-inlineUI-controlBar-controlBar-thumb-thumb{

}

//

.doc-video-inlineUI-controlBar-counterRow{
	position: absolute;	
	height: 20px;
	left: 0px;
	right: 0px;
	bottom: 28px;

	padding-left: 12px;
	padding-right: 12px;

	box-sizing: border-box;

	// background: $green;
	user-select: none;
	display: flex;
}


.doc-video-inlineUI-controlBar-counterRow-counter{
	width: 64px;
	height: 100%;
	
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;

	font-size: 12.5px;
	letter-spacing: 0.01em;
	box-sizing: border-box;

	padding-bottom: 1px;	
	
	color: $white;
}

.doc-video-inlineUI-controlBar-counterRow-counter--elapsed{
	justify-content: flex-start;
	// padding-left: 18px;		

	font-weight: $lato-semibold;
	opacity: 0.9;
}

.doc-video-inlineUI-controlBar-counterRow-counter--duration{
	margin-left: auto;
	justify-content: flex-end;

	font-weight: $lato-semibold;

	opacity: 0.7;
}




//
//

button.doc-video-inlineUI-controlBar-controlRow-playPauseBtn{
	width: 64px;
	height: 38px;

	display: flex;
	align-items: center;
	justify-content: center;
	// outline: 1px solid red;

	border-radius: 4px;

	background: none;
	border: none;

	cursor: pointer;
	padding: 0px;
}

.doc-video-inlineUI-controlBar-controlRow-playPauseBtn:hover{
	background: rgba(0,0,0,0.15);
}

.doc-video-inlineUI-controlBar-controlRow-playPauseBtn svg{
	width: 24px;
	height: 24px;
	fill: $white;
}

.doc-video-inlineUI-controlBar-controlRow-playPauseBtn:hover svg{
	transform: scale(1.05);
}

.doc-video-inlineUI-controlBar-controlRow-playPauseBtn svg.icon--name--playbackPlay{
	margin-right: -2px;
}



//

//


.doc-video-inlineUI-controlBar-controlRow{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;

	//height: 44px;
	height: 40px;

	// background: orange;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.doc-video-inlineUI-controlBar-controlRow-secondaryBtn{
	width: 38px;
	height: 38px;

	background: none;
	border: none;
	box-shadow: none;
	// outline: 1px solid red;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	cursor: pointer;

	opacity: 0.7;

	padding: 0px;
}

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn:hover{
	opacity: 1;
	background: rgba(0,0,0,0.15);
}

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn svg{
	height: 24px;
	width: 24px;
	fill: $white;
}

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn:hover svg{
	transform: scale(1.05);
}

//

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--mute svg{
	width: 16px;
	height: 16px;
}

button.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--mute{
	position: relative;
	left: -38px;
	opacity: 0.5;
}

//

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captionsIcon{
	width: 28px;
	height: 18px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: $lato-bold;
	letter-spacing: 0.004em;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}


.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captions--active
.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captionsIcon{
	background: $white;
	border: 0.5px solid $invisible;
	color: var(--text600);
}

.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captions--inactive
.doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captionsIcon{
	background: rgba(255,255,255,0.12);
	border: 0.5px solid rgba(255,255,255,0.25);
	color: rgba(255,255,255,0.75);
}

//
// VOLUME SLIDER

.doc-video-inlineUI-controlBar-controlRow-volumeSlider{
	position: absolute;
	left: 7px;
	bottom: 6px;
	height: 23px;

	width: 100px;
}


.doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn{
	height: 100%;
	width: 26px;

	cursor: pointer;

	display: flex;
	
	position: relative;
	cursor: pointer;

	opacity: 0.5;	
	
	transition: opacity 150ms linear;	
	transition-delay: 200ms;
}


.doc-video-inlineUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn,
.doc-video-inlineUI-controlBar-controlRow-volumeSlider:hover
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn{
	opacity: 1;
	transition: opacity 50ms linear;	
	transition-delay: 100ms;
}

// .doc-video-inlineUI-controlBar-controlRow-volumeSlider:hover
// .doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn{
// 	transition-delay: 200ms;
// }


.doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn svg{
	width: 16px;
	height: 16px;
	fill: $white;	
}



.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;

	left: 26px;
	right: 0px;
	
	box-sizing: border-box;
	
	z-index: 10;	

	cursor: pointer;
	opacity: 0.5;
	transition: opacity 150ms linear;
	transition-delay: 200ms;
	// background: blue;
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider--maxVolume
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	opacity: 0;
}


.doc-video-inlineUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBarContainer,
.doc-video-inlineUI-controlBar-controlRow-volumeSlider:hover 
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBarContainer{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}



.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar{
	height: 100%;
	margin-top: 2px;
	// background: $green;
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-track{
	height: 3px;
	margin-top: 8px;
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-track-0{
	background: white;	
	border-radius: 2px;
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-track-1{	
	border-radius: 2px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background: rgb(86,99,114);
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb{
	width: 7px;
	height: 7px;
	background: $white;
	border-radius: 4px;
	
	margin-top: 6px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 0px 2px 0px rgba(0,0,0,0.12),
		0px 0px 0px 1px rgba(0,0,0,0.12),

		;	

	// opacity: 0;	
	// transition: opacity 150ms linear;
	// transition-delay: 200ms;
}

.doc-video-inlineUI-controlBar-controlRow-volumeSlider--adjustingVolume
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb,
.doc-video-inlineUI-controlBar-controlRow-volumeSlider:hover 
.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb{
	// opacity: 1;
	// transition: opacity 50ms linear;
	// transition-delay: 100ms;
}


.doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb:focus{
	outline: none;
	border: none;
}


.doc-video-inlineUI-controlBar-controlBar-thumb-thumb{

}


//

.doc-video-inlineUI-controlBar-commentRowContainer{
	position: absolute;
	width: 466px;
	top: -4px;
	left: 50%;
	transform: translateX(-50%);

	height: $height-inlineVideoUI-commentRow;

	// background: $tempr;

}

// .doc-video-inlineUI-controlBar-commentRowContainer
// .doc-video-commentRow-commentMarker{
// 	transform: scale(0.9);
// 	transform-origin: 50% 50%;
// }

// .doc-video-inlineUI-controlBar-commentRowContainer
// .doc-video-commentRow-commentMarker:hover{
// 	transform: scale(1);	
// }