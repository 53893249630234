$interiorLeftMarginToMenus-docEditor: 8px;
$interiorRightMarginToMenus-docEditor: 4px;
$interiorTopBottomMarginToMenus-docEditor: 10px;
$interiorTopMarginToMenus-docEditor: 6px;
$width-participantMenu-feedback: 44px;
//$topPadding-doc-draft: 96px;
//$topPadding-doc-draft: 134px;
$topPadding-doc: 98px; // subtract height of top menu

$height-docEditor-topMenu: 36px;
//$width-docEditorRecordingPreviewContainer: 74px;
$width-docEditorRecordingPreviewContainer: 96px;

.docEditorContainer{
	position: fixed;
	
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-docEditor);

	border-radius: 8px;

	z-index: 5;

	scroll-padding-top: calc(#{$height-docEditor-topMenu} + 14px); // this allows scrolling into view without getting blocked behind the topmenu
	
	display: flex;

	box-sizing: border-box;


}

.docEditorContainer--sideMenuState--hidden{
	left: calc(#{$windowMargin-sideMenuMode} - 1px);
}



//


//

.docEditor{
	display: flex;
	height: 100%;

	position: relative;
	z-index: 10; // this is higher than message panel

	flex-grow: 1;
	flex-shrink: 1;

	box-sizing: border-box;
	padding-top: $height-docEditor-topMenu;
}

// .docEditorContainer--sideMenuState--visible
// .docEditor--rightPanelHidden
// .docEditor-docInnerContainer{
// 	padding-right: 8vw;
// }


.docEditorContainer--messageMediaIsZoomed .docEditor{
	z-index: 2; // lower than message panel
}

// sidemode
.docEditor-docInnerContainer--showHTMLOutputPanel{	
	padding-right: 1500px !important;	
}


// sidemode
.docEditor-docInnerContainer--showHTMLOutputPanel{	
	padding-right: 1500px !important;	
}


// // overlay mode
// .docEditor-docInnerContainer--sideMode{	
// 	padding-right: 0px !important;
// 	padding-left: 0px !important;
// 	//padding-left: 450px !important; 
// }


// .docEditor--draft-metadataBtn--channel--add{
// 	display: none !important;
// }

// .docEditor--draft-metadataBtn{
// 	display: none !important;
// }

//

.docEditor-docLeftMarginSpacer{
	flex-grow: 0;
	flex-shrink: 0;
}

.docEditor-docOuterContainer{
	overflow-x: hidden;
	overflow-y: scroll;

	position: relative;
	z-index: 0;

	width: 100%;

	// background: $tempr;

	display: flex;
	justify-content: center;
}

.docEditorContainer--figmaModalIsZoomed
.docEditor-docOuterContainer::-webkit-scrollbar{
	display: none; // hide scrollbar when zooming
}




.docEditor-docInnerContainer{
	width: $textWidth-doc;
}


.docEditor-docRightMarginSpacer{
	flex-grow: 0;
	flex-shrink: 0;	
}



.docEditor-docRightMarginSpacer-teamPanelSpacer{
	width: $width-teamPanel;
}

.docEditor-docRightMarginSpacer-threadPanelSpacer{
	//width is in corelayout
	padding-right: $width-teamPanel--narrow;

}



//
// SAVE STATUS

.docEditor-docTitle:hover ~ 
.docEditor-saveStatus{
	opacity: 0.8;
	transition: all 50ms linear;
	transition-delay: 200ms;
}

.docEditor-saveStatus{
	margin-top: -10px;
	margin-bottom: 22px;
	font-size: 15.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.018em;
	color: var(--text500);
	opacity: 0.5;
	pointer-events: none;

	transition: all 50ms linear;

	position: relative;
	z-index: 5;
}

[data-theme="dark"] .docEditor-saveStatus{
	opacity: 0.4;
}


@import 'DocEditor-TopMenu';

@import 'DocEditor-SelectionMenu'; 

@import 'DocEditor-StashedPanel'; 
@import 'DocEditor-IssuePanel'; 

@import 'DocEditor-SummarizerProto';

@import 'DocEditor-ProjectIcon';
@import 'DocEditor-ProjectDescription';
@import 'DocEditor-ProjectSubDocsTable';

@import 'DocEditor-ProjectContentTabs'; 
@import 'DocEditor-ProjectFigmaGrid'; 

@import 'DocEditor-ProjectMentionsSheet'; 

@import 'DocEditor-InsertMenu';  // / slash command opener
@import 'DocEditor-InsertLinkPopover';  // allows you to insert a link

@import 'DocEditor-TopRightFixedMenu'; 
@import 'DocEditor-BottomRightFixedMenu'; 

@import 'DocEditor-MeetingInfo'; 

@import 'DocEditor-ProjectUpdates'; 

@import 'DocEditor-ProjectUpdates'; 

@import 'DocEditor-InDocSearch';

@import 'DocEditor-OutputPanel'; 
// @import 'DocEditor-PresencePanel'; 

@import 'DocEditor-Metadata';

@import 'DocEditor-SuggestionsMenu'; 

@import 'DocEditor-MoveDocDialog'; 

@import 'DocEditor-AddLinearLink'; 



@import 'DocEditor-MeetingContent';

@import 'DocEditor-RecordingPreview'; 

@import 'DocEditor-TemplateSelection'; 

@import 'DocEditor-TemplateSelect'; 

@import 'DocEditor-CreateTemplatePopover'; 


