.tooltip{
  background: $tempr;
  border: 1px solid var(--borderColorTooltip);
  background: var(--bgTooltip);
  color: var(--textTooltip);

  // font-size: 13.5px;
  // font-weight: $lato-semibold;
  // letter-spacing: 0.02em;

  font-size: 13px;
  font-weight: $lato-medium;
  letter-spacing: 0.022em;

  box-shadow: var(--shadowTooltip);

  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 4px; 

  max-width: 250px;

  white-space: nowrap;

  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip[data-side="top"]{
  margin-bottom: 2px;
}

.tooltip[data-side="bottom"]{
  margin-top: 2px;
}

.tooltip[data-side="left"]{
  margin-right: 2px;
}

.tooltip[data-side="right"]{
  margin-left: 2px;
}

.tooltip--textSelectionUI{
  max-width: 500px;
}




.tooltip--uploadRecordingButton{
  max-width: 210px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}



.tooltip.tooltip--addToLinear[data-side="top"]{
  margin-bottom: -4px;
}



.tooltip--activityRoadmap{
  max-width: 120px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}

.tooltip--addToCalendarEmails{
  white-space: unset;
  text-align: center;
  width: 160px;
  padding-top: 5px;
  padding-bottom: 6px;
}


.tooltip--newTeamSyncTeamBtn{
  width: 220px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}

.tooltip--projectsSideControls{
  max-width: 200px;
  white-space: unset;
  margin-left: 6px !important;
}

.tooltip.tooltip--mediaHoverMenu[data-side="top"]{
  margin-top: -2px;
}

.tooltip--warning{
  //background: var(--accentRed600);
  //border-color: var(--accentRed700);
  color: var(--accentRed200);
}



.tooltip.tooltip--textSelectionUI.tooltip--tableHoverBtn[data-side="top"]{
  margin-top: -5px;
}

.tooltip.tooltip--textSelectionUI.tooltip--tableRowCol[data-side="top"]{
  margin-top: -3px;
}


//
// KEYBOARD SHORTCUTS FOR TOOLTIPS
.tooltip .keyboardShortcutTagContainer{
  width: 100%;
  margin-left: 5px;
  margin-right: -2px;

  display: flex;
  align-items: center;
  justify-content: center;
}


// .tooltip--tableHoverRowCol{
//   padding-left: 0px; // override of the shortcut offset
// }
// .tooltip--tableHoverRowCol:first-child
// .keyboardShortcutTagContainer{
//   margin-right: 0px;
// }



.tooltip .keyboardShortcutTag{
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;

  font-size: 13px;

  background: var(--bgKeyboardShortcutTooltip);
  color: var(--textKeyboardShortcutTooltip);

  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $lato-bold;
}

.tooltip.tooltip--withSquareBracket .keyboardShortcutTag{
  // square brackets look a bit off
  font-size: 12.5px;
}

.tooltip .keyboardShortcutTag svg{
  width: 12px;
  height: 12px;
  fill: var(--textKeyboardShortcutTooltip);
}

.tooltip .keyboardShortcutTag-item{  
  height: 12px;
  width: 13px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly{
  margin-top: 0px;
  width: 100%;
  margin-left: -4px;
  margin-right: -4px;
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly .keyboardShortcutTag-item--letter{
  margin-right: -1px; // not sure why this is required, for move to doc dialog
}

.tooltip .keyboardShortcutTagContainer--keyboardShortcutOnly
.tooltip .keyboardShortcutTag{
  // background: $invisible;
  width: 100%;
}

//
// HIDE TOOLTIP IF WRAPPING DROPDOWN IS OPEN
button[data-state="open"] span[data-state="closed"]{
  pointer-events: none;
}

// FORCE HIDE TOOLTIP
.tooltip--forceHide{
  display: none;
}


