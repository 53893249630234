.docEditor-linearProjectPopoverContainer{	
	margin-left: -82px;
	margin-top: 4px;
}



.docEditor-linearProjectPopover{
	width: 280px;

	background: $white;
	border: 1px solid var(--line500);
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);

	
	padding-top: 16px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
}

.docEditor-linearProjectPopover-header{

}

.docEditor-linearProjectPopover-topline{
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 12px;

}

.docEditor-linearProjectPopover-topline-projectIconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}
.docEditor-linearProjectPopover-topline-projectIconContainer svg{
	fill: #5E6AD2;
	width: 22px;
	height: 22px;
}
	
.docEditor-linearProjectPopover-topline-linearIconContainer{

}

.docEditor-linearProjectPopover-topline-linearIconContainer svg{
	width: 16px;
	height: 16px;
	opacity: 0.5;
	filter: grayscale(100%);
}

//

.docEditor-linearProjectPopover-projectTitle{
	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.002em;
	color: var(--text600);

	margin-bottom: 16px;
}

//

.docEditor-linearProjectPopover-sectionTitle{
	
	

	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	margin-top: 14px;
	margin-bottom: 6px;

	pointer-events: none;
}

.docEditor-linearProjectPopover-divider{
	width: 100%;
	height: 1px;
	background: var(--text400);
	opacity: 0.15;
	margin-top: 6px;
	margin-bottom: 6px;
}

.docEditor-linearProjectPopover-actionItem{
	height: 30px;
	
	width: calc(100% + 10px + 10px);
	margin-left: -10px;
	padding-left: 10px;
	padding-right: 10px;

	box-sizing: border-box;

	display: flex;
	align-items: center;

	// background: $tempr;
	font-size: 15px;

	color: var(--text400);
	font-weight: $lato-semibold;

	cursor: pointer;
	
	border-radius: 4px;
	user-select: none;

	line-height: 1.9;
}

.docEditor-linearProjectPopover-actionItem:hover{
	background: rgb(243,242,243);
	color: var(--text600);
}

.docEditor-linearProjectPopover-actionItem-avatarContainer{
	width: 24px;
	height: 24px;

	margin-right: 8px;
}

.docEditor-linearProjectPopover-actionItem--projectLead{
	margin-top: 8px;
	margin-bottom: 8px;
}
.docEditor-linearProjectPopover-actionItem--projectLead
.docEditor-linearProjectPopover-actionItem-label{
	font-weight: $lato-bold;
}

.docEditor-linearProjectPopover-actionItem-avatar{
	width: 24px;
	height: 24px;
	object-fit: cover;
	border-radius: 3px;
}