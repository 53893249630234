//
// PROJECT ROW

.roadmapsProjectRow{
	width: 100%;
	
	// margin-top: 2px;
	// margin-bottom: 2px;

	
	// background: $tempb;
	// outline: 1px solid blue;

	height: 44px;	
	height: 36px;	

	display: flex;
	align-items: center;
	

	box-sizing: border-box;

	cursor: pointer;

	border-radius: 5px;

	padding-left: 14px;
	padding-right: 12px;

  margin-left: -14px;
  width: calc(100% + 14px);

  // margin-left: -48px;
  // width: calc(100% + 60px);


	border: 1px solid $invisible;
	user-select: none;

	// background: $tempr;
}

.roadmapsProjectRow:hover{
	background: var(--253gray);
	outline: 1px solid var(--line100);
}


.roadmapsProjectRow[data-state="open"],
.roadmapsProjectRow--popoverOpen{
	background: var(--253gray);
	outline: 1px solid var(--line100);

	// background: var(--248gray);
	// outline: 1px solid var(--line200);
}

.roadmapsProjectRow--lastItemInGroup{
	margin-bottom: 20px;
}

// .projectsPage-projectRow--startedNoMilestones{
// 	margin-bottom: 8px;
// }

.roadmapsProjectRow--lastItemInGroup.projectsPage-projectRow--noMilestones{
	margin-bottom: 28px;
}


//
// STATUS

.roadmapsProjectRow-statusContainer{	
	
	height: 28px;
	width: 28px;	

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 4px;

	cursor: pointer;
}

.roadmapsProjectRow-statusContainer:hover,
.roadmapsProjectRow-statusOuterContainer[data-state="open"] .roadmapsProjectRow-statusContainer{
	background: var(--246gray);
}

.roadmapsProjectRow-statusContainer
.linearProjectStatusIcon{
	width: 15px;
	height: 15px;
}

.roadmapsProjectRow-statusContainer
.linearProjectStatusIcon--started-dot{
	width: 6px;
	height: 6px;
}


.roadmapsProjectRow-statusContainer svg.icon--name--toDoItemCheckbox{
	width: 11px;
	height: 11px;
}

//
// MARKER 

.roadmapsProjectRow-markerContainer{
	font-size: 16px;
	
	height: 26px;
	width: 22px;	

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	
	margin-right: 8px;
}

.roadmapsProjectRow-markerContainer
.linearMarker{
	font-size: 17px;
}

.roadmapsProjectRow-markerContainer
.linearMarker svg{
	width: 17px;
	height: 17px;
}

//

.roadmapsProjectRow-name{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	
  flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	
}

.roadmapsProjectRow-name-label{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.001em;
	color: var(--text500);
	position: relative;
	top: -0.5px;

	padding-right: 0px;
}

.roadmapsProjectRow-name-label-label{
	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[data-theme="dark"]{
	.roadmapsProjectRow-name{
		letter-spacing: 0.0025em;
	}
}


//

.roadmapsProjectRow-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;

	height: 100%;

	margin-left: auto;
	display: flex;
	align-items: center;
	padding-left: 9px;
}


.roadmapsProjectRow-roadmapTag{
	height: 26px;
	padding-left: 5px;
	padding-right: 6px;
	margin-right: 4px;

	border-radius: 4px;

	display: flex;

	// background: $tempr;
	background: var(--253gray);
}

.roadmapsProjectRow:hover .roadmapsProjectRow-roadmapTag,
.roadmapsProjectRow-roadmapTagContainer[data-state="open"] .roadmapsProjectRow-roadmapTag{
	background: var(--243gray);	
}

.roadmapsProjectRow-roadmapTagContainer[data-state="open"] .roadmapsProjectRow-roadmapTag{
	opacity: 0.5;
}



.roadmapsProjectRow-roadmapTag-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 3px;
}

.roadmapsProjectRow-roadmapTag-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.4;
}

.roadmapsProjectRow-roadmapTag-label{
	font-size: 14.25px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.6;
}

.roadmapsProjectRow-roadmapTag-label:not(:last-child){
	margin-right: 4px; // for comma
}

@media screen and (max-width: 1750px){
	.roadmapsHomePageContainer--sideMenuState--visible .roadmapsProjectRow-roadmapTag{
		display: none; // too small and with fixed positioning annoying
	}
}

@media screen and (max-width: 1600px){
	.roadmapsHomePageContainer--sideMenuState--hidden .roadmapsProjectRow-roadmapTag{
		display: none; // too small and with fixed positioning annoying
	}
}


// 


.roadmapsProjectRow-targetDateOuterContainer{
	flex-grow: 0;
	flex-shrink: 0;
	//margin-left: auto;
	margin-left: 8px;
	//margin-right: -16px;
	min-width: 110px;

	// background: $tempr;
	display: flex;
	justify-content: flex-start;
}

.roadmapsProjectRow-targetDateContainer--add{
	opacity: 0.5;
}

.roadmapsProjectRowContainer:hover
.roadmapsProjectRow-targetDateContainer--add{
	opacity: 1;
}

.roadmapsProjectRow-targetDateContainer{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.roadmapsProjectRow-targetDate{
	display: flex;
	height: 26px;

	//background: var(--253gray);

	position: relative;
	top: 1.2px; // maybe better way of aligning

	align-items: center;
	// margin-left: 7px;

	padding-left: 6px;
	padding-right: 8px;

	border-radius: 4px;
}

.roadmapsProjectRow-targetDate-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.roadmapsProjectRow-targetDate-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text300);
	opacity: 0.6;
	position: relative;
	top: 0px;
}

.roadmapsProjectRow-targetDate-label{
	font-size: 14.25px;
	font-weight: $lato-medium;
	color: var(--text500);

	opacity: 0.6;
}


.roadmapsProjectRow-targetDate:hover,
.roadmapsProjectRow-targetDateOuterContainer[data-state="open"] .roadmapsProjectRow-targetDate{
	background: var(--246gray);
}

.roadmapsProjectRow-targetDate:hover .roadmapsProjectRow-targetDate-label,
.roadmapsProjectRow-targetDateOuterContainer[data-state="open"] .roadmapsProjectRow-targetDate-label{
	opacity: 0.8;
}

.roadmapsProjectRow-targetDate:hover .roadmapsProjectRow-targetDate-iconContainer svg,
.roadmapsProjectRow-targetDateOuterContainer[data-state="open"] .roadmapsProjectRow-targetDate-iconContainer svg{
	opacity: 0.8;
}



.roadmapsProjectRow-targetDate--warning{
	background: hsla(359, 83%, 68%, 0.05); // need it to be good in dark
	opacity: 1;
}

.roadmapsProjectRow-targetDate--warning 
.roadmapsProjectRow-targetDate-iconContainer svg{
	fill: var(--accentRed700);
	opacity: 0.6;
}

.roadmapsProjectRow-targetDate--warning
.roadmapsProjectRow-targetDate-label{
	color: var(--accentRed700);
	opacity: 0.8;
}

//

.roadmapsProjectRow-leadAvatarContainer{
	width: 24px;
	height: 24px;

	margin-left: 12px;

	border-radius: 4px;
	overflow: hidden;
}


.roadmapsProjectRow-leadAvatarContainer--hasLead{
	//transform: scale(0.97);
	//transform-origin: center center;
}

img.selectUserPopover-item-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;	
}

.roadmapsProjectRow-leadAvatarContainer--noLead{
	display: flex;
	align-items: center;
	justify-content: center;
}

.roadmapsProjectRow-leadAvatarContainer--noLead:hover{
	//background: var(--250gray);
}

.roadmapsProjectRow-leadAvatarContainer svg{
	width: 24px;
	height: 24px;
	fill: var(--text500);
	opacity: 0.4;
}

.roadmapsProjectRow-leadAvatarContainer--noLead:hover svg,
.roadmapsProjectRow-leadAvatarOuterContainer[data-state="open"] .roadmapsProjectRow-leadAvatarContainer--noLead svg{
	opacity: 0.6;
	transform: scale(1.02);
}

@import 'Roadmaps-ProjectRow-Tooltips';

@import 'Roadmaps-ProjectRow-Milestones';



//.roadmapsProjectRow-teamTagContainer{
// 	display: flex;
// 	align-items: center;
// 	gap: 3px;
// 	height: 100%;
// }

// .roadmapsProjectRow-teamTag{
// 	padding-left: 6px;
// 	padding-right: 6px;
	
// 	height: 20px;

// 	font-size: 13px;
// 	font-weight: $lato-medium;
// 	color: var(--text200);
// 	letter-spacing: 0.025em;

// 	text-transform: uppercase;

// 	//background: var(--bgSideMenuTabLight);
// 	// border: 1px solid var(--line300);
// 	background: var(--253gray);
// 	border-radius: 4px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .roadmapsProjectRow-teamTag{
// 	opacity: 1;
// 	// transition: opacity 50ms linear;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag{
// 	background: var(--243gray);
// }


// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag{
// 	opacity: 1;
// 	transition: opacity 150ms linear;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team1{
// 	transition-delay: 50ms;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team2{
// 	transition-delay: 100ms;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team3{
// 	transition-delay: 150ms;
// }

// //

// .roadmapsProjectRow-dropdownBtn{
// 	height: 24px;
// 	width: 24px;

// 	margin-left: 6px;

// 	border-radius: 4px;

// 	margin-right: 4px;
// }

// .roadmapsProjectRow-dropdownBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .roadmapsProjectRow
// .roadmapsProjectRow-dropdownBtn{
// 	opacity: 0;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn{
// 	opacity: 1;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn svg{
// 	opacity: 0.5;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn:hover{
// 	background: var(--250gray);	
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn:hover svg{
// 	opacity: 1;
// }



// // DIVIDER
// .roadmapsProjectRowDateDivider{
// 	font-size: 14px;	
// 	font-weight: $lato-regular;
// 	letter-spacing: 0.01em;

	
// 	text-align: left;

// 	color: var(--text600);
// 	opacity: 0.5;

// 	margin-top: 14px;
// 	margin-bottom: 6px;

// 	user-select: none;
// 	cursor: default;

// 	padding-left: 3px;
// }

// // PROJECT

// .roadmapsProjectRow{
// 	width: 100%;
// 	height: 38px;
// 	// background: $tempb;
// 	// outline: 1px solid blue;

// 	display: flex;
// 	align-items: center;

// 	box-sizing: border-box;

// 	cursor: pointer;

// 	border-radius: 5px;

// 	padding-left: 8px;
// 	margin-left: -8px;
// 	width: calc(100% + 8px);

// 	border: 1px solid $invisible;

// }

// .roadmapsProjectRow:hover{
// 	background: var(--253gray);
// 	border: 1px solid var(--line100);
// }

// .roadmapsProjectRow-emoji{
// 	font-size: 18px;
	
// 	height: 28px;
// 	width: 28px;

// 	margin-right: 8px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	background: var(--bgSideMenuTabLight);
// 	border-radius: 4px;
// }

// .roadmapsProjectRow-name{
// 	font-size: 16px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.001em;
// 	color: var(--text500);

// 	margin-right: auto;
// }

// .roadmapsProjectRow-teamTag{
// 	padding-left: 4px;
// 	padding-right: 4px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

// 	font-size: 11.5px;
// 	font-weight: $lato-medium;
// 	color: var(--text300);
// 	letter-spacing: 0.025em;

// 	text-transform: uppercase;

// 	//background: var(--bgSideMenuTabLight);
// 	border: 1px solid var(--line200);
// 	border-radius: 2px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: 4px;
// }

// .roadmapsProjectRow-teamTag{
// 	opacity: 0.65;
// 	transition: opacity 50ms linear;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag{
// 	opacity: 1;
// 	transition: opacity 150ms linear;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team1{
// 	transition-delay: 50ms;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team2{
// 	transition-delay: 100ms;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-teamTag--team3{
// 	transition-delay: 150ms;
// }


// .roadmapsProjectRow-leadAvatarContainer{
// 	width: 26px;
// 	height: 26px;

// 	margin-left: 12px;

// 	border-radius: 4px;
// 	overflow: hidden;
// }

// img.selectUserPopover-item-avatar{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;

	
// }

// .roadmapsProjectRow-dropdownBtn{
// 	height: 24px;
// 	width: 24px;

// 	margin-left: 6px;

// 	border-radius: 4px;

// 	margin-right: 4px;
// }

// .roadmapsProjectRow-dropdownBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .roadmapsProjectRow
// .roadmapsProjectRow-dropdownBtn{
// 	opacity: 0;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn{
// 	opacity: 1;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn svg{
// 	opacity: 0.5;
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn:hover{
// 	background: var(--250gray);	
// }

// .roadmapsProjectRow:hover
// .roadmapsProjectRow-dropdownBtn:hover svg{
// 	opacity: 1;
// }
