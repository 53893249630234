
//
// TOOLTIPS
:root{	

	--libraryFrameHeader-menuBtnBG: rgb(250,248,248); // was $lightGray-bg, refactor maybe
	--libraryFrameHeader-menuBtnHoverBG: darken(rgb(250,248,248), 5%);

	--channelBoardDividerBG: rgb(240,238,238); // between boards and list
	--channelBucketBG: rgb(255,255,255);
	--channelBucketEditBG: rgb(251,249,249);
	// --chaennelBucketDivider: rgb()
	
	--channelBucketItemHoverBG: rgb(250,248,250);
	--channelBucketHeaderBorderColor: rgb(234,230,230);
	--channelBucketDescriptionColor: hsl(240, 8%, 41%); //text 300


	--docTableItemHoverBG: rgb(251,248,251);
	--docTableItemContextOpenBG: rgb(244,242,244);
	--docTableHeaderBtnHoverBG: rgb(248,245,245);

	--docTableRowMetadataTagBG: rgb(247,246,246);
	--docTableRowMetadataTagHoverBG: darken(rgb(247,246,246), 5%);

	
}

[data-theme="dark"]{
	--libraryFrameHeader-menuBtnBG: rgba(255,255,255,0.08);
	--libraryFrameHeader-menuBtnHoverBG: rgba(255,255,255,0.12);

	--channelBoardDividerBG: rgb(255,255,255,0.18); // between boards and list
	--channelBucketBG: rgba(255,255,255,0.02);
	--channelBucketEditBG: rgba(255,255,255,0.04);
	
	--channelBucketItemHoverBG: rgba(255,255,255,0.08);
	--channelBucketHeaderBorderColor: rgba(255,255,255,0.20);
	--channelBucketDescriptionColor: hsl(240, 8%, 62%); // text 200

	--docTableItemHoverBG: rgba(255,255,255,0.08);
	--docTableItemContextOpenBG: rgba(255,255,255,0.13);
	--docTableHeaderBtnHoverBG: rgba(255,255,255,0.10);

	--docTableRowMetadataTagBG: rgba(255,255,255,0.10);
	--docTableRowMetadataTagHoverBG: rgba(255,255,255,0.12);
	// --docTableRowMetadataTagChannelIcon: rgba(255,255,255,0.10);

}
