
.dndSideMenu-tabList-tab{
	
	width: 100%;
	//margin-left: 6px;
	border-radius: 5px;

	height: var(--heightSideTab);


	// display: flex;
	// align-items: center;
	// justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 6px;

	cursor: pointer;

	user-select: none;

	position: relative;
	outline: 1px solid $tempr;

	background: rgba(255,255,255,0.25);

	display: flex;
	align-items: center;
}

.dndSideMenu-tabList-tab-label{
	flex-grow: 1;
	flex-shrink: 1;
}

.dndSideMenu-tabList-tab-delete{
	width: 22px;
	height: 22px;
	background: rgba(0,0,0,0.08);
	cursor: pointer;

	opacity: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;
	border: none;

	margin-right: -3px;
}

.dndSideMenu-tabList-tab:hover
.dndSideMenu-tabList-tab-delete{
	opacity: 1;
}

.dndSideMenu-tabList-tab-delete svg{
	height: 14px;
	width: 14px;
	fill: $white;
	opacity: 0.75;
}