
.roadmapsHomePageContainer{
	position: fixed;
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);
	
	overflow-x: hidden;
	overflow-y: scroll;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-channelFrame);

	z-index: 10;

	border-radius: 8px;
}

.roadmapsHomePageContainer--sideMenuState--hidden{
	left: calc(#{$windowMargin-sideMenuMode} - 1px);
}

//

.roadmapsHomePage{
	width: 100%;
	height: 100%;
	display: flex;
}

.roadmapsHomePage-leftSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	// widths set dynamically in coreLayout
}

.roadmapsHomePage-center{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	// widths set dynamically in coreLayout

	padding-top: 56px;
}

.roadmapsHomePage-rightPanel{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;	
	position: relative;
	// widths set dynamically in coreLayout
}

$height-stickyHeader-sticky: 42px;

.roadmapsHomePage-header{
	//padding-top: 64px;
	//padding-top: calc(84px - #{$height-stickyHeader-sticky});
	padding-top: calc(72px - #{$height-stickyHeader-sticky});

	display: flex;
	align-items: center;
	
	//margin-bottom: 18px;

	user-select: none;
	//border-bottom: 1px solid var(--line200);
}


.roadmapsHomePage-stickyContainer{
	width: 100%;
	height: $height-stickyHeader-sticky;
	// background: $tempg;
	position: sticky;
	top: 0px;

	z-index: 50;

	background: var(--bg-docEditor);

	user-select: none;
	cursor: default;
}

.roadmapsHomePage-stickyContainer--hide{
	opacity: 0;
	pointer-events: none;
	transition: opacity 50ms;
}

.roadmapsHomePage-stickyContainer--show{
	opacity: 1;
	transition: opacity 75ms;
}


.roadmapsHomePage-sticky{
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	border-bottom: 1px solid var(--bg-docEditor);

	display: flex;
	align-items: center;
}

.roadmapsHomePage-sticky-title{
	font-size: 19px;
	font-weight: $lato-bold;
	letter-spacing: 0.004em;
	color: var(--text600);
	margin-right: 8px;
}

.roadmapsHomePage-stickyContainer--show
.roadmapsHomePage-sticky-title{
	opacity: 1;
	transform: translateY(0px);
	transition: all 100ms;
	transition-delay: 100ms;
}

.roadmapsHomePage-stickyContainer--hide
.roadmapsHomePage-sticky-title{
	opacity: 0;
	transform: translateY(-3px);
	transition: all 50ms;
	transition-delay: 0ms;
}
	

.roadmapsHomePage-sticky-subtitle{
	font-size: 19px;
	font-weight: $lato-bold;	
	color: var(--text600);
	opacity: 0.5;
}

.roadmapsHomePage-sticky-subtitle--show{
	opacity: 0.5;
	transform: translateY(0px);
	transition: all 100ms;
	transition-delay: 100ms;
}

.roadmapsHomePage-sticky-subtitle--hide{
	opacity: 0;
	transform: translateY(-3px);
	transition: all 50ms;
	transition-delay: 0ms;
}




.roadmapsHomePage-header-title{
	display: flex;
	align-items: center;
	
	padding-bottom: 16px;
	padding-bottom: 24px;
	opacity: 0.25;
	display: none;
}

.roadmapsHomePage-projectList-header-label{
	font-size: 26px;
	font-weight: $lato-heavy;
	color: var(--text600);
}

.roadmapsHomePage-projectList-header-label{
	font-weight: $lato-bold;
}


.roadmapsHomePage-header-title-iconContainer{
	width: 38px;
	height: 38px;
	display: none;
	//background: $tempr;

	margin-right: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.teamPage-header-title-markerContainer
.linearMarker svg{
	width: 34px;
	height: 34px;
}

.roadmapsHomePage-header-title-iconContainer,
.privateDocsPage-header-title-iconContainer{
	width: 40px;
	height: 40px;

}

.roadmapsHomePage-header-title-iconContainer{
	width: 24px;
	height: 24px;
	display: none;
}

.roadmapsHomePage-header-title-iconContainer{
	margin-left: -1px;
	margin-right: 13px;
}

.privateDocsPage-header-title-iconContainer{
	margin-left: -6px;
	margin-right: 8px;
}

.roadmapsHomePage-header-title-iconContainer svg,
.privateDocsPage-header-title-iconContainer svg{
	width: 40px;
	height: 40px;
	fill: var(--text500);
	opacity: 0.4;
}



.roadmapsHomePage-header-title-iconContainer svg{
	position: relative;
	top: 2px;

	width: 24px;
	height: 24px;
}


.roadmapsHomePage-header-title-label{	
	
	//font-size: 39px;
	font-size: 34px;
	//font-size: 36px;
	font-weight: $lato-heavy;
	color: var(--text600);
	
	letter-spacing: 0.001em;

	font-size: 26px;
	font-weight: $lato-heavy;
	color: var(--text600);

	cursor: default;
	user-select: none;
}

[data-theme="dark"] {
	.roadmapsHomePage-header-title-label{
		font-weight: $lato-heavy;
		letter-spacing: 0.005em;
	}
}

.roadmapsHomePage-header-rightContainer{
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.roadmapsHomePage-header-btn{
	padding-left: 16px;
	padding-right: 16px;
	margin-right: -16px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	border-radius: 6px;

	font-size: 16px;
	font-weight: $lato-medium;
	color: var(--text600);

	opacity: 0.6;
}

.roadmapsHomePage-header-btn:hover{
	opacity: 1;
	background: var(--250gray);
}



.roadmapsHomePage-header-btn svg{
	width: 22px;
	height: 22px;
	fill: var(--text500);

	opacity: 0.8;
}


//
// QUARTER

@import 'Roadmaps-HomePage-Quarter';


//

// SORT CONTROLS

.roadmapsHomePage-projectList-sortOptions{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
	padding-top: 10px;

	position: sticky;
	top: calc(#{$height-stickyHeader-sticky} + 12px);
	//width: 300px;
	// width: 100%;
	// margin-bottom: 14px;

	// // dirty
	// position: absolute;
	// left: 700px;
	// margin-left: 45px;
	// margin-top: 14px;
}

.roadmapsHomePage-projectList-sortOptions-group{
	margin-bottom: 18px;
}



//

.roadmapsHomePage-projectList-sortOptions-btn{
	height: 28px;
	display: flex;
	align-items: center;	
	justify-content: flex-start;

	border-radius: 3px;
	// background: $tempr;

	padding-left: 4px;

	width: 210px;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 28px;
	width: 24px;

	margin-right: 4px;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.8;
}
.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortManual{
	transform: scale(0.95);
	transform-origin: center center;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--milestone{
	transform: scale(0.95);
	transform-origin: center center;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortAZ{
	width: 16px;
	height: 16px;
}

.roadmapsHomePage-projectList-sortOptions-btn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
}

.roadmapsHomePage-projectList-sortOptions-btn-subLabel{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);

	opacity: 0.75;

	margin-left: 6px;
}


//
// INFO 

.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer{
	width: 28px;
	height: 28px;

	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: auto;
}

.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);

	opacity: 0;
}

// ROADMAP GRID


@import 'Roadmaps-HomePage-RoadmapGrid';



//
// PROJECT LIST


.roadmapsHomePage-projectList-sortOptions-btn--active{
	background: var(--253gray);
	opacity: 1;
}

.roadmapsHomePage-projectList-sortOptions-btn--inactive{
	//background: var(--250gray);
	opacity: 0.5;
}

.roadmapsHomePage-projectList-sortOptions-btn--inactive:hover{
	background: var(--253gray);
	opacity: 0.8;
}

.roadmapsHomePage-projectList-sortOptions-btn--milestone{
	opacity: 0.8;
}

.roadmapsHomePage-projectList-sortOptions-btn--milestone:hover{
	background: var(--253gray);
	opacity: 1;
}






// // HORIZONTAL

// .roadmapsHomePage-projectList-sortOptions{
// 	display: flex;
// 	width: 100%;
// 	margin-bottom: 14px;
// }

// .roadmapsHomePage-projectList-sortOptions-group{
// 	display: flex;
// }

// .roadmapsHomePage-projectList-sortOptions-group--group{
// 	margin-left: auto;
// }

// .roadmapsHomePage-projectList-sortOptions-group--group-label{
// 	font-size: 15px;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	margin-right: 8px;

// 	color: var(--text600);
// 	opacity: 0.75;
// }

// //

// .roadmapsHomePage-projectList-sortOptions-btn{
// 	height: 30px;
// 	display: flex;
// 	align-items: center;	
// 	justify-content: flex-start;

// 	border-radius: 3px;
// 	// background: $tempr;

// 	padding-left: 3px;
// 	padding-right: 8px;

// 	// width: 210px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	height: 28px;
// 	width: 24px;

// 	margin-right: 4px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text500);
// 	opacity: 0.8;
// }
// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortManual{
// 	transform: scale(0.95);
// 	transform-origin: center center;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortAZ{
// 	width: 16px;
// 	height: 16px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-label{
// 	font-size: 15px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);
// }

// //
// // INFO 

// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer{
// 	width: 28px;
// 	height: 28px;

// 	// background: $tempr;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: auto;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
// 	width: 18px;
// 	height: 18px;

// 	fill: var(--text500);

@import 'Roadmaps-HomePage-ProjectList';



