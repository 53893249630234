
.figmaViewZoomedModal-viewWrapper{
	// background: $tempr;
}

.figmaView{	
	position: relative;	
	user-select: none;
	overflow: hidden
}



.figmaView-cropWindow{	
	position: absolute;
	overflow: hidden;

	cursor: pointer;

	z-index: 10;
}

// green thing
.figmaView-frame{
	position: absolute;
	z-index: 10;	
}

//

.figmaView-frame img.figmaView-frame-img{
	position: absolute;
	z-index: 10;
}




// BACKGROUND IMAGE

.figmaView-backgroundImageContainer{
	position: absolute;
	width: 100%;
	height: 100%;
	
	overflow: hidden;

	will-change: transform;

	
	z-index: 5;
}

.figmaView-backgroundImageContainer--hasBackground{
	cursor: pointer;
}

.figmaView-backgroundImageContainer--noBackground{
	pointer-events: none; // i think this is right, maybe it should focus it or something dunno
}

.figmaView-backgroundImage{
	width: 100%;
	height: 100%;
}

.figmaView-backgroundImage-img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@import 'FigmaView-BackgroundImageStyles';

@import 'FigmaView-DeviceFrame';

@import 'FigmaView-ZoomedModal';

//

.figmaViewZoomedModal-viewWrapper{
	//will-change: transform; // maybe add this, seems smoother, but maybe it's fuzzier?
	//backface-visibility: hidden;
}

.figmaView-modal{
	position: fixed;	
	z-index: 2;

	background: rgb(0,0,0);
	border: 1px solid lighten(rgb(36,33,42), 4%);
	border-top: 1px solid lighten(rgb(36,33,42), 10%);

	border-radius: 5px;
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 8px 16px 0px rgba(0,0,0,0.12),
	;

	display: flex;

	overflow: hidden;

	z-index: 75;

	cursor: default;
}


.figmaView-zoomedBG{
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(1,0,2,0.88);
	cursor: default;
}

.figmaView-zoomedBG--show{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.figmaView-zoomedBG--hide{
	opacity: 0;
	transition: opacity 50ms linear;
}

.figmaView-zoomedBG--disableMotion{
	transition: 0ms !important;
	transition-delay: 0ms !important;
}

//
// CROP MODAL

.figmaView-modalCropCanvas{
	position: absolute;	
	z-index: 10;
	

	opacity: 0;
	pointer-events: none;
}

.figmaView-modalCropCanvas--zoomed.figmaView-modalCropCanvas--cropModeActive{
	opacity: 1;
	pointer-events: auto;
}


//
// SELECTION BG

.doc-figmaEmbed-figmaViewWrapper{
	position: relative;
}

.doc-figma--selectionBG{
	position: absolute;
	z-index: 0;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;

	border-radius: 7px;

	pointer-events: none;
	background: var(--mediaNodeSelectedBG);

	opacity: 0;
	// opacity: 1;
	// background: red;
}

.doc-figmaWrapper--selected .doc-figma--selectionBG{
	opacity: 0.5;

	// opacity: 1;
	// background: green;
}




// //
// // IMAGES
// .doc-figma-image-backgroundImage--ventura,
// .doc-figma-image-backgroundImage--monterey{
// 	width: 140%;
// 	height: 140%;
// 	object-fit: cover;
// 	object-position: center center;
// }