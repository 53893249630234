.checkbox{
	width: 14px;
	height: 14px;
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;	
}

.checkbox[data-is-checked="false"]{
	background: var(--bg-docEditor);
	border: 1px solid var(--checkbox-uncheckedBorderColor);
}

.checkbox[data-is-checked="true"]{
	background: var(--checkbox-checkedBG);
	border: 1px solid var(--checkbox-checkedBorderColor);
}

$dashoffset: 90; // must admit just kind of reverse engineered this number

.checkbox svg{
	pointer-events: none;

	width: 11px;
	height: 11px;
	stroke: var(--bg-docEditor);

	z-index: 10;
	position: relative;		

	stroke-width: 10px;
  stroke-dasharray: $dashoffset;
  stroke-dashoffset: $dashoffset;
}

.checkbox[data-is-checked="true"] svg{
	//transition: stroke-dashoffset 240ms linear;	
	stroke-dashoffset: 0;
}

.checkbox[data-is-checked="false"] svg{
	stroke-dashoffset: $dashoffset;
	//transition: stroke-dashoffset 0ms linear;
}