

.meetingContentAddToGCalPopoverContainer{	
	
}

.meetingContentAddToGCalPopoverContainer:focus{
	outline: none;
}

.meetingContentAddToGCalPopoverContainer--hidden,
.meetingContentAddToGCalPopoverContainer--hidden *{
	pointer-events: none !important;
}


.meetingContentAddToGCalPopover{
	width: 275px;
	// width: 360px;
	
	// min-height: 440px;
	// min-height: 280px;
	// max-height: 380px;
	
	// overflow-y: scroll;

	// height: 400px;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 8px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 4px;
	padding-right: 4px;

	display: flex;
	flex-direction: column;
}

.meetingContentAddToGCalPopover-addDocToMeeting,
.meetingContentAddToGCalPopover-notifications{
	width: 100%;
}

.meetingContentAddToGCalPopover-addDocToMeeting-border{
	width: calc(100% - 8px);
	margin-left: 10px;
	height: 1px;
	background: var(--line300);	
}

.meetingContentAddToGCalPopover-notifications
.selectUserPopover-item{
	height: 50px;
	
}

.meetingContentAddToGCalPopover-notifications
.selectUserPopover-item-username{
	width: 200px;
	height: 40px;
	white-space: unset;
	text-overflow: unset;
	overflow: unset;

	text-align: left;
}

.meetingContentAddToGCalPopover-notifications
.selectUserPopover-item-checkboxContainer{
	position: relative;
	top: -5px;
}

.meetingContentAddToGCalPopover-addDocToMeeting{
	margin-top: 4px;
}


.meetingContentAddToGCalPopover-submitBtn{
	margin-top: 8px;

	width: calc(100% - 8px);
	margin-left: 4px;

	height: 36px;
	border-radius: 4px;

	font-size: 15px;
	font-weight: $lato-semibold;

	background: var(--text400);
	border: 1px solid var(--text500);
	
	color: $white;
}

.meetingContentAddToGCalPopover-submitBtn:hover{
	background: var(--text500);
	border: 1px solid var(--text600);
}