
.editProjectModal-footerRow-footerBtn{
	height: 29px;
	// min-width: 85px;
	border: 1px solid var(--line300);
	border-bottom: 1px solid var(--line325);

	flex-grow: 0;
	flex-shrink: 0;

	justify-content: flex-start;

	margin-right: 6px;
	border-radius: 4px;

	padding-left: 6px;
	padding-right: 8px;
}

.editProjectModal-footerRow-footerBtn--teams{
	min-width: 0px;
}

[data-theme="dark"]{
	.editProjectModal-footerRow-footerBtn{
		border: 1px solid var(--line150);
		border-bottom: 1px solid var(--line150);		
	}
}

.editProjectModal-footerRow-footerBtn[data-state="open"],
.editProjectModal-footerRow-footerBtnContainer[data-state="open"]{
	pointer-events: none;
}

.editProjectModal-footerRow-footerBtn:hover{
	border: 1px solid var(--line400);
}


//

.editProjectModal-footerRow-footerBtn--leadUser-avatarContainer,
.editProjectModal-footerRow-footerBtn--members-avatarContainer{
	width: 19px;
	height: 19px;
}

.editProjectModal-footerRow-footerBtn--leadUser-avatar,
.editProjectModal-footerRow-footerBtn--members-avatar{
	width: 19px;
	height: 19px;
	border-radius: 3px;
}

.editProjectModal-footerRow-footerBtn--members-avatar{
	outline: 2px solid var(--bg-dropdown);
}

.editProjectModal-footerRow-footerBtn--members-avatarRow{
	display: flex;
}

.editProjectModal-footerRow-footerBtn--members-avatarContainer:not(:last-child){
	margin-right: -7px;
}

//

.editProjectModal-footerRow-footerBtn--teams-team
.editProjectModal-footerRow-footerBtn--teams-team-markerContainer{
	width: 14px;
	height: 14px;
	position: relative;
	// top: 1px;
}

.editProjectModal-footerRow-footerBtn--teams-team--single
.editProjectModal-footerRow-footerBtn--teams-team-markerContainer{
	margin-left: -4px;
}

.editProjectModal-footerRow-footerBtn--teams-team--multi:first-child
.editProjectModal-footerRow-footerBtn--teams-team-markerContainer{
	margin-left: -4px;
}

.editProjectModal-footerRow-footerBtn--teams-team-markerContainer
.linearMarker svg{
	width: 14px;
	height: 14px;	
}

.editProjectModal-footerRow-footerBtn--teams-team-markerContainer
.linearMarker{
	font-size: 14px;
}

// comma
.editProjectModal-footerRow-footerBtn--teams-team--multi:last-child
.editProjectModal-footerRow-footerBtn--teams-team span{
	display: none;
}

// EMPTY STATE

.editProjectModal-footerRow-footerBtn--leadUser .editProjectModal-footerRow-footerBtn--leadUser-iconContainer,
.editProjectModal-footerRow-footerBtn--members .editProjectModal-footerRow-footerBtn--members-iconContainer{
	width: 18px;
	height: 18px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editProjectModal-footerRow-footerBtn--members .editProjectModal-footerRow-footerBtn--members-iconContainer{
	margin-right: -2px;
}

.editProjectModal-footerRow-footerBtn--leadUser .editProjectModal-footerRow-footerBtn--leadUser-iconContainer svg,
.editProjectModal-footerRow-footerBtn--members .editProjectModal-footerRow-footerBtn--members-iconContainer svg{
	width: 16px;
	height: 16px;

	fill: var(--text600);
	opacity: 0.5;
}

.editProjectModal-footerRow-footerBtn--leadUser .editProjectModal-footerRow-footerBtn--leadUser-iconContainer svg{
	width: 18px;
	height: 18px;
}

// PROJECT STATUS



//

.editProjectModal-footerRow-footerBtn--leadUser-name,
.editProjectModal-footerRow-footerBtn--members-label,
.editProjectModal-footerRow-footerBtn--teams-team,
.editProjectModal-footerRow-footerBtn--projectStatus-label{

	margin-left: 5px;
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text400);

	white-space: nowrap;
}

.editProjectModal-footerRow-footerBtn--projectStatus-label{
	text-transform: capitalize;
}

.editProjectModal-footerRow-footerBtn--teams-team{
	font-size: 14px;
}

[data-theme="dark"]{
	.editProjectModal-footerRow-footerBtn--leadUser-name,
	.editProjectModal-footerRow-footerBtn--members-label,
	.editProjectModal-footerRow-footerBtn--teams-team{
		opacity: 0.9;
	}
}



.editProjectModal-footerRow-footerBtn--teams-team{
	display: flex;
}
