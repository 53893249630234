.projectsPage-filterOuterContainer{
	width: 100%;
	display: flex;

	position: sticky;
	top: 0px;
	z-index: 20;

	padding-top: 12px;
}

.projectsPage-filterContainer{
	margin-left: auto;
	display: flex;
	background: var(--bg-docEditor);
}

.projectsPage-largeHeader-filterBtn{
	padding-left: 8px;
	padding-right: 8px;
	//border: 1px solid var(--line300);

	height: 30px;

	margin-left: -1px;
	position: relative;

	font-size: 15.25px;

	border-radius: 5px;
}

.projectsPage-largeHeader-filterBtn--your{
	//margin-right: 2px;
}

.projectsPage-largeHeader-filterBtn--your img{
	width: 22px;
	height: 22px;
	border-radius: 4px;
}

$borderRadius-projectHeaderFilter: 5px;

.projectsPage-largeHeader-filterBtn--team{
	padding-left: 6px;
	padding-right: 6px;
}

// .projectsPage-largeHeader-filterBtn:first-child{
// 	border-top-left-radius: $borderRadius-projectHeaderFilter;
// 	border-bottom-left-radius: $borderRadius-projectHeaderFilter;
// }

// .projectsPage-largeHeader-filterBtn:last-child{
// 	border-top-right-radius: $borderRadius-projectHeaderFilter;
// 	border-bottom-right-radius: $borderRadius-projectHeaderFilter;
// }

.projectsPage-largeHeader-filterBtn--inactive{	
	// background: var(--253gray);
	border-color: var(--line400);	
	//border: 1px solid $invisible;

	z-index: 1;
}

.projectsPage-largeHeader-filterBtn--inactive:hover{
	background: var(--253gray);
}

.projectsPage-largeHeader-filterBtn--active{	
	//background: var(--bg-docEditor);
	background: var(--250gray);
	// border-color: var(--line200);
	//border: 1px solid var(--line200);

	z-index: 5;
}


.projectsPage-largeHeader-filterBtn--inactive
.projectsPage-largeHeader-filterBtn-label{
	font-weight: $lato-medium;	
	color: var(--text200);
}

.projectsPage-largeHeader-filterBtn--inactive:hover
.projectsPage-largeHeader-filterBtn-label{
	font-weight: $lato-medium;	
	color: var(--text400);
}

.projectsPage-largeHeader-filterBtn--active
.projectsPage-largeHeader-filterBtn-label{
	font-weight: $lato-semibold;		
	color: var(--text600);
}