//
// TOP RIGHT MENU IN IMAGE
// Contains comment, delete, etc.
// Not displayed in zoom
// Also contains fake temporary drawing menu

$borderRadius-btn-imageTopRightMenu: 5px;


.doc-media-topRightMenu{
	position: absolute;
	top: $innerMarginForUI-docMedia;
	right: $innerMarginForUI-docMedia;

	display: flex;

	z-index: 10;

	height: 26px;

	font-size: 13.5px;
	font-weight: $lato-semibold;

	border-radius: $borderRadius-btn-imageTopRightMenu;

	// border: 1px solid var(--outsideLineFloatingMenu);
	// border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);	
	
	border: none;
	//box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
}

button.doc-media-topRightMenu-btn{
	height: 100%;

	color: rgba(255,255,255,0.95);

	position: relative;
	background: rgba(60,55,60,0.85);	

	user-select: none;
}



button.doc-media-topRightMenu-btn:hover{
	z-index: 5;	
	background: rgba(60,55,60,0.9);
}

[data-theme="dark"]{ button.doc-media-topRightMenu-btn{
		background: rgba(28,23,28,0.85);	
	}
}

[data-theme="dark"]{ button.doc-media-topRightMenu-btn:hover{
		background: rgba(28,23,28,0.90);	
	}
}


// LABEL AND ICON
button.doc-media-topRightMenu-btn--iconAndLabel{
	padding-left: 8px;
	padding-right: 10px;
}

button.doc-media-topRightMenu-btn--iconAndLabel
.doc-media-topRightMenu-btn-iconContainer{
	margin-right: 4px;
}

// ICON ONLY
button.doc-media-topRightMenu-btn--iconOnly{
	width: 28px;
}

// LABEL ONLY 
button.doc-media-topRightMenu-btn--labelOnly{
	padding-left: 8px;
	padding-right: 8px;

}

// BORDERS
$topRightMenuInnerBorderColor: rgba(255,255,255,0.15);

button.doc-media-topRightMenu-btn:first-child{
	border-top-left-radius: calc(#{$borderRadius-btn-imageTopRightMenu} - 1px);
	border-bottom-left-radius: calc(#{$borderRadius-btn-imageTopRightMenu} - 1px);
	border-right: 1px solid $topRightMenuInnerBorderColor;
	margin-left: 0px;
	border-left: none;
}


button.doc-media-topRightMenu-btn:last-child{
	border-top-right-radius: calc(#{$borderRadius-btn-imageTopRightMenu} - 1px);
	border-bottom-right-radius: calc(#{$borderRadius-btn-imageTopRightMenu} - 1px);
	border-left: 1px solid $topRightMenuInnerBorderColor;
	border-right: none;
	padding-right: 1px;
}

button.doc-media-topRightMenu-btn{
	border-right: 1px solid $topRightMenuInnerBorderColor;
	border-left: 1px solid $topRightMenuInnerBorderColor;

	margin-left: -1px;
	margin-right: 0px;
}

// ICONS

.doc-media-topRightMenu-btn-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg{
	width: 17px;
	height: 17px;
	//fill: rgba(255,255,255,0.95);
	fill: $white;
}

.doc-media-topRightMenu-btn-iconContainer svg.icon--name--commentLayered #outline,
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--stashLayered #outline{
	fill: var(--text600);
	fill: rgba(255,255,255,1);
}

.doc-media-topRightMenu-btn-iconContainer svg.icon--name--commentLayered #background,
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--stashLayered #background{
	fill: var(--text000);
	fill: rgba(255,255,255,0.2);
	fill: rgba(0,0,0,0.2);

}

button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--slackIcon{
	width: 13px;
	height: 13px;
}

button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--trash{
	width: 15px;
	height: 15px;
}


button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--stashOutline{
	width: 18px;
	height: 18px;
}


button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--messageOutlineMedium{
	width: 16px;
	height: 16px;
}

button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--unZoomMedia{
	width: 13px;
	height: 13px;
}

button.doc-media-topRightMenu-btn:hover
.doc-media-topRightMenu-btn-iconContainer svg{
	fill: rgba(255,255,255,1);
}

// FIGMA ICON

.doc-media-topRightMenu-btn--figmaIcon{
	width: 28px;
	border-left: none !important; // sorry, i think it's quicker
	border-right: none !important;
	padding-right: 0px !important;
}

.doc-media-topRightMenu--static
.doc-media-topRightMenu-btn--figmaIcon{

}

.doc-media-topRightMenu--static
.doc-media-topRightMenu-btn--figmaIcon svg{
	width: 16px;
	height: 16px;
}


//
// DRAWING MENU

.doc-drawing-topLeftMenu{
	position: absolute;
	top: $innerMarginForUI-docMedia;
	left: $innerMarginForUI-docMedia;

	display: flex;

	background: $white;

	z-index: 10;

	height: 28px;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: var(--text350);

	border-radius: $borderRadius-btn-imageTopRightMenu;

	border: 1px solid var(--outsideLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);
	//box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
}


.doc-drawing-topLeftMenu
button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer svg.icon--name--insertBarDrawing{
	width: 19px;
	height: 19px;
}

.doc-drawing-topLeftMenu
button.doc-media-topRightMenu-btn 
.doc-media-topRightMenu-btn-iconContainer{
	margin-right: 0px;
	margin-left: -3px;
}