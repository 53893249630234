.doc-video-captionsOuterContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 20;
	pointer-events: none;

	display: flex;
	flex-direction: column;
	
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	user-select: none;
}

.doc-video-captionsOuterContainer--inline{
	padding-bottom: 12px;
}

.doc-video-captionsOuterContainer--inline.doc-video-captionsOuterContainer--showInlineControlBar{
	bottom: $height-startedControls-inlineUI;
}

.doc-video-captionsOuterContainer--inline.doc-video-captionsOuterContainer--showInlineControlBar.doc-video-captionsOuterContainer--hasComments{
	bottom: calc(#{$height-startedControls-inlineUI} + 24px); // should centralize
}


.doc-video-captionsOuterContainer--zoomed{
	padding-bottom: 10px;
	bottom: 88px;
}

//

.doc-video-captionsInnerContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-video-captionsOuterContainer--inline
.doc-video-captionsInnerContainer{
	max-width: 450px; // max width
}

.doc-video-captionsOuterContainer--zoomed
.doc-video-captionsInnerContainer{
	max-width: 800px; // max width
}

//

.doc-video-captions{
	// display: flex;
	// flex-wrap: wrap;
	
	font-weight: $lato-semibold;

	// background: rgba(24,24,24,0.8);

	

	
	color: $white;

	text-align: center;


}

.doc-video-captionsOuterContainer--inline
.doc-video-captions{
	font-size: 20px;

	line-height: 1.15;

	border-radius: 6px;
	
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 5px;
	padding-bottom: 7px;
}


.doc-video-captionsOuterContainer--zoomed
.doc-video-captions{
	font-size: 28px;

	border-radius: 7px;

	line-height: 1.15;

	padding-left: 16px;
	padding-right: 16px;
	padding-top: 6px;
	padding-bottom: 8px;

}