// image Test

.doc-imageContainerTest{
	width: $imageWidth-doc;
	height: 400px;
	background: $tempr;

	position: relative;
	left: calc(#{$edgeMarginTextToDoc} * -1);

	margin-top: 24px;
	margin-bottom: 24px;
}

// .doc p::selection{
// 	background: $invisible; // think this is old
// }



// DOC

.doc{		
	width: 200vw;
	padding-left: 50vw;
	margin-left: -50vw;
	// this is a bit weird, what we're trying to do is translateX the parent container,
	// so we need to make this wider than the screen so it always remains full-width
	color: var(--text500);
	// flex-grow: 1;
	// flex-shrink: 1;

	cursor: text;

	position: relative;
	z-index: 0;
	z-index: 10; // firefox fix

	min-height: calc(100% - 72px);
	padding-bottom: 72px;
}

.staticHTML{
	pointer-events: none !important; 
}


.staticHTML{
	pointer-events: none !important; 
}




.doc.ProseMirror:focus{
	outline: none;
	cursor: text;
}

// selection



// .docEditor--draft #editor{
// 	display: flex;
// 	flex-direction: column;
// 	height: 100%;
// }

// .docEditorContainer--web .docEditor--draft .doc{	
// 	min-height: calc(100vh - 201px); // this isn't quite right, but tricky, need to find right way of doing this with multi-line titles
// }

// .docEditorContainer--electron .docEditor--draft .doc{	
// 	min-height: calc(100vh - 201px - #{$height-tabBar}); // this isn't quite right, but tricky, need to find right way of doing this with multi-line titles
// }


.docInnerWrapper{
	width: $textWidth-doc;
	// background: $tempr;
	position: relative;	
}

.docEditor--draft .docInnerWrapper{
	padding-bottom: 160px;
}

// similar effect so putting here
.docEditor--feedbackReview .docEditor--feedbackReview-docTitle{
	padding-top: 98px;
}

.docEditor-docInnerContainer{
	display: flex;
	flex-direction: column;
}

.docEditor-docInnerContainer #editor{
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempb;
	// display: flex;
	// flex-direction: column;
}

.docEditor-docInnerContainer--showTemplate #editor{
	position: absolute;
	pointer-events: none;
	opacity: 0;
}


	

// // actual doc
// .ProseMirror{
// 	height: 100%;
// 	width: 800px;
// 	padding-top: 84px;
// 	box-sizing: border-box;

// 	background: $tempr;

// 	padding-left: 200px;
// 	padding-right: 200px;

// 	cursor: text;
// }




.doc-title,
.openDocMenu-docPreview-doc-title,
.docEditor-docTitle-projectDocTitle,
.docEditor-docTitle-milestoneDocTitle,
.suggestionMenu-item--mention--docLink--preview-doc-title,
.libraryDocPreview-doc-title,
.insertMentionsMenu-item--doc-preview-doc-title,
.tabSwitchMenu-tab-doc-docPreview-doc-title,
.docEditorPreviewPopover-doc-title,
.docEditor-GCalMeetingTitle-title{
	width: 100%;
	text-align: left;
	font-size: 42px;
	font-weight: $lato-black;	
	letter-spacing: -0.005em;
	color: var(--text600);
	margin-bottom: 18px;
}

[data-theme="dark"] .doc-title,
[data-theme="dark"] .docEditor--feedbackReview-docTitle,
[data-theme="dark"] .openDocMenu-docPreview-doc-title,
[data-theme="dark"] .docEditor--feedbackReview-docTitle,
[data-theme="dark"] .docEditor-docTitle-projectDocTitle,
[data-theme="dark"] .docEditor-docTitle-milestoneDocTitle,
[data-theme="dark"] .libraryDocPreview-doc-title,
[data-theme="dark"] .docEditorPreviewPopover-doc,
[data-theme="dark"] textarea.docEditor-docTitle-input,
[data-theme="dark"] .docEditor-docTitle-ghost,
[data-theme="dark"] .insertMentionsMenu-item--doc-preview-doc-title,
[data-theme="dark"] .suggestionMenu-item--mention--docLink--preview-doc-title{
	font-weight: $lato-heavy; // seems more balanced
}

.docEditor--feedbackReview .doc-title{
	display: none; // outside of PM here as need discusion points above.
}

.docEditor--feedbackReview-title{	
	cursor: default;
}


//
// DOC INPUT

.doc-h1,
.docEditor--feedback-sectionTitle,
.doc-h1-placeholder{		
	//font-size: 26.75px;
	//font-weight: $lato-bold;
	//letter-spacing: -0.006em;

	// font-size: 24px;	
	font-size: 26.75px;	
	font-size: 27.75px;	
	font-weight: $lato-heavy;
	color: var(--text600);
	//letter-spacing: -0.01em;

	margin-bottom: 12px;
	//margin-top: 36px;

	// SAT AUG 6 UPDATE
	font-size: 28.75px;	
	padding-top: 32px;
	margin-bottom: 12px;
	line-height: 1.2;
}

.doc-h1:first-child{
	padding-top: 16px; // too much at start
}


.doc-h2{			
	font-size: 18px;	
	font-weight: $lato-bold;
	//letter-spacing: -0.01em;
	//color: var(--text400);
	color: var(--text500);

	margin-bottom: 8px;
	// margin-top: 20px;

	// SAT AUG 6 UPDATE
	font-size: 21px;
	font-weight: $lato-heavy;
	margin-bottom: 8px;
	//margin-top: 24px;
	padding-top: 24px;
}


// .doc-h2:first-child{
// <<<<<<< HEAD
// 	padding-top: 12px; // too much padding if first thing
// }


// =======
// 	padding-top: 14px; // too much at start
// }

// >>>>>>> main


.doc-h2:first-child{
	padding-top: 14px; // too much at start
}

.doc-h3{			
	font-size: 16px;	
	font-weight: $lato-bold;
	//letter-spacing: -0.01em;
	color: var(--text400);

	margin-bottom: 4px;
	margin-top: 12px;

	//color: green;	
}

.doc-h1 + .doc-h2{
	// reduce padding if directly follow each other
	padding-top: 6px;
}


//
// Header Placeholder

.doc-h1,
.doc-h2,
.doc-h3{
	position: relative;
}

.doc-h1.doc-header--empty::before,
.doc-h2.doc-header--empty::before,
.doc-h3.doc-header--empty::before{
	position: absolute;
	height: 0px;
	pointer-events: none;
	-webkit-text-fill-color: var(--docHeaderPlaceholderColor); // to do, refactor
}

.doc-h1.doc-header--empty::before{	
	content: "Header 1";
}

.doc-h2.doc-header--empty::before{
	content: "Header 2";	
}

.doc-h3.doc-header--empty::before{
	content: "Header 3";	
}



//
// STRIKE THROUGH

.doc-para s,
span.doc-span--style--strikethrough,	
.openDocMenu-docPreview-doc p s,
.insertMentionsMenu-item--doc-preview-doc p s,
.suggestionMenu-item--mention--docLink--preview-doc p s{		
	text-decoration: line-through;
}


//
// LIGHT TEXT STYLING
span.doc-span--style--light{
	text-decoration: none;
	color: var(--text200); // if change here, will have to change other places
	// this also solves code
	//transition: color 0ms;
}

.doc-h1 span.doc-span--style--light,
.doc-h2 span.doc-span--style--light,
.doc-h3 span.doc-span--style--light{		
	color: var(--text100);
	//transition: color 0ms;
}

// light strikethrough
.doc-para s span.doc-span--style--light{
	text-decoration: line-through;
	text-decoration-color: var(--text200);
}

// span.doc-span--style--light:hover{		
// 	text-decoration: none;
// 	color: var(--text500);
// 	transition-delay: 250ms;
// }

//
// CODE EXAMPLE

span.doc-span--style--code{		
	text-decoration: none;

	box-sizing: border-box;

	padding-left: 3px;
	padding-right: 3px;
	padding-top: 2px;
	padding-bottom: 1px;
	

	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
	font-weight: 400;	
	color: var(--docCodeTextColor);

	border-radius: 3px;

	//font-size: 14px;
	font-size: 13.5px;

	border: var(--docCodeBorder);
	background-color: var(--docCodeBackground);
}

strong span.doc-span--style--code{
	font-weight: 600;
}

.doc-h1 span.doc-span--style--code{
	font-size: 22px;
	font-weight: 600;
}

.doc-h2 span.doc-span--style--code{
	font-size: 15px;
	font-weight: 600;
}	


//
// EMOJI

.doc-para-emoji{
	
	//height: auto;
	height: 0.8em;
	width: 1.02em;
	// margin-left: 0.03em;
	// margin-right: 0.03em;

	display: inline-block;
	overflow: visible;

	object-fit: contain;
	
	vertical-align: middle;
	//vertical-align: -0.09em;

	position: relative;
	top: -0.4em;

	// background: $tempr;

	font-size: 1.15em;

}

.doc-para-emoji::after{
	content: "\200B";
}


// //

.doc-h1 .doc-para-emoji{
	top: -0.3em;
	font-size: 0.9em;
	width: 1.08em;
	// height: calc(0.8em * 0.8);
	
}
.doc-h2 .doc-para-emoji{
	top: -0.3em;
	font-size: 0.95em;
	width: 1.08em;
}

// .doc-h2 .doc-para-emoji-inner{	
// 	top: -0.05em;
// 	// left: 0.09em;
// 	//left: 0.08em;
// 	bottom: 0px;
// 	right: 0px;

// 	font-size: 1.25em;

// 	// background: $tempr;
// }

//









//
// INTERNAL LINK STYLING PROTOTYPE
// .doc p s{		
// 	text-decoration: none;

// 	box-sizing: border-box;

// 	padding-left: 5px;
// 	padding-right: 5px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

	
// 	border-radius: 4px;

// 	//background: var(--primary75);
// 	background: rgb(251,248,253);
// 	background: darken(rgb(251,248,253), 2%);
// 	// background: $tempr;

// 	font-weight: $lato-semibold;
// 	// font-weight: $lato-bold;

// 	// color: rgb(45,40,50);
// 	//background: rgb(248,244,252);

// 	// text-decoration: underline;
// 	// border-bottom: 1px solid rgb(120,120,120);

// 	// border-bottom: 1.5px solid var(--primary500);
// 	//background: hsl(212, 100%, 98%);
// 	border-bottom: 1.5px solid $invisible;

// 	//color: var(--primary700);

// 	// opacity: 0.95;

// 	cursor: pointer;


// }

// .doc p s:hover{
// 	// border-bottom-right-radius: 0px;
// 	// border-bottom-left-radius: 0px;
// 	//border-bottom: 1.5px solid var(--primary500);	
// 	border-bottom: 1.5px solid $invisible;	

// 	//background: rgb(245,243,248);
// 	color: rgb(0,0,0);
// 	background: darken(rgb(251,248,253), 4%);
// }



//
//
//



.docEditor--feedback-sectionTitle{
	cursor: default;
	user-select: none;
}

.docEditor--feedback-sectionDivider,
.doc-faq-header-divider{
	margin-top: 8px;

	width: 55px;
	height: 6px;
	border-radius: 3px;
	background: rgb(240,238,238);
}


//
// REGULAR TEXT
.doc p,
.doc-para,
.doc-contentBox,
.openDocMenu-docPreview-doc p,
.suggestionMenu-item--mention--docLink--preview-doc p,
.insertMentionsMenu-item--doc-preview-doc p,
.docEditor-meetingTemplate-button-label{
	//font-size: 16.75px;	
	font-size: 16.5px;	
	// font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.45;

	position: relative;
	margin-block-start: 0em;
	margin-block-end: 0em;

	//min-height: 25px; (I'm not sure need this)
	//margin-bottom: 15px; not sure about this

	
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;

	box-sizing: border-box;
}


.openDocMenu-docPreview-doc .doc-para,
.openDocMenu-docPreview-doc p{
	min-height: 24.25px; // temp fix, really want to put a BR with prosemirror-trailing break classname in there to match reality
}

.openDocMenu-docPreview-doc .openDocMenu-docPreview-doc-titleContainer{
	//padding-bottom: 18px;
	min-height: 66.3px; // temp fix
}


//
// INDENTATION
// list item and todo item also have their own indentation rules


$doc-paragraph-indent: 29px;

.doc-para[data-indent-level="0"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="1"]{
	padding-left: calc(1 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="2"]{
	padding-left: calc(2 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="3"]{
	padding-left: calc(3 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="4"]{
	padding-left: calc(4 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="5"]{
	padding-left: calc(5 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="6"]{
	padding-left: calc(6 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="7"]{
	padding-left: calc(7 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="8"]{
	padding-left: calc(8 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="9"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-para[data-indent-level="10"]{
	padding-left: calc(10 * #{$doc-paragraph-indent});
}















//
//
// refactor, i think only in html
.doc-listItem-contents{
	flex-grow: 1;
	flex-shrink: 1;
	color: var(--text500);
}


// .doc-listItem-contents .doc-para{
// 	line-height: 1.4;
// }



// // numbered list

// .doc ol{
//    margin-block-start: 14px;
//    margin-block-end: 14px;

//    margin-inline-start: 0px;
//    margin-inline-end: 0px;

// 	padding-inline-start: 30px;
// }

// .doc ol li{
// 	margin-bottom: 5px;
// }

// .doc ol li::marker{
// 	font-weight: $lato-bold;
// }

// // bullet list
// .doc ul{
//    margin-block-start: 0px;
//    margin-block-end: 0px;

//    margin-inline-start: -35px;
//    margin-inline-end: 0px;

// 	padding-inline-start: 30px;
// }

// .doc ul li{
// 	padding-left: 5px !important;
// 	margin-block-start: 5px;
// }
// .doc ul li::marker{	
// 	color: var(--text400);
// }




//
// CONTENT BOX

.doc-contentBox{
	padding-left: 12px;
	padding-right: 12px;

	padding-left: 14px;
	padding-right: 14px;

	padding-top: 12px;
	padding-bottom: 12px;

	margin-top: 10px;
	margin-bottom: 10px;

	border-radius: 4px;
	border-radius: 6px;
	border-radius: 7px;

	background: var(--docContentBoxBackground);
}

// .doc-contentBox{
// 	background: var(--primary50);
// }

// .doc-contentBox{
// 	background: var(--accentBlue50);
// }

// .doc-contentBox{
// 	background: var(--accentGreen50);
// }

// .doc-contentBox{
// 	background: var(--accentPurple50);
// }

// .doc-contentBox{
// 	background: var(--accentPurple50);
// }

// .doc-contentBox{
// 	background: var(--accentPurple50);
// }

// .doc-contentBox{
// 	background: var(--accentPurple50);
// }


// .doc-contentBox .doc-para{
// 	color: var(--primary700) !important;	
// }



//
// CODE BLOCK

.doc-codeBlock{
	padding-left: 12px;
	padding-right: 12px;

	padding-top: 9px;	
	padding-bottom: 9px;

	margin-top: 6px;
	margin-bottom: 6px;

	border-radius: 4px;

	border-radius: 3px;

	border: var(--docCodeBorder);
	background-color: var(--docCodeBackground);
}

.doc-codeBlock p,
.doc-codeBlock .doc-para{
	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
	font-weight: 400;	
	
	font-size: 13.5px;
	min-height: unset;

	text-decoration: none;

	line-height: 1.6;
}


//
// HORIZONTAL DOC DIVIDER
.doc-divider{
	width: 100%;
	height: 1px;
	background: var(--lineDocDivider);
	opacity: 0.8;

	margin-top: 24px;
	margin-bottom: 24px;
}

.doc-divider + .doc-h1{
	padding-top: 12px;	
}

.doc-divider + .doc-h2{
	padding-top: 12px;	
}

// .doc-divider + .doc-h3{
// 	padding-top: 12px;	
// }


// for under-header dividers
.doc-h1 + .doc-divider{
	margin-top: -4px;	
}


.doc-h2 + .doc-divider{
	margin-top: -1px;	
}

// .doc-h3 + .doc-divider{
// 	margin-top: -4px;	
// }



////
////
//// NOT SURE WHAT THIS IS


.doc-section-contents-p-barContainer{
	position: absolute;
	top: 1px;
	left: -34px;
	bottom: -2px;
	width: 34px;
	
	// background: red;
}

.doc-section-contents-p-bar{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 12px;

	border-radius: 2px;
}

.doc-section-contents-p-barContainer:hover
.doc-section-contents-p-bar{
	//background: transparentize($blue, 0.8);	
	background: var(--primary100);
}

.doc-section-contents-p-barContainer:hover
.doc-section-contents-p-bar:hover{
	//background: transparentize($blue, 0.2);
	background: var(--primary400);
}

// .doc-textHighlight{
// 	//background: transparentize($blue, 0.85);
// 	background: var(--primary75);
// 	padding-left: 0.15em;
// 	margin-left: -0.15em;
// 	padding-right: 0.15em;
// 	margin-right: -0.15em;
// 	padding-top: 0.05em;
// 	padding-bottom: 0.1em;
// 	border-radius: 3px;
// }

//
// Mention Placeholder

////

@import 'Doc-Title';

@import 'Doc-ThreadGutter';

@import 'Doc-Tables';

@import 'Doc-ListItem';
@import 'Doc-ToggleListItem';
@import 'Doc-ToDoItem';

@import 'Doc-Image';
@import 'Doc-ImageThumbnail';
@import 'Doc-Drawing'; // maybe refactor into above

@import 'Doc-MentionInlineSearchBar';

//@import 'Doc-BlockLinearIssue';
@import 'Doc-InlineLinearIssue';
@import 'Doc-ProjectLink';
@import 'Doc-InternalLink';
@import 'Doc-Date';
@import 'Doc-TextLink';
@import 'Doc-ParticipantHeader';

@import 'figma/Doc-Figma';

@import 'Doc-Media';
@import 'Doc-Video';
// @import 'Doc-Gallery';
@import 'Doc-Comments'; 
@import 'Doc-FAQ'; 
@import 'Doc-ReactionHoverParagraph'; 
@import 'Doc-InlineReactionsBar'; 
@import 'Doc-RealTimeCollaboration'; 

@import 'Doc-BodyPlaceholder';

@import 'Doc-CodeMirror';

@import 'Doc-WhiteboardPreview';
