$heightTag-meetingTopInfo: 28px;
$borderRadiusTag-meetingTopInfo: 4px;
$sideMargin-meetingTopInfo: 6px;


// MEETING DOC INFO MENU
.docEditor-meetingTopInfoContainer{
	width: 100%;
	height: $height-docEditor-topMenu;
	// background: $tempr;

	// // solution where doc header position doesn't move
	// margin-top: 17px;
	// margin-bottom: calc((#{$height-docEditor-topMenu} * -1) - 17px);

	// solution where doc header position doesn't move
	margin-top: 28px;
	margin-bottom: -48px;

	position: relative;
	z-index: 50;

	//
	// margin-top: 30px;
	// margin-bottom: calc((#{$height-docEditor-topMenu} * -1) - 30px);
}


.docEditor-meetingTopInfo,
.docEditor-meetingTopNewMeetingInfo{
	width: 100%;
	height: $height-docEditor-topMenu;
	
	// background: $tempb;

	display: flex;
	align-items: center;

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text500);
	letter-spacing: 0.008em;

	user-select: none;
	cursor: default;

	position: relative;
}

.docEditor-meetingTopNewMeetingInfo{
	position: absolute;
	top: 0px;
	left: 0px;
}

.meetingTopNewMeetingInfo-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.meetingTopNewMeetingInfo-iconContainer svg{
	width: 17px;
	height: 17px;
	fill: var(--text500);
	opacity: 0.75;
}

//
// info tag

.docEditor-meetingTopInfo-typeTag{
	display: flex;
	align-items: center;
	height: $heightTag-meetingTopInfo;
	background: var(--253gray);
	border-radius: $borderRadiusTag-meetingTopInfo;
	margin-right: $sideMargin-meetingTopInfo;

	padding-right: 9px;
	padding-left: 4px;

	pointer-events: none;
}

.docEditor-meetingTopInfo-typeTag:hover,
.docEditor-meetingTopInfo-typeTag[data-state="open"]{
	background: var(--250gray);
}

.docEditor-meetingTopInfo-typeTag[data-state="open"]{
	pointer-events: none;
}

.docEditor-meetingTopInfo-typeTag-iconContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1px;
}

.docEditor-meetingTopInfo-typeTag-iconContainer svg{
	width: 18px;
	height: 18px;

	transform: scale(0.9);
	transform-origin: 50% 50%;
}

.docEditor-meetingTopInfo-typeTag--type--blank{
	padding-left: 4px;
	padding-right: 4px;
	background: none;
	margin-right: 0px;
}

.docEditor-meetingTopInfo-typeTag--type--blank 
.docEditor-meetingTopInfo-typeTag-iconContainer svg{
	opacity: 0.5;
}

// [data-theme="light"]{
// 	.docEditor-meetingTopInfo .docEditor-meetingTopInfo-typeTag-iconContainer svg #core{
// 		fill: white;
// 	}

// 	.docEditor-meetingTopInfo--type--standup .docEditor-meetingTopInfo-typeTag-iconContainer svg #bg{
// 		fill: var(--accentOrange600);
// 	}

// 	.docEditor-meetingTopInfo--type--teamSync .docEditor-meetingTopInfo-typeTag-iconContainer svg #bg{
// 		fill: var(--primary600);
// 	}

// 	.docEditor-meetingTopInfo--type--adhoc .docEditor-meetingTopInfo-typeTag-iconContainer svg #bg{
// 		fill: var(--accentGreen600);
// 	}

// 	.docEditor-meetingTopInfo--type--discovery .docEditor-meetingTopInfo-typeTag-iconContainer svg #bg{
// 		fill: var(--accentPurple600);
// 	}

// 	.docEditor-meetingTopInfo--type--sprintPlanning .docEditor-meetingTopInfo-typeTag-iconContainer svg #bg{
// 		fill: var(--accentSky600);
// 	}	
// }

.docEditor-meetingTopInfo-typeTag-label{
	font-weight: $lato-medium;
	color: var(--text600);
}

.docEditor-meetingTopInfo-typeTag-label span{
	opacity: 0.5;
	margin-left: 4px;
	font-weight: $lato-regular;
}

//
// Cycle

.docEditor-meetingTopInfo--standup-cycleTag{
	display: flex;
	margin-right: $sideMargin-meetingTopInfo;

	display: flex;
	align-items: center;

	height: calc(#{$heightTag-meetingTopInfo} - 2px);
	border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;

	padding-left: 4px;
	padding-right: 10px;

	opacity: 0.65;

}

.docEditor-meetingTopInfo--standup-cycleTag-iconContainer{
	width: 20px;
	height: 20px;
	margin-right: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingTopInfo--standup-cycleTag svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.5;
}


//

@import 'DocEditor-MeetingInfo-DateTime';

//
//

.docEditor-meetingTopInfo-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 10px;
}

//
//



.addToCalendarPopoverContainer{	
	
}

.addToCalendarPopoverContainer:focus{
	outline: none;
}

.addToCalendarPopoverContainer--hidden,
.addToCalendarPopoverContainer--hidden *{
	pointer-events: none !important;
}

.addToCalendarPopover{
	width: unset;	
}

.addToCalendarPopover{
	width: 230px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 10px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;
}

.popoverMenu-item--addToCalendar{
	align-items: center;
	height: calc(#{$heightTag-meetingTopInfo} + 2px);
	//border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;
	padding-left: 8px;
	padding-right: 0px;
	color: var(--text500);

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;



	// background: $tempb;
}

.popoverMenu-item--addToCalendar--low{
	font-weight: $lato-regular;
	opacity: 0.65;
}
.popoverMenu-item--addToCalendar--low:hover{
	opacity: 1;
}

.popoverMenu-item--addToCalendar-iconContainer{
	width: 24px;
	height: 24px;
}

.popoverMenu-item--addToCalendar-noIconSpacer{
	width: 5px;
}






.docEditor-meetingTopInfo-addToCalendarBtn{	
	height: calc(#{$heightTag-meetingTopInfo});
	//border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;
	padding-left: 4px;
	padding-right: 10px;
	color: var(--text500);

	cursor: pointer;

	margin-right: 6px;

	// opacity: 0.6;
	transition: 50ms linear;

	//background: $tempr;
	background: var(--253gray);
}

.docEditor-meetingTopInfo-addToCalendarBtn-iconContainer,
.popoverMenu-item--addToCalendar-iconContainer{
	width: 20px;
	height: 20px;
	//margin-right: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingTopInfo-addToCalendarBtn-iconContainer{
	margin-right: 4px;
	margin-left: 2px;
}

.popoverMenu-item--addToCalendar-iconContainer{
	// background: $tempr;
	margin-left: auto;
	height: 100%;
	width: 32px;
}


.popoverMenu-item--addToCalendar-iconContainer svg,
.docEditor-meetingTopInfo-addToCalendarBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	opacity: 0.75;
}

.docEditor-meetingTopInfo-addToCalendarBtn-iconContainer svg{
	opacity: 1;
}

.popoverMenu-item--addToCalendar-iconContainer svg{
	height: 18px;
	width: 18px;
	opacity: 0.5;
}

.docEditor-meetingTopInfo-addToCalendarBtn:hover{
	opacity: 1;
	background: var(--250gray);
}

.docEditor-meetingTopInfo-addToCalendarBtn:hover svg{
	opacity: 1;
}




.docEditor-meetingTopInfo-openGCalEventBtn{
	display: flex;
	align-items: center;

	height: calc(#{$heightTag-meetingTopInfo});
	//border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;

	padding-left: 5px;
	padding-right: 9px;

	cursor: pointer;

	margin-right: 8px;

	color: var(--text500);
}


.docEditor-meetingTopInfo-openGCalEventBtn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.docEditor-meetingTopInfo-openGCalEventBtn svg{
	width: 17px;
	height: 17px;

	fill: var(--text500);
	opacity: 0.8;
}

.docEditor-meetingTopInfo
.docEditor-meetingTopInfo-openGCalEventBtn{
	opacity: 0;
}

.docEditor-meetingTopInfo:hover
.docEditor-meetingTopInfo-openGCalEventBtn{	
	opacity: 0.8;
	transition: all 100ms;
	transition-delay: 220ms;
}

.docEditor-meetingTopInfo:hover
.docEditor-meetingTopInfo-openGCalEventBtn:hover{
	opacity: 1;
	background: var(--252gray);
}





//
//

.docEditor-meetingTopInfo-addRecordingBtn{
	display: flex;
	align-items: center;

	height: calc(#{$heightTag-meetingTopInfo});
	//border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;

	padding-left: 6px;
	padding-right: 8px;

	cursor: pointer;

	margin-right: 8px;

	opacity: 0.9;
}

.docEditor-meetingTopInfo-addRecordingBtn:hover{
	opacity: 1;
	background: var(--252gray);
}

.docEditor-meetingTopInfo-addRecordingBtn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
}

.docEditor-meetingTopInfo-addRecordingBtn svg{
	width: 16px;
	height: 16px;

	fill: var(--text500);
	opacity: 0.8;
}

.docEditor-meetingTopInfo--type--discovery
.docEditor-meetingTopInfo-addRecordingBtn svg #bg{
	fill: var(--accentPurple50);
	fill: var(--text600);
	opacity: 0.15;
}

.docEditor-meetingTopInfo--type--discovery
.docEditor-meetingTopInfo-addRecordingBtn svg #arrow{
	fill: var(--accentPurple600);
	fill: var(--text600);
	// fill: white;
}

.docEditor-meetingTopInfo-addRecordingBtn label{
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: $lato-medium;
}

.docEditor-meetingTopInfo-addRecordingBtn{
	opacity: 0.6;
}

.docEditor-meetingTopInfo-addRecordingBtn:hover{
	opacity: 1;
}

.docEditor-meetingTopInfo-addRecordingBtn
.docEditor-meetingTopInfo-addRecordingBtn-hiddenFileInput{
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
	






//
//

.docEditor-meetingTopInfo-watchRecordingBtn{
	display: flex;
	align-items: center;

	height: calc(#{$heightTag-meetingTopInfo});
	//border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;

	padding-left: 6px;
	padding-right: 8px;

	cursor: pointer;

	margin-right: 8px;

	opacity: 0.9;
}

.docEditor-meetingTopInfo-watchRecordingBtn:hover{
	opacity: 1;
	background: var(--252gray);
}

.docEditor-meetingTopInfo-watchRecordingBtn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.docEditor-meetingTopInfo-watchRecordingBtn svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.8;
}


//
//

.docEditor-meetingTopInfo-recordBtn{
	display: flex;
	align-items: center;

	height: calc(#{$heightTag-meetingTopInfo} - 2px);
	border: 1px solid var(--line300);
	border-radius: $borderRadiusTag-meetingTopInfo;

	padding-left: 5px;
	padding-right: 8px;

	cursor: pointer;

	margin-right: 8px;

	opacity: 0.75;
}

.docEditor-meetingTopInfo-recordBtn:hover{
	opacity: 1;
}

.docEditor-meetingTopInfo-recordBtn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.docEditor-meetingTopInfo-recordBtn svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.5;
}


//
// TYPE TAG POPOVER



.addToCalendarPopoverContainer{	
	
}

.addToCalendarPopoverContainer:focus{
	outline: none;
}

.addToCalendarPopoverContainer--hidden,
.addToCalendarPopoverContainer--hidden *{
	pointer-events: none !important;
}

.addToCalendarPopover{
	width: unset;	
}

.addToCalendarPopover{
	width: 200px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 10px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;

	transform-origin: 50% 20%;
}

.addToCalendarPopover-header{
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	color: var(--text500);

	padding-left: 15px;
	padding-bottom: 6px;
	padding-top: 2px;

	user-select: none;
	cursor: default;
}



.popoverMenu-item--editMeetingType{
	align-items: center;
}

.popoverMenu-item--editMeetingType--blank[data-state="unchecked"]{
	opacity: 0.65;
}

.popoverMenu-item--editMeetingType--blank[data-state="unchecked"]:hover{
	opacity: 0.95;
}

.popoverMenu-item--editMeetingType[data-state="checked"]{
	background: var(--activeBG-suggestionItem); ;
}

.popoverMenu-item--editMeetingType[data-state="unchecked"]:hover{
	background: var(--253gray);
}


.popoverMenu-item--editMeetingType
.popoverMenu-item-iconContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 3px;
	margin-left: -2px;

}

.popoverMenu-item--editMeetingType
.popoverMenu-item-iconContainer svg{
	width: 18px;
	height: 18px;

	margin-top: 1px;

	transform: scale(0.9);
	transform-origin: 50% 50%;
}



//
//


