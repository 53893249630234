// DEVICE FRAME
.figmaView-deviceFrameContainer{
	position: absolute;
	z-index: 20;
	will-change: transform;
	//outline: 2px solid green;
}

.figmaView-deviceFrame{
	width: 100%;
	height: 100%;

	position: relative;

	z-index: 5;
}

.figmaView-deviceFrame-img{
	width: 100%;
	height: 100%;
}


//
// ARC

.figmaView-deviceFrame--arc{
	width: 100%;
	height: 100%;
}

.figmaView-deviceFrame--arc-outerContainer{
	width: 100%;
	height: 100%;
	position: relative;

	box-sizing: border-box;

	// background: red;	
	border: 0.5px solid rgba(255,255,255,0.3);
	border-left-color: rgba(255,255,255,0.15);
	border-right-color: rgba(255,255,255,0.15);

	background: rgba(0,0,0,0);
	backdrop-filter: saturate(120%) brightness(115%) blur(20px);		

	border: 1px solid rgba(0,0,0,0.08);

	z-index: 5;
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--offWhite{
	backdrop-filter: none;
	background: rgba(0,0,0,0.2);
}

// light blue
.figmaView-deviceFrame--arc-outerContainer--backgroundImage--lightBlue{
	backdrop-filter: none;
	//background: lighten(#70affb, 5%);

	background: radial-gradient(
    100% 100% at 50% 30%,
    lighten(#70affb, 5%), lighten(#70affb, 0%)
  );

	border: 1px solid darken(#70affb, 1%);
	border-bottom: 1px solid darken(#70affb, 2%);
	border-top: 1px solid darken(#70affb, 0%);
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--lightOrange{
	backdrop-filter: none;
	background: #f9e1e1;
	
	background: radial-gradient(
    100% 100% at 50% 30%,
    #f9e1e1, darken(#f9e1e1,4%)
  );

	border: 1px solid darken(#f9e1e1, 1%);
	border-bottom: 1px solid darken(#f9e1e1, 2%);
	border-top: 1px solid darken(#f9e1e1, 0%);
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--lightPurple{
	backdrop-filter: none;
	background: #FBE4FB;

	background: radial-gradient(
    100% 100% at 50% 30%,
    #FBE4FB, darken(#FBE4FB,4%)
  );

	border: 1px solid darken(#FBE4FB, 1%);
	border-bottom: 1px solid darken(#FBE4FB, 2%);
	border-top: 1px solid darken(#FBE4FB, 0%);
}


.figmaView-deviceFrame--arc-outerContainer--backgroundImage--lightGreen{
	backdrop-filter: none;
	background: #E6FDFD;

	background: radial-gradient(
    100% 100% at 50% 30%,
    #E6FDFD, darken(#E6FDFD,7%)
  );

	border: 1px solid darken(#E6FDFD, 1%);
	border-bottom: 1px solid darken(#E6FDFD, 2%);
	border-top: 1px solid darken(#E6FDFD, 0%);
}



.figmaView-deviceFrame--arc-outerContainer--backgroundImage--richBlueSpotlight{
	backdrop-filter: none;
	background: #E6FDFD;
  background: radial-gradient(
    100% 100% at 50% 30%,
    lighten(#435DD6, 2%), lighten(#5970DB, 7%)
  );
	border: 1px solid darken(#435DD6, 1%);
	border-bottom: 1px solid darken(#435DD6, 2%);
	border-top: 1px solid darken(#435DD6, 0%);
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--meshGreenBlue{
	backdrop-filter: none;
	background: #E6FDFD;
  background: radial-gradient(
    100% 100% at 0% 100%,
    #C1E9D3, #91C1F5
  );
	border-left: 1px solid darken(#91C1F5, 1%);
	border-bottom: 1px solid darken(#C1E9D3, 2%);
	border-top: 1px solid darken(#91C1F5, 0%);
	border-right: 1px solid darken(#91C1F5, 0%);
}




.figmaView-deviceFrame--arc-outerContainer--backgroundImage--offWhite{
	backdrop-filter: none;
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--offWhite{
	backdrop-filter: none;
}

.figmaView-deviceFrame--arc-outerContainer--backgroundImage--offWhite{
	backdrop-filter: none;
}


//

.figmaView-deviceFrame--arc-innerContainer{
	position: absolute;

	box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.28);
	// outline: 2px solid rgba(0,0,0,0.8);
}

.figmaView-deviceFrameContainer{
	//opacity: 0.2; // for dev
}

.figmaView-deviceFrame-shadow--desktop-arc{
	position: absolute;

	top: 0px;
	left: 0px;

	width: 100%;
	height: 100%;

	background: none;
	
	
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12), 
		0px 4px 8px 0px rgba(0,0,0,0.08), 
		0px 8px 16px 0px rgba(0,0,0,0.06),
		0px 16px 32px 0px rgba(0,0,0,0.04),
	;	

	z-index: 1;

	// display: none;
}



//
// SHADOWS

.figmaView-deviceFrame-shadow{
	position: absolute;
	z-index: 10;
}


//
// ARC






// TO DO, WORK THIS OUT
.doc-figma-image--framed-imageInnerContainer--device--iPhone13ProMax,
.doc-figma-image--framed-imageInnerContainer--device--iPhone14ProMax,
.doc-figma-image--framed-imageInnerContainer--device--iPhone14Pro,
.doc-figma-image--framed-imageInnerContainer--device--iPhone14,
.doc-figma-image--framed-imageInnerContainer--device--iPhone13Mini{
	overflow: hidden;
	border-radius: 22px;
}





//
// NON FRAME IMAGE SHADOW

// PHONES
.doc-figma-image--default-imageContainer--device--iPhone13ProMax.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPhone14ProMax.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPhone14Pro.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPhone14.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPhone13Mini.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--AndroidLarge.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--AndroidSmall.doc-figma-image--default-imageContainer--showImageShadow{
	overflow: hidden;
	border-radius: 10px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.14), 
		0px 4px 8px 0px rgba(0,0,0,0.10),
		0px 8px 12px 0px rgba(0,0,0,0.06),
	;

}

.doc-figma-image--default-imageContainer--device--iPhone13ProMax .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPhone14ProMax .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPhone14Pro .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPhone14 .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPhone13Mini .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--AndroidLarge .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--AndroidSmall .doc-figma-image--default-image-border{
	border-radius: 10px;
}


// TABLET

.doc-figma-image--default-imageContainer--device--iPadPro11Landscape.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPadPro129Landscape.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPadPro11Portrait.doc-figma-image--default-imageContainer--showImageShadow,
.doc-figma-image--default-imageContainer--device--iPadPro129Portrait.doc-figma-image--default-imageContainer--showImageShadow{
	overflow: hidden;
	border-radius: 8px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12), 
		0px 4px 8px 0px rgba(0,0,0,0.10),
		0px 8px 12px 0px rgba(0,0,0,0.06),
	;

}

.doc-figma-image--default-imageContainer--device--iPadPro11Landscape .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPadPro129Landscape .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPadPro11Portrait .doc-figma-image--default-image-border,
.doc-figma-image--default-imageContainer--device--iPadPro129Portrait .doc-figma-image--default-image-border{
	border-radius: 8px;
}




// DESKTOP
.doc-figma-image--default-imageContainer--device--desktop.doc-figma-image--default-imageContainer--showImageShadow{
	overflow: hidden;
	border-radius: 4px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.10), 
		0px 4px 8px 0px rgba(0,0,0,0.08),
		0px 8px 12px 0px rgba(0,0,0,0.04),
	;
}


//
// GENERAL NO SHADOW SMALL RADIUS
.doc-figma-image--default-imageContainer--noImageShadow{
	overflow: hidden;
	border-radius: 4px;
}
