
.doc-video-zoomedUI{
	position: absolute;
	z-index: 50;
	// background: $tempb;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	
}



//

.doc-video-zoomedUI-controlBar{
	position: absolute;
	height: $height-zoomVideo-controlBar;
	bottom: 0px;
	left: 0px;
	right: 0px;
	//background: $tempb;
	cursor: default;

	transition: opacity 50ms linear;
}

.doc-video-zoomedUI--fadeOut .doc-video-zoomedUI-controlBar:not(:hover) .doc-video-zoomedUI-controlBar-buttonRow,
.doc-video-zoomedUI--fadeOut .doc-video-zoomedUI-controlBar:not(:hover) .doc-video-zoomedUI-controlBar-seekbarContainer{
	opacity: 0.25;
	transition: opacity 400ms linear;
}

.doc-video-commentRow-fadeOutCover{
	opacity: 0;
	transition: opacity 50ms linear;
}

.doc-video-zoomedUI--fadeOut .doc-video-zoomedUI-controlBar:not(:hover) .doc-video-commentRow-fadeOutCover{
	opacity: 1;
	transition: opacity 400ms linear;
}





.doc-video-zoomedUI-controlBar-commentRowContainer{
	position: absolute;
	top: 6px;
	left: 0px;
	right: 0px;
	height: $height-zoomVideo-controlBar-commentRow;

	//background: $tempr;
	background: rgb(12,12,15);
	border-bottom: 1px solid rgba(255,255,255,0.04);

	z-index: 10;
}

//

.doc-video-zoomedUI-controlBar-buttonRow{
	position: absolute;
	height: calc(#{$height-zoomVideo-controlBar-buttonRow} - 6px);
	bottom: 0px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;
}

.doc-video-zoomedUI-controlBar-buttonRow-leftContainer,
.doc-video-zoomedUI-controlBar-buttonRow-centerContainer,
.doc-video-zoomedUI-controlBar-buttonRow-rightContainer{
	display: flex;
	align-items: center;
	height: 100%;
}

.doc-video-zoomedUI-controlBar-buttonRow-leftContainer,
.doc-video-zoomedUI-controlBar-buttonRow-rightContainer{
	width: 400px;
	flex-grow: 0;
	flex-shrink: 0;
}

.doc-video-zoomedUI-controlBar-buttonRow-centerContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: center;
}

.doc-video-zoomedUI-controlBar-buttonRow-rightContainer{
	justify-content: flex-end;
}


.doc-video-zoomedUI-controlBar-pingButton{
	width: 44px;
	height: 38px;
	background: rgba(255,255,255,0.08);
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.5;
}

.doc-video-zoomedUI-controlBar-pingButton-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: $white;
}

//

.doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer{
	margin-right: 8px;
}

//

.doc-video-zoomedUI-controlBar-counterGroup{
	display: flex;
}

.doc-video-zoomedUI-controlBar-counterGroup-counter{
	width: 40px;
	color: $white;
	font-size: 14px;

	cursor: default;
	user-select: none;
}

.doc-video-zoomedUI-controlBar-counterGroup-counter--elapsed{
	font-weight: $lato-semibold;
}

.doc-video-zoomedUI-controlBar-counterGroup-counter--duration{
	font-weight: $lato-regular;
	opacity: 0.5;
}

.doc-video-zoomedUI-controlBar-collabBtn{
	height: 100%;
	padding-left: 8px;
	padding-right: 8px;
	// background: $tempr;

	display: flex;
	align-items: center;

	opacity: 0.5;
}

.doc-video-zoomedUI-controlBar-collabBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: $white;
}

.doc-video-zoomedUI-controlBar-collabBtn-label{
	color: $white;
	margin-left: 6px;
}

//

.doc-video-zoomedUI-backgroundPlayPauseBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	right: 0px;
	bottom: 88px;
	// background: $tempr;
	// background: $white;
	cursor: default;
	z-index: 100;
}

.doc-video-zoomedUI--cursor--hide
.doc-video-zoomedUI-backgroundPlayPauseBtn{
	cursor: none;
}

@import 'Doc-Video-ZoomedUI-SeekBar';
@import 'Doc-Video-ZoomedUI-VolumeSlider';
@import 'Doc-Video-ZoomedUI-TitleBar';
@import 'Doc-Video-ZoomedUI-HoverSeekButtons';

