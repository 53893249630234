
///
/// HOVER MENU


.message-hoverMenu{
	display: flex;
	position: absolute;
	//right: 20px;
	right: 8px;
	top: -15px;
	top: -12px;

	z-index: 50;

	height: 28px;

	border-radius: 5px;
	background: $white;
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);

	background: var(--bgFloatingMenu);
	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);

	padding: 0px;

	box-shadow: var(--boxShadowFloatingMenu);

	padding-top: 0px;
	padding-bottom: 1px;
	padding-left: 3px;
	padding-right: 3px;

	user-select: none;
}

// .docEditor-commentsPanel-commentGroup-comment:first-child
// .message-hoverMenu{
// 	top: 1px;
// }

.message-hoverMenu-inner{
	width: 100;
	display: flex;
}


button.message-hoverMenu-btn{
	height: 28px;
	width: 28px;
	border: none;
	background: $invisible;
	box-shadow: none;

	padding: 0px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 18.5px; // for emoji

	cursor: pointer;
	user-select: none;


}

button.message-hoverMenu-btn:hover{
	background: var(--hoverBGFloatingMenu);
}	

.message-hoverMenu-btn svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.7;
}

.message-hoverMenu-btn:hover svg{
	opacity: 0.8;
}

.message-hoverMenu-inner:hover 
button.message-hoverMenu-btn{
	opacity: 0.7;
}

.message-hoverMenu-inner:hover 
button.message-hoverMenu-btn:hover{
	opacity: 1;
}

.message-hoverMenu--menuClosed{
	opacity: 0;
}

.messageContainer:hover .message-hoverMenu,
.message-hoverMenu:hover,
.hoverMenu--menuOpen{
	opacity: 1;
}

.message-hoverMenu-btn svg.icon--name--slackIcon{
	width: 16px;
	height: 16px;
}

.message-hoverMenu-btn svg.icon--name--commentOutline{
	width: 16px;
	height: 16px;
}

.message-hoverMenu-btn svg.icon--name--reply{
	transform: scaleX(-1.0);
}

.message-hoverMenu-btn svg.icon--name--thread{
	width: 20px;
	height: 20px;
}

//
// FIRST ONE SHOULD BE POSITIONED LOWER

.docEditor-messagePanel-scrollContents
.messageContainer:nth-last-child(2)
.message-hoverMenu{
	top: 2px;
}

//
// FOLLOW ON SHOULD BE POSITION HIGHER

.messageContainer--followOnMessage
.message-hoverMenu{
	top: -26px;
}