
//
// TAB ICONS – LATER


.tabBar-tab-iconContainer-bucketEmoji{
	width: 20px;
	border-radius: 4px;


	

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 20px;
}

.tabBar-tab-iconContainer-openForFeedback{
	width: 20px;
	height: 20px;
	border-radius: 4px;
	

	display: flex;
	align-items: center;
	justify-content: center;
}

.tabBar-tab--inactive .tabBar-tab-iconContainer-openForFeedback{
	background: rgb(0,0,0,0.05);
}

.tabBar-tab--active .tabBar-tab-iconContainer-openForFeedback{
	background: darken($green, 5%);
}

.tabBar-tab--active .tabBar-tab-iconContainer-openForFeedback svg{
	fill: $white;
}

.tabBar-tab-iconContainer .icon--name--twoWayArrowsSemibold{
	width: 14px;
	height: 14px;
}

//

.tabBar-tab-iconContainer .stageIcon{
	width: 16px;
	height: 16px;
	
	flex-grow: 0;
	flex-shrink: 0;
}