$lato-regular:  400;
$lato-medium:   500;
$lato-semibold: 600;
$lato-bold:     700;
$lato-heavy:    800;
$lato-black:    900;

@font-face {
    font-family: 'Lato';        
    src: url(../Lato/Lato-Medium.ttf) format('truetype');    
    font-style: normal;
    font-display: auto;
    font-weight: $lato-medium;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';       
    src: url(../Lato/Lato-Semibold.ttf) format('truetype');   
    font-style: normal;
    font-display: auto;
    font-weight: $lato-semibold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: 'Lato';   
    src: url(../Lato/Lato-Bold.ttf) format('truetype');   
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: 'Lato';    
    src: url(../Lato/Lato-Heavy.ttf) format('truetype');   
    font-style: normal;
    font-display: auto;
    font-weight: $lato-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';    
    src: url(../Lato/Lato-Black.ttf) format('truetype');   
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}



@font-face {
    font-family: 'tldraw_draw';    
    src: url(../Shantell_Sans-Normal-SemiBold.woff2) format('woff2');   
    font-style: normal;
    font-display: auto;
    // font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}


///

.App, body, input, textarea, button {
    font-family: Lato, "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.App, body::selection, input::selection, textarea::selection, div::selection, span::selection{
    background-color: var(--textSelectionBlue) !important; 
    // I think this is right
    // chrome is adding special text-selection colors if dark mode
}