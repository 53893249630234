
.allDocsPageContainer,
.privateDocsPageContainer,
.recentPageContainer,
.meetingNotesPageContainer{
	position: fixed;
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);
	
	overflow-x: hidden;
	overflow-y: scroll;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-channelFrame);

	z-index: 10;

	border-radius: 8px;
}

.allDocsPageContainer--sideMenuState--hidden,
.privateDocsPageContainer--sideMenuState--hidden,
.recentPageContainer--sideMenuState--hidden,
.meetingNotesPageContainer--sideMenuState--hidden{
	left: calc(#{$windowMargin-sideMenuMode} - 1px);
}

//

.allDocsPage,
.privateDocsPage,
.recentPage,
.meetingNotesPage{
	width: 100%;
	height: 100%;
	display: flex;
}

.allDocsPage-leftSpacer,
.privateDocsPage-leftSpacer,
.recentPage-leftSpacer,
.meetingNotesPage-leftSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	// widths set dynamically in coreLayout
}

.allDocsPage-center,
.privateDocsPage-center,
.recentPage-center,
.meetingNotesPage-center{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	// widths set dynamically in coreLayout
}

.allDocsPage-rightPanel,
.privateDocsPage-rightPanel,
.recentPage-rightPanel,
.meetingNotesPage-rightPanel{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;	
	position: relative;
	// widths set dynamically in coreLayout
}

.allDocsPage-header,
.privateDocsPage-header,
.recentPage-header,
.meetingNotesPage-header{
	//padding-top: 64px;
	padding-top: 84px;

	display: flex;
	align-items: center;
	
	margin-bottom: 18px;

	user-select: none;
}

.privateDocsPage-header{
	align-items: flex-end;
}


.allDocsPage-header-title,
.privateDocsPage-header-title,
.recentPage-header-title,
.meetingNotesPage-header-title{
	display: flex;
	align-items: center;
}

.allDocsPage-header-title-iconContainer,
.privateDocsPage-header-title-iconContainer,
.recentPage-header-title-markerContainer,
.meetingNotesPage-header-title-iconContainer{
	width: 38px;
	height: 38px;
	//background: $tempr;

	margin-right: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.allDocsPage-header-title-iconContainer,
.privateDocsPage-header-title-iconContainer,
.recentPage-header-title-iconContainer,
.meetingNotesPage-header-title-iconContainer{
	width: 40px;
	height: 40px;

}

.allDocsPage-header-title-iconContainer{
	margin-left: -1px;
	margin-right: 13px;
}

.privateDocsPage-header-title-iconContainer{
	margin-left: -6px;
	margin-right: 8px;
}

.recentPage-header-title-iconContainer{
	margin-left: -6px;
	margin-right: 8px;
}

.allDocsPage-header-title-iconContainer svg,
.privateDocsPage-header-title-iconContainer svg,
.recentPage-header-title-iconContainer svg,
.meetingNotesPage-header-title-iconContainer svg{
	width: 40px;
	height: 40px;
	fill: var(--text500);
	opacity: 0.4;
}

.meetingNotesPage-header-title-iconContainer svg{
	position: relative;
	width: 41px;
	height: 41px;
	left: -2px;
}

.allDocsPage-header-title-iconContainer svg{
	position: relative;
	top: 2px;
}

.recentPage-header-title-iconContainer svg{
	position: relative;
	top: 2px;
}


.allDocsPage-header-title-label,
.privateDocsPage-header-title-label,
.recentPage-header-title-label,
.meetingNotesPage-header-title-label{	
	
	font-size: 39px;
	//font-size: 36px;
	font-weight: $lato-black;
	color: var(--text600);
	letter-spacing: 0.001em;

	cursor: default;
	user-select: none;
}

[data-theme="dark"] {
	.allDocsPage-header-title-label,
	.privateDocsPage-header-title-label,
	.recentPage-header-title-label,
	.meetingNotesPage-header-title-label{
		font-weight: $lato-heavy;
		letter-spacing: 0.005em;
	}
}

.allDocsPage-header-rightContainer,
.privateDocsPage-header-rightContainer,
.recentPage-header-rightContainer,
.meetingNotesPage-header-rightContainer{
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.allDocsPage-header-btn,
.recentPage-header-btn,
.meetingNotesPage-header-btn{
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	border-radius: 6px;

	opacity: 0.6;
}

.allDocsPage-header-btn:hover,
.recentPage-header-btn:hover,
.meetingNotesPage-header-btn{
	opacity: 1;
	background: var(--250gray);
}

.allDocsPage-header-btn svg,
.recentPage-header-btn svg,
.meetingNotesPage-header-btn svg{
	width: 22px;
	height: 22px;
	fill: var(--text500);

	opacity: 0.8;
}


//

.allDocsPage-content-tableHeader{
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}

.allDocsPage-content-tableHeader-label{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: var(--text500);
	cursor: default;
	user-select: none;
}

	


.allDocsPage-docList-header-rightContainer{
	margin-left: auto;
}


//
// NEW DOC BUTTON

.privateDocsPage-header-newDocBtn,
.recentPage-content-tableHeader-newDocBtn,
.meetingNotesPage-header-newMeetingBtn{
	margin-left: auto;
	padding-left: 7px;
	padding-right: 9px;

	height: 30px;
	border-radius: 4px;

	//background: var(--primary500);
	background: var(--text400);
	border: 1px solid var(--text500);
}

.privateDocsPage-header-newDocBtn:hover,
.recentPage-content-tableHeader-newDocBtn:hover,
.meetingNotesPage-header-newMeetingBtn:hover{
	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]{
	.privateDocsPage-header-newDocBtn,
	.recentPage-content-tableHeader-newDocBtn,
	.meetingNotesPage-header-newMeetingBtn{
		background: var(--250gray);
		border: 1px solid var(--246gray);
	}
}

:root[data-theme="dark"]{
	.privateDocsPage-header-newDocBtn:hover,
	.recentPage-content-tableHeader-newDocBtn:hover,
	.meetingNotesPage-header-newMeetingBtn:hover{
		background: var(--248gray);
		border: 1px solid var(--246gray);
	}
}





.privateDocsPage-header-newDocBtn-iconContainer,
.recentPage-content-tableHeader-newDocBtn-iconContainer,
.meetingNotesPage-header-newMeetingBtn-iconContainer{
	margin-right: 3px;
	height: 100%;
	display: flex;
	align-items: center;
}

.privateDocsPage-header-newDocBtn-iconContainer svg,
.recentPage-content-tableHeader-newDocBtn-iconContainer svg,
.meetingNotesPage-header-newMeetingBtn-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: $white;
}

.privateDocsPage-header-newDocBtn-label,
.recentPage-content-tableHeader-newDocBtn-label,
.meetingNotesPage-header-newMeetingBtn-label{
	color: $white;
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
}

.meetingNotesPage-header-newMeetingBtn{
	background: var(--text500);
}

.meetingNotesPage-header-newMeetingBtn:hover{
	background: var(--text600);	
}


@import 'LibraryPages-AllDocsPage';
@import 'LibraryPages-MeetingNotesPage';
@import 'LibraryPages-PrivateDocsPage';
@import 'LibraryPages-RightPanelTip';
@import 'LibraryPages-DocPreview';
@import 'LibraryPages-MemberGrid';

