


.docEditor-insertLinkTrigger{
	position: absolute;
	
	top: 0px;
	left: 0px;
	width: 10px;
	height: 24px;

	background: $tempr;

	z-index: 10;
}

.docEditor-insertLinkPopover{
	margin-left: -32px;
	margin-top: 6px;

	// width: 455px; // with bucket
	width: 370px;
	
	background: $white;
	border: 1px solid red;

	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border: 1px solid var(--outsideBottomLineFloatingMenu);

	box-shadow: var(--boxShadowFloatingMenuStrong);

	border-radius: 5px;

	background: var(--bgFloatingMenu);
	background: rgb(255,255,255);

	box-sizing: border-box;

	padding-top: 5px;
	padding-bottom: 10px;

	padding-left: 4px;
	padding-right: 4px;

	position: relative;

}


.docEditor-insertLinkPopover-resultsList-rowDocPreview{
	position: absolute;
	top: -1px;
	left: 375px;
	height: 239px;
	width: 200px;

	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border: 1px solid var(--outsideBottomLineFloatingMenu);

	// border: 1px solid hsl(240, 3%, 88%);

	//box-shadow: var(--boxShadowFloatingMenuStrong);

	border-radius: 5px;

	background: var(--bgFloatingMenu);
	background: rgb(255,255,255);
}

//


// .docEditor-insertMenu-item{
// 	width: calc(100% - 8px);
// 	margin-left: 4px;

// 	height: 28px;

// 	display: flex;

// 	box-sizing: border-box;
// 	padding-left: 6px;
// 	padding-right: 4px;
	
// 	background: none;

// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;

// 	user-select: none;
// 	cursor: pointer;

// 	border-radius: 4px;
// }

.docEditor-insertLinkPopover-resultsList-row{
	height: 28px;
	width: 100%;
	//background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 4px;

	user-select: none;
	cursor: pointer;
}

.docEditor-insertLinkPopover-resultsList-row--active{
	background: rgba(20,0,20,0.06);
	// background: $white;
}



.docEditor-insertLinkPopover-resultsList-row-docTitle{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-insertLinkPopover-resultsList-row-metadataContainer{
	margin-left: auto;
	display: flex;
	height: 100%;
	align-items: center;
	flex-shrink: 0;
	flex-grow: 0;
}

.docEditor-insertLinkPopover-resultsList-row-metadataTag{
	margin-left: 8px;
	display: flex;
	align-items: center;
	height: 100%;

	opacity: 0.65;
}

.docEditor-insertLinkPopover-resultsList-row--active
.docEditor-insertLinkPopover-resultsList-row-metadataTag{
	opacity: 1;
}

.docEditor-insertLinkPopover-resultsList-row-metadataTag-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;

	margin-right: 3px;
}

.docEditor-insertLinkPopover-resultsList-row-metadataTag-label{
	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.001em;
	color: var(--text350);
}


// Bucket

.docEditor-insertLinkPopover-resultsList-row-metadataTag--bucket{
	
}

// .docEditor-insertLinkPopover-resultsList-row:hover
// .docEditor-insertLinkPopover-resultsList-row-metadataTag--bucket{
// 	background: darken(rgb(247,246,246), 5%);
// 	color: darken(rgb(79,81,91), 5%);
// }

.docEditor-insertLinkPopover-resultsList-row-metadataTag--bucket
.docEditor-insertLinkPopover-resultsList-row-metadataTag-iconContainer{
	//emoji
	font-size: 13px;
	margin-top: 1px;
}


// Channel

.docEditor-insertLinkPopover-resultsList-row-metadataTag--channel
.docEditor-insertLinkPopover-resultsList-row-metadataTag-iconContainer{
	margin-right: 2px;
}

.docEditor-insertLinkPopover-resultsList-row-metadataTag--channel
.docEditor-insertLinkPopover-resultsList-row-metadataTag-iconContainer svg{
	width: 12px;
	height: 12px;
	opacity: 0.3;
	margin-top: 2px;
}