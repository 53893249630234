
.settingsContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

// SIDE MENU
.settings-sideMenu{
	// width set in coreLayout

	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;

	z-index: 50;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 48px;
	padding-bottom: 48px;
	padding-left: 14px;
	padding-right: 14px;
}

.settings-sideMenu-backBtnContainer{
	height: 100%;
	width: 20%;
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempr;

	box-sizing: border-box;
}

.settings-sideMenu-backBtn{
	height: 56px;
	width: 100%;
	border-radius: 8px;

	display: flex;
	align-items: flex-start;
	padding-top: 24px;
	justify-content: center;

	height: 100%;

	opacity: 0.4;

}

.settings-sideMenu-backBtn svg{
	 width: 28px;
	 height: 28px;
	 fill: var(--text600);
}

.settings-sideMenu-backBtn:hover{
	background: var(--bgSideMenuTabHover);
	opacity: 1;
}



.settings-sideMenu-content{
	flex-grow: 1;
	flex-shrink: 1;



	box-sizing: border-box;
	padding-left: 32px;
	

	height: 100%;

	display: flex;
	flex-direction: column;
}

.settings-sideMenu-content-profile{
	display: flex;
	flex-direction: column;

	flex-grow: 0;
	flex-shrink: 0;	

	cursor: default;
	user-select: none;
}

.settings-sideMenu-content-profile-avatarContainer{
	width: 128px;
	height: 128px;
	background: $tempr;

	margin-bottom: 12px;
	overflow: hidden;
	border-radius: 18px;
}

.settings-sideMenu-content-profile-avatar{
	border-radius: 18px;
}


.settings-sideMenu-content-profile-displayName{
	font-size: 44px;
	font-weight: $lato-black;
	color: var(--text600);

	margin-bottom: 8px;
}

.settings-sideMenu-content-profile-orgName{
	font-size: 20px;
	font-weight: $lato-medium;
	color: var(--text400);
	margin-bottom: 8px;
}

.settings-sideMenu-content-profile-email{
	font-size: 18px;
	font-weight: $lato-regular;
	color: var(--text400);
	opacity: 0.8;
}



.settings-sideMenu-content-vSpacer{
	min-height: 10px;
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
}

.settings-sideMenu-content-footer{
	display: flex;
	flex-direction: column;

	flex-grow: 0;
	flex-shrink: 0;	

	padding-bottom: 24px;
}

.settings-sideMenu-content-footer-btn{
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 16px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.5;
}

.settings-sideMenu-content-footer-btn--slack{
	font-weight: $lato-semibold;
	opacity: 0.75;

	margin-bottom: 8px;
}

.settings-sideMenu-content-footer-btn--slack:hover,
.settings-sideMenu-content-footer-btn--signOut:hover{
	opacity: 1;
}

.settings-sideMenu-content-footer-btn--version{
	user-select: auto;
	cursor: text;
}







//

.settingsPage{
	position: fixed;
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);
	
	overflow-x: hidden;
	overflow-y: scroll;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-channelFrame);

	z-index: 10;

	border-radius: 8px;

	display: flex;


}

.settingsPage-leftSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	// widths set dynamically in coreLayout
}

.settingsPage-content{	
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	padding-top: 32px;

	box-sizing: border-box;
	// widths set dynamically in coreLayout
}

.settingsPage-rightSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;	
	position: relative;
	// widths set dynamically in coreLayout
}


.settingsPage-content-header{
	font-size: 32px;
	font-weight: $lato-heavy;
	color: var(--text600);
	letter-spacing: 0.008em;
	margin-top: 16px;
}

.settingsPage-content-section{
	padding-top: 22px;
	padding-bottom: 18px;
}

.settingsPage-content-section-header{
	user-select: none;
	cursor: default;
}

.settingsPage-content-section-header-title{
	font-size: 17.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	letter-spacing: 0.002em;
	margin-bottom: 4px;

}

.settingsPage-content-section-header-subheader{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.012em;
	opacity: 0.65;

}

.settingsPage-content-divider{
	width: 100%;
	height: 1px;	

	background: var(--line300);
}

//
// THEME

.settingsPage-content-section--theme-btnContainer{
	width: 100%;
	display: flex;
	margin-top: 8px;

	margin-left: -8px;

}

.settingsPage-content-section--theme-btn{
	width: 130px;
	height: 32px;

	border-radius: 5px;

	padding-left: 8px;
	padding-right: 8px;

	align-items: center;
	justify-content: flex-start;

	user-select: none;
}

.settingsPage-content-section--theme-btn:hover{
	background: var(--hoverBG-item-dropdown);
}

.settingsPage-content-section--theme-btn-radioContainer{
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 8px;
}

.settingsPage-content-section--theme-btn-radio{
	width: 14px;
	height: 14px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--bg-docEditor);
}

.settingsPage-content-section--theme-btn.settingsPage-content-section--theme-btn--inactive
.settingsPage-content-section--theme-btn-radio{	
	border: 1px solid var(--line600);
}

.settingsPage-content-section--theme-btn.settingsPage-content-section--theme-btn--active
.settingsPage-content-section--theme-btn-radio{	
	border: 1px solid var(--125gray);
}

.settingsPage-content-section--theme-btn-radio-inner{	
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: var(--125gray);
}

.settingsPage-content-section--theme-btn-label{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);
	text-transform: capitalize;
}


//
// FIGMA / GCAL
.settingsPage-content-section--figma,
.settingsPage-content-section--GCal{
	position: relative;
}

.settingsPage-content-section--figma{

}


.settingsPage-content-section--figma-statusRow{
	display: flex;
	font-size: 16px;
	font-weight: $lato-medium;
	color: var(--text500);

	margin-top: 8px;
	margin-bottom: 5px;

	align-items: center;

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text400);
	letter-spacing: 0.012em;

	user-select: none;
	cursor: default;
}



.settingsPage-content-section--figma-statusRow-checkbox{
	width: 16px;
	height: 16px;
	background: var(--activeUserGreen);
	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 3px;

	margin-right: 6px;

}

 .settingsPage-content-section--figma-statusRow-checkbox svg{
 	width: 11px;
	height: 11px;
	stroke: var(--toDoItem-checkIconColor);

	z-index: 10;
	position: relative;	

	stroke-width: 10px;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;

  fill: $white;
 }


// .settingsPage-content-section--figma-activeStatusTag{
// 	// pretty hacky
// 	position: absolute;
// 	top: 22px;
// 	//left: 145px;
// 	right: 0px;

// 	padding-left: 5px;
// 	padding-right: 5px;
// 	height: 20px;

// 	border-radius: 2px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	background: var(--activeUserGreen);

// 	color: $white;

// 	font-size: 11px;
// 	text-transform: uppercase;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.08em;
// }

// [data-theme="dark"] .settingsPage-content-section--figma-activeStatusTag{
// 	background: rgba(104,255,211, 0.25);
// 	color: var(--activeUserGreen);
// }


.settingsPage-content-section--figma-activeStatusTagContainer{
		// pretty hacky
	position: absolute;
	top: 22px;
	//left: 145px;
	right: 0px;
	//background: $tempr;
	flex-grow: 0;
	flex-shrink: 0;
}

.settingsPage-content-section--figma-activeStatusTag{
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 4px; 

	position: relative;
	top: -2px;
	right: -1px;

	font-size: 14.5px;
	letter-spacing: 0.008em;
	font-weight: $lato-semibold;

	border: 1px solid $invisible;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}

.settingsPage-content-section--figma-activeStatusTag{
	color: var(--text600);
	//border: 1px solid var(--line300);
	background: rgba(246,246,249, 1);
}
[data-theme="dark"] {
	.settingsPage-content-section--figma-activeStatusTag{
		background: rgba(104,255,211,0.1);
	}
}



.settingsPage-content-section--figma-activeStatusTag-dot{
	width: 6px;
	height: 6px;
	border-radius: 50%;

	margin-right: 5px;
	margin-left: -2px;

	border: 1px solid $invisible;
}

.settingsPage-content-section--figma-activeStatusTag
.settingsPage-content-section--figma-activeStatusTag-dot{
	background: var(--activeUserGreen)
}



//

.settingsPage-content-section--figma-statusRow-enabledUser{
	display: flex;
	align-items: center;

	margin-left: 0.35em;
	margin-right: 0.35em;
}

.settingsPage-content-section--figma-statusRow-enabledUser-avatarContainer{
	width: 18px;
	height: 18px;
	overflow: hidden;
	border-radius: 3px;
	margin-right: 6px;
}

.settingsPage-content-section--figma-statusRow-enabledUser-userName{
	// font-weight: $lato-semibold;
	// color: var(--text600);
	font-size: 16px;
}

button.settingsPage-content-section--figma-statusRow-btn{
	margin-left: 0.5em;
	margin-left: auto;	
	color: var(--text500);

	font-size: 15px;

	opacity: 0.5;
}

button.settingsPage-content-section--figma-statusRow-btn:hover{
	text-decoration: underline;
}

button.settingsPage-content-section--figma--connectBtn,
.settingsPage-content-section--GCal .GCalSignInBtn{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 14px;

	height: 34px;
	padding-right: 14px;
	padding-left: 9px;
	
	border-radius: 4px;
	
	// background: var(--text400);
	// border: 1px solid var(--text500);

	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line450);
	color: var(--text500);

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);

	
	border-radius: 4px;

	font-size: 15.75px;
	font-weight: $lato-semibold;
}

button.settingsPage-content-section--figma--connectBtn:hover,
.settingsPage-content-section--GCal .GCalSignInBtn:hover{
	border: 1px solid var(--line500);
	border-bottom: 1px solid var(--line500);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.06);
}

[data-theme="dark"]{
	button.settingsPage-content-section--figma--connectBtn,
	.settingsPage-content-section--GCal .GCalSignInBtn{
		background: lighten(rgb(32,30,36), 2%);
		border: 1px solid var(--line200);
		border-top: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		box-shadow: none;
	}
	button.settingsPage-content-section--figma--connectBtn:hover,
	.settingsPage-content-section--GCal .GCalSignInBtn:hover{
		background: lighten(rgb(32,30,36), 2%);
		border: 1px solid var(--line200);
		border-top: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		box-shadow: none;
	}
}


.settingsPage-content-section--figma--connectBtn-iconContainer,
.settingsPage-content-section--GCal .GCalSignInBtn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

button.settingsPage-content-section--figma--connectBtn svg,
.settingsPage-content-section--GCal .GCalSignInBtn-iconContainer svg{
	width: 13px;
	height: 13px;

	position: relative;
	top: 0.5px;	
}

.settingsPage-content-section--GCal .GCalSignInBtn-iconContainer svg{
	
}

//
// TEAMMATES

.settingsPage-content-section--teammates-list,
.settingsPage-content-section--contacts-list{
	width: 100%;
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 16px;

	padding-left: 1px;
	padding-right: 1px;
}

.settingsPage-content-section--contacts-list{
	margin-bottom: 32px;
}


.settingsPage-content-section--teammates-row,
.settingsPage-content-section--contacts-row{
	display: flex;
	height: 36px;
	width: 100%;
	border-radius: 5px;

	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;

	cursor: default;
}

.settingsPage-content-section--teammates-row-avatarContainer,
.settingsPage-content-section--contacts-row-avatarContainer{
	width: 28px;
	height: 28px;

	// width: 24px;
	// height: 24px;

	margin-right: 10px;
}

.settingsPage-content-section--teammates-row-avatar,
.settingsPage-content-section--contacts-row-avatar{
	border-radius: 4px;
}

.settingsPage-content-section--teammates-row-username,
.settingsPage-content-section--contacts-row-primary,
.settingsPage-content-section--contacts-row-secondary{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text500);
	letter-spacing: 0.01em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settingsPage-content-section--contacts-row-secondary{
	opacity: 0.6;
	font-weight: $lato-medium;
	margin-left: 6px;
}

.settingsPage-content-section--teammates-row-email,
.settingsPage-content-section--contacts-row-tertiary{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	margin-left: auto;
	opacity: 0.5;
}

.settingsPage-content-section--contacts-row-dropdownBtn{
	width: 28px;
	height: 28px;
	// background: $tempr;
	border-radius: 4px;

	margin-left: auto;
}

.settingsPage-content-section--contacts-row-dropdownBtn:hover,
.settingsPage-content-section--contacts-row-dropdownBtn[data-state="open"]{
	background: var(--251gray);
}

.settingsPage-content-section--contacts-row-tertiary ~ 
.settingsPage-content-section--contacts-row-dropdownBtn{
	margin-left: 8px;
}

.settingsPage-content-section--contacts-row-dropdownBtn svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.5;
}

.settingsPage-content-section--contacts-row-dropdownBtn:hover svg,
.settingsPage-content-section--contacts-row-dropdownBtn[data-state="open"] svg{
	opacity: 0.8;
}