

//

.docEditor-docTemplateSelectContainer{	
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.docEditor-docInnerContainer--showTemplateSelection #editor{
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.docEditor-docTemplateSelect{
	height: 100%;

	display: flex;
	flex-direction: column;

	// min-height: 400px;
	
	background: var(--bg-docEditor); // temporary cover up
	// background: $tempr;

	z-index: 50;
}

.docEditor-docTemplateSelect-button{
	 
	 // width: calc(100% - 80px);
	 height: 34px;	 
	 
	 border-radius: 4px;	 

	 width: calc(100% + 24px);

	 padding-left: 12px;	 
	 margin-left: -12px;
	 box-sizing: border-box;
	 padding-right: 12px;
	 justify-content: flex-start;

	 position: relative;
}

.docEditor-docTemplateSelect-button--instruction{
	color: var(--text500);

	transition: opacity 175ms ease-in-out;
}

.docEditor-docTemplateSelect-button--instruction--hide{
	opacity: 0.25;
}

.docEditor-docTemplateSelect-button--instruction--show{
	opacity: 1;
}


.docEditor-docTemplateSelect-button--openModalBtn{
	margin-top: 8px;
}

.docEditor-docTemplateSelect-button-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
	margin-left: -2px; // optical center icons
}

// .docEditor-docTemplateSelect-button-iconContainer svg{
// 	width: 16px;
// 	height: 16px;	
// }

.docEditor-docTemplateSelect-button-iconContainer svg{
	width: 19px;
	height: 19px;	
}

.docEditor-docTemplateSelect-button-iconContainer svg.icon--name--template{
	fill: var(--text500);
	opacity: 0.8;
}

.docEditor-docTemplateSelect-button-iconContainer
.meetingTypeIconContainer svg{
	width: 17px;
	height: 17px;
}

.docEditor-docTemplateSelect-button--inactive
.docEditor-docTemplateSelect-button-iconContainer
.meetingTypeIconContainer svg #bg{	
	fill: var(--text500) !important;
	opacity: 0.6; 
}


.docEditor-docTemplateSelect-button-iconContainer svg{
	width: 19px;
	height: 19px;	
}

.docEditor-docTemplateSelect-button-iconContainer svg.icon--name--template{
	fill: var(--text500);
	opacity: 0.8;
}

//

.docEditor-docTemplateSelect-button-label-subIconContainer{
	display: inline-flex;
	width: 22px;
	height: 22px;
	// background: $tempr;

	position: relative;
	top: 2px;

	align-items: center;
	justify-content: center;

	margin-right: -8px;
	opacity: 0.5;
}
.docEditor-docTemplateSelect-button-label-subIconContainer svg{
	width: 17px;
	height: 17px;
	fill: var(--text500);
}
.docEditor-docTemplateSelect-button-label-subIconContainer--flip svg{
	transform: scaleY(-1);
}

//

.docEditor-docTemplateSelect-button--suggested{
	margin-bottom: 14px;
}

.docEditor-docTemplateSelect-button-suggestedIconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
	margin-left: -4px;
}

.docEditor-docTemplateSelect-button-suggestedIconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.75;
}

//


button.docEditor-docTemplateSelect-button .docEditor-docTemplateSelect-button-label{
	// same as body – in doc.scss
	color: var(--text500);
	font-weight: $lato-medium;
	opacity: 1;
}


//


.docEditor-docTemplateSelect-button-enterIcon{
	display: flex;
	margin-left: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-docTemplateSelect-button-enterIcon svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.8;
}


.docEditor-docTemplateSelect-button--active,
.docEditor-docTemplateSelect--mouseHasMoved
.docEditor-docTemplateSelect-button--inactive:hover{
	background: var(--248gray);
	opacity: 1;
}

.docEditor-docTemplateSelect-button--inactive{
	opacity: 0.7;
}


//

//
//

.docEditor-docTemplateSelect-vSpacer{
	min-height: 10px;

	flex-grow: 1;
	flex-shrink: 1;
}

//

.docEditor-docTemplateSelect-addLinkToLinearBtnContainer{
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-docTemplateSelect-addLinkToLinearBtn{
	// background: $tempr;

	padding-left: 34px;
	padding-right: 34px;

	width: 300px;

	padding-top: 14px;
	padding-bottom: 14px;

	border-radius: 8px;

	margin-bottom: 8px;
	cursor: pointer;

	opacity: 0.6;
	transition: opacity 50ms linear;

	//background: var(--253gray);
}

.docEditor-docTemplateSelect-addLinkToLinearBtn:hover{
	opacity: 1;
}

.docEditor-docTemplateSelect-addLinkToLinearBtn[data-state='unchecked']{
	opacity: 1;
}



.docEditor-docTemplateSelect-addLinkToLinearBtn-checkBoxContainer{
	margin-right: 7px;
	position: relative;
	top: -1px;
}

.docEditor-docTemplateSelect-addLinkToLinearBtn-checkBoxContainer
.checkbox[data-is-checked="true"]{
	background: var(--text200);
	border: 1px solid var(--text300);
}

.docEditor-docTemplateSelect-addLinkToLinearBtn-checkBoxContainer svg{
	position: relative;
	top: 1px; // todo fix
	left: 1px;
}

.docEditor-docTemplateSelect-addLinkToLinearBtn-label{
	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	opacity: 0.8;
}


//
//
// .docEditor-docTemplateSelect-button-previewContainer{
	
// 	position: fixed;
// 	top: 200px;
// 	right: 220px;
	

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	z-index: 400;
// 	// display: none;
// }

// .docEditor-docTemplateSelect-button-preview{
	
// 	height: 500px;
// 	width: 400px;

// 		background: var(--bg-dropdown);
// 	border: var(--outsideLine-dropdown);
// 	border-top: var(--outsideTopLine-dropdown);
// 	border-bottom: var(--outsideBottomLine-dropdown);

// 	//box-shadow: var(--boxShadow-dropdown);
// 	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);

// 	border-radius: 5px;
// }


.docEditor-docTemplateSelect-button-popoverTrigger{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 10px;
	height: 100%;
	pointer-events: none;
	// background: $tempr;
}

.docEditorPreviewPopoverContainer{	
	
}

.docEditorPreviewPopoverContainer:focus{
	outline: none;
}

.docEditorPreviewPopoverContainer--hidden,
.docEditorPreviewPopoverContainer--hidden *{
	pointer-events: none !important;
}

.docEditorPreviewPopoverContainer.docEditorPreviewPopoverContainer--layout--topMenu
.moveDocPopover{
	margin-right: -100px; // when it was in code, then keyboard shortcut opening seemed to cause it to jump
	//if you can hide right panel in future, we'll have issues
}

//

$scalar-docPreviewPopover: 0.8;

.docEditorPreviewPopover{
	height: calc(480px * #{$scalar-docPreviewPopover});
	width: calc(400px  * #{$scalar-docPreviewPopover});


	border-radius: 5px;

	display: flex;
	flex-direction: row; // override popover
	align-items: flex-start;
	justify-content: center;

	overflow: hidden;
	position: relative;

	border-top: 1px solid var(--line300);
	border: 1px solid var(--line300);
	border-bottom: 1px solid var(--line400);

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
	
}

[data-theme="dark"]{
	.docEditorPreviewPopover{
		border-top: 1px solid var(--line300);
		border: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.14);
	}	
}


//box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);

.docEditorPreviewPopover-docContainer{
	width: calc(320px  * #{$scalar-docPreviewPopover});
	padding-top: calc(34px  * #{$scalar-docPreviewPopover});


}

.docEditorPreviewPopover-doc{
	width: calc(650px  * #{$scalar-docPreviewPopover}); 
	// background: $tempr;

	transform: scale(0.49);
	transform: scale(0.392);
	transform-origin: top left;
}

.docEditorPreviewPopover-doc-innerWrapper{
	width: $textWidth-doc;
}

.docEditorPreviewPopover-bottomGradient{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 100px;
	//background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.66) 33%, rgba(255,255,255,1) 100%);

  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.013) 8.1%,
    hsla(0, 0%, 100%, 0.049) 15.5%,
    hsla(0, 0%, 100%, 0.104) 22.5%,
    hsla(0, 0%, 100%, 0.175) 29%,
    hsla(0, 0%, 100%, 0.259) 35.3%,
    hsla(0, 0%, 100%, 0.352) 41.2%,
    hsla(0, 0%, 100%, 0.45) 47.1%,
    hsla(0, 0%, 100%, 0.55) 52.9%,
    hsla(0, 0%, 100%, 0.648) 58.8%,
    hsla(0, 0%, 100%, 0.741) 64.7%,
    hsla(0, 0%, 100%, 0.825) 71%,
    hsla(0, 0%, 100%, 0.896) 77.5%,
    hsla(0, 0%, 100%, 0.951) 84.5%,
    hsla(0, 0%, 100%, 0.987) 91.9%,
    hsl(0, 0%, 100%) 100%
  );

	z-index: 10;

}

[data-theme="dark"] .docEditorPreviewPopover-bottomGradient{
	background: linear-gradient(
    to bottom,
    rgba(36,31,38, 0) 0%,
    rgba(36,31,38, 0.013) 8.1%,
    rgba(36,31,38, 0.049) 15.5%,
    rgba(36,31,38, 0.104) 22.5%,
    rgba(36,31,38, 0.175) 29%,
    rgba(36,31,38, 0.259) 35.3%,
    rgba(36,31,38, 0.352) 41.2%,
    rgba(36,31,38, 0.45) 47.1%,
    rgba(36,31,38, 0.55) 52.9%,
    rgba(36,31,38, 0.648) 58.8%,
    rgba(36,31,38, 0.741) 64.7%,
    rgba(36,31,38, 0.825) 71%,
    rgba(36,31,38, 0.896) 77.5%,
    rgba(36,31,38, 0.951) 84.5%,
    rgba(36,31,38, 0.987) 91.9%,
    rgba(36,31,38, 1) 100%
  );

}
