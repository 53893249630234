

.doc-para-internalLinkContainer{
	display: inline-block;

	white-space: pre-wrap;
	word-break: break-word;

	cursor: pointer;
	
	position: relative;
	//top: 0.08em;
	//margin-bottom: -0.02em;

	// margin-right: 0.1em;
	margin-left: 0.1em;
}


.doc-para-internalLink{
	border-radius: 1px;
}


.doc-para-internalLink:hover,
.doc-para-internalLinkContainer.ProseMirror-nodeInSelection.ProseMirror-selectednode .doc-para-internalLink{
	background: var(--250grayTransparent);
	box-shadow: 0px 0px 0px 3px var(--250grayTransparent);
	border-radius: 1px;
}

.doc-para-internalLink:hover{
	transition: all 0ms linear;
	transition-delay: 100ms;
}

.doc-para-internalLinkContainer.ProseMirror-nodeInSelection .doc-para-internalLink,
.doc-para-internalLinkContainer.ProseMirror-nodeInSelection .doc-para-internalLink:hover{
	background: var(--textSelectionBlue);
	box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);
}	

.doc-para-internalLinkContainer.ProseMirror-nodeInSelection .doc-para-internalLink{
	border-radius: 0px;
}

.doc-para-internalLinkContainer.ProseMirror-nodeSearchResult .doc-para-internalLink,
.doc-para-internalLinkContainer.ProseMirror-nodeSearchResult .doc-para-internalLink:hover{
	background: var(--searchResultBG);
	box-shadow: 0px 0px 0px 2px var(--searchResultBG);
}	

.doc-para-internalLinkContainer.ProseMirror-nodeSearchResult--active .doc-para-internalLink,
.doc-para-internalLinkContainer.ProseMirror-nodeSearchResult--active .doc-para-internalLink:hover{
	animation: activeResultAnimation 220ms ease-in forwards;
}	




[data-theme="dark"]{
	.doc-para-internalLink:hover,
	.doc-para-internalLinkContainer.ProseMirror-nodeInSelection.ProseMirror-selectednode .doc-para-internalLink{
		background: var(--246gray);
		box-shadow: 0px 0px 0px 3px var(--246gray);
	}
}


.doc-para-internalLink-spacer{
	display: inline-block;
	white-space: nowrap;
	width: 0.01em;
}

.doc-para-internalLink-spacer::before{
	content: "\200B";
}
// .doc-para-internalLinkContainer.selection-span,
// .doc-para-internalLinkContainer.selection-span:hover{	
// 	background: var(--textSelectionBlue);
// 	border-radius: 0px;
// }


.doc-para-internalLink:active{
	background: var(--246gray);
}

.doc-para-internalLink-iconContainer{
	white-space: nowrap;
	margin-right: 0.05em;
	margin-left: 0.05em;
	position: relative;
	// top: 0.15em;
	// /vertical-align: -0.18em;
}

.doc-para-internalLink svg{
	width: 18px;
	height: 18px;
	
	vertical-align: -0.16em;	
}

.doc-para-internalLink svg #box{	
  fill: var(--246gray);	
  fill: var(--primary25);	
}

.doc-para-internalLink svg #arrow{
	fill: var(--text600);
	fill: var(--primary400);	
}	


// Selected text styling
.doc-para-internalLinkContainer.ProseMirror-nodeInSelection:not(.ProseMirror-selectednode) .doc-para-internalLink svg #box{
	fill: var(--primary400);	
	opacity: 0.2;
}


// light styling
span.doc-span--style--light .doc-para-internalLink svg{
	opacity: 0.75;
}

span.doc-span--style--light .doc-para-internalLink:hover svg,
span.doc-span--style--light .doc-para-internalLinkContainer.ProseMirror-selectednode svg{
	opacity: 1;
}

span.doc-span--style--light .doc-para-internalLink svg #box{
	fill: var(--246gray);	
}

span.doc-span--style--light .doc-para-internalLink svg #arrow{
	fill: var(--text300);
}


[data-theme="dark"]{
	.doc-para-internalLink svg #box{	
	  fill: var(--240gray);	
	}

	.doc-para-internalLink svg #arrow{
		fill: var(--text300);
		opacity: 0.5;
	}	
}




.doc-para-internalLink-title{
	white-space: pre-wrap; 
	word-break: break-word;

	margin-right: 0.1em;
	margin-left: 0.2em;

	position: relative;
	//top: -0.08em;
	
	font-size: 16.5px;	

	font-weight: $lato-bold;
	letter-spacing: 0.005em;

	color: var(--text500);
	line-height: 1.45;

	border-bottom: 1px solid var(--220gray); 	
}

span.doc-span--style--light
.doc-para-internalLink-title{
	color: var(--text200);	
}

.doc-para-internalLink-title
span.doc-para-internalLink-title-dateTime{
	color: var(--text200);
	font-weight: $lato-semibold;
}

span.doc-span--style--light .doc-para-internalLink:hover .doc-para-internalLink-title,
span.doc-span--style--light .doc-para-internalLinkContainer.ProseMirror-selectednode .doc-para-internalLink-title{
	color: var(--text500); // turn back normal on hover
}


.doc-para-internalLink:hover  .doc-para-internalLink-title,
.doc-para-internalLinkContainer.ProseMirror-selectednode .doc-para-internalLink .doc-para-internalLink-title,
.doc-para-internalLinkContainer.ProseMirror-selectednode .doc-para-internalLink:hover .doc-para-internalLink-title{
	border-bottom: 1px solid var(--210gray); 	
}





// span.doc-span--style--light
// .doc-para-internalLink-innerIconContainer svg #core{
// 	fill: var(--text200)
// }

// span.doc-span--style--light
// .doc-para-internalLink:hover
// .doc-para-internalLink-innerIconContainer svg #core{
// 	fill: var(--text600)
// }




// [data-theme="dark"] {
// 	// to do - make dark mode blue
// 	.doc-para-internalLink-iconContainer--noBoard-inner{
// 		background: var(--240gray);
// 	}
// 	.doc-para-internalLink-iconContainer--noBoard-inner svg{
// 		fill: var(--text300);
// 		opacity: 0.5;
// 	}

// }


//
