.projectsPage-sideControls{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.projectsPage-sideControls-controlGroup{
	margin-bottom: 14px;
}

//

.projectsPage-sideControls-btn{
	height: 28px;
	display: flex;
	align-items: center;	
	justify-content: flex-start;

	border-radius: 3px;
	// background: $tempr;

	padding-left: 4px;

	width: 210px;
}

.projectsPage-sideControls-btn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 28px;
	width: 24px;

	margin-right: 4px;
}

.projectsPage-sideControls-btn-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.8;
}
.projectsPage-sideControls-btn-iconContainer svg.icon--name--sortManual{
	transform: scale(0.95);
	transform-origin: center center;
}

.projectsPage-sideControls-btn-iconContainer svg.icon--name--sortAZ{
	width: 16px;
	height: 16px;
}

.projectsPage-sideControls-btn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
}

//
// INFO 

.projectsPage-sideControls-btn-infoIconContainer{
	width: 28px;
	height: 28px;

	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: auto;
}

.projectsPage-sideControls-btn-infoIconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);

	opacity: 0;
}

.projectsPage-sideControls-btn--active
.projectsPage-sideControls-btn-infoIconContainer svg{
	opacity: 0.5;
}

.projectsPage-sideControls-btn--inactive:hover
.projectsPage-sideControls-btn-infoIconContainer svg{
	opacity: 0.5;
}

.projectsPage-sideControls-btn-infoIconContainer:hover svg{
	opacity: 0.8
}

//

.projectsPage-sideControls-btn--active{
	background: var(--253gray);
	opacity: 1;
}

.projectsPage-sideControls-btn--inactive{
	//background: var(--250gray);
	opacity: 0.5;
}

.projectsPage-sideControls-btn--inactive:hover{
	background: var(--253gray);
	opacity: 0.8;
}

