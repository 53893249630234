
.todoItem-assigned-assigneeList{
	// background: $tempb;
	width: 100%;
	height: 100%;
	transform-origin: 100% 50%;
}

.todoItem-assigned-user{
	width: 22px;
	height: 22px;	

	position: absolute;
	right: 0px;
	top: 2px;

}

.todoItem-assigned-user-avatarContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	border-radius: 7px;
	border: 2px solid var(--bg-docEditor);
}

.todoItem-assigned-user-avatar{
	width: 100%;
	height: 100%;
	border-radius: 5px;
	transform-origin: 50% 50%;
}

.todoItem-assigned-user.todoItem-assigned-user--showAvatar{
	z-index: 20;
}

.todoItem-assigned-user--index--1.todoItem-assigned-user--showAvatar{
	z-index: 19;
}

.todoItem-assigned-user--index--2.todoItem-assigned-user--showAvatar{
	z-index: 18;
}

.todoItem-assigned-user--index--3.todoItem-assigned-user--showAvatar{
	z-index: 17;
}

.todoItem-assigned-user--index--4.todoItem-assigned-user--showAvatar{
	z-index: 16;
}

.todoItem-assigned-user--index--5.todoItem-assigned-user--showAvatar{
	z-index: 15;
}

.todoItem-assigned-user--index--4.todoItem-assigned-user--showAvatar{
	z-index: 14;
}


.todoItem-assigned-user.todoItem-assigned-user--hideAvatar{
	z-index: 10;
}

.todoItem-assigned-user--index--1.todoItem-assigned-user--hideAvatar{
	z-index: 9;
}

.todoItem-assigned-user--index--2.todoItem-assigned-user--hideAvatar{
	z-index: 8;
}

.todoItem-assigned-user--index--3.todoItem-assigned-user--hideAvatar{
	z-index: 7;
}

.todoItem-assigned-user--index--4.todoItem-assigned-user--hideAvatar{
	z-index: 6;
}

.todoItem-assigned-user--index--5.todoItem-assigned-user--hideAvatar{
	z-index: 5;
}

.todoItem-assigned-user--index--4.todoItem-assigned-user--hideAvatar{
	z-index: 4;
}

// .todoItem-assigned-user--hideAvatar{
// 	z-index: 0;
// 	pointer-events: none;
// }


.todoItem-assigned-user-tooltip{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -24px;

	opacity: 0;
	transition: opacity 50ms linear;


	padding-top: 2px;
	padding-bottom: 2px;

	visibility: hidden;
	transition: opacity 50ms linear;
	transition-delay: 0ms;

	z-index: 200;

	pointer-events: none;
}


.todoItem-assigned-assigneeList:hover 
.todoItem-assigned-user-tooltip{
	opacity: 1;
	transition: opacity 70ms linear;
	transition-delay: 300ms;
}

.todoItem-assigned-assigneeList:hover 
.todoItem-assigned-user:hover
.todoItem-assigned-user-tooltip{
	visibility: visible;
}