
.doc-paragraph-selection--collab{
	//background: $tempr;
	// stolen from doc.scss
	padding-left: 0.05em;
	margin-left: -0.05em;
	padding-right: 0.05em;
	margin-right: -0.05em;
	padding-top: 0.05em;
	padding-bottom: 0.2em;
	border-radius: 3px;
}

span.doc-paragraph-selection--collab.comment{
	padding-bottom: 0.2em;
	padding-left: 0px;
	margin-left: 0px;
	padding-right: 0px;
	margin-right: 0px;
}

@import 'Doc-RealTimeCollaboration-SelectionColors';

//


span.doc-collabCursorFlag{
	position: relative;
	padding-left: 1px;
	padding-right: 2px;
	margin-right: -2px;	
	margin-left: -1px;
	padding-top: 4px;

	left: 0px;
	background: $red;
	border-radius: 1px;

	user-select: none !important;
}

.doc-collabCursorFlag span{
	position: absolute;

	width: auto;

	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.018em;
	color: $white;

	padding-left: 0.5em;
	padding-right: 0.5em;
	padding-top: 0.04em;
	padding-bottom: 0.18em;

	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;

	top: -1.08em;
	left: 0em;

	white-space: nowrap;
}

.doc-collabCursorFlag.doc-collabCursorFlag--minimalFlag span{
	width: 6px;
	height: 6px;

	padding: 0px;

	position: absolute;
	top: 0px;
	left: 0px;

	border-radius: 1px;

}


span.doc-collabCursorFlag--userColor--teal,
span.doc-collabCursorFlag--userColor--teal span{
	//background: darken($accentColor-teal, 5%);	
	background: var(--accentTeal600);
}

span.doc-collabCursorFlag--userColor--blue,
span.doc-collabCursorFlag--userColor--blue span{	
	//background: darken($accentColor-blue, 5%);	
	background: var(--accentBlue600);
}

span.doc-collabCursorFlag--userColor--purple,
span.doc-collabCursorFlag--userColor--purple span{	
	//background: darken($accentColor-purple, 5%);	
	background: var(--accentPurple600);
}

span.doc-collabCursorFlag--userColor--red,
span.doc-collabCursorFlag--userColor--red span{		
	//background: darken($accentColor-red, 5%);
	background: var(--accentRed600);
}

span.doc-collabCursorFlag--userColor--orange,
span.doc-collabCursorFlag--userColor--orange span{		
	//background: darken($accentColor-orange, 20%);	
	background: var(--accentOrange600);
}

span.doc-collabCursorFlag--userColor--green,
span.doc-collabCursorFlag--userColor--green span{			
	//background: darken($accentColor-green, 5%);	
	background: var(--accentGreen600);
}

span.doc-collabCursorFlag--userColor--sky,
span.doc-collabCursorFlag--userColor--sky span{			
	//background: darken($accentColor-sky, 5%);	
	background: var(--accentSky600);
}

span.doc-collabCursorFlag--userColor--pink,
span.doc-collabCursorFlag--userColor--pink span{		
	//background: darken($accentColor-pink, 5%);	
	background: var(--accentPink600);
}



// COLLAB CURSOR TESTING
// .doc-listItem:nth-child(1) .doc-para-span{
// 	background: var(--accentPurpleSelection);
// }

// .doc-listItem:nth-child(2) .doc-para-span{
// 	background: var(--accentRedSelection);
// }

// .doc-listItem:nth-child(3) .doc-para-span{
// 	background: var(--accentBlueSelection);
// }

// .doc-listItem:nth-child(4) .doc-para-span{
// 	background: var(--accentOrangeSelection);
// }

// .doc-listItem:nth-child(5) .doc-para-span{
// 	background: var(--accentGreenSelection);
// }

// .doc-listItem:nth-child(6) .doc-para-span{
// 	background: var(--accentSkySelection);
// }

// .doc-listItem:nth-child(7) .doc-para-span{
// 	background: var(--accentPinkSelection);
// }

// .doc-listItem:nth-child(8) .doc-para-span{
// 	background: var(--accentTealSelection);
// }

// .doc-listItem:nth-child(9) .doc-para-span{
// 	background: var(--accentGreySelection);
// }
