.docEditor-projectDescriptionContainer{
	width: 100%;

	margin-top: -18px;
	margin-bottom: 12px;
	cursor: default;

	position: relative;
	z-index: 10;
}

.docEditor-projectDescriptionContainer--add{
	margin-top: -10px;
}



.docEditor-projectDescription{
	width: calc(100% + 24px);
	margin-left: -12px;

	// width: 100%;
	// margin-left: 0px;
	
	//width: 100%;

	box-sizing: border-box;
	
	padding-left: 12px;
	padding-right: 12px;

	margin-top: 4px;
	margin-bottom: 4px;
	padding-top: 8px;
	padding-bottom: 8px;

	

	//border-bottom: 1px solid var(--line200);

	// background: var(--253gray);

	
	//transition: asdbackground 50ms linear;	

	display: flex;
	align-items: flex-start;

	opacity: 0.65;

	cursor: pointer;
	position: relative;
	transition: opacity 75ms linear;
}




.docEditor-projectDescriptionContainer--add
.docEditor-projectDescription{
	//height: 70px;
	opacity: 0.7;
}


.docEditor-projectDescription:hover{
	opacity: 0.8;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}



.docEditor-projectDescription-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 4px;

	background: var(--253gray);
	opacity: 0;
	transition: opacity 75ms linear;

	// opacity: 1;
	// background: $tempr;
}

.docEditor-projectDescription:hover
.docEditor-projectDescription-bg{
	opacity: 1;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}

.docEditor-projectDescriptionContainer--add
.docEditor-projectDescription-bg{
	opacity: 1;
	background: var(--250gray);
	// background: var(--primary50);
}


.docEditor-projectDescription-header{
	display: flex;
	align-items: center;
	margin-right: 4px;
	margin-top: 4px;
	// margin-bottom: 10px;

}


.docEditor-projectDescription .docEditor-projectDescription-textContainer{
	display: flex;
	position: relative;
	z-index: 5;
}


.docEditor-projectDescription .docEditor-projectDescription-textContainer-iconContainer{
	width: 24px;
	height: 24px;	

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.docEditor-projectDescription .docEditor-projectDescription-textContainer-iconContainer svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.6;
}

.docEditor-projectDescription .docEditor-projectDescription-textContainer-label{
	// font-size: 17.5px;
	// font-weight: $lato-regular;
	// color: var(--text500);
	// line-height: 1.35;
	// letter-spacing: 0.01em;
	//font-size: 16.5px;	
	font-size: 17.5px;		
	font-weight: $lato-regular;
	font-weight: $lato-medium;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.45;

	margin-block-start: 0em;
	margin-block-end: 0em;

	

}

// button

.docEditor-projectDescription-addDescriptionBtnContainer{
	margin-bottom: 9px;
	margin-top: -7px;
}

.docEditor-projectDescription-addDescriptionBtn{
	display: flex;

	height: 32px;
	position: relative;
	z-index: 10;

	margin-left: -6px; // optical to icon
	padding-left: 5px;
	
	padding-right: 12px;

	border-radius: 5px;

	margin-top: 6px;

	opacity: 0.7;	
	transition-delay: 200ms;
	transition: opacity 100ms;
}

.docEditor-projectDescription-addDescriptionBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms;
	background: var(--253gray);
}

.docEditor-projectDescription-addDescriptionBtn-iconContainer{
	margin-right: 3.5px;	

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;
}

.docEditor-projectDescription-addDescriptionBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.8;
	position: relative;
}

.docEditor-projectDescription-addDescriptionBtn-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text500);
}



// .docEditor-projectDescription-header-avatarContainer{
// 	width: 26px;
// 	height: 26px;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	padding-right: 8px;
// }

// .docEditor-projectDescription-header-avatar{
// 	width: 26px;
// 	height: 26px;
// 	object-fit: cover;
// 	border-radius: 5px;
// }

// .docEditor-projectDescription-header-label{
// 	font-size: 16px;
// 	font-weight: $lato-semibold;
// 	color: var(--text600);
// 	opacity: 0.5;
// }

// .docEditor-projectDescription:hover{
// 	// background: var(--250gray);
// }

// .docEditor-projectDescription-textContainer-title{
// 	color: var(--text500);

// 	font-size: 12.5px;
// 	font-weight: $lato-semibold;
// 	text-transform: uppercase;
// 	letter-spacing: 0.025em;
// 	opacity: 0.5;
// 	font-size: 12px;

// 	margin-bottom: 4px;

// }
