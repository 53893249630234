// DOC PREVIEW
.libraryDocPreviewContainer{
	position: fixed;
	// top: 120px;
	bottom: 26px; // for org home-page, makes more sense if fixed to bottom
	right: 11px;

	height: 520px;
	width: 400px;
	
	z-index: 20;

	pointer-events: none;

}

.libraryDocPreviewContainer--show{
	transform: translateX(-15px);
	opacity: 1;
	transition: 100ms linear;
	transition-delay: 0ms;
}

.libraryDocPreviewContainer--hide{
	transform: translateX(0px);
	opacity: 0;
	transition: 100ms linear;
	transition-delay: 0ms;
}

.libraryDocPreview{
	width: 100%;
	height: 100%;
	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);

	border-radius: 5px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	overflow: hidden;
	position: relative;
}

.libraryDocPreview-docContainer{
	width: 320px;
	padding-top: 34px;
}

.libraryDocPreview-doc{
	width: 650px;
	// background: $tempr;

	transform: scale(0.49);
	transform-origin: top left;
}

.libraryDocPreview-doc-innerWrapper{
	width: $textWidth-doc;
}

.libraryDocPreview-bottomGradient{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 100px;
	//background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.66) 33%, rgba(255,255,255,1) 100%);

  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.013) 8.1%,
    hsla(0, 0%, 100%, 0.049) 15.5%,
    hsla(0, 0%, 100%, 0.104) 22.5%,
    hsla(0, 0%, 100%, 0.175) 29%,
    hsla(0, 0%, 100%, 0.259) 35.3%,
    hsla(0, 0%, 100%, 0.352) 41.2%,
    hsla(0, 0%, 100%, 0.45) 47.1%,
    hsla(0, 0%, 100%, 0.55) 52.9%,
    hsla(0, 0%, 100%, 0.648) 58.8%,
    hsla(0, 0%, 100%, 0.741) 64.7%,
    hsla(0, 0%, 100%, 0.825) 71%,
    hsla(0, 0%, 100%, 0.896) 77.5%,
    hsla(0, 0%, 100%, 0.951) 84.5%,
    hsla(0, 0%, 100%, 0.987) 91.9%,
    hsl(0, 0%, 100%) 100%
  );

	z-index: 10;

}

[data-theme="dark"] .libraryDocPreview-bottomGradient{
	background: linear-gradient(
    to bottom,
    rgba(36,31,38, 0) 0%,
    rgba(36,31,38, 0.013) 8.1%,
    rgba(36,31,38, 0.049) 15.5%,
    rgba(36,31,38, 0.104) 22.5%,
    rgba(36,31,38, 0.175) 29%,
    rgba(36,31,38, 0.259) 35.3%,
    rgba(36,31,38, 0.352) 41.2%,
    rgba(36,31,38, 0.45) 47.1%,
    rgba(36,31,38, 0.55) 52.9%,
    rgba(36,31,38, 0.648) 58.8%,
    rgba(36,31,38, 0.741) 64.7%,
    rgba(36,31,38, 0.825) 71%,
    rgba(36,31,38, 0.896) 77.5%,
    rgba(36,31,38, 0.951) 84.5%,
    rgba(36,31,38, 0.987) 91.9%,
    rgba(36,31,38, 1) 100%
  );

}