.projectsPage-header{
	

	width: 100%; // to cover up rows
	// background: $tempr;
	display: flex;
	flex-direction: column;

	position: relative;
}

.projectsPage-header-topBar{
	width: 100%;
	// background: $tempr;

	height: $height-docEditor-topMenu;

	display: flex;
	align-items: center;	

	// position: relative;
	// z-index: 5; // above drag bar
	-webkit-app-region: drag;
}

.projectsPage-header-leftDragRegion{
	// bit dirty
	position: absolute;
	right: 100%;
	width: 500px;	
	height: $height-docEditor-topMenu;
	-webkit-app-region: drag;
}

.projectsPage-header-rightDragRegion{
	// bit dirty
	position: absolute;
	left: 100%;
	width: 500px;	
	height: $height-docEditor-topMenu;
	-webkit-app-region: drag;
}

@import 'Projects-Header-SelectRoadmap';

.projectsPage-header-titleBar{
	//padding-top: 84px;
	padding-top: 72px;
	display: flex;
	align-items: flex-end;
	margin-bottom: 4px;
}


.projectsPage-header-titleBar-title{	
	font-size: 39px;
	//font-size: 36px;
	font-weight: $lato-black;
	color: var(--text600);
	letter-spacing: 0.001em;

	cursor: default;
	user-select: none;
}

[data-theme="dark"] {
	.projectsPage-header-titleBar-title{
		font-weight: $lato-heavy;
		letter-spacing: 0.005em;
	}
}

.projectsPage-header-titleBar-rightContainer{
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.projectsPage-header-titleBar-btn{
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	border-radius: 6px;

	opacity: 0.6;
}

.projectsPage-header-titleBar-btn:hover{
	opacity: 1;
	background: var(--250gray);
}

.projectsPage-header-titleBar-btn svg{
	width: 22px;
	height: 22px;
	fill: var(--text500);

	opacity: 0.8;
}

.projectsPage-header-descriptionContainer{
	padding-top: 6px;
	font-size: 17px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.65;
	padding-bottom: 4px;
}