//
// ACCENT COLORS
//

// TO START, 500 is default
// 600 is 5% darken
// 400 is 85% opacity
// 300 is 70% opacity
// 200 is 60% opacity
// 150 is 40% opacity
// 100 is 30% opacity
// 50 is 10% opacity

:root{

	// PURPLE
	--accentPurple700: hsl(276, 60%, 59%); // text	
	--accentPurple600: hsl(276, 60%, 62%); // text	
	--accentPurple500: hsl(276, 60%, 67%); // default	
	--accentPurple400: hsl(276, 60%, 72%); 
	--accentPurple300: hsl(276, 60%, 77%); 
	--accentPurple200: hsl(276, 60%, 80%); 
	--accentPurple150: hsl(276, 59%, 87%); 
	--accentPurple100: hsl(276, 60%, 90%); 
	--accentPurple75: hsl(278, 65%, 94%);
	--accentPurple50: hsl(278, 65%, 97%);
	--accentPurple25: hsl(278, 65%, 98%); 
	
	// RED
	--accentRed700: hsl(359, 83%, 62%); // text
	--accentRed600: hsl(359, 83%, 68%); // text
	--accentRed500: hsl(358, 83%, 73%); // default
	--accentRed400: hsl(358, 83%, 77%); 
	--accentRed300: hsl(359, 84%, 81%); 
	--accentRed200: hsl(358, 83%, 84%); 
	--accentRed150: hsl(359, 82%, 89%); 
	--accentRed100: hsl(358, 85%, 92%); 
	--accentRed75: hsl(0, 86%, 94%); 
	--accentRed50: hsl(0, 86%, 97%); 
	--accentRed25: hsl(0, 86%, 98%);

	// BLUE
	--accentBlue700: hsl(210, 93%, 44%); // text
	--accentBlue600: hsl(210, 94%, 54%); // text
	--accentBlue500: hsl(210, 94%, 59%); // default
	--accentBlue400: hsl(210, 94%, 65%); 
	--accentBlue300: hsl(209, 94%, 72%); 
	--accentBlue200: hsl(209, 94%, 75%); 
	--accentBlue150: hsl(210, 95%, 84%); 
	--accentBlue100: hsl(210, 94%, 88%); 
	--accentBlue75: hsl(208, 90%, 93%); 
	--accentBlue50: hsl(208, 90%, 96%); 
	--accentBlue25: hsl(208, 90%, 98%); 

	// ORANGE
	--accentOrange700: hsl(24, 100%, 63%); // text
	--accentOrange600: hsl(24, 100%, 67%); // text
	--accentOrange500: hsl(24, 100%, 72%); // default
	--accentOrange400: hsl(25, 100%, 76%); 
	--accentOrange300: hsl(24, 100%, 80%); 
	--accentOrange200: hsl(24, 100%, 83%); 
	--accentOrange150: hsl(24, 100%, 89%); 
	--accentOrange100: hsl(25, 100%, 92%); 
	--accentOrange75: hsl(26, 100%, 94%);
	--accentOrange50: hsl(26, 100%, 97%);
	--accentOrange25: hsl(26, 100%, 98%); 

	// GREEN
	--accentGreen800: hsl(163, 61%, 24%); // text
	--accentGreen700: hsl(163, 61%, 39%); // text
	--accentGreen600: hsl(163, 61%, 44%); // text
	--accentGreen500: hsl(163, 61%, 49%); // default
	--accentGreen400: hsl(163, 58%, 57%); 
	--accentGreen300: hsl(162, 58%, 64%); 
	--accentGreen200: hsl(162, 59%, 69%); 
	--accentGreen150: hsl(163, 58%, 80%); 
	--accentGreen100: hsl(163, 59%, 85%); 
	--accentGreen75: hsl(161, 62%, 90%); 
	--accentGreen50: hsl(161, 62%, 95%); 
	--accentGreen25: hsl(161, 62%, 97%); 

	// SKY
	--accentSky700: hsl(198, 93%, 56%); // text
	--accentSky600: hsl(198, 93%, 61%); // text
	--accentSky500: hsl(199, 93%, 66%); // default
	--accentSky400: hsl(199, 93%, 71%); 
	--accentSky300: hsl(199, 93%, 76%); 
	--accentSky200: hsl(199, 92%, 80%); 
	--accentSky150: hsl(198, 94%, 86%); 
	--accentSky100: hsl(199, 92%, 90%); 
	--accentSky75: hsl(199, 89%, 93%); 
	--accentSky50: hsl(199, 89%, 96%); 
	--accentSky25: hsl(199, 89%, 98%); 

	// PINK
	--accentPink700: hsl(327, 86%, 66%); // text
	--accentPink600: hsl(327, 86%, 72%); // text
	--accentPink500: hsl(327, 86%, 77%); // default
	--accentPink400: hsl(327, 86%, 80%); 
	--accentPink300: hsl(327, 86%, 84%); 
	--accentPink200: hsl(327, 86%, 86%); 
	--accentPink150: hsl(328, 87%, 91%); 
	--accentPink100: hsl(327, 89%, 93%); 
	--accentPink75: hsl(324, 83%, 95%); 
	--accentPink50: hsl(324, 83%, 98%); 
	--accentPink25: hsl(324, 83%, 99%); 

	// TEAL
	--accentTeal700: hsl(184, 100%, 30%); // text
	--accentTeal600: hsl(184, 100%, 35%); // text
	--accentTeal500: hsl(184, 100%, 40%); // default
	--accentTeal400: hsl(184, 70%, 49%);
	--accentTeal300: hsl(184, 67%, 58%);
	--accentTeal200: hsl(184, 67%, 64%);
	--accentTeal150: hsl(184, 67%, 76%); 
	--accentTeal100: hsl(184, 67%, 82%); 
	--accentTeal75: hsl(183, 68%, 90%); 
	--accentTeal50: hsl(183, 68%, 94%); 
	--accentTeal25: hsl(183, 68%, 98%); 

	// GREY
	--accentGrey700: hsl(236, 6%, 42%); // text
	--accentGrey600: hsl(236, 6%, 48%); // text
	--accentGrey500: hsl(236, 6%, 53%); // default
	--accentGrey400: hsl(235, 6%, 60%);
	--accentGrey300: hsl(234, 6%, 67%);
	--accentGrey200: hsl(240, 6%, 72%);
	--accentGrey150: hsl(230, 6%, 81%); 
	--accentGrey100: hsl(240, 6%, 86%); 
	--accentGrey75: hsl(240, 8%, 91%); 
	--accentGrey50: hsl(240, 8%, 95%); 
	--accentGrey25: hsl(240, 8%, 98%);
}

//
// DARK THEME
// TO DO

[data-theme="dark"], .multiPlaygroundSelection--theme--dark{

	// maybe bump numbers by a bit, not sure if scale inverts

	// PURPLE
	--accentPurple700: 	hsl(273, 100%, 84%); // text	
	--accentPurple600: 	hsl(276, 60%, 62%); // text	
	// --accentPurple500: 	hsl(276, 60%, 87%); // default
	// --accentPurple400: 	hsl(276, 60%, 72%); 
	// --accentPurple300: 	hsl(276, 60%, 77%); 
	// --accentPurple200: 	hsl(276, 60%, 80%); 
	--accentPurple150: 	hsl(276, 59%, 87%); 
	// --accentPurple100: 	hsl(276, 60%, 90%); 
	--accentPurple50: 	hsl(269, 34%, 24%); 
	--accentPurpleSelection: 	hsl(273, 40%, 22%); // text	

	


	// RED
	--accentRed700: hsl(359, 83%, 68%); // text
	--accentRed600: hsl(359, 83%, 68%); // text
	// --accentRed500: hsl(358, 83%, 73%); // default
	// --accentRed400: hsl(358, 83%, 77%); 
	// --accentRed300: hsl(359, 84%, 81%); 
	// --accentRed200: hsl(358, 83%, 84%); 
	--accentRed150: hsl(359, 82%, 89%); 
	// --accentRed100: hsl(358, 85%, 92%); 
	--accentRed50: hsl(0, 32%, 24%); 
	--accentRedSelection: hsl(0, 40%, 22%); // text	



	// BLUE
	--accentBlue700: hsl(221, 100%, 72%); // text
	--accentBlue600: hsl(210, 94%, 54%); // text
	// --accentBlue500: hsl(210, 94%, 59%); // default
	// --accentBlue400: hsl(210, 94%, 65%); 
	// --accentBlue300: hsl(209, 94%, 72%); 
	// --accentBlue200: hsl(209, 94%, 75%); 
	--accentBlue150: hsl(210, 95%, 84%); 
	// --accentBlue100: hsl(210, 94%, 88%); 
	--accentBlue50: hsl(221, 28%, 22%); 
	--accentBlueSelection: hsl(221, 49%, 25%); 



	// ORANGE
	--accentOrange700: hsl(25, 98%, 79%); // text
	--accentOrange600: hsl(24, 85%, 50%); // text
	// --accentOrange500: hsl(24, 100%, 72%); // default
	// --accentOrange400: hsl(25, 100%, 76%); 
	// --accentOrange300: hsl(24, 100%, 80%); 
	// --accentOrange200: hsl(24, 100%, 83%); 
	--accentOrange150: hsl(24, 100%, 89%); 
	// --accentOrange100: hsl(25, 100%, 92%); 
	--accentOrange50: hsl(16, 28%, 22%); 
	--accentOrangeSelection: hsl(25, 86%, 16%); // text



	// GREEN
	--accentGreen700: hsl(163, 61%, 44%); // text
	--accentGreen600: hsl(163, 61%, 38%); // text
	// --accentGreen500: hsl(163, 61%, 49%); // default
	// --accentGreen400: hsl(163, 58%, 57%); 
	// --accentGreen300: hsl(162, 58%, 64%); 
	// --accentGreen200: hsl(162, 59%, 69%); 
	--accentGreen150: hsl(163, 58%, 80%); 
	// --accentGreen100: hsl(163, 59%, 85%); 
	--accentGreen75: hsl(161, 62%, 90%); 
	--accentGreen50: hsl(161, 25%, 18%); 
	--accentGreen25: #002109;
	--accentGreenSelection: hsla(136, 43%, 16%); // text


	// SKY
	--accentSky700: hsl(198, 93%, 61%); // text
	--accentSky600: hsl(198, 93%, 61%); // text
	// --accentSky500: hsl(199, 93%, 66%); // default
	// --accentSky400: hsl(199, 93%, 71%); 
	// --accentSky300: hsl(199, 93%, 76%); 
	// --accentSky200: hsl(199, 92%, 80%); 
	--accentSky150: hsl(198, 94%, 86%); 
	// --accentSky100: hsl(199, 92%, 90%); 
	--accentSky50: hsl(199, 25%, 25%); 
	--accentSkySelection: hsla(199, 78%, 19%); // text



	// PINK
	--accentPink700: hsl(327, 86%, 72%); // text
	--accentPink600: hsl(327, 86%, 72%); // text
	// --accentPink500: hsl(327, 86%, 77%); // default
	// --accentPink400: hsl(327, 86%, 80%); 
	// --accentPink300: hsl(327, 86%, 84%); 
	// --accentPink200: hsl(327, 86%, 86%); 
	--accentPink150: hsl(328, 87%, 91%); 
	// --accentPink100: hsl(327, 89%, 93%); 
	--accentPink50: hsl(324, 25%, 25%); 
	--accentPinkSelection: hsl(327, 38%, 24%); // text


	// TEAL
	--accentTeal700: hsl(184, 100%, 35%); // text
	--accentTeal600: hsl(184, 100%, 35%); // text
	// --accentTeal500: hsl(184, 100%, 40%); // default
	// --accentTeal400: hsl(184, 70%, 49%);
	// --accentTeal300: hsl(184, 67%, 58%);
	// --accentTeal200: hsl(184, 67%, 64%);
	--accentTeal150: hsl(184, 67%, 76%); 
	// --accentTeal100: hsl(184, 67%, 82%); 
	--accentTeal50: hsl(183, 25%, 25%); 
	--accentTealSelection: hsl(183, 68%, 16%); 

	// GREY
	--accentGrey700: hsl(236, 6%, 48%); // text
	--accentGrey600: hsl(236, 6%, 48%); // text
	// --accentGrey500: hsl(236, 6%, 53%); // default
	// --accentGrey400: hsl(235, 6%, 60%);
	// --accentGrey300: hsl(234, 6%, 67%);
	// --accentGrey200: hsl(240, 6%, 72%);
	--accentGrey150: hsl(230, 6%, 81%); 
	// --accentGrey100: hsl(240, 6%, 86%); 
	--accentGrey50: hsl(240, 8%, 25%); 
	--accentGreySelection: hsl(240, 8%, 20%);



}