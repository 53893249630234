// .dndPageContainer{
// 	width: 100vw;
// 	height: 100vh;
// 	background: white;
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	z-index: 1000000;
// }

// .dndSideMenu{
// 	width: 230px;
// 	height: 100%;
// 	background: rgb(244,242,244);
// 	border-right: 1px solid rgb(220,220,220);

// 	display: flex;
// 	flex-direction: column;
// }

// .dndSideMenu-header{
// 	width: 100%;
// 	height: 100px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// 	// background: rgba(0,0,0,0.02);
// }

// .dndSideMenu-footer{
// 	width: 100%;
// 	height: 200px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// 	// background: rgba(0,0,0,0.02);
// }

// .dndSideMenu-tabList{
// 	// flex-grow: 1;
// 	// flex-shrink: 1;
// 	height: 600px;
// 	background: rgba(0,0,0,0.04);
// 	width: 100%;
// 	box-sizing: border-box;
// 	padding-left: 4px;
// 	padding-right: 4px;
// 	//background: $tempg;
// }

//

@import 'DNDTest-Tab';
@import 'DNDTest-TabGroupHeader';


.dndSideMenu-tabList-tabGroup{
	width: 100%;
	// padding-top: 14px;
	min-height: 50px;

	padding-top: 14px;

}

.dndSideMenu-tabList-tabGroup-tabList{
	min-height: 30px;
	//background: $tempr;
}

.dndSideMenu-tabList-indentTabGroup{
	padding-top: 24px;
}


.dndSideMenu-tabList-tabGroup{

}
