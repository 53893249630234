.meetingParticipantListBtn{
	display: flex;
	align-items: center;

	height: 26px;
	border: 1px solid var(--line300);
	border-radius: 4px;

	padding-left: 5px;
	padding-right: 8px;

	cursor: pointer;

	position: relative;
}

.meetingParticipantListBtn:hover{
	border: 1px solid var(--line400);
}

.meetingParticipantListBtn--tagOnly{
	// cursor: default;
	border: 1px solid $invisible;
}

.meetingParticipantListBtn--tagOnly:hover{
	border: 1px solid var(--line200);
}

.meetingParticipantListBtn--view{
	border: 1px solid $invisible;
	background: var(--253gray);
}

.meetingParticipantListBtn--view:hover{
	border: 1px solid $invisible;
	background: var(--253gray);	
}

//

.meetingParticipantListBtn--edit[data-state="open"]{
	border: 1px solid var(--line500);
}

.meetingParticipantListBtn[data-state="open"]{
	pointer-events: none;
}


.forceDarkTheme,
[data-theme="dark"]{
	.meetingParticipantListBtn{
		border: 1px solid var(--line150);
		border: 1px solid $invisible;
		background: var(--253gray);
	}
	.meetingParticipantListBtn:hover{
		border: 1px solid var(--line200);
		border: 1px solid $invisible;
		background: var(--250gray);
	}
	.meetingParticipantListBtn[data-state="open"]{
		background: var(--248gray);	
	}
}



.meetingParticipantListBtn-tooltipContainer{
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);

	margin-top: 5px;
	width: 120px;

	pointer-events: none;

	opacity: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.meetingParticipantListBtn:hover
.meetingParticipantListBtn-tooltipContainer{
	opacity: 1;
}

.tooltip--meetingParticipantListBtn{  
  max-width: 120px;
  white-space: unset;
  text-align: center;
  padding-bottom: 5px;
}

.meetingParticipantListBtn-tooltipContainer--containsEmail{
	width: 200px;
}

.meetingParticipantListBtn-tooltipContainer--containsEmail
.tooltip--meetingParticipantListBtn{
	max-width: 200px;
}


//

.meetingParticipantListBtn-avatarContainer{
	width: 20px;
	height: 20px;
	outline: 2px solid var(--bg-docEditor);
	margin-right: -7px;

	border-radius: 3px;

	position: relative;
}

.meetingParticipantListBtn--view
.meetingParticipantListBtn-avatarContainer{
	outline: 2px solid var(--253gray);
}

.meetingParticipantListBtn-avatarContainer:nth-child(1){
	z-index: 3;
}

.meetingParticipantListBtn-avatarContainer:nth-child(2){
	z-index: 2;
}

.meetingParticipantListBtn-avatarContainer:nth-child(3){
	z-index: 1;
}

.meetingParticipantListBtn-avatarContainer:last-child{
	margin-right: 0px;
}

.meetingParticipantListBtn-avatar{
	width: 20px;
	height: 20px;
	border-radius: 3px;
	cursor: pointer;
}


//


.meetingParticipantListBtn-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);
}

//

.meetingParticipantListBtn-count{
	margin-left: 13px;
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 0.5;	
}

.forceDarkTheme,
[data-theme="dark"]{
	.meetingParticipantListBtn-count{
		letter-spacing: 0.012em;
	}	
}


.meetingParticipantListBtn:hover
.meetingParticipantListBtn-count{
	opacity: 0.7;	
}

.meetingParticipantListBtn[data-state="open"]
.meetingParticipantListBtn-count{
	opacity: 1;
}


//

@import "ParticipantList-Popover.scss";