

.templateModal-list{
	width: 100%;
	height: 100%;

	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 40px;


}

.templateModal-listHeader{
	flex-grow: 0;
	flex-shrink: 0;
	
	height: 46px;

	width: 100%;

	display: flex;
	align-items: center;

	box-sizing: border-box;

	padding-left: 26px;
	padding-right: 24px;

	padding-top: 22px;
	margin-bottom: 4px;
}

.templateModal-listHeader-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
	margin-left: -4px;
}

.templateModal-listHeader-iconContainer svg{
	width: 24px;
	height: 24px;
	// fill: var(--primary500);
	fill: var(--text500);
	opacity: 0.8;
}

[data-theme="dark"]{
	.templateModal-listHeader-iconContainer svg{
		opacity: 0.5;
	}
}

.templateModal-listHeader-label{
	font-size: 20px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;
	color: var(--text600);

	user-select: none;
	cursor: default;
}

//

.templateModal-listHeader-archiveBtn{
	margin-left: auto;
	height: 30px;	
	padding-right: 14px;
	padding-left: 14px;
	border-radius: 4px;

	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.010em;

	background: var(--text400);
	border: 1px solid var(--text500);

	color: white;

}

.templateModal-listHeader-archiveBtn:hover{
	background: var(--text500);
	background: var(--text600);
}




// .templateModal-listHeader-archiveBtn svg{
// 	fill: var(--text500);
// 	width: 20px;
// 	height: 20px;

// 	opacity: 0.5;
// }

// .templateModal-listHeader-archiveBtn:hover svg{
// 	opacity: 1;
// }

// .templateModal-listHeader-archiveBtn[data-state="active"],
// .templateModal-listHeader-archiveBtn[data-state="active"]:hover{
// 	opacity: 1;
// 	fill: var(--text600);
// }

// .templateModal-listHeader-showArchiveBtn{
// 	height: 32px;
// 	padding-left: 14px;	
// 	padding-right: 14px;

// 	font-size: 14px;
// 	font-weight: $lato-regular;
// 	color: var(--text500);
// 	opacity: 0.75;

// 	margin-top: 4px;
// 	border-radius: 3px;
// }

//

.templateModal-listContainer-list{
	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;

	width: 100%;
	//max-width: 310px;
	box-sizing: border-box;
	padding-left: 10px;	
	padding-bottom: 32px;

	padding-top: 8px;

	
}


.templateModal-listHeader,
.templateModal-listContainer-list{
	@include poly-fluid-sizing('padding-right', (		
		1280px: 14px,		
		1366px: 14px,
		1440px: 16px,
		1680px: 16px,
		1800px: 24px,
		1920px: 24px,
		2560px: 24px)
	);
}


//

.templateModal-listContainer-list-header{
	
	box-sizing: border-box;
	padding-left: 13px;

	padding-top: 14px;
	margin-top: 12px;
	
	margin-bottom: 10px;

	position: relative;
	user-select: none;
	cursor: default;
}


.templateModal-listContainer-list-header-label{
	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-semibold;

	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.65;
}

//


//

.templateModal-listContainer-orgBtns{
	padding-bottom: 16px;
}

.templateModal-list-orgTemplateBtn{
	width: 100%;
	height: 30px;
	border-radius: 4px;

	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;	
}

.templateModal-list-orgTemplateBtn-avatarContainer{
	width: 22px;
	height: 22px;

	margin-left: 2px;

	margin-right: 9px;

	flex-grow: 0;
	flex-shrink: 0;
}

.templateModal-list-orgTemplateBtn-avatar{	
	border-radius: 3px;
}

.templateModal-list-orgTemplateBtn-title{
	font-size: 15.75px;
	color: var(--text600);
	font-weight: $lato-medium;

	  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.templateModal-list-orgTemplateBtn[data-state='inactive']:hover{
	background: var(--240gray);
}

.templateModal-list-orgTemplateBtn[data-state='active']{
	background: var(--235gray);
}

//

.templateModal-list-starterTemplateBtn{
	width: 100%;
	height: 30px;
	border-radius: 4px;

	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer{
	flex-grow: 0;
	flex-shrink: 0;

	width: 24px;
	height: 24px;
	border-radius: 2px;

	//background: var(--240gray);
	margin-right: 5px;
	margin-right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg{
	width: 22px;
	height: 22px;
	transform-origin: center center;
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer .meetingTypeIconContainer svg{
	width: 18px;
	height: 18px;
	transform-origin: center center;
}

//


.templateModal-list-starterTemplateBtn[data-state="inactive"]{

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg{		
		filter: grayscale(100%);
		opacity: 0.4;
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--figma path{
		fill: $white;
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--figma path{
		fill: black;
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--figma rect{
		fill: rgb(200,200,200);
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--linear,
	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--mixpanel,
	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--slack{
		filter: brightness(-100%);
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--stripe rect,
	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--pagerduty rect,
	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react circle{
		filter: brightness(-100%);
	}

	.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react path{
		filter: brightness(100%);
	}

}

[data-theme="dark"]{
	.templateModal-list-starterTemplateBtn[data-state="inactive"]{

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg{		
			filter: grayscale(100%);
			opacity: 0.4;
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--figma path{
			fill: $white;
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--linear,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--mixpanel,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--slack,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--square{
			filter: brightness(100%) grayscale(100%);			
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--square,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--yarn{
			filter: invert(100%);
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--stripe rect,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--pagerduty rect{
			filter: brightness(100%);
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react path{
			filter: brightness(100%);
		}

		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react circle{
			filter: brightness(0%);
		}
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react{
			// opacity: 0.6;
		}

	}
}

[data-theme="dark"]{
	.templateModal-list-starterTemplateBtn[data-state="active"]{
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--square,
		.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--yarn{
			filter: invert(100%);
		}
	}
}


//
// SIZE TWEAKS
	
.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--yarn{
	transform: scale(0.9);
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--figma{
	transform: scale(1.3);
}	

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--square{
	transform: scale(0.9);
}	



.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--mixpanel{
	transform: scale(0.95);
}	



.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--pagerduty path{
	transform: scale(0.95);
	transform-origin: center center;
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--react,
.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--amazon{
	transform: scale(0.98);
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--linear{
	transform: scale(0.97);
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--google{
	transform: scale(0.97);
}

.templateModal-list-starterTemplateBtn-sourceLogoContainer svg.companyIcon--slack{
	transform: scale(0.97);
}



// .templateModal-list-starterTemplateBtn-sourceLogoContainer svg path{
// 	fill: red !important;
// }

.templateModal-list-starterTemplateBtn-title{
	font-size: 15.75px;
	color: var(--text600);
	font-weight: $lato-medium;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	//font-weight: $lato-semibold;
}

.templateModal-list-starterTemplateBtn[data-state='inactive']:hover{
	background: var(--240gray);
}

.templateModal-list-starterTemplateBtn[data-state='active']{
	background: var(--235gray);
}

//

.templateModal-listContainer-emptyOrgContainer{
	position: relative;
	z-index: 50;
	width: calc(100% - 24px);
	margin-left: 14px;
	margin-top: 7px;
	margin-bottom: 6px;
	// margin-bottom: -10px;

	//background: $tempr;
}

.templateModal-listContainer-emptyOrg{
		
	width: 100%;
	height: 30px;
	height: 34px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;	

	opacity: 0.6;
	transition: opacity 100ms linear;

	cursor: default;
	user-select: none;

	cursor: help;

	//background: var(--240gray);
}



.templateModal-listContainer-emptyOrgContainer:hover
.templateModal-listContainer-emptyOrg{
	opacity: 0.3;
}


.templateModal-listContainer-emptyOrg-iconContainer{
	width: 22px;
	height: 22px;

	margin-left: 2px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.templateModal-listContainer-emptyOrg-iconContainer svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
}

.templateModal-listContainer-emptyOrg-title{
	font-size: 15.75px;
	color: var(--text600);
	font-weight: $lato-medium;

}

// .templateModal-list-orgTemplateBtn[data-state='inactive']:hover{
// 	background: var(--240gray);
// }

// .templateModal-list-orgTemplateBtn[data-state='active']{
// 	background: var(--235gray);
// }





//
//

.templateModal-list-helpContainer{
	
	padding-top: 10px;
	padding-bottom: 12px;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-right: 24px;
	padding-left: 10px;

	// display: flex;
	// align-items: center;
	// justify-content: flex-end;

	position: relative;
	
}

.templateModal-list-help{
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 8px;

	height: 34px;
	border-radius: 4px;

	opacity: 0.75;

	cursor: help;
	user-select: none;
}

.templateModal-list-help:hover{
	opacity: 0.5;	
	//background: var(--240gray);
}

.templateModal-list-helpPopover,
.templateModal-listContainer-emptyOrg-helpPopover{
	position: absolute;

	width: 400px;
	height: 300px;

	background: var(--bg-dropdown);

	border-radius: 6px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);

	z-index: 40;

	pointer-events: none;

	opacity: 0;
	
	transition: all 75ms;

	overflow: hidden;
}

.templateModal-list-helpPopover{
	left: 0px;
	bottom: 100%;
	margin-bottom: -8px;

	transform: translateY(5px) translateX(0px);
}

.templateModal-listContainer-emptyOrg-helpPopover{
	position: absolute;
	top: -40px;
	left: 100%;
	margin-left: -40px;

	transform: translateY(0px) translateX(-5px);

	z-index: 50;
}



.templateModal-list-help:hover + .templateModal-list-helpPopover,
.templateModal-listContainer-emptyOrgContainer:hover .templateModal-listContainer-emptyOrg-helpPopover{
	opacity: 1;
	transform: translateY(0px) translateX(0px);
	transition-delay: 200ms;
}

.templateModal-listContainer-emptyOrgContainer:hover .templateModal-listContainer-emptyOrg-helpPopover{
	transition-delay: 0ms;
}



.templateModal-list-help-iconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.templateModal-list-help-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.9;
}

.templateModal-list-help-label{
	font-size: 14.25px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;
}