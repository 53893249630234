

.doc-zoomedFigmaModal-statusContainer{
	width: 250px;
	position: fixed;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	left: 50%;
	transform: translateX(-50%);

	height: 200px;

	z-index: 200;

	user-select: none !important;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalStatus{
	width: 130px;
	background: rgba(255,255,255,0.08);
	height: 30px;
	margin-left: 4px;
	margin-right: 4px;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedFigmaModal-statusBtnWrapper--noStatus
.doc-zoomedImageModal-btn--figmaModalStatus{
	background: none;
	opacity: 0.5;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedFigmaModal-statusBtnWrapper--noStatus
.doc-zoomedImageModal-btn--figmaModalStatus:hover{	
	opacity: 1;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalStatus:hover{

}

.doc-zoomedFigmaModal-status-customIcon{
	transform: scale(0.95);
	margin-left: -5px;
	margin-right: 2px;
	position: relative;
	top: 0.5px;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalStatus
.doc-zoomedImageModal-btn-label{
	letter-spacing: 0.015em;
	font-weight: $lato-medium;
	opacity: 0.9;
}

.doc-zoomedImageModal-btn--figmaModalStatus
.doc-zoomedImageModal-btn-spotlight{
	width: 500px;
	height: 500px;
	margin-top: -250px;
	margin-left: -250px;
	background: radial-gradient(rgba(255,255,255,0.58) 0%, rgba(255,255,255,0.52) 60%, rgba(255,255,255,0.48) 100%);
}

//

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalVersions
.doc-zoomedImageModal-btn-iconContainer svg{
	height: 15px;
	width: 15px;	
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalVersions,
.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalUpdate{
	height: 28px;
	width: 32px;
	min-width: 32px;
	padding-left: 0px;
	background: rgba(255,255,255,0.06);
	// background: $tempr;
	border-radius: 4px;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalUpdate{
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalVersions{
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.doc-zoomedFigmaModal-statusContainer
.doc-zoomedImageModal-btn--figmaModalVersions{
	padding-right: 1px;
}

.doc-zoomedImageModal-btn--figmaModalVersions
.doc-zoomedImageModal-btn-label{
	letter-spacing: 0.1em;
	font-weight: $lato-semibold;
	font-size: 14.5px;
	user-select: none !important;
}

.doc-zoomedImageModal-btn--figmaModalVersions{
	user-select: none;
}

.doc-zoomedImageModal-btn--figmaModalVersions
.doc-zoomedImageModal-btn-label::first-letter{
	opacity: 0.75;
	user-select: none;
}




//
// MENU

.doc-zoomedFigmaModal-status-menuContainer{
	position: absolute;
	top: 32px;
	left: 50%;
	transform: translateX(-50%); 
	// background: $tempr;
	padding-top: 4px;

	z-index: 5;
	// margin-left: 18px;

	// padding-left: 12px;
	// padding-right: 12px;
	// padding-bottom: 12px;

	// display: none;
	// opacity: 0;
	// cursor: pointer;

	// transition: all 50ms linear;
}

// .doc-zoomedFigmaModal-statusBtnWrapper:hover ~ .doc-zoomedFigmaModal-status-menuContainer,
// .doc-zoomedFigmaModal-status-menuContainer:hover{
// 	display: flex;
// 	// visibility: visible;
// 	opacity: 1;
// 	transition: opacity 0ms;
// 	transition-delay: 100ms;
// }



.doc-zoomedFigmaModal-status-menu{
	width: 177px;
	cursor: pointer;
	// background: $tempr;
}

.doc-zoomedFigmaModal-status-menu
button.doc-zoomedFigmaModal-status-menu-btn{
	justify-content: flex-start;
	text-align: left;
}

.doc-zoomedFigmaModal-status-menu
.doc-zoomedFigmaModal-status-menu-btn-iconContainer{
	transform: scale(0.95);
	margin-left: -3px;
	position: relative;
	margin-right: 5px;
	top: 0px;
}

button.doc-zoomedFigmaModal-status-menu-btn
.doc-zoomedFigmaModal-status-menu-btn-label{
	letter-spacing: 0.015em;
}

button.doc-zoomedFigmaModal-status-menu-btn:last-child
.doc-zoomedFigmaModal-status-menu-btn-label{
	opacity: 0.75;
}
