.doc-para-inlineMentionSearchBar{
	min-width: 26px;
	
	position: relative;
	// top: 7.5px;
	// margin-top: -9px;

	cursor: pointer;
	
	overflow: hidden;

	// display: inline;

	white-space: pre; // don't allow to wrap, causes @ to appear, and attempts to wrap @ didn't work

	// background: $tempr;
	border-radius: 3px;

	padding-top: 2px;
	padding-bottom: 2px;
	
	padding-left: 9px;
	padding-right: 4px;
	margin-right: 3px; 

	position: relative;

	//background: var(--248gray);
	background: var(--primary25);
	// color: var(--primary700);	
	//color: var(--)
	// background: rgb(250,250,250);

	//word-break: break-all; // keep the @ on the right line

	z-index: -1;
	
}



.doc-para-inlineMentionSearchBar--empty{
	padding-right: 5px;	
	//color: $invisible;
}



.doc-para-inlineMentionSearchBar:before{	
	content: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(0,92,204,0.8)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");

	display: inline-block;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 22px;
	width: 22.5px;

//	background: var(--248gray);
	background: var(--primary25);
}

.doc-para-inlineMentionSearchBar--empty:after,
span.doc-span--style--light .doc-para-inlineMentionSearchBar--empty:after{
	content: 'Mention a doc, project, or issue';
	//content: 'Search docs, projects, andissue';
	//content: 'Mention';
	color: var(--primary500);
	opacity: 0.7;
}


.doc-toDoItem .doc-para-inlineMentionSearchBar--empty:after,
.doc-toDoItem span.doc-span--style--light .doc-para-inlineMentionSearchBar--empty:after{
	content: 'Assign or mention';
}


[data-theme="dark"]{
	.doc-para-inlineMentionSearchBar{
		background: #1f2847;
	}
	.doc-para-inlineMentionSearchBar:before{
		content: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(74,153,255,0.9)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");
		background: #1f2847;	
	}
	.doc-para-inlineMentionSearchBar--empty:after,
	span.doc-span--style--light .doc-para-inlineMentionSearchBar--empty:after{
		//color: #8FC4FF; // action command menu
		//color: #1D9BD1; // slack
		color: #4a99ff;
		opacity: 0.6;

	}
}


.doc-para-inlineMentionSearchBar,
span.doc-span--style--light .doc-para-inlineMentionSearchBar{
	color: var(--text500);
	
}




.doc-h1 .doc-para-inlineMentionSearchBar:before{
	//transform: scale(1.5);
	height: 38px;
	width: 32px;
}


.doc-h2 .doc-para-inlineMentionSearchBar:before{
	//transform: scale(1.5);
	height: 29px;
	width: 26px;
	
}



// .docEditor-messagePanel-inputBox .prosemirror-suggestion:before{
// 	width: 22px; // probs need better styling
// }

