
//
// TEAM GRID
.teamPage-memberGrid,
.allDocsPage-memberGrid{
	//width: 100%;
	width: calc(100% + 20px);
	margin-left: -10px;
	
	// height: 200px;
	margin-top: 24px;
	// background: $tempb;
	
	margin-bottom: 36px;

 	display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr) ) ;
}

.memberGrid-memberContainer{
	// background: $tempr;
	display: flex;

	box-sizing: border-box;
	//padding: 8px;

	cursor: default;
	user-select: none;
}

.memberGrid-member{
	display: flex;
	width: 100%;

	padding-left: 12px;
	padding-top: 12px;
	padding-right: 12px;
	padding-bottom: 12px;

	border-radius: 8px;
	background: var(--253gray);
}

//

.memberGrid-member-avatarContainer{
	width: 40px;
	height: 40px;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.memberGrid-member-avatar{
	width: 40px;
	height: 40px;
	border-radius: 6px;	
}

.memberGrid-member-statusIndicator{
	position: absolute;
	bottom: 0px;
	right: -2px;

	width: 9px;
	height: 9px;
	border-radius: 50%;
	border: 1.5px solid;

	outline: 3px solid var(--253gray);
}

.memberGrid-member-statusIndicator--active{
	border-color: var(--activeUserGreen);
	background: var(--activeUserGreen);
}

.memberGrid-member-statusIndicator--away{
	background: var(--bg-docEditor);
	border-color: var(--line600);
}

//

.memberGrid-member-centerContainer{
	padding-left: 10px;

	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	flex-grow: 1;
	flex-shrink: 1;
}

.memberGrid-member-name{
	font-size: 17px;
	font-weight: $lato-bold;
	color: var(--text500);

	margin-bottom: 2px;
}

.memberGrid-member-name-you{
	font-weight: $lato-semibold;
	opacity: 0.5;
}

.memberGrid-member-time{
	font-size: 14px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.55;
}

