
//
// RICH TEXT FIXES

.doc-table td .doc-h1{
	font-size: 20px;
	padding-top: 4px;
	margin-bottom: 4px;	
}

.doc-table td .doc-h2{
	font-size: 17.5px;
	padding-top: 4px;
	margin-bottom: 4px;	
}


//

.doc-table .doc-codeBlock{
	padding-left: 6px;
	padding-right: 6px;

	margin-top: 4px;
	margin-bottom: 4px;

	border-radius: 3px;

	border-radius: 3px;

	border: var(--docCodeBorder);
	background-color: var(--docCodeBackground);
}

.doc-table .doc-codeBlock p
.doc-table .doc-codeBlock p,
.doc-table .doc-codeBlock .doc-para{
	font-size: 11.5px;
}

//
//

.doc-table span.doc-span--style--code{
	text-decoration: none;

	box-sizing: border-box;

	padding-left: 3px;
	padding-right: 3px;
	padding-top: 2px;
	padding-bottom: 1px;
	

	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
	font-weight: 400;	
	color: var(--docCodeTextColor);

	border-radius: 3px;

	font-size: 12.5px;

	border: var(--docCodeBorder);
	background-color: var(--docCodeBackground);
}

// 
// Lists

.doc-table .doc-listItem[data-marker-type="number"] .doc-listItem-markerContainer,
.doc-table .doc-listItem[data-marker-type="letter"] .doc-listItem-markerContainer{
	//padding-bottom: 1px;
	height: 22px;
	width: 20px;
}

.doc-table .doc-listItem[data-marker-type="number"] .doc-listItem-marker,
.doc-table .doc-listItem[data-marker-type="letter"] .doc-listItem-marker{
	font-size: 15px;
	padding-right: 6px;
}


.doc-table .doc-listItem[data-marker-type="dash"] .doc-listItem-markerContainer{
	//padding-bottom: 1px;
	height: 22px;
	width: 20px;
}

.doc-table .doc-listItem[data-marker-type="dash"] .doc-listItem-marker::before{
	position: relative;
	top: -1px;
	left: -3px;
}

.doc-table .doc-listItem[data-marker-type="bullet"] .doc-listItem-markerContainer{
	padding-left: 4px;
	height: 22px;
	width: 20px;
}

.doc-table .doc-listItem[data-marker-type="bullet"] .doc-listItem-marker{
	width: 4px;
	height: 4px;
	position: relative;
	top: -1px;
	right: -1px;
}

//
// To dos

.doc-table .doc-toDoItem{
	min-height: 24px;
	margin-top: -2px;
	margin-bottom: -1px;
}

.doc-table .doc-toDoItem-checkboxContainer{
	width: 14px;
	height: 14px;
	border-radius: 3px;
	//top: calc(((#{$minHeight-checkBox-toDoItem} - #{$exteriorSideDimension-checkbox-toDoItem}) / 2) - #{$padding-checkBoxContainer-toDoItem});
	top: 2px
}

.doc-table .doc-toDoItem-checkbox{
 	width: 12px;
 	height: 12px;
 	border-radius: 3px;
}

.doc-table .doc-toDoItem-checkbox svg{
	width: 11px;
	height: 11px;
}

.doc-table .doc-toDoItem-assignedContainer{
	display: none; // testing
}

.doc-table .doc-toDoItem-checkboxTooltip{
	display: none;
}

.doc-table .doc-toDoItem-contents{
	padding-left: 9px;
}




// Issues
.doc-table .doc-para-linearIssueOuterContainer{
	margin-top: 2px;
}

.doc-table .doc-inlineLinearIssue-title{
	font-size: 15px;
}



// Project
.doc-table .doc-para-projectLinkContainer{
	// margin-bottom: 1px;
}

.doc-table .doc-para-projectLink-title{
	font-size: 15px;
	line-height: 1.29;
}

.doc-table .doc-para-projectLink-markerContainer{
	//height: 0.9em;
	top: 0em;
	vertical-align: unset;

}

.doc-table .doc-para-projectLink-markerContainer .inlineLinearMarker{
	font-size: 13.5px;
}

.doc-table .doc-para-projectLink-markerContainer svg{
	width: 13.5px;
	height: 13.5px;
}
// fix if only item



.doc-table .doc-para-internalLink-title{
	font-size: 15px;
	line-height: 1.29;
}

.doc-table .doc-para-internalLink svg{
	width: 16px;
	height: 16px;
}


//
// Mention Search Bar

.doc-table .doc-para-inlineMentionSearchBar:before{
	//height: 20px;
	width: 21.5px;
}

.doc-table .doc-para-inlineMentionSearchBar--empty:after{
	content: "Search ";
}

.doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar{
  background: var(--accentGreen75);
}

.doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(43,177,139,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentGreen700);
}

//

.doc-table-cell.doc-table-cell--bg--purple .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--purple .doc-para-inlineMentionSearchBar{
  background: var(--accentPurple75);
}

.doc-table-cell.doc-table-cell--bg--purple .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(163,88,213,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--purple .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentPurple700);
}	

//

.doc-table-cell.doc-table-cell--bg--red .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--red .doc-para-inlineMentionSearchBar{
  background: var(--accentRed75);
}

.doc-table-cell.doc-table-cell--bg--red .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(239,78,80,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--red .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentRed700);
}	

//

.doc-table-cell.doc-table-cell--bg--blue .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--blue .doc-para-inlineMentionSearchBar{
  background: var(--accentBlue75);
}

.doc-table-cell.doc-table-cell--bg--blue .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(8,112,217,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--blue .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentBlue700);
}	

//

.doc-table-cell.doc-table-cell--bg--orange .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--orange .doc-para-inlineMentionSearchBar{
  background: var(--accentOrange75);
}

.doc-table-cell.doc-table-cell--bg--orange .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(255,142,66,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--orange .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentOrange700);
}	

//

.doc-table-cell.doc-table-cell--bg--pink .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--pink .doc-para-inlineMentionSearchBar{
  background: var(--accentPink75);
}

.doc-table-cell.doc-table-cell--bg--pink .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(243,94,176,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--pink .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentPink700);
}	

//

.doc-table-cell.doc-table-cell--bg--sky .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--sky .doc-para-inlineMentionSearchBar{
  background: var(--accentSky75);
}

.doc-table-cell.doc-table-cell--bg--sky .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(38,185,247,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--sky .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentSky700);
}	

//

.doc-table-cell.doc-table-cell--bg--teal .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--teal .doc-para-inlineMentionSearchBar{
  background: var(--accentTeal75);
}

.doc-table-cell.doc-table-cell--bg--teal .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(0,143,153,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--teal .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentTeal700);
}	

//


.doc-table-cell.doc-table-cell--bg--grey .doc-para-inlineMentionSearchBar:before,
.doc-table-cell.doc-table-cell--bg--grey .doc-para-inlineMentionSearchBar{
  background: var(--accentGrey75);
}

.doc-table-cell.doc-table-cell--bg--grey .doc-para-inlineMentionSearchBar:before{
	content: url("data:image/svg+xml,<svg width='22' height='22' viewBox='0 0 24 24' transform='translate(0 0.5)' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(101,102,114,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
}

.doc-table-cell.doc-table-cell--bg--grey .doc-para-inlineMentionSearchBar--empty:after{
	color: var(--accentGrey700);
}	



// .doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar:before,
// .doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar{
//   background: var(--accentGreen75);
// }

// .doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar:before{
// 	content: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='rgba(43,177,139,1)' d='M5.25 10.755c0 .768.144 1.488.433 2.16a5.625 5.625 0 0 0 2.973 2.975 5.428 5.428 0 0 0 2.16.432c.569 0 1.112-.083 1.63-.25a5.627 5.627 0 0 0 1.431-.691l3.136 3.142c.102.097.215.17.34.218.126.049.257.073.394.073a1 1 0 0 0 .52-.134.952.952 0 0 0 .354-.367c.085-.154.128-.327.128-.518 0-.136-.024-.265-.072-.387a.955.955 0 0 0-.216-.326l-3.112-3.13c.324-.447.577-.941.76-1.483a5.327 5.327 0 0 0 .274-1.714c0-.767-.144-1.486-.432-2.159a5.62 5.62 0 0 0-2.975-2.974 5.424 5.424 0 0 0-2.16-.432c-.767 0-1.487.144-2.16.432a5.625 5.625 0 0 0-2.974 2.974 5.418 5.418 0 0 0-.432 2.16zm1.455 0c0-.567.106-1.099.318-1.595A4.138 4.138 0 0 1 9.22 6.963a4.018 4.018 0 0 1 1.597-.318c.568 0 1.1.106 1.596.318.497.212.933.507 1.31.885.375.378.67.815.884 1.312.213.496.32 1.028.32 1.595 0 .569-.107 1.1-.32 1.597a4.158 4.158 0 0 1-2.194 2.194 3.988 3.988 0 0 1-1.596.321c-.569 0-1.1-.107-1.597-.321a4.2 4.2 0 0 1-1.312-.885 4.105 4.105 0 0 1-.885-1.309 4.018 4.018 0 0 1-.318-1.597z'/></svg>");	
// }

// .doc-table-cell.doc-table-cell--bg--green .doc-para-inlineMentionSearchBar--empty:after{
// 	color: var(--accentGreen700);
// }

// //

// .doc-table-cell.doc-table-cell--bg--purple .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentPurple100);
// }

// .doc-table-cell.doc-table-cell--bg--red  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentRed100);
// }

// .doc-table-cell.doc-table-cell--bg--blue  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentBlue100);
// }

// .doc-table-cell.doc-table-cell--bg--orange  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentOrange100);
// }

// .doc-table-cell.doc-table-cell--bg--sky  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentSky100);
// }

// .doc-table-cell.doc-table-cell--bg--pink  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentPink100);
// }

// .doc-table-cell.doc-table-cell--bg--teal  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentTeal100);
// }

// .doc-table-cell.doc-table-cell--bg--grey  .doc-para-inlineMentionSearchBar:before{
// 	background: var(--accentGrey100);
// }
