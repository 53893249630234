$leftOverhang-docTableRow: 12px;
$rightOverhang-docTableRow: 10px;

//
// DOC ROW AND TITLE


.libraryDocTable-table-row{
	width: calc(100% + #{$leftOverhang-docTableRow} + #{$rightOverhang-docTableRow});
	display: flex;
	align-items: center;

	margin-left: calc(-1 * #{$leftOverhang-docTableRow});

	height: 32px;
	border-radius: 4px;

	cursor: pointer;
	position: relative;
}


.libraryDocTable-table-row:hover{
	background: var(--docTableItemHoverBG);
}

.libraryDocTable-table-row[data-state="open"]{
	background: var(--docTableItemContextOpenBG);
}

// .libraryDocTable-table-row:nth-child(even){
// 	background: rgb(251,248,248);
// }

.libraryDocTable-table-row-startSpacer{
	width: $leftOverhang-docTableRow;
	flex-grow: 0;
	flex-shrink: 0;
}

.libraryDocTable-table-row-endSpacer{
	width: $rightOverhang-docTableRow;
	flex-grow: 0;
	flex-shrink: 0;
}

// MARKER

.libraryDocTable-table-row-title-marker{
	width: 30px;
	height: 32px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -8px;
}

.libraryDocTable-table-row-title-marker--project
.linearMarkerContainer--markerType--emoji,
.libraryDocTable-table-row-title-marker--team
.linearMarkerContainer--markerType--emoji{
	font-size: 15.5px;
}

.libraryDocTable-table-row-title-marker--project
.linearMarkerContainer--markerType--icon svg,
.libraryDocTable-table-row-title-marker--team
.linearMarkerContainer--markerType--icon svg{
	width: 14px;
	height: 14px;
}

.libraryDocTable-table-row-title-marker--subdoc svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.5;
}

.libraryDocTable-table-row-title-marker--private svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.7;
}

.libraryDocTable-table-row-title-marker--meeting svg{
	width: 17px;
	height: 17px;	
}



//

.libraryDocTable-table-row-title{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	height: 100%;

	display: flex;
	align-items: center;
}

.libraryDocTable-table-row-title-title{
	font-size: 16.25px;
	font-weight: $lato-bold;
	color: var(--text500);

	letter-spacing: 0.001em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 10px;

  position: relative;
  //top: 0.5px;
}

.libraryDocTable-table-row-title-title-meetingDateTime{
	margin-left: 5px;

	font-size: 15.75px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.5;
}

[data-theme="dark"]{
	.libraryDocTable-table-row-title-title{
		letter-spacing: 0.003em;
	}
}



//

.libraryDocTable-table-row-title-projectTag,
.libraryDocTable-table-row-title-roadmapTag,
.libraryDocTable-table-row-title-orgTag{
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);


	height: 22px;

	border-radius: 4px;
	padding-left: 6px;
	padding-right: 6px;

	margin-left: auto;
}

.libraryDocTable-table-row:hover .libraryDocTable-table-row-title-projectTag,
.libraryDocTable-table-row:hover .libraryDocTable-table-row-title-roadmapTag,
.libraryDocTable-table-row:hover .libraryDocTable-table-row-title-orgTag{
	background: var(--246gray);
}


.libraryDocTable-table-row-title-projectTag-label,
.libraryDocTable-table-row-title-roadmapTag-label,
.libraryDocTable-table-row-title-orgTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.85;
	font-weight: $lato-medium;

	position: relative;
	top: -0.5px;

	margin-left: 4px;
}

//

// .libraryDocTable-table-row-title-roadmapTag{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	border: 1px solid var(--line200);
// 	border-radius: 4px;

// 	height: 20px;
// 	padding-left: 5px;
// 	padding-right: 5px;

// 	margin-left: auto;
// }

// .libraryDocTable-table-row-title-roadmapTag-label{
// 	font-size: 14px;
// 	color: var(--text500);
// 	opacity: 0.75;
// 	font-weight: $lato-semibold;

// 	position: relative;
// 	top: -0.5px;

// 	margin-left: 3px;
// }

.libraryDocTable-table-row-title-projectTag-marker{
	position: relative;
	//top: 1px;	
}


.libraryDocTable-table-row-title-projectTag
.linearMarkerContainer--markerType--emoji{
	font-size: 13px;
}

.libraryDocTable-table-row-title-projectTag
.linearMarkerContainer--markerType--icon svg{
	width: 13px;
	height: 13px;
}

.libraryDocTable-table-row-title-roadmapTag-icon svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.5;
	position: relative;
	top: 2px;
}

//

.libraryDocTable-table-row-title-orgTag-image{
	width: 14px;
	height: 14px;
	position: relative;
	top: -1px;
}

.libraryDocTable-table-row-title-orgTag-image-image{
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 3px;
}

// Private tag

.libraryDocTable-table-row-title-privateTag{
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	height: 22px;

	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;

	// margin-left: auto;
}

.libraryDocTable-table-row-title-privateTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.75;
	font-weight: $lato-semibold;

	position: relative;
	top: -0.5px;
}


.libraryDocTable-table-row:hover .libraryDocTable-table-row-title-privateTag{
	background: var(--246gray);
}

// Meeting datetime tag

.libraryDocTable-table-row-title-meetingDateTimeTag{
	margin-left: auto;
	
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	height: 22px;

	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;

	// margin-left: auto;
}

.libraryDocTable-table-row-title-meetingDateTimeTag-iconContainer{
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.libraryDocTable-table-row-title-meetingDateTimeTag-iconContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text500);
	opacity: 0.5;
}

.libraryDocTable-table-row-title-meetingDateTimeTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.75;
	font-weight: $lato-semibold;

	position: relative;
	top: -0.5px;
}


.libraryDocTable-table-row:hover .libraryDocTable-table-row-title-meetingDateTimeTag{
	background: var(--246gray);
}


//

.libraryDocTable-table-row-title-projectStatusTagContainer{
	margin-left: auto;
}

.libraryDocTable-table-row-title-projectStatusTagContainer
.linearProjectStatusTag{
	transform: scale(0.85);
	transform-origin: right center;
}










// DEV NOTIFICATION CONTAINER 

.libraryDocTable-table-row-title-notificationCountContainer{
	flex-grow: 0;
	flex-shrink: 0;

	// padding-left: 4px;
	margin-right: 8px;

	// position: absolute;
	// left: -24px;
}

.libraryDocTable-table-row-title-notificationCount{
	height: 18px;
	min-width: 25px;

	padding-left: 5px;
	padding-right: 5px;

	box-sizing: border-box;

	border-radius: 9px;	
	border-radius: 5px;	

	font-size: 12.75px;	
	font-weight: $lato-bold;

	

	color: var(--text600);
	background: var(--bgSideMenuTabActive);

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.65;
}

.libraryDocTable-table-row:hover 
.libraryDocTable-table-row-title-notificationCount{
	opacity: 0.85;
}

// CONVERSATIONS

.libraryDocTable-table-row-metadata-conversations{
	display: flex;
}

.libraryDocTable-table-row-metadata-conversations-user{
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.libraryDocTable-table-row-metadata-conversations-user-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}







//


.libraryDocTable-table-row-lastUpdated{
	width: 120px;

	flex-grow: 0;
	flex-shrink: 0;

	font-size: 12px;
	font-weight: $lato-regular;
	letter-spacing: 0.04em;

	//color: rgb(125,124,124);
	color: var(--text200);

	box-sizing: border-box;
	padding-left: 10px;
	padding-top: 2px;

	text-align: right;



}


// .libraryDocTable-table-row:nth-child(even){
// 	background: rgb(244,242,242);
// }



// //
// // ACTIVE 

// .library-content-channel-tableContainer .libraryDocTable-table-row--active,
// .library-content-channel-tableContainer .libraryDocTable-table-row--active:nth-child(even),
// .library-content-channel-tableContainer .libraryDocTable-table-row--active:nth-child(odd){
// 	background: $blue;
// }

// .libraryDocTable-table-row--active
// .libraryDocTable-table-row-title{
// 	color: $white;
// }

// .libraryDocTable-table-row--active
// .libraryDocTable-table-row-lastUpdated{
// 	color: $white;
// }

// .libraryDocTable-table-row--active
// .libraryDocTable-table-row-creator{
// 	color: $white;
// 	opacity: 0.8;
// }
