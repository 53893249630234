.editProjectModal-footerRow-submitBtnContainer{
	position: relative;
}

.editProjectModal-footerRow-submitBtn{
	width: 160px;
	height: 36px;

	background: var(--text500);
	border: 1px solid var(--text600);
	border-radius: 3px;

	position: relative;
	transform: scale(1.0);
	transition: transform 50ms linear;
}

.editProjectModal-footerRow-submitBtn:disabled{
	pointer-events: none;
}

.editProjectModal-footerRow-submitBtn:focus{
	outline: 3px solid var(--primary100);	
}


.editProjectModal-footerRow-submitBtn-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: $white;
}

.editProjectModal-footerRow-submitBtn-keyboardKey{
	background: rgba(255,255,255,0.07);
	border-radius: 3px;

	height: 19px;
	padding-left: 4px;
	padding-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 8px;
	margin-right: -5px;

	position: relative;
	top: 0.5px;
}

.editProjectModal-footerRow-submitBtnKeyboardHint-iconContainer svg{
	width: 13px;
	height: 13px;
	fill: $white;
	opacity: 0.8;
	position: relative;
	top: 1px;
}

:root[data-theme="dark"]
.editProjectModal-footerRow-submitBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.editProjectModal-footerRow-submitBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.editProjectModal-footerRow-submitBtn:focus{
	outline: none;
	background: var(--243gray);
	border: 1px solid var(--210gray);
}



.editProjectModal-footerRow-submitBtn-loadingBar{
	right: 18px;
	left: 18px;

	top: 50%;
	transform: translateY(-50%);

	height: 3px;

	border-radius: 10px;

	position: absolute;

	background: var(--246gray);
	background: rgba(255,255,255,0.2);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.editProjectModal-footerRow-submitBtn-loadingBar-ball{
	width: 40px;
	height: 3px;
	border-radius: 4px;

	// background: red;
	background: rgba(255,255,255,0.8);	

}





// .editProjectModal-footerRow-submitBtnKeyboardHint{
// 	position: absolute;
// 	left: 0px;
// 	right: 0px;
// 	top: 100%;
// 	height: 20px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .editProjectModal-footerRow-submitBtnKeyboardHint-iconContainer{
// 	width: 14px;
// 	height: 14px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .editProjectModal-footerRow-submitBtnKeyboardHint-iconContainer svg{
// 	width: 12px;
// 	height: 12px;
// }

// .editProjectModal-footerRow-submitBtnKeyboardHint-label{
// 	font-size: 13.5px;
// 	font-weight: $lato-medium;
// 	letter-spacing: 0.01em;
// 	color: var(--text500);
// }

