.message-inputBox-insertPopover{
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 6px;
	padding-right: 6px;
}
.message-inputBox-insertPopover-row{
	height: 32px;
	width: 160px;
	border-radius: 4px;

	padding-left: 6px;

	font-size: 15px;
	justify-content: flex-start;
}

.message-inputBox-insertPopover-row:hover{
	background: rgb(240,240,240);
}