.dialogOverlay--openDocMenu{
	z-index: 9999;
	background: $invisible;
}
.openDocMenu{

	max-height: 350px;

	//background: rgb(251,249,249);
	background: var(--openDocMenu-bg);

	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;

	border: 1px solid var(--appContentsBorder-sideMenuMode);

	box-shadow: var(--openDocMenu-boxShadow);
		
	position: fixed;
	// top: $height-tabBar;
	
	top: $windowMargin-sideMenuMode; // for side menu
	left: 50%;
	margin-top: -1px;

	//right: 10px;
	transform: translateX(-50%);

	z-index: 9999;

	display: flex;
	flex-direction: column;
}

// OPEN DOC WIDTH

.openDocMenu{
	@include poly-fluid-sizing('width', (
		1020px: 540px, 
		1140px: 600px, 
		1280px: 620px,
		1366px: 700px,
		1600px: 730px,
		1920px: 730px)
	);
}



.openDocMenu-input{
	width: 100%;
	height: calc(30px + 5px);
	position: relative;
}

input.openDocMenu-input-input{
	border: none;
	border-radius: 3px;
	position: absolute;
	left: 5px;
	width: calc(100% - 10px);
	right: 5px;
	bottom: 0px;
	top: 7px;

	box-sizing: border-box;
	
	background: none;

	padding-left: 10px;
	padding-right: 10px;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text600);

	background: var(--250gray);
}

input.openDocMenu-input-input:focus{
	outline: none;
	border: none;
}

input.openDocMenu-input-input::placeholder{
	color: var(--text300);
}

//

.openDocMenu-resultsList{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;

	width: 100%;
	box-sizing: border-box;
	padding-top: 4px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
}

.openDocMenu-row{
	height: 30px;
	width: 100%;
	//background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 3px;

	user-select: none;
	cursor: pointer;
}

.openDocMenu-row--active{
	background: rgba(0,0,0,0.06);
}

[data-theme="dark"]
.openDocMenu-row--active{
	background: rgba(255,255,255,0.08);
}

.openDocMenu-row-docTitle-marker,
.openDocMenu-row-docTitle-iconContainer{
	width: 30px;
	height: 30px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
	margin-left: -8px;
}

.openDocMenu-row-docTitle-subdocSlash{
	width: 24px;
	height: 24px;
	font-weight: 15.5px;
	color: var(--text500);
	font-weight: $lato-medium;
	opacity: 0.35;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -13px;
	margin-right: -4px;
}

.openDocMenu-row-docTitle-marker
.linearMarkerContainer--markerType--emoji{
	font-size: 15.5px;
}

.openDocMenu-row-docTitle-marker
.linearMarkerContainer--markerType--icon svg{
	width: 14px;
	height: 14px;
}

.openDocMenu-row-docTitle-marker
.meetingTypeIconContainer svg{
	width: 15px;
	height: 15px;
}


.openDocMenu-row-docTitle-iconContainer--roadmapRow svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.5;
}


.openDocMenu-row-docTitle{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 10px;
}

.openDocMenu-row-docTitle-meetingDateTime{
	margin-left: 3px;

	font-size: 14.75px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.5;
}


@import "OpenDocMenu-DocPreview.scss";