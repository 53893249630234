.doc-table-actionMenuContainer{
	// position: fixed;
	// bottom: 14px;	

	//background: $tempr;

	position: absolute;
	bottom: 100%;
	margin-bottom: -8px;
	left: 0px;
	
	z-index: 50;
	height: 32px;

	width: 650px;

	display: flex;
	align-items: center;
	justify-content: center;	

	font-size: 15px;
	font-weight: $lato-semibold;

	opacity: 0;
	transform: translateY(3px);
	transition: all 100ms linear;

	pointer-events: none;

	//background: $tempr;
}

.doc-tableContainer--focused
.doc-table-actionMenuContainer{
	opacity: 1;
	transform: translateY(0px);
	transition: all 100ms linear;
}

//

.doc-table-actionMenu{
	pointer-events: none;
}

.doc-tableContainer--focused
.doc-table-actionMenu{
	pointer-events: auto;
}

.doc-tableContainer--selected
.doc-table-actionMenuContainer{
	opacity: 0;
	pointer-events: none;
}

.doc-tableContainer--selected
.doc-table-actionMenuContainer
.doc-table-actionMenu{
	pointer-events: none;
}

//

.doc-table-actionMenu{

	padding-left: 4px;
	padding-right: 4px;

	cursor: default;

	border-radius: 4px;

	// background: var(--bg-docEditor);
	// border: 1px solid var(--line400);
	// box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);

	// SELECTION MENU
	height: 32px;
	background: var(--bgFloatingMenu);
	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);
	box-shadow: var(--boxShadowFloatingMenu);


	// background: rgba(35,30,40,0.9);
	// backdrop-filter: blur(5px);

	//background: red;
	// width: 100%;

	display: flex;
	align-items: center;

	color: var(--text500);
	font-size: 14px;

	
}



.doc-table-actionMenu-rowCol{
	display: flex;
	height: 100%;
	display: flex;
	align-items: center;
}

.doc-table-actionMenu-btnGroup{
	display: flex;
}

.doc-table-actionMenu-rowCol-countLabel{
	height: 100%;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: default;	
}


//

button.doc-table-actionMenu-btn{
	min-width: 32px;
	background: $tempr;
	height: 28px;
	border-radius: 3px;

	color: var(--text400);
	display: flex;

	opacity: 0.7;
	background: var(--bgFloatingMenu);
}

button.doc-table-actionMenu-btn:hover{
	background: var(--hoverBGFloatingMenu);
}

button.doc-table-actionMenu-btn[data-btn-state="active"]{
	// opacity: 0.5;
	background: var(--hoverBGFloatingMenu);
}



.doc-table-actionMenu-btn--distributeCols{
	width: 38px;

}

button.doc-table-actionMenu-btn svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
}

//


button.doc-table-actionMenu-btn svg.icon--name--hideInterface{
	width: 30px;
	height: 30px;
}

button.doc-table-actionMenu-btn svg.icon--name--showInterface{
	width: 30px;
	height: 30px;
}

button.doc-table-actionMenu-btn svg.icon--name--hideInterface{
	display: block;
}

button.doc-table-actionMenu-btn svg.icon--name--showInterface{
	display: none;
}

.doc-tableContainer--wide button.doc-table-actionMenu-btn svg.icon--name--hideInterface{
	display: none;
}

.doc-tableContainer--wide button.doc-table-actionMenu-btn svg.icon--name--showInterface{
	display: block;
}


//

button.doc-table-actionMenu-btn svg.icon--name--blockDecoration #inner{
	fill: var(--bg-docEditor);
}

button.doc-table-actionMenu-btn svg.icon--name--blockDecoration #main{
	fill: red;
}

.doc-table-actionMenu-btn--bg-icon{
	width: 16px;
	height: 12px;
	border: 1px solid var(--text600);
	border-radius: 2px;
}


//

button.doc-table-actionMenu-btn svg.icon--name--plus,
button.doc-table-actionMenu-btn svg.icon--name--minus{
	width: 14px;
	height: 14px;
}

//

button.doc-table-actionMenu-btn--textAlign svg{
	width: 18px;
	height: 18px;
}

button.doc-table-actionMenu-btn--delete svg{
	width: 16px;
	height: 16px;
}


button.doc-table-actionMenu-btn svg.icon--name--headerRowActive #fill,
button.doc-table-actionMenu-btn svg.icon--name--headerColActive #fill,
button.doc-table-actionMenu-btn svg.icon--name--altRowFillActive #fill{
	fill: var(--text500);
	opacity: 0.3;	
}

button.doc-table-actionMenu-btn svg.icon--name--altRowFillActive #fill{
	fill: var(--text500);
	opacity: 1;
}

button.doc-table-actionMenu-btn svg.icon--name--altRowFill #fill{
	fill: var(--text500);
	opacity: 0.2;
}







// button.doc-table-actionMenu-btn svg.icon--name--ellipses{
// 	width: 16px;
// 	height: 16px;
// }


.doc-table-actionMenu-divider{
	height: 20px;
	width: 1px;

	background: var(--text600);
	opacity: 0.15;

	margin-left: 2px;
	margin-right: 2px;

	

}

button.doc-table-actionMenu-btn svg.icon--name--cellBG{
	// width: 20px;
	// height: 20px;
}

button.doc-table-actionMenu-btn--cellBG svg #border{
	//fill: var(--text200);
}

button.doc-table-actionMenu-btn--cellBG svg #fill{
	fill: var(--bg-docEditor)
}

[data-theme="dark"]{
	button.doc-table-actionMenu-btn--cellBG svg #fill{
		fill: rgb(64,63,68);
	}
}


button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--green svg #fill{
	fill: var(--accentGreen75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--green svg #border{
	fill: var(--accentGreen700);
}


button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--purple svg #fill{
	fill: var(--accentPurple75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--purple svg #border{
	fill: var(--accentPurple700);
}


button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--red svg #fill{
	fill: var(--accentRed75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--red svg #border{
	fill: var(--accentRed700);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--blue svg #fill{
	fill: var(--accentBlue75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--blue svg #border{
	fill: var(--accentBlue700);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--sky svg #fill{
	fill: var(--accentSky75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--sky svg #border{
	fill: var(--accentSky700);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--pink svg #fill{
	fill: var(--accentPink75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--pink svg #border{
	fill: var(--accentPink700);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--orange svg #fill{
	fill: var(--accentOrange75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--orange svg #border{
	fill: var(--accentOrange700);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--teal svg #fill{
	fill: var(--accentTeal75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--teal svg #border{
	fill: var(--accentTeal700);
}


button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--grey svg #fill{
	fill: var(--accentGrey75);
}

button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--grey svg #border{
	fill: var(--accentGrey700);
}


[data-theme="dark"]{


	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--green svg #fill{
		fill: var(--accentGreen50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--purple svg #fill{
		fill: var(--accentPurple50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--red svg #fill{
		fill: var(--accentRed50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--blue svg #fill{
		fill: var(--accentBlue50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--sky svg #fill{
		fill: var(--accentSky50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--pink svg #fill{
		fill: var(--accentPink50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--orange svg #fill{
		fill: var(--accentOrange50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--teal svg #fill{
		fill: var(--accentTeal50);
	}

	button.doc-table-actionMenu-btn--cellBG.doc-table-actionMenu-btn--cellBG--activeColor--grey svg #fill{
		fill: var(--accentGrey50);
	}

}