.doc-video-commentRow{
	width: 100%;
	height: 100%;
	position: relative;
	user-select: none;
	// background: $tempr;
}

.doc-video-commentRow-fadeOutCover{
	position: absolute;
	left: 0px;
	right: 0px;
	width: 100%;
	top: 0px;
	bottom: 0px;
	z-index: 8;

	background: rgba(0,0,0,0.75);
	opacity: 0;

	pointer-events: none;
}

.doc-video-commentRow-commentContainer{
	// background: $tempr;

	position: absolute;
	height: 20px;
	top: 5px;
	z-index: 5;
}

.doc-video-commentRow-commentContainer:hover,
.doc-video-commentRow-commentContainer--forceShow{
	z-index: 10;
}

.doc-video-commentRow-commentMarker{
	width: 100%;
	height: 100%;
	border-radius: 4px;
	cursor: pointer;
	outline: 1px solid rgb(12,12,15);
	// background: $tempr;
}

.doc-video-commentRow-commentMarker-avatar{
	border-radius: 4px;
	width: 100%;
	height: 100%;

}

// inline make smaller
.doc-video-inlineUI-controlBar-commentRowContainer
.doc-video-commentRow-commentMarker{
	transform: scale(0.5);
	outline: 3px solid rgba(12,12,15,0.4);
	// opacity: 0.5;
}
.doc-video-inlineUI-controlBar-commentRowContainer
.doc-video-commentRow-commentMarker:hover{
	transform: scale(0.8);
	outline: 1px solid rgba(12,12,15,0.4);
	// opacity: 1;
}

.doc-video-inlineUI-controlBar-commentRowContainer
.doc-video-commentRow-commentMarker
.avatarImage-doodleAvatar-img{
	opacity: 0;
}

.doc-video-inlineUI-controlBar-commentRowContainer
.doc-video-commentRow-commentMarker:hover
.avatarImage-doodleAvatar-img{
	opacity: 1;
}


.doc-video-commentRow-commentMarker:hover,
.doc-video-commentRow-commentContainer--forceShow
.doc-video-commentRow-commentMarker{
	outline: 1px solid rgba(255,255,255,0.75);
}

.doc-video-commentRow-commentMessageOuterContainer{
	position: absolute;

	// height: 100px;
	
	bottom: 34px;	

	display: flex;

	opacity: 0;
	transition: opacity 150ms;
	pointer-events: none;

	// background: $tempr
}

.doc-video-inlineUI-controlBar-commentRowContainer
.doc-video-commentRow-commentMessageOuterContainer{
	bottom: 28px;
}

.doc-video-commentRow-commentContainer:hover
.doc-video-commentRow-commentMessageOuterContainer,
.doc-video-commentRow-commentContainer--forceShow
.doc-video-commentRow-commentMessageOuterContainer{
	opacity: 1;
	transition: opacity 50ms;
	pointer-events: auto;
}

.doc-video-commentRow-commentMessageOuterContainer--ghost{
	opacity: 1;
	bottom: 80px;
	position: absolute;
	
	width: 320px;

	display: flex;

	visibility: hidden;
	pointer-events: none;
}


// .doc-video-commentRow-commentContainer:nth-child(2)
// .doc-video-commentRow-commentMessageOuterContainer{
// 	opacity: 1;
// 	transition: opacity 50ms;
// 	pointer-events: auto;
// }


//
//


:root{
	--videoMessageBG: rgb(255,255,255);
	--videoMessageTopBorder: 1px solid rgb(200,200,200);
	--videoMessageSideBorder: 1px solid rgb(200,200,200);
	--videoMessageBottomBorder: 1px solid rgb(200,200,200);
}

.forceDarkTheme{
	//--videoMessageBG: rgb(20,20,24);
	--videoMessageBG: rgb(30,30,34);
	--videoMessageTopBorder: 1px solid rgb(50,50,60);
	--videoMessageSideBorder: 1px solid rgb(40,40,50);
	--videoMessageBottomBorder: 1px solid rgb(30,30,40);
}

.doc-video-commentRow-messageContainer{
	
	display: flex;

	background: $tempr;

	// padding-left: 10px;
	// padding-right: 14px;
	// padding-top: 10px;
	// padding-bottom: 8px;

	padding-left: 10px;
	padding-right: 12px;
	padding-top: 10px;
	padding-bottom: 10px;

	background: var(--videoMessageBG);

	border-radius: 5px;
	border: var(--videoMessageSideBorder);
	border-top: var(--videoMessageTopBorder);
	border-bottom: var(--videoMessageBottomBorder);

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
}

.doc-video-commentRow-message{
	display: flex;
}

.doc-video-commentRow-message-leftContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 9px;
}

.doc-video-commentRow-message-avatarContainer{
	// width: 32px;
	// height: 32px;
	width: 28px;
	height: 28px;

	border-radius: 4px;
}

.doc-video-commentRow-message-avatar{
	width: 100%;
	height: 100%;

	border-radius: 4px;

	object-fit: cover;
}

//

.doc-video-commentRow-message-rightContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;
}

.doc-video-commentRow-message-header{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 1px;

	cursor: default;
	user-select: none;
}

.doc-video-commentRow-message-header-name{
	//font-size: 15.5px;
	font-size: 14.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-right: 6px;
}

.doc-video-commentRow-message-header-timestamp{
	//font-size: 12px;
	font-size: 11px;
	font-weight: $lato-medium;
	color: var(--text200);
}


.doc-video-commentRow-message-content .message-para{
	font-weight: $lato-regular;
	font-size: 14.25px;
	//font-size: 15.25px;
	color: var(--text500);
	letter-spacing: 0.003em;

	//line-height: 1.4;
	line-height: 1.25;

	min-height: 12px;

	cursor: text;
	// white-space: pre-wrap;

}