// PROJECT

.projectsPage-plannedProject{
	width: 100%;
	height: 40px;
	// background: $tempb;
	// outline: 1px solid blue;

	display: flex;
	align-items: center;

	box-sizing: border-box;

	cursor: pointer;

	border-radius: 5px;

	padding-left: 8px;
	margin-left: -8px;
	width: calc(100% + 8px);

	border: 1px solid $invisible;

}

.projectsPage-plannedProject:hover{
	background: var(--253gray);
	border: 1px solid var(--line100);
}

.projectsPage-plannedProject-markerContainer{
	font-size: 18px;
	
	height: 28px;
	width: 28px;

	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	// background: var(--bgSideMenuTabLight);
	// border-radius: 4px;
}

.projectsPage-plannedProject-markerContainer
.linearMarker{
	font-size: 20px;
}

.projectsPage-plannedProject-markerContainer
.linearMarker svg{
	width: 20px;
	height: 20px;
}

//

.projectsPage-plannedProject-primary{
	display: flex;
	align-items: baseline;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 1;
	flex-shrink: 1;
}

.projectsPage-plannedProject-primary-name{
	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.001em;
	color: var(--text500);
}

.projectsPage-plannedProject-primary-description{
	font-size: 15.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.001em;
	color: var(--text500);
	opacity: 0.5;
	margin-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//

.projectsPage-plannedProject-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;

	margin-left: auto;
	display: flex;
	padding-left: 5px;
}

.projectsPage-plannedProject-teamTagContainer{
	display: flex;
	gap: 4px;
}

.projectsPage-plannedProject-teamTag{
	padding-left: 5px;
	padding-right: 5px;
	
	height: 18px;


	font-size: 11.5px;
	font-weight: $lato-medium;
	color: var(--text300);
	letter-spacing: 0.025em;

	text-transform: uppercase;

	//background: var(--bgSideMenuTabLight);
	border: 1px solid var(--line200);
	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.projectsPage-plannedProject-teamTag{
	opacity: 0.65;
	transition: opacity 50ms linear;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-teamTag{
	opacity: 1;
	transition: opacity 150ms linear;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-teamTag--team1{
	transition-delay: 50ms;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-teamTag--team2{
	transition-delay: 100ms;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-teamTag--team3{
	transition-delay: 150ms;
}


.projectsPage-plannedProject-leadAvatarContainer{
	width: 24px;
	height: 24px;

	margin-left: 12px;

	border-radius: 4px;
	overflow: hidden;
}

img.selectUserPopover-item-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	
}

.projectsPage-plannedProject-dropdownBtn{
	height: 24px;
	width: 24px;

	margin-left: 6px;

	border-radius: 4px;

	margin-right: 4px;
}

.projectsPage-plannedProject-dropdownBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.5;
}

.projectsPage-plannedProject
.projectsPage-plannedProject-dropdownBtn{
	opacity: 0;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-dropdownBtn{
	opacity: 1;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-dropdownBtn svg{
	opacity: 0.5;
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-dropdownBtn:hover{
	background: var(--250gray);	
}

.projectsPage-plannedProject:hover
.projectsPage-plannedProject-dropdownBtn:hover svg{
	opacity: 1;
}



// // DIVIDER
// .projectsPage-plannedProjectDateDivider{
// 	font-size: 14px;	
// 	font-weight: $lato-regular;
// 	letter-spacing: 0.01em;

	
// 	text-align: left;

// 	color: var(--text600);
// 	opacity: 0.5;

// 	margin-top: 14px;
// 	margin-bottom: 6px;

// 	user-select: none;
// 	cursor: default;

// 	padding-left: 3px;
// }

// // PROJECT

// .projectsPage-plannedProject{
// 	width: 100%;
// 	height: 38px;
// 	// background: $tempb;
// 	// outline: 1px solid blue;

// 	display: flex;
// 	align-items: center;

// 	box-sizing: border-box;

// 	cursor: pointer;

// 	border-radius: 5px;

// 	padding-left: 8px;
// 	margin-left: -8px;
// 	width: calc(100% + 8px);

// 	border: 1px solid $invisible;

// }

// .projectsPage-plannedProject:hover{
// 	background: var(--253gray);
// 	border: 1px solid var(--line100);
// }

// .projectsPage-plannedProject-emoji{
// 	font-size: 18px;
	
// 	height: 28px;
// 	width: 28px;

// 	margin-right: 8px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	background: var(--bgSideMenuTabLight);
// 	border-radius: 4px;
// }

// .projectsPage-plannedProject-name{
// 	font-size: 16px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.001em;
// 	color: var(--text500);

// 	margin-right: auto;
// }

// .projectsPage-plannedProject-teamTag{
// 	padding-left: 4px;
// 	padding-right: 4px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

// 	font-size: 11.5px;
// 	font-weight: $lato-medium;
// 	color: var(--text300);
// 	letter-spacing: 0.025em;

// 	text-transform: uppercase;

// 	//background: var(--bgSideMenuTabLight);
// 	border: 1px solid var(--line200);
// 	border-radius: 2px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: 4px;
// }

// .projectsPage-plannedProject-teamTag{
// 	opacity: 0.65;
// 	transition: opacity 50ms linear;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-teamTag{
// 	opacity: 1;
// 	transition: opacity 150ms linear;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-teamTag--team1{
// 	transition-delay: 50ms;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-teamTag--team2{
// 	transition-delay: 100ms;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-teamTag--team3{
// 	transition-delay: 150ms;
// }


// .projectsPage-plannedProject-leadAvatarContainer{
// 	width: 26px;
// 	height: 26px;

// 	margin-left: 12px;

// 	border-radius: 4px;
// 	overflow: hidden;
// }

// img.selectUserPopover-item-avatar{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;

	
// }

// .projectsPage-plannedProject-dropdownBtn{
// 	height: 24px;
// 	width: 24px;

// 	margin-left: 6px;

// 	border-radius: 4px;

// 	margin-right: 4px;
// }

// .projectsPage-plannedProject-dropdownBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .projectsPage-plannedProject
// .projectsPage-plannedProject-dropdownBtn{
// 	opacity: 0;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-dropdownBtn{
// 	opacity: 1;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-dropdownBtn svg{
// 	opacity: 0.5;
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-dropdownBtn:hover{
// 	background: var(--250gray);	
// }

// .projectsPage-plannedProject:hover
// .projectsPage-plannedProject-dropdownBtn:hover svg{
// 	opacity: 1;
// }
