.redirectContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999999;
	background: blue;

	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--bg-docEditor);
	background: rgb(252,252,254);

	user-select: none;
	cursor: default;
}

.redirectContainer-content{
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	margin-top: -5vw;
}

.redirectContainer-content-icon{	
	margin-bottom: 28px;
	display: flex;
	flex-direction: column;
	gap: 6px;

	width: 58px;
}

.redirectContainer-content-icon-bar{
	height: 10px;
	border-radius: 3px;
	width: 100%;
	background: var(--text600);
	opacity: 0.7;
}

.redirectContainer-content-icon-bar:nth-child(3){
	width: 70%;	
}

.redirectContainer-content-label{
	font-size: 16.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: var(--text600); 
	opacity: 0.7;
}