$height-startedControls-inlineUI: 68px;

.doc-video-inlineUI{
	position: absolute;
	top: $height-inlineVideo-header;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 50;

	// background: $tempr;
}

// HEADER

@import 'Doc-Video-InlineUI-Header'; // outside of top header
@import 'Doc-Video-InlineUI-PreControls'; // zoom button
@import 'Doc-Video-InlineUI-StartedControls'; // control bar
@import 'Doc-Video-InlineUI-HoverSeekButtons'; // panels for seeking back and forth

//

.doc-video-inlineUI--cursor--hide
button.doc-video-inlineUI-bgPlayPauseClick{
	cursor: none;
}

.doc-video-inlineUI-zoomPlayBtn,
.doc-video-inlineUI-controlBar,
.doc-video-captions{
	border-radius: 6px;

	background: rgba(2,2,5,0.75);
	backdrop-filter: blur(10px);

	z-index: 10;
}

.doc-video-captionsOuterContainer--inline
.doc-video-captions{
	background: rgba(2,2,5,0.65);
}




button.doc-video-inlineUI-bgPlayPauseClick{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 5;

	background: $invisible;
	// background: rgb(250,250,250);
	border: none;
	box-shadow: none;

	cursor: pointer;

}

//
// TOP RIGHT MENU
// most of styling in doc media top right menu



// .doc-video-inlineUI .doc-media-topRightMenu--hover{
// 	opacity: 0;	
// 	transition: opacity 100ms linear;
// }


.doc-video-inlineUI--mouseMovedShort--active:hover .doc-media-topRightMenu--hover,
.doc-video-inlineUI--mouseMovedShort--active:hover .doc-media-topRightMenu--hover:hover{
	opacity: 1;
	transition: opacity 50ms linear;
	// transition-delay: 50ms;
}

.doc-video-inlineUI .doc-media-topRightMenu--hover{
	opacity: 0;
	transition: opacity 150ms linear;
}
