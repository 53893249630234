.playgroundTranscriptProto{
	position: absolute;
	top: 0px;
	left: 00px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;

	overflow-y: scroll;

	box-sizing: border-box;
	padding-left: 200px;
	padding-right: 200px;

}

.playgroundtestvideoButtons{
	display: flex;
	width: 100%;
}

.playgroundtestvideoButtons button{
	background: $tempb;
	padding: 5px;
	margin: 4px;
}

.playgroundtestvideoButtonsSeekBar{
	background: $tempr;
	margin-top: 80px;
	height: 20px;
	position: relative;
	width: 500px;
}

.playgroundtestvideoButtonsSeekBar
.doc-video-inlineUI-controlBar-seekBar-track-0{
	background: $tempr;
}


// .warpImage{
// 	width: 150px;
// 	height: 150px;

// 	background: $tempr;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	flex-wrap: wrap;
// 	outline: 2px solid red;
// }


// .warpImageThird{
// 	width: 50px;
// 	height: 50px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// 	outline: 1px solid red;
// }


// .playgroundPage-warp{
// 	position: absolute;
// 	top: 100px;
// 	left: 100px;
// 	bottom: 100px;
// 	right: 100px;

// 	background: rgba(255,0,0,0.05);

// 	cursor: pointer;
// }

// .playgroundPage-warp--active{
// 	background: rgba(255,0,0,0.15);	
// }


// .playgroundPage-avatarSet{
// 	width: 80vw;

// 	display: flex;
// 	flex-wrap: wrap;

// 	align-items: center;
// 	justify-content: center;
// }

// .playgroundPage-avatarSet .avatarImage-doodleAvatar{
// 	margin: 24px;
// }


.playgroundPage-linearIcons{
	width: 60vw;
	display: flex;
	flex-wrap: wrap;
}

.playgroundPage-linearProjectIcon{
	width: 32px;
	height: 32px;

	margin: 4px;
	background: var(--250gray);
	border-radius: 4px;
}

.playgroundPage-linearProjectIcon svg{
	width: 32px;
	height: 32px;
}



.playgroundPage{
	width: 100vw;
	height: 100vh;
	background: var(--bg-docEditor);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	// padding-left: 16vw;
	// padding-top: 12vw;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 50;
}

.playgroundPage-toDosList{
	width: 650px;	
}


.resizeablePanelContainer{
	width: 80%;
	height: 80%;

	// background: $tempr;
	position: relative;
}


.resizablePanel{
	display: flex;
	// width: 100%;
	height: 100%;

	background: $tempr;

	position: relative;
}

.resizablePanel-dragArea{
	position: absolute;
	top: 0px;
	right: 0px;
	width: 50%;
	bottom: 0px;

	background: $invisible;
	pointer-events: none;;
}

.resizablePanel-resizeBar{	
	background: green;
	user-select: none;
	z-index: 10000;
	opacity: 0.5;
}

.resizablePanel-primaryContainer{
	height: 100%;
	
	background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.5;
}

.resizablePanel-primaryVideo{
	aspect-ratio: 16/9;
	width: 100%;
	max-height: 100%;
	background: red;
	flex-grow: 1;
	flex-shrink: 1;

	opacity: 0.5;
}

//

.resizablePanel-dragSpacer{
	height: 100%;
	background: $white;
}

//

.resizablePanel-narrationContainer{
	height: 100%;
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempb;
	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.5;
}

.resizablePanel-narrationContainer--popout{
	// background: $tempg;
	position: absolute;
	right: 0px;
	width: unset;
	bottom: 0px;
	top: 0px;	

	z-index: 10;
}

.resizablePanel-narrationVideo{
	aspect-ratio: 350/470;
	max-height: 100%;
	width: 100%;
	// height: 200px;
	background: blue;

	opacity: 0.5;
}

.resizablePanel-narrationContainer--popout
.resizablePanel-narrationVideo{
	position: absolute;
	bottom: 20px;
	right: 20px;

	width: 180px;
}

////

.colorPaletteOuterContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	flex-direction: column;

}

.colorPaletteContainer{
	width: 100%;
	height: 50%;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding: 36px;
}

.colorPaletteContainer--dark{
	background: rgb(28,26,32);
}

.colorPalette{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.accentColorSwatch{
	width: 100px;
	height: 50px;
	border-radius: 2px;

	background: $tempr;
}

@import 'Playground-MultiplayerSelection';
@import 'Playground-Issues';
@import 'Playground-LinearYarnColorMap';