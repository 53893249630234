// $width-workflowIcon: 15px;
$width-workflowIcon: 14px;
$borderWidth-workflowIcon: 1.5px;

.linearIssueWorkflowStateIconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}

// BACKLOG

.linearIssueWorkflowStateIcon--backlog{
	width: $width-workflowIcon;
	height: $width-workflowIcon;

	border-radius: 50%;
	border: $borderWidth-workflowIcon dashed var(--grey--yarn);
}

// UNSTARTED AKA TODO

.linearIssueWorkflowStateIcon--unstarted{
	width: $width-workflowIcon;
	height: $width-workflowIcon;

	border-radius: 50%;
	border: $borderWidth-workflowIcon solid var(--lightGrey--yarn);
}


// STARTED AKA IN PROGRESS

.linearIssueWorkflowStateIcon--started{
	display: flex;
	align-items: center;
	justify-content: center;

	width: $width-workflowIcon;
	height: $width-workflowIcon;

	border-radius: 50%;
	border: $borderWidth-workflowIcon solid var(--yellow--yarn);
}

.linearIssueWorkflowStateIcon--started svg{
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
	
}

.linearIssueWorkflowStateIconContainer--defaultColor
.linearIssueWorkflowStateIcon--started svg{
	fill: var(--yellow--yarn);
}

// COMPLETED AKA DONE

.linearIssueWorkflowStateIcon--completed{
	display: flex;
	align-items: center;
	justify-content: center;

	width: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});
	height: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});

	border-radius: 50%;
	background: var(--purple--yarn);
	position: relative;
}

.linearIssueWorkflowStateIcon--completed svg{
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
	stroke: var(--bg-docEditor);
	stroke-width: 10px;
	position: relative;
	top: 0.25px;
}

.linearIssueWorkflowStateIcon--completed--innerBorder{
	position: absolute;
	top: $borderWidth-workflowIcon;
	left: $borderWidth-workflowIcon;
	right: $borderWidth-workflowIcon;
	bottom: $borderWidth-workflowIcon;
	

	border-radius: 50%;
	outline: $borderWidth-workflowIcon solid rgba(0,0,0,0.05);
}

//
// CANCELED

.linearIssueWorkflowStateIcon--canceled{
	display: flex;
	align-items: center;
	justify-content: center;

	width: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});
	height: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});

	border-radius: 50%;
	background: var(--darkGrey--yarn);
	position: relative;
}

.linearIssueWorkflowStateIcon--canceled svg{
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
	fill: var(--bg-docEditor);
}

.linearIssueWorkflowStateIcon--canceled--innerBorder{
	position: absolute;
	top: $borderWidth-workflowIcon;
	left: $borderWidth-workflowIcon;
	right: $borderWidth-workflowIcon;
	bottom: $borderWidth-workflowIcon;
	

	border-radius: 50%;
	outline: $borderWidth-workflowIcon solid rgba(0,0,0,0.05);
}

//
// CANCELED

.linearIssueWorkflowStateIcon--triage{
	display: flex;
	align-items: center;
	justify-content: center;

	width: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});
	height: calc(#{$width-workflowIcon} + #{$borderWidth-workflowIcon} + #{$borderWidth-workflowIcon});

	border-radius: 50%;
	background: var(--red--yarn);
	position: relative;
}

.linearIssueWorkflowStateIcon--triage svg{
	// width: 12px;
	// height: 12px;
	width: 13px;
	height: 13px;
	fill: var(--bg-docEditor);
}

.linearIssueWorkflowStateIcon--triage--innerBorder{
	position: absolute;
	top: $borderWidth-workflowIcon;
	left: $borderWidth-workflowIcon;
	right: $borderWidth-workflowIcon;
	bottom: $borderWidth-workflowIcon;
	
	border-radius: 50%;
	outline: $borderWidth-workflowIcon solid rgba(0,0,0,0.05);
}
