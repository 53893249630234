.message-para-linearIssueOuterContainer{
	display: inline-block;
	height: 20px;
	
	position: relative;
	

	top: 3.5px;
	margin-top: -7px;

	//margin-top: -12px;

	cursor: pointer;


	background: var(--253gray);
	border-radius: 4px;

	border: 1px solid var(--line200);
	border-bottom: 1px solid var(--line300);

	cursor: pointer;

	margin-right: 1px;
	margin-left: 1px;

	//background: rgb(248,245,252);
}

.message-para-linearIssueOuterContainer:hover{
	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);
	transition: all 0ms linear;
	transition-delay: 50ms;

	z-index: 20; // for inline popover?
}

.message-para-linearIssueOuterContainer.ProseMirror-selectednode{
	background: var(--bg-docEditor);
	border: 1px solid var(--line500);
	border-bottom: 1px solid var(--line600);
}

.message:hover .message-para-linearIssueOuterContainer{
	background: var(--bg-docEditor);	
}

.message-para-inlineLinearIssueContainer{
	// we want to handle clicks inside of the react component

	width: calc(100% + 2px);
	height: calc(100% + 2px);

	box-sizing: border-box;
	padding: 1px;
	margin-top: -1px;
	margin-left: -1px;
	//outline: 1px solid rgba(255,0,0,0.2); // we want to capture clicks at the edge.
	border-radius: 5px;
}

.message-para-inlineLinearIssue{
	width: 100%;
	height: 100%;

	display: flex;
	
	align-items: center;

	padding-left: 2px;
	padding-right: 2px;
}

// ASSIGNEE

.message-para-inlineLinearIssue-assigneeContainer{
	width: 20px;
	height: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 4px;

	position: relative;
}

.message-para-inlineLinearIssue-assignee{
	width: 17px;
	height: 17px;
}

.message-para-inlineLinearIssue-assignee img{
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 3px;
	transform-origin: 50% 50%;
}

.message-para-inlineLinearIssue-assignee--unassigned{
	display: flex;
	align-items: center;
	justify-content: center;
}

.message-para-inlineLinearIssue-assignee--unassigned svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;
}

// ICON SHOWS ON HOVER
.message-para-inlineLinearIssue-hoverIconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-para-inlineLinearIssue-hoverIconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	margin-left: 1px;
}

//

.message-para-inlineLinearIssueContainer
.message-para-inlineLinearIssue-assignee{
	opacity: 1;
	transition: opacity 0ms linear;
}

.message-para-inlineLinearIssueContainer
.message-para-inlineLinearIssue-hoverIconContainer{
	opacity: 0;	
	transition: opacity 0ms linear;
}

.message-para-inlineLinearIssueContainer:hover
.message-para-inlineLinearIssue-assignee{
	opacity: 0;
	transition: opacity 0ms linear;
	transition-delay: 50ms;
}

.message-para-inlineLinearIssueContainer:hover
.message-para-inlineLinearIssue-hoverIconContainer{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 50ms;
}

// STATUS

.message-para-inlineLinearIssue-statusContainer{
	transform: scale(0.9);
}

//

.message-para-inlineLinearIssue-identifer{
	font-size: 13.5px;
	margin-right: 3px;
	color: var(--text500);
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	opacity: 0.8;
}

.message-para-inlineLinearIssueContainer:hover
.message-para-inlineLinearIssue-identifer{
	opacity: 1;
}

.message-para-inlineLinearIssue
.linearIssueWorkflowStateIcon--started{
	background: $white;
}

//
//

.message-para-inlineLinearIssue-popoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none !important;
}