.figmaViewZoomedModal{
	position: fixed;

	cursor: pointer;

	z-index: 50;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	// background: $tempr;

}

.figmaViewZoomedModal--visible{
	visibility: visible;
}

.figmaViewZoomedModal--hidden{
}

.figmaViewZoomedModal-viewWrapper{
	// background: $tempr;
	position: fixed;
	cursor: pointer;
	z-index: 80;
}

.figmaViewZoomedModal-zoomBG{
	
}

.doc-zoomedFigmaModal-centerBGClick{
	position: absolute;
	z-index: 0;
	// background: $tempr;
	cursor: pointer;
}

.figmaViewZoomedModal-zoomBG{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	cursor: pointer;

	z-index: 20;
	width: 100%;
	height: 100%;
	background: rgba(1,0,2,0.88);
}

.figmaViewZoomedModal-zoomBG--zoomed{
	cursor: default;
}

.figmaViewZoomedModal-viewWrapper--activeCrop{
	opacity: 0;
	pointer-events: none;	
}
