
//
// COLORS
//

//
// This is slightly messy (both inline and non-inline, )

//
// GREY

.linearMarkerContainer--color--grey.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--grey.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--grey--yarn);	
}

//
// LIGHT GREY

.linearMarkerContainer--color--lightGrey.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--lightGrey.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--lightGrey--yarn);	
}


//
// DARK GREY

.linearMarkerContainer--color--darkGrey.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--darkGrey.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--darkGrey--yarn);	
}

//
// PURPLE

.linearMarkerContainer--color--purple.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--purple.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--purple--yarn);	
}


//
// TEAL

.linearMarkerContainer--color--teal.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--teal.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--teal--yarn);	
}


//
// DARK TEAL

.linearMarkerContainer--color--darkTeal.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--darkTeal.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--darkTeal--yarn);	
}


//
// GREEN

.linearMarkerContainer--color--green.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--green.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--green--yarn);	
}


//
// DARK GREEN

.linearMarkerContainer--color--darkGreen.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--darkGreen.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--darkGreen--yarn);	
}

//
// YELLOW

.linearMarkerContainer--color--yellow.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--yellow.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--yellow--yarn);	
}


//
// BROWN

.linearMarkerContainer--color--brown.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--brown.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--brown--yarn);	
}

//
// ORANGE

.linearMarkerContainer--color--orange.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--orange.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--orange--yarn);	
}

//
// DARK ORANGE

.linearMarkerContainer--color--darkOrange.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--darkOrange.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--darkOrange--yarn);	
}


//
// PINK

.linearMarkerContainer--color--pink.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--pink.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--pink--yarn);	
}


//
// TEAL

.linearMarkerContainer--color--red.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--red.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--red--yarn);	
}


//
// TEAL

.linearMarkerContainer--color--darkRed.linearMarkerContainer--markerType--icon .linearMarker svg,
.inlineLinearMarkerContainer--color--darkRed.inlineLinearMarkerContainer--markerType--icon .inlineLinearMarker svg{	
	fill: var(--darkRed--yarn);	
}
