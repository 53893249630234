//

.libraryPageTipContainer{
	position: fixed;
	
	right: 12px;
	bottom: 12px;

	padding: 10px;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}


@media screen and (max-width: 1550px){
	.libraryPageTipContainer{
		display: none; // too small and with fixed positioning annoying
	}
}


.libraryPageTip{
	width: 100%;
	display: flex;
	flex-direction: column;

	border-radius: 10px;

	max-width: 355px;

	background: var(--253gray);

	box-sizing: border-box;
	padding-top: 14px;
	padding-bottom: 14px;
	padding-left: 18px;
	padding-right: 18px;

	user-select: none;
	cursor: default;
}

.libraryPageTip-avatar{
	font-size: 26px;
	margin-bottom: 6px;
}

.libraryPageTip-title{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	// flex-wrap: wrap;
	margin-bottom: 2px;
}

.libraryPageTip-title-label{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: var(--text500);
}

.libraryPageTip .keyboardShortcutTagContainer{
	margin-left: 6px;
}

.libraryPageTip .keyboardShortcutTag{
	display: flex;
	gap: 2px;
}

.libraryPageTip .keyboardShortcutTag-item{
	height: 22px;
	min-width: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	padding-left: 4px;
	padding-right: 4px;

	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);
	//box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);

	background: var(--bg-docEditor);
	background: none;


	// background: $tempr;

	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);
}



.libraryPageTip .keyboardShortcutTag-item--letter{
	font-weight: $lato-bold;
	font-size: 15px;
}

.libraryPageTip .keyboardShortcutTag-item svg{
	width: 16px;
	height: 16px;

	fill: var(--text400);
}

.libraryPageTip .keyboardShortcutTag-item span{
	margin-left: 2px;
	margin-right: 2px;
}




//

.libraryPageTip-description{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: var(--text300);

	letter-spacing: 0.004em;
	line-height: 1.25;
}

.libraryPageTip-description-para{
	margin-bottom: 5px;
}

.libraryPageTip-description-para:last-child{
	margin-bottom: 0px;
}