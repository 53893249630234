.doc-video-zoomedUI-hoverSeekButton{
	position: absolute;
	top: 120px;
	bottom: calc(120px + 88px); // offset for rows

	width: 70px;

	z-index: 1000;
	// background: $tempb;

	box-sizing: border-box;

	opacity: 0;
	transition: opacity 150ms linear;
	transition-delay: 50ms;
}

.doc-video-zoomedUI-hoverSeekButton:hover{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}

.doc-video-zoomedUI-hoverSeekButton--backwards{
	left: 0px;
	padding-left: 6px;
}

.doc-video-zoomedUI-hoverSeekButton--forwards{
	right: 0px;
	padding-right: 6px;
}

.doc-video-zoomedUI-hoverSeekButton:active
.doc-video-zoomedUI-hoverSeekButton-inner{
	background: rgb(2,2,5,0.40);
	transform: scale(1.02);

	transition: transform 50ms linear;
}

.doc-video-zoomedUI-hoverSeekButton-inner{
	width: 100%;
	height: 100%;
	background: rgb(2,2,5,0.35);

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: transform 100ms linear;
	backdrop-filter: blur(2px);
}

.doc-video-zoomedUI-hoverSeekButton-inner svg{
	width: 32px;
	height: 32px;
	fill: $white;
	transition: transform 100ms linear;
}

.doc-video-zoomedUI-hoverSeekButton:active
.doc-video-zoomedUI-hoverSeekButton-inner svg{
	transform: scale(1.03);
	transition: transform 50ms linear;
}