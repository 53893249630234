//
// FLOATING MENU
// used over images, and selection
:root{

	--outsideLineFloatingMenu: 				hsl(240, 3%, 83%); 
	--outsideTopLineFloatingMenu: 		hsl(240, 3%, 83%); 
	--outsideBottomLineFloatingMenu: 	hsl(240, 4%, 82%); 
	--insideLineFloatingMenu: rgb(236,234,236); // between buttons
	
	--boxShadowFloatingMenu: 0px 1px 2px 0px rgba(0,0,0,0.04);	
	--boxShadowFloatingMenuStrong: 0px 4px 8px 0px rgba(0,0,0,0.08);	 // used for insert menu

	--bgFloatingMenu: 			rgb(254,251,254);
	--bgFloatingMenu: 			rgb(255,253,255); // i like it
	--hoverBGFloatingMenu: 	rgb(255,255,255);
	--hoverBGFloatingMenu: 	rgb(248,246,248);

	--fillIconBGFloatingMenu: rgb(255,255,255);

	--tooltipOffsetFloatingMenu: -1px;

	--bgInlineKeyboardShortcutTag: rgba(0,0,0,0.02);
	--colorInlineKeyboardShortcutTag: rgba(65,60,70,0.8);
	// --bgInlineKeyboardShortcutTag: hsl(212, 100%, 97%);
	// --colorInlineKeyboardShortcutTag: hsl(213, 100%, 40%);

	// --hoverBGInlineKeyboardShortcutTag: hsl(213, 100%, 50%);
	// --hoverColorInlineKeyboardShortcutTag: rgb(255,255,255);
	--hoverBGInlineKeyboardShortcutTag: hsl(212, 100%, 93%);
	--hoverColorInlineKeyboardShortcutTag: hsl(213, 100%, 35%);

	--outsideLineSearchMenu: 				hsl(240, 3%, 85%); 
	--outsideTopLineSearchMenu: 		hsl(240, 3%, 85%); 
	--outsideBottomLineSearchMenu: 	hsl(240, 4%, 84%); 
}

[data-theme="dark"]{
	
	--outsideLineFloatingMenu: 				rgb(60,60,64);
	--outsideTopLineFloatingMenu: 		rgb(70,70,74);
	--outsideBottomLineFloatingMenu: 	rgb(62,60,64);

	--outsideLineSearchMenu: 				rgb(60,60,64);
	--outsideTopLineSearchMenu: 		rgb(70,70,74);
	--outsideBottomLineSearchMenu: 	rgb(62,60,64);

	--insideLineFloatingMenu: rgb(72,70,74); // between buttons

	--boxShadowFloatingMenu: none;

	//--bgFloatingMenu: 			rgb(64,54,64);
	//--bgFloatingMenu: 			rgb(52,50,55);
	--bgFloatingMenu: 			rgb(49,46,53);
	--hoverBGFloatingMenu: 	rgb(64,62,66);
	
	--fillIconBGFloatingMenu: rgb(255,255,255,0.04);

	--tooltipOffsetFloatingMenu: -3px;

}
