.doc-video-inlineUI-hoverSeekButton{
	position: absolute;
	top: 80px;
	bottom: 80px;

	width: 70px;

	z-index: 50;

	box-sizing: border-box;

	opacity: 0;
	transition: opacity 150ms linear;
	transition-delay: 50ms;
}

.doc-video-inlineUI-hoverSeekButton:hover{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}

.doc-video-inlineUI-hoverSeekButton--backwards{
	left: 0px;
	padding-left: 6px;
}

.doc-video-inlineUI-hoverSeekButton--forwards{
	right: 0px;
	padding-right: 6px;
}

.doc-video-inlineUI-hoverSeekButton:active
.doc-video-inlineUI-hoverSeekButton-inner{
	background: rgb(2,2,5,0.4);
	transform: scale(1.02);

	transition: transform 50ms linear;
}

.doc-video-inlineUI-hoverSeekButton-inner{
	width: 100%;
	height: 100%;
	background: rgb(2,2,5,0.35);

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: transform 100ms linear;
	backdrop-filter: blur(2px);
}

.doc-video-inlineUI-hoverSeekButton-inner svg{
	width: 30px;
	height: 30px;
	fill: $white;
	transition: transform 100ms linear;
}

.doc-video-inlineUI-hoverSeekButton:active
.doc-video-inlineUI-hoverSeekButton-inner svg{
	transform: scale(1.03);
	transition: transform 50ms linear;
}