.linearIssuePreviewPopoverOuterContainer:focus{
	outline: none;
}

.linearIssuePreviewPopoverOuterContainer,
.linearIssuePreviewPopoverOuterContainer *{
	pointer-events: none !important;
}



.linearIssuePreviewPopover{
	min-width: 350px;
	max-width: 500px;
	//min-height: 370px;

	box-sizing: border-box;

	padding-top: 12px;
	padding-bottom: 16px;

	padding-left: 20px;
	padding-right: 20px;

	cursor: default;
	user-select: none;

	border-radius: 6px;;
}

// 

.linearIssuePreviewPopover-projectTagContainer{
	display: flex;

	width: 100%;

	margin-bottom: 4px;	
}

.linearIssuePreviewPopover-projectTag{
	display: flex;
	align-items: center;

	background: var(--253gray);
	
	padding-left: 6px;
	padding-right: 6px;
	border-radius: 4px;

	height: 22px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


}

.linearIssuePreviewPopover-projectTag-markerContainer{	
	margin-right: 5px;

	flex-grow: 0;
	flex-shrink: 0;	
}

.linearIssuePreviewPopover-projectTag-markerContainer
.linearMarker{
	font-size: 14px;
}

.linearIssuePreviewPopover-projectTag-markerContainer 
.linearMarker svg{
	width: 14px;
	height: 14px;
}

.linearIssuePreviewPopover-projectTag-label{
	font-size: 13.75px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.85;
	letter-spacing: 0.008em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

//

.linearIssuePreviewPopover-identifier{
	margin-top: 7px;
	margin-bottom: 9px;

	font-size: 13.75px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: var(--primary600);
}

[data-theme="dark"] .linearIssuePreviewPopover-identifier{
	color: rgb(111, 173, 249); // to do, check on primary 600 in dark
}


//

.linearIssuePreviewPopover-title{
	font-size: 18px;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	color: var(--text600);

	text-align: left;

	overflow: hidden;

	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  

  line-height: 1.2;

  width: 0px;
	min-width: 100%;
	// makes it not contribute to width, otherwise will keep growing
}

//

.linearIssuePreviewPopover-summaryRow{
	display: flex;
	align-items: center;
	width: 100%;

	margin-top: 18px;
}

//

.linearIssuePreviewPopover-summaryRow-assignee,
.linearIssuePreviewPopover-summaryRow-status,
.linearIssuePreviewPopover-summaryRow-priority{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 11px;
	
}

.linearIssuePreviewPopover-summaryRow-assignee-avatar{
	width: 20px;
	height: 20px;
	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.linearIssuePreviewPopover-summaryRow-assignee-avatar img{
	width: 20px;
	height: 20px;
	border-radius: 3px;
}

.linearIssuePreviewPopover-summaryRow-assignee-avatar svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.5;
}

//

.linearIssuePreviewPopover-summaryRow-status-iconContainer{
	transform: scale(0.9);
}


//
.linearIssuePreviewPopover-summaryRow-assignee-name,
.linearIssuePreviewPopover-summaryRow-status-label,
.linearIssuePreviewPopover-summaryRow-priority-label{
	font-size: 14px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.9;
	margin-left: 2px;
}

.linearIssuePreviewPopover-summaryRow-assignee--assigned
.linearIssuePreviewPopover-summaryRow-assignee-name{
	font-weight: $lato-semibold;
	opacity: 1;
}

.linearIssuePreviewPopover-summaryRow-assignee--unassigned
.linearIssuePreviewPopover-summaryRow-assignee-name{
	font-weight: $lato-semibold;
	opacity: 0.65;
	margin-left: -1px;
}



// 

.linearIssuePreviewPopover-divider{
	width: 100%;
	height: 1px;
	background: var(--line300);

	margin-top: 10px;
	margin-bottom: 9px;
}

//

.linearIssuePreviewPopover-descriptionContainer{
	width: 0px;
	min-width: 100%;
	// makes it not contribute to width, otherwise will keep growing

	max-height: 211px;

	overflow: hidden;
	position: relative;

	min-height: 50px;
}

.linearIssuePreviewPopover-descriptionContainer--noDescription{
	
}

.linearIssuePreviewPopover-description{
	
	font-size: 14.5px;
	font-weight: $lato-regular;
	color: var(--text500);
	letter-spacing: 0.008em;
	opacity: 0.75;
	
	// display: -webkit-box;
  // -webkit-line-clamp: 8;
  // -webkit-box-orient: vertical;

  line-height: 1.3;
  text-align: left;
}


.linearIssuePreviewPopover-descriptionContainer--noDescription{
	opacity: 0.6;
}


.linearIssuePreviewPopover-description-para{
	margin-bottom: 10px;
}

.linearIssuePreviewPopover-descriptionOverflowGradient{
	
	background: $tempr;

	position: absolute;
	top: 211px; // only show if overflow
	left: 0px;
	right: 0px;
	
	margin-top: -50px;
	height: 50px;

	background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.013) 8.1%,
    hsla(0, 0%, 100%, 0.049) 15.5%,
    hsla(0, 0%, 100%, 0.104) 22.5%,
    hsla(0, 0%, 100%, 0.175) 29%,
    hsla(0, 0%, 100%, 0.259) 35.3%,
    hsla(0, 0%, 100%, 0.352) 41.2%,
    hsla(0, 0%, 100%, 0.45) 47.1%,
    hsla(0, 0%, 100%, 0.55) 52.9%,
    hsla(0, 0%, 100%, 0.648) 58.8%,
    hsla(0, 0%, 100%, 0.741) 64.7%,
    hsla(0, 0%, 100%, 0.825) 71%,
    hsla(0, 0%, 100%, 0.896) 77.5%,
    hsla(0, 0%, 100%, 0.951) 84.5%,
    hsla(0, 0%, 100%, 0.987) 91.9%,
    hsl(0, 0%, 100%) 100%
  );

}

[data-theme="dark"] .linearIssuePreviewPopover-descriptionOverflowGradient{
	background: linear-gradient(
    to bottom,
    rgba(36,31,38, 0) 0%,
    rgba(36,31,38, 0.013) 8.1%,
    rgba(36,31,38, 0.049) 15.5%,
    rgba(36,31,38, 0.104) 22.5%,
    rgba(36,31,38, 0.175) 29%,
    rgba(36,31,38, 0.259) 35.3%,
    rgba(36,31,38, 0.352) 41.2%,
    rgba(36,31,38, 0.45) 47.1%,
    rgba(36,31,38, 0.55) 52.9%,
    rgba(36,31,38, 0.648) 58.8%,
    rgba(36,31,38, 0.741) 64.7%,
    rgba(36,31,38, 0.825) 71%,
    rgba(36,31,38, 0.896) 77.5%,
    rgba(36,31,38, 0.951) 84.5%,
    rgba(36,31,38, 0.987) 91.9%,
    rgba(36,31,38, 1) 100%
  );

}

//

.linearIssuePreviewPopover-labelsContainer{
	display: flex;
	flex-wrap: wrap;
	align-items: center;	

	margin-top: 12px;
}

.linearIssuePreviewPopover-labels-label{
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;
	padding-left: 7px;
	padding-right: 10px;

	background: var(--250gray);

	margin-right: 8px;
	margin-bottom: 4px;
	position: relative;
}

.linearIssuePreviewPopover-labels-label-dot{
	width: 8px;
	height: 8px;
	border-radius: 50%;

	margin-right: 5px;

	position: relative;
	z-index: 5;
}

.linearIssuePreviewPopover-labels-label-name{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 0.8;

	position: relative;
	top: -0.5px;
	z-index: 5;
}
