

$bg-stormTheme: #25283A;
$bg-sublimeTheme: #323840;
$bg-vampireTheme: #282A35;
$bg-artifactDarkTheme: rgb(36,34,44);
$bg-artifactLightTheme: rgb(252,251,255);


.doc-codeMirrorWrapper{
	width: calc(100% + 40px);
	margin-left: -20px;

	padding-top: 26px;
	padding-bottom: 8px;

	border-radius: 3px;

	position: relative;
}

.doc-codeMirrorWrapper--theme--artifactDark{
	background: $bg-artifactDarkTheme;
}

.doc-codeMirrorWrapper--theme--artifactLight{
	background: $bg-artifactLightTheme;
}

.doc-codeMirrorWrapper--theme--storm{
	background: $bg-stormTheme;
}

.doc-codeMirrorWrapper--theme--sublime{
	background: $bg-sublimeTheme;
}

.doc-codeMirrorWrapper--theme--vampire{
	background: $bg-vampireTheme;
}


//
//

.doc-codeMirror-menu{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	

	// margin-top: -30px;
	height: 27px;

	border-radius: 5px;

	// background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 3px

}

.doc-codeMirror-menu-langBtn{
	padding-left: 14px;
	padding-right: 14px;
	height: 22px;
	// background: $tempr;
	opacity: 0.5;

	margin-top: 4px;

	border-radius: 3px;

	box-sizing: border-box;
	// padding-top: 1px;
	cursor: pointer;
}

.doc-codeMirror-menu-langBtn:hover{
	opacity: 1;
	
}

.doc-codeMirror-menu--lightUI
.doc-codeMirror-menu-langBtn:hover{
	background: var(--250gray);
}

.doc-codeMirror-menu--darkUI
.doc-codeMirror-menu-langBtn:hover{
	background: rgba(255,255,255,0.05);
}

.doc-codeMirrorWrapper--theme--storm
.doc-codeMirror-menu-langBtn:hover{
	//background: transparentize(#565f89, 0.6);
	//background: transparentize(#bb9af7, 0.2);
	background: transparentize(#bb9af7, 0.8);

}



.doc-codeMirror-menu-langBtn-iconContainer{

}

.doc-codeMirror-menu-langBtn-label{
	font-size: 14px;
	font-weight: $lato-regular;
	letter-spacing: 0.012em;
	
}

.doc-codeMirror-menu--lightUI
.doc-codeMirror-menu-langBtn{
	color: var(--text600);
}

.doc-codeMirror-menu--darkUI
.doc-codeMirror-menu-langBtn{
	color: white;
	opacity: 0.4;
}




//

.doc-codeMirror-menu-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

//

.doc-codeMirror-menu-dropdownBtn{
	width: 32px;
	height: 100%;
}

.doc-codeMirror-menu-dropdownBtn svg{
	width: 14px;
	height: 14px;

	opacity: 0.5;
}



.doc-codeMirror-menu--lightUI
.doc-codeMirror-menu-dropdownBtn svg{
	fill: var(--text600);
}

.doc-codeMirror-menu--darkUI
.doc-codeMirror-menu-dropdownBtn svg{
	fill: white;
}




.doc-codeMirror-menu-dropdownBtn:hover svg{
	opacity: 1;
}

//
// CODEMIRROR CUSTOM

.cm-gutterElement{
	opacity: 0.5;
}

@import 'Doc-CodeMirror-DraculaTheme';



//
//
//


.codeMirrorMenuLanguagePopoverContainer{	
	
}

.codeMirrorMenuLanguagePopoverContainer:focus{
	outline: none;
}

.codeMirrorMenuLanguagePopoverContainer--hidden,
.codeMirrorMenuLanguagePopoverContainer--hidden *{
	pointer-events: none !important;
}


//

.codeMirrorLanguagePopover{
	width: 240px;
	// min-height: 440px;
	// max-height: 80vh;

	// height: 400px;

	box-sizing: border-box;
	padding-top: 4px;
	//padding-bottom: 12px;
	//padding-bottom: 14px;
	border-radius: 5px;

	padding-left: 4px;
	padding-right: 4px;

	display: flex;
	flex-direction: column;
}



//

.codeMirrorLanguagePopover-search{
	width: 100%;
	height: 26px;
	position: relative;

	margin-bottom: 4px;

	flex-grow: 0;
	flex-shrink: 0;
}


.codeMirrorLanguagePopover-list{
	width: calc(100% - 4px);
	margin-left: 2px;

	flex-grow: 1;
	flex-shrink: 1;

	max-height: min(calc(80vh - 100px), 600px);

	padding-top: 2px;
	padding-bottom: 8px;

	overflow-y: scroll;
}

.codeMirrorLanguagePopover-list--empty{
	min-height: 400px;	
}

.codeMirrorLanguagePopover-list--results{
	min-height: 400px;
}


//

.codeMirrorLanguagePopover-search-input{
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	padding-right: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	box-sizing: border-box;

	padding-left: 24px;

	position: absolute;
	border: none;
	border-radius: 3px;

	font-size: 14px;
	font-weight: $lato-medium;
	color: var(--text600);

	border: 1px solid $invisible;
	background: var(--250gray);
}

.codeMirrorLanguagePopover-search-input::placeholder{
	color: var(--toDoItem-placeholderColor);
}

.codeMirrorLanguagePopover-search-input:focus{
	background: var(--bg-docEditor);	
	border: 1px solid var(--primary400);
	outline: 2px solid var(--primary25);
}

.codeMirrorLanguagePopover--theme--sublime .codeMirrorLanguagePopover-search-input:focus{
	background: darken($bg-stormTheme, 3%);
}

.codeMirrorLanguagePopover--theme--sublime .codeMirrorLanguagePopover-search-input:focus{
	background: darken($bg-sublimeTheme, 3%);
}

.codeMirrorLanguagePopover--theme--sublime .codeMirrorLanguagePopover-search-input:focus{
	background: darken($bg-vampireTheme, 3%);
}


.codeMirrorLanguagePopover--theme--sublime .codeMirrorLanguagePopover-search-input:focus{
	background: darken($bg-artifactDarkTheme, 3%);
}

.codeMirrorLanguagePopover--theme--sublime .codeMirrorLanguagePopover-search-input:focus{
	background: darken($bg-artifactLightTheme, 3%);
}




[data-theme="dark"], .forceDarkTheme{
	.codeMirrorLanguagePopover-search-input:focus{
		outline: none;
	}
}

.codeMirrorLanguagePopover-search-ui-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;

	width: 24px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	box-sizing: border-box;
	padding-left: 4px;
}

.codeMirrorLanguagePopover-search-ui-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--toDoItem-placeholderColor);
	opacity: 0.8;
}

//


.popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover{
	background: var(--hoverBG-item-dropdown);
}

.popover-item--codeMirrorLanguageSelection[data-state="active"],
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover{
	background: var(--activeBG-suggestionItem);
}


.dropdownMenu--codeMirrorLanguageSelection
.dropdownMenu-subHeader{
	font-size: 10.5px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.popover-item--codeMirrorLanguageSelection,
.dropdownMenu--codeMirrorLanguageSelection div[role='menuitem']{
	border-radius: 3px;
	height: 25px;
}

.popover-item--codeMirrorLanguageSelection .popover-item-label,
.dropdownMenu--codeMirrorLanguageSelection  div[role='menuitem']{
	// margin-left: 3px;
	font-size: 14px;
}

.popover-item--codeMirrorLanguageSelection .popover-item-label{
	margin-left: 3px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;
}

.dropdownMenu--codeMirrorLanguageSelection  div[role='menuitem']{
	box-sizing: border-box;
	padding-bottom: 2px;
}


.popover-item--codeMirrorLanguageSelection[data-state="active"] .popover-item-label,
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover .popover-item-label{
	color: var(--text600);
}


//
//

.codeMirrorLanguagePopover--theme--storm,
.dropdownMenu--codeMirrorLanguageSelection--theme--storm{
	background: lighten($bg-stormTheme, 4%);
}

.codeMirrorLanguagePopover--theme--storm
.popover-item--codeMirrorLanguageSelection[data-state="inactive"],
.dropdownMenu--codeMirrorLanguageSelection--theme--storm div[role='menuitem']{
	background: lighten($bg-stormTheme, 4%);
}

.codeMirrorLanguagePopover--theme--storm
.popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--storm div[role='menuitem']:hover{
	background: lighten($bg-stormTheme, 7%);
}

.codeMirrorLanguagePopover--theme--storm
.popover-item--codeMirrorLanguageSelection[data-state="active"],
.codeMirrorLanguagePopover--theme--storm
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--storm
.suggestionMenu-item--active,
.dropdownMenu--codeMirrorLanguageSelection--theme--storm
.suggestionMenu-item--active:hover{
	background: lighten($bg-stormTheme, 10%) !important;
}

//

.codeMirrorLanguagePopover--theme--sublime,
.dropdownMenu--codeMirrorLanguageSelection--theme--sublime{
	background: lighten($bg-sublimeTheme, 4%);
}

.codeMirrorLanguagePopover--theme--sublime
.popover-item--codeMirrorLanguageSelection[data-state="inactive"],
.dropdownMenu--codeMirrorLanguageSelection--theme--sublime div[role='menuitem']{
	background: lighten($bg-sublimeTheme, 4%);
}

.codeMirrorLanguagePopover--theme--sublime
.popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--sublime div[role='menuitem']:hover{
	background: lighten($bg-sublimeTheme, 7%);
}

.codeMirrorLanguagePopover--theme--sublime
.popover-item--codeMirrorLanguageSelection[data-state="active"],
.codeMirrorLanguagePopover--theme--sublime
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--sublime
.suggestionMenu-item--active,
.dropdownMenu--codeMirrorLanguageSelection--theme--sublime
.suggestionMenu-item--active:hover{
	background: lighten($bg-sublimeTheme, 10%) !important;
}

//

.codeMirrorLanguagePopover--theme--vampire,
.dropdownMenu--codeMirrorLanguageSelection--theme--vampire{
	background: lighten($bg-vampireTheme, 4%);
}

.codeMirrorLanguagePopover--theme--vampire
.popover-item--codeMirrorLanguageSelection[data-state="inactive"],
.dropdownMenu--codeMirrorLanguageSelection--theme--vampire div[role='menuitem']{
	background: lighten($bg-vampireTheme, 4%);
}

.codeMirrorLanguagePopover--theme--vampire
.popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--vampire div[role='menuitem']:hover{
	background: lighten($bg-vampireTheme, 7%);
}

.codeMirrorLanguagePopover--theme--vampire
.popover-item--codeMirrorLanguageSelection[data-state="active"],
.codeMirrorLanguagePopover--theme--vampire
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--vampire
.suggestionMenu-item--active,
.dropdownMenu--codeMirrorLanguageSelection--theme--vampire
.suggestionMenu-item--active:hover{
	background: lighten($bg-vampireTheme, 10%) !important;
}

//

.codeMirrorLanguagePopover--theme--artifactDark,
.dropdownMenu--codeMirrorLanguageSelection--theme--artifactDark{
	background: lighten($bg-artifactDarkTheme, 4%);
}

.codeMirrorLanguagePopover--theme--artifactDark
.popover-item--codeMirrorLanguageSelection[data-state="inactive"],
.dropdownMenu--codeMirrorLanguageSelection--theme--artifactDark div[role='menuitem']{
	background: lighten($bg-artifactDarkTheme, 4%);
}

.codeMirrorLanguagePopover--theme--artifactDark
.popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--artifactDark div[role='menuitem']:hover{
	background: lighten($bg-artifactDarkTheme, 7%);
}

.codeMirrorLanguagePopover--theme--artifactDark
.popover-item--codeMirrorLanguageSelection[data-state="active"],
.codeMirrorLanguagePopover--theme--artifactDark
.popover-item--codeMirrorLanguageSelection[data-state="active"]:hover,
.dropdownMenu--codeMirrorLanguageSelection--theme--artifactDark
.suggestionMenu-item--active,
.dropdownMenu--codeMirrorLanguageSelection--theme--artifactDark
.suggestionMenu-item--active:hover{
	background: lighten($bg-artifactDarkTheme, 10%) !important;
}



// .codeMirrorLanguagePopover--theme--artifactLight,
// .dropdownMenu--codeMirrorLanguageSelection--theme--artifactLight{
// 	background: darken($bg-artifactLightTheme, 4%);
// }

// .codeMirrorLanguagePopover--theme--artifactLight
// .popover-item--codeMirrorLanguageSelection[data-state="inactive"],
// .dropdownMenu--codeMirrorLanguageSelection--theme--artifactLight div[role='menuitem']{
// 	background: da($bg-artifactLightTheme, 4%);
// }

// .codeMirrorLanguagePopover--theme--artifactLight
// .popover-item--codeMirrorLanguageSelection[data-state="inactive"]:hover,
// .dropdownMenu--codeMirrorLanguageSelection--theme--artifactLight div[role='menuitem']:hover{
// 	background: lighten($bg-artifactLightTheme, 7%);
// }

// .codeMirrorLanguagePopover--theme--artifactLight
// .popover-item--codeMirrorLanguageSelection[data-state="active"],
// .codeMirrorLanguagePopover--theme--artifactLight
// .popover-item--codeMirrorLanguageSelection[data-state="active"]:hover,
// .dropdownMenu--codeMirrorLanguageSelection--theme--artifactLight
// .suggestionMenu-item--active,
// .dropdownMenu--codeMirrorLanguageSelection--theme--artifactLight
// .suggestionMenu-item--active:hover{
// 	background: lighten($bg-artifactLightTheme, 10%) !important;
// }





//
// CUSTOM CODEMIRROR

.cm-editor{
	font-size: 13px;
}

.doc-codeMirrorWrapper
.cm-focused{
	outline: none !important;
}

.doc-codeMirrorWrapper--theme--artifactLight .cm-gutters,
.doc-codeMirrorWrapper--theme--artifactDark .cm-gutters{
	border-right: none;
}

.doc-codeMirrorWrapper--theme--artifactDark .cm-gutterElement{
	opacity: 0.4;
}

.doc-codeMirrorWrapper--theme--artifactDark  .cm-gutters .cm-activeLineGutter{
	background: lighten($bg-artifactDarkTheme, 3%);
	opacity: 0.9;
}


.doc-codeMirrorWrapper--theme--artifactLight .cm-gutterElement{
	opacity: 0.6;
}

.doc-codeMirrorWrapper--theme--artifactLight .cm-gutters .cm-activeLineGutter{
	background: darken($bg-artifactLightTheme, 2%);
	opacity: 1;
}


.doc-codeMirrorWrapper--theme--artifactLight .cm-gutter.cm-lineNumbers .cm-gutterElement,
.doc-codeMirrorWrapper--theme--artifactDark .cm-gutter.cm-lineNumbers .cm-gutterElement{
	min-width: 32px;
} 

.cm-gutterElement span[title="Fold line"],
.cm-gutterElement span[title="Unfold line"]{
	font-size: 15px;
	font-weight: $lato-semibold;
	position: relative;
	top: -2px;
}

.cm-gutterElement span[title="Fold line"]{
	top: -6px;	
}