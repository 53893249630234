

.roadmapsHomePage-roadmapGrid-item-activityContainer{
	box-sizing: border-box;
	// padding-top: 20px;
	// padding-bottom: 12px;
	// padding-right: 12px;

	width: 400px;
	margin-left: 1px;
	height: 120px;

	margin-top: 20px;
	// background: $tempr;
}

.roadmapsHomePage-roadmapGrid-item-activityContainer,
.roadmapsHomePage-roadmapGrid-item-activityContainer *{
	cursor: default !important;
}

$completedColorHeatmap: rgb(85,104,249);

.react-calendar-heatmap-month-labels{
	position: relative;
	top: -10px;
}

.react-calendar-heatmap-month-label{	
	transform: translateY(-2px);
}

[data-theme="dark"]{
	.react-calendar-heatmap-month-label{
		opacity: 0.5;
	}
}

.react-calendar-heatmap
 text.react-calendar-heatmap-month-label{
	font-size: 10.5px;
}


.react-calendar-heatmap rect:hover{
	stroke: $invisible !important;
	stroke-width: 0px !important;
}

.react-calendar-heatmap .color-empty,
.react-calendar-heatmap .color-scale-0 { 
	fill: transparentize($completedColorHeatmap, 0.95);
	fill: var(--251gray);
}

.react-calendar-heatmap .color-scale-1 {
	fill: transparentize($completedColorHeatmap, 0.75);
	//fill: brown;
}

.react-calendar-heatmap .color-scale-2 {
	fill: transparentize($completedColorHeatmap, 0.6);
	//fill: yellow;
}

.react-calendar-heatmap .color-scale-3 {
	fill: transparentize($completedColorHeatmap, 0.45);
	//fill: blue;
}

.react-calendar-heatmap .color-scale-4 {
	fill: transparentize($completedColorHeatmap, 0.3);
	//fill: green;
}

.react-calendar-heatmap .color-scale-5 {
	fill: transparentize($completedColorHeatmap, 0.15);
	//fill: red;
}

[data-theme="dark"]{
	.react-calendar-heatmap .color-empty,
	.react-calendar-heatmap .color-scale-0 { 
		fill: transparentize($completedColorHeatmap, 0.87);
	}

	.react-calendar-heatmap .color-scale-1 {
		fill: transparentize($completedColorHeatmap, 0.5);
	}

	.react-calendar-heatmap .color-scale-2 {
		fill: transparentize($completedColorHeatmap, 0.45);
	}

	.react-calendar-heatmap .color-scale-3 {
		fill: transparentize($completedColorHeatmap, 0.4);
	}

	.react-calendar-heatmap .color-scale-4 {
		fill: transparentize($completedColorHeatmap, 0.35);
	}

	.react-calendar-heatmap .color-scale-5 {
		fill: transparentize($completedColorHeatmap, 0.3);
	}	
}
