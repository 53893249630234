
// REPLY AND NEW COMMENT

.docEditor-commentsPanel-commentGroup-commentReply{
	margin-top: 1px;

	width: 100%;
	border: 1px solid $invisible;
	// background: $tempr;

	box-sizing: border-box;

	padding-left: 10px;
	padding-right: 10px;

	position: relative;

}

.docEditor-commentsPanel-commentGroup-commentReply-ghostBox,
.docEditor-commentsPanel-commentGroup-comment--new-ghostBox{
	min-height: 0px;
	padding-bottom: 30px; // space for thing
}

.docEditor-commentsPanel-commentGroup-commentReply-ghostBox{
	padding-bottom: 28px;
}

.docEditor-commentsPanel-commentGroup-commentReply.docEditor-commentsPanel-commentGroup-commentReply--isFocused
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox,
.docEditor-commentsPanel-commentGroup-commentReply.docEditor-commentsPanel-commentGroup-commentReply--notEmpty
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox{
	min-height: 140px;
}

.docEditor-commentsPanel-commentGroup-comment--new-textAreaContainer{
	position: relative;
}


textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea,
textarea.docEditor-commentsPanel-commentGroup-comment--new-textArea,
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox,
.docEditor-commentsPanel-commentGroup-comment--new-ghostBox{
	font-weight: $lato-regular;
	font-size: 15px;
	color: var(--text500);
	letter-spacing: 0.01em;

	line-height: 1.4;
}

textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea,
textarea.docEditor-commentsPanel-commentGroup-comment--new-textArea{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;

	cursor: text;
	background: $white;
}


textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea,
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox{
	box-sizing: border-box;
	padding-left: 9px;
	padding-top: 4px;

	left: 10px; // same as left padding
	right: 10px; // same as right padding

	border-radius: 5px;
	border: 1px solid var(--line400);
}


.docEditor-commentsPanel-commentGroup--active
textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea{
	//border: 1px solid darken($lineDark, 5%);
	border: 1px solid var(--line500);
}


.docEditor-commentsPanel-commentGroup-comment--new-ghostBox,
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox{
	color: $invisible;	
}

	
.docEditor-commentsPanel--devStyling
.docEditor-commentsPanel-commentGroup-commentReply-ghostBox{
	color: blue;
}

textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea{	
	resize: none;

	overflow-y: hidden;	
}

.docEditor-commentsPanel--devStyling
textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea{
	color: red;
	opacity: 0.5;
}


// .docEditor-commentsPanel-commentGroup-commentReply--notEmpty
// textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea,
// .docEditor-commentsPanel-commentGroup-commentReply--empty
// textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea:focus{
// 	height: 100px;
// }

textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea:focus{
	border: 1px solid var(--line600);
	outline: none;
}

textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea::placeholder{
	color: var(--text200);
}

// TO DO FIX FOR DARK THEME
.docEditor-commentsPanel-commentGroup--active
textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea::placeholder{
	// color: lighten($textLight, 10%);
	color: var(--text200);
}

textarea.docEditor-commentsPanel-commentGroup-commentReply-textArea:focus::placeholder{
	// color: lighten($textLight, 20%);
	color: var(--text200);
}


// CANCEL BUTTON

button.docEditor-commentsPanel-commentGroup-comment-cancelBtn{
	position: absolute;
	top: -12px;
	right: -12px;
	// background: $tempr;

	padding-left: 8px;
	padding-right: 8px;
	height: 26px;

	font-size: 14px;
	font-weight: $lato-regular;
	color: lighten(rgb(85,83,79), 30%);

	border-radius: 4px;
}

button.docEditor-commentsPanel-commentGroup-comment-cancelBtn:hover{
	background: rgb(248,245,245);
	color: var(--text500);
}



// BTN ROW


.docEditor-commentsPanel-commentGroup-commentReply-btnRow,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow{
	display: flex;
	height: 30px;
	
	align-items: center;

	box-sizing: border-box;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow{
	position: absolute;
	bottom: 4px;
	left: 11px;
	right: 11px;
	right: -10px;

	padding-left: 8px;
}

.docEditor-commentsPanel-commentGroup-commentReply--empty
.docEditor-commentsPanel-commentGroup-commentReply-btnRow{
	pointer-events: none;
}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow{
	position: absolute;
	bottom: 0px;
	left: 58px;
	right: -6px;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn{
	height: 28px;
	border: none;
	// background: $tempr;

	padding: 0px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 28px;
	background: none;

	cursor: pointer;

	position: relative;
}	

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn:hover,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn:hover{
	background: $lightGray-bg;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn svg,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn svg{
	width: 20px;
	height: 20px;	
	position: relative;
	right: -1px; // vertical align
}


//
// Send button setup

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send{
	margin-left: auto;
	margin-right: 4px;

	width: 40px;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send svg,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send svg{
	width: 16.5px;
	height: 16.5px;
	opacity: 0.5;
}


//
// Don't show for replies when not focused

.docEditor-commentsPanel-commentGroup-commentReply--empty.docEditor-commentsPanel-commentGroup-commentReply--isNotFocused
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send{
	background: none;
	pointer-events: none;
}

.docEditor-commentsPanel-commentGroup-commentReply--empty.docEditor-commentsPanel-commentGroup-commentReply--isNotFocused
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send svg{
	opacity: 0;
	fill: var(--text500);
}


//
// If empty, make send do nothing and gray with no hover state
.docEditor-commentsPanel-commentGroup-comment--new--empty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send,
.docEditor-commentsPanel-commentGroup-commentReply--empty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send{
	background: $lightGray-bg;
	pointer-events: none;
}

.docEditor-commentsPanel-commentGroup-comment--new--empty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send svg,
.docEditor-commentsPanel-commentGroup-commentReply--empty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send svg{
	opacity: 0.4;
	fill: var(--text500);
}




//
// If not empty, make send button blue
.docEditor-commentsPanel-commentGroup-comment--new--notEmpty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send,
.docEditor-commentsPanel-commentGroup-commentReply--notEmpty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send{
	//background: transparentize($blue, 0.95);
	fill: var(--primary25);

}

.docEditor-commentsPanel-commentGroup-comment--new--notEmpty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send svg,
.docEditor-commentsPanel-commentGroup-commentReply--notEmpty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send svg{
	opacity: 1;
	//fill: $blue;
	fill: var(--primary500);
}


.docEditor-commentsPanel-commentGroup-comment--new--notEmpty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send:hover,
.docEditor-commentsPanel-commentGroup-commentReply--notEmpty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send:hover{
	//background: transparentize($blue, 0.85);
	background: var(--primary75)
}


.docEditor-commentsPanel-commentGroup-comment--new--notEmpty
button.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn--send:hover svg,
.docEditor-commentsPanel-commentGroup-commentReply--notEmpty
button.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn--send:hover svg{
	//fill: darken($blue, 10%);
	fill: var(--primary700)
}





// NEW COMMENT TEXTAREA

textarea.docEditor-commentsPanel-commentGroup-comment--new-textArea{
	width: 100%;
	resize: none;
	min-height: 72px;

	margin-left: -2px;
	margin-top: -2px;

	border: none;
}

textarea.docEditor-commentsPanel-commentGroup-comment--new-textArea:placeholder{
	color: var(--text200);
}

textarea.docEditor-commentsPanel-commentGroup-comment--new-textArea:focus{
	outline: none;
}

.dropdownMenu.dropdownMenu--commentReactionsMenu{
	width: 160px;
	margin-left: 10px;
}




// KEYBOARD SHORTCUT

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn-keyboardShortcut{
	position: absolute;
	
	right: 100%;
	top: 0px;
	bottom: 0px;

	//background: $tempr;

	display: flex;

	align-items: center;
	justify-content: center;

	padding-right: 10px;
	padding-left: 8px;

	pointer-events: none;

	opacity: 0.8;
	transition: opacity 50ms linear;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn:hover
.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut{
	opacity: 0;
	transition: opacity 100ms linear;
}

.docEditor-commentsPanel-commentGroup-commentReply--isNotFocused
.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut{
	opacity: 0;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut-iconContainer,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn-keyboardShortcut-iconContainer{
	margin-right: 2px;
}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut-iconContainer svg,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn-keyboardShortcut-iconContainer svg{
	width: 15px;
	height: 15px;
	margin-top: 3.2px;

	//fill: darken($blue, 5%) !important; // sorry
	fill: var(--primary600) !important; 

}

.docEditor-commentsPanel-commentGroup-commentReply-btnRow-btn-keyboardShortcut-label,
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-btn-keyboardShortcut-label{
	font-size: 14px;
	font-weight: $lato-semibold;
	// color: var(--text300);
	//color: darken($blue, 5%);
	color: var(--primary600);
}

// ASYNC MESSAGE

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint{
	position: absolute;
	left: -60px;
	right: 50px;
	bottom: 0px;

	// background: $tempr;

	display: flex;

	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text300);
	letter-spacing: 0.005em;

	user-select: none;
	pointer-events: none;

	padding-bottom: 6px;

	transition: opacity 100ms linear;
}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint-iconContainer{
	position: absolute;
	width: 24px;
	height: 24px;
	left: -24px;

	display: flex;
	align-items: top;
	justify-content: flex-start;

	padding-top: 2px;

}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint-iconContainer svg{
	height: 16px;
	width: 16px;
	fill: var(--text400);
	opacity: 0.5;
}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint--hide{
	opacity: 0.7;
}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint--hide
.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint-iconContainer svg{
	opacity: 0.4;
}

.docEditor-commentsPanel-commentGroup-comment--new-btnRow-asyncSubmitHint--show{
	opacity: 1;
}