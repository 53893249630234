

//$lightBlue-ping-actionCommandMenu: #81BCFF;
// $lightBlue-ping-actionCommandMenu: #8FC4FF;
// $lightPurple-directMessage-actionCommandMenu: #E4BBFF;
// $lightGreen-jumpToCursor-actionCommandMenu: #C1FFED;


$lightBlue-ping-actionCommandMenu: #8FC4FF;
$lightPurple-directMessage-actionCommandMenu: #8FC4FF;
$lightGreen-jumpToCursor-actionCommandMenu: #8FC4FF;

$darkPurple-directMessage-actionCommandMenu: #A259D2;
$darkBlue-directMessage-actionCommandMenu: #A259D2;



.dialogOverlay.dialogOverlay--actionCommandMenu{
	align-items: flex-start;
	padding-top: 20vh;
	
	// for video
	// padding-top: 50vh;
	// background: #35343C;
}

.actionCommandMenu{
	width: 480px;		

	// background: rgb(55,53,68);
	//background: rgba(22,21,26,0.88);
	background: rgba(23,21,28,0.88);

	border: 1px solid darken(rgb(55,53,68), 5%);
	border-radius: 8px;

	box-shadow:
	  0px 2.8px 2.2px rgba(0, 0, 0, 0.028),
	  0px 6.7px 5.3px rgba(0, 0, 0, 0.04),
	  0px 12.5px 10px rgba(0, 0, 0, 0.05),
	  0px 22.3px 17.9px rgba(0, 0, 0, 0.06),
	  0px 41.8px 33.4px rgba(0, 0, 0, 0.072),
	  0px 100px 80px rgba(0, 0, 0, 0.1)
	;

	// box-shadow:
	//   0px 2px 4px 0px rgba(0,0,0,0.14),
	//   0px 4px 8px 0px rgba(0,0,0,0.12),
	//   0px 8px 16px 0px rgba(0,0,0,0.10),
	//   0px 16px 32px 0px rgba(0,0,0,0.08),
	//   0px 32px 64px 0px rgba(0,0,0,0.06);
	// ;


	display: flex;
	flex-direction: column;

	backdrop-filter: blur(24px);

	position: relative;
}

.actionCommandMenu-input{
	width: 100%;
	height: 46px;
	position: relative;

	box-sizing: border-box;

	
	padding-left: 10px;
	padding-right: 10px;

	border-bottom: 1px solid rgba(255,255,255,0.1);

	margin-bottom: 8px;

	flex-grow: 0;
	flex-shrink: 0;

}

$indentWidth-input-specialKMenu: 52px;

input.actionCommandMenu-input-input{
	border: none;
	border-radius: none;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;

	box-sizing: border-box;
	padding-top: 5px;

	background: none;

	padding-left: $indentWidth-input-specialKMenu;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: $white;
	letter-spacing: 0.005em;

	
	z-index: 10;
}

.actionCommandMenu--actionCommandMenuType--ping
input.actionCommandMenu-input-input{
	caret-color: $lightBlue-ping-actionCommandMenu;
}

.actionCommandMenu--actionCommandMenuType--directMessage
input.actionCommandMenu-input-input{
	caret-color: $lightPurple-directMessage-actionCommandMenu
}

.actionCommandMenu--actionCommandMenuType--jumpToCursor
input.actionCommandMenu-input-input{
	caret-color: $lightGreen-jumpToCursor-actionCommandMenu;
}


//


input.actionCommandMenu-input-input:focus{
	outline: none;
	border: none;
}


input.actionCommandMenu-input-input::placeholder{
	color: rgba(255,255,255,0.5);
	font-weight: $lato-medium;
	
}

// .actionCommandMenu--actionCommandMenuType--ping
// input.actionCommandMenu-input-input::placeholder{	
// 	color: transparentize($lightBlue-ping-actionCommandMenu, 0.5);
// }

// .actionCommandMenu--actionCommandMenuType--jumpToCursor
// input.actionCommandMenu-input-input::placeholder{	
// 	color: transparentize($lightGreen-jumpToCursor-actionCommandMenu, 0.5);
// }

// .actionCommandMenu--actionCommandMenuType--directMessage
// input.actionCommandMenu-input-input::placeholder{	
// 	color: transparentize($lightPurple-directMessage-actionCommandMenu, 0.5);
// }

.actionCommandMenu-input-UI{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: $indentWidth-input-specialKMenu;

	pointer-events: none;

	// display: none;
}

.actionCommandMenu-input-UI-iconBoxContainer{
	width: 100%;
	height: 100%;

	padding-left: 6px;
	padding-top: 4px;
	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}

.actionCommandMenu-input-UI-iconBox{
	// background: $tempr;
	width: 26px;
	height: 26px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;
}

.actionCommandMenu-input-UI-iconBox svg{
	fill: $white;	
	height: 18px;
	width: 18px;	
}

.actionCommandMenu--actionCommandMenuType--ping
.actionCommandMenu-input-UI-iconBox{
	background: transparentize($lightBlue-ping-actionCommandMenu, 0.8);
	//background: var(--primary500);
}

.actionCommandMenu--actionCommandMenuType--ping
.actionCommandMenu-input-UI-iconBox svg{
	fill: $lightBlue-ping-actionCommandMenu;
	//fill: white;
}

.actionCommandMenu--actionCommandMenuType--directMessage
.actionCommandMenu-input-UI-iconBox{
	//background: transparentize($darkPurple-directMessage-actionCommandMenu, 0.9);
	background: var(--accentPurple500);
}

.actionCommandMenu--actionCommandMenuType--directMessage
.actionCommandMenu-input-UI-iconBox svg{
	fill: $lightPurple-directMessage-actionCommandMenu;
	margin-top: 1px;
	width: 20px;
	height: 20px;
	opacity: 0.75;

	fill: white;
}

.actionCommandMenu--actionCommandMenuType--jumpToCursor
.actionCommandMenu-input-UI-iconBox{
	background: transparentize($lightGreen-jumpToCursor-actionCommandMenu, 0.8);
	//background: var(--accentGreen500);
}

.actionCommandMenu--actionCommandMenuType--jumpToCursor
.actionCommandMenu-input-UI-iconBox svg{
	fill: $lightGreen-jumpToCursor-actionCommandMenu;
	// fill: white;
}






.actionCommandMenu-input-UI-iconBox svg.icon--name--broadcast{
	margin-top: 1px;
}


.actionCommandMenu-input-UI-autocompleteLabel{
	position: absolute;
	top: 15.2px;
	left: 52px;
	font-size: 16px;
	font-weight: $lato-semibold;
	color: white;
	opacity: 0.5;
	letter-spacing: 0.005em;

	z-index: 0;
}


// .actionCommandMenu-input--notEmpty
// .actionCommandMenu-input-UI-iconContainer svg{
// 	opacity: 0.5;	
// }




.actionCommandMenu-resultsList{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;
	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	min-height: 200px;
	// max-height: 295px;

	overflow-y: scroll;

	padding-bottom: 14px;

	display: flex;
	flex-direction: column;
}

.actionCommandMenu-resultsList-noResults{
	width: 100%;
	color: $white;
	height: 38px;

	font-size: 15px;
	font-weight: $lato-semibold;

	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;

	padding-left: $indentWidth-input-specialKMenu;
	pointer-events: none;
	margin-left: -6px; // a bit messy

	opacity: 0.5;
}

.actionCommandMenu-resultsList-actionGuidanceContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	padding-top: 24px;
	margin-top: auto;
}

.actionCommandMenu-resultsList-actionGuidance{
	
	width: calc(100% - 36px);

	box-sizing: border-box;
	background: rgba(255,255,255,0.05);

	border-radius: 5px;

	padding-left: 14px;
	padding-right: 14px;
	padding-top: 8px;
	padding-bottom: 8px;

	font-size: 13.5px;
	letter-spacing: 0.004em;
	line-height: 1.25;

	font-weight: $lato-regular;
	color: rgba(255,255,255,0.7);

	opacity: 0.65;
	transition: opacity 50ms linear;

	user-select: none;

	text-align: center;
}

.actionCommandMenu-resultsList-actionGuidance emph{
	color: rgba(255,255,255,1);
}

.actionCommandMenu-resultsList-actionGuidance:hover{
	opacity: 1;
}

// ROW

@import 'ActionCommandMenu-Row'; 

