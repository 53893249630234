.doc-video-inlineUI-header{
	position: absolute;
	top: calc(#{$height-inlineVideo-header} * -1);
	left: $edgeMarginTextToDoc;
	right: $edgeMarginTextToDoc;
	height: $height-inlineVideo-header;
	// background: $tempr;

	display: flex;
	//align-items: baseline;
	align-items: center;

	box-sizing: border-box;
	padding-top: 2px;

	user-select: none;
	cursor: default;
}

//



input.doc-video-inlineUI-header-videoTitleInput{
	font-size: 14.5px;

	font-weight: $lato-medium;
	letter-spacing: 0.012em;
	padding-left: 8px;
	padding-right: 8px;
	margin-left: -8px;

	border: none;
	height: 28px;

	box-sizing: border-box;
	
	
	width: 100%;
	
	border-radius: 4px;
	background: $invisible;
	padding-top: 0px;
	padding-bottom: 0px;
	
	color: var(--text500);
	opacity: 0.8;

	
}

input.doc-video-inlineUI-header-videoTitleInput::selection{
	background: $invisible;
}

input.doc-video-inlineUI-header-videoTitleInput:hover{
	//background: var(--bgSideMenuTabLight);

	//opacity: 0.99;

	// transition: background 0ms linear;
	// transition-delay: 100ms;
	user-select: auto;
}

input.doc-video-inlineUI-header-videoTitleInput:hover::selection,
input.doc-video-inlineUI-header-videoTitleInput:focus::selection,
input.doc-video-inlineUI-header-videoTitleInput:hover:focus::selection{
	background: var(--textSelectionBlue);
}

input.doc-video-inlineUI-header-videoTitleInput:focus,
input.doc-video-inlineUI-header-videoTitleInput:hover:focus{
	outline: none;
	user-select: auto;
	//background: var(--bgSideMenuTabHover);	
}












// //

// .doc-video-inlineUI-header-duration{
// 	color: var(--text300);
// 	font-weight: $lato-regular;
// 	font-size: 14px;
// 	opacity: 0.8;
// 	position: relative;
// 	top: 0.5px;
// }

// .doc-video-inlineUI-header-buttons{
// 	margin-left: auto;
// 	display: flex;

// 	margin-right: -8px; // optical centering of icon
// }



// .doc-video-inlineUI-header-btn{
// 	// background: $tempr;	

// 	height: 28px;
// 	margin-top: -6px; // offset the padding
	

	
// 	border-radius: 4px;

// 	color: var(--text500);

// 	opacity: 0;
// 	transition: opacity 100ms linear;

// 	font-size: 14.5px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .doc-video-inlineUI-header-btn--iconAndText{
// 	padding-left: 6px;
// 	padding-right: 8px;
// }

// .doc-video-inlineUI-header-btn--iconOnly{
// 	width: 26px;
// }

// .doc-video-inlineUI-header-btn-iconContainer{
// 	height: 100%;
// 	display: flex;
// 	align-items: center
// }

// .doc-video-inlineUI-header-btn-iconContainer svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text500);
// }

// .doc-video-inlineUI-header-btn-iconContainer svg.icon--name--messageOutlineMedium{
// 	position: relative;
// 	top: 1px;
// }

// .doc-video-inlineUI-header-btn--iconAndText
// .doc-video-inlineUI-header-btn-iconContainer{
// 	margin-right: 3px;
// }


// .doc-videoWrapper:hover .doc-video-inlineUI-header-btn{
// 	opacity: 0.5;
// 	transition-delay: 50ms;
// 	transition: opacity 50ms linear;
// }

// .doc-videoWrapper:hover .doc-video-inlineUI-header-btn:hover{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// }