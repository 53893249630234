:root{		
	//--electronMacDotsBG: 	rgb(200,198,198);
	--electronMacDotsBG: 	rgb(0,0,0,0.15);

	--hoverBG--button-topRightFixedMenu: rgb(248,245,245);

	//--textSelectionBlue: #D3E2FF;
	
	//--mediaNodeSelectedBG: rgb(247,245,250);
	//--mediaNodeSelectedBG: rgb(238,238,242);
	--mediaNodeSelectedBG: #C3D7FF;
	// --mediaNodeSelectedBG: red;

	--activeUserGreen: rgb(51,201,157);

	// Checkboxes	
	--checkbox-uncheckedBorderColor: rgb(191, 191, 201);
	--checkbox-checkedBG: rgb(24, 122, 242);
	--checkbox-checkedBorderColor: rgb(0, 103, 230);
}

// DARK THEME

[data-theme="dark"] {
	--electronMacDotsBG: 	rgba(255,255,255,0.19);
	--hoverBG--button-topRightFixedMenu: rgb(34,34,38);	

	--mediaNodeSelectedBG: #1F3650;

	--activeUserGreen: rgb(104,255,211);

	// Checkboxes	
	--checkbox-uncheckedBorderColor: rgb(255,255,255,0.25);
	//--checkbox-checkedBG: rgb(111, 173, 249);
	--checkbox-checkedBG: rgb(63, 149, 255);
	--checkbox-checkedBorderColor: rgb(93, 153, 227);
}




