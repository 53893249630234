
.roadmapsHomePage-quarter-header{
	display: flex;
	align-items: center;

	width: 100%;

		
	margin-top: 32px;	

	user-select: none;
	cursor: default;

	margin-bottom: 32px;

	position: relative;
	z-index: 500;
}

.roadmapsHomePage-quarter-header-visualContainer{
	width: 74px;
	height: 74px;

	border-radius: 7px;

	background: $tempr;
	background: var(--254gray);

	margin-right: 12px;
	position: relative;
	top: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.roadmapsHomePage-quarter-header-leftContainer{
	height: 100%;
}

.roadmapsHomePage-quarter-header-titleContainer{
	margin-top: -4px;
	margin-bottom: -6px;
	margin-left: -4px;
}

.roadmapsHomePage-quarter-header-title{
	font-size: 36px;
	font-size: 39px; // like other things
	font-weight: $lato-heavy;
	color: var(--text600);
	margin-bottom: 8px;

	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 4px;
	padding-top: 4px;

	border-radius: 5px;
}

.roadmapsHomePage-quarter-header-title:hover{
	background: var(--253gray);
}

[data-theme="dark"]{
 	.roadmapsHomePage-quarter-header-title{
		font-weight: $lato-bold;		
	}
}





//

.roadmapsHomePage-quarter-header-rightContainer{
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	align-items: top;
	
	//padding-top: 12px;

	// background: $tempr;

	//width: 200px; // for hover
	//height: 70px; // for hover
	//opacity: 0.7;
	//transition: opacity 100ms linear;

	// padding-top: 36px;
	// padding-right: 36px;
	// margin-top: calc(-16px - 36px);
	// margin-right: -36px;
}

.roadmapsHomePage-quarter-header-rightContainer:hover{
	opacity: 1;
	transition: opacity 100ms linear;
}



.roadmapsHomePage-quarter-header-subtitle-vizContainer{
	
	// background: $tempr;
	// margin-right: 8px;
	// margin-left: -2.5px;
	position: relative;
	z-index: 10;
	// top: 1px;

	// pointer-events: none;


}



.roadmapsHomePage-quarter-header-subtitle-viz{
	width: 56px;
	height: 56px;
	// cursor: pointer;	


	
}

//

.roadmapsHomePage-quarter-header-subtitle-label{
	font-size: 17px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.7;

	margin-left: 4px;
	display: flex;
	align-items: center;
}

.roadmapsHomePage-quarter-header-subtitle-label span{
	opacity: 0.4;
	margin-left: 0.3em;
}

[data-theme="dark"]{
 	.roadmapsHomePage-quarter-header-subtitle-label{
		color: var(--text200);
		font-weight: $lato-regular;
	}
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip{
	position: absolute;
	top: 100%;
	margin-top: 6px;
	width: 200px;
	max-width: 200px;
	// height: 30px;

	top: 0%;
	left: 100%;
	margin-top: -15px;
	margin-left: 20px;
	transform: translateX(-4px);

	display: flex;
	flex-direction: column;

	padding-bottom: 6px;

	opacity: 0;
	transition: all 100ms linear;

	pointer-events: none;
}


.roadmapsHomePage-quarter-header-subtitle-viz:hover
.roadmapsHomePage-quarter-header-subtitle-viz-tooltip{
	opacity: 1;
	transform: translateX(0px);
	transition: all 100ms linear;

	transition-delay: 500ms;
}


.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status{
	height: 28px;
	position: relative;
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-bottom: 5px;

	width: 100%;
	margin-bottom: 2px;
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-count{
	margin-left: auto;
	opacity: 0.75;
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 2px;
	height: 2px;
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-bar{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	background: red;

	z-index: 5;
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status--completed
.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-bar{
	background: rgb(85,104,249);	
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status--started
.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-bar{
	background: rgb(255,170,112);	
}

.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status--planned
.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-bar{
	background: var(--line300);	
	opacity: 0.5;
}



.roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-track{
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(255,255,255,0.2);
}




// .roadmapsHomePage-quarter-header-lookbackBtn{
// 	padding-left: 10px;
// 	padding-right: 10px;

// 	border-radius: 4px;
// 	letter-spacing: 0.02em;

// 	height: 30px;
// 	opacity: 0.5;
// 	margin-left: 0px;

// 	font-size: 14.5px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);

// 	text-transform: capitalize;
// }


// .roadmapsHomePage-quarter-header-lookbackBtn--inactive{
// 	opacity: 0.5;
// }

// .roadmapsHomePage-quarter-header-lookbackBtn--inactive:hover{
// 	opacity: 0.7;
// 	background: var(--253gray);
// }

// .roadmapsHomePage-quarter-header-lookbackBtn--inactive:hover svg{
// 	opacity: 0.9;
// }


// .roadmapsHomePage-quarter-header-lookbackBtn--active{
// 	background: var(--253gray);
// 	opacity: 1;
// }

// .roadmapsHomePage-quarter-header-lookbackBtn--active:hover{
// 	background: var(--246gray);
// 	opacity: 1;
// }
