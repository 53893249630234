.doc-video-inlineUI-zoomPlayBtn{
	position: absolute;

	bottom: $innerMargin-participant-video-ui;	
	left: $edgeMarginTextToDoc;	

	width: 78px;
	height: 46px;

	border: none;
	outline: none;
	box-shadow: none;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
}

.doc-video-inlineUI-zoomPlayBtn svg{
	width: 40px;
	height: 40px;
	fill: $white;
}

.doc-video-inlineUI-zoomPlayBtn:hover svg{
	transform: scale(1.05);
}

.doc-video-inlineUI-zoomPlayBtn{

}

//

.doc-video .doc-video-inlineUI-zoomPlayBtn,
.doc-video .doc-video-inlineUI-duration,
.doc-video .doc-video-inlineUI-titleContainer{
	// opacity: 0.9;
	transition: opacity 50ms;
}

.doc-video:hover .doc-video-inlineUI-zoomPlayBtn,
.doc-video:hover .doc-video-inlineUI-duration,
.doc-video:hover .doc-video-inlineUI-titleContainer{
	opacity: 1;
	transition: opacity 50ms;
}

.doc-video 				button.doc-video-inlineUI-zoomPlayBtn--hide,
.doc-video:hover 	button.doc-video-inlineUI-zoomPlayBtn--hide,
.doc-video 				.doc-video-inlineUI-duration--hide,
.doc-video:hover 	.doc-video-inlineUI-duration--hide,
.doc-video 				.doc-video-inlineUI-titleContainer--hide,
.doc-video:hover 	.doc-video-inlineUI-titleContainer--hide{
	opacity: 0;
	transition: opacity 200ms;
}