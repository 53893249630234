// 
.doc-image-reactionBarContainer{
	width: 100%;
	display: flex;
	justify-content: center;

	user-select: none;
}

.doc-image-reactionBarContainer--readOnlyMode{
	margin-top: -10px;
	margin-bottom: 20px;
}

.doc-image-reactionBar{
	
	height: 31px;

	background: $white;
	border-radius: 10px;
	// border: 1px solid darken($lineMid, 1%);
	// border-bottom: 1px solid darken($lineMid, 5%);

	border: 1px solid var(--line300);
	border-bottom: 1px solid var(--line375);
	

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);

	display: flex;	
	justify-content: center;
	align-items: center;;

	background: $lightGray-bg;
	background: $white;

	padding-left: 2px;
	padding-right: 2px;


}



// INSIDE IMAGE
// .doc-image .doc-image-reactionBar{
// 	position: absolute;
// 	bottom: $innerMarginForUI-docMedia;
// 	left: 50%;
// 	transform: translateX(-50%);
// }

// button

button.doc-image-reactionBar-btn{
	width: 41px;
	height: 28px;

	border: 1.5px solid $invisible;
	background: $invisible;

	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 1px;
	margin-right: 1px;
}

.doc-image-reactionBar-btn-emojiContainer{
	font-size: 20px;
}

.doc-image-reactionBar-btn-reactionCount{
	font-size: 14px;
	font-weight: $lato-bold;

	text-align: right;

	box-sizing: border-box;
	
	width: 15px;

	padding-right: 3px;

}


// USER HAS REACTED

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userHasReacted{
	// need to make opaque for hover effect
	// border: 1.5px solid lighten($blue, 5%);
	// background: lighten($blue, 48%);

	border: 1.5px solid var(--primary400); // inexact match, 
	background: var(--primary25);
}


button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userHasReacted
.doc-image-reactionBar-btn-reactionCount{
	//color: darken($blue, 10%);
	color: var(--primary700);
}

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userHasReacted:hover
.doc-inlineReactionBar-reaction-count{
	//color: darken($blue, 15%)
	color: var(--primary800);
}


// USER HASN'T REACTED BUT OTHERS HAVE
button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userNotReacted.doc-image-reactionBar-btn--hasReactions{
	background: rgb(243,241,241);	
	background: $lightGray-bg;
	background: rgb(247,244,244);
}

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userNotReacted.doc-image-reactionBar-btn--hasReactions:hover{
	background: rgb(241,239,239);	
}

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userNotReacted.doc-image-reactionBar-btn--hasReactions
.doc-image-reactionBar-btn-reactionCount{
	color: var(--text300);

}

// HAS REACTIONS

// USER HASN'T REACTED

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--hasReactions
.doc-image-reactionBar-btn-emojiContainer{
	font-size: 18px;
}

button.doc-image-reactionBar-btn.doc-image-reactionBar-btn--userNotReacted:hover{
	//border: 1.5px solid darken($lineMid, 2%);
	background: $lightGray-bg;
}

// NO ONE HAS REACTED

.doc-image-reactionBar-btn--noReactions:hover 
.doc-image-reactionBar-btn-emojiContainer{
	// transform: scale(1.05);
}