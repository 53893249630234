// TEMP
.libraryContainer{
//	display: none !important;
}

@import 'SideMenu-Container';

//

.sideMenu{
	display: flex;
	flex-direction: column;

	height: 100%;
	// background: $tempr;
}

@import 'SideMenu-Header';



@import 'SideMenu-ActiveViewStatus';

@import 'SideMenu-FullScreenActiveViewStatus';

@import 'SideMenu-TabSections';

@import 'SideMenu-TabGroups';

@import 'SideMenu-Tab';
@import 'SideMenu-RecentPopover';


@import 'SideMenu-Footer';

