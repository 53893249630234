//
// CHECKBOX

//
// MOVE TO STATIC CHECKBOX COMPONENT

.contextMenu-item--checkboxItem{
	background: $tempr;
}

// .contextMenu-item--checkboxItem[data-is-checked="false"]{
// 	opacity: 0.75;
// }
// .contextMenu-item--checkboxItem[data-is-checked="false"]:hover{
// 	opacity: 1;
// }

.contextMenu-item--checkboxItem-checkboxContainer{
	
	height: 100%;
	margin-right: 5px;
	margin-left: auto;

	//background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.contextMenu-item--checkboxItem-checkbox{
	width: 14px;
	height: 14px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.contextMenu-item--checkboxItem[data-is-checked="false"]
.contextMenu-item--checkboxItem-checkbox{
	background: var(--bg-docEditor);
	border: 1px solid var(--checkbox-uncheckedBorderColor);
}

.contextMenu-item--checkboxItem[data-is-checked="true"]
.contextMenu-item--checkboxItem-checkbox{
	background: var(--checkbox-checkedBG);
	border: 1px solid var(--checkbox-checkedBorderColor);
}

$dashoffset: 90; // must admit just kind of reverse engineered this number

.contextMenu-item--checkboxItem-checkbox svg{
	pointer-events: none;

	width: 11px;
	height: 11px;
	stroke: var(--bg-docEditor);

	z-index: 10;
	position: relative;	

	stroke-width: 10px;
  stroke-dasharray: $dashoffset;
  stroke-dashoffset: $dashoffset;
}

.contextMenu-item--checkboxItem[data-is-checked="true"]
.contextMenu-item--checkboxItem-checkbox svg{
	//transition: stroke-dashoffset 240ms linear;	
	stroke-dashoffset: 0;
}

.contextMenu-item--checkboxItem[data-is-checked="false"]
.contextMenu-item--checkboxItem-checkbox svg{
	stroke-dashoffset: $dashoffset;
	//transition: stroke-dashoffset 0ms linear;
}