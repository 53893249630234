//
// BUTTONS

button.docEditor--draft-metadataBtn{
	position: relative;
	z-index: 200;

	height: 30px;

	cursor: pointer;

	pointer-events: auto;

	font-size: 15.5px;
	font-weight: $lato-semibold;	
	color: var(--text350);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	padding: 0px;
	padding-left: 8px;
	padding-right: 14px;

	border-radius: 5zxpx;

	user-select: none;

	// reset
	border: none;
	box-shadow: none;
	background: $invisible;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


	//background: rgb(248,245,245);
}

button.docEditor--draft-metadataBtn:hover{
	background: var(--248gray);
	color: var(--text600);
}

button.docEditor--draft-metadataBtn:active{
	background: var(--243gray);
	color: var(--text600);
}

button.docEditor--draft-metadataBtn--selectUIOpen{
	background: var(--250gray);
	color: var(--text600);
}

//

.docEditor--draft-metadataBtn-iconContainer{
	width: 24px;
	height: 24px;

	//background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;

	flex-grow: 0;
	flex-shrink: 0;
}

//

.docEditor--draft-metadataBtn-labelContainer{
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor--draft-metadataBtn-label{
	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


//
// CHANNEL

button.docEditor--draft-metadataBtn--channel{
	margin-top: 0.5px;
	font-size: 15px;
	font-weight: $lato-medium;
	//width: 200px;
	// background: $tempr;
}

button.docEditor--draft-metadataBtn--channel
.docEditor--draft-metadataBtn-iconContainer,
button.docEditor--draft-metadataBtn--linearProject
.docEditor--draft-metadataBtn-iconContainer,
button.docEditor--draft-metadataBtn--bucket--add
.docEditor--draft-metadataBtn-iconContainer{
	width: 20px;
}

button.docEditor--draft-metadataBtn--channel
.docEditor--draft-metadataBtn-iconContainer{
}

button.docEditor--draft-metadataBtn--channel
.docEditor--draft-metadataBtn-iconContainer svg,
button.docEditor--draft-metadataBtn--bucket--add
.docEditor--draft-metadataBtn-iconContainer svg{
	width: 15px;
	height: 15px;

	fill: var(--text500);
	opacity: 0.4;	

	position: relative;
}

button.docEditor--draft-metadataBtn--channel
.docEditor--draft-metadataBtn-iconContainer svg.icon--name--hash{
	width: 13px;
	height: 13px;
	top: -0.5px;
}

.docEditor--draft-metadataBtn--channel:hover
.docEditor--draft-metadataBtn-iconContainer svg,
button.docEditor--draft-metadataBtn--bucket--add:hover
.docEditor--draft-metadataBtn-iconContainer svg{
	opacity: 0.5;
}


button.docEditor--draft-metadataBtn--channel
.docEditor--draft-metadataBtn-label,
button.docEditor--draft-metadataBtn--linearProject
.docEditor--draft-metadataBtn-label,
button.docEditor--draft-metadataBtn--bucket--add
.docEditor--draft-metadataBtn-label{
	opacity: 0.65;
	margin-top: -1px;
}

button.docEditor--draft-metadataBtn--channel:hover
.docEditor--draft-metadataBtn-label,
button.docEditor--draft-metadataBtn--linearProject:hover
.docEditor--draft-metadataBtn-label,
button.docEditor--draft-metadataBtn--bucket--add:hover
.docEditor--draft-metadataBtn-label{
	opacity: 1;
}

// ADD CHNANEL EXTRAS 

button.docEditor--draft-metadataBtn--channel--add
.docEditor--draft-metadataBtn-iconContainer svg,
button.docEditor--draft-metadataBtn--channel--bucket--add
.docEditor--draft-metadataBtn-iconContainer svg{
	width: 17px;
	height: 17px;
}

.docEditor--draft-metadataBtn--channel--add:hover
.docEditor--draft-metadataBtn-iconContainer svg,
.docEditor--draft-metadataBtn--channel--bucket--add:hover
.docEditor--draft-metadataBtn-iconContainer svg{
	opacity: 0.7;
}


// 
button.docEditor--draft-metadataBtn--linearProject
.docEditor--draft-metadataBtn-iconContainer svg{
	fill: #5E6AD2;
	width: 18px;
	height: 18px;
}

button.docEditor--draft-metadataBtn--linearProject{
	padding-left: 12px;
	padding-right: 8px;
	background: transparentize(#5E6AD2, 0.95);
	height: 28px;
}

button.docEditor--draft-metadataBtn--linearProject:hover{
	background: transparentize(#5E6AD2, 0.8);
}


button.docEditor--draft-metadataBtn--linearProject
.docEditor--draft-metadataBtn-label{
	display: none;

}

//
// BUCKET

.docEditor--draft-metadataBtn--bucket--existing
.docEditor--draft-metadataBtn-iconContainer{
	font-size: 20px;
}

//
// DOC STATUS PROTOTYPE

button.docEditor--draft-metadataBtn--docStatus--earlyDraft{
	color: darken(#FAA733, 10%);
	background: transparentize(#FFAA70, 0.9);
}

button.docEditor--draft-metadataBtn--docStatus--earlyDraft:hover{
	color: darken(#FAA733, 10%);
	background: transparentize(#FFAA70, 0.85);
}

button.docEditor--draft-metadataBtn--docStatus--proposal{
	color: darken(#B578DD, 5%);
	background: transparentize(#B578DD, 0.9);
}

button.docEditor--draft-metadataBtn--docStatus--proposal:hover{
	color: darken(#B578DD, 5%);
	background: transparentize(#B578DD, 0.85);	
}

button.docEditor--draft-metadataBtn--docStatus--currentThinking{
	color: darken(#33C99D, 9%);
	background: transparentize(#33C99D, 0.95);
}

button.docEditor--draft-metadataBtn--docStatus--currentThinking:hover{
	color: darken(#33C99D, 9%);
	background: transparentize(#33C99D, 0.9);
}

.docEditor--draft-topLeftFixedMenu button.docEditor--draft-metadataBtn--docStatus--earlyDraft,
.docEditor--draft-topLeftFixedMenu button.docEditor--draft-metadataBtn--docStatus--proposal,
.docEditor--draft-topLeftFixedMenu button.docEditor--draft-metadataBtn--docStatus--currentThinking{
	//color: var(--text500);
	// background: none;
}

button.docEditor--draft-metadataBtn--channel--add--menuOpen{
	opacity: 0.75;
	pointer-events: none;
}

button.docEditor--draft-metadataBtn--channel--add--menuOpen svg{
	visibility: hidden;
}


//
// PRIVATE DRAFT

button.docEditor--draft-metadataBtn--privateDraft{
	//cursor: default;
	pointer-events: none;
}

button.docEditor--draft-metadataBtn--privateDraft:hover{
	background: $invisible;
}

button.docEditor--draft-metadataBtn--privateDraft
.docEditor--draft-metadataBtn-label{
	opacity: 0.65; // same as for channel
}