//
// OFFWHITE

.figmaView-backgroundImage--light,
.doc-figma-effectsToolbar-bgButtonCarousel-btn--light{
	background: rgb(248,248,252);
}

[data-theme="dark"]{	
	.figmaView-backgroundImage--light{
		background: lighten(rgb(28,26,32), 3%);
	}
	.doc-figma-effectsToolbar-bgButtonCarousel-btn--light{
		background: lighten(rgb(28,26,32), 5%);
	}
}



//
// LIGHT BLUE


.figmaView-backgroundImage--lightBlue{
	background: radial-gradient(60% 60% at 50% 50%,  lighten(#AAD1FF, 5%), darken(#AAD1FF, 5%));
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightBlue{
	background: #AAD1FF;
}



[data-theme="dark"]{	
	.figmaView-backgroundImage--lightBlue{
		background: #747EAF;
		background: radial-gradient(60% 60% at 50% 50%,  lighten(#747EAF, 5%), darken(#747EAF, 3%));
	}

	.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightBlue{
		background: #364561;
	}
}


//
// LIGHT ORANGE

.figmaView-backgroundImage--lightOrange{
	background: radial-gradient(60% 60% at 50% 50%,  lighten(#FFD8D9, 3%), darken(#FFD8D9, 4%));
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightOrange{
	background: #FFD8D9;
}

[data-theme="dark"]{	
	.figmaView-backgroundImage--lightOrange{
		background: #af7474;
		background: radial-gradient(60% 60% at 50% 50%,  lighten(#af7474, 5%), darken(#af7474, 3%));
	}

	.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightOrange{
		background: #af7474;
	}
}





// [data-theme="dark"]{	
// 	.figmaView-backgroundImage--lightOrange{
// 		background: radial-gradient(60% 60% at 50% 50%,  lighten(#FFD8D9, 5%), darken(#EDD3FF, 5%));
// 	}
// }

//
// LIGHT PURPLE

.figmaView-backgroundImage--lightPurple{
	//background: radial-gradient(circle, lighten(#FFD8D9, 5%), darken(#FFD8D9, 5%));
	background: radial-gradient(60% 60% at 50% 50%, lighten(#EDD3FF, 1%), darken(#EDD3FF, 2%));
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightPurple{
	background: #EDD3FF;
}

[data-theme="dark"]{	
	.figmaView-backgroundImage--lightPurple{
		//background: #7d6493;
		background: radial-gradient(60% 60% at 50% 50%,  lighten(#7d6493, 5%), darken(#7d6493, 3%));
	}

	.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightPurple{
		background: #7d6493;
	}
}


// [data-theme="dark"]{	
// 	.figmaView-backgroundImage--lightPurple{
// 		background: radial-gradient(60% 60% at 50% 50%,  lighten(#EDD3FF, 3%), darken(#EDD3FF, 3%));
// 	}
// }





//
// LIGHT GREEN

.figmaView-backgroundImage--lightGreen{
	background: radial-gradient(60% 60% at 50% 50%, lighten(#D8F2EA, 5%), darken(#D8F2EA, 5%));
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightGreen{
	background: #D1FDF0;
}




[data-theme="dark"]{	
	.figmaView-backgroundImage--lightGreen{
		//background: #5a7662;
		background: radial-gradient(60% 60% at 50% 50%,  lighten(#5a7662, 5%), darken(#5a7662, 3%));
	}

	.doc-figma-effectsToolbar-bgButtonCarousel-btn--lightGreen{
		background: #5a7662;
	}
}


