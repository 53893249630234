




//
// SIDENAV

:root{	
	--textSideNavLabel: 				rgb(50,	45,	45);
	--iconSideNavLabel:				 	rgb(120,120,120);
	--iconSideNavLabelActive: 	rgb(80,	80,	80);
	--iconSideNavChannel: 			rgb(130,130,130);
	
	--bgSideNav: rgba(251,249,249); // invisible doesn't make it semi-transparent, would require layout refactor
	--borderColorLibrarySideNav: rgb(240,238,235); 
}

[data-theme="dark"]{
	
}
