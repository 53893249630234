.keyboardShortcutsPanelContainer{
	position: absolute;
	top: 14px;
	right: 14px;

	z-index: 20;
}

.keyboardShortcutsPanelContainer:focus{
	outline: none;
}

.keyboardShortcutsPanelContainer--hidden,
.keyboardShortcutsPanelContainer--hidden *{
	pointer-events: none !important;
}

.keyboardShortcutsPanel{	
	height: calc(100vh - 28px);

	box-sizing: border-box;

	border-radius: 5px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;
}

//
// HOVER AREA HIDDEN
.keyboardShortcutsPanel{
	@include poly-fluid-sizing('width', (
		
		1280px: 360px,		
		1366px: 360px,
		1440px: 400px,
		1680px: 420px,
		1920px: 440px,
		2560px: 440px)
	);
}

.keyboardShortcutsPanel-header{	
	height: 48px;
	width: 100%;
	display: flex;
	
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	
	// border-bottom: 1px solid $invisible;
	// border-bottom: 1px solid var(--bottomBorder-header-messagePanel);
	//box-shadow: var(--boxShadow-header-messagePanel);

	padding-left: 16px;
	
	user-select: none;

	cursor: pointer;

	position: relative;
	z-index: 10;
}

.keyboardShortcutsPanel-header-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;	
	color: var(--text500);
}

.keyboardShortcutsPanel-header-closeBtn{
	margin-left: auto;
	height: 100%;		
	padding-left: 6px;
	padding-right: 10px;

	//border-left: 1px solid var(--sideBorder-header-messagePanel-dndButton);
}


.keyboardShortcutsPanel-header-closeBtn-inner{
	width: 33px;
	height: 100%;
	border-radius: 5px;
	// background: $tempb;
	
	display: flex;
	align-items: center;
	justify-content: center;

	

	//background: var(--253gray);
}

.keyboardShortcutsPanel-header-closeBtn:hover
.keyboardShortcutsPanel-header-closeBtn-inner{
	background: var(--250gray);
}

.keyboardShortcutsPanel-header-closeBtn svg {
	width: 20px;
	height: 20px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0.3;
	transition: opacity 50ms linear;
}

.keyboardShortcutsPanel-header:hover svg{
	opacity: 0.8;
}



//

.keyboardShortcutsPanel-list{
	//padding-bottom: 40px;
	overflow-y: scroll;

	cursor: default;
	user-select: none;
}

.keyboardShortcutsPanel-list-groupHeader{
	width: 100%;
	margin-top: 26px;
	margin-bottom: 5px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 16px;

	font-size: 12.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	color: var(--text500);
	opacity: 0.5;
}

.keyboardShortcutsPanel-list-groupHeader:first-child{
	margin-top: 8px;
}

.keyboardShortcutsPanel-spacer{
	height: 10px;
}

.keyboardShortcutsPanel-list-bottomSpacer{
	flex-shrink: 1;
	flex-grow: 0;
	height: 30px;
}

.keyboardShortcutsPanel-list-row{
	width: 100%;

	height: 28px;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 12px;

	display: flex;
	align-items: center;
}

.keyboardShortcutsPanel-list-row-label{
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: var(--text500);
}



.keyboardShortcutsPanel-list-row-shortcut{
	display: flex;
	margin-left: auto;
}

.keyboardShortcutsPanel-list-row-shortcut .keyboardShortcutTag{
	display: flex;
}

.keyboardShortcutsPanel-list-row-shortcut-orSpan{
	font-size: 15px;
	margin-left: 4px;
	margin-right: 4px;
	opacity: 0.5;
}

.keyboardShortcutsPanel-list
.keyboardShortcutTag-item{
	
	width: 20px;
	height: 20px;

	border-radius: 3px;
	background: var(--250gray);
	margin-left: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.keyboardShortcutsPanel-list
.keyboardShortcutTag-item svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 1;
}

[data-theme="dark"] 
.keyboardShortcutsPanel-list .keyboardShortcutTag-item svg{
	opacity: 0.8;
}

.keyboardShortcutsPanel-list
.keyboardShortcutTag-item--letter{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 01;

	min-width: 12px;
	width: auto;
	padding-left: 4px;
	padding-right: 4px;
}

[data-theme="dark"] 
.keyboardShortcutsPanel-list .keyboardShortcutTag-item--letter{
	opacity: 0.9;
}

.keyboardShortcutsPanel-list
.keyboardShortcutTag-item--space{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.9;
	letter-spacing: 0.01em;	

	width: 47px;
}

[data-theme="dark"] 
.keyboardShortcutsPanel-list .keyboardShortcutTag-item--space{
	opacity: 0.7;
}