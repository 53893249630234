.selectUserPopoverContainer{	
	
}

.selectUserPopoverContainer:focus{
	outline: none;
}

.selectUserPopoverContainer--hidden,
.selectUserPopoverContainer--hidden *{
	pointer-events: none !important;
}

.selectUserPopoverContainer--actionType--directMessage{
	margin-top: -3px;
	margin-left: 28px;
}

.selectUserPopoverContainer--actionType--directMessageInvite{
	margin-top: 2px;	
	margin-left: 56px;
}

.selectUserPopoverContainer--actionType--pingDoc{
	// margin-top: -2px;
	
	// margin-left: -3px;

	margin-top: -4px;
	//margin-left: 18px;


	// margin-top: 32px;
	// margin-left: 170px;
}

.selectUserPopoverContainer--manageDocFollowers{
	margin-top: -4px;
}

//

.selectUserPopover{
	width: 270px;

	box-sizing: border-box;
	padding-top: 12px;
	//padding-bottom: 12px;
	padding-bottom: 14px;
	border-radius: 6px;
}

.selectUserPopoverContainer--actionType--pingDoc
.selectUserPopover{
	width: 250px;
}

.selectUserPopoverContainer--actionType--directMessage .selectUserPopover,
.selectUserPopoverContainer--actionType--directMessageInvite .selectUserPopover{
	width: 280px;
}

.selectUserPopoverContainer--manageToDoAssignment .selectUserPopover{
	width: 260px;
}

.selectUserPopover--manageDocFollowers{
	margin-right: 10px;
}

.selectUserPopover--manageDocFollowers{
	width: 315px;
	padding-top: 14px;
	padding-bottom: 18px;
	border-radius: 7px;
}



@import 'SelectUserPopover-Header';


//
//

.selectUserPopover-itemList{
	width: 100%;
	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;
}

.selectUserPopover--manageDocFollowers
.selectUserPopover-itemList{
	padding-left: 10px;
	padding-right: 10px;
}

@import 'SelectUserPopover-Item';
