.onboardingModal-topBar{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 40px;
	// background: $tempr;
	z-index: 20;

	-webkit-user-select: none;
	-webkit-app-region: drag;
}

.onboardingModal-topBar-macDots{
	position: absolute;	
	top: 18px;
	left: 15px;

	display: flex;
	column-gap: 8px;
}

.onboardingModal-topBar-macDots-dot{
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: var(--electronMacDotsBG);
}


// .electronMacDots{
// 	position: fixed;
// 	z-index: 2000;

// 	top: 14px;
// 	left: 19px;

// 	top: 18px;
// 	left: 15px;

// 	top: 18px;
// 	left: 18px;

// 	left: 15px;

// 	display: flex;

// 	// background: $tempr;
// 	pointer-events: none;
// }

// @keyframes apiErrorStateMacDots { 
//   from { opacity: 0.5; } 
// }

// .electronMacDots-dot{
// 	width: 12px;
// 	height: 12px;
// 	border-radius: 50%;

// 	margin-right: 8px;

// 	background: var(--electronMacDotsBG);
// }

// .electronMacDots--apiErrorState{
// 	width: 60px;
// 	height: 12px;
// 	background: var(--accentRed600);
// 	border-radius: 6px;

// 	animation: apiErrorStateMacDots 0.8s infinite alternate;

// }

// .electronMacDots--apiErrorState .electronMacDots-dot{
// 	visibility: hidden;
// }

// .electronMacDots--hoverSideMenu{
// 	position: absolute;
// 	top: 13px;
// 	left: 10px;

// 	z-index: 500;
// }
