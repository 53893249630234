.selectUserPopover-header{
	display: flex;
	width: 100%;

	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 12px;

	margin-bottom: 8px;
	margin-bottom: 10px;

	cursor: default;
	user-select: none;
}


.selectUserPopover--manageDocFollowers
.selectUserPopover-header{
	padding-left: 18px;
	padding-right: 14px;
}

.selectUserPopover-header-title{
	
	// font-size: 16.5px;
	// font-weight: $lato-bold;

	font-size: 17.5px;
	font-weight: $lato-heavy;

	color: var(--text500);
	margin-bottom: 3px;
}

.selectUserPopover--manageDocFollowers
.selectUserPopover-header-title{
	margin-bottom: 5px;
}

.selectUserPopover-header-description{
	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text300);
	opacity: 0.9;
}

.selectUserPopover--manageDocFollowers
.selectUserPopover-header-description{
	font-size: 14px;
	letter-spacing: 0.01em;
	margin-bottom: 3px;
}

.selectUserPopover-header-description--dark{
	color: var(--text600);
	
}

[data-theme="dark"] {
	.selectUserPopover--manageDocFollowers
	.selectUserPopover-header-description--light{
		opacity: 0.5;
	}
}





//
.selectUserPopover-header-rightContainer{
	margin-left: auto;
}

.selectUserPopover-header-keyboardShortcut{
	background: $tempr;

	display: flex;
	height: 24px;

	padding-right: 6px;
	padding-left: 6px;

	border-radius: 4px;

	align-items: center;

	background: var(--bgSideMenuTabLight);

	margin-right: -2px;
	margin-top: -2px;

	user-select: none;
}

.selectUserPopover-header-keyboardShortcut-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	margin-right: 1px;
}

.selectUserPopover-header-keyboardShortcut-iconContainer svg{
	width: 14px;
	height: 14px;
	position: relative;
	top: 0.5px;

	fill: var(--text300);
	opacity: 0.9;
}

.selectUserPopover-header-keyboardShortcut-label{
	font-size: 15px;
	font-weight: $lato-semibold;

	color: var(--text300);
}