
//
// THREAD

.docEditor-messagePanel-header--thread{
	cursor: pointer;
	padding-left: 12px;
}

.docEditor-messagePanel-header--thread-backBtn{
	width: 18px;
	height: 24px;
	margin-left: -4px;
	margin-right: 3px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-messagePanel-header--thread-backBtn svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.35;
	transform: scaleX(-1);
}

.docEditor-messagePanel-header--thread:hover svg{
	opacity: 0.5;
}


.docEditor-messagePanel-header--thread-title,
.docEditor-messagePanel-header--newThread-title{
	font-size: 15.75px;
	font-weight: $lato-bold;
	color: var(--text400);
	opacity: 0.9;

	// padding-left: 2px;
}

.docEditor-messagePanel-header--thread-title,
.docEditor-messagePanel-header--newThread-title{
	flex-grow: 1;
	flex-shrink: 1;
}

.docEditor-messagePanel-header--thread-closeBtn,
.docEditor-messagePanel-header--newThread-closeBtn{
	// background: $tempr;
	// width: 38px;
	height: 100%;		
	padding-left: 6px;
	padding-right: 3px;


	//border-left: 1px solid var(--sideBorder-header-messagePanel-dndButton);
}


.docEditor-messagePanel-header--thread-closeBtn-inner,
.docEditor-messagePanel-header--newThread-closeBtn-inner{
	width: 34px;
	height: 30px;
	border-radius: 5px;
	// background: $tempb;
	
	display: flex;
	align-items: center;
	justify-content: center;

	

	//background: var(--253gray);
}

.docEditor-messagePanel-header--newThread-closeBtn:hover
.docEditor-messagePanel-header--newThread-closeBtn-inner,
.docEditor-messagePanel-header--thread-closeBtn:hover
.docEditor-messagePanel-header--thread-closeBtn-inner{
	background: var(--250gray);
}

.docEditor-messagePanel-header--newThread-closeBtn svg,
.docEditor-messagePanel-header--thread-closeBtn svg{
	width: 20px;
	height: 20px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0.3;
	transition: opacity 50ms linear;
}

.docEditor-messagePanel-header--newThread-closeBtn:hover svg,
.docEditor-messagePanel-header--thread-closeBtn:hover svg{
	opacity: 0.8;
}


// .docEditor-messagePanel-header--thread:hover svg{
// 	opacity: 1;
// }


// .docEditor-messagePanel-header--docGroup-followerPopoverAnchor{
// 	position: absolute;
// 	right: 12px;
// 	bottom: 0px;
// }

// // WIDE BUTTON

// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn{
// 	padding-right: 14px;
// 	padding-left: 10px;

// 	height: 28px;
// 	margin-right: 4px;

// 	background: var(--primary25);

// 	border-radius: 5px;	

// }


// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin-right: 4px;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn-iconContainer svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--primary500);
// 	opacity: 0.9;
// }
// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn-label{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: var(--primary500);
// 	// opacity: 0.5;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn:hover{
// 	background: var(--primary50);
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverWideBtn--menuOpen{
// 	pointer-events: none;
// }


// //
// // DEFAULT BUTTON

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn{
// 	height: 28px;
// 	width: 28px;
	
// 	background: var(--250gray);
// 	margin-right: 2px;
// 	flex-grow: 0;
// 	flex-shrink: 0;


// 	border-radius: 5px;

// 	position: relative;
// }

// .docEditor-messagePanel-header--docGroup:hover
// .docEditor-messagePanel-header--docGroup-followerPopoverBtn{
// 	background: var(--248gray);
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn:hover{
// 	background: var(--243gray);
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// 	transition: all 100ms linear;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn:hover svg{
// 	transform: scale(1.1);
// 	opacity: 0.7;
// 	transition: all 50ms linear;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn--menuOpen{
// 	pointer-events: none;
// 	opacity: 0.5;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn-tooltip{
// 	position: absolute;
// 	top: 31px;
// 	left: 50%;
// 	transform: translateX(-50%);

// 	opacity: 0;
// }

// .docEditor-messagePanel-header--docGroup-followerPopoverBtn:hover
// .docEditor-messagePanel-header--docGroup-followerPopoverBtn-tooltip{
// 	opacity: 1;
// }



// //
// //

// .docEditor-messagePanel-header--docGroup-dropdownBtn{	
// 	height: 100%;
// 	box-sizing: border-box;
// 	padding-left: 3px;	
// 	padding-right: 3px;
// 	padding-top: 3px;
// 	padding-bottom: 3px;
// 	width: 35px;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	display: none;
// }

// .docEditor-messagePanel-header--docGroup-dropdownBtn-inner{
// 	width: 100%;
// 	height: 100%;
// 	cursor: pointer;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	border-radius: 4px;
// }

// .docEditor-messagePanel-header--docGroup-dropdownBtn-inner:hover{
// 	background: var(--250gray);
// }

// //


// //
// //


// .docEditor-messagePanel-header--docGroup-dropdownBtn-inner svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text600);
// 	opacity: 0.3;
// }

// .docEditor-messagePanel-header--docGroup-dropdownBtn-inner:hover svg{
// 	opacity: 1;
// }
