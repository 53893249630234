.message-content-imageContainer,
.message-content-drawingContainer,
.message-content-gifContainer,
.message-content-figmaContainer{
	width: 100%;
	height: 100%;
	margin-top: 5px;
	
	overflow: hidden;
}


//
// IMAGE{

.message-content-imageContainer{	
	display: flex;	
	cursor: pointer;
	justify-content: flex-start;

	user-select: none !important;
}



//

.message-content-imageInnerContainer{
	max-height: 400px;	
	min-height: 10px;

	flex-grow: 1;
	flex-shrink: 1;
}

img.message-content-image{
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center left;
	border-radius: 4px;
}

//
//

.message-content-zoomImageContainer{	
	cursor: pointer;

	position: fixed;
	z-index: 99999;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--messageMediaZoomedBG);


	user-select: none !important;
}

.message-content-zoomImageInnerContainer{
	width: calc(100% - 200px);
	height: calc(100% - 200px);
	max-width: 1280px;
	max-height: 800px;

	display: flex;
	align-items: center;
	justify-content: center;
}

img.message-content-zoomImage{
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center left;
	border-radius: 6px;
	//box-shadow: var(--messageMediaZoomedBoxShadow);
	
	
}


//
// DRAWING

.message-content-drawingContainer{
	
}

.message-content-drawing{
	width: 100%;
	height: 100%;
	border-radius: 2px;
	cursor: pointer;

}

//
// GIF
.message-content-gifContainer{
	border-radius: 2px;
	cursor: pointer;
	display: flex;
}

.message-content-gif{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.message-content-gif-gifContainer{
	border-radius: 2px;
	overflow: hidden;
}


.message-content-gifContainer--minimized
.message-content-gif-gifContainer{
	height: 70px;

}

.message-content-gifContainer--minimized
.message-content-gif-gifContainer video{
	border-radius: 2px;
}

.message-content-gifContainer--minimized
.message-content-gif-gif{
	width: 100%;
	height: 100%;
}

.message-content-gif-label{
	margin-left: 14px;
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.4;
	letter-spacing: 0.003em;
}

.message-content-gifContainer:hover
.message-content-gif-label{
	opacity: 0.8;
}




//
// IMAGE

.message-content-figmaContainer{
	position: relative;
}

.message-content-figma{
	width: 100%;
	height: 100%;
	border-radius: 4px;
	cursor: pointer;

}

.message-content-figmaToolbar{
	position: absolute;
	top: 5px;
	right: 5px;

	border-radius: 5px;

	background: rgba(60,55,60,0.85);	
	// background: rgba(60,55,60,0.95);	

	height: 26px;
	width: 26px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-content-figmaToolbar svg{
	width: 14px;
	height: 14px;
}
