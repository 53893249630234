

.meetingNotesPage-GCalBannerOuterContainer{
	width: 100%;
	display: flex;
}

.meetingNotesPage-GCalBanner{
	width: 100%;
	height: 360px;

	border-radius: 5px;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgb(253,253,255);

	margin-bottom: 32px;
}

[data-theme="dark"]{
	.meetingNotesPage-GCalBanner{
		background: rgba(255,255,255,0.02);
	}
}


//
// 

.meetingNotesPage-GCalBanner-content{
	display: flex;
	flex-direction: column;
	align-items: center;

	cursor: default;

	margin-top: 20px;
}

//

.meetingNotesPage-GCalBanner-content-iconContainer{
	width: 54px;
	height: 54px;

	margin-bottom: 30px;


	display: flex;
	align-items: center;
	justify-content: center;

}

.meetingNotesPage-GCalBanner-content-iconContainer svg{
	width: 50px;
	height: 50px;
}

//

.meetingNotesPage-GCalBanner-content-title{
	font-size: 22.5px;
	font-weight: $lato-heavy;
	color: var(--text500);
	letter-spacing: 0.004em;

	margin-bottom: 10px;
}

//

.meetingNotesPage-GCalBanner-content-description{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.meetingNotesPage-GCalBanner-content-description-para{
	font-size: 16px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;

	margin-bottom: 5px;

}


.meetingNotesPage-GCalBanner-content-description-para--link{
	margin-top: 24px;
	margin-bottom: -32px;

	font-size: 14px;

	opacity: 0.6;
}

.meetingNotesPage-GCalBanner-content-description-para--link:hover{
	opacity: 1;
}

//
// SIGN IN BUTTON

.meetingNotesPage-GCalBanner-content-buttonContainer{
	margin-top: 34px;
	position: relative;
}

.meetingNotesPage-GCalBanner-content-buttonSideLine{
	position: absolute;
	
	top: 50%;
	transform: translateY(-50%);

	height: 1px;
	// background: red;

	width: 280px;

	opacity: 0.08;

	border-radius: 1px;

	pointer-events: none;

	background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.33) 66%, rgba(0,0,0,0.6) 100%);
}

[data-theme="dark"]{
	.meetingNotesPage-GCalBanner-content-buttonSideLine{
		background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.33) 66%, rgba(0,0,0,0.6) 100%);	
	}
}

.meetingNotesPage-GCalBanner-content-buttonSideLine--left{
	right: calc(100% + 36px);
	
}

.meetingNotesPage-GCalBanner-content-buttonSideLine--right{
	left: calc(100% + 36px);
	transform: translateY(-50%) scaleX(-100%);
}

.meetingNotesPage-GCalBanner .GCalSignInBtn{
	background: $white;

	width: 240px;
	height: 38px;

	border-radius: 5px;
	border: 1px solid var(--line375);
	border-top: 1px solid var(--line375);
	border-bottom: 1px solid var(--line400);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.04);

	cursor: pointer;
}

.meetingNotesPage-GCalBanner .GCalSignInBtn:hover{
	border: 1px solid var(--line450);
	border-top: 1px solid var(--line450);
	border-bottom: 1px solid var(--line450);
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.07);
}

[data-theme="dark"]{
	.meetingNotesPage-GCalBanner .GCalSignInBtn{
		background: rgba(248,248,255,0.05);
		border: 1px solid var(--line200);
		border-top: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		box-shadow: none;
	}
	.meetingNotesPage-GCalBanner .GCalSignInBtn:hover{
		background: rgba(248,248,255,0.06);
		border: 1px solid var(--line200);
		border-top: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		box-shadow: none;
	}
}


.meetingNotesPage-GCalBanner .GCalSignInBtn-iconContainer{
	width: 18px;
	height: 18px;

	margin-left: -6px;
	margin-right: 6px;
}

.meetingNotesPage-GCalBanner .GCalSignInBtn-iconContainer svg{
	width: 15px;
	height: 15px;
	position: relative;
	top: 2px;
}


.meetingNotesPage-GCalBanner .GCalSignInBtn-label{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text500);
}

.meetingNotesPage-GCalBanner .GCalSignInBtn:hover .GCalSignInBtn-label{
	color: var(--text600);
}


//

.meetingNotesPage-GCalBanner-innerBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	width: calc(100% - 2px);
	height: calc(100% - 2px);

	z-index: 10;

	border-radius: 5px;

	border: 1px solid rgba(0,0,0,0.05);

	pointer-events: none;
}

[data-theme="dark"]{
	.meetingNotesPage-GCalBanner-innerBorder{
		border: 1px solid rgba(255,255,255,0.02);
	}

}

//

.meetingNotesPage-GCalBanner-hideBtn{
	position: absolute;
	top: 8px;
	right: 8px;
	width: 26px;
	height: 26px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

.meetingNotesPage-GCalBanner-hideBtn svg{
	width: 24px;
	height: 24px;
	fill: var(--text500);
	opacity: 0.25;
}


.meetingNotesPage-GCalBanner-hideBtn:hover svg{
	opacity: 0.5;	
}