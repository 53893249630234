.sideMenu-header{
	//height: 64px;
	height: 66px;
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;


}

//
// TOP AREA

.sideMenu-header-top{
	height: 40px;
	margin-top: 6px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	padding-right: 7px;

	position: relative;

	-webkit-app-region: drag;
}

.sideMenu-header-top-btn{
	height: 30px;		
	border-radius: 5px;

	cursor: pointer;

	-webkit-app-region: no-drag;
}

.sideMenu-header-top-btn:disabled{
	opacity: 0.4;
	pointer-events: none;
}

.sideMenu-header-top-btn:hover{
	background: var(--bgSideMenuTabHover);
}

.sideMenu-header-top-btn svg{
	fill: var(--text600);
	opacity: 0.5;
}

.sideMenu-header-top-btn:hover svg{
	opacity: 1;
}

.sideMenu-header-top-btn--toggleInterface{
	//width: 48px;
	width: 32px;
}

// .sideMenu-header-top-btn--toggleInterface svg{
// 	width: 31px;
// 	height: 31px;
// }

// New sidemenu button
.sideMenu-header-top-btn--toggleInterface svg{
	width: 19px;
	height: 19px;
	position: relative;
	top: -0.5px;
}

.sideMenu-header-top-btn--newDoc{
	width: 30px;
}

.sideMenu-header-top-btn--newDoc svg{
	width: 18px;
	height: 18px;
	transform: scale(0.92);
}

//

.sideMenu-header-top-btn--settings{
	width: 30px;
}

.sideMenu-header-top-btn--settings svg{
	width: 14px;
	height: 14px;
}

.sideMenu-header-top-btn--back,
.sideMenu-header-top-btn--forward{
	width: 30px;
}

.sideMenu-header-top-btn--back svg,
.sideMenu-header-top-btn--forward svg{
	width: 15px;
	height: 15px;
}

.sideMenu-header-top-btn--back svg{
	transform: scaleX(-100%);
}

//

.sideMenu-header-forwardBackButtons{
	position: absolute;
	left: 100%;
	margin-left: 4px;
	top: 5px;

	display: flex;
}


// //
// // ORG BUTTON

// .sideMenu-header-orgBtnContainer{
// 	width: 100%;
// 	box-sizing: border-box;
// 	padding-left: 6px;
// 	padding-right: 4px;

// 	margin-top: 16px;
// 	user-select: none;
// }

// .sideMenu-header-orgBtn{
// 	width: 100%;
// 	height: var(--heightSideTab);
// 	height: 32px;
// 	// background: $tempr;

// 	border-radius: 4px;

// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: center;

// }

// .sideMenu:hover 
// .sideMenu-header-orgBtn{
// 	//background: var(--bgSideMenuTabLight);
// }

// .sideMenu:hover  .sideMenu-header-orgBtn:hover{
// 	background: var(--bgSideMenuTabHover);
// }

// .sideMenu-header-orgBtn[data-state="open"]{
// 	background: var(--bgSideMenuTabActive);	
// }

