
.recordingPlaybackModal-transcript-header{
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	// background: $tempb;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;

	// padding-left: 36px;
	// padding-right: 18px;

	height: 44px;

	position: relative;
	// background: $tempr;

	// display: none;
}

// .recordingPlaybackModal-transcript-header-title{
// 	display: none;

// 	display: flex;
// 	align-items: center;

// 	display: none;
// }

// .recordingPlaybackModal-transcript-header-title-iconContainer{
// 	width: 24px;
// 	height: 24px;
// 	// background: $tempr;

// 	justify-content: center;
// 	align-items: center;

// 	margin-right: 6px;
// }

// .recordingPlaybackModal-transcript-header-title-iconContainer svg{
// 	width: 20px;
// 	height: 20px;

// }

// .recordingPlaybackModal-transcript-header-title-label{
// 	font-size: 16px;
// 	font-weight: $lato-semibold;
// 	color: var(--text600);

// 	margin-right: 6px;
// }

// .recordingPlaybackModal-transcript-header-title-date{
// 	font-size: 16px;
// 	font-weight: $lato-semibold;
// 	color: var(--text400);
// }




.recordingPlaybackModal-transcript-header-searchBarContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	height: 32px;

	background: $tempr;
	position: relative;

	// margin-top: 14px;
	// margin-bottom: 8px;

	border-radius: 3px;

	margin-right: 4px;

	background: rgba(255,255,255,0.06);

	display: flex;

	box-sizing: border-box;
	padding-right: 4px;
}

.recordingPlaybackModal-transcript-header-searchBarContainer:hover{
	background: rgba(255,255,255,0.12);	
}

.recordingPlaybackModal-transcript-header-searchBarContainer:focus-within{
	background: rgba(255,255,255,0.08);	
}


.recordingPlaybackModal-transcript-header-searchBar{
	height: 100%;

	display: flex;
	flex-grow: 1;
	flex-shrink: 1;

	box-sizing: border-box;
	padding: 0px;
	padding-left: 32px;

	font-size: 15.75px;
	letter-spacing: 0.006em;

	font-weight: $lato-regular;
	background: $invisible;
	color: var(--text500);
	box-shadow: none;
	padding-bottom: 1px;

	border: none;

	// background: $tempr;
	
}


.recordingPlaybackModal-transcript-header-searchBar:focus{
	outline: none;
	
}

.recordingPlaybackModal-transcript-header-searchBarUI{
	position: absolute;
	pointer-events: none;

	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;


}

.recordingPlaybackModal-transcript-header-searchBarUI-searchBarIcon{
	width: 26px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 4px;
}

.recordingPlaybackModal-transcript-header-searchBarUI-searchBarIcon svg{
	fill: var(--text500);
	width: 19px;
	height: 19px;
	opacity: 0.4;

	position: relative;
	// top: 1px;
}

//
// SEARCH FILTER


.recordingPlaybackModal-transcript-header-searchResultsLabel{
	// position: absolute;
	// right: 4px;
	// top: 0px;
	// bottom: 0px;
	height: 100%;

	min-width: auto;
	white-space: nowrap;

	flex-grow: 0;
	flex-shrink: 0;

	letter-spacing: 0.008em;

	// background: $tempb; 
	padding-right: 4px;
	padding-left: 4px;

	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.4;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.recordingPlaybackModal-transcript-header-searchResultsLabel--noResults{
	opacity: 0.8;
}

//

.recordingPlaybackModal-transcript-header-navigateBtnContainer{
	display: flex;
}

.recordingPlaybackModal-transcript-header-navigateBtn{
	width: 34px;
	height: 30px;
	border-radius: 4px;

	//background: var(--250gray);
	//border: 1px solid lighten(rgb(20,18,22), 13%);
	opacity: 0.8;

	border-radius: 3px;
	background: var(--251gray);
}

.recordingPlaybackModal-transcript-header-navigateBtn:hover{
	background: var(--250gray);
	//border: 1px solid lighten(rgb(20,18,22), 20%);
	opacity: 1;
}

.recordingPlaybackModal-transcript-header-navigateBtn:active{
	background: var(--246gray);
	//border: 1px solid lighten(rgb(20,18,22), 20%);
	opacity: 1;
}

.recordingPlaybackModal-transcript-header-navigateBtn:disabled{
	opacity: 0.4;
	pointer-events: none;
}

.recordingPlaybackModal-transcript-header-navigateBtn svg{
	width: 17px;
	height: 17px;

	opacity: 0.8;

	fill: var(--text600);
}

.recordingPlaybackModal-transcript-header-navigateBtn:hover svg{
	opacity: 1;
}


.recordingPlaybackModal-transcript-header-navigateBtn--up{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.recordingPlaybackModal-transcript-header-navigateBtn--up svg{
	position: relative;
	
}

.recordingPlaybackModal-transcript-header-navigateBtn--down svg{
	position: relative;
}

.recordingPlaybackModal-transcript-header-navigateBtn--down{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-left: -1px;
	border-left: none !important;
}
//

// .docEditor-inDocSearch-separator{
// 	width: 1px;
// 	height: 100%;
// 	background: var(--insideLineFloatingMenu);
// 	margin-right: 4px;
// 	margin-left: 3px;
// }

//

.recordingPlaybackModal-transcript-header-doneSearchBtn{	
	border-radius: 3px;

	margin-left: 4px;

	font-size: 14.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.02em;
	color: $white;

	width: 80px;
	height: 30px;
}

.recordingPlaybackModal-transcript-header-doneSearchBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

.recordingPlaybackModal-transcript-header-doneSearchBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}


//

.recordingPlaybackModal-transcript-header-autoScrollBtn{
	position: absolute;
	top: 7px; // hacky
	//right: calc(18px + 32px);
	right: 18px;

	width: 100px;
	height: 32px;

	font-size: 14.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.02em;
	color: $white;

	// background: $tempr;

	z-index: 50;

	cursor: pointer;
}


.recordingPlaybackModal-transcript-header-autoScrollBtn--light{
	opacity: 0.2;
	transition: all 100ms linear;
}

.recordingPlaybackModal-transcript-header-autoScrollBtn--bold{
	opacity: 0.5;
	transition: all 100ms linear;
}

.recordingPlaybackModal-transcript-header-autoScrollBtn--bold:hover{
	opacity: 0.8;
	transition: all 0ms linear;
}



//

.recordingPlaybackModal-transcript-header-participantList{
	display: flex;
}

.recordingPlaybackModal-transcript-header-participant{
	width: 28px;
	height: 28px;
	margin-left: 4px;

	// background: $tempr;
	opacity: 0.5;
	border-radius: 4px;
}


.recordingPlaybackModal-transcript-header-dropdownBtnContainer{
	width: 32px;
	margin-left: 4px;
}

.recordingPlaybackModal-transcript-header-dropdownBtn{
	width: 32px;
	height: 28px;
	background: rgba(255,255,255,0.01);
	border-radius: 4px;
}

.recordingPlaybackModal-transcript-header-dropdownBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.75;
}

.recordingPlaybackModal-transcript-header-dropdownBtn:hover,
.recordingPlaybackModal-transcript-header-dropdownBtn[data-state="open"]{	
	background: rgba(255,255,255,0.05);
}

.recordingPlaybackModal-transcript-header-dropdownBtn:hover svg,
.recordingPlaybackModal-transcript-header-dropdownBtn[data-state="open"] svg{	
	opacity: 1;
}
