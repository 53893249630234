
.docEditor-docTitle-recordingPreviewContainer{	
	position: absolute;
	top: 0px;
	left: calc(#{$width-docEditorRecordingPreviewContainer} * -1);
	height: 50px;
	width: $width-docEditorRecordingPreviewContainer;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.docEditor-docTitle-recordingPreview{
	width: 72px;
	aspect-ratio: 4/3;
	background: var(--text300);
	border-radius: 3px;

	position: relative;
	z-index: 10;

	overflow: hidden;

	display: flex;
	align-items: center;
	justify-content: center;
}

//
// UPLOADING

.docEditor-docTitle-recordingPreview--uploading{
	background: var(--primary25);
}

.docEditor-docTitle-recordingPreview--uploading-progressBar{
	width: 34px;
	height: 34px;
	
	position: relative;
}


.docEditor-docTitle-recordingPreview--uploading-progressBar .CircularProgressbar {
  width: 100%;  
}

.docEditor-docTitle-recordingPreview--uploading-progressBar .CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  //stroke: var(--primary500);
  stroke: var(--primary500);
  //stroke-linecap: round;

  transition: stroke-dashoffset 0.5s ease 0s;
}

.docEditor-docTitle-recordingPreview--uploading-progressBar .CircularProgressbar .CircularProgressbar-trail {
  // stroke: #d6d6d6;
  // stroke: var(--primary50);
  // stroke: var(--240gray);
  // stroke: red;
  stroke: var(--primary100);
  // opacity: 0.35;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.docEditor-docTitle-recordingPreview--uploading-progressBar .CircularProgressbar .CircularProgressbar-background {
  //fill: #d6d6d6;
}


.docEditor-docTitle-recordingPreview--uploading-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-docTitle-recordingPreview--uploading-iconContainer svg{
	 width: 22px;
	 height: 22px;
	 fill: var(--primary500);
	 //opacity: 0.5;
	 display: none;
}



//
// PROCESSING

.docEditor-docTitle-recordingPreview--processing{
	transform: scale(0.95);
	transform-origin: 50% 50%;
	background: var(--text200);
}

.docEditor-docTitle-recordingPreview--processing-spinnerContainer{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	margin: auto;

	width: 56px;
	height: 56px;
	background: none;
	border-radius: 6px;

	z-index: 10;
}


@keyframes spinnerRotateAnimationRecording {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.docEditor-docTitle-recordingPreview--processing-spinner{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	right: 0px;
	left: 0px;
}

.docEditor-docTitle-recordingPreview--processing-spinner-spinner{
	height: 26px;
	width: 26px;
	
  border-radius: 26px;

  border: 4px rgba(255,255,255,0.3) solid;
  border-top: 4px rgba(255,255,255,0.8) solid;

  opacity: 0.7;

  animation: spinnerRotateAnimationRecording 1.5s infinite linear;
}



//


.docEditor-docTitle-recordingPreview--ready-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;

	opacity: 1;
}

.docEditor-docTitle-recordingPreview:hover
.docEditor-docTitle-recordingPreview--ready-iconContainer{
	opacity: 1;
}


.docEditor-docTitle-recordingPreview--ready-iconContainer svg{
	width: 20px;
	height: 20px;
	fill: rgb(250,250,255);
	opacity: 0.95;	

	filter: drop-shadow(0px 1px 5px rgba(0,0,0,0.2));

	margin-right: -4px;
}

.docEditor-docTitle-recordingPreview--ready:hover
.docEditor-docTitle-recordingPreview--ready-iconContainer svg{
	transform: scale(1.05);
	transform-origin: 50% 50%;
	transition: all 75ms linear;
} 

//

.docEditor-docTitle-recordingPreview-posterCover{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 5;
	background: rgba(0,0,0,0.35);
	background: rgba(0,0,0,0.15);
	opacity: 0.5;
}


.docEditor-docTitle-recordingPreview--ready
.docEditor-docTitle-recordingPreview-posterCover{
	opacity: 1;
}

.docEditor-docTitle-recordingPreview--ready:hover
.docEditor-docTitle-recordingPreview-posterCover{
	opacity: 1;
}

.docEditor-docTitle-recordingPreview--processing
.docEditor-docTitle-recordingPreview-posterCover{
	backdrop-filter: saturate(120%) blur(2px);
}

// .docEditor-docTitle-recordingPreview--ready:hover
// .docEditor-docTitle-recordingPreview-posterCover{
// 	background: rgba(0,0,0,0.45);
// }

img.docEditor-docTitle-recordingPreview-poster{
	width: 100%;
	height: 100%;
	object-fit: cover;

	//transform: scale(1.1);
}


img.docEditor-docTitle-recordingPreview-poster{
	width: 100%;
	height: 100%;
	object-fit: cover;

	//transform: scale(1.1);
}

.docEditor-docTitle-recordingPreview--ready-imageGrid{
	position: relative;
	position: absolute;
	top: -2px;
	left: -2px;
	width: calc(100% + 4px);
	height: calc(100% + 4px); 	
}

.docEditor-docTitle-recordingPreview--ready-imageGrid-image{
	position: absolute;
	width: 50%;
	height: 50%;
}

.docEditor-docTitle-recordingPreview--ready-imageGrid-image:nth-child(1){
	top: 0px;	
	left: 0px;
}

.docEditor-docTitle-recordingPreview--ready-imageGrid-image:nth-child(2){
	top: 0px;	
	right: 0px;
}

.docEditor-docTitle-recordingPreview--ready-imageGrid-image:nth-child(3){
	bottom: 0px;	
	left: 0px;
}

.docEditor-docTitle-recordingPreview--ready-imageGrid-image:nth-child(4){
	bottom: 0px;	
	right: 0px;
}