.projectsPage-activeProjectPanel{
	display: flex;
	flex-direction: column;

	//margin-top: -8px;
	// margin-top: -14px;

}

.projectsPage-activeProjectPanel-statusContainer{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin-bottom: 4px;

	opacity: 0.7;
}


.projectsPage-activeProjectPanel-statusContainer:hover{
	opacity: 1;
}

.projectsPage-activeProjectPanel-statusContainer 
.linearProjectStatusTag{
	padding-left: 0px;	
}

.projectsPage-activeProjectPanel-statusContainer 
.linearProjectStatusTag-bg{
	opacity: 0;
}

.projectsPage-activeProjectPanel-name{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: var(--text500);
	// margin-bottom: 6px;

	justify-content: flex-start;

	padding-bottom: 4px;
	padding-top: 4px;
	padding-left: 8px;
	padding-right: 8px;

	width: 100%;
	box-sizing: border-box;
	margin-left: -8px;
	border-radius: 4px;
	user-select: none;

}

.projectsPage-activeProjectPanel-name:hover{
	// text-decoration: underline;
	// text-decoration-color: var(--text100);
	//background: var(--250gray);
}

.projectsPage-activeProjectPanel-description{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text300);
	margin-bottom: 4px;
	user-select: none;
	cursor: default;
	opacity: 0.75;
}



//
//

$height-members-activeProjectPanel: 52px;

.projectsPage-activeProjectPanel-members{
	position: relative;
	height: $height-members-activeProjectPanel;
	
	margin-left: -10px;

	// background: $tempr;
}

.projectsPage-activeProjectPanel-members-avatar{
	width: 28px;
	height: 28px;
	// background: $tempr;
	
	position: absolute;

	top: calc((#{$height-members-activeProjectPanel} - 28px)/2);
}

.projectsPage-activeProjectPanel-members-avatar{

}

.projectsPage-activeProjectPanel-members-avatar-avatar{
	width: 24px;
	height: 24px;
	border-radius: 6px;
	border: 2px solid var(--bg-docEditor);
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(1){
	z-index: 10;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(2){
	z-index: 9;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(3){
	z-index: 8;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(4){
	z-index: 7;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(5){
	z-index: 6;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(6){
	z-index: 5;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(7){
	z-index: 4;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(8){
	z-index: 3;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(9){
	z-index: 2;
}

.projectsPage-activeProjectPanel-members-avatar:nth-child(10){
	z-index: 1;
}


.projectsPage-activeProjectPanel-members-avatar-tooltip{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -24px;

	opacity: 0;
	transition: opacity 50ms linear;


	padding-top: 2px;
	padding-bottom: 3px;

	visibility: hidden;
	transition: opacity 50ms linear;
	transition-delay: 0ms;

	z-index: 200;

	pointer-events: none;

	font-weight: $lato-medium;
	font-size: 13px;
}

.projectsPage-activeProjectPanel-members-avatar-tooltip span{
	font-weight: $lato-regular;
	margin-left: 0.2em;
	opacity: 0.5;
}


.projectsPage-activeProjectPanel-members:hover 
.projectsPage-activeProjectPanel-members-avatar-tooltip{
	opacity: 1;
	transition: opacity 70ms linear;
	transition-delay: 300ms;
}

.projectsPage-activeProjectPanel-members:hover 
.projectsPage-activeProjectPanel-members-avatar:hover
.projectsPage-activeProjectPanel-members-avatar-tooltip{
	visibility: visible;
}


//
// DOCs

.projectsPage-activeProjectPanel-docs{
	margin-left: -6.5px;
}

.projectsPage-activeProjectPanel-docs-docBtn{
	height: 28px;
	justify-content: flex-start;
	border-radius: 3px;
	padding-left: 4px;
	width: 100%;

	box-sizing: border-box;
	padding-right: 4px;

}

.projectsPage-activeProjectPanel-docs-docBtn:hover{
	background: var(--250gray);
}

.projectsPage-activeProjectPanel-docs-docBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 28px;
	width: 18px;

	margin-right: 4px;
}

.projectsPage-activeProjectPanel-docs-docBtn-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.5;
}

.projectsPage-activeProjectPanel-docs-docBtn-title{
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text500);
}

.projectsPage-activeProjectPanel-docs-docBtn-timestampLabel{
	width: 120px;
	margin-left: auto;
	
	font-size: 12px;
	font-weight: $lato-regular;
	letter-spacing: 0.04em;

	//color: rgb(125,124,124);
	color: var(--text200);

	box-sizing: border-box;
	padding-left: 10px;
	padding-top: 2px;

	text-align: right;

	opacity: 0.8;
	transition: opacity 50ms;
}

.projectsPage-activeProjectPanel:hover
.projectsPage-activeProjectPanel-docs-docBtn-timestampLabel{
	opacity: 1;
	transition: opacity 50ms;
}