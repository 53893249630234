.message-inputBox-addMediaPopover{
	width: 420px;
	height: 460px;

	// background: $tempr;
}

.message-inputBox-addMediaPopover-search{
	
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	//border-bottom: 1px solid var(--line300);

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 10px;
	padding-bottom: 10px;

	position: relative;
}

.message-inputBox-addMediaPopover-search-input{
	width: 100%;
	height: 32px;

	padding-left: 8px;
	box-sizing: border-box;

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text600);

	background: var(--bg-docEditor);

	border-radius: 4px;
	border: 1px solid var(--line300);
}

.message-inputBox-addMediaPopover-search-input:focus{
	border: 1px solid var(--primary500);
	outline: 2px solid var(--primary50);
}

.message-inputBox-addMediaPopover-search-clearBtn{
	position: absolute;
	top: 10px;
	bottom: 6px;
	right: 14px;
	// background: $tempr;

	height: 29px;
	padding-right: 12px;
	padding-left: 12px;

	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.5;

	cursor: pointer;
}

.message-inputBox-addMediaPopover-search-clearBtn:hover{
	opacity: 0.75;
}
//
//

$padding-GIFResult: 6px;

.message-inputBox-addMediaPopover-resultsContainer{
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;

	flex-wrap: wrap;

	overflow-y: scroll;

	padding-bottom: 14px;
	padding-top: 0px;
	padding-left: $padding-GIFResult;
	padding-right: $padding-GIFResult;
}


// GIF RESULT

.message-inputBox-addMediaPopover-resultsContainer--GIFSearchResultsGrid{
	// display: grid;
 //  grid-template-columns: repeat(2, 1fr);
 //  grid-template-rows: masonry;

   display: flex;
   width: auto;
   // margin-left: -30px;
   box-sizing: border-box;   

}

.message-inputBox-addMediaPopover-resultsContainer--GIFSearchResultsGrid-column{
	// padding-left: 30px;
	background-clip: padding-box;
}

.message-inputBox-addMediaBtn--GIF-searchResult{	
	box-sizing: border-box;
	padding: $padding-GIFResult;

	position: relative;

	cursor: pointer;
}

.message-inputBox-addMediaBtn--GIF-searchResult-preview{
	width: 100%;
	// height: 100%;
	border-radius: 5px;
	object-fit: contain;
}

.message-inputBox-addMediaBtn--GIF-searchResult-preview:hover{
	outline: 2px solid var(--primary500);
}

// 

.message-inputBox-addMediaBtn--GIF-category{
	width: calc(50% - #{$padding-GIFResult});
	height: 140px;
	box-sizing: border-box;
	padding: $padding-GIFResult;

	position: relative;

	cursor: pointer;
}

.message-inputBox-addMediaBtn--GIF-category-title{
	position: absolute;
	left: $padding-GIFResult;
	right: $padding-GIFResult;
	top: $padding-GIFResult;
	bottom: $padding-GIFResult;

	border-radius: 5px;

	background: rgba(0,0,0,0.25);

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 16.5px;
	font-weight: $lato-bold;
	color: $white;

	user-select: none;
}

.message-inputBox-addMediaBtn--GIF-category:hover 
.message-inputBox-addMediaBtn--GIF-category-title{
	outline: 2px solid var(--primary500);
	background: rgba(0,0,0,0.4);
}

.message-inputBox-addMediaBtn--GIF-category-preview{
	width: 100%;
	height: 100%;
	border-radius: 5px;
	object-fit: cover;
}