

@import 'Messages-MessageContent-Highlights-Inline';

//

.message-content-highlight{
	width: 100%;
	margin-top: 5px;	

	display: flex;
	flex-direction: column;

	// background: $tempr;

	position: relative;
}

$leftOffset-messagePanel: 42px;
$rightOffset-messagePanel: 5px;

.message-content-highlight{
	position: relative;
	width: calc(100% + #{$leftOffset-messagePanel} + #{$rightOffset-messagePanel});
	margin-left: calc(#{$leftOffset-messagePanel} * -1);
}

.message-content-highlightInlineMeasurer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
}

@import 'Messages-MessageContent-Highlights-Video';
@import 'Messages-MessageContent-Highlights-Transcript';


.message-content-highlight-description{
	box-sizing: border-box;
	padding-top: 12px;
	padding-left: 16px;
	padding-right: 12px;
	padding-bottom: 6px;
}

.message-content-highlight-description .message-para{
	opacity: 1;
}

.message-content-highlight-actionRow{
	display: flex;

	padding-left: 9px;
}

.message-content-highlight-actionRow-btn{
	height: 28px;
	padding-left: 5px;
	padding-right: 8px;
	border-radius: 3px;

	opacity: 0.7;
}

.message-content-highlight-actionRow-btn:hover{
	opacity: 1;
	background: var(--251gray);
}

.message-content-highlight-actionRow-btn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.message-content-highlight-actionRow-btn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	opacity: 0.8;
}

.message-content-highlight-actionRow-btn:hover
.message-content-highlight-actionRow-btn-iconContainer svg{
	opacity: 1;
}


.message-content-highlight-actionRow-btn-iconContainer svg.icon--name--watchRecording{
	position: relative;
	top: -1px;
}

.message-content-highlight-actionRow-btn-label{
	font-size: 14.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;
	color: var(--text600);
}

//
//
