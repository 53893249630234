




.doc-video-zoomedUI-controlBar-seekBarContainer{
	position: absolute;
	top: -9px; 
	left: 0px;
	right: 0px;
	height: 24px;
	//background: $tempb;

	cursor: pointer;

	z-index: 50;
}

.doc-video-zoomedUI-controlBar-seekBarInnerContainer{
	width: 100%;
	height: 100%;
	position: relative;
}

.doc-video-zoomedUI-controlBar-seekBar{
	height: 100%;
	// background: $green;
}

.doc-video-zoomedUI-controlBar-seekBar-track{
	height: 6px;
	margin-top: 10px;
}

.doc-video-zoomedUI-controlBar-seekBar-track-0{
	//background: rgb(139,129,255);
	background: #81b0ff;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.doc-video-zoomedUI-controlBar-seekBar-track-1{	
	border-radius: 0px;
	background: rgb(75,75,89);
}

.doc-video-zoomedUI-controlBar-seekBar-thumb{
	display: none;
	width: 12px;
	height: 12px;
	background: $white;
	border-radius: 6px;
	
	margin-top: 8px;
	// margin-left: -6px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 0px 2px 0px rgba(0,0,0,0.12),
		0px 0px 0px 1px rgba(0,0,0,0.12),

		;	
}

// SEEK BAR PREVIEW

.doc-video-zoomedUI-controlBar-seekBar-hoverPreview{
	position: absolute;

	background: $white;
	
	pointer-events: none;
	border-radius: 2px;

	outline: 1px solid rgba(2,2,5,0.2);
	border: none;
	overflow: hidden;
}


.doc-video-zoomedUI-controlBar-seekBar-hoverPreview-storyboardImageOuterContainer{
	width: 100%;
	height: 100%;
	border-radius: 2px;

	overflow: hidden;
}


.doc-video-zoomedUI-controlBar-seekBar-hoverPreview-storyboardImage-image{
	transform-origin: 0% 0%;
}

.doc-video-zoomedUI-controlBar-seekBar-hoverPreview-storyboardImage-image{
	background-image: url('https://res.cloudinary.com/yarn/image/upload/v1667235735/dummydata/examplestoryboard_a1ttwy.png');
	background-position: 100px 200px;
	background-repeat: 'no repeat';
}

///

.doc-video-zoomedUI-controlBar-seekBar-hoverPreview-timestampContainer{
	position: absolute;
	bottom: 2px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-video-zoomedUI-controlBar-seekBar-hoverPreview-timestamp{
	width: 44px;
	height: 20px;

	background: rgb(60,60,70);
	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: $white;
	font-size: 13px;
	font-weight: $lato-semibold;

	letter-spacing: 0.005em;
}

.doc-video-zoomedUI-controlBar-seekBar-hoverMarker{
	position: absolute;
	top: 10px;

	width: 2px;
	height: 6px;
	background: $white;
	border-radius: 0px;
	transform: translateX(-1px);

	pointer-events: none;
}
