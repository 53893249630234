      			


.commandMenu-row{
	height: 38px;
	width: 100%;
	//background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;

	border-radius: 4px;

	user-select: none;
	cursor: pointer;

	position: relative;
}

//// TEMP GET RID OF JASPER
.commandMenu-row:nth-child(8){
	display: none;
}


.commandMenu-row--activeStatus--active{
	background: rgba(255,255,255,0.10);
}

.commandMenu-row--activeStatus--active:hover{
	background: rgba(255,255,255,0.11);
}

.commandMenu-row--activeStatus--inactive:hover{
	background: rgba(255,255,255,0.08);	
}


.commandMenu-row--activeStatus--inactive
.commandMenu-row-label{
	opacity: 0.8;
}
.commandMenu-row--activeStatus--inactive
.commandMenu-row-visual{
	opacity: 0.95;
}

$activatedBrightBG: rgba(255,255,255,0.18);
$activatedDarkBG: rgba(255,255,255,0.1);

@keyframes activatedFlash { 
  0% { background: $activatedBrightBG; } 
  50% { background: $activatedDarkBG; }
}

.commandMenu-row--activeStatus--activated{
	animation: activatedFlash 150ms 2 linear;
}

//

.commandMenu-row-visual{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,0,0.05);

	border-radius: 5px;
	overflow: hidden;
	margin-right: 10px;
}

.commandMenu-row-visual--actionIcon svg{
	fill: $white;
	height: 16px;
	width: 16px;
}

.commandMenu-row-visual--avatar-avatar{

}



.commandMenu-row--userAction--actionType--pingCursor
.commandMenu-row-visual{
	background: var(--primary500);
}

//

.commandMenu-row--userAction--actionType--jumpToCursor
.commandMenu-row-visual{	
	background: var(--accentGreen600);
	background-opacity: 0.9;
}

.commandMenu-row--userAction--actionType--jumpToCursor
.commandMenu-row-visual svg{
	margin-right: -2px;	
}



//

.commandMenu-row--userAction--actionType--newDiscussion
.commandMenu-row-visual{
	background: var(--accentPurple600);
}

//

.commandMenu-row--userAction--actionType--directMessage
.commandMenu-row-visual{
	background: var(--accentOrange600);
}

.commandMenu-row--userAction--actionType--directMessage
.commandMenu-row-visual svg{
	width: 18px;
	height: 18px;
}

//

.commandMenu-row--userAction--actionType--roundRobin
.commandMenu-row-visual{
	background: var(--accentPurple600);
}

.commandMenu-row--userAction--actionType--roundRobin
.commandMenu-row-visual svg{
	transform: scaleX(-1.0);
}




//

.commandMenu-row--userAction--actionType--sendLinkViaSlack
.commandMenu-row-visual{
	background: var(--accentPink600);
	background-opacity: 0.75;
}

.commandMenu-row--userAction--actionType--sendLinkViaSlack
.commandMenu-row-visual svg{
	width: 14px;
	height: 14px;
}


//

.commandMenu-row-label{
	display: flex;
	align-items: center;
}

.commandMenu-row-label-primary{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: $white;

	margin-right: 6px;
}



.commandMenu-row-label-secondary{
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: $white;
	opacity: 0.5;
}

.commandMenu-row--activeStatus--active
.commandMenu-row-label-secondary{
	opacity: 0.7;
}


//

.commandMenu-row-activeRightHint{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.commandMenu-row-activeRightHint-label{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $white;
	opacity: 0.5;
	letter-spacing: 0.01em;

	margin-right: 5px;
}


.commandMenu-row-activeRightHint-iconContainer{	
	height: 20px;
	width: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.commandMenu-row-activeRightHint-iconContainer svg{
	fill: $white;
	height: 14px;
	width: 14px;
	opacity: 0.8;
}

// KEYBOARD SHORTCUTS

.commandMenu-row-activeRightHint-keyboardShortcut{
	background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 4px;
	padding-right: 4px;
	height: 18px;

	border-radius: 3px;
	background: rgba(255,255,255,0.06);

	margin-right: -4px;
}



.commandMenu-row-activeRightHint-keyboardShortcut svg{
	width: 12px;
	height: 12px;
	fill: $white;

	opacity: 0.75;
	margin-left: 2px;
}
.commandMenu-row-activeRightHint-keyboardShortcut svg:first-child{
	margin-left: 0px;
}

.commandMenu-row-activeRightHint-keyboardShortcut-letter{
	color: $white;
	font-size: 12px;

	opacity: 0.75;
	margin-left: 2px;
}