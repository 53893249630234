
.roadmapPageContainer{
	position: fixed;
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);
	
	overflow-x: hidden;
	overflow-y: scroll;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-channelFrame);

	z-index: 10;

	border-radius: 8px;
}

.roadmapPageContainer--sideMenuState--hidden{
	left: calc(#{$windowMargin-sideMenuMode} - 1px);
}

$maxWidth-centerArea-roadmapPage: 800px;

//

@import 'Roadmaps-RoadmapPage-Header';



//

.roadmapPage-contentContainer{
	width: 100%;
	height: 100%;
	display: flex;
}

.roadmapPage-leftSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	// widths set dynamically in coreLayout
}

.roadmapPage-center{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	max-width: $maxWidth-centerArea-roadmapPage;
	// widths set dynamically in coreLayout
}

.roadmapPage-rightPanel{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;	
	position: relative;
	// widths set dynamically in coreLayout
}




// PROJECT LIST

.roadmapPage-projectList{
	width: 100%;
	max-width: $maxWidth-centerArea-roadmapPage;

	position: relative;
}

.roadmapPage-projectList-header{
	font-size: 26px;
	font-weight: $lato-heavy;
	color: var(--text600);
	margin-bottom: 20px;

	width: 100%;
}

// FOOTER

.roadmapPage-projectList-list-footerRow{
	display: flex;
	align-items: center;	

	margin-left: -6px;
	width: calc(100% + 6px);

	//margin-top: -4px;
	margin-bottom: -2px;

	position: relative;
	z-index: 5;
}


.roadmapPage-projectList-list-newProjectBtn,
.roadmapPage-docList-header-newDocBtn,
.roadmapsHomePage-projectList-header-newProjectBtn{
	padding-left: 6px;
	padding-right: 16px;
	// background: $tempr;	

	border-radius: 4px;

	height: 34px;
	opacity: 0.75;
	margin-left: 0px;

	background: var(--253gray);
}

.roadmapPage-docList-header-newDocBtn{
	margin-left: auto;
}

.roadmapsHomePage-projectList-header-newProjectBtn{
	margin-left: auto;
}

.roadmapPage-projectList-list-newProjectBtn:hover,
.roadmapPage-docList-header-newDocBtn:hover,
.roadmapsHomePage-projectList-header-newProjectBtn:hover{
	background: var(--250gray);
	opacity: 1;
}


.roadmapPage-projectList-list-newProjectBtn-iconContainer,
.roadmapPage-docList-header-newDocBtn-iconContainer,
.roadmapsHomePage-projectList-header-newProjectBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 28px;
	width: 28px;
}

.roadmapPage-projectList-list-newProjectBtn-iconContainer svg,
.roadmapPage-docList-header-newDocBtn-iconContainer svg,
.roadmapsHomePage-projectList-header-newProjectBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.75;
}

.roadmapPage-projectList-list-newProjectBtn-label,
.roadmapPage-docList-header-newDocBtn-label,
.roadmapsHomePage-projectList-header-newProjectBtn-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	letter-spacing: 0.001em;
	color: var(--text500);
	position: relative;
	top: -0.5px;
}



// New Doc Button

// .roadmapPage-docList-header-newDocBtn{
// 	margin-left: auto;
// 	padding-left: 7px;
// 	padding-right: 10px;

// 	height: 30px;
// 	border-radius: 4px;

// 	// background: var(--primary500);
// 	// background: var(--text400);
// 	// border: 1px solid var(--bg-docEditor);
// 	background: var(--253gray);
// }

// .roadmapPage-docList-header-newDocBtn:hover{
// 	background: var(--primary600);
// 	background: var(--text600);
// 	border: 1px solid var(--bg-docEditor);
// }

// [data-theme="dark"]{
// 	.roadmapPage-docList-header-newDocBtn{
// 		border-radius: 3px;
// 		background: var(--250gray);
// 		border: 1px solid var(--246gray);
// 	}

// 	.roadmapPage-docList-header-newDocBtn:hover{
// 		background: var(--248gray);
// 		border: 1px solid var(--240gray);
// 	}
// }


// .roadmapPage-docList-header-newDocBtn-iconContainer{
// 	margin-right: 3px;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// }

// .roadmapPage-docList-header-newDocBtn-iconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);
// 	opacity: 0.9;
// }

// .roadmapPage-docList-header-newDocBtn-label{
// 	color: var(--text600);
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.008em;
// }



//

.roadmapPage-projectList-list-completedToggle{
	margin-left: auto;
	//margin-left: -9px;
	padding-left: 10px;
	padding-right: 10px;
	height: 32px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.5;
	font-size: 15.5px;

	margin-top: 2px;

	// background: $tempr;
	border-radius: 4px;	
}

.roadmapPage-projectList-list-completedToggle:hover{
	background: var(--250gray);
}


.roadmapPage-docList-header-rightContainer{
	margin-left: auto;
	display: flex;
}

// DOC LIST

.roadmapPage-docList{
	width: 100%;
	max-width: $maxWidth-centerArea-roadmapPage;

	position: relative;
	padding-top: 36px;
	margin-top: 24px;

	border-top: 1px solid var(--line200);
}

.roadmapPage-docList--empty{
	display: flex;
	align-items: center;
	justify-content: center;
}

.roadmapPage-docList-header{
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 4px;
}

.roadmapPage-docList-header-label{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: var(--text500);
	cursor: default;
	user-select: none;
}



//

.roadmapPage-docList--empty-box{
	width: 100%;
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	border-radius: 10px;

	max-width: 640px;

	background: var(--253gray);

	margin-top: 24px;

	box-sizing: border-box;
	padding-top: 74px;
	padding-bottom: 74px;
	padding-left: 72px;
	padding-right: 72px;
	
	margin-bottom: 48px;

	user-select: none;
	cursor: default;

	text-align: center;
}


.roadmapPage-docList--empty-box-emojiContainer{
	font-size: 56px;
	margin-bottom: 32px;
}

.roadmapPage-docList--empty-box-title{
	// flex-wrap: wrap;
	margin-bottom: 6px;

	font-size: 19px;
	font-weight: $lato-bold;
	color: var(--text500);
}

.roadmapPage-docList--empty-box-description{
	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.5;

	max-width: 400px;

}

button.roadmapPage-docList--empty-box-newDocBtn{
	margin-top: 24px;

	padding-left: 12px;
	padding-right: 16px;
	height: 34px;

	border-radius: 5px;

	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);

	border-radius: 4px;

	background: var(--bg-docEditor);
}

button.roadmapPage-docList--empty-box-newDocBtn:hover{
	border: 1px solid var(--line500);
	border-bottom: 1px solid var(--line600);
}

.roadmapPage-docList--empty-box-newDocBtn-iconContainer{
	display: flex;
	width: 24px;
	height: 24px;

	align-items: center;
	justify-content: center;

	margin-right: 0px;
}

.roadmapPage-docList--empty-box-newDocBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.75;
}

.roadmapPage-docList--empty-box-newDocBtn-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text500);
}