


.sideMenuContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;

	z-index: 15;
}

.sideMenuContainer-openCloseSpacer{
	width: 30px;
	position: absolute;
	top: -999px;
	left: -999px;	
	height: 0px;
	pointer-events: none;
}

.sideMenuContainer--hidden
.sideMenuContainer-openCloseSpacer{
	width: 20px;
}

.sideMenuContainer--figmaModalZoomed,
.sideMenuContainer--messageMediaIsZoomed{
	z-index: 5;
}

// .sideMenuContainer:hover{
// 	z-index: 15;	
// }

.electronWindowDragArea{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: calc($windowMargin-sideMenuMode + 6px);

	z-index: 9999;

	background: rgba(255,255,255,0);

	-webkit-user-select: none;
	-webkit-app-region: drag;

	// background: red;
}

.sideMenuContainer--visible{	
	// background: $tempg;
}

$width-hiddenSideMenuHover: 40px; // area where you can hover and pop out menu when its hidden
$topBottomMargin-hiddenSideMenuHover: 50px; // protected area in top left and bottom left so can access e.g. the mac traffic lights




.sideMenuContainer--hidden{
	
	//visibility: hidden; // for now, its a distraction
	//pointer-events: none;
	// top: $topBottomMargin-hiddenSideMenuHover; // allow access to top-left-buttons
	// bottom: $topBottomMargin-hiddenSideMenuHover;
	// width: $width-hiddenSideMenuHover;
}

// .sideMenuContainer--hidden:hover{
// 	top: 0px;
// 	bottom: 0px;
// 	width: 240px;
// }

//

.sideMenuContainer--visible .sideMenu{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	// background: $tempg;
}

.sideMenuContainer--hidden .sideMenu{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}

.sideMenuContainer--hidden:hover .sideMenu{
	opacity: 1;
}


.sideMenuHiddenHoverArea{
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	// width set in core laoyut
	
	// background: $tempr;

	z-index: 12000;
}

.sideMenuTopHoverArea{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 200px;
	height: 50px;
	
	// background: $tempr;

	z-index: 12000;
}

.sideMenuContainer--hidden-sideMenuOuterContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;

	//width: set in core layout

	z-index: 14000;
}

.sideMenuContainer--hidden-sideMenuOuterContainer--hide{
	pointer-events: none;
}

.sideMenuContainer--hidden-sideMenuInnerContainer{
	position: absolute;
	left: 4px;
	top: 4px;
	right: 24px; // don't want to fall off immediately
	bottom: 4px;
	
	background: var(--floatingSideMenuBG);
	border: 1px solid var(--floatingSideMenuBorder);
	border-bottom: 1px solid var(--floatingSideMenuBottomBorder);
	border-radius: 8px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12), 0px 4px 8px 0px rgba(0,0,0,0.06);
}



//
// HIDDEN BUTTON
.sideMenuHiddenLeftToggle{
	position: fixed;
	top: 50px;
	bottom: 0px;
	left: 0px;
	width: 14px;
	background: $invisible;
	cursor: default;

	z-index: 10000;
}