.custom-layout {
	position: absolute;
	inset: 0px;
	z-index: 300;
	pointer-events: none;
}

.custom-toolbar {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	gap: 8px;
}

.custom-button {
	pointer-events: all;
	padding: 4px 12px;
	background: white;
	border: 1px solid black;
	border-radius: 64px;
}

.custom-button[data-isactive='true'] {
	background-color: black;
	color: white;
}
