


.selectUserPopover-item{
	display: flex;
	height: 34px;
	width: 100%;
	border-radius: 4px;

	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 6px;
}

.selectUserPopover-item:hover{
	background: var(--hoverBG-item-dropdown);
}

.selectUserPopover-item:disabled .selectUserPopover-item-avatar,
.selectUserPopover-item:disabled .selectUserPopover-item-username{
	opacity: 0.65;
	// want to keep the status indicators visible
}

.selectUserPopover-item:disabled:hover{
	background: none;
}

.selectUserPopover-item-avatarContainer{
	width: 28px;
	height: 28px;

	// width: 24px;
	// height: 24px;

	margin-right: 8px;
	position: relative;
}

.selectUserPopover-item-avatar{
	border-radius: 4px;
}

.selectUserPopover-item-username{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//
// CHECKBOX


.selectUserPopover-item-checkboxContainer{
	width: 24px;
	height: 24px;
	margin-right: 2px;
	
	padding-left: 8px;
	margin-left: auto;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.selectUserPopover-item-checkbox{
	width: 14px;
	height: 14px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.selectUserPopover-item[data-is-checked="false"]
.selectUserPopover-item-checkbox{
	background: rgb(255,255,255);
	border: 1px solid rgb(181, 181, 192);
}

.selectUserPopover-item[data-is-checked="true"]
.selectUserPopover-item-checkbox{
	background: rgb(24, 122, 242);
	border: 1px solid rgb(0, 103, 230);
}



$dashoffset: 90; // must admit just kind of reverse engineered this number

.selectUserPopover-item-checkbox svg{
	pointer-events: none;

	width: 11px;
	height: 11px;
	stroke: var(--toDoItem-checkIconColor);

	z-index: 10;
	position: relative;	

	stroke-width: 10px;
  stroke-dasharray: $dashoffset;
  stroke-dashoffset: $dashoffset;
}

.selectUserPopover-item[data-is-checked="true"]
.selectUserPopover-item-checkbox svg{
	//transition: stroke-dashoffset 240ms linear;	
	stroke-dashoffset: 0;
}

.selectUserPopover-item[data-is-checked="false"]
.selectUserPopover-item-checkbox svg{
	stroke-dashoffset: $dashoffset;

	//transition: stroke-dashoffset 0ms linear;
}

//
// END BUTTON
.selectUserPopover-item-endBtnContainer{
	display: flex;
	height: 100%;
	align-items: center;
	margin-left: auto;


}

.selectUserPopover-item-endBtn{
	height: 20px;
	padding-left: 6px;
	padding-right: 6px;

	border-radius: 3px;

	color: var(--text500);

	font-size: 12px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	
	color: var(--primary500);
	background: var(--primary25);

	display: flex;
	align-items: center;
	justify-content: center;	
}

.selectUserPopover-item:hover
.selectUserPopover-item-endBtn{
	//color: var(--primary500);
	color: var(--primary600);
	background: var(--primary100);
}

.selectUserPopover--manageDocFollowers
.selectUserPopover-item:hover {
	background: var(--primary25);
}

[data-theme="dark"] {
	.selectUserPopover-item[data-is-checked="false"]
	.selectUserPopover-item-endBtn{
		color: transparentize(#8FC4FF, 0.4);
		background: transparentize(#8FC4FF, 0.9);
	}	
	.selectUserPopover-item[data-is-checked="false"]:hover
	.selectUserPopover-item-endBtn{
		color: #8FC4FF;
		background: transparentize(#8FC4FF, 0.7);
	}	
	.selectUserPopover--manageDocFollowers
	.selectUserPopover-item[data-is-checked="false"]:hover{
		background: transparentize(#8FC4FF, 0.9);
	}
}


.selectUserPopover--manageDocFollowers
.selectUserPopover-item[data-is-checked="false"]{
	opacity: 1;
}


.selectUserPopover--manageDocFollowers
.selectUserPopover-item[data-is-checked="true"]{
	opacity: 0.5;
	pointer-events: none;
}

.selectUserPopover--manageDocFollowers
.selectUserPopover-item[data-is-checked="true"]
.selectUserPopover-item-endBtn{
	background: var(--bgSideMenuTabActiveHover);
	color: var(--text600);
	opacity: 0.5;
}

//
// STATUS TAG FOR PING DOC

.selectUserPopover-item-avatar-statusDot{
	position: absolute;
	bottom: 0px;
	right: -1px;

	width: 6px;
	height: 6px;
	border-radius: 50%;
	border: 1.5px solid;

	outline: 2px solid var(--bg-dropdown);

	z-index: 10;
}

.selectUserPopover-item-avatar-statusDot--state--online{
	border-color: var(--activeUserGreen);
	background: var(--activeUserGreen);
}

.selectUserPopover-item-avatar-statusDot--state--away{
	background: var(--bg-dropdown);
	border-color: var(--line600);
}

//

.selectUserPopover-item-statusTagContainer{
	//background: $tempr;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: auto;
}

.selectUserPopover-item-statusTagContainer-statusTag{
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 4px; 

	font-size: 13px;
	letter-spacing: 0.008em;
	font-weight: $lato-medium;

	border: 1px solid $invisible;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}

.selectUserPopover-item-statusTagContainer-statusTag--state--online{
	color: var(--text600);
	//border: 1px solid var(--line300);
	background: var(--250gray)
}
[data-theme="dark"] {
	.selectUserPopover-item-statusTagContainer-statusTag--state--online{
		background: rgba(104,255,211,0.1);
	}
}


.selectUserPopover-item-statusTagContainer-statusTag--state--away{
	//background: rgba(251,251,251);
	color: var(--text300);
	opacity: 0.8;
}




[data-theme="dark"] {
	.selectUserPopover-item-statusTagContainer-statusTag--state--online
	.selectUserPopover-item-statusTagContainer-statusTag-dot{
		background: var(--activeUserGreen);
	}
}




.selectUserPopover-item-statusTagContainer-statusTag--state--away
.selectUserPopover-item-statusTagContainer-statusTag-dot{
	background: var(--bg-docEditor);
	border: 1px solid var(--line600);
}