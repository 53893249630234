$minHeight-checkBox-toDoItem: 30px;
$exteriorSideDimension-checkbox-toDoItem: 16px;
$leftPadding-toDoItem: 7px;
// $gutterOverhang-toDoItem: 3px;
$gutterOverhang-toDoItem: 5px;
$padding-checkBoxContainer-toDoItem: 4px;
$defaultWidth-toDoItem: calc(100% + #{$gutterOverhang-toDoItem} + #{$gutterOverhang-toDoItem});
$defaultMargin-toDoItem: calc(#{$gutterOverhang-toDoItem} * -1);

.doc-toDoItem{
	display: flex;

	width: $defaultWidth-toDoItem;
	margin-left: $defaultMargin-toDoItem;

	min-height: $minHeight-checkBox-toDoItem;

	border-radius: 6px;

	position: relative;
	// background: $tempr;
	// background: $tempr;
	z-index: 1;
}

.doc-toDoItem--selected{
	background: $tempg;	
}



.doc-toDoItem:hover{
	z-index: 10;
}


// .doc-toDoItem-shortIdContainer{
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;

// 	pointer-events: none;
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;

// 	user-select: none !important;
// }

// .doc-toDoItem-shortId{

// 	font-size: 13px;
// 	font-weight: $lato-regular;
// 	color: var(--text500);

// 	opacity: 0.6;
// }


.doc-toDoItem-UIContainer{
	// also used for other containers
	position: absolute;
	width: 100%;
	height: 100%;

	// display: none;
}

//
// CHECKBOX


.doc-toDoItem-checkboxContainer{
	position: absolute;

	z-index: 1000;	

	top: calc(((#{$minHeight-checkBox-toDoItem} - #{$exteriorSideDimension-checkbox-toDoItem}) / 2) - #{$padding-checkBoxContainer-toDoItem});
	left: calc(#{$leftPadding-toDoItem} - #{$padding-checkBoxContainer-toDoItem});
	height: $exteriorSideDimension-checkbox-toDoItem;
	width: $exteriorSideDimension-checkbox-toDoItem;

	padding: $padding-checkBoxContainer-toDoItem;

	user-select: none;
	cursor: pointer;

	will-change: transform;
}

.doc-toDoItem-checkbox{
	width: calc(#{$exteriorSideDimension-checkbox-toDoItem} - 2px); // take off border
	height: calc(#{$exteriorSideDimension-checkbox-toDoItem} - 2px); // take off border
	
	border-radius: 4px;	

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 150;

	border: 1px solid;


}


//
// STATIC HTML

.staticHTML .doc-toDoItem-checkbox--state--unchecked{
		background: rgb(255,255,255);
		border-color: rgb(181, 181, 192);
}

[data-theme="dark"]{
	.staticHTML .doc-toDoItem-checkbox--state--unchecked{
		background: rgb(28,26,32);
		border-color: rgb(255,255,255,0.25);
	}	
}

.staticHTML .doc-toDoItem-checkbox--state--checked{
		background: rgb(24, 122, 242);
		border-color: rgb(0, 103, 230);
}

[data-theme="dark"]{
	.staticHTML .doc-toDoItem-checkbox--state--checked{
		background: rgb(111, 173, 249);
		border-color: rgb(97, 152, 220);
	}	
}


$dashoffset: 90; // must admit just kind of reverse engineered this number

.doc-toDoItem-checkbox svg{
	pointer-events: none;

	width: 11px;
	height: 11px;
	stroke: var(--toDoItem-checkIconColor);


	z-index: 10;
	position: relative;	

	stroke-width: 10px;
  stroke-dasharray: $dashoffset;
  stroke-dashoffset: $dashoffset;
}

.doc-toDoItem-checkbox--state--checked svg{

	transition: stroke-dashoffset 240ms linear;	
	stroke-dashoffset: 0;
}

.doc-toDoItem-checkbox--state--unchecked svg{
	stroke-dashoffset: $dashoffset;

	transition: stroke-dashoffset 0ms linear;
}

//

.doc-toDoItem-checkbox-outline{
	position: absolute;
	top: $padding-checkBoxContainer-toDoItem;
	left: $padding-checkBoxContainer-toDoItem;
	right: $padding-checkBoxContainer-toDoItem;
	bottom: $padding-checkBoxContainer-toDoItem;
	border-radius: 5px;
	background: var(--primary400);
}

//
// Temporary dark border on background
.doc-toDoItem-checkboxOuterContainer-mouseDownBG{
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 50;
	background: var(--toDoItem-mouseDownBG);

	border-radius: 6px;
}

//
// This puts white box on top of text to simulate opacity animation
.doc-toDoItem-checkboxOuterContainer-checkedOpacityFake{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;

	height: 100%;
	pointer-events: none;
	z-index: 100;
	background: rgb(255,255,255);
}
//

.doc-toDoItem[data-state='unchecked']:hover
.doc-toDoItem-checkbox{
	border: 1px solid var(--line600);
}

//

.doc-toDoItem-contents{

	position: relative;
	z-index: 5000;

	// this causes cursor to disappear on electron
	// display: flex;
	// align-items: center;

	cursor: text;
	
	box-sizing: border-box;
	width: calc(100% - 20px);
	// width: 100%;
	// height: 100%;
	// background: $tempr;

	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 14px;
	padding-right: 2px;

	margin-left: 20px;
	

	// background: $tempr;
}



.doc-toDoItem--empty .doc-para::before{
	position: absolute;
	height: 0px;
	width: 100px;
	pointer-events: none;
	-webkit-text-fill-color: var(--toDoItem-placeholderColor); // to do, refactor
}

.doc-toDoItem--empty .doc-para::before{	
	content: "To-do";
	// content: "Issue Title";
	//content: "List Item";
}


.doc-toDoItem-contents .doc-para{
	line-height: 1.5;
}

//
// CSS ANIMATIONS
// to do, change to opacity on the content side with new span styling

.doc-toDoItem[data-is-checked='true']
.doc-toDoItem-contents .doc-para{	
	// opacity: 0.55;
	color: rgb(140,140,145);
	
	text-decoration: line-through !important;
	text-decoration-color: rgba(160,160,165,0.6) !important;
	
	transition-property: color, text-decoration-color;
	transition-duration: 400ms, 900ms;	
	transition-delay: 100ms, 300ms;
	transition-timing-function: ease-in-out;
}

//

.doc-toDoItem[data-is-checked='false']
.doc-toDoItem-contents .doc-para{
	// opacity: 1;
	color: var(--text500);

	text-decoration-color: rgba(255,0,0,0) !important;

	transition-property: color, text-decoration-color;
	transition-duration: 200ms, 200ms;	
	transition-delay: 50ms, 0ms;
	transition-timing-function: ease-out;
}

//
// CODE GOES GRAY

.doc-toDoItem[data-is-checked='true']
.doc-toDoItem-contents .doc-para span.doc-span--style--code{	
	// opacity: 0.55;
	color: var(--text200);
	
	transition-property: color;
	transition-duration: 400ms;	
	transition-delay: 100ms;
	transition-timing-function: ease-in-out;
}

//

.doc-toDoItem[data-is-checked='false']
.doc-toDoItem-contents .doc-para span.doc-span--style--code{
	// opacity: 1;
	color: var(--docCodeTextColor);

	text-decoration-color: rgba(255,0,0,0) !important;

	transition-property: color;
	transition-duration: 200ms;	
	transition-delay: 50ms;
	transition-timing-function: ease-out;
}

//
// LIGHT GOES LIGHTER

.doc-toDoItem[data-is-checked='true']
.doc-toDoItem-contents .doc-para span.doc-span--style--light span{	
	// opacity: 0.55;
	color: var(--text100);
	
	transition-property: color;
	transition-duration: 400ms;	
	transition-delay: 100ms;
	transition-timing-function: ease-in-out;
}

//

.doc-toDoItem[data-is-checked='false']
.doc-toDoItem-contents .doc-para span.doc-span--style--light span{
	// opacity: 1;
	color: var(--text200);

	text-decoration-color: rgba(255,0,0,0) !important;

	transition-property: color;
	transition-duration: 200ms;	
	transition-delay: 50ms;
	transition-timing-function: ease-out;
}



//
// TOOLTIP

.doc-toDoItem-checkboxTooltip{
	position: absolute;
	left: -54px;
	top: 2.5px;

	height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;


	border-radius: 4px; 
	padding-left: 4px;
	padding-right: 5px;

	

	user-select: none;
	pointer-events: none;

	background: rgb(140,140,148);
	border: 1px solid rgb(135,135,143);

	color: $white;

	font-size: 12px;
  font-weight: $lato-medium;
  letter-spacing: 0.022em;

  line-height: 1.2;

 	background: rgb(244,244,248);
	border: 1px solid darken(rgb(240,240,245), 1%);

	color: var(--text400);

	display: none; // temp for gutter
}

.doc-toDoItem-checkboxTooltip{
	opacity: 0;
	transform: translateX(5px);

	transition-property: opacity, transform;
	transition-duration: 100ms, 100ms;	
	transition-delay: 100ms, 100ms;
	transition-timing-function: ease-out;
}

.doc-toDoItem[data-is-checked='false'][data-indent-level="0"]
.doc-toDoItem-checkboxContainer:hover
.doc-toDoItem-checkboxTooltip{
	
	opacity: 0.95;
	transform: translateX(0px);


	transition-property: opacity, transform;
	transition-duration: 200ms, 200ms;	
	transition-delay: 800ms, 700ms;
	transition-timing-function: ease-out;

}

.doc-toDoItem[data-is-checked='false'][data-indent-level="0"]
.doc-toDoItem-checkboxContainer:hover
.doc-toDoItem-checkboxTooltip.doc-toDoItem-checkboxTooltip--forceHide{
	opacity: 0;
	transform: translateX(5px);	

	transition-property: opacity, transform;
	transition-duration: 50ms, 50ms;	
	transition-delay: 0ms, 0ms;
	transition-timing-function: ease-out;
}

.doc-toDoItem-checkboxTooltip-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1px;
	margin-left: -1px;
}

.doc-toDoItem-checkboxTooltip-iconContainer svg{
	width: 11.75px;
	height: 11.75px;
	fill: $white;
	fill: var(--text400);
	opacity: 0.8;

	position: relative;
}


@import 'Doc-ToDoItem-Assignment';





//
//
// width: $defaultWidth-toDoItem;
// margin-left: $defaultMargin-toDoItem;
//
//

.doc-toDoItem .doc-para{
	padding-left: 0 !important;
}

.doc-toDoItem[data-indent-level="0"]{
	width: calc($defaultWidth-toDoItem - (0 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (0 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="1"]{
	width: calc($defaultWidth-toDoItem - (1 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (1 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="2"]{
	width: calc($defaultWidth-toDoItem - (2 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (2 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="3"]{
	width: calc($defaultWidth-toDoItem - (3 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (3 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="4"]{
	width: calc($defaultWidth-toDoItem - (4 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (4 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="5"]{
	width: calc($defaultWidth-toDoItem - (5 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (5 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="6"]{
	width: calc($defaultWidth-toDoItem - (6 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (6 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="7"]{
	width: calc($defaultWidth-toDoItem - (7 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (7 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="8"]{
	width: calc($defaultWidth-toDoItem - (8 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (8 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="9"]{
	width: calc($defaultWidth-toDoItem - (0 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (0 * #{$doc-paragraph-indent}));
}

.doc-toDoItem[data-indent-level="10"]{
	width: calc($defaultWidth-toDoItem - (10 * #{$doc-paragraph-indent}));
	margin-left: calc($defaultMargin-toDoItem + (10 * #{$doc-paragraph-indent}));
}
