$leftPadding-faqItem: 16px;
$rightPadding-faqItem: 40px;

.doc-faq{

}

.doc-faq-header{
	padding-top: 64px;
	padding-bottom: 24px;

	display: flex;

	position: relative;
}

.doc-faq-header-divider{
	margin-top: 0px;
	position: absolute;
	top: 8px;
	left: 0px;
}

.doc-faq-header-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	letter-spacing: -0.01em;

	user-select: none;
	cursor: default;
}

button.doc-faq-header-actionsDropdownBtn{
	margin-left: auto;
	height: 32px;

	cursor: pointer;

	pointer-events: auto;

	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text350);

	// background: $lightGray-bg;

	cursor: pointer;
	
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 6px;

	margin-right: -5px;
}

button.doc-faq-header-actionsDropdownBtn svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.7;
}

button.doc-faq-header-actionsDropdownBtn:hover{
	background: rgb(248,245,245);
}

button.doc-faq-header-actionsDropdownBtn:hover svg{
	opacity: 1;
}


// HIDE NEW FAQ BUTTON BUTTON in FEEEDBACK
.docEditor--feedbackReply button.doc-faq-header-actionsDropdownBtn,
.docEditor--feedbackReview button.doc-faq-header-actionsDropdownBtn{
	display: none;
}

//

.doc-faq-itemContainer{
	// background: $tempr;
	position: relative;

	box-sizing: border-box;
	padding-right: $rightPadding-faqItem;

	margin-left: -18px;
	padding-left: 18px;

	padding-top: 10px;
	padding-bottom: 10px;

	margin-bottom: 0px;

	border-radius: 5px;

	// background: $tempr;
}

.doc-faq-itemContainer.doc-faq-itemContainer--minimized:hover{
	background: $lightGray-bg;
}


.doc-faq-item{
	width: 100%;
}

.doc-faq-item-expandArrowBtnContainer{
	position: absolute;
	top: 6px;
	bottom: 8px;
	right: 2px;
}

.doc-faq-item-expandArrowBtn{
	width: 28px;
	height: 100%;

	border-radius: 4px;

	// background: $tempr;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 8px;
}

.doc-faq-itemContainer--expanded
.doc-faq-item-expandArrowBtn:hover{
	background: $lightGray-bg;
}

.doc-faq-item-expandArrowBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.5;
}

.doc-faq-item-expandArrowBtn:hover svg{
	opacity: 0.8;
}

.doc-faq-itemContainer--expanded
.doc-faq-item-expandArrowBtn svg{
	transform: rotate(180deg);
}

.doc-faq-item-previewBtn{
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	background: $invisible;
	user-select: none;

	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

.doc-faq-itemContainer--expanded
.doc-faq-item-previewBtn{
	display: none;
}


.doc-faq-item-answer{
	min-height: 26px;
}

.doc-faq-itemContainer--minimized
.doc-faq-item-answer{
	height: 26px;

	color: var(--text100);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.doc-faq-item-question,
.doc-faq-item-answer{
	font-size: 16.75px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	color: var(--text600);
	line-height: 1.35;

	position: relative;
	margin-block-start: 0em;
	margin-block-end: 0em;

	font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	
	line-height: 1.45;
}

.doc-faq-item-question{
	font-size: 18px;
	font-weight: $lato-bold;
	margin-bottom: 2px;
	line-height: 1.25;
}


button.doc-faq-addFAQItemBtn{
	margin-top: 16px;
	height: 40px;
	width: calc(100% + 8px);
	margin-left: -4px;

	cursor: pointer;

	background: $lightGray-bg;

	border-radius: 6px;
}

button.doc-faq-addFAQItemBtn svg{
	width: 24px;
	height: 24px;
	color: var(--text500);
	opacity: 0.5;
}


button.doc-faq-addFAQItemBtn:hover{
	background: rgb(248,245,245);
}

button.doc-faq-addFAQItemBtn:hover svg{
	opacity: 0.8;
}

button.doc-faq-addFAQItemBtn:active{
	background: rgb(244,240,240);
}

button.doc-faq-addFAQItemBtn:active svg{
	opacity: 1;
}


// HIDE NEW FAQ BUTTON BUTTON in FEEEDBACK
.docEditor--feedbackReply button.doc-faq-addFAQItemBtn,
.docEditor--feedbackReview button.doc-faq-addFAQItemBtn{
	display: none;
}


	


// .docEditor-content-faqContainer{
// 	display: flex;
// }

// .docEditor-content-faq{
// 	width: $fullWidth-doc;	
// }

// .docEditor-content-faq-content{
// 	width: $textWidth-doc;
// 	margin: 0 auto;
// }

// h2.docEditor-content-faq-content-title{
// 	margin-top: 0px;
// 	margin-bottom: 20px;
// }


// //

// .docEditor-content-faq-content-faqItemContainer{
// 	margin-bottom: 2px;
// 	width: 100%;
// }

// .docEditor-content-faq-content-faqItemContainer--closed
// .docEditor-content-faq-content-faqItem--closed{
// 	display: block;
// }

// .docEditor-content-faq-content-faqItemContainer--closed
// .docEditor-content-faq-content-faqItem--open{
// 	display: none;
// }

// .docEditor-content-faq-content-faqItemContainer--open
// .docEditor-content-faq-content-faqItem--open{
// 	display: block;
// }

// .docEditor-content-faq-content-faqItemContainer--open
// .docEditor-content-faq-content-faqItem--closed{
// 	display: none;
// }

// $leftPadding-faqItem: 16px;
// $rightPadding-faqItem: 36px;

// button.docEditor-content-faq-content-faqItem{
// 	width: calc(100% + $leftPadding-faqItem + $rightPadding-faqItem);

// 	background: none;
// 	border: none;
// 	text-align: left;

// 	margin-left: calc(-1 * #{$leftPadding-faqItem});
// 	padding-left: $leftPadding-faqItem;
// 	padding-right: $rightPadding-faqItem;

// 	padding-top: 8px;
// 	padding-bottom: 8px;

// 	border-radius: 6px;

// 	// background: $tempr;
// }

// // button.docEditor-content-faq-content-faqItem--closed:hover{
// // 	background: rgb(250,247,247);
// // }

// button.docEditor-content-faq-content-faqItem
// .docEditor-content-faq-content-faqItem-label:hover{
// 	background: rgb(250,247,247);
// }

// button.docEditor-content-faq-content-faqItem
// .docEditor-content-faq-content-faqItem-label:active{
// 	background: darken(rgb(250,247,247), 2%);
// }


// .docEditor-content-faq-content-faqItem-label{
// 	font-size: 17.25px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.01em;
// 	color: var(--text600);

// 	// background: $tempr;

// 	box-sizing: border-box;
// 	width: calc(100% + $leftPadding-faqItem + $rightPadding-faqItem);

// 	margin-left: calc(-1 * #{$leftPadding-faqItem});
// 	padding-left: $leftPadding-faqItem;
// 	padding-right: calc($rightPadding-faqItem + 8px);

// 	padding-top: 8px;
// 	padding-bottom: 8px;	

// 	border-radius: 6px;

// 	margin-bottom: -4px;

// 	cursor: pointer;

// 	position: relative;

// 	// background: $tempb;

// 	box-sizing: border-box;
// }

// .docEditor-content-faq-content-faqItemContainer--open
// .docEditor-content-faq-content-faqItem-label{
// 	color: var(--text600);
// }

// .docEditor-content-faq-content-faqItem-label-iconContainer{
// 	position: absolute;
// 	right: 0px;
// 	top: 0px;
// 	bottom: 0px;

// 	width: $rightPadding-faqItem;

// 	// background: $tempr;

// 	display: flex;
// 	align-items: top;
// 	justify-content: center;

// 	box-sizing: border-box;
// 	padding-top: 9px;
// }

// .docEditor-content-faq-content-faqItem-label-iconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);
// }


// .docEditor-content-faq-content-faqItemContainer--closed
// .docEditor-content-faq-content-faqItem-label
// .docEditor-content-faq-content-faqItem-label-iconContainer svg{
// 	opacity: 0.2;	
// }

// .docEditor-content-faq-content-faqItemContainer--closed
// .docEditor-content-faq-content-faqItem-label:hover
// .docEditor-content-faq-content-faqItem-label-iconContainer svg{
// 	opacity: 0.6;	
// }


// .docEditor-content-faq-content-faqItemContainer--open
// .docEditor-content-faq-content-faqItem-label
// .docEditor-content-faq-content-faqItem-label-iconContainer svg{
// 	opacity: 0.6;	
// }

// .docEditor-content-faq-content-faqItemContainer--open
// .docEditor-content-faq-content-faqItem-label:hover
// .docEditor-content-faq-content-faqItem-label-iconContainer svg{
// 	opacity: 0.9;	
// }


// //


// .docEditor-content-faq-content-faqItem-previewContent,
// .docEditor-content-faq-content-faqItem-content p{
// 	width: 100%;

// 	font-size: 16.75px;	
// 	font-size: 17.25px;
// 	font-size: 16.5px;
// 	font-weight: $lato-regular;
// 	letter-spacing: 0.005em;
// 	color: black;
// 	line-height: 1.45;

// 	position: relative;
// 	margin-block-start: 0em;
// 	margin-block-end: 0em;

// 	min-height: 18px;

// 	margin-bottom: 15px;
// }

// .docEditor-content-faq-content-faqItem-previewContent{
// 	width: $textWidth-doc;
// 	margin-bottom: 0px;

// 	white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   opacity: 0.5;
// }

// .docEditor-content-faq-content-faqItem-content p:last-child{
// 	margin-bottom: 0px;
// }

// .docEditor-content-faq-content-faqItem-content{
// 	padding-bottom: 12px;
	
// }

// .docEditor-content-faq-content-faqItem-content p {
// 	user-select: text;
// 	cursor: pointer;
// }

