//

.templateModalContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.templateModalContainer:focus{
	outline: none;
}

.templateModalContainer--hidden,
.templateModalContainer--hidden *{
	pointer-events: none !important;
}


.templateModalModalUnderlay{
	background: var(--bg-zoomedMediaModals);

	position: fixed;
	top: -100vh;
	left: -100vw;
	right: -100vw;
	bottom: -100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 100;
}

.templateModalModalUnderlay--show{
	opacity: 0.75;
	transition: 100ms linear;
	transition-delay: 0ms;
}

.templateModalModalUnderlay--hide{
	opacity: 0;
	transition: 100ms linear;
	pointer-events: none !important;
}

//

.templateModal{		
	height: calc(100vh - 82px);
	height: calc(100vh - 116px);
	// height: calc(100vh - 132px);
	max-height: 1100px;

	max-width: 1900px;	

	box-sizing: border-box;	

	border-radius: 6px;

	position: relative;
	z-index: 5000; // above underlay

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;

	position: relative;

	background: rgb(243,242,246);
	background: rgb(243,242,248);

	box-sizing: border-box;
	//padding-right: 120px;
	//background: rgb(245,245,248);
	//padding-right: 26px;
}

.templateModal-cancelCreateModeBtn{
	background: rgb(243,242,248);
}

[data-theme="dark"]{
	.templateModal,
	.templateModal-cancelCreateModeBtn{
		background: rgb(20,20,20);
		background: rgb(32,30,36);
	}	
}





//

.templateModal{
	@include poly-fluid-sizing('width', (		
		1280px: 1240px,		
		1366px: 1320px,
		1440px: 1380px,
		1680px: 1580px,
		1800px: 1600px,
		1920px: 1700px,
		2560px: 1800px)
	);
}

$width-preview-templateModal: 820px;

//

.templateModal-listContainer{
	height: 100%;

	flex-grow: 0;
	flex-shrink: 0;
	
	box-sizing: border-box;

	// border-right: 1px solid var(--line300);
	// background: var(--253gray);
	// background: $tempb;
}

@import 'TemplateModal-List';

//



.templateModal-listContainer{
	flex-grow: 0;
	flex-shrink: 0;

	@include poly-fluid-sizing('width', (		
		1280px: 300px,		
		1366px: 300px,
		1440px: 300px,		
		1680px: 300px,
		1800px: 350px,
		1920px: 400px,
		2560px: 400px)
	);

	box-sizing: border-box;	

	position: relative;

	@include poly-fluid-sizing('padding-left', (		
		1280px: 4px,		
		1366px: 4px,
		1440px: 6px,
		1680px: 14px,
		1800px: 20px,
		1920px: 26px,
		2560px: 26px)
	);

}





.templateModal-previewContainer{
	height: calc(100% - 16px);	
	margin-top: 8px;
	margin-right: 8px;

	height: calc(100% - 22px);	// borders
	margin-top: 10px;
	margin-right: 10px;

	//

	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	
	cursor: default;
	user-select: none;
	
	position: relative;
	
	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
	border-radius: 6px;
}

	

[data-theme="dark"]{	
	.templateModal-previewContainer{
		border: 1px solid var(--line200);
		border-bottom: 1px solid var(--line200);
		border-top: 1px solid var(--line300);
		box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
	}	
}






$height-detailsPanel-templateModal: 196px;

.templateModal-previewGradient{
	position: absolute;
	bottom: $height-detailsPanel-templateModal;
	left: 1px;
	width: calc(100% - 2px);

	z-index: 10;
	pointer-events: none;
	
	display: none;
	//height: 240px;
	// background: $tempr;
	//background: linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 33%, rgba(255,255,255,0.8) 100%);
}

.templateModal-previewScrollContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: calc(100% - #{$height-detailsPanel-templateModal});

	overflow-y: scroll;
}

//

.templateModal-detailsContainer{
	height: 100%;

	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: $height-detailsPanel-templateModal;

	box-sizing: border-box;

	background: none;

	// background: $tempr;	

}


.templateModal-cancelCreateModeBtn{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: calc(100% - 18px);
	
	z-index: 50;

	//transition: opacity 50ms linear;
}

.templateModal-cancelCreateModeBtn--show{
	opacity: 0;
}

.templateModal-cancelCreateModeBtn--hide{
	opacity: 0;
	pointer-events: none;
}

//

.templateModal-dropdownBtnContainer{
	position: absolute;
	top: 8px;
	right: 10px;
	width: 36px;
	height: 32px;

	z-index: 20;
}

.templateModal-dropdownBtn{
	border-radius: 4px;
	width: 100%;
	height: 100%;
}

.templateModal-dropdownBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.8;
}

.templateModal-dropdownBtn:hover,
.templateModal-dropdownBtn[data-state="open"]{
	background: var(--250gray);
}

.templateModal-dropdownBtn:hover svg{
	opacity: 1;
}

.templateModal-dropdownBtn[data-state="open"]{
	// pointer-events: none;
}



//


.templateModal-preview{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	padding-top: 100px;
	padding-top: 80px;
	padding-bottom: 0px;
}

.templateModal-preview-templateTitle{
	width: $textWidth-doc;
	font-size: 42px;
	font-size: 48px;
	font-weight: $lato-black;	
	font-weight: $lato-heavy;
	letter-spacing: -0.005em;
	color: var(--text600);
	margin-bottom: 14px;

	//transform: scale(0.86);
	// transform: scale(0.88);
	transform: scale(0.95);
	transform-origin: top center;
}

[data-theme="dark"]{
	.templateModal-preview-templateTitle{
		font-weight: $lato-heavy; // seems more balanced
	}	
}


.templateModal-preview-htmlWrapper{
	width: $textWidth-doc;
	
	//transform: scale(0.88);
	transform: scale(0.95);
	transform-origin: top center;	
}

//

@import 'TemplateModal-Details';

//

.templateModal-closeBtn{
	position: absolute;
	top: 20px;
	right: 20px;
	display: none;
}


.templateModal-closeBtn{
	position: absolute;
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;

	border-radius: 5px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.templateModal-closeBtn:hover{
	background: var(--250gray);
}

.templateModal-closeBtn svg {
	width: 24px;
	height: 24px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0.3;
	transition: opacity 50ms linear;
}

.templateModal-closeBtn:hover svg{
	opacity: 0.8;
}
