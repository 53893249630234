.meetingNotesPage{
	flex-direction: column;
}

.meetingNotesPage-headerContainer,
.meetingNotesPage-tableContainer{
	width: 100%;
	display: flex;
}

@import 'LibraryPages-MeetingNotesPage-HeaderMenu';


.meetingNotesPage-rightPanel{
	pointer-events: none;
}

//
//

@import 'LibraryPages-MeetingNotesPage-GCalBanner';

//


@import 'LibraryPages-MeetingNotesPage-YourCalendar';

//
//

@import 'LibraryPages-MeetingNotesPage-MeetingTable';





// $upcomingEvents-columnGap: 74px;

// .meetingNotesPage-upcoming{
// 	display: flex;
// 	column-gap: $upcomingEvents-columnGap;

// 	padding-bottom: 24px;
// 	min-height: 200px;
// 	border-bottom: 1px solid var(--line300);
// }

// .meetingNotesPage-upcoming-pane{
// 	width: calc(50% - (#{$upcomingEvents-columnGap} * 0.5));
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	position: relative;
// }


// .meetingNotesPage-meetingList{
// 	padding-top: 18px;
// 	padding-top: 4px;
// 	padding-bottom: 4px;
	
// 	//border-bottom: 1px solid var(--line300);
// }

// .meetingNotesPage-meetingList-header{
// 	font-size: 13px;
// 	font-weight: $lato-bold;
// 	color: var(--text600);
// 	opacity: 0.5;

// 	text-transform: uppercase;
// 	margin-bottom: 4px;

// 	user-select: none;
// 	cursor: default;

// 	display: none;
// }

// button.meetingNotesPage-upcoming-pane-header-tab,
// .meetingNotesPage-pastMeetings-header{
// 	font-size: 21px;	
// 	font-weight: $lato-heavy;
// 	color: var(--text500);

// 	user-select: none;	
// 	cursor: default;
// }

// .meetingNotesPage-pastMeetings-header{
// 	cursor: default;
// }


// .meetingNotesPage-pastMeetings-header{
// 	padding-top: 18px;
// 	padding-top: 24px;
// 	margin-bottom: 14px;
// }

// .meetingNotesPage-upcoming-pane-header{
// 	display: flex;

// 	margin-left: -8px;
// 	user-select: none;
// 	cursor: default;

// 	display: flex;

// }

// button.meetingNotesPage-upcoming-pane-header-tab{
// 	padding-left: 9px;
// 	padding-right: 9px;

// 	padding-top: 5px;
// 	padding-bottom: 5px;

// 	border-radius: 4px;
// }

// button.meetingNotesPage-upcoming-pane-header-tab--inactive{
// 	opacity: 0.5;

// }

// button.meetingNotesPage-upcoming-pane-header-tab--inactive:hover{
// 	opacity: 0.8;
// 	background: var(--250gray);
// }

// //

// button.meetingNotesPage-meetingList-showGCalEvents{
// 	margin-top: 10px;
// 	height: 30px;

// 	min-width: 255px;

// 	justify-content: flex-start;

// 	margin-left: -8px;
// 	padding-left: 8px;
// 	padding-right: 8px;

// 	border-radius: 5px;

// 	font-size: 15.25px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);

// 	opacity: 0.8;

// 	margin-bottom: 4px;
// }

// button.meetingNotesPage-meetingList-showGCalEvents:hover{
// 	background: var(--253gray);
// 	opacity: 1;
// }

// //

// .meetingNotesPage-meetingList-empty{
// 	font-size: 15.75px;
// 	font-weight: $lato-regular;
// 	//letter-spacing: 0.007em;
// 	color: var(--text500);
// 	opacity: 0.5;
// 	user-select: none;
// 	margin-top: 6px;
// }



// //






