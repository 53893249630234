.recordingPlaybackModal-identifySpeakersPanelContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 200;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
}

.recordingPlaybackModal-identifySpeakersPanelContainer:focus{
	outline: none;
}

.recordingPlaybackModal-identifySpeakersPanelContainer--hidden,
.recordingPlaybackModal-identifySpeakersPanelContainer--hidden *{
	pointer-events: none !important;
}

.recordingPlaybackModal-identifySpeakersPanel-bg{
	position: absolute;
	top: -100vh;
	left: -100vw;
	right: -100vw;
	bottom: -100vh;

	z-index: 10;

	background: rgba(0,0,0,0.75);	
	// cursor: pointer;
}

.recordingPlaybackModal-identifySpeakersPanel{	
	// height: 100%;

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;


	pointer-events: auto;

	box-sizing: border-box;

	border-radius: 5px;

	position: relative;

	//background: lighten(rgb(20,18,22), 0%);
	//border-radius: 4px;

	background: $invisible;

	//border: 1px solid rgba(255,255,255,0.03);
	//border-top: 1px solid rgba(255,255,255,0.05);

	display: flex;
	flex-direction: column;

	z-index: 10;
}


.recordingPlaybackModal-identifySpeakersPanel-header{
	
	position: absolute;
	top: 40px;
	left: 0px;
	width: 100%;
	
	display: flex;
	align-items: center;
	justify-content: center;


}

.recordingPlaybackModal-identifySpeakersPanel-header-tag{
	padding-left: 7px;
	padding-right: 10px;

	height: 32px;
	
	
	border-radius: 4px;

	position: relative;
	z-index: 20;

	display: flex;
	align-items: center;
	justify-content: center;

	
	background: darken(rgb(20,18,22), 4%);
	
	border: 1px solid var(--line50);

	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);

	cursor: default;
	user-select: none;

}

.recordingPlaybackModal-identifySpeakersPanel-header-tag-iconContainer{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 1px;
}

.recordingPlaybackModal-identifySpeakersPanel-header-tag-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.75;
}

.recordingPlaybackModal-identifySpeakersPanel-header-tag-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text400);
}

//

.recordingPlaybackModal-identifySpeakersPanel-list{
	//width: 100%;
	width: 100%;
	height: 100%;
	// background: $tempg;

	pointer-events: none;

	display: flex;	
	
	align-items: center;

	column-gap: 24px;
	column-gap: 32px;

	box-sizing: border-box;
	padding-left: 32px;
	padding-right: 32px;
	padding-left: 64px;
	padding-right: 64px;

	padding-bottom: 12px;

	position: relative;
	z-index: 15;

	overflow-x: auto;
}

//
//

.recordingPlaybackModal-identifySpeakersPanel-speakerContainer{
	flex-grow: 0;
	flex-shrink: 0;
	
	pointer-events: none;
	width: 300px;
}

.recordingPlaybackModal-identifySpeakersPanel-speakerContainer:first-child{
	margin-left: auto; 
}

.recordingPlaybackModal-identifySpeakersPanel-speakerContainer:last-child{
	margin-right: auto; 
}

.recordingPlaybackModal-identifySpeakersPanel-speaker{
	
	min-height: 320px;

	
	//background: rgba(255,255,255,0.03);
	background: lighten(rgb(20,18,22), 0%);
	border-radius: 6px;

	display: flex;


	padding-top: 12px;
	
	pointer-events: auto;
	flex-direction: column;
	align-items: center;


	border: 1px solid rgba(255,255,255,0.04);
	border: 1px solid var(--line100);

	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);

	//transform: translateX(400px);

	// background: red;
	
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview{
	transition: all 100ms ease-in-out;
	transform: scale(0.98);

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker:hover
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview{
	transform: scale(1.00);
}

// .recordingPlaybackModal-identifySpeakersPanel-speaker:not(:first-child){
// 	transform: translateX(400px) scale(0.9);
// }

// .recordingPlaybackModal-identifySpeakersPanel-speaker:not(:first-child)
// .recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview{
// 	opacity: 0.25;
// }

// .recordingPlaybackModal-identifySpeakersPanel-speaker:not(:first-child)
// .recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn{
// 	opacity: 0.25;
// }


.recordingPlaybackModal-identifySpeakersPanel-speaker-media{
	width: 100%;
	padding-bottom: 18px;
}



.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview{
	width: 100%;
	aspect-ratio: 16/9;
	overflow: hidden;
	border-radius: 4px;

	position: relative;
	// background: $tempr;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview--audio{
	aspect-ratio: 16/7;
	background: rgba(255,255,255,0.04);
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag{
	position: absolute;

	z-index: 10;

	bottom: 12px;
	left: 50%;
	transform: translateX(-50%);

	height: 28px;
	padding-left: 6px;
	padding-right: 8px;

	background: rgb(0,0,0,0.75);
	backdrop-filter: saturate(120%) blur(4px);
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview--audio
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag{
	bottom: unset;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag{
	opacity: 1;
	transition: all 100ms linear;
	transition-delay: 100ms;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview:hover
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag{
	opacity: 0;
	transition: all 100ms linear;
}


.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-audioIcon{
	opacity: 0.5;
	transition: all 100ms linear;
	transition-delay: 100ms;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview:hover
.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-audioIcon{
	opacity: 0.8;
	transition: all 100ms linear;
}


.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag-iconContainer{
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	opacity: 0.8;
}


.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-listenTag-label{
	color: var(--text500);
	font-size: 14.5px;
	font-weight: $lato-regular;

	white-space: nowrap;
	letter-spacing: 0.008em;
}
//

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-audioIcon{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
	cursor: default;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-media-videoPreview-audioIcon svg{
	fill: white;
	width: 32px;
	height: 32px;
	opacity: 1;

}


//

.recordingPlaybackModal-identifySpeakersPanel-speaker-selection{
	max-height: calc(100vh - 500px);
	overflow-y: scroll;
	width: 100%;
	padding-bottom: 32px;
}




.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn{
	
	width: calc(100% - 36px);
	margin-left: 18px;
	height: 38px;
	margin-bottom: 3px;

	padding-left: 8px;
	box-sizing: border-box;

	justify-content: flex-start;

	border-radius: 4px;

	background: rgba(255,255,255,0.03);
	color: var(--text600);

	font-size: 15.5px;
	font-weight: $lato-regular;
	opacity: 0.95;
	letter-spacing: 0.008em;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn-avatarContainer{
	width: 24px;
	height: 24px;
	background: $tempr;

	margin-right: 8px;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn-avatar{
	border-radius: 3px;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn:hover{
	background: rgba(255,255,255,0.06);
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn[data-selected-state="true"]{
	background: var(--primary500);
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn[data-previouslySelected-state="true"]{
	opacity: 0.5;
}

.recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn--someoneElse{
	opacity: 0.5;
}

//
//

.recordingPlaybackModal-identifySpeakersPanel-doneBtnContainer{
	width: 100%;
	
	position: absolute;
	bottom: 30px;
	left: 0px;
	width: 100%;


	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 20;
}

.recordingPlaybackModal-identifySpeakersPanel-doneBtn{
	width: 200px;

	height: 42px;

	font-size: 17px;
	font-weight: $lato-bold;
	color: var(--text500);
	letter-spacing: 0.025;
	
	border-radius: 4px;

	
	background: darken(rgb(20,18,22), 4%);
	
	border: 1px solid var(--line100);

	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);


	background: var(--primary550);
}

.recordingPlaybackModal-identifySpeakersPanel-doneBtn:hover{
	border: 1px solid var(--line150);
	background: darken(rgb(20,18,22), 0%);
	//background: rgba(40,40,50,0.4);
	background: var(--primary600);
}