.linearMilestoneIcon{
	width: 14px;
	height: 14px;

	// transform: scale(2);


	// background: $tempg;
}

.linearMilestoneIcon svg{
	width: 14px;
	height: 14px;
}

.linearMilestoneIcon-icon #bg,
.linearMilestoneIcon-icon #progress{
	fill: none;
}

.linearMilestoneIcon-icon #bg{
	
	stroke-width: 4px;
}

.linearMilestoneIcon-icon #progress{
	
	stroke-width: 4px;
}

//
//
// INCOMPLETE

// ACTIVE

.linearMilestoneIcon--incomplete.linearMilestoneIcon--activeMilestone
.linearMilestoneIcon-icon #progress{
	stroke: var(--yellow--yarn);
}

.linearMilestoneIcon--incomplete.linearMilestoneIcon--activeMilestone
.linearMilestoneIcon-icon #bg{
	stroke: var(--yellow--yarn);
	opacity: 0.5;
}

// INACTIVE

.linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone
.linearMilestoneIcon-icon #progress{
	stroke: var(--text500);
	opacity: 0.8;
}

.linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone
.linearMilestoneIcon-icon #bg{
	stroke: var(--text500);
	opacity: 0.3;
}



//
// COMPLETED

.linearMilestoneIcon--completed
.linearMilestoneIcon-icon #bg{
	fill: var(--purple--yarn);
	stroke: var(--purple--yarn);
}

.linearMilestoneIcon--completed
.linearMilestoneIcon-icon #progress{
	display: none;
}


//
// UNASSIGNED
.linearMilestoneIcon--unassignedToMilestone.linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone
.linearMilestoneIcon-icon #bg{
	stroke: var(--text400);
	 stroke-dasharray: 5; /* dashes and gaps are both 5 units long */

}

//
// LARGE ICON
// used in milestone docs


.linearMilestoneIcon.linearMilestoneIcon--largeIcon{
	width: 34px;
	height: 34px;	
}

.linearMilestoneIcon--largeIcon svg{
	width: 34px;
	height: 34px;
}

.linearMilestoneIcon--largeIcon .linearMilestoneIcon-icon #bg{
	stroke-width: 3px;
}

.linearMilestoneIcon--largeIcon .linearMilestoneIcon-icon #progress{
	stroke-width: 3px;
}


.linearMilestoneIcon.linearMilestoneIcon--largeIcon.linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone
.linearMilestoneIcon-icon #progress{
	stroke: var(--text200);
	opacity: 0.6;
}

.linearMilestoneIcon.linearMilestoneIcon--largeIcon.linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone
.linearMilestoneIcon-icon #bg{
	stroke: var(--text200);
	opacity: 0.3;
}
