.docEditor-messagePanelContainer{
	// position: fixed;	
	// top: $windowMargin-sideMenuMode;
	// bottom: $windowMargin-sideMenuMode;

	// right: calc(#{$width-teamPanel--narrow} + #{$windowMargin-sideMenuMode});
	//right: calc(#{$windowMargin-sideMenuMode});

	// z-index: 10000;

	//border-top-right-radius: 7px;
	//border-bottom-right-radius: 7px;

	background: var(--bg-docEditor);
	border-left: 1px solid var(--sideBorder-messagePanel);
	//border-right: 1px solid var(--sideBorder-messagePanel);

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;

	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}

.messagePanelContainer--spacer{
	background: none;
}

.docEditor-messagePanelContainer{
	z-index: 5;
}

.docEditor-messagePanelContainer--elevateZIndex{
	z-index: 10;
}

.docEditorContainer--sideMenuState--hidden
.docEditor-messagePanelContainer{
	//display: none;
	// when hidden interface, user panel comes in from hover
	//right: $windowMargin-sideMenuMode;
}

.docEditor-hiddenMessagePanelHoverArea{
	position: fixed;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 50px;

	z-index: 9999;
	// background: $tempr;
}

.docEditor-hoverMessagePanelOuterContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	//width: set in core layout

	z-index: 14000;

	// background: $tempg;
}

.docEditor-hoverMessagePanelOuterContainer--hide{
	pointer-events: none;
}

.docEditor-hoverMessagePanelInnerContainer{
	position: absolute;
	right: 4px;
	top: 4px;
	left: 24px; // don't want to fall off immediately
	bottom: 4px;
	
	background: var(--bg-docEditor);
	border: 1px solid var(--floatingSideMenuBorder);
	border-bottom: 1px solid var(--floatingSideMenuBottomBorder);
	border-radius: 8px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12), 0px 4px 8px 0px rgba(0,0,0,0.06);
}



//
//

.docEditor-messagePanel{	
	height: 100%;
	display: flex;
	flex-direction: column;
}

@import 'Messages-Panel-Header';
@import 'Messages-Panel-Divider';
@import 'Messages-Panel-PreloadMessage';
@import 'Messages-Panel-FooterFollowBox';
//

.docEditor-messagePanel-scrollContents{
	height: 100px;
	width: 100%;

	flex-grow: 1;
	flex-shrink: 1;	

	overflow-y: scroll;
	display: flex;
	flex-direction: column-reverse;

	padding-bottom: 8px;
}

.docEditor-messagePanel-scrollContents-vSpacer{
	width: 100%;
	min-height: 1px;
	flex-grow: 1;
	flex-shrink: 1;
}

//

.docEditor-messagePanel-footer{	

	width: 100%;

	flex-grow: 0;
	flex-shrink: 0;
	min-height: 216px;

	position: relative;
}
