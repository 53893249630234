
.doc-table-editRowColBtn{
	position: absolute;	
	cursor: pointer;

	z-index: 20;

	// background: $tempr;
}

.doc-table-editRowColBtn--col{	
	height: 15px;
	bottom: 100%;
	margin-bottom: -6px;

	left: 0px;
	right: 0px;
	width: 100%;
}

.doc-table-editRowColBtn--row{	
	right: 100%;
	width: 24px;

	margin-right: -5px;

	top: 0px;
	bottom: 0px;
	height: 100%;

	z-index: 5; // over top of row (I guess, because col btn is bigger)	
}


.doc-table-editRowColBtn-dropdownBtn{	
	border-radius: 2px;

	background: var(--primary500);
}

.doc-table-editRowColBtn--col
.doc-table-editRowColBtn-dropdownBtn{	
	position: absolute;

	left: 2px;
	width: calc(100% - 4px);

	bottom: 3px;
	height: 7px;
}

.doc-table-editRowColBtn--row
.doc-table-editRowColBtn-dropdownBtn{
	position: absolute;

	top: 2px;
	height: calc(100% - 5px);

	right: 2px;
	width: 7px;
}





// HIDE AND SHOW
.doc-table-editRowColBtn .doc-table-editRowColBtn-dropdownBtn{
	opacity: 0;	
}

.doc-table-editRowColBtn:hover
.doc-table-editRowColBtn-dropdownBtn,
.doc-table-editRowColBtn[data-state="open"]
.doc-table-editRowColBtn-dropdownBtn,
.doc-table-cellUIContainer span[data-state="open"]	
.doc-table-editRowColBtn-dropdownBtn{
	opacity: 1; 
	transition: 0ms ease-in-out;
}

.doc-table-editRowColBtn[data-state="open"]{
	pointer-events: none;
}



