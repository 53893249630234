//
// PARTICIPANT HEADER

.doc-participantHeader{
	display: flex;
	align-items: center;
	// cursor: pointer;
	//cursor: default;
	pointer-events: none;

	width: calc(#{$textWidth-doc} + 24px);
	margin-left: -12px;
	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;

	// background: $tempr;
}

.doc-participantHeader,
.doc-participantHeader * {
	user-select: none !important;
}

.doc-participantHeader-avatarOuterContainer{
	height: 10px;
	width: 10px;

	// background: $tempr;
	position: relative;

	margin-right: 32px;
}

.doc-participantHeader-avatarContainer{
	width: 28px;
	height: 28px;

	position: absolute;
	left: 0px;
	top: 50%;
	transform: translateY(-50%);


	
}

.doc-participantHeader-avatar{
	border-radius: 5px;
	margin-top: -1px;
}

.doc-participantHeader-rightContainer{
	// margin-left: auto;
	margin-left: 10px;
	display: flex;

	align-items: center;
	// background: $tempr;
	height: 100%;
}

.doc-participantHeader-priorityTag{
	display: flex;
	align-items: center;	
}

.doc-participantHeader-priorityTag-iconContainer{
	width: 20px;
	height: 20px;
	margin-right: 0px;	
}

.doc-participantHeader-priorityTag-iconContainer
.linearIssuePriorityIconContainer{
	transform-origin: 50% 50%;
	
	//transform: scale(1.06);
}

.doc-participantHeader-priorityTag-iconContainer
.linearIssuePriorityIconContainer svg{
	width: 14px !important;
	height: 14px !important;
}

	
.doc-participantHeader-priorityTag-label{
	// font-size: 15.75px;		
	// font-weight: $lato-medium;
	// letter-spacing: 0.005em;	
	// color: var(--text500);
	// opacity: 0.5;

	margin-left: 1px;
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 0.6;
}

.doc-participantHeader-priorityTagBtn{
	// background: $tempr;

	height: 30px;
	border-radius: 4px;

	padding-left: 6px;
	padding-right: 6px;

	display: flex;
	align-items: center;
	column-gap: 9px;
	margin-right: -10px;

	position: relative;
	top: 5px; // dirty

	opacity: 0.7;
	pointer-events: auto;
}

.doc-participantHeader-priorityTagBtn:hover{
	background: var(--252gray);
	opacity: 1;
}