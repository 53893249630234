
//
// POPOVER

.docEditor-messagePanel-header-follower-popoverContainer{
	position: absolute;
	top: 30px;
	// width: 300px;
	right: 0px;
	padding-top: 6px;
}

.docEditor-messagePanel-header-follower:nth-last-child(1)
.docEditor-messagePanel-header-follower-popoverContainer{		
	transform: translateX(38px);
}

.docEditor-messagePanel-header-follower:nth-last-child(2)
.docEditor-messagePanel-header-follower-popoverContainer{		
	transform: translateX(58px);	
}

.docEditor-messagePanel-header-follower:nth-last-child(3)
.docEditor-messagePanel-header-follower-popoverContainer{	
	transform: translateX(78px);		
}

.docEditor-messagePanel-header-follower:nth-last-child(4)
.docEditor-messagePanel-header-follower-popoverContainer{	
	transform: translateX(98px);
}

.docEditor-messagePanel-header-follower:nth-last-child(5)
.docEditor-messagePanel-header-follower-popoverContainer{
	transform: translateX(118px);
	// even though centered might be better, that leads to strange situations when hover
}

.docEditor-messagePanel-header-follower:nth-last-child(6)
.docEditor-messagePanel-header-follower-popoverContainer{	
	transform: translateX(138px);
}

.docEditor-messagePanel-header-follower:nth-last-child(7)
.docEditor-messagePanel-header-follower-popoverContainer{	
	transform: translateX(158px);	
}

.docEditor-messagePanel-header-follower:nth-last-child(8)
.docEditor-messagePanel-header-follower-popoverContainer{	
	transform: translateX(178px);	
}


.docEditor-messagePanel-header{
	z-index: 1000;
}

// .docEditor-messagePanel-header-follower-popoverContainer{
// 	z-index: 10000;
// }

.docEditor-messagePanel-header-followerList
.docEditor-messagePanel-header-follower
.docEditor-messagePanel-header-follower-popoverContainer{
	opacity: 0;		
}


.docEditor-messagePanel-header-followerList:hover
.docEditor-messagePanel-header-follower
.docEditor-messagePanel-header-follower-popoverContainer{
	opacity: 1;		
	transition: opacity 0ms linear;
	//transition-delay: 500ms;
}


.docEditor-messagePanel-header-follower
.docEditor-messagePanel-header-follower-popoverContainer{	
	visibility: hidden;	
	pointer-events: none;
}

.docEditor-messagePanel-header-follower:hover
.docEditor-messagePanel-header-follower-popoverContainer{	
	visibility: visible;
	pointer-events: auto;
}



// .docEditor-messagePanel-header-follower:nth-last-child(1)
// .docEditor-messagePanel-header-follower-popoverContainer{	
// 	opacity: 1 !important;	
// }

// .docEditor-messagePanel-header-follower:nth-last-child(1)
// .docEditor-messagePanel-header-follower-popoverContainer{	
// 	visibility: visible;
// 	pointer-events: auto;
// }







//


.docEditor-messagePanel-header-follower-menu{
	width: 305px;

	box-sizing: border-box;
	padding: 8px;

	background: var(--bg-docEditor);
	border: 1px solid var(--line500);
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);

	padding: 14px;
	padding-top: 14px;
	padding-bottom: 8px;
	padding-bottom: 14px;
}

.docEditor-messagePanel-header-follower-menu-header{
	display: flex;
	width: 100%;
}

.docEditor-messagePanel-header-follower-menu-header-avatar{
	width: 34px;
	height: 34px;

	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 10px;
}

.docEditor-messagePanel-header-follower-menu-header-avatar-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 5px;
}

.docEditor-messagePanel-header-follower-menu-header-title{
	flex-grow: 1;
	flex-shrink: 1;

	user-select: none;
	cursor: default;
}

.docEditor-messagePanel-header-follower-menu--channel
.docEditor-messagePanel-header-follower-menu-header-title{
	padding-left: 6px;
}

.docEditor-messagePanel-header-follower-menu-header-title-primary{
	display: flex;

	margin-top: -1px;
	margin-bottom: 3px;
}

.docEditor-messagePanel-header-follower-menu-header-title-primary-iconContainer{
	margin-right: 2px;
}

.docEditor-messagePanel-header-follower-menu-header-title-primary-iconContainer svg{
	width: 13px;
	height: 13px;

	position: relative;
	top: 1px;

	color: var(--text600);
	opacity: 0.8;
}


.docEditor-messagePanel-header-follower-menu-header-title-primary-label{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text600);
}

.docEditor-messagePanel-header-follower-menu-header-title-primary-label-you{
	font-weight: $lato-semibold;
	opacity: 0.5;
}



.docEditor-messagePanel-header-follower-menu-header-title-subtitle{
	font-size: 13px;
	font-weight: $lato-medium;
	color: var(--text200);
}

//
// STATUS

.docEditor-messagePanel-header-follower-menu-header-statusTagContainer{
	//background: $tempr;
	flex-grow: 0;
	flex-shrink: 0;
}

.docEditor-messagePanel-header-follower-menu-header-statusTag{
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 4px; 

	position: relative;
	top: -2px;
	right: -1px;

	font-size: 12.75px;
	letter-spacing: 0.008em;
	font-weight: $lato-semibold;

	border: 1px solid $invisible;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}

.docEditor-messagePanel-header-follower-menu-header-statusTag--state--active{
	background: var(--accentPurple500);
	color: $white;
}

.docEditor-messagePanel-header-follower-menu-header-statusTag--state--online{
	
	color: var(--text600);
	//border: 1px solid var(--line300);
	background: rgba(246,246,249, 1);
}
[data-theme="dark"] {
	.docEditor-messagePanel-header-follower-menu-header-statusTag--state--online{
		background: rgba(104,255,211,0.1);
	}
}


.docEditor-messagePanel-header-follower-menu-header-statusTag--state--online{

}

.docEditor-messagePanel-header-follower-menu-header-statusTag--state--away{
	//background: rgba(251,251,251);
	color: var(--text300);
	opacity: 0.8;
}

.docEditor-messagePanel-header-follower-menu-header-statusTag-dot{
	width: 6px;
	height: 6px;
	border-radius: 50%;

	margin-right: 4px;
	margin-left: -2px;

	border: 1px solid $invisible;
}

.docEditor-messagePanel-header-follower-menu-header-statusTag--state--online
.docEditor-messagePanel-header-follower-menu-header-statusTag-dot{
	background: var(--activeUserGreen)
}

[data-theme="dark"] {
	.docEditor-messagePanel-header-follower-menu-header-statusTag--state--online
	.docEditor-messagePanel-header-follower-menu-header-statusTag-dot{
		background: var(--activeUserGreen);
	}
}




.docEditor-messagePanel-header-follower-menu-header-statusTag--state--away
.docEditor-messagePanel-header-follower-menu-header-statusTag-dot{
	background: var(--bg-docEditor);
	border: 1px solid var(--line600);
}

//
//

.docEditor-messagePanel-header-follower-menu-slackMessage{
	margin-top: 8px;
	margin-bottom: 8px;

	height: 36px;

	width: 100%;
	background: rgb(246,246,248);
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text300);

	cursor: text;
}

.docEditor-messagePanel-header-follower-menu-slackMessage:hover{
	background: rgb(240,240,244);
}

// .docEditor-messagePanel-header-follower-menu-contributions{
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	font-size: 12.5px;
// 	font-weight: $lato-regular;
// 	color: var(--text200);

// 	margin-top: 7px;
// 	margin-bottom: 2px;
// }

// .docEditor-messagePanel-header-follower-menu-contributions:hover{
// 	text-decoration: underline;
// 	color: var(--text400);
// }




//
//
//

.docEditor-messagePanel-header-follower-menu-actionBtnList{
	width: calc(100% + 10px);
	margin-left: -5px;
	margin-top: 10px;
	// margin-bottom: 11px;

	//background: $tempr;
}

.docEditor-messagePanel-header-follower-menu-actionBtnList-divider{
	width: calc(100% - 12px);
	margin-left: 8px;
	height: 1px;
	margin-top: 4px;
	margin-bottom: 4px;

	background: var(--text400);
	opacity: 0.1;
}


.docEditor-messagePanel-header-follower-menu-actionBtn{
	width: 100%;

	justify-content: flex-start;

	background: var(--bg-dropdown);
	height: 28px;
	box-sizing: border-box;
	padding: 0px;
	//padding-left: 20px;
	//font-size: 15px;
	padding-left: 8px;
	padding-left: 6px;
	font-size: 14.5px;
	margin-bottom: 0px;

	font-weight: $lato-semibold;
	color: var(--text400);
	cursor: default;	

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	position: relative;

	cursor: pointer;

	border-radius: 5px;

	// background: $tempb;

}



.docEditor-messagePanel-header-follower-menu-actionBtn:hover{	
	background: var(--hoverBG-item-dropdown);
	color: var(--text600);
}

.docEditor-messagePanel-header-follower-menu-actionBtn--disabled{
	opacity: 0.5;
	pointer-events: none;
}

.docEditor-messagePanel-header-follower-menu-actionBtn--remove{
	opacity: 0.7;
	height: 32px;
	padding-left: 10px;
}

.docEditor-messagePanel-header-follower-menu-actionBtn--remove:hover{
	background: transparentize($red, 0.9);
	color: darken($red, 30%);
	opacity: 1;
}


.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer{
	width: 24px;
	height: 24px;
	
	border-radius: 4px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

}

.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.75;
}

.docEditor-messagePanel-header-follower-menu-actionBtn:hover
.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg{
	opacity: 1;
}

.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg.icon--name--headphones,
.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg.icon--name--hash,
.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg.icon--name--forwardArrow{
	height: 14px;
	width: 14px;
}

.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg.icon--name--slackIcon{
	width: 14px;
	height: 14px;
	fill: var(--text300);
}

.docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer svg.icon--name--forwardArrow{
	height: 13px;
	width: 13px;
}
