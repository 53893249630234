//
// RICH TEXT COLORS
//



:root{	
	

	--docContentBoxBackground: rgb(245,245,247);


	// CODE (stolen from slack)
	--docCodeTextColor: 	rgb(224,30,90);
	--docCodeBorder: 			1px solid rgba(29,28,29,0.13);
	--docCodeBackground: 	rgba(29,28,29,0.04);

	// Internal Link
	--internalLinkHoverBG: rgb(248,245,252);
	--internalLinkActiveBG: rgb(242,238,245);

	// Placeholders
	--docHeaderPlaceholderColor: rgba(50,50,65,0.2);
	--docTitlePlaceholderColor: rgba(50,50,65,0.2);

	// To Do Item
	--toDoItem-checkIconColor: rgb(255,255,255);
	--toDoItem-placeholderColor: rgba(50,50,65,0.5);
	--toDoItem-mouseDownBG: rgb(244,244,248);
}

// DARK THEME

.forceDarkTheme, [data-theme="dark"] {

	--docContentBoxBackground: rgba(255,255,255,0.09);

	// CODE
	--docCodeTextColor:		rgb(232,145,45);
	--docCodeBorder:			1px solid rgba(232,232,232,0.13);
	--docCodeBackground:	rgba(232,232,232,0.04);

	// Internal Link
	--internalLinkHoverBG: rgba(255,255,255,0.12);
	--internalLinkActiveBG: rgba(255,255,255,0.16);

	// Placeholders
	--docHeaderPlaceholderColor: rgba(252,252,255,0.2);
	--docTitlePlaceholderColor: rgba(252,252,255,0.2);

	// To Do Item
	--toDoItem-checkIconColor: rgb(0,0,0);
	--toDoItem-placeholderColor: rgba(252,252,255,0.32);
	--toDoItem-mouseDownBG: rgb(250,250,255,0.05);


}