.docEditor-meetingContent-activeProjects-calendar{
	// background: $tempr;

	width: $fullWidth-doc;
	margin-left: $negativeEdgeMarginTextToDoc;

	// width: 960px;
	// margin-left: calc(((960px - 650px)/2) * -1);

	width: 1100px;
	margin-left: calc(((1100px - 650px)/2) * -1);

	width: 1000px;
	margin-left: calc(((1000px - 650px)/2) * -1);

	display: flex;
	flex-direction: column;

	margin-top: 12px;
	margin-bottom: 14px;
}

.docEditor-meetingContent-activeProjects-calendar--isDragging,
.docEditor-meetingContent-activeProjects-calendar--isDragging *{
	cursor: grabbing !important;
}



.docEditor-meetingContent-activeProjects-calendar-startMonth{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: var(--text500);

	padding-left: 7%;
	margin-bottom: 10px;
}

.docEditor-meetingContent-activeProjects-calendar-dayOfWeekRow{
	width: 100%;
	display: flex;
	margin-bottom: 7px;
}

.docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell{
	width: calc(100% / 7);
	flex-grow: 0;
	flex-shrink: 0;

	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 15.25px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.65;
}

.docEditor-meetingContent-activeProjects-calendar-weekRow{
	width: 100%;
	display: flex;
	margin-top: -1px;
}

.docEditor-meetingContent-activeProjects-calendar-dayCellContainer{
	width: calc(100% / 7);
	flex-grow: 0;
	flex-shrink: 0;

	margin-right: -1px;


	height: 96px;

	position: relative;

}


.docEditor-meetingContent-activeProjects-calendar-dayCell{
	width: 100%;
	height: 100%;
	border: 1px solid var(--line300);
	
	box-sizing: border-box;
	padding: 4px;

	display: flex;
	flex-direction: column;

	position: relative;

	justify-content: flex-end;
}

[data-theme="dark"]{
	.docEditor-meetingContent-activeProjects-calendar-dayCell{
		border: 1px solid var(--line200);
	}

}

.docEditor-meetingContent-activeProjects-calendar-dayCell--isWeekendDay{
	background: var(--253gray);
}

.docEditor-meetingContent-activeProjects-calendar-dayCell--isOver{
	border: 1px solid var(--line500);	
	z-index: 5;
}

.docEditor-meetingContent-activeProjects-calendar-dayCell-label{
	position: absolute;
	top: 5px;
	right: 5px;

	min-width: 24px;

	font-size: 14.75px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.7;

	padding-right: 1px;
	padding-left: 1px;
	padding-top: 4px;
	padding-bottom: 4px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	user-select: none;
	cursor: default;
}

.docEditor-meetingContent-activeProjects-calendar-dayCell--isMonthStart
.docEditor-meetingContent-activeProjects-calendar-dayCell-label{
	opacity: 1;
	font-weight: $lato-medium;
}

.docEditor-meetingContent-activeProjects-calendar-dayCell--isWeekendDay
.docEditor-meetingContent-activeProjects-calendar-dayCell-label{
	opacity: 0.45;
}

.docEditor-meetingContent-activeProjects-calendar-dayCell--isToday
.docEditor-meetingContent-activeProjects-calendar-dayCell-label{
	justify-content: center;

	opacity: 1;

	border-radius: 4px;
  background: var(--primary500);
  color: white;
  font-weight: $lato-semibold;
}

//

.docEditor-meetingContent-activeProjects-calendar-project{
	height: 28px;
	border: 1px solid $invisible;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;

	padding-left: 5px;
	padding-right: 5px;

	border-radius: 3px;

	cursor: pointer;

	position: relative;
	z-index: 10;

	user-select: none;
	//background: var(--254gray);
}

.docEditor-meetingContent-activeProjects-calendar-project:hover{
	// background: var(--bg-docEditor);
	// border: 1px solid var(--line400);
	background: var(--253gray);
	//cursor: grab;
}

.docEditor-meetingContent-activeProjects-calendar--isDragging
.docEditor-meetingContent-activeProjects-calendar-project:hover{
	cursor: grabbing !important;
}


// .docEditor-meetingContent-activeProjects-calendar-project--isDragging{
// 	background: var(--bg-docEditor);
// 	border: 1px solid var(--line400);
// 	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);
// }


.docEditor-meetingContent-activeProjects-calendar-project-markerContainer{
	width: 17px;
	height: 17px;
	border-radius: 3px;
	margin-left: -1px;

	margin-right: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 15px;

	// background: var(--primary400);
}

.docEditor-meetingContent-activeProjects-calendar-project-label{
	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text500);
}