//
// YARN LIGHT - MAC LIGHT
// translucent


:root[data-theme="light"]{
	//--appContentsBorder-sideMenuMode: rgba(220,220,222);
	--appContentsBorder-sideMenuMode: rgb(214,214,216);

	--titleTextColorSideMenuStatus: 	rgb(20,20,20);

	--dividerBGTopTabList: 					rgba(2,2,5,0.08);
	
	--bgSideMenuTabLight:						rgba(2,2,5,0.03);
	--bgSideMenuTabHover:						rgba(2,2,5,0.04);
	--bgSideMenuTabActive:					rgba(2,2,5,0.09);
	--bgSideMenuTabActiveHover:			rgba(2,2,5,0.12);

	--textColorSideMenuTab: 				rgb(35,	32,	40); // text 500
	--textColorSideMenuTabHover: 		rgb(32,	30,	35);
	--textColorSideMenuTabActive: 	rgb(15,	15,	25);
	
	--iconColorSideMenuTab:				 	rgb(120,120,140);
	--iconColorSideMenuTabActive: 	rgb(80,	80,	110);

	--iconColorSideMenuChannelTab: 	rgb(130,130,142);

	// --fontSizeSideMenuTab:				14.25px;
	--fontSizeSideMenuTab:					15px;
	--heightSideTab:								28px;

	--fontSizeSideMenuTab:					15px;
	--textWeightSideMenuTab: 				500; // lato-medium
	--textWeightSideMenuTabActive: 	500; // lato-semibold
	//--textWeightSideMenuTabActive: 	600; // lato-semibold
	//--textWeightSideMenuTabActive: 	700; // lato-bold

	--textSpacingSideMenuTab: 			0.0016em;	
	//--textSpacingSideMenuTabActive: 0.0030em;	
	//--textSpacingSideMenuTabActive: 0.0055em;	
	--textSpacingSideMenuTabActive:	0.0016em;	

	--textWeightSideMenuAppTab: 				600; // lato-medium
	//--textWeightSideMenuAppTabActive: 	700; // lato-bold
	--textWeightSideMenuAppTabActive: 	600; // lato-bold

	--textSpacingSideMenuAppTab: 0.005em;	
	--textSpacingSideMenuAppTabActive: 0.005em;	
	//--textSpacingSideMenuAppTabActive: 0.0034em;	

	--textColorNotificationSideMenuTab: rgb(255,255,255);
	//--bgNotificationSideMenuTab:		rgb(0,0,0,0.5);
	--bgNotificationSideMenuTab:		rgb(224,29,90); // red

	--sideMenuFooterToggleBG: rgb(255,255,255);
	--sideMenuFooterNotesBoxShadow: 0px 4px 8px 0px rgba(0,0,0,0.14), 0px 8px 16px 0px rgba(0,0,0,0.08);	

	--floatingSideMenuBG: rgb(253,251,253);
	--floatingSideMenuBorder: rgb(214,214,216);
	--floatingSideMenuBottomBorder: rgb(208,208,212);
}

:root[data-theme="dark"], .forceDarkTheme{

	// Check against electron when don't have multiple browser views layered
	// I think dark mode in electron is likely to mean system dark mode, so electron system dark-theme testing is important

	--appContentsBorder-sideMenuMode: rgb(54,50,58);

	--dividerBGTopTabList: 					rgba(255,255,255,0.08);

	--bgSideMenuTabLight:						rgba(255,255,255,0.03);
	--bgSideMenuTabHover:						rgba(255,255,255,0.07);
	--bgSideMenuTabActive:					rgba(255,255,255,0.10);
	--bgSideMenuTabActiveHover:			rgba(255,255,255,0.14);	

	--textColorSideMenuTab: 				rgb(210,208,220);
	--textColorSideMenuTabHover: 		rgb(232,230,240);
	--textColorSideMenuTabActive: 	rgb(248,244,255);
	
	--iconColorSideMenuTab:				 	rgb(160,160,160);
	--iconColorSideMenuTabActive: 	rgb(200,200,200);

	--iconColorSideMenuChannelTab: 	rgb(130,130,130);

	//--fontSizeSideMenuTab:				14.25px;
	--fontSizeSideMenuTab:					15px;
	--heightSideTab:								28px;

	--textWeightSideMenuTab: 				400; // lato-regular
	//--textWeightSideMenuTabActive: 	600; // lato-semibold
	--textWeightSideMenuTabActive: 	400; // lato-semibold

	--textSpacingSideMenuTab: 			0.01em;	
	//--textSpacingSideMenuTabActive: 0.0075em;	
	--textSpacingSideMenuTabActive: 0.01em;	

	--textWeightSideMenuAppTab: 				500; // lato-medium
	--textWeightSideMenuAppTabActive: 			500; // lato-medium
	// --textWeightSideMenuAppTabActive: 	600; // lato-bold

	--textSpacingSideMenuAppTab: 				0.007em;	
	//--textSpacingSideMenuAppTabActive:  0.0042em;	
	--textSpacingSideMenuAppTabActive:  0.007em;	

	//--textColorNotificationSideMenuTab: rgba(225,255,255, 0.75);
	--textColorNotificationSideMenuTab: rgba(225,255,255,1);
	//--bgNotificationSideMenuTab:		rgb(255,255,255,0.1);
	--bgNotificationSideMenuTab:		rgb(221,70,117); // red

	--sideMenuFooterToggleBG: rgba(255,255,255,0.2);

	--floatingSideMenuBG: rgb(32,30,36);	
	--floatingSideMenuBorder: rgb(70,70,80);
	--floatingSideMenuBottomBorder: rgb(70,70,80);
}
	

//
// MAC LIGHT - YARN LIGHT 
// opaque
@media (prefers-color-scheme: light){
:root[data-theme="light"]{	
	--appBG-sideMenuMode: rgba(255,252,255,0.6); // too see through with invisible
	--appBG-sideMenuHidden: rgba(255,255,255,0); // if full screen, make see through	
	}
}

//
// MAC DARK - YARN LIGHT 
// opaque
@media (prefers-color-scheme: dark){
:root[data-theme="light"] {
	--appBG-sideMenuMode: rgb(243,242,246);
	// --appBG-sideMenuMode: rgb(246,242,242);
	--appBG-sideMenuHidden: rgb(244,242,244);
	}
}

// WEB VERSION
:root[data-theme="light"]
.app--web{
	--appBG-sideMenuMode: rgb(243,242,246);
	// --appBG-sideMenuMode: rgb(252,244,244);
	--appBG-sideMenuHidden: rgb(244,242,244);
}


//
// YARN DARK - SYSTEM DARK
// opaque

@media (prefers-color-scheme: dark){
:root[data-theme="dark"] {
	//--appBG-sideMenuMode: rgba(23,22,24,0.2); // electron	
	//--appBG-sideMenuMode: rgba(23,22,24,0.0); // electron	
	//--appBG-sideMenuMode: rgba(15,12,34,0.2); // electron	
	//--appBG-sideMenuMode: rgba(15,12,22,0.6); // electron	
	--appBG-sideMenuMode: rgba(35,30,44,0.8); // electron	
	//--appBG-sideMenuMode: rgb(35,30,54);
	--appBG-sideMenuHidden: rgb(0,0,0,0);
	}
}


//
// YARN DARK - SYSTEM LIGHT
// opaque
@media (prefers-color-scheme: light){
:root[data-theme="dark"] {
	//--appContentsBorder-sideMenuMode: rgb(68,68,74);
	--appContentsBorder-sideMenuMode: rgb(58,58,64);
	//--appBG-sideMenuMode: rgb(35,30,54);
	//--appBG-sideMenuMode: rgb(45,40,54);
	//--appBG-sideMenuMode: rgb(32,30,36);
	// --appBG-sideMenuMode: rgb(22,20,26);
		--appBG-sideMenuMode: rgb(32,30,36);
		--appBG-sideMenuHidden: rgb(32,30,36);
	// //--appBG-sideMenuMode: rgba(23,22,24,0.15);
	}
}

// WEB VERSION
:root[data-theme="dark"]
.app--web{
	--appContentsBorder-sideMenuMode: rgb(68,68,74);	
	--appBG-sideMenuMode: rgb(32,30,36);
	--appBG-sideMenuHidden: rgba(54,50,58);
}










// SIDE MENU 