$side-newColRowBtn: 18px;

.doc-table-newColumnBtnContainer,
.doc-table-newRowBtnContainer{
	position: absolute;
	box-sizing: border-box;

	display: none !important;
}

.doc-table-newRowBtnContainer,
.doc-table-newColumnBtnContainer{
	opacity: 0;
	pointer-events: none;
}

.doc-tableContainer:hover .doc-table-newRowBtnContainer,
.doc-tableContainer:hover .doc-table-newColumnBtnContainer,
.doc-tableContainer--focused .doc-table-newRowBtnContainer,
.doc-tableContainer--focused .doc-table-newColumnBtnContainer,
.doc-tableContainer--focused .doc-table-newRowBtnContainer:hover,
.doc-tableContainer--focused .doc-table-newColumnBtnContainer:hover{
	opacity: 1;
	pointer-events: auto;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}

.doc-table--resizing .doc-table-newColumnBtnContainer,
.doc-table--resizing .doc-table-newRowBtnContainer{
	opacity: 0 !important;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}


//
//

.doc-table-newColumnBtnContainer{
	

	top: $topPadding-tableContainer;
	bottom: $bottomPadding-tableContainer;

	box-sizing: border-box;
	padding-left: 4px;

	width: $side-newColRowBtn;
}

.doc-table-newRowBtnContainer{
	left: 0px;
	
	top: calc(100% - #{$bottomPadding-tableContainer});
	
	box-sizing: border-box;
	padding-top: 4px;

	height: $side-newColRowBtn;
}

.doc-table-newColumnBtn,
.doc-table-newRowBtn{
	width: 100%;
	height: 100%;

	//background: var(--253gray);
	//border: 1px solid var(--line300);
	border-radius: 3px;

	background: rgb(235,235,240);

	cursor: pointer;
}

[data-theme="dark"]{
	.doc-table-newColumnBtn,
	.doc-table-newRowBtn{
		background: rgba(250,250,255,0.08);	
	}
}

.doc-table-newColumnBtn:hover,
.doc-table-newRowBtn:hover{
	background: darken(rgb(235,235,240), 5%);
	// background: rgb(6,115,255);
}

[data-theme="dark"]{
	.doc-table-newColumnBtn:hover,
	.doc-table-newRowBtn:hover{
		background: rgba(250,250,255,0.12);	
	}
}


.doc-table-newColumnBtn svg,
.doc-table-newRowBtn svg{
	width: 14px;
	height: 14px;

	fill: var(--text600);
	opacity: 0.4;
}

.doc-table-newColumnBtn:hover svg,
.doc-table-newRowBtn:hover svg{
	opacity: 0.7;
}

