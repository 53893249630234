.projectsPageContainer{
	position: fixed;
	
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);
	
	overflow-x: hidden;
	overflow-y: scroll;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-channelFrame);

	z-index: 10;

	border-radius: 8px;
}


.projectsPageContainer--sideMenuState--hidden{
	left: calc(#{$windowMargin-sideMenuMode} - 1px);
}

.projectsPage{
	width: 100%;
	// height: 100%;
	display: flex;	
}

.projectsPage-leftSpacer,
.projectsPage-rightSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	// height: 100%;
	// widths set dynamically in coreLayout
}

.projectsPage-contentContainer{
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;
	//max-width: 1100px;
	box-sizing: border-box;	
	// overflow: hidden;

	padding-top: 0px;	
	min-width: 10px;

	position: relative;
}

.projectsPage-bottomSpacer{
	height: 96px;
	width: 100%;
}






@import 'Projects-Header';

$height-stickyHeader-projectsPage: 54px;

.projectsPage-header{
	margin-bottom: calc(-1 * #{$height-stickyHeader-projectsPage});
}

.projectsPage-sticky{
	width: 100%;
	height: $height-stickyHeader-projectsPage;
	// background: $tempg;
	position: sticky;
	top: 0px;

	z-index: 50;
}

.projectsPage-sticky-header{
	
	// to cover up rows
	width: calc(100% + 36px);
	margin-left: -36px;
	padding-left: 36px;

	box-sizing: border-box;

	height: 100%;
	display: flex;
	align-items: center;

	background: var(--bg-docEditor);
}

.projectsPage-sticky-header-header{
	height: 100%;

	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;

	font-size: 24px;
	color: var(--text600);
	font-weight: $lato-heavy;	

	position: relative;	
}

.projectsPage-sticky-header-header-bottomBorder{
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	height: 1px;
	background: var(--line400);
}

.projectsPage-sticky-header-rightSpacer{
	// width set in core layout
	flex-grow: 0;
	flex-shrink: 0;	
}

.projectsPage-sticky-activePanelContainer,
.projectsPage-sticky-sideControlsContainer{
	position: absolute;
	right: 0px;
	top: $height-stickyHeader-projectsPage;
	//margin-top: 37px;
	
	// width set in corelayout
	//height: 600px;	

	box-sizing: border-box;
	
	//padding-left set in corelayout
	// padding-left: 48px;
	// padding-left: 56px;
	// padding-left: 64px;
}

.projectsPage-sticky-sideControlsContainer--hasDescription{
	margin-top: 33px;
}

.projectsPage-sticky-sideControlsContainer--noDescription{
	margin-top: 22px;
}

.projectsPage-activePanelContainer{
	position: fixed;
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);

	//background: $tempr;

	display: flex;

	z-index: 50;

	box-sizing: border-box;
	padding-top: 64px;
}


.projectsPage-list{
	width: 100%;	
	// background: $tempb;

	display: flex;
}

.projectsPage-list-list{
	min-width: 10px;	
	flex-grow: 1;
	flex-shrink: 1;

	//height: 2000px;
	
	// background: $tempb;
}

.projectsPage-contentContainer--hasDescription
.projectsPage-list-list{
	margin-top: 24px;
}

.projectsPage-contentContainer--noDescription
.projectsPage-list-list{
	margin-top: 14px;
}


.projectsPage-list-rightPanelSpacer{
	// width set in corelayout	
	flex-grow: 0;
	flex-shrink: 0;
}




















// OLD STUFF

//

.projectsPage-largeHeader{
	margin-bottom: 8px;

	display: flex;
	align-items: center;

	height: 56px;
	padding-top: 14px;

	background: var(--bg-docEditor);

	z-index: 20;

	margin-bottom: -57px;
	margin-bottom: -48px;
}

.projectsPage-largeHeader-markerContainer{
	width: 44px;
	height: 44px;	
	border-radius: 7px;
	//background: var(--252gray);
	
	box-sizing: border-box;

	margin-right: 11px;

	margin-left: -11px;

	// /background: $tempr;
	margin-top: 1px;
}

//

.projectsPage-largeHeader-markerContainer--all{
	display: flex;
	align-items: center;
	justify-content: center;
}

.projectsPage-largeHeader-markerContainer--all svg{
	width: 42px;
	height: 42px;
	fill: var(--text500);
	opacity: 0.4;

	// same as drafts
}

//

.projectsPage-largeHeader-markerContainer--your{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	//justify-content: flex-end;
	//padding-bottom: 8px;

	gap: 4px;
}

.projectsPage-largeHeader-markerContainer--your-blockContainer{
	display: flex;
	gap: 3px;
}

.projectsPage-largeHeader-markerContainer--your-block{
	width: 9px;
	height: 9px;
	border-radius: 3px;

	border: 1.75px solid var(--text500);
	opacity: 0.4;

	// background: var(--210gray);	
}


// //

// .projectsPage-largeHeader-markerContainer--your{
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: flex-end;

// 	gap: 4px;
// 	padding-bottom: 8px;
// }

// .projectsPage-largeHeader-markerContainer--your-block{
// 	width: 25px;
// 	height: 5px;
// 	border-radius: 1px;

// 	background: var(--210gray);
// }

//


.projectsPage-largeHeader-markerContainer--team{
	display: flex;
	align-items: center;
	justify-content: center;

	// position: relative;
	// overflow: hidden;
	// background: none;
	// background: $tempb;

}

.projectsPage-largeHeader-markerContainer--team
.linearMarker{
	font-size: 30px;
	position: relative;
	z-index: 20;
}

.projectsPage-largeHeader-markerContainer--team
.linearMarker svg{
	width: 30px;
	height: 30px;
}

// .projectsPage-largeHeader-markerContainer--team-markerBlurBorder{
// 	position: absolute;
// 	top: 0px;
// 	right: 0px;
// 	left: 0px;
// 	bottom: 0px;

// 	z-index: 15;
// 	border: 2px solid rgba(0,0,0,0.05);
// 	// border: 2px solid $tempr;
// 	border-radius: 7px;

// 	display: none;

// }

// .projectsPage-largeHeader-markerContainer--team-markerBlurOverlay{
// 	position: absolute;
// 	top: 0px;
// 	right: 0px;
// 	left: 0px;
// 	bottom: 0px;

// 	z-index: 10;
// 	background: var(--bg-docEditor);
// 	border-radius: 7px;

// 	opacity: 0.8;

// 	// display: none;
// }

// .projectsPage-largeHeader-markerContainer--team-markerBlurBG{
// 	position: absolute;
// 	top: -100px;
// 	right: -100px;
// 	left: -100px;
// 	bottom: -100px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	filter: blur(10px) saturate(250%);

// 	opacity: 0.8;

	

// }

// .projectsPage-largeHeader-markerContainer--team-markerBlurBG
// .linearMarker{
//  	font-size: 84px;
// }

//

.projectsPage-largeHeader-title{
	font-size: 36px;
	font-weight: $lato-black;
	color: var(--text600);
	letter-spacing: 0.001em;
}


[data-theme="dark"] {
	.projectsPage-largeHeader-title{
		font-weight: $lato-heavy;
		letter-spacing: 0.005em;
	}
}

.projectsPage-largeHeader-title span{
	
}

@media screen and (max-width: 1270px){
	.projectsPage-largeHeader-title span{
		display: none; // hide projects word if too narrow
	}
}


@import 'Projects-SideControls';
@import 'Projects-ActiveProjectPanel';

@import 'Projects-HeaderFilters';

@import 'Projects-PageSectionHeader';
@import 'Projects-Project';

.projectsPage-emptyStatus{
	width: 100%;
	height: 32px;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 44px;
	padding-left: 12px;

	// background: $tempr;

	color: var(--text400);
	opacity: 0.5;

	font-size: 16.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.001em;

	cursor: default;
	user-select: none;
}



//
// // YOUR PROJECTS

// .projectsPage-yourProjectsContainer{
// 	width: 100%;
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin-bottom: 48px;
// }

// .projectsPage-yourProject{
// 	width: 50%;
// 	height: 40px;
// 	// background: $tempr;
// 	// outline: 1px solid red;
// }



// //
// // SHIPPED PROJECTS

// .projectsPage-shippedProjectContainer{
// 	display: flex;
// 	width: 4000px; // temp
// 	margin-bottom: 36px;
// }

// .projectsPage-shippedProject{
// 	width: 500px;
// 	height: 300px;
// 	// background: $tempr;
// 	// outline: 1px solid red;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// //
// // //

// // .projectsPage-inProgressProjectsOuterContainer{
// // 	width: 100%;
	
// // }

// // .projectsPage-inProgressProjectsInnerContainer{
// // 	width: calc(100% + 24px + 24px);
// // 	width: 100%;
// // 	display: flex;
// // 	flex-wrap: wrap;	
// // 	margin-left: -24px;	

// // 	margin-top: 8px;
// // }



// // //

// // .projectsPage-twoColumnContainer{
// // 	width: 100%;
// // 	display: flex;
// // 	column-gap: 8vw;
// // 	margin-top: 68px;
// // }

// // .projectsPage-plannedProjectsOuterContainer{
// // 	width: calc(50% - 4vw);
// // 	flex-grow: 0;
// // 	flex-shrink: 0;
// // }

//
// IN PROGRESS PROJECT


//
// PLANNED PROJECT

