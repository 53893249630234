//
//
//

.fullScreenActiveViewStatusDoc{
	// width: 40px;
	// height: 200px;	

	position: fixed;
	left: 0px;
	

	top: calc(50% - 40px);
	transform: translateY(-50%);

	z-index: 10000;

	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	padding-top: 40px;
	padding-bottom: 40px;

	margin-top: -48px;
}


.fullScreenActiveViewStatusDoc-channelBtnContainer{
	position: relative;	
	padding: 4px;

	cursor: pointer;

	padding-left: 16px;
	padding-top: 12px;
	padding-bottom: 12px;

	//margin-bottom: 12px;
}

.fullScreenActiveViewStatusDoc-channelBtn{
	width: 26px;
	height: 26px;
	height: 32px;
	// height: 52px;

	border-radius: 4px;

	background: rgba(0,0,0,0.05);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	background: #a6a1a8;
}

.fullScreenActiveViewStatusDoc-channelBtn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;	
}

.fullScreenActiveViewStatusDoc-channelBtn svg.icon{
	width: 16px;
	height: 16px;

	fill: var(--text500);
	opacity: 0.5;

	fill: $white;
	opacity: 1;
}

.fullScreenActiveViewStatusDoc
.fullScreenActiveViewStatusDoc-channelBtnContainer{
	opacity: 0.5;
}

.fullScreenActiveViewStatusDoc:hover 
.fullScreenActiveViewStatusDoc-channelBtnContainer{
	opacity: 0.25;
}

.fullScreenActiveViewStatusDoc:hover 
.fullScreenActiveViewStatusDoc-channelBtnContainer:hover{
	opacity: 1;
}

.fullScreenActiveViewStatusDoc:hover 
.fullScreenActiveViewStatusDoc-channelBtn:hover{
	background: darken(#a6a1a8, 5%);
}


//
// DROPDOWN PROTOTYPE
.fullScreenActiveViewStatusDoc-channelBtnPopoverContainer{
	pointer-events: none;
	//background: $tempr;

	position: absolute;

	top: -44px;
	top: -72px;
	left: 39px;
	// right: 0px;

	width: 300px;
	height: 280px;

	padding-top: 6px;

	// background: $tempr;	

	z-index: 100;

	opacity: 0;

}

.fullScreenActiveViewStatusDoc-channelBtnPopoverContainer{
	width: 280px;
}

.fullScreenActiveViewStatusDoc-channelBtnPopoverContainer
.sideMenu-activeViewStatus--doc-popover{
	margin-left: 12px;
}

.fullScreenActiveViewStatusDoc:hover 
.fullScreenActiveViewStatusDoc-channelBtnPopoverContainer{
	opacity: 1;

	transition: opacity 0ms linear;
	transition-delay: 0ms;
}

.fullScreenActiveViewStatusDoc-channelBtnContainer:hover
.fullScreenActiveViewStatusDoc-channelBtnPopoverContainer{
	pointer-events: auto;
}

.fullScreenActiveViewStatusDoc-channelBtnContainer:hover
.sideMenu-activeViewStatus--doc-popover{
	opacity: 1;
}

//
//

.fullScreenActiveViewStatusDoc-userList{
	display: flex;
	flex-direction: column;
}

.fullScreenActiveViewStatusDoc-userList {

}