//$height-insertMentionsMenu-item: 28px;
$height-insertMentionsMenu-item: 28px;


.insertMentionsMenu-item{
	height: $height-insertMentionsMenu-item;
	width: calc(100% - 10px);
	margin-left: 5px;	

	display: flex;	
	align-items: center;

	box-sizing: border-box;
	padding-left: 8px;	
	padding-right: 8px;

	line-height: 1.9;

	background: var(--bg-dropdown);
	border-radius: 4px;

	cursor: pointer;

	position: relative;
}

.insertMentionsMenu-item--mouseMoved.insertMentionsMenu-item:hover,
.insertMentionsMenu-item--active,
.insertMentionsMenu-item--active:hover{
	background: var(--hoverBG-item-dropdown);
}

//

.insertMentionsMenu-item--mouseMoved.insertMentionsMenu-item:hover .insertMentionsMenu-item-title,
.insertMentionsMenu-item--active .insertMentionsMenu-item-title,
.insertMentionsMenu-item--active:hover .insertMentionsMenu-item-title{
	color: var(--text600);
}

//

.insertMentionsMenu-item--issue-workflowStateIconContainer,
.insertMentionsMenu-item--project-markerContainer,
.insertMentionsMenu-item--user-avatarOuterContainer,
.insertMentionsMenu-item--doc-iconContainer,
.insertMentionsMenu-item--web-iconContainer,{
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 26px;
	height: 26px;

	margin-left: -4px;

	margin-right: 4px;
}

.insertMentionsMenu-item--issue-workflowStateIconContainer{
	
}

.insertMentionsMenu-item--project-markerContainer{

}

.insertMentionsMenu-item--project-markerContainer
.linearMarker{
	font-size: 16px;
}

.insertMentionsMenu-item--project-markerContainer
.linearMarker svg{
	width: 16px;
	height: 16px;
}

.insertMentionsMenu-item--doc-iconContainer svg,
.insertMentionsMenu-item--web-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.35;
}

.insertMentionsMenu-item--web-iconContainer svg{
	width: 16px;
	height: 16px;
}

.insertMentionsMenu-item--user-avatarInnerContainer{
	width: 20px;
	height: 20px;
	position: relative;
	top: -1px;
}

.insertMentionsMenu-item--user-avatar{
	border-radius: 2px;
}

//

.insertMentionsMenu-item-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;		

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insertMentionsMenu-item-label{
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text500);


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insertMentionsMenu-item--web
.insertMentionsMenu-item-label{
	position: relative;
	top: -1px; // a bit hacky, but looks better beause all lower case
}

.insertMentionsMenu-item--date--hasLabel
.insertMentionsMenu-item-label--date{
	margin-left: 0.4em;
	opacity: 0.7;
}

//

.insertMentionsMenu-item--web-hint{
	margin-left: auto;
	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.5;
	white-space: nowrap;
	flex-grow: 0;
	flex-shrink: 0;
}

//

.insertMentionsMenu-item--issue-identifierContainer{
	flex-grow: 0;
	flex-shrink: 0;

	margin-left: auto;

	padding-left: 2px;
	// background: $tempr;
	min-width: 55px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-size: 12.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	opacity: 0.6;
}


// TAGS
// taken from doc rows
.insertMentionsMenu-item--doc-projectTag,
.insertMentionsMenu-item--doc-roadmapTag,
.insertMentionsMenu-item--doc-orgTag,
.insertMentionsMenu-item--doc-teamTag{
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	height: 22px;

	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;

	margin-left: auto;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .insertMentionsMenu-item--doc-projectTag,
// .insertMentionsMenu-item--doc-roadmapTag,
// .insertMentionsMenu-item--doc-orgTag,
// .insertMentionsMenu-item--doc-teamTag{
// 	// only show on hover
// 	display: none;
// }

// .insertMentionsMenu-item--active{
// 	.insertMentionsMenu-item--doc-projectTag,
// 	.insertMentionsMenu-item--doc-roadmapTag,
// 	.insertMentionsMenu-item--doc-orgTag,
// 	.insertMentionsMenu-item--doc-teamTag{
// 		display: flex;
// 	}
// }

.insertMentionsMenu-item--active .insertMentionsMenu-item--doc-projectTag,
.insertMentionsMenu-item--active .insertMentionsMenu-item--doc-roadmapTag,
.insertMentionsMenu-item--active .insertMentionsMenu-item--doc-orgTag,
.insertMentionsMenu-item--active .insertMentionsMenu-item--doc-teamTag{
	background: var(--235gray);
}


.insertMentionsMenu-item--doc-orgTag-label,
.insertMentionsMenu-item--doc-roadmapTag-label,
.insertMentionsMenu-item--doc-projectTag-label,
.insertMentionsMenu-item--doc-teamTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.8;
	font-weight: $lato-medium;

	position: relative;
	top: -0.5px;

	margin-left: 5px;

	max-width: 120px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insertMentionsMenu-item--doc-projectTag-marker{
	flex-grow: 0;
	flex-shrink: 0;
}

.insertMentionsMenu-item--doc-projectTag
.linearMarkerContainer--markerType--emoji,
.insertMentionsMenu-item--doc-teamTag
.linearMarkerContainer--markerType--emoji{
	font-size: 12.5px;
}

.insertMentionsMenu-item--doc-projectTag
.linearMarkerContainer--markerType--icon svg,
.insertMentionsMenu-item--doc-teamTag
.linearMarkerContainer--markerType--icon svg{
	width: 13px;
	height: 13px;
}


.insertMentionsMenu-item--doc-roadmapTag-icon svg{
	width: 13px;
	height: 13px;
	fill: var(--text500);
	opacity: 0.4;
}

.insertMentionsMenu-item--doc-orgTag-image{
	width: 13px;
	height: 13px;
	margin-right: 1px;

	background: $tempr;
	border-radius: 3px;
	overflow: hidden;
	position: relative;
}

img.insertMentionsMenu-item--doc-orgTag-image-image{
	// object fit didn't seem to work
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;

}


//


$popoverEdgeMargin-insertMentionsMenu: 4px; // don't undesrtand this

.insertMentionsMenu-item-hoverPreviewContainer{
	position: absolute;
	top: -120px;
	height: 400px;
	// bottom: 0px;
	left: calc(#{$width-insertMentionsMenu} + #{$popoverEdgeMargin-insertMentionsMenu});
	// background: $tempr;
	width: 400px;

	display: flex;
	align-items: flex-start;	
	justify-content: flex-start;

	padding-top: 10px;

	opacity: 0;
}

.insertMentionsMenu--nearTop
.insertMentionsMenu-item
.insertMentionsMenu-item-hoverPreviewContainer{
	top: -40px;
}

.insertMentionsMenu--nearBottom 
.insertMentionsMenu-item
.insertMentionsMenu-item-hoverPreviewContainer{
	top: unset;
	bottom: -100px;
}



.insertMentionsMenu--popoverSide--left
.insertMentionsMenu-item-hoverPreviewContainer{
	left: unset;
	right: calc(#{$width-insertMentionsMenu} + #{$popoverEdgeMargin-insertMentionsMenu});
	justify-content: flex-end;
}

.insertMentionsMenu-item--active
.insertMentionsMenu-item-hoverPreviewContainer{
	opacity: 1;
}

.insertMentionsMenu-item-hoverPreviewContainer
.linearIssuePreviewPopover-descriptionContainer{
	max-height: 180px; // looks tidier when doesn't overflow
	height: 180px; // for testing
}

.insertMentionsMenu-item-hoverPreviewContainer
.linearIssuePreviewPopover-descriptionOverflowGradient{
	top: 180px;
}

//

$width-docPreview-insertMentionsMenu: 278px;
$docOriginalScaleWidth-docPreview-insertMentionsMenu: 860px;
$docScale-docPreview-insertMentionsMenu: 0.345;


.insertMentionsMenu-item--doc-previewInnerContainer{
	
	height: 320px;
	width: $width-docPreview-insertMentionsMenu;

	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-bottom: 0px;

	overflow: hidden;
	cursor: pointer;

	background: $white;
}


.insertMentionsMenu-item--doc-preview{
	transform: scale($docScale-docPreview-insertMentionsMenu);
	transform-origin: top left;
	width: $docOriginalScaleWidth-docPreview-insertMentionsMenu;
	display: flex;
	justify-content: center;

	pointer-events: none;	
}

.insertMentionsMenu-item--doc-preview-doc{
	width: $fullWidth-doc;

	height: 100%;
	// background: $tempg;

	padding-top: 46px; // hacky
}

.insertMentionsMenu-item--doc-preview-doc-title{
	width: $textWidth-doc;
}

.insertMentionsMenu-item--doc-preview-doc-innerWrapper{
	width: $textWidth-doc;
}

//