
.docEditor-topMenuContainer--rootDoc-linearSummary{
	display: flex;
	height: 30px;

	// background: $tempr;
	padding-right: 9px;
	padding-left: 7px;

	border-radius: 4px;
	// padding-bottom: 1px;

	-webkit-app-region: no-drag;
}



.docEditor-topMenuContainer--rootDoc-linearSummary-progressCircleContainer{
	width: 14px;
	height: 14px;
	// background: $tempr;

	margin-right: 4px;
	position: relative;
	// top: 1px;
	top: -1.25px;
}

.docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel,
.docEditor-topMenuContainer--rootDoc-linearSummary-scope-label{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: var(--text500);
	opacity: 0.8;
}

.docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel span{
	opacity: 0.75;
}

.docEditor-topMenuContainer--rootDoc-linearSummary-scopeDot{
	width: 4px;
	height: 4px;
	border-radius: 1px;
	background: var(--text600);
	opacity: 0.5;
	margin-right: 5px;
	margin-left: 6px;
}

.docEditor-topMenuContainer--rootDoc-linearSummary:hover{
	background: var(--250gray);
}

.docEditor-topMenuContainer--rootDoc-linearSummary:hover .docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel,
.docEditor-topMenuContainer--rootDoc-linearSummary:hover .docEditor-topMenuContainer--rootDoc-linearSummary-scope-label{
	opacity: 0.9;
}

.docEditor-topMenuContainer--rootDoc-linearSummary--active,
.docEditor-topMenuContainer--rootDoc-linearSummary--active:hover{
	background: var(--246gray);
}

.docEditor-topMenuContainer--rootDoc-linearSummary--active .docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel,
.docEditor-topMenuContainer--rootDoc-linearSummary--active .docEditor-topMenuContainer--rootDoc-linearSummary-scope-label,
.docEditor-topMenuContainer--rootDoc-linearSummary--active:hover .docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel,
.docEditor-topMenuContainer--rootDoc-linearSummary--active:hover .docEditor-topMenuContainer--rootDoc-linearSummary-scope-label{
	opacity: 1;
}



//

.docEditor-topMenuContainer--rootDoc-teamBtn:hover{
	background: var(--250gray);
}

.docEditor-topMenuContainer--rootDoc-teamBtn:hover
.docEditor-topMenuContainer--rootDoc-teamBtn-label{
	color: var(--text600);
}

.docEditor-topMenuContainer--rootDoc-teamBtn--active{
	background: var(--246gray);
}

.docEditor-topMenuContainer--rootDoc-teamBtn--active
.docEditor-topMenuContainer--rootDoc-teamBtn-label{
	color: var(--text600)
}

//
// PROGRESS BAR

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.docEditor-topMenuContainer--rootDoc-linearSummary .CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  
}

.docEditor-topMenuContainer--rootDoc-linearSummary .CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  //stroke: var(--primary500);
  stroke: var(--purple--yarn);
  stroke-linecap: round;

  transition: stroke-dashoffset 0.5s ease 0s;
}

.docEditor-topMenuContainer--rootDoc-linearSummary .CircularProgressbar .CircularProgressbar-trail {
  // stroke: #d6d6d6;
  // stroke: var(--primary50);
  // stroke: var(--240gray);
  // stroke: red;
  stroke: var(--purple--yarn);
  opacity: 0.35;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.docEditor-topMenuContainer--rootDoc-linearSummary .CircularProgressbar .CircularProgressbar-background {
  //fill: #d6d6d6;

}
