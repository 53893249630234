
///
/// HOVER MENU


.docEditor-commentsPanel-commentGroup-comment-reactionMenu{
	display: flex;
	position: absolute;
	//right: 20px;
	right: 8px;
	top: -15px;

	z-index: 50;

	height: 28px;

	border-radius: 5px;
	background: $white;
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);

	background: var(--bgFloatingMenu);
	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);

	padding: 0px;

	box-shadow: var(--boxShadowFloatingMenu);

	padding-top: 0px;
	padding-bottom: 1px;
	padding-left: 3px;
	padding-right: 3px;
}

.docEditor-commentsPanel-commentGroup-comment:first-child
.docEditor-commentsPanel-commentGroup-comment-reactionMenu{
	top: 1px;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-inner{
	width: 100;
	display: flex;
}


button.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn{
	height: 28px;
	width: 28px;
	border: none;
	background: $invisible;
	box-shadow: none;

	padding: 0px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 18.5px; // for emoji

	cursor: pointer;



}

button.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn:hover{
	background: $lightGray-bg;
}	

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);
	opacity: 0.7;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn:hover svg{
	opacity: 0.8;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-inner:hover 
button.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn{
	opacity: 0.7;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-inner:hover 
button.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn:hover{
	opacity: 1;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu{
	opacity: 0;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu:focus-within{
	opacity: 1;
}

.docEditor-commentsPanel-commentGroup-comment:hover
.docEditor-commentsPanel-commentGroup-comment-reactionMenu,
.docEditor-commentsPanel-commentGroup-comment-reactionMenu:hover{
	opacity: 1;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn svg.icon--name--slackIcon{
	width: 16px;
	height: 16px;
}

.docEditor-commentsPanel-commentGroup-comment-reactionMenu-btn svg.icon--name--reply{
	transform: scaleX(-1.0);
}