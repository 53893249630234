///
///


.sideMenu-footer{
	// temp
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	background: $tempb;
	
	height: 50px;
	user-select: none;
}

.sideMenu-tabList-footer{
	width: 100%;
	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	height: 50px;

	cursor: default;
	user-select: none;
	position: relative;
}

.sideMenu-tabList-footer-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: calc(100% * 3);
	bottom: -200px;
	// background: $tempr;

	z-index: 1;

	pointer-events: none;
	background: linear-gradient(90deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.25) 50%, rgba(255,255,255,0) 100%);	
}

:root[data-theme="dark"]
.sideMenu-tabList-footer-bg{
	background: rgb(90,90,90);
	background: linear-gradient(90deg, rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 50%, rgba(255,255,255,0) 100%);
}


.sideMenu-tabList-footer-divider{
	position: absolute;
	top: 0px;
	left: 12px;
	right: 0px;
	height: 1px;

	background: var(--text600);
	opacity: 0.1;
}

.sideMenu-tabList-footer--activeSession
.sideMenu-tabList-footer-divider{
	left: 0px;
}


.sideMenu-tabList-footer-avatarRow{
	position: absolute;
	top: 9px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 6px;

	z-index: 100;
}

.sideMenu-tabList-footer-avatarRow-avatar{
	width: 28px;
	height: 28px;

	margin-right: 7px;

	border-radius: 5px;
	position: relative;

	// opacity: 0.75;
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator{
	width: 9px;
	// width: 3px;
	height: 9px;
	border-radius: 15px;

	border: 2.5px solid var(--appBG-sideMenuMode);

	position: absolute;
	bottom: -2px;
	right: -2px;

	//background: red;
	z-index: 5;

	// opacity: 0;
	// transition: opacity 200ms linear;
	// transition-delay: 2000ms;
}

// .sideMenu-tabList-footer:hover
// .sideMenu-tabList-footer-avatarRow-avatar-statusIndicator{
// 	opacity: 1;
// 	transition: opacity 0ms linear;
// 	transition-delay: 0ms;
// }


.sideMenu-tabList-footer-avatarRow-addUser{
	width: 26px;
	height: 26px;
	border-radius: 4px;
	background: var(--bgSideMenuTabLight);

	margin-left: 1px;
	margin-top: 1px;

	cursor: pointer;
	opacity: 1;

	transition: opacity 200ms linear;
	transition-delay: 2000ms;
}

.sideMenu-tabList-footer-avatarRow-addUser svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.4;
	transition: all 100ms linear;
}

.sideMenu-tabList-footer-avatarRow-addUser:hover{
	background: var(--bgSideMenuTabHover);
}

.sideMenu-tabList-footer-avatarRow-addUser:hover svg{
	opacity: 0.65;
	transform: scale(1.05);
}


.sideMenu-tabList-footer:hover
.sideMenu-tabList-footer-avatarRow-addUser{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}

.sideMenu-tabList-footer-avatarRow-addUser--menuOpen,
.sideMenu-tabList-footer-avatarRow-addUser--menuOpen:hover{
	pointer-events: none;
	opacity: 0.5;
	transition: all 100ms linear;
	transition-delay: 0ms;
}



// .sideMenu-tabList-footer-avatarRow-avatar:nth-child(2){
// 	opacity: 0.25;
// }

// .sideMenu-tabList-footer-avatarRow-avatar:nth-child(2){
// 	background: var(--accentOrange500);
// }

.sideMenu-tabList-footer-avatarRow-avatar-image{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}


.sideMenu-tabList-footer-actionRow{
	position: absolute;
	bottom: 12px;
	left: 0px;
	right: 0px;

	height: 30px;

	// background: $tempr;

	box-sizing: border-box;

	z-index: 50;
	
}
























//


//

.sideMenu-tabList-footer-actionRow-activeSet{
	position: absolute;
	left: 6px;
	top: 4px;

	display: flex;

	z-index: 5;
}

.sideMenu-tabList-footer-actionRow-activeSet--show{
	opacity: 1;
	transition: opacity 175ms linear;
	transition-delay: 25ms;
}

.sideMenu-tabList-footer-actionRow-activeSet--hide{
	opacity: 0;
	transition: opacity 50ms linear;
	pointer-events: none;
}


.sideMenu-tabList-footer-actionRow-activeSet-btn{
	width: 32px;
	height: 30px;
	
	border-radius: 5px;

	// border-radius: 50%;
}

.sideMenu-tabList-footer-actionRow-activeSet-btn--inactive:hover{
	background: var(--bgSideMenuTabHover);
}



.sideMenu-tabList-footer-actionRow-activeSet-btn svg{
	width: 20px;
	height: 20px;

	fill: var(--text600);
	opacity: 0.7;
}


.sideMenu-tabList-footer-actionRow-activeSet-btn svg.icon--name--captions{
	width: 22px;
	height: 22px;
	margin-top: 2px;
	
}

.sideMenu-tabList-footer-actionRow-activeSet-btn svg.icon--name--drawingSquiggle{
	width: 22px;
	height: 22px;
	margin-top: 1px;
	
}

.sideMenu-tabList-footer-actionRow-activeSet-btn svg.icon--name--textNote{
	width: 22px;
	height: 22px;
}

.sideMenu-tabList-footer-actionRow-activeSet-btn:hover svg{
	opacity: 0.9;
}

.sideMenu-tabList-footer-actionRow-activeSet-btn--active{
	
}

.sideMenu-tabList-footer-actionRow-activeSet-btn--active:hover{
	
}

.sideMenu-tabList-footer-actionRow-activeSet-btn--active svg{
	opacity: 1;
}


.sideMenu-tabList-footer-toggleContainer{
	position: absolute;
	bottom: 40px;
	right: -2px;
	z-index: 100;
}

.sideMenu-tabList-footer-actionRow-toggleBtn{
	position: absolute;
	top: 5px;
	right: 14px;

	width: 52px;
	height: 24px;

	border-radius: 12px;

	background: var(--sideMenuFooterToggleBG);
	border: 1px solid var(--line400);	

	z-index: 500;
}

.sideMenu-tabList-footer-actionRow-toggleBtn-circle{
	position: absolute;
	top: 2px;
	left: 2px;

	width: 18px;
	height: 18px;
	border-radius: 50%;

	z-index: 10;

	background: rgba(0,0,0,0.25);
}

.sideMenu-tabList-footer-actionRow-toggleBtn--active
.sideMenu-tabList-footer-actionRow-toggleBtn-circle{
	outline: 1px solid rgba(0,0,0,0.3);
}

.sideMenu-tabList-footer-actionRow-toggleBtn-headphonesIconContainer{
	position: absolute;
	right: 3px;
	top: 0px;
	bottom: 0px;
	pointer-events: none;

	width: 23px;

	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;

	// display: none;
}

.sideMenu-tabList-footer-actionRow-toggleBtn-headphonesIconContainer svg{
	width: 15px;
	height: 15px;
	position: relative;
	top: -0.5;

	fill: var(--text100);

	opacity: 1;
}

.sideMenu-tabList-footer-actionRow-toggleBtn-activeBG{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	border-radius: 12px;

	background: var(--primary500);
	box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.04);
}

:root[data-theme="dark"]
.sideMenu-tabList-footer-actionRow-toggleBtn-activeBG{
	background: rgb(90,90,90);
	box-shadow: 0px 0px 0px 4px rgba(20,20,20);	
}

//

.sideMenu-tabList-footer-tabList{
	position: absolute;	
	left: 5px;
	right: 8px;
	bottom: 110px;

	z-index: 5;
}

.sideMenu-tabList-footer-tabList-tab{
	width: 100%;
	height: var(--heightSideTab);
	background: var(--bgSideMenuTabActive);

	border-radius: 5px;

	box-sizing: border-box;
	padding-left: 12px;

	display: flex;
	align-items: center;

	font-size: var(--fontSizeSideMenuTab);
	font-weight: var(--textWeightSideMenuTab);
	letter-spacing: var(--textSpacingSideMenuTab);
	color: var(--textColorSideMenuTab);
}

.sideMenu-tabList-footer-tabList-tab--active{		
	font-weight: var(--textWeightSideMenuTabActive);
	letter-spacing: var(--textSpacingSideMenuTabActive);
	color: var(--textColorSideMenuTabActive);
}

.sideMenu-tabList-footer-tabList--show{
	opacity: 1;
	transform: translateX(0);

	transition: all 200ms ease-in-out;

	transition-delay: 175ms
}

.sideMenu-tabList-footer-tabList--hide{
	transform: translateX(-5px);
	opacity: 0;

	transition: all 50ms linear;
	transition-delay: 0ms;
	pointer-events: none;
}

// NOTES EXAMPLE

.sessionNotes{
	position: fixed;
	z-index: 5000;
	width: 500px;
	height: 600px;
	background: var(--bg-docEditor);

	bottom: 0px;
	right: 0px;

	border-radius: 8px;

	box-sizing: border-box;
	padding: 6px;


	

	border: 1px solid var(--line500);
	box-shadow: var(--sideMenuFooterNotesBoxShadow);
}

.sessionNotes-header{
	height: 38px;

	padding-left: 12px;

	border-radius: 4px;

	display: flex;
	align-items: center;	

	font-weight: $lato-bold;
	font-size: 18px;
	color: var(--text400);

	background: var(--bgSideMenuTabLight);

	cursor: pointer;
}

.sessionNotes-header:hover{
	// background: var(--bgSideMenuTabActive);	
	color: var(--text600);
}

.sessionNotes-header:active{
	background: var(--bgSideMenuTabLight);
}

//
//

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--purple{	
	background: var(--accentPurple600);
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--orange{
	background: var(--accentOrange600);
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--pink{
	background: var(--accentPink600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--red{
	background: var(--accentRed600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--green{
	background: var(--accentGreen600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--blue{
	background: var(--accentBlue600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--sky{
	background: var(--accentSky600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--teal{
	background: var(--accentTeal600);	
}

.sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--grey{
	background: var(--accentGrey600);	
}


//


.sideMenu-tabList-footer{
	width: 100%;
	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
	height: 48px; // for presence animation, overridden in JS

}

.sideMenu-tabList-footer--channel,
.sideMenu-tabList-footer--allDocs,
.sideMenu-tabList-footer--roadmap,
.sideMenu-tabList-footer--roadmaps,
.sideMenu-tabList-footer--privateDocs{
	display: flex;

	align-items: center;
	box-sizing: border-box;
	padding-left: 20px;
	padding-left: 14px;
	padding-right: 5px;
	padding-bottom: 5px;

	font-size: var(--fontSizeSideMenuTab);
	font-weight: $lato-semibold;
	color: var(--text200);
}


.sideMenu-tabList-footer--roadmap-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.sideMenu-tabList-footer--roadmap-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
	position: relative;
	top: 0.5px;
}



.sideMenu-tabList-footer--channel-iconContainer{
	width: 20px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}


.sideMenu-tabList-footer--channel-iconContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text200);
	opacity: 0.75;
}



.sideMenu-tabList-footer--docDefault{
	display: flex;

	align-items: center;
	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 5px;

	// background: $tempg;
}

.sideMenu-tabList-footer--docDefault-btn{
	// background: $tempr;
	height: 34px;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	border-radius: 5px;

	opacity: 0.75;

	box-sizing: border-box;
	padding-right: 4px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideMenu-tabList-footer--docDefault-btn:hover{
	background: var(--bgSideMenuTabHover);
	opacity: 1;
}

.sideMenu-tabList-footer--docDefault-btn:disabled{
	pointer-events: none;
}

.sideMenu-tabList-footer--docDefault-btn-iconContainer{
	width: 26px;
	height: 26px;
	// background: $tempr;	
	padding-right: 4px;
	padding-left: 6.5px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

.sideMenu-tabList-footer--docDefault-btn-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
}

.sideMenu-tabList-footer--docDefault-btn-labelContainer{
	min-width: 10px;

	flex-grow: 1;
	flex-shrink: 1;	

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: var(--fontSizeSideMenuTab);	
	color: var(--text600);

	user-select: none;

}

.sideMenu-tabList-footer--docDefault-btn-label{
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
 }

 .sideMenu-tabList-footer--docDefault-btn-label--docName{
 	font-weight: $lato-semibold;
 	letter-spacing: var(--textSpacingSideMenuTab);
 }

 .sideMenu-tabList-footer--docDefault-btn-label--instructions{
 	font-weight: $lato-medium;
 	letter-spacing: var(--textSpacingSideMenuTabActive);
 	color: var(--text500);
 }


.sideMenu-tabList-footer--docDefault-btn--menuClosed .sideMenu-tabList-footer--docDefault-btn-label--docName{
	display: block;
}

.sideMenu-tabList-footer--docDefault-btn--menuClosed .sideMenu-tabList-footer--docDefault-btn-label--instructions{
	display: none;
}	

.sideMenu-tabList-footer--docDefault-btn--menuClosed:hover .sideMenu-tabList-footer--docDefault-btn-label--docName,
.sideMenu-tabList-footer--docDefault-btn--menuOpen .sideMenu-tabList-footer--docDefault-btn-label--docName{
	display: none;
}

.sideMenu-tabList-footer--docDefault-btn--menuClosed:hover .sideMenu-tabList-footer--docDefault-btn-label--instructions,
.sideMenu-tabList-footer--docDefault-btn--menuOpen .sideMenu-tabList-footer--docDefault-btn-label--instructions{
	display: block;
}

@import 'SideMenu-Footer-DocPresence';

