.docEditor-meetingContent{
	margin-bottom: 22px;
	padding-bottom: 7px;
	margin-top: -6px;
	width: 100%;

	border-bottom: 1px solid var(--line300);
	position: relative;
	z-index: 20; // above the input
}

[data-theme="dark"]{
	.docEditor-meetingContent{
		border-bottom: 1px solid var(--line200);
	}
}

//
//
.docEditor-meetingContent-menu{
	display: flex;
	align-items: center;

	padding-bottom: 6px;
}

.docEditor-meetingContent-activeProjects
.docEditor-meetingContent-menu,
.docEditor-meetingContent-highlights
.docEditor-meetingContent-menu{
	border-bottom: 1px solid var(--line300);
	height: 30px;
}

.docEditor-meetingContent-activeProjects-list-smallVSpacer{
	height: 8px;
}

//
// Header Label

.docEditor-meetingContent-menu-headerLabel{
	font-size: 17.25px;
	font-weight: $lato-semibold;
	color: var(--text600);

	user-select: none;
	cursor: default;
}




//
// Primary button
// on the left

.docEditor-meetingContent-menu-primaryBtn{
	padding-left: 5px;
	padding-right: 13px;
	border-radius: 4px;

	background: var(--254gray);
	border: 1px solid var(--line300);

	height: 30px;

	margin-bottom: -6px;
}

.docEditor-meetingContent-menu-primaryBtn[data-state="open"]{
	pointer-events: none;
}

.docEditor-meetingContent-menu-primaryBtn:hover{
	background: var(--252gray);
	border: 1px solid var(--line375);
}

[data-theme="dark"]{
	.docEditor-meetingContent-menu-primaryBtn{
		background: var(--253gray);
		border: 1px solid var(--line150);
	}
	.docEditor-meetingContent-menu-primaryBtn:hover{
		border: 1px solid var(--line200);
	}
}

.docEditor-meetingContent-menu-primaryBtn-iconContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1px;
}

.docEditor-meetingContent-menu-primaryBtn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.85;
}

.docEditor-meetingContent-menu-primaryBtn:hover
.docEditor-meetingContent-menu-primaryBtn-iconContainer svg{
	opacity: 0.95;
}

// Upload

.docEditor-meetingContent-menu-primaryBtn--addRecording
.docEditor-meetingContent-menu-primaryBtn-iconContainer{
	margin-right: 3px;
}

.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle{
	width: 18px;
	height: 18px;

	transform-origin: 50% 50%;
	transform: scale(1.1);
}

[data-theme="light"]{
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #circle{
		fill: var(--text500);
		opacity: 0.1;
	}
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #arrow{
		fill: var(--text500);
		opacity: 0.85;
	}	
	.docEditor-meetingContent-menu-primaryBtn:hover
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #circle{		
		opacity: 0.125;
	}

	.docEditor-meetingContent-menu-primaryBtn:hover
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #arrow{		
		opacity: 0.9;
	}	
}

[data-theme="dark"]{
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #circle{		
		opacity: 0.15;
	}
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #arrow{		
		opacity: 0.85;
	}	
	.docEditor-meetingContent-menu-primaryBtn:hover
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #circle{		
		opacity: 0.2;
	}

	.docEditor-meetingContent-menu-primaryBtn:hover
	.docEditor-meetingContent-menu-primaryBtn-iconContainer svg.icon--name--upArrowCircle #arrow{		
		opacity: 0.95;
	}	

}


//

.docEditor-meetingContent-menu-primaryBtn-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: var(--text500);
}

.docEditor-meetingContent-menu-primaryBtn label{
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingContent-menu-primaryBtn-hiddenFileInput{
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}



//

.docEditor-meetingContent-menu-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

//
// Normal Btn

.docEditor-meetingContent-menu-btn{
	height: 28px;
	padding-right: 8px;
	padding-left: 4px;
	margin-right: -8px;

	border-radius: 3px;

	opacity: 0.75;
	position: relative;
	top: 1px;
}

.docEditor-meetingContent-menu-btn--iconOnly{
	width: 30px;
	height: 30px;
	top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: 0px;
}

.docEditor-meetingContent-menu-btn:hover{
	background: var(--250gray);
	opacity: 1;
}

.docEditor-meetingContent-menu-btn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-meetingContent-menu-btn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.75;
}

.docEditor-meetingContent-menu-btn--iconOnly 
.docEditor-meetingContent-menu-btn-iconContainer svg{
	width: 17px;
	height: 17px;
}

.docEditor-meetingContent-menu-btn-label{
	font-size: 15px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;
	color: var(--text500);
}

//
// Toggle

.docEditor-meetingContent-menu-btnToggleSet{
	display: flex;
	margin-left: auto;
}

.docEditor-meetingContent-menu-btnToggle{
	width: 30px;
	height: 30px;
	border-radius: 3px;
}

.docEditor-meetingContent-menu-btnToggle svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
}

.docEditor-meetingContent-menu-btnToggle[data-state="active"]{
	background: var(--250gray);
	opacity: 1;
}

.docEditor-meetingContent-menu-btnToggle[data-state="inactive"]{
	opacity: 0.5;
}

.docEditor-meetingContent-menu-btnToggle[data-state="inactive"]:hover{
	opacity: 0.75;
}



//
// Linked List

.docEditor-meetingContent-linkedProjects-list{
	padding-bottom: 6px;
	margin-top: -6px;
	position: relative;	

	// background: $tempr;

	box-sizing: border-box;
	width: calc(#{$textWidth-doc} + 112px);
	margin-left: -56px;
	padding-left: 56px;
	padding-right: 56px;
}


button.docEditor-meetingContent-linkedProjects-list-addBtn{
	position: absolute;
	bottom: 11px;
	left: 28px;

	z-index: 10;

	width: 20px;
	height: 20px;
	
	background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	transition: opacity 100ms linear;


	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);
}

.docEditor-meetingContent-linkedProjects-list:hover
button.docEditor-meetingContent-linkedProjects-list-addBtn{
	opacity: 1;
	transition-delay: 50ms;
}

.docEditor-meetingContent-linkedProjects-list-addBtnContainer[data-state="open"]{
	pointer-events: none;
}

.docEditor-meetingContent-linkedProjects-list-addBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.6;
}

//
//

.docEditor-meetingContent-activeProjects-list{
	margin-top: 10px;

	padding-bottom: 6px;
	position: relative;	

	// background: $tempr;

	box-sizing: border-box;
	width: calc(#{$textWidth-doc} + 112px);
	margin-left: -56px;
	padding-left: 56px;
	padding-right: 56px;
}

.docEditor-meetingContent-linkedProjects-plannedBtn{
	// background: $tempr;
	border-radius: 4px;
	margin-top: 2px;
	margin-bottom: -2px;

	padding-left: 12px;
	padding-right: 12px;
	margin-left: -12px;

	height: 30px;
	font-size: 14.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;

	color: var(--text500);
	opacity: 0.5;
}

[data-theme="dark"] .docEditor-meetingContent-linkedProjects-plannedBtn{
	letter-spacing: 0.012em;
}

.docEditor-meetingContent-linkedProjects-plannedBtn:hover{
	background: var(--250gray);
	opacity: 1;
}


//

.docEditor-meetingContent-addRecording{
	//margin-top: 1px;
	margin-bottom: 6px;
}


//

@import 'DocEditor-MeetingContent-ProjectRow';

@import 'DocEditor-MeetingContent-AddProject';





//
// HIGHLIGHT GRID






@import 'DocEditor-MeetingContent-Highlights';


@import 'DocEditor-MeetingContent-AddToGCal';


@import 'DocEditor-MeetingContent-ActiveProjectsCalendar';




// // .docEditor-meetingContent--newTeamSync{
// // 	border-bottom: 1px solid $invisible;
// // }

// // [data-theme="dark"]{
// // 	.docEditor-meetingContent--newTeamSync{
// // 		border-bottom: 1px solid $invisible;
// // 	}		
// // }


// .docEditor-meetingContent-projectList-headerRow{
// 	margin-bottom: 8px;
// 	cursor: default;
// 	user-select: none;
// }

// .docEditor-meetingContent-menu,
// .docEditor-meetingContent-linkedProjectsList-header{
// 	display: flex;
// 	align-items: center;
	
// 	height: 30px;
// 	// background: $tempg;

// 	width: calc(100% + 7px + 8px);
// 	margin-left: -7px;
// }

// .docEditor-meetingContent-linkedProjectsList-header{
// 	//border-bottom: 1px solid var(--line300);	
// 	height: 32px;
// }

// //
// //

// .docEditor-meetingContent-menu-btn--addProjectLinkFromProjects{
// 	margin-left: auto;
// 	padding: 0px;
// 	padding-right: 0px;
// 	padding-left: 0px;
// 	width: 32px;
// }

// .docEditor-meetingContent-menu-btn--addProjectLinkFromProjects
// .docEditor-meetingContent-menu-btn-iconContainer svg{
// 	// plus button
// 	width: 16px;
// 	height: 16px;
// }

// .docEditor-meetingContent-linkedProjectsList-header{
// 	margin-bottom: 6px;
// }



// @import 'DocEditor-MeetingContent-Buttons';




// .docEditor-meetingContent-projectList{
// 	//margin-bottom: 24px;
// 	width: 100%;
// 	padding-bottom: 12px;
// 	//border-bottom: 1px solid var(--line300);
// }

// .docEditor-meetingContent-linkedProjectsList{
// 	// height: 100px;
// 	width: 100%;
// 	// background: $tempb;
// }




// .docEditor-meetingContent-addRecording-button{
		

// 	border-radius: 4px;

// 	// flex-direction: column;

// 	background: var(--254gray);
// 	border: 1px solid var(--line300);	
// }


// .docEditor-meetingContent-addRecording-button label{
// 	width: 100%;
// 	height: 100%;
// 	cursor: pointer;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;

// 	padding-left: 42px;
// 	padding-right: 42px;
	
// 	padding-top: 9px;
// 	padding-bottom: 8px;
// }

// .docEditor-meetingContent-addRecording-button:hover{
// 	background: var(--252gray);
// 	border: 1px solid var(--line400);
// }

// [data-theme="dark"]{
// 	.docEditor-meetingContent-addRecording-button{
// 		background: var(--253gray);
// 		border: 1px solid var(--line200);
// 	}
// 	.docEditor-meetingContent-addRecording-button:hover{
// 		border: 1px solid var(--line300);
// 	}
// }

// .docEditor-meetingContent-addRecording-button-primaryLabel{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: var(--text500);
// 	letter-spacing: 0.008em;

// 	margin-bottom: 3px;
// }

// .docEditor-meetingContent-addRecording-button-secondaryLabel{
// 	font-size: 13.5px;
// 	font-weight: $lato-regular;
// 	color: var(--text500);
// 	opacity: 0.6;
// }



// .docEditor-meetingContent-addRecording-button-hiddenFileInput{
// 	width: 0.1px;
// 	height: 0.1px;
// 	opacity: 0;
// 	overflow: hidden;
// 	position: absolute;
// 	z-index: -1;
// }