.message-para-projectLinkOuterContainer{
	// margin-left: 2px;
	// margin-right: 2px;


	height: 20px;
	
	position: relative;
	top: 7px;
	margin-top: -7px;


	cursor: pointer;
	
	overflow: hidden;

	display: inline-block;

	// background: $tempr;
	border-radius: 4px;

	position: relative;
	//background: rgb(248,245,252);
	background: var(--253gray);
	border: 1px solid var(--line200);
	border-bottom: 1px solid var(--line300);

	margin-right: 1px;
	margin-left: 1px;
}

.message-para-projectLinkOuterContainer:hover{
	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);
	transition: all 0ms linear;
	transition-delay: 50ms;
}

.message-para-projectLinkOuterContainer.ProseMirror-selectednode{
	background: var(--bg-docEditor);
	border: 1px solid var(--line500);
	border-bottom: 1px solid var(--line600);
}

.message:hover .message-para-projectLinkOuterContainer{
	background: var(--bg-docEditor);	
}

.message-para-projectLinkInnerContainer{
	width: 100%;
	height: 100%;
}

.message-para-projectLink{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	padding-left: 2px;
	padding-right: 2px;
}

.message-para-projectLink-statusContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;

	//display: none; // testing
}

.message-para-projectLink-statusContainer .linearProjectStatusIcon{
	transform: scale(0.9);
}

.message-para-projectLink-markerContainer{
	margin-right: 3px;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	

	font-size: 15px;
}

.message-para-projectLink-markerContainer svg{
	width: 15px;
	height: 15px;
}


//

.message-para-projectLink-title{
	font-weight: $lato-bold;
	//border-bottom: 1px solid rgb(220,220,227); 	

	height: 20.5px;

	margin-right: 2px;
	line-height: 1.25;
}

// .message-para-projectLink:hover 
// .message-para-projectLink-title{
// 	border-bottom: 1px solid rgb(200,200,207); 	
// }

.message-para-projectLink-teamsContainer{
	
	display: flex;
	align-items: center;
	margin-right: 2px;
	margin-left: 3px;

	gap: 2px;

	display: none; // testing
}
.message-para-projectLink-teamTag{
	padding-left: 4px;
	padding-right: 4px;

	height: 17px;

	font-size: 13px;

	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.8;

	background: var(--246gray);

	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-para-projectLink-popoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	
}