.doc-textLink-popover-inputPreview{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding-right: 5px;
}

//

.doc-textLink-popover-inputPreview--web-iconContainer,
.doc-textLink-popover-inputPreview--project-markerContainer,
.doc-textLink-popover-inputPreview--doc-iconContainer,
.doc-textLink-popover-inputPreview--issue-avatarContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 3px;
}
	

.doc-textLink-popover-inputPreview--web-iconContainer,
.doc-textLink-popover-inputPreview--doc-iconContainer{
	width: 18px;
	height: 18px;
}


.doc-textLink-popover-inputPreview--web-iconContainer svg,
.doc-textLink-popover-inputPreview--doc-iconContainer svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.5;
	position: relative;
	// top: -1px;
}

.doc-textLink-popover-inputPreview--doc-iconContainer svg{
	width: 18px;
	height: 18px;
}

//

.doc-textLink-popover-inputPreview--issue-avatarContainer{
	width: 18px;
	height: 18px;
	margin-left: 1px;

}

.doc-textLink-popover-inputPreview--issue-avatar{
	width: 18px;
	height: 18px;
	border-radius: 3px;
}

//

.doc-textLink-popover-inputPreview--project-markerContainer{
	width: 16px;
	height: 16px;
	margin-left: 1px;
	padding-right: 1px;
}

.doc-textLink-popover-inputPreview--project-markerContainer .linearMarker{
	font-size: 15px;
	position: relative;
	top: 1px;
}

.doc-textLink-popover-inputPreview--project-markerContainer .linearMarker svg{
	width: 14px;
	height: 14px;
}

//

.doc-textLink-popover-inputPreview--web-url,
.doc-textLink-popover-inputPreview--project-name,
.doc-textLink-popover-inputPreview--doc-name,
.doc-textLink-popover-inputPreview--issue-identifier{
	//font-size: 14.25px;
	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	text-align: left;

	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc-textLink-popover-inputPreview--web-url{
	position: relative;
	top: -1px;
	letter-spacing: 0.001em;
}

.doc-textLink-popover-inputPreview--issue-identifier{
	font-size: 13.75;
	text-transform: uppercase;
}
