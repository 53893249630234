.docEditor-stashedPanelContainer{
	position: absolute;

	right: 0px;
	top: 0px;
	bottom: 0px;

	background: var(--bg-docEditor);
	border-left: 1px solid var(--sideBorder-messagePanel);
	// border: 1px solid var(--sideBorder-messagePanel);
	//border-right: 1px solid var(--sideBorder-messagePanel);



	flex-grow: 0;
	flex-shrink: 0;

	z-index: 25; // above messages

	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	

	opacity: 1;


	//background: rgb(28,26,32);
	background: var(--bg-docEditor);
	background: rgb(253,252,254);

}

.docEditor-stashedPanel{	
	height: 100%;
	display: flex;
	flex-direction: column;

}

.docEditor-stashedPanel-header{
	height: 37px;
	width: 100%;
	display: flex;
	

	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;	

	display: flex;
	align-items: center;
	
	border-bottom: 1px solid $invisible;
	//border-bottom: 1px solid var(--bottomBorder-header-messagePanel);
	//box-shadow: var(--boxShadow-header-messagePanel);

	padding-left: 12px;
	
	user-select: none;

	position: relative;
	z-index: 10;

	cursor: pointer;
}

.docEditor-stashedPanel-header-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	//letter-spacing: 0.001em;
	color: var(--text400);
	opacity: 1;

	color: var(--text500);
	// color: $white;
	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // opacity: 0.75;
}

.docEditor-stashedPanel-header-hideBtn{
	
	width: 40px;
	height: 100%;		
	position: relative;
	left: -4px;
	top: 0.5px;
	

}




.docEditor-stashedPanel-header-hideBtn-inner{
	width: 24px;
	height: 24px;
	border-radius: 50%;
	// background: $tempb;
	//background: var(--bgSideMenuTabLight);

	display: flex;
	align-items: center;
	justify-content: center;
}



.docEditor-stashedPanel-header-hideBtn svg {
	width: 19px;
	height: 19px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0;
	transition: opacity 50ms linear;
}

.docEditor-stashedPanel-header:hover 
.docEditor-stashedPanel-header-hideBtn svg {
	opacity: 0.5;
}

//

.docEditor-stashedPanel-list{
	width: 100%;
	box-sizing: border-box;
	padding-right: 24px;
	padding-left: 8px;
	padding-top: 12px;

	overflow-y: scroll;

}

.docEditor-stashedPanel-item{
	display: flex;
	width: 100%;

	margin-bottom: 36px;
}

.docEditor-stashedPanel-item-type{
	width: 48px;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.docEditor-stashedPanel-item-type-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}


.docEditor-stashedPanel-item-type-iconContainer svg{
	width: 20px;
	height: 20px;
	fill: var(--text600);
	opacity: 0.4;
}

.docEditor-stashedPanel-item-type-iconContainer svg.icon--name--text{
	width: 16px;
	height: 16px;
}

.docEditor-stashedPanel-item:hover 
.docEditor-stashedPanel-item-type-iconContainer svg{
	opacity: 0.6;
}

//

.docEditor-stashedPanel-item-content-text{
	font-size: 15.25px;	
	
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	
	color: var(--text500);
	line-height: 1.35;
}


.docEditor-stashedPanel-item-contentContainer{
	display: flex;
	flex-direction: column;
}

.docEditor-stashedPanel-item-content-imageOuterContainer{	
	display: flex;
	justify-content: flex-start;
}

.docEditor-stashedPanel-item-content-imageInnerContainer{
	max-height: 400px;	
	min-height: 10px;

	flex-grow: 1;
	flex-shrink: 1;
}

img.docEditor-stashedPanel-item-content-image{
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center left;
	border-radius: 4px;
}



.docEditor-stashedPanel-item-footer{
	width: 100%;
	height: 24px;
	margin-top: 8px;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 13.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.01em;
}

.docEditor-stashedPanel-item-footer-timestamp{
	margin-right: 4px;
	opacity: 0.5;
}

.docEditor-stashedPanel-item-footer-btn{
	opacity: 0;
	transition: opacity 50ms linear;
	padding-left: 8px;
	padding-right: 8px;
}

.docEditor-stashedPanel-item-footer-btn--restore{
	margin-left: auto;
}

.docEditor-stashedPanel-item:hover 
.docEditor-stashedPanel-item-footer-btn{
	opacity: 1;
	// transition-delay: 200ms;
}


.docEditor-stashedPanel-item:hover 
.docEditor-stashedPanel-item-footer-btn--delete{
	opacity: 0.5;
}

.docEditor-stashedPanel-item:hover 
.docEditor-stashedPanel-item-footer-btn--delete:hover{
	opacity: 1;
}


