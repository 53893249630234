.multiPlaygroundSelectionPage{
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding-top: 48px;
	padding-bottom: 48px;
}

.multiPlaygroundSelectionContainer{
	width: 100%;	
	display: flex;
	align-items: center;
	justify-content: center;
}

.multiPlaygroundSelectionContainer--theme--dark{
	background: rgb(28,26,32);
}

.multiPlaygroundSelection{
	width: 650px;
	padding-top: 32px;
	padding-bottom: 32px;

	margin-top: 20px;
	margin-bottom: 20px;

	// background: $tempr;
}

.multiPlaygroundSelection--theme--dark{
	
}


.multiPlaygroundSelection-para{
	position: relative;
	font-size: 16.75px;	
	// font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.45;

	position: relative;
	margin-block-start: 0em;
	margin-block-end: 0em;

	//min-height: 25px; (I'm not sure need this)
	//margin-bottom: 15px; not sure about this

	
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;

	box-sizing: border-box;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para{
	color: $white;
	opacity: 0.9;
}

.multiPlaygroundSelection-para-selection{
	// background: $tempb;
	height: 23px;
	position: absolute;
	border-radius: 1px;
	z-index: 1;
}

.multiPlaygroundSelection-para-selection--top{
	right: 7px;
	top: 1px;
	left: 303px;
}

.multiPlaygroundSelection-para-selection--middle{
	top: 25px;
	right: 19px;
	left: -3px;
}

.multiPlaygroundSelection-para-selection--bottom{
	top: 49px;
	right: 412px;
	left: -3px;
}


.multiPlaygroundSelection-para-docFlag{
	position: absolute;
	left: 236px;
	bottom: 25px;

	height: 46px;
	width: 200px;

	z-index: 10;


	// background: $tempr;

}

.multiPlaygroundSelection-para-docFlag-stem{
	position: absolute;
	left: 0px;
	bottom: 0px;
	top: 20px;
	width: 3px;
	border-radius: 2px;
	background: red;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
}

.multiPlaygroundSelection-para-docFlag-labelContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 24px;
	padding-left: 7px;
	padding-right: 7px;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: red;

	// border-bottom: 1px solid rgba(0,0,0,0.2);
	// box-shadow: inset 0 0 2px rgba(0,0,0,0.2);

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);

	// height: 26px;
	// border-radius: 12px;
	// padding-left: 12px;
	// padding-right: 12px;


}

.multiPlaygroundSelection-para-docFlag-image{
	width: 18px;
	height: 18px;
	border-radius: 4px;
	object-fit: cover;
	margin-right: 4px;
	display: none;


}

.multiPlaygroundSelection-para-docFlag-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $white;

	margin-top: -1.5px;

	// color: $invisible;
}

// .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	transform-origin: -2% 105%;
// 	transform: scale(0.2);
// 	border-radius: 12px;
// }

//
// AMIE COLORS
// red
// $amie-red-dark: rgb(207,56,69);
// $amie-red-mid: rgb(255,88,102);
// $amie-red-light: rgb(255,213,218);

// $amie-orange-dark: rgb(225,229,213);
// $amie-orange-mid: rgb(255,149,88);
// $amie-orange-light: rgb(255,229,213);

// $amie-green-dark: rgb(53,176,112);
// $amie-green-mid: rgb(86,224,153);
// $amie-green-light: rgb(214,247,229);

// $amie-blue-dark: rgb(58,130,206);
// $amie-blue-mid: rgb(91,169,255);
// $amie-blue-light: rgb(213,234,255);

// $amie-purple-dark: rgb(135,56,207);
// $amie-purple-mid: rgb(174,88,255);
// $amie-purple-light: rgb(236,213,255);

// $amie-ping-dark: rgb(207,56,191);
// $amie-pink-mid: rgb(255,88,236);
// $amie-pink-light: rgb(255,213,250);


// .multiPlaygroundSelection-para-selection--color--red{
// 	background: $amie-red-light;
// }

// .multiPlaygroundSelection-para-selection--color--blue{
// 	background: $amie-blue-light;
// }

// .multiPlaygroundSelection-para-selection--color--orange{
// 	background: $amie-orange-light;
// }

// .multiPlaygroundSelection-para-selection--color--green{
// 	background: $amie-green-light;
// }

// .multiPlaygroundSelection-para-selection--color--pink{
// 	background: $amie-pink-light;
// }

// .multiPlaygroundSelection-para-selection--color--purple{
// 	background: $amie-purple-light;
// }


// .multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-red-mid;
// }

// .multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-blue-mid;
// }

// .multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-orange-mid;
// }

// .multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-green-mid;
// }

// .multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-pink-mid;
// }

// .multiPlaygroundSelection-para-docFlag--color--purple .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--purple .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $amie-purple-mid;
// }


// $apple-red-default: rgb(255,59,47);
// $apple-red-light: transparentize($apple-red-default, 0.8);

// $apple-orange-default: rgb(255,149,0);
// $apple-orange-light: transparentize($apple-orange-default, 0.8);

// $apple-green-default: rgb(39,205,65);
// $apple-green-light: transparentize($apple-green-default, 0.8);

// $apple-blue-default: rgb(0,122,255);
// $apple-blue-light: transparentize($apple-blue-default, 0.8);

// $apple-purple-default: rgb(175,82,222);
// $apple-purple-light: transparentize($apple-purple-default, 0.8);

// $apple-pink-default: rgb(255,44,85);
// $apple-pink-light: transparentize($apple-pink-default, 0.8);


// .multiPlaygroundSelection-para-selection--color--red{
// 	background: $apple-red-light;
// }

// .multiPlaygroundSelection-para-selection--color--blue{
// 	background: $apple-blue-light;
// }

// .multiPlaygroundSelection-para-selection--color--orange{
// 	background: $apple-orange-light;
// }

// .multiPlaygroundSelection-para-selection--color--green{
// 	background: $apple-green-light;
// }

// .multiPlaygroundSelection-para-selection--color--pink{
// 	background: $apple-pink-light;
// }

// .multiPlaygroundSelection-para-selection--color--purple{
// 	background: $apple-purple-light;
// }


// .multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $apple-red-default;
// }

// .multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $apple-blue-default;
// }

// .multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $apple-orange-default;
// }

// .multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $apple-green-default;
// }

// .multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-stem,
// .multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-labelContainer{
// 	background: $apple-pink-default;
// }






.multiPlaygroundSelection-para-selection--color--red{
	background: var(--accentRed500);
	opacity: 0.25;
}

.multiPlaygroundSelection-para-selection--color--blue{
	background: var(--accentBlue500);
	opacity: 0.25;
}

.multiPlaygroundSelection-para-selection--color--orange{
	background: var(--accentOrange500);
	opacity: 0.25;
}

.multiPlaygroundSelection-para-selection--color--green{
	background: var(--accentGreen500);
	opacity: 0.25;
}

.multiPlaygroundSelection-para-selection--color--pink{
	background: var(--accentPink500);
	opacity: 0.25;
}

.multiPlaygroundSelection-para-selection--color--purple{
	background: var(--accentPurple500);
	opacity: 0.25;
}


.multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--red .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentRed600);
}

.multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--blue .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentBlue600);
}

.multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--orange .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentOrange600);
}

.multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--green .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentGreen600);
}

.multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--pink .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentPink600);
}

.multiPlaygroundSelection-para-docFlag--color--purple .multiPlaygroundSelection-para-docFlag-stem,
.multiPlaygroundSelection-para-docFlag--color--purple .multiPlaygroundSelection-para-docFlag-labelContainer{
	background: var(--accentPurple600);
}


.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--red{
	background: var(--accentRed600);
	opacity: 0.15;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--blue{
	background: var(--accentBlue600);
	opacity: 0.15;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--orange{
	background: var(--accentOrangeSelection);
	opacity: 0.14;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--green{
	background: var(--accentGreen600);
	opacity: 0.15;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--pink{
	//background: var(--accentPink600);
	background: var(--accentPinkSelection);
	opacity: 0.15;
}

.multiPlaygroundSelection--theme--dark
.multiPlaygroundSelection-para-selection--color--purple{
	background: var(--accentPurpleSelection);
	opacity: 0.15;
}

