
//
//
.editMetadataPopover--type--projectIcon-content-colorBtn--color--grey
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--grey--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--lightGrey
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--lightGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--darkGrey
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--darkGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--purple
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--purple--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--teal
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--teal--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--darkTeal
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--darkTeal--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--green
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--green--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--darkGreen
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--darkGreen--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--yellow
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--yellow--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--brown
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--brown--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--orange
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--orange--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--darkOrange
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--darkOrange--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--pink
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--pink--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--red
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--red--yarn);
}
.editMetadataPopover--type--projectIcon-content-colorBtn--color--darkRed
.editMetadataPopover--type--projectIcon-content-colorBtn-tile{
	background: var(--darkRed--yarn);
}

//
//


.editMetadataPopover--type--projectIcon-content-iconGrid--color--grey svg{
	fill: var(--grey--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--lightGrey svg{
	fill: var(--lightGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--darkGrey svg{
	fill: var(--darkGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--purple svg{
	fill: var(--purple--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--teal svg{
	fill: var(--teal--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--darkTeal svg{
	fill: var(--darkTeal--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--green svg{
	fill: var(--green--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--darkGreen svg{
	fill: var(--darkGreen--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--yellow svg{
	fill: var(--yellow--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--brown svg{
	fill: var(--brown--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--orange svg{
	fill: var(--orange--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--darkOrange svg{
	fill: var(--darkOrange--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--pink svg{
	fill: var(--pink--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--red svg{
	fill: var(--red--yarn);
}
.editMetadataPopover--type--projectIcon-content-iconGrid--color--darkRed svg{
	fill: var(--darkRed--yarn);
}

//
//

.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--grey svg{
	fill: var(--grey--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--lightGrey svg{
	fill: var(--lightGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--darkGrey svg{
	fill: var(--darkGrey--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--purple svg{
	fill: var(--purple--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--teal svg{
	fill: var(--teal--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--darkTeal svg{
	fill: var(--darkTeal--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--green svg{
	fill: var(--green--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--darkGreen svg{
	fill: var(--darkGreen--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--yellow svg{
	fill: var(--yellow--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--brown svg{
	fill: var(--brown--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--orange svg{
	fill: var(--orange--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--darkOrange svg{
	fill: var(--darkOrange--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--pink svg{
	fill: var(--pink--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--red svg{
	fill: var(--red--yarn);
}
.editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--darkRed svg{
	fill: var(--darkRed--yarn);
}

//
//