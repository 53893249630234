.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--image,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--figmaEmbed{
	// need to test this out really, I think half extra is good balance
	//height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-imageSelectionPreview} / 2));
	min-height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-imageSelectionPreview}));
}

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--image .docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--image .docEditor-messagePanel-inputBox-placeholder,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--figmaEmbed .docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--figmaEmbed .docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-imageSelectionPreview} + 4px);
}

//
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--video{	
	// height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-videoSelectionPreview} / 2));
	min-height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-videoSelectionPreview}));
}

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--video .docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--video .docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-videoSelectionPreview} + 4px);
}

//

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--message{
	// need to test this out really, I think half extra is good balance
	min-height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-messageSelectionPreview}));
}

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--message .docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--message .docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-messageSelectionPreview} + 4px);
}

//

.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--1.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--1.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-messageSelectionPreview-docSelection1Line} + 4px);
}

.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--2.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--2.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-messageSelectionPreview-docSelection2Lines} + 4px);
}

.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--3.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--3.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection
.docEditor-messagePanel-inputBox-placeholder{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-messageSelectionPreview-docSelection3Lines} + 4px);
}



//

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--todoItem{
	// need to test this out really, I think half extra is good balance
	min-height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-todoItemSelectionPreview}));
}

.docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--todoItem
.docEditor-messagePanel-inputBox-input .ProseMirror{
	padding-top: calc(#{$defaultTopInternalPadding-messagePanel-inputBox} + #{$height-todoItemSelectionPreview} + 4px);
}

// Doc selection preview height is unknown (could be 1 to 3 lines)
// we could measure
// So we guess about 1.5 lines worth
// 3 lines is 64px

// $height-docSelectionPreview: 32px;

// .docEditor-messagePanel-inputBox.docEditor-messagePanel-inputBox--selectionPreviewType--docSelection{
// 	// need to test this out really, I think half extra is good balance
// 	min-height: calc(#{$defaultHeight-messagePanel-inputBox} + (#{$height-docSelectionPreview}));
// }
