.editProjectModalContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.editProjectModalContainer:focus{
	outline: none;
}

.editProjectModalContainer--hidden,
.editProjectModalContainer--hidden *{
	pointer-events: none !important;
}


//

.editProjectModal{
	margin-top: -20vh; // we need this for enough space for the dropdowns

	min-width: 640px;

	box-sizing: border-box;
	padding-top: 26px;
	padding-bottom: 24px;
	padding-left: 34px;
	padding-right: 34px;

	border-radius: 6px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;
}

// PROJECT ICON


.editProjectModal-projectIconRow{
	width: 100%;
	// background: $tempr;
	display: flex;
	justify-content: flex-start;

	position: relative;
	z-index: 10;
}

.editProjectModal-projectIconRow-editBtnContainer{
	width: 50px;
	height: 50px;
	// background: $tempr;
	border-radius: 2px;
}

.editProjectModal-projectIconRow-editBtn{
	width: 100%;
	height: 100%;
	background: var(--253gray);

	border-radius: 5px;
	transition: background 50ms ease-in-out;
}

[data-theme="dark"] {
	.editProjectModal-projectIconRow-editBtn{
		background: var(--252gray);
	}
}

.editProjectModal-projectIconRow-editBtn[data-state="open"],
.editProjectModal-projectIconRow-editBtn:hover{
	background: var(--250gray);
	transition: background 50ms ease-in-out;
}

.editProjectModal-projectIconRow-editBtn[data-state="open"]{
	pointer-events: none;
}

.editProjectModal-projectIconRow-editBtn .linearMarker{
	font-size: 30px;
}

.editProjectModal-projectIconRow-editBtn .linearMarker svg{
	width: 32px;
	height: 32px;
}

.editProjectModal-projectIconRow-editBtn svg.icon--name--plus{
	width: 26px;
	height: 26px;
	fill: var(--text600);
	opacity: 0.2;
	transform: scale(1);
	transition: all 100ms ease-in-out;
}

.editProjectModal-projectIconRow-editBtn:hover svg.icon--name--plus{
	opacity: 0.4;
	transform: all(1.03);
	transition: transform 50ms ease-in-out;
}



//

.editProjectModal-titleRow{
	width: 100%;
	// background: $tempb;
	height: 40px;
	margin-top: 4px;
	position: relative;
}

$editProjectModal-gutterOverhang: 8px;

input.editProjectModal-titleRow-input{
	position: absolute;
	top: 0px;
	left: calc(#{$editProjectModal-gutterOverhang} * -1);
	right: calc(#{$editProjectModal-gutterOverhang} * -1);
	bottom: 0px;

	width: calc(100% + (2 * #{$editProjectModal-gutterOverhang}));

	box-sizing: border-box;
	padding-top: 8px;
	padding-left: $editProjectModal-gutterOverhang;
	padding-right: $editProjectModal-gutterOverhang;

	border: none;
	outline: none;
	box-shadow: none;
	background: none;

	border-radius: 3px;

	// background: $tempb;

	

	//font-size: 21.75px;
	font-size: 20.75px;
	font-weight: $lato-bold;
	//letter-spacing: 0.002em;
	color: var(--text600);
}


//

.editProjectModal-descriptionContainer{
	width: 100%;
	// background: $tempg;
	height: 144px;
	position: relative;
}

.editProjectModal-description{
	position: absolute;
	top: 0px;
	left: calc(#{$editProjectModal-gutterOverhang} * -1);
	right: calc(#{$editProjectModal-gutterOverhang} * -1);
	bottom: 0px;

	resize: none;

	width: calc(100% + (2 * #{$editProjectModal-gutterOverhang}));

	box-sizing: border-box;
	padding-left: $editProjectModal-gutterOverhang;
	padding-right: $editProjectModal-gutterOverhang;


	border: none;
	outline: none;
	box-shadow: none;
	background: none;

	border-radius: 3px;

	// background: $tempb;

	line-height: 1.4;

	font-size: 16.25px;
	font-weight: $lato-regular;
	color: var(--text400);
	opacity: 0.7;
	letter-spacing: 0.008em;
}


//
//

.editProjectModal-footerRow{
	display: flex;
	align-items: center;

	width: calc(100% + 4px);
	margin-left: -4px;
}

//


@import 'EditProjectModal-FooterBtns';

//

.editProjectModal-footerRow-spacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}



//

@import 'EditProjectModal-SubmitBtn';







//
// CLOSE BUTTON
.editProjectModal-closeBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	
	width: 56px;
	height: 56px;

	box-sizing: border-box;

	display: flex;
	align-items: flex-end;
	justify-content: start;

	padding-bottom: 12px;
	padding-left: 12px;

	z-index: 40;
}

.editProjectModal-closeBtn svg{
	width: 24px;
	height: 24px;
	fill: var(--text600);
	opacity: 0.25;

	transition: all 50ms linear;
}

.editProjectModal-closeBtn:hover svg{
	opacity: 0.75;
	transform: scale(1.05);

	transition: all 50ms linear;
}


.editProjectModal-cancelBtn{
	// background: $tempr;

	position: absolute;
	top: 14px;
	right: 20px;
	
	//width: 56px;
	height: 34px;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 12px;
	padding-left: 12px;

	// background: $tempr;

	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text400);

	opacity: 0.6;

	border-radius: 4px;

	z-index: 40;
}

.editProjectModal-cancelBtn:hover{
	background: var(--253gray);
	opacity: 1;
}


//
// DIALOG OVERLAY


.editProjectModalOverlay{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	//background: $tempr;
	
	background: rgba(0,0,0,0.12);
	background: rgba(0,0,0,0.12);
}

.editProjectModalTestOverlay{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;

	background: $tempr;
}


[data-theme="dark"]{
	.editProjectModalOverlay{
		background: rgba(0,0,0,0.24);	
	}
}