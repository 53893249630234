.suggestionMenu--linearIssues{
	width: 420px;
	max-height: 320px; // make automated
	overflow-y: scroll;
	margin-left: -54px; // alignment with title
}

.suggestionMenu-item--linearIssue{	
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestionMenu-item--linearIssue-assignee{
	flex-grow: 0;
	flex-shrink: 0;

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 2px;
	margin-left: -2px;
}

.suggestionMenu-item--linearIssue-assignee-img{
	width: 20px;
	height: 20px;
	border-radius: 4px;

	object-fit: cover;
}

.suggestionMenu-item--linearIssue-statusContainer{
	flex-grow: 0;
	flex-shrink: 0;
	width: 26px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 2px;

}

.suggestionMenu-item--linearIssue-status{
	width: 13px;
	height: 13px;
	border-radius: 50%;

	border: 1.5px solid var(--line600);
	opacity: 0.8;
}

.suggestionMenu-item--linearIssue-status--todo{
	opacity: 0.9;
}

.suggestionMenu-item--linearIssue-status--backlog{
	border-style: dashed;
}

.suggestionMenu-item--linearIssue-status--done{
	width: 13px;
	height: 13px;
	border-radius: 50%;

	border-color: var(--primary400);
	background: var(--primary400);
	opacity: 1;

	display: flex;
	align-items: center;
	justify-content: center;
}

.suggestionMenu-item--linearIssue-status--done svg{
	width: 11px;
	height: 11px;
	stroke-width: 11px;
	stroke: var(--bg-docEditor);
	margin-top: 1px;
}


.suggestionMenu-item--linearIssue.suggestionMenu-item--active{
	opacity: 1;
}

//

.suggestionMenu-item--linearIssue
.suggestionMenu-item-label{
	flex-grow: 1;
	flex-shrink: 1;
	font-size: 15px;
}

.suggestionMenu-item--linearIssue-shortId{
	margin-left: auto;
	margin-right: 9px;

	font-size: 13px;
	font-weight: $lato-regular;
	color: var(--text500);	
	opacity: 0.5;

	height: 100%;
	display: flex;
	align-items: center;
}
