.doc-placeholder{
	user-select: none;
	pointer-events: none;

	color: var(--text200);
	opacity: 0.8;

	position: relative;
	height: 0px;
}

.doc-placeholder-innerContainer{
	position: absolute;
	top: -3px; // to optically align with first line of text
	left: 0px;
}


.doc-placeholder-key{
	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;

	height: 22px;
	min-width: 12px; // plus the 8 for padding

	border-radius: 3px;
	// border: 1px solid var(--line300);
	// border-bottom: 1px solid var(--line400);

	background: var(--250gray);

	margin-left: 8px;
	margin-right: 8px;

	box-sizing: border-box;

	font-weight: $lato-semibold;
}


.doc-placeholder-key--noMargin{
	margin-left: -4px;
	margin-right: 8px;
}

.doc-placeholder-key--iconOnly{
	width: 22px;
}

.doc-placeholder-key--period{
	font-size: 24px;
	box-sizing: border-box;
	padding-bottom: 8px;
}


.doc-placeholder-key--label{
	padding-left: 6px;
	padding-right: 6px;	
	height: 24px;
}

	

.doc-placeholder-key svg{
	width: 16px;
	height: 16px;

	fill: var(--text300);
}

.doc-placeholder .doc-para{
	display: flex;
	align-items: center;
	height: 30px;

	color: var(--text300);
}

.doc-placeholder-key-label{
	font-size: 15px;
	font-weight: $lato-semibold;
}

.doc-placeholder-key-label--iconBefore{
	margin-left: 4px;
}

//

.doc-placeholder-break--small{
	height: 4px;
	width: 100%;
	//background: $tempb;
}

.doc-placeholder-break--big{
	height: 24px;
	width: 100%;
	//background: $tempr;
}