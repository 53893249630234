//
// PROJECT HEADER MARKER

.docEditor-projectMarkerTopSpacer{
	height: 1px;
	width: 100%;
	padding-top: 84px;

	position: relative;
	
}

.docEditor-projectMarker{
	width: 72px;
	height: 72px;
	position: absolute;
	top: -84px;
	// top: -94px;
	
	left: 0px;
	left: -9px;

	display: flex;
	align-items: center;
	//justify-content: flex-start;

	justify-content: center;

	user-select: none;

	cursor: pointer;
}

.docEditor-projectMarker
.linearMarkerContainer--markerType--emoji{
	font-size: 52px;
}

.docEditor-projectMarker
.linearMarkerContainer--markerType--icon svg{
	width: 46px;
	height: 46px;
}

.docEditor-projectMarker .linearMarker,
.docEditor-projectMarker svg.icon--name--plus{
	position: relative;
	z-index: 5;
}


.docEditor-projectMarker svg.icon--name--plus{
	width: 36px;
	height: 36px;
	fill: var(--text500);
	opacity: 0.25;
	transform: scale(1);
	transition: all 100ms ease-in-out;
}

.docEditor-projectMarker:hover svg.icon--name--plus,
.docEditor-projectMarker[data-state="open"] svg.icon--name--plus{
	opacity: 0.4;
	transform: scale(1.08);
	transition: all 100ms ease-in-out;
	transition-delay: 150ms;
}
	




.docEditor-projectMarker-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 6px;

	background: var(--253gray);
	opacity: 0;
	transition: opacity 75ms linear;
}

.docEditor-projectMarker--noIcon
.docEditor-projectMarker-bg{
	opacity: 0.75;
}

.docEditor-projectMarker:hover
.docEditor-projectMarker-bg{
	opacity: 1;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}

.docEditor-projectMarker[data-state="open"]
.docEditor-projectMarker-bg{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
	background: var(--250gray);
}

