
//
//
.docEditor-messagePanel-header-dndButtonContainer{
	// margin-left: auto;

	flex-grow: 0;
	flex-shrink: 0;

	width: 33px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 5px;
	margin-left: 3px;
	padding-left: 4px;

	border-left: 1px solid var(--sideBorder-header-messagePanel-dndButton);

	-webkit-app-region: no-drag;
}

.docEditor-messagePanel-header-dndButton{
	width: 28px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
	border-radius: 5px;
	
	// cursor: pointer;

	position: relative;
	z-index: 10;
	
	transition: opacity 100ms linear;
}

.docEditor-messagePanel-header-dndButton{
	opacity: 0.8;
}

.docEditor-messagePanel-header-dndButton:hover{
	opacity: 1;
}

.docEditor-messagePanel-header-dndButton-circle{
	border-radius: 50%;

	width: 16px;
	height: 16px;
	background: red;

	position: relative;
}

.docEditor-messagePanel-header-dndButton-circle-inner{
	border-radius: 50%;

	position: absolute;
	width: 16px;
	height: 16px;

	right: -5px;
	top: -5px;

	background: #797979;
}


.tooltip--dndButton{
	//hacky
	margin-top: -4px;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	padding-top: 3px;
	padding-bottom: 3px;
}

.tooltip--dndButton-light{
	opacity: 0.5;
	margin-top: 1px;
}
