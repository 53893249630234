.doc-para-projectLinkContainer{
	// margin-left: 2px;
	// margin-right: 2px;
	display: inline-block;

	white-space: pre-wrap;
	word-break: break-word;

	cursor: pointer;
	
	position: relative;
	//top: 0.08em;
	//margin-bottom: -0.02em;

	// margin-right: 0.1em;
	margin-left: 0.1em;

	// margin-right: 1px;
	// margin-left: 1px;

	//background: $tempr;
}


.doc-para-projectLinkContainer.ProseMirror-nodeInSelection .doc-para-projectLink,
.doc-para-projectLinkContainer.ProseMirror-nodeInSelection:hover .doc-para-projectLink{
	background: var(--textSelectionBlue);
	box-shadow: 0px 0px 0px 2px var(--textSelectionBlue);
	border-radius: 0px;
}	


.doc-para-projectLinkContainer.ProseMirror-selectednode .doc-para-projectLink,
.doc-para-projectLinkContainer.ProseMirror-selectednode.ProseMirror-nodeInSelection .doc-para-projectLink,
.doc-para-projectLinkContainer .doc-para-projectLink:hover{
	background: var(--250grayTransparent);	
	box-shadow: 0px 0px 0px 2px var(--250grayTransparent);
	border-radius: 2px;
	// border: 1px solid var(--line500);
	// border-bottom: 1px solid var(--line600);
}




.doc-para-projectLinkContainer.ProseMirror-nodeSearchResult .doc-para-projectLink,
.doc-para-projectLinkContainer.ProseMirror-nodeSearchResult .doc-para-projectLink:hover{
	background: var(--searchResultBG);
	box-shadow: 0px 0px 0px 2px var(--searchResultBG);
	border-radius: 2px;
}


.doc-para-projectLinkContainer.ProseMirror-nodeSearchResult--active .doc-para-projectLink,
.doc-para-projectLinkContainer.ProseMirror-nodeSearchResult--active .doc-para-projectLink:hover{
	animation: activeResultAnimation 220ms ease-in forwards;
	border-radius: 2px;
}







.doc-para-projectLink:hover{
	transition: all 0ms linear;	
	transition-delay: 50ms;
}


[data-theme="dark"]{
	.doc-para-projectLinkContainer.ProseMirror-selectednode .doc-para-projectLink{
		background: var(--246gray);
		box-shadow: 0px 0px 0px 2px var(--246gray);
	}
}

//

.doc-para-projectLink-spacer{
	display: inline-block;
	white-space: nowrap;
	width: 0.01em;

	background: $tempr;
}

.doc-para-projectLink-statusContainer{
	// width: 1.1em;
	// height: 1.1em;
	
	// position: relative;

	// display: inline-block;
	// overflow: visible;

	// object-fit: contain;
	
	// vertical-align: middle;

	// background: $tempr;


	display: inline-block;

	vertical-align: 0.1em;
	//margin-top: -0.3em;

	margin-left: 0.1em;
	
	position: relative;
	z-index: 5;
	margin-right: 0.3em;

	transform: scale(0.9);
	transform-origin: 50% 50%;

	width: 12px;
	height: 12px;
}


.doc-para-projectLink-statusContainer .linearProjectStatusIcon{
	position: absolute;
	
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	height: 100%;
	// display: none;
}

//

.doc-para-projectLink-markerContainer{
	// // margin-right: 3px;

	width: 1.1em;
	height: 1.1em;

	position: relative;
	top: -0.1em;

	margin-right: 0.2em;
	margin-left: 0.15em;
	
	position: relative;

	// display: inline-block;
	overflow: visible;

	object-fit: contain;
	
	vertical-align: middle;

	// background: $tempb;
}

.doc-para-projectLink-markerContainer .inlineLinearMarker{
	font-size: 15px;
}

.doc-para-projectLink-markerContainer svg{
	width: 15px;
	height: 15px;
}


// Selected text styling
.doc-para-projectLinkContainer.ProseMirror-nodeInSelection:not(.ProseMirror-selectednode) .linearProjectStatusIcon--planned,
.doc-para-projectLinkContainer.ProseMirror-nodeInSelection:not(.ProseMirror-selectednode) .linearProjectStatusIcon--started{
	background: none;
}



//

.doc-para-projectLink-title{
	white-space: pre-wrap; 
	word-break: break-word;

	margin-right: 0.1em;
	margin-left: 0.2em;

	position: relative;
	//top: -0.08em;
	
	font-size: 16.5px;	

	font-weight: $lato-bold;
	letter-spacing: 0.005em;

	color: var(--text500);
	line-height: 1.45;

	border-bottom: 1px solid var(--220gray); 	
}

span.doc-span--style--light
.doc-para-projectLink-title{
	color: var(--text200);	
}

span.doc-span--style--light .doc-para-projectLink:hover .doc-para-projectLink-title,
span.doc-span--style--light .doc-para-internalLinkContainer.ProseMirror-selectednode .doc-para-internalLink-title{
	color: var(--text500); // turn back normal on hover
}


.doc-para-projectLink:hover  .doc-para-projectLink-title,
.doc-para-projectLinkContainer.ProseMirror-selectednode .doc-para-projectLink .doc-para-projectLink-title,
.doc-para-projectLinkContainer.ProseMirror-selectednode .doc-para-projectLink:hover .doc-para-projectLink-title{
	border-bottom: 1px solid var(--210gray); 	
}



.doc-para-projectLink:hover 
.doc-para-projectLink-title{
	border-bottom: 1px solid var(--210gray);
}

.doc-para-projectLinkContainer.doc-span--comment--active .doc-para-projectLink-title{
	border-bottom: 1px solid rgba(255,212,0,0.9);	
}



.doc-para-projectLink-popoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	
}