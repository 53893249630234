
.projectsPage-header-topBar-selectRoadmap{
	display: flex;
	margin-left: -6px; // offset padding	
}

.projectsPage-header-topBar-selectRoadmap-roadmap{
	flex-grow: 0;
	flex-shrink: 0;

	min-width: 34px;

	position: relative;
	z-index: 50;

	height: 30px;

	cursor: pointer;

	pointer-events: auto;

	font-size: $fontSize-topMenuBtn;
	font-weight: $lato-semibold;
	color: var(--text350);

	border-radius: 5px;

	// background: $tempr;

	padding-left: 6px;
	padding-right: 6px;

	-webkit-app-region: no-drag;


}

.projectsPage-header-topBar-selectRoadmap-roadmap--active{
	opacity: 1;
}

.projectsPage-header-topBar-selectRoadmap-roadmap--inactive{
	opacity: 0.5;
}

.projectsPage-header-topBar-selectRoadmap-roadmap--inactive:hover{
	opacity: 0.85;
}

// ICON

.projectsPage-header-topBar-selectRoadmap-roadmap-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;

	margin-right: 4px;
	margin-left: -2px;
}

.projectsPage-header-topBar-selectRoadmap-roadmap-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text350);
	opacity: 0.8;
}
