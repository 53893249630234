.tabBar-actionMenu{	
	height: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	margin-left: auto;

	display: flex;

	padding-right: 12px;

	-webkit-app-region: no-drag;
}


////
//// NEW DOC


button.tabBar-actionMenu-actionBtn{
	margin-top: 5px;
	height: 32px;
	display: flex;
	align-items: center;

	cursor: default;
	
	background: $invisible;

	border: none;
	box-shadow: none;

	border-radius: 4px;

	padding-left: 9px;
	padding-right: 9px;

	position: relative;
	z-index: 10;
}

.tabBar-actionMenu-actionBtn-iconContainer{
	width: 24px;
	height: 24px;
	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;
}

.tabBar-actionMenu-actionBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--primary500);
}

.tabBar-openTab-iconContainer svg{
	width: 18px;
	height: 18px;
	margin-right: 4px;
}

//

.tabBar-actionMenu-actionBtn-label{
	font-weight: $lato-medium;
	font-size: 14px;
	letter-spacing: 0.01em;
}

button.tabBar-actionMenu-actionBtn{
	color: var(--text100);
	opacity: 0.5;
}

.tabBar-actionMenu:hover
button.tabBar-actionMenu-actionBtn{
	opacity: 0.6;
} 



// STATES

.tabBar-actionMenu-actionBtn-iconContainer svg{
	fill: var(--text200);
}

.tabBar-actionMenu:hover
button.tabBar-actionMenu-actionBtn:hover{
	color: var(--text600);
	opacity: 0.8;
}

button.tabBar-actionMenu-actionBtn:hover svg{
	fill: var(--text600);
}


// //// NEW TAB

// button.tabBar-newTab{
// 	width: 44px;
// 	height: 100%;
// 	background: none;
// 	border: none;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	// background: $tempr;

// 	opacity: 0.4;
// 	-webkit-app-region: no-drag;
// }

// button.tabBar-newTab:hover{
// 	//background: $tempr;
// 	background: rgba(0,0,0,0.05);
// 	opacity: 0.8;
// }


// button.tabBar-newTab .icon{
// 	width: 18px;
// 	height: 18px;
// 	fill: $black;
// }


// .tabBar-actionsMenu{
// 	width: 200px;
// 	height: 100%;
// 	background: $tempr;

// 	flex-grow: 0;
// 	flex-shrink: 0;
// }
