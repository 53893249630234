.recordingPlaybackModal-transcript-highlightGutter{
	position: absolute;
	top: 0px;
	top: 16px;
	right: 4px; // because this is the padding to hide the scrollbar

	z-index: 20;

	// display: none;
}

.recordingPlaybackModal-transcript-highlightGutter-marker{
	position: absolute;
	top: 0px;
	right: 0px;

	width: 20px;
	width: 24px;

	display: flex;
	
	align-items: flex-start;
	justify-content: flex-start;

	// background: $tempr;
}

// .recordingPlaybackModal-transcript-highlightGutter-marker--indented{
// 	width: 6px;
// }

.recordingPlaybackModal-transcript-highlightGutter-marker--hovered{
	cursor: pointer;
}


.recordingPlaybackModal-transcript-highlightGutter-marker-indicator{
	display: flex;
	align-items: center;
	//justify-content: flex-end;
	justify-content: flex-end;

	height: 22px;
	width: 22px;

	// margin-right: 16px;
	
	// background: $tempr;

	position: relative;
	right: -4px;
	top: -1px;

	// margin-left: -8px;
	// margin-top: -22px;

	box-sizing: border-box;
	//padding-right: 5px;
	// padding-left: 4px;
	// padding-right: 12px;
	//border-radius: 3px;

	position: relative;

	//background: $tempr;

	display: none;
}

.recordingPlaybackModal-transcript-highlightGutter-marker-indicator--isMultiLine{
	margin-top: 4px;
}

// .recordingPlaybackModal-transcript-highlightGutter-marker--indented
// .recordingPlaybackModal-transcript-highlightGutter-marker-indicator{
// 	display: none;	
// }

.recordingPlaybackModal-transcript-highlightGutter-marker--active
.recordingPlaybackModal-transcript-highlightGutter-marker-indicator-label{
	font-weight: $lato-medium;
}


.recordingPlaybackModal-transcript-highlightGutter-marker-indicator-iconContainer svg{
	width: 19px;
	height: 19px;
	position: relative;
	//top: 0.07em;
	
}

//

.recordingPlaybackModal-transcript-highlightGutter-marker-edge{
	position: absolute;
	
	//left: 2px;
	right: 4px;
	right: 2px;
	//top: 22px;
	top: 0px;
	bottom: 0px;
	width: 2px;

	border-radius: 3px;
}

// .recordingPlaybackModal-transcript-highlightGutter-marker--singleLine.recordingPlaybackModal-transcript-highlightGutter-marker--notIndented
// .recordingPlaybackModal-transcript-highlightGutter-marker-edge{
// 	visibility: hidden;
// }


//
// COLORS

// .recordingPlaybackModal-transcript-highlightGutter-marker--active
// .recordingPlaybackModal-transcript-highlightGutter-marker-indicator{
// 	background: var(--250gray);
// }

// // Label

// .recordingPlaybackModal-transcript-highlightGutter-marker--noUnreads.recordingPlaybackModal-transcript-highlightGutter-marker--inactive
// .recordingPlaybackModal-transcript-highlightGutter-marker-indicator-label{
// 	color: var(--text500);
// 	opacity: 0.8;
// }

// .recordingPlaybackModal-transcript-highlightGutter-marker--noUnreads.recordingPlaybackModal-transcript-highlightGutter-marker--inactive:hover
// .recordingPlaybackModal-transcript-highlightGutter-marker-indicator-label{	
// 	opacity: 1;
// }

// .recordingPlaybackModal-transcript-highlightGutter-marker--noUnreads.recordingPlaybackModal-transcript-highlightGutter-marker--active
// .recordingPlaybackModal-transcript-highlightGutter-marker-indicator-label{
// 	color: var(--text600);
// 	opacity: 1;
// }

// Default SVG

.recordingPlaybackModal-transcript-highlightGutter-marker--inactive
.recordingPlaybackModal-transcript-highlightGutter-marker-indicator-iconContainer svg{
	// fill: var(--text600);
	fill: $highlightYellow;
	// opacity: 0.5;
	opacity: 0.75;
}

.recordingPlaybackModal-transcript-highlightGutter-marker--inactive:hover
.recordingPlaybackModal-transcript-highlightGutter-marker-indicator-iconContainer svg{
	// fill: var(--text600);
	fill: $highlightYellow;
	opacity: 1;
}

.recordingPlaybackModal-transcript-highlightGutter-marker--active
.recordingPlaybackModal-transcript-highlightGutter-marker-indicator-iconContainer svg{
	fill: $highlightYellow;
	opacity: 1;
}





//
// Edge

.recordingPlaybackModal-transcript-highlightGutter-marker--inactive
.recordingPlaybackModal-transcript-highlightGutter-marker-edge{
	background: var(--line400);
	background: var(--primary500);
	background: $highlightYellow;
	opacity: 0.75;
	// opacity: 0.5;
}

.recordingPlaybackModal-transcript-highlightGutter-marker--inactive:hover
.recordingPlaybackModal-transcript-highlightGutter-marker-edge{
	opacity: 1;
}

.recordingPlaybackModal-transcript-highlightGutter-marker--active
.recordingPlaybackModal-transcript-highlightGutter-marker-edge{
	background: var(--text200);
	background: var(--primary500);
	background: $highlightYellow;
}





//
// POPOVER

.recordingPlaybackModal-transcript-highlightGutter-marker-popoverTrigger{
	position: absolute;
	top: 0px;
	left: 0px;
	right: -20px;
	bottom: 0px;
	width: calc(100% + 20px);
	height: 100%;
	// pointer-events: none !important;
	// background: $tempr;
}

//

.highlightGutterMarkerPopoverContainer{
	position: absolute;
	top: 0px;
	left: -20px;

//	background: $tempr;
	// width: 300px;
	// height: 200px;
}

.highlightGutterMarkerPopoverContainer:focus{
	outline: none;
}

.highlightGutterMarkerPopoverContainer{
	pointer-events: none !important;
}


.popover.highlightGutterMarkerPopover{
	margin-left: 2px;

	width: 380px;
	max-height: 70vw;

	overflow: hidden;
	
	//min-height: 370px;

	box-sizing: border-box;

	//padding-top: 12px;
	padding-top: 8px;
	//padding-bottom: 16px;
	padding-bottom: 8px;

	padding-left: 4px;
	padding-right: 4px;

	cursor: default;
	user-select: none;

	border-radius: 4px;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	background: rgb(12,10,13);
	border: var(--outsideLine-dropdown);
	//border-top: var(--outsideTopLine-dropdown-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	border-radius: 6px;

	box-shadow: var(--boxShadow-dropdown);

	color: var(--text600);

	pointer-events: auto !important;
}


.highlightGutterMarkerPopover-description{
	width: 100%;

	box-sizing: border-box;

	padding-left: calc(10px + 41px); // 41 is for the avatar
	padding-right: 12px;
	padding-top: 10px;
	padding-bottom: 8px;

	border-top-right-radius: 2px;
	border-bottom-left-radius: 2px;

	background: none;

	position: relative;

	cursor: pointer;

	
}

// .highlightGutterMarkerPopover-description:hover{	
// 	background: var(--messageHoverBG);
// 	background: rgba(255,255,255,0.03);
// }


.highlightGutterMarkerPopover-description-avatarContainer{
	position: absolute;
	left: 10px; // messy
	top: 10px;
	// background: $tempr;
}

.highlightGutterMarkerPopover-description-avatar{
	width: 32px;
	height: 32px;
	// background: $tempr;
	border-radius: 4px;
}

.highlightGutterMarkerPopover-description-header{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 1px;

	cursor: pointer;
	user-select: none;
}

.highlightGutterMarkerPopover-description-header-name{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-right: 6px;
}

.highlightGutterMarkerPopover-description-header-subtitle{
	font-size: 12px;
	font-weight: $lato-medium;
	color: var(--text200);
}

.highlightGutterMarkerPopover-description-description{
	min-height: 60px;
}

.highlightGutterMarkerPopover-description .message-para{
	cursor: pointer;
}

.highlightGutterMarkerPopover-description-deleteBtn{
	// background: var(--250gray);
	color: var(--text500);
	opacity: 0.4;
	font-size: 14.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;


	// margin-left: auto;
	margin-left: -9px;
	padding-left: 8px;
	padding-right: 8px;
	height: 30px;

	// margin-right: -6px;
	margin-top: 2px;
	margin-bottom: -2px;

	border-radius: 3px;

	cursor: pointer;

}


.highlightGutterMarkerPopover-description-deleteBtn:hover{
	background: transparentize($red, 0.9);
	color: rgb(221,70,117);
	opacity: 1;
}