
$orgButtonBottomMargin: 4px;
//
// TAB

.sideMenu-tab{
	flex-grow: 0;
	flex-shrink: 0;

	// // FULL WIDTH
	// width: 100%;

	// NOT FULL WIDTH
	width: calc(100% - 16px);
	//margin-left: 6px;
	margin-left: 8px;
	border-radius: 5px;

	height: var(--heightSideTab);

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 7px;
	padding-right: 6px;

	cursor: pointer !important;

	user-select: none;

	position: relative;
}

// .sideMenu-tab--dragging{
// 	//opacity: 0.6 !important;
// 	background: rgba(255,255,255,0) !important;
// 	background: rgb(255,255,255) !important;
// 	z-index: 999;
// 	outline: 1px solid rgb(220,220,220);
// 	box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.12);
// }

.sideMenu-tab--inactive{
	background: none;
}

.sideMenu-tab--inactive:hover{
	background: var(--bgSideMenuTabHover);
}

.sideMenu-tab--active{	
	background: var(--bgSideMenuTabActive);
}

.sideMenu-tab--active:hover{
	background: var(--bgSideMenuTabActiveHover);
}

// PRIVATE SHOULD BE SEPARATED OFF
// TO DO: DO THIS NICER

// .sideMenu-tab.sideMenu-tab--tabType--librarySection:last-child{
// 	margin-top: 14px;
// }


.sideMenu-tab--newDoc{
	opacity: 0.5;
	// margin-top: 4px;
}

.sideMenu-tab--newDoc:hover{
	opacity: 1;
}

//

.sideMenu-tab-noIconSpacer{
	width: 3px;
	flex-grow: 0;
	flex-shrink: 0;
}

.sideMenu-tab-iconContainer{
	width: 22px;
	height: 22px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 5px;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
  z-index: 5;
}

// LINEAR MARKER


.sideMenu-tab-iconContainer
.linearMarkerContainer--markerType--emoji{
	font-size: 15.5px;
}

.sideMenu-tab-iconContainer
.linearMarkerContainer--markerType--icon svg{
	width: 15.5px;
	height: 15.5px;
}


.sideMenu-tab-iconContainer--roadmap svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.6;
}

// Milestone
.sideMenu-tab-iconContainer--milestone{
	margin-right: 2px;
}

.sideMenu-tab-iconContainer--milestone .linearMilestoneIcon{
	transform: scale(0.95);
	transform-origin: 50% 100%;
	position: relative;
	top: -1px;
}



//
// Meeting Doc
.sideMenu-tab-iconContainer--meetingDoc{
	margin-right: 2px;
}

.sideMenu-tab-iconContainer--meetingDoc svg{
	width: 17px;
	height: 17px;

	//transform: scale(1.02);
	//transform-origin: 50% 50%;	
}

.sideMenu-tab-label.sideMenu-tab-label--dateTime{
	opacity: 0.55;
	margin-left: 4px;

	flex-grow: 0;
	flex-shrink: 100000 !important;
}




//
// APP TABS

.sideMenu-tab--inactive.sideMenu-tab--tabType--appTab .sideMenu-tab-label{
	font-weight: var(--textWeightSideMenuAppTab);
	letter-spacing: var(--textSpacingSideMenuAppTab);
	// color: var(--text600);
}

.sideMenu-tab--active.sideMenu-tab--tabType--appTab .sideMenu-tab-label{
	font-weight: var(--textWeightSideMenuAppTabActive);
	letter-spacing: var(--textSpacingSideMenuAppTabActive);
	// color: var(--text600);
}


.sideMenu-tab--tabType--appTab svg{
	fill: var(--text500);	
}

.sideMenu-tab--tabType--appTab svg.icon--name--clock{
	width: 19px;
	height: 19px;
}

.sideMenu-tab--tabType--appTab.sideMenu-tab--inactive svg{
	opacity: 0.73;
	// opacity: 0.5;
}

.sideMenu-tab--tabType--appTab.sideMenu-tab--active svg{
	opacity: 1;
	// opacity: 0.8;
}

[data-theme="dark"]{
	.sideMenu-tab--tabType--appTab.sideMenu-tab--inactive svg{
		opacity: 0.60;
		// opacity: 0.45;
	}
	.sideMenu-tab--tabType--appTab.sideMenu-tab--active svg{
		opacity: 0.95;
		// opacity: 0.8;
	}
}


.sideMenu-tab-iconInnerContainer{
	width: 24px;
	height: 24px;

	margin-left: 0px;

	

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;

	position: relative;
}

.sideMenu-tab-iconInnerContainer-bg{
	position: absolute;
	top: 1px;
	left: 1px;
	right: 1px;
	bottom: 1px;

	//border-radius: 3px/
	// opacity: 0.08;
	// display: none;
}


.sideMenu-tab-iconInnerContainer svg{
	width: 22px;
	height: 22px;

	transform: scale(0.95);

	position: relative;
	z-index: 5;
}



.sideMenu-tab--appTab--support svg.icon--name--slackIcon{
	width: 14px;
	height: 14px;
	position: relative;
	left: -0.5px;
}


.sideMenu-tab--appTab--support svg.icon--name--linkArrow,
.sideMenu-tab--appTab--help svg.icon--name--linkArrow{
	width: 16px;
	height: 16px;
	position: relative;
	//left: -0.5px;
}

.sideMenu-tab--appTab--support svg.icon--name--slackIcon,
.sideMenu-tab--appTab--help svg.icon--name--helpDocs{
	display: block;
}

.sideMenu-tab--appTab--support svg.icon--name--linkArrow,
.sideMenu-tab--appTab--help svg.icon--name--linkArrow{
	display: none;
}

.sideMenu-tab--appTab--support:hover svg.icon--name--slackIcon,
.sideMenu-tab--appTab--help:hover svg.icon--name--helpDocs{
	display: none;
}

.sideMenu-tab--appTab--support:hover svg.icon--name--linkArrow,
.sideMenu-tab--appTab--help:hover svg.icon--name--linkArrow{
	display: block;
}

//

.sideMenu-tab--appTab--avSettings
.sideMenu-tab-iconInnerContainer svg{
	width: 14px;
	height: 14px;
	position: relative;
	left: -0.5px;
}


.sideMenu-tab--appTab--help
.sideMenu-tab-iconInnerContainer svg{
	width: 16px;
	height: 16px;
	position: relative;

	transform: scale(1);
	//left: -0.5px;
	// top: 0.25px;
}

.sideMenu-tab--appTab--templates
.sideMenu-tab-iconInnerContainer svg{
	width: 19px;
	height: 19px;

	transform: scale(1);
}


.sideMenu-tab--appTab--support,
.sideMenu-tab--appTab--help,
.sideMenu-tab--appTab--avSettings,
.sideMenu-tab--appTab--templates{
	opacity: 0.7;
}

.sideMenu-tab--appTab--support:hover,
.sideMenu-tab--appTab--help:hover,
.sideMenu-tab--appTab--avSettings:hover,
.sideMenu-tab--appTab--templates:hover{
	opacity: 1;
}

.sideMenu-tab--inactive.sideMenu-tab--appTab--support .sideMenu-tab-label,
.sideMenu-tab--inactive.sideMenu-tab--appTab--help .sideMenu-tab-label,
.sideMenu-tab--inactive.sideMenu-tab--appTab--avSettings .sideMenu-tab-label,
.sideMenu-tab--inactive.sideMenu-tab--appTab--templates .sideMenu-tab-label{
	font-size: var(--fontSizeSideMenuTab);
	font-weight: var(--textWeightSideMenuTab);

	color: var(--textColorSideMenuTab);
	
	letter-spacing: var(--textSpacingSideMenuTab);
}
	




// HOME TAB IMAGE

.sideMenu-tab--tabType--homeTab
.sideMenu-tab-iconContainer{
	background: $tempr;

	border-radius: 5px;
	background: var(--bgSideMenuTabHover);
}

.sideMenu-tab--tabType--homeTab
.sideMenu-tab-iconContainer img{
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	object-fit: contain;
}

//
// SUBDOC INDICATOR
.sideMenu-tab-subDocIndicator{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -3px;
	margin-right: 3px;
}

.sideMenu-tab-subDocIndicator svg{
	width: 12px;
	height: 12px;
	fill: var(--text500);
	opacity: 0.3;
}

//
// LABEL

.sideMenu-tab-labelContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  z-index: 5;
}

.sideMenu-tab-label{	
	font-size: var(--fontSizeSideMenuTab);
	font-weight: var(--textWeightSideMenuTab);

	color: var(--textColorSideMenuTab);
	
	letter-spacing: var(--textSpacingSideMenuTab);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 0;
  flex-shrink: 1;
}

.sideMenu-tab--inactive:hover
.sideMenu-tab-label{
	color: var(--textColorSideMenuTabHover);
}

.sideMenu-tab--active
.sideMenu-tab-label{
	font-weight: var(--textWeightSideMenuTabActive);
	color: var(--textColorSideMenuTabActive);
	letter-spacing: var(--textSpacingSideMenuTabActive);	
}

.sideMenu-tab--inactive.sideMenu-tab--tabType--homeTab
.sideMenu-tab-label{
	font-weight: $lato-semibold;
}

// DEV NOTIFICATIONS
.sideMenu-tab-devNotificationContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 4px;
	padding-right: 2px;
	padding-top: 2px;
	padding-bottom: 2px;

	display: flex;

	background: rgba(0,0,0,0.6);
	border-radius: 4px;

	font-size: 12px;
	color: $white;

	font-weight: $lato-semibold;
}

.sideMenu-tab--inactive
.sideMenu-tab-devNotificationContainer{
	opacity: 0.75;
}

.sideMenu-tab:hover
.sideMenu-tab-devNotificationContainer{
	opacity: 0;
}

.sideMenu-tab-devNotification{
	margin-right: 3px;
}

//
// NOTIFICATIONS

.sideMenu-tab-notificationContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 4px;
}

.sideMenu-tab-notification{
	height: 18px;
	min-width: 25px;
	min-width: 22px;

	padding-left: 5px;
	padding-right: 5px;
	

	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	//border-radius: 9px;	
	//border-radius: 3px;	
	border-radius: 4px;	

	font-size: 12.75px;	
	font-size: 13.5px;	
	font-weight: $lato-bold;

	color: var(--textColorNotificationSideMenuTab);	
	color: $white;
	//background: var(--bgNotificationSideMenuTab);
	//background: var(--text300);	

	background: #FF4D42; // apple

}

// [data-theme="dark"]{
// 	.sideMenu-tab-notification{
// 		color: $white;
// 		background: var(--bgNotificationSideMenuTab);
// 	}
// }

.sideMenu-tab--inactive
.sideMenu-tab-notificationContainer{
	//opacity: 0.75;
}


//
// ACTIONS
// close and dropdown
.sideMenu-tab-actionsContainer{
	display: flex;

	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 4px;
	margin-right: -3px;

	position: relative;
	z-index: 20; // above context menu
}

.sideMenu-tab-actionBtn{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;	

	border-radius: 5px;


}

.sideMenu-tab-actionBtn:hover{
	background: var(--bgSideMenuTabHover);
}

.sideMenu-tab-actionBtn svg{
	width: 17px;
	height: 17px;
	fill: var(--iconColorSideMenuChannelTab);
}

.sideMenu-tab-actionBtn svg.icon--name--crossSmall{
	width: 15px;
	height: 15px;
}


.sideMenu-tab-actionBtn:hover svg{
	fill: var(--iconColorSideMenuTabActive);
}

//
// SEARCH ON ALL DOCS
.sideMenu-tab--showSearchBtn{	
	// width: calc(100% - 45px);
	// background: $tempr;
	// border-top-right-radius: 2px;
	// border-bottom-right-radius: 2px;
}


.sideMenu-tab--showSearchBtn.sideMenu-tab--active ~ .sideMenu-searchBtn,
.sideMenu-tab--showNewDraftBtn.sideMenu-tab--active ~ .sideMenu-newDraftBtn,
.sideMenu-tab--showNewDraftBtn.sideMenu-tab--active ~ .sideMenu-newMeetingBtn,
.sideMenu-tab--appTab--support.sideMenu-tab--active ~ .sideMenu-keyboardBtn{
	opacity: 0.75;
}


.sideMenu-searchBtn,
.sideMenu-newDraftBtn,
.sideMenu-keyboardBtn,
.sideMenu-newMeetingBtn{
	position: absolute;
	top: 0px;
	// top: 28px;
	height: var(--heightSideTab);
	width: 32px;
	right: 6px;

	z-index: 50;

	// background: $tempr;
	border-radius: 5px;
	// border-top-left-radius: 2px;
	// border-bottom-left-radius: 2px;
}

.sideMenu-newDraftBtn{
	top: calc(var(--heightSideTab) + #{$orgButtonBottomMargin}); // lame, should refactor
}

.sideMenu-newMeetingBtn{
	top: calc((var(--heightSideTab) * 2) + #{$orgButtonBottomMargin})
}

.sideMenu-keyboardBtn{
	top: unset;
	bottom: 8px;	
}



.sideMenu-searchBtn:hover,
.sideMenu-newDraftBtn:hover,
.sideMenu-keyboardBtn:hover,
.sideMenu-newMeetingBtn:hover{
	background: var(--bgSideMenuTabHover);
}

.sideMenu-searchBtn:active,
.sideMenu-newDraftBtn:active,
.sideMenu-keyboardBtn:active,
.sideMenu-newMeetingBtn:active{
	background: var(--bgSideMenuTabActive);	
}

.sideMenu-searchBtn svg,
.sideMenu-newDraftBtn svg,
.sideMenu-keyboardBtn svg,
.sideMenu-newMeetingBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--iconColorSideMenuTabActive);
	opacity: 0.65;
	transform: scale(1.0);
}

.sideMenu-newDraftBtn svg{
	transform: scale(0.96);
}

.sideMenu-newMeetingBtn svg{
	transform: scale(0.92);
}

.sideMenu-searchBtn:hover svg,
.sideMenu-newDraftBtn:hover svg,
.sideMenu-keyboardBtn:hover svg,
.sideMenu-newMeetingBtn:hover svg{
	opacity: 1;
}

.sideMenu-searchBtn:active svg,
.sideMenu-newDraftBtn:active svg,
.sideMenu-keyboardBtn:active svg,
.sideMenu-newMeetingBtn:active svg{
	opacity: 1;
}

.sideMenu-newMeetingBtn{
	opacity: 0;
	transition: opacity 50ms linear;
}

.sideMenu:hover .sideMenu-newMeetingBtn{
	opacity: 0.75;
}

.sideMenu:hover .sideMenu-newMeetingBtn:hover{
	opacity: 1;
}

.sideMenu-tab--appTab--sideMenuMeetingNotes.sideMenu-tab--active ~ 
.sideMenu-newMeetingBtn{
	display: none;
}

//
// HIDE AND SHOW ACTIONS AND NOTIFICATIONS

.sideMenu-tab--showTabActions:hover .sideMenu-tab-notificationContainer{
	display: none;
}

.sideMenu-tab-actionsContainer{
	display: none;
	// visibility: hidden;
}

.sideMenu-tab--showTabActions:hover
.sideMenu-tab-actionsContainer{
	// visibility: visible;
	display: flex;
}

.sideMenu-tab-contextMenuAnchor{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 10;
	border-radius: 5px;
}

.sideMenu-tab-contextMenuAnchor[data-state='open']{
	background: var(--bgSideMenuTabHover);
	z-index: 1;
}

.sideMenu-tab-moveDocPopoverAnchor{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 1px;
	background: none;
	z-index: 20;
	pointer-events: none;
}

//
// EXTRA BTN CONTAINER

.sideMenu-tabGroup--fixed-bottomRightActions{
	position: absolute;
	bottom: 12px;
	right: 8px;

	display: flex;
	// background: $tempr;

}

.sideMenu-tabGroup--fixed-bottomRightActions
.sideMenu-tab-actionBtn{
	cursor: pointer;

	width: 30px;
	height: 28px;

	// margin-left: 4px;
	// background: $tempr;
}

.sideMenu-tabGroup--fixed-bottomRightActions
.sideMenu-tab-actionBtn svg{
	width: 16px;
	height: 16px;
}

// .sideMenu-tabGroup--fixed
// .sideMenu-tab:last-child{
// 	// background: red !important;

// 	position: relative;
// 	//width: calc(100% - 12px);
// 	width: calc(100% - 74px);
// }

//
// MOVE DOC ANCHOR

.sideMenu-tab-moveDocAnchor{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 1px;
	pointer-events: none;
}

//
// ORG TAB
.sideMenu-tab--tabType--orgTab{
	margin-bottom: $orgButtonBottomMargin;
	//width: calc(100% - 44px);
}

.sideMenu-tab--tabType--orgTab
.sideMenu-tab-label{
	font-weight: $lato-bold;
	// color: var(--text600);
	letter-spacing: var(--textSpacingSideMenuTabActive);
}

.sideMenu-tab--tabType--orgTab-orgLogoContainer{
	width: 23px;
	height: 23px;

	margin-left: -4px;
	margin-right: 8px;
	// margin-top: -1px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.sideMenu-tab--tabType--orgTab-orgLogo{
	width: 18px;
	height: 18px;
	transform: scale(0.88);
	transform-origin: center center;
}

img.sideMenu-tab--tabType--orgTab-orgLogo-image{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 3px;
}

// Demo Account

.sideMenu-tab--tabType--orgTab-orgLogo--demoAccount-avatar{
	width: 100%;
	height: 100%;
	border-radius: 2px;

	background: var(--accentPurple100);
	outline: 2px solid var(--accentPurple100);
	margin-left: -2px;

	color: var(--accentPurple600);

	font-size: 14px;
	font-weight: $lato-heavy;

	display: flex;
	align-items: center;
	justify-content: center;
}

:root[data-theme="dark"]{
	.sideMenu-tab--tabType--orgTab-orgLogo--demoAccount-avatar{
		color: lighten(#B578DD, 5%);
		font-weight: $lato-bold;
		background: transparentize(#B578DD, 0.85);
		outline: 2px solid transparentize(#B578DD, 0.85);
	}
}




.sideMenu-header-orgBtn-iconContainer{
	flex-grow: 0;
	flex-shrink: 0;

	width: 24px;
	height: 24px;
	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: auto;

	padding-right: 6px;

	position: relative;
  z-index: 5;
}

.sideMenu-header-orgBtn-iconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text600);
	opacity: 0.6;
}

.sideMenu-header-orgBtn
.sideMenu-header-orgBtn-iconContainer{
	opacity: 0;
}

.sideMenu-header-orgBtn:hover
.sideMenu-header-orgBtn-iconContainer{
	opacity: 0.75;
}

.sideMenu
.sideMenu-header-orgBtn[data-state="open"]
.sideMenu-header-orgBtn-iconContainer{
	opacity: 1;
}