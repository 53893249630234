.roadmapsHomePage-quarter-timeline-timeline-projectContainer{
	width: 100%;
	height: 36px;
	// background: $tempr;
	position: relative;
	margin-bottom: 14px;
	// /margin-bottom: 18px;
	//background: rgba(0,0,0,0.01);
}

.roadmapsHomePage-quarter-timeline-timeline-project{
	z-index: 210;
}



.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer{
	width: 100%;
	height: 100%;
	
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 6px;

	user-select: none;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 

  border-radius: 4px;

  background: var(--bg-docEditor);
	border: 1px solid var(--line375);
	// border-bottom: 1px solid var(--line400);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);
}


.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--openEnd{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-right: none;
}

.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--previousStart{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--futureEnd{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-previousStartLine,
.roadmapsHomePage-quarter-timeline-timeline-project-futureEndLine{
	position: absolute;	
	top: -2px;
	bottom: -1px;
	width: 1px;
	
	background: var(--line375);
}

.roadmapsHomePage-quarter-timeline-timeline-project-previousStartLine{
	left: 0px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndLine{
	right: 0px;
}


[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-previousStartLine,
	.roadmapsHomePage-quarter-timeline-timeline-project-futureEndLine{
		background: rgb(57,56,65);
	}	
}


.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer:hover{
	border: 1px solid var(--line400);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.06);
}

.roadmapsHomePage-quarter-timeline-timeline-project--dragging
.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer,
.roadmapsHomePage-quarter-timeline-timeline-project--dragging
.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer:hover{
	border: 1px solid var(--line500);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.06);	
}


.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--openEnd:hover{
	border-right: none;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer{
		//background: rgba(255,255,255,0.02);
		background: rgba(35,33,40,1);
		//border: 1px solid rgba(255,255,255,0.10);		
		border: 1px solid lighten(rgb(57,56,65), 3%);		
	}	
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-contentContainer:hover{
		//background: rgba(255,255,255,0.02);
		background: lighten(rgba(35,33,40,1), 1%);
		border: 1px solid lighten(rgb(57,56,65), 5%);	
	}	
}


// .roadmapsHomePage-quarter-timeline-timeline-project-avatarContainer{
// 	width: 22px;
// 	height: 22px;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	margin-right: 6px;
// }

// .roadmapsHomePage-quarter-timeline-timeline-project-avatar{
// 	border-radius: 3px;
// }

.roadmapsHomePage-quarter-timeline-timeline-project-iconContainer{
	width: 22px;
	height: 22px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-iconContainer
.linearMarker{
	font-size: 16px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-iconContainer
.linearMarker svg{
	width: 16px;
	height: 16px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-name{
	font-size: 15.5px;
	font-size: 16.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.005em;
	color: var(--text500);

	flex-grow: 1;
	flex-shrink: 1;

	//background: $tempr;
	height: 100%;
	padding-left: 4px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;   

  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-name{
		letter-spacing: 0.008em;	
	}	
}


.roadmapsHomePage-quarter-timeline-timeline-project:hover 
.roadmapsHomePage-quarter-timeline-timeline-project-name{
	opacity: 1;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-name{
		letter-spacing: 0.02em;
	}
}

.roadmapsHomePage-quarter-timeline-timeline-project-name-label{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;   

}

.roadmapsHomePage-quarter-timeline-timeline-project-name:hover{
	color: var(--text600);
	opacity: 1;
}


//

.roadmapsHomePage-quarter-timeline-timeline-project-openEndContainer{
	position: absolute;
	top: 0px;
	left: 100%;
	bottom: 0px;

	// background: $tempr;
	z-index: 10;
	pointer-events: none;
}

.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer{
	position: absolute;
	top: 0px;
	left: -1px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg{
	height: 36px;
	width: 15px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #bg{
		//fill: red;
		fill: var(--bg-docEditor);
	}

	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #border{
		fill: var(--line375);
	}

	.roadmapsHomePage-quarter-timeline-timeline-project:hover 
	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #bg{
		fill: var(--bg-docEditor);
	}


	.roadmapsHomePage-quarter-timeline-timeline-project:hover 
	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #border{
		fill: var(--line400);
	}


[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #bg{
		//fill: red;
		fill: rgba(35,33,44,1);
	}

	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #border{
		fill: rgb(57,56,65);
	}

	.roadmapsHomePage-quarter-timeline-timeline-project:hover 
	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #bg{
		fill: rgba(37,35,47,1);
	}


	.roadmapsHomePage-quarter-timeline-timeline-project:hover 
	.roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer svg #border{
		fill: rgb(64,64,72);		
	}
}




.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg{
	position: absolute;
	top: 0px;
	bottom: 0px;
	height: 100%;
	
	background: var(--bg-docEditor);
	border-top: 1px solid var(--line375);
	border-bottom: 1px solid var(--line375);

	opacity: 0.5;

	box-sizing: border-box;

	pointer-events: none;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg{
		background: rgba(255,255,255,0.02);
		border-top: 1px solid	rgb(57,56,65);
		border-bottom: 1px solid rgb(57,56,65);
	}
}

.roadmapsHomePage-quarter-timeline-timeline-project-endDate{
	position: absolute;
	left: 100%;

	cursor: pointer;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endDate--closeToEnd{
	left: unset;
	right: 12px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endDate,
.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
	height: 100%;
	display: flex;
	align-items: center;

	transition: opacity 50ms linear;

	

	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text500);
	letter-spacing: 0.005em;

	opacity: 0;

	user-select: none;
	
}

.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
	opacity: 0.5;
	padding-left: 20px;
	cursor: pointer;
	pointer-events: auto;
}


[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-project-endDate,
	.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
		letter-spacing: 0.02em;
	}
}




.roadmapsHomePage-quarter-timeline-timeline-projectContainer:hover
.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
	opacity: 0.6;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}

.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg[data-state="open"]
.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
	opacity: 0.8;
}

.roadmapsHomePage-quarter-timeline-timeline-project--dragging ~
.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg
.roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label{
	opacity: 0 !important;
	transition: opacity 100ms linear;
	transition-delay: 0ms;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endDate{
	margin-left: 10px;
	opacity: 0.4;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endDate[data-state="open"]{
	opacity: 0.8;
}


.roadmapsHomePage-quarter-timeline-timeline-projectContainer:hover
.roadmapsHomePage-quarter-timeline-timeline-project-endDate{
	opacity: 0.5;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}

//
// FUTURE END DATE INDICATOR

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate{
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;
	margin-right: -6px;
	padding-right: 6px;
	transition: opacity 100ms linear;

	width: 100px;
}

.roadmapsHomePage-quarter-timeline-timeline-project:hover
.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate{
	opacity: 0.4;
	transition: opacity 100ms linear;
	transition-delay: 200ms;
}

.roadmapsHomePage-quarter-timeline-timeline-project:hover
.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate:hover{
	opacity: 0.4;
	transition: opacity 100ms linear;
	transition-delay: 0ms;
}

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate[data-state="open"]{
	opacity: 0.6;
}

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	display: none;
}

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.8;
	margin-left: 2px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate-label{
	font-size: 14.5px;
	font-weight: $lato-regular;
	color: var(--text600);
	letter-spacing: 0.008em;
}


//
// END HANDLE

.roadmapsHomePage-quarter-timeline-timeline-project-startHandle,
.roadmapsHomePage-quarter-timeline-timeline-project-endHandle{
	width: calc(100% + 14px);
	margin-left: -7px;
	height: 100%;
	
	position: relative;
	z-index: 20;
	cursor: grab;

	display: flex;
	align-items: center;

	opacity: 0;
}

.roadmapsHomePage-quarter-timeline-timeline-project-startHandle{
	justify-content: flex-end;
	// background: $tempb;	
}

.roadmapsHomePage-quarter-timeline-timeline-project-endHandle{
	justify-content: flex-start;
	// background: $tempr;
}

.roadmapsHomePage-quarter-timeline-timeline-project--dragging .roadmapsHomePage-quarter-timeline-timeline-project-endHandle,
.roadmapsHomePage-quarter-timeline-timeline-project--dragging .roadmapsHomePage-quarter-timeline-timeline-project-startHandle{
	cursor: grabbing;
}

//.roadmapsHomePage-quarter-timeline-timeline-project--dragging .roadmapsHomePage-quarter-timeline-timeline-project-startHandle,
.roadmapsHomePage-quarter-timeline-timeline-projectContainer:hover .roadmapsHomePage-quarter-timeline-timeline-project-startHandle,
//.roadmapsHomePage-quarter-timeline-timeline-project--dragging .roadmapsHomePage-quarter-timeline-timeline-project-endHandle,
.roadmapsHomePage-quarter-timeline-timeline-projectContainer:hover .roadmapsHomePage-quarter-timeline-timeline-project-endHandle{
	opacity: 1;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endHandle-bar,
.roadmapsHomePage-quarter-timeline-timeline-project-startHandle-bar{
	height: calc(100% - 12px);
	width: 4px;
	border-radius: 2px;
	border: 1px solid var(--line300);
	background: var(--bg-docEditor);
}

.roadmapsHomePage-quarter-timeline-timeline-project-startHandle-bar{
	margin-right: 3px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endHandle-bar{
	margin-left: 3px;
}

.roadmapsHomePage-quarter-timeline-timeline-project-endHandle:hover
.roadmapsHomePage-quarter-timeline-timeline-project-endHandle-bar{
	border: 1px solid var(--line400);
}