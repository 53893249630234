
.doc-textLink-hoverMenu{

	height: 26px;

	background: var(--bgFloatingMenu);
	
	//border-radius: $borderRadius-btn-selectionMenu;
	border-radius: 4px;
	
	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border-bottom: 1px solid var(--outsideBottomLineFloatingMenu);

	box-shadow: var(--boxShadowFloatingMenu);

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 5px;
	padding-right: 3px;

	cursor: default;

}

//
//

.doc-textLink-hoverMenu-link{
	display: flex;
	align-items: center;
	justify-content: center;
}

//

.doc-textLink-hoverMenu-link--web-iconContainer,
.doc-textLink-hoverMenu-link--project-markerContainer,
.doc-textLink-hoverMenu-link--doc-iconContainer,
.doc-textLink-hoverMenu-link--issue-avatarContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 5px;
}
	

.doc-textLink-hoverMenu-link--web-iconContainer,
.doc-textLink-hoverMenu-link--doc-iconContainer{
	width: 15px;
	height: 15px;
}

.doc-textLink-hoverMenu-link--web-iconContainer svg,
.doc-textLink-hoverMenu-link--doc-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.5;
	position: relative;
	top: -1px;
}

.doc-textLink-hoverMenu-link--issue-avatarContainer{
	width: 18px;
	height: 18px;
}

.doc-textLink-hoverMenu-link--issue-avatar{
	width: 18px;
	height: 18px;
	border-radius: 2px;
}

.doc-textLink-hoverMenu-link--project-markerContainer{
	width: 14px;
	height: 14px;
}

.doc-textLink-hoverMenu-link--project-markerContainer .linearMarker{
	font-size: 14px;
	position: relative;
	top: 1px;
}

.doc-textLink-hoverMenu-link--project-markerContainer .linearMarker svg{
	width: 14px;
	height: 14px;
}

//

.doc-textLink-hoverMenu-link--web-url,
.doc-textLink-hoverMenu-link--project-name,
.doc-textLink-hoverMenu-link--doc-name,
.doc-textLink-hoverMenu-link--issue-identifier{
	//font-size: 14.25px;
	font-size: 14px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;

	color: var(--text600);
	opacity: 0.8;

	text-align: left;

	cursor: default;


	max-width: 300px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc-textLink-hoverMenu-link--web-url{
	position: relative;
	top: -1px;
	letter-spacing: 0.001em;
}

.doc-textLink-hoverMenu-link--issue-identifier{
	font-size: 13.75;
	text-transform: uppercase;
}


//
// Edit Button

.doc-textLink-hoverMenu-editBtn{
	width: 24px;
	height: 24px;
	
	border-radius: 4px;

	margin-left: 2px;
}

.doc-textLink-hoverMenu-editBtn:hover{
	background: var(--250gray);
}

.doc-textLink-hoverMenu-editBtn svg{
	width: 14px;
	height: 14px;
	fill: var(--text600);
	opacity: 0.5;
}




//
// HOVER MENU


// .doc-textLink-hoverMenuContainer{
// 	display: none;
// }

// .doc-textLink:hover .doc-textLink-hoverMenuAnchor .doc-textLink-hoverMenuContainer,
// .doc-textLink .doc-textLink-hoverMenuAnchor:hover .doc-textLink-hoverMenuContainer,
// .doc-textLink-hoverMenuContainer:hover{
// 	display: block;
// }

// .doc-textLink-hoverMenu{
// 	opacity: 0;

// 	transition: opacity 50ms linear;
// 	transition-delay: 100ms;
// }
// .doc-textLink:hover .doc-textLink-hoverMenu,
// .doc-textLink-hoverMenuContainer:hover .doc-textLink-hoverMenu{
// 	opacity: 1;

// 	transition: opacity 50ms linear;
// 	transition-delay: 200ms;
// }
// .doc-textLink-hoverMenuAnchor{
// 	position: absolute;
// 	left: 0px;
// 	right: 0px;
// 	top: 100%;

// 	height: 5px;

// 	z-index: 10;
// }

// .doc-textLink-hoverMenuContainer{
// 	position: absolute;
// 	top: 3px;
// 	height: 28px;

// 	left: 50%;
// 	transform: translateX(-50%);
// 	max-width: 400px;

// 	// background: $tempr;
// }
