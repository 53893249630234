.messagePanel-preloadMessageContainer{
	width: 100%;
	// background: $tempr;
	
	box-sizing: border-box;
	padding-left: 14px;	
	padding-right: 14px;

	padding-top: 24px;
	padding-bottom: 14px;

	cursor: default;
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	// justify-content: center;
}

.messagePanel-preloadMessage{
	width: 100%;

	max-width: 420px;

	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;
	padding-bottom: 12px;
	padding-top: 12px;

	padding-bottom: 2px;

	border-radius: 6px;
	//background: var(--253gray);

	// border: 1px solid var(--line300);
	// border-bottom: 1px solid var(--line400);

	// box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
}

.messagePanel-preloadMessage-emoij{
	font-size: 30px;
	margin-bottom: 9px;
}

.messagePanel-preloadMessage-title{
	//font-size: 15.5px;
	font-size: 16.25px;
	font-weight: $lato-bold;
	color: var(--text500);

	margin-bottom: 6px;
}

.messagePanel-preloadMessage-description{
	display: flex;
	flex-direction: column;
	
	gap: 4px;
}

.messagePanel-preloadMessage-description-para{
	font-size: 14.75px;
	// font-size: 15.25px;
	font-weight: $lato-regular;
	color: var(--text300);

	letter-spacing: 0.004em;
	line-height: 1.25;

	margin-bottom: 6px;

}

