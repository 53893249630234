.whiteboard-toolbar-propertiesRow{
	height: 36px;
	position: relative;

	display: flex;
	align-items: center;
}

.whiteboard-toolbar-propertiesRow-topBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 1px;
	width: 100%;
	background: var(--line100);
}


.whiteboard-toolbar-propertiesRow-btn{
	width: 28px;
	height: 28px;
	border-radius: 3px;
	// background: $tempr;
}

.whiteboard-toolbar-propertiesRow-btn[data-state="inactive"]:hover{
	background: var(--250gray);
}

.whiteboard-toolbar-propertiesRow-btn[data-state="active"]{
	background: var(--240gray);
}

.whiteboard-toolbar-propertiesRow-btn:disabled{
	background: none;
	opacity: 0.25;
}

//

.whiteboard-toolbar-propertiesRow-btn svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
}

.whiteboard-toolbar-propertiesRow-btn[data-state="inactive"] svg{
	opacity: 0.7;
}

.whiteboard-toolbar-propertiesRow-btn[data-state="inactive"]:hover svg{
	opacity: 0.9;
}

.whiteboard-toolbar-propertiesRow-btn[data-state="active"] svg{
	opacity: 1;
}

//var(--background-whiteboardsToolbar);

.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingFillTransparent #frontBG{
	display: none;
}

.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingFillSolid #frontBG{
	fill: var(--background-whiteboardsToolbar);
}

.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingFillFill #frontBG{
	//fill: var(--background-whiteboardsToolbar);
	fill: lighten(rgb(36,33,42), 25%);
}


.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingLineScribble,
.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingLineStraight,
.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingLineDashed,
.whiteboard-toolbar-propertiesRow-btn svg.icon--name--drawingLineDotted{
	transform: scale(1.1);
}


//


.whiteboard-toolbar-propertiesRow-divider{
	width: 9px;
	flex-grow: 0;
	flex-shrink: 0;
}


//

.whiteboard-toolbar-propertiesRow-btn-color{
	width: 18px;
	height: 18px;
	border-radius: 3px;
	background: var(--248gray);
	transform-origin: center center;
	transform: scale(0.95);

	//border: 1px solid red;
}

[data-theme="dark"]{
	.whiteboard-toolbar-propertiesRow-btn-color{
		opacity: 0.85; // a bit too bright
	}
}

//





.whiteboard-toolbar-propertiesRow-btn-color--black{
	background: var(--tldrawBlack);
	background: var(--tldrawBlackFill);
	background: var(--tldrawBlackMid);
	//border-color: var(--tldrawBlack);
}

.whiteboard-toolbar-propertiesRow-btn-color--grey{
	background: var(--tldrawGrey);
	background: var(--tldrawGreyFill);
	background: var(--tldrawGreyMid);
	//border-color: var(--tldrawGrey);
}

.whiteboard-toolbar-propertiesRow-btn-color--blue{
	background: var(--tldrawBlue);
	background: var(--tldrawBlueFill);
	background: var(--tldrawBlueMid);
	//border-color: var(--tldrawBlue);
}

.whiteboard-toolbar-propertiesRow-btn-color--lightBlue{
	background: var(--tldrawLightBlue);
	background: var(--tldrawLightBlueFill);
	background: var(--tldrawLightBlueMid);
	//border-color: var(--tldrawLightBlue);
}

.whiteboard-toolbar-propertiesRow-btn-color--orange{
	background: var(--tldrawOrange);
	background: var(--tldrawOrangeFill);
	background: var(--tldrawOrangeMid);
	//border-color: var(--tldrawOrange);
}

.whiteboard-toolbar-propertiesRow-btn-color--red{
	background: var(--tldrawRed);
	background: var(--tldrawRedFill);
	background: var(--tldrawRedMid);
	//border-color: var(--tldrawRed);
}

.whiteboard-toolbar-propertiesRow-btn-color--green{
	background: var(--tldrawGreen);
	background: var(--tldrawGreenFill);
	background: var(--tldrawGreenMid);
	//border-color: var(--tldrawGreen);
}

.whiteboard-toolbar-propertiesRow-btn-color--lightViolet{
	background: var(--tldrawLightViolet);
	background: var(--tldrawLightVioletFill);
	background: var(--tldrawLightVioletMid);
	//border-color: var(--tldrawLightViolet);
}