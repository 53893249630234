// REACTIONS

.docEditor-commentsPanel-commentGroup-comment-reactions{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100px;
	height: 20px;

	margin-top: 6px;
	margin-left: -4px;
	
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-reaction{

	width: 40px;
	height: 22px;
	border-radius: 10px;	

	display: flex;
	align-items: center;
	justify-content: center;	

	border: 1.5px solid $invisible;
	background: rgb(251,249,249);

	cursor: pointer;

	// I think this might be for new or something
	// border: 1.5px solid transparentize($blue, 0.5);
	// background: transparentize($blue, 0.95);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-reaction:hover{
	//border: 1.5px solid transparentize($blue, 0.2);
	border: 1.5px solid var(--primary400);
	//background: transparentize($blue, 0.9);
	background: var(--primary50);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-reaction:active{
	//border: 1.5px solid transparentize($blue, 0.15);
	border: 1.5px solid var(--primary450); // inexact match
	//background: transparentize($blue, 0.8);	
	background: var(--primary100);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-reaction:hover
.docEditor-commentsPanel-commentGroup-comment-reactions-reaction-count{
	//color: darken($blue, 15%)
	color: var(--primary800);
}

.docEditor-commentsPanel-commentGroup-comment-reactions-reaction-emoji{
	margin-right: 4px;
	font-size: 15px;
}

.docEditor-commentsPanel-commentGroup-comment-reactions-reaction-count{
	font-weight: $lato-bold;
	font-size: 13px;
	color: var(--text300);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-reaction:hover
.docEditor-commentsPanel-commentGroup-comment-reactions-reaction-count{	
	//color: darken($blue, 10%);
	color: var(--primary700);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-addReactionBtn{
	width: 34px;
	height: 25px;

	padding: 0px;

	border-radius: 12.5px;

	background: none;
	border: none;
	box-shadow: none;

	background: rgb(244,244,244);

	margin-left: 5px;

	cursor: pointer;

	opacity: 0;
}

.docEditor-commentsPanel-commentGroup-comment:hover
button.docEditor-commentsPanel-commentGroup-comment-reactions-addReactionBtn{
	opacity: 1;
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-addReactionBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text400);	
	margin-top: 2px;
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-addReactionBtn:hover{
	//background: transparentize($blue, 0.9);
	background: var(--primary50);
}

button.docEditor-commentsPanel-commentGroup-comment-reactions-addReactionBtn:hover svg{
	//fill: darken($blue, 10%);
	fill: var(--primary700);
}