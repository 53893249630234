$width-dropdownMenu: 195px;
$height-dropdownMenu-item: 28px;
$width-dropdownMenu-visual: 24px;

//
//
// DROPDOWNS

.dropdownMenu,
.contextMenu,
.selectMenu,
.dropdownMenu-subMenu,
.suggestionMenu,
.suggestionMenu-item--mention--docLink--previewContainer,
.doc-zoomedFigmaModal-status-menu,
.whiteboardContainer .tlui-menu{
	margin-top: 4px;

	width: $width-dropdownMenu;
	
	box-sizing: border-box;
	padding: 0px;
	padding-top: 4px;
	padding-bottom: 8px;
	
	overflow: none;

	background: var(--bg-dropdown);

	border-radius: 5px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;
	
}


.contextMenu,
.whiteboardContainer .tlui-menu{
	width: 210px;
}

.suggestionMenu--insert{
	width: 260px;
}



.suggestionMenu--emoji{
	width: 320px;
}


.dropdownMenu--tableColorSelect{
	width: 206px;
	padding-bottom: 6px;
}

.dropdownMenu--tableColorSelect
.contextMenu-menu-cellColorSelect{
	padding-left: 6px;
	padding-right: 6px;
}

.suggestionMenu--mentions{
	width: 270px;
}

.dropdownMenu--roadmapHomeGridItem{
	width: 160px;
}


.dropdownMenu--settingsContact{
	width: 160px;
}
.dropdownMenu--templateModal{
	width: 200px;
}

// <<<<<<< HEAD
// .dropdownMenu--settingsContact{
// 	width: 160px;
// =======
// .dropdownMenu--templateModal{
// 	width: 200px;
// >>>>>>> main
// }

.dropdownMenu--projectSortOrder{
	width: 160px;
	padding-bottom: 10px;
}

.suggestionMenu--messagePanel{
	
}

.dropdownMenu--roadmapHeader{
	width: 210px; 
}

.contextMenu--inlineIssueViewOptions{
	width: 180px;
}

.suggestionMenu--assign{
	width: 250px;
	margin-left: -30px;
	padding-bottom: 4px;
	margin-top: 0px;
}

.dropdownMenu:focus,
.suggestionMenu:focus{
	outline: none;
}

//
// DROPDOWN MENU ITEM

.dropdownMenu div[role='menuitem'],
.dropdownMenu div[role='menuitemradio'],
.contextMenu div[role='menuitem'],
.selectMenu div[role='menuitem'],
.selectMenu div[role='menuitemradio'],
.contextMenu div[role='menuitemradio'],
.contextMenu-label,
.popoverMenu-item,
.suggestionMenu-item,
.contextMenu-item--disabled-hint,
.doc-zoomedFigmaModal-status-menu-btn,
.whiteboardContainer .tlui-button{
	width: calc(100% - 10px);
	margin-left: 5px;

	background: var(--bg-dropdown);
	height: $height-dropdownMenu-item;
	box-sizing: border-box;
	padding: 0px;
	//padding-left: 20px;
	//font-size: 15px;
	padding-left: 8px;
	font-size: 14.5px;
	letter-spacing: 0.01em;
	margin-bottom: 0px;

	font-weight: $lato-medium;
	color: var(--text500);
	cursor: default;	

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	position: relative;

	cursor: pointer;

	border: none;
	outline: none;
	box-shadow: none;

	border-radius: 4px;

}

.dropdownMenu div[role='menuitem']:hover,
.dropdownMenu div[role='menuitemradio']:hover,
.contextMenu div[role='menuitem']:hover,
.contextMenu div[role='menuitemradio']:hover,
.doc-zoomedFigmaModal-status-menu-btn:hover,
.popoverMenu-item:hover,
.whiteboardContainer .tlui-button:hover{
	// background: $blue;
	// color: $white;

	//background: rgb(244,240,240);
	background: var(--hoverBG-item-dropdown);
	color: var(--text600);
	//color: $white;

	border: none;
	outline: none;
	box-shadow: none;
}

.suggestionMenu-item--active,
.suggestionMenu-item--active:hover,
.doc-zoomedFigmaModal-status-menu-btn--active,
.doc-zoomedFigmaModal-status-menu-btn--active:hover,
.popoverMenu-item--active,
.popoverMenu-item--active:hover{
	background: var(--activeBG-suggestionItem) !important // dangerous;
}

// //
// .dropdownMenu--projectSortOrder div[role='menuitem']{
// 	justify-content: flex-end;
// 	padding-right: 8px;
// }

//
// DISABLED
.dropdownMenu div[role='menuitem'].dropdownMenu-item--disabled,
.contextMenu div[role='menuitem'].contextMenu-item--disabled{
	pointer-events: none;
	opacity: 0.5;
}

//
// LIGHT
.contextMenu div[role='menuitem'].contextMenu-item--light,
.contextMenu div[role='menuitemradio'].contextMenu-item--light,
.dropdownMenu div[role='menuitem'].dropdownMenu-item--light{
	opacity: 0.6;
}
.contextMenu div[role='menuitem'].contextMenu-item--light:hover,
.contextMenu div[role='menuitemradio'].contextMenu-item--light:hover,
.contextMenu div[role='menuitemradio'].contextMenu-item--light:hover{
	opacity: 1;
}

.dropdownMenu div[role='menuitem'].dropdownMenu-item--light{
	opacity: 0.7;
}

//
// WARNING

.dropdownMenu div[role='menuitem'].dropdownMenu-item--warning:hover,
.contextMenu div[role='menuitem'].contextMenu-item--warning:hover{
	background: transparentize($red, 0.9);
	color: darken($red, 30%);
}


// WRAPPED
.dropdownMenu div[role='menuitem'].dropdownMenu-item--wrapped{
	height: 47px;
	//background: $tempr;
	line-height: 1.3;
	padding-top: 4px;
}

//RADIO

.dropdownMenu div[role='menuitemradio'][data-state='checked']{
	//background: $blue;
	background: var(--primary500);
	color: $white;
}

.dropdownMenu div[role='menuitemradio'][data-state='checked']:hover{
	//background: darken($blue, 5%);
	background: var(--primary600);
	color: $white;
}

// CHECKMARK
.contextMenu div[role='menuitemradio']{
	display: flex;
}

.contextMenu div[role='menuitemradio'][data-state='checked']{
	font-weight: $lato-bold;
}

.contextMenu div[role='menuitemradio'][data-state='unchecked']{
	// opacity: 0.8;
}

.contextMenu div[role='menuitemradio'] span[data-state='checked']{
	margin-left: auto;
	background: rgba(0,0,0,0.03);
	width: 22px;
	height: 22px;

	border-radius: 5px;

	margin-right: 4px;
	margin-top: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.contextMenu div[role='menuitemradio'] span[data-state='checked'] svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
}


// KEYBOARD SHORTCUT

.dropdownMenu-item-keyboardShortcutContainer{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.dropdownMenu-item-keyboardShortcutContainer
.keyboardShortcutTag{
	display: flex;
	opacity: 0.5;
	margin-right: 2px;
}

.dropdownMenu-item-keyboardShortcutContainer
.keyboardShortcutTag-item--icon:first-child{
	margin-right: 3px;
}

.dropdownMenu-item-keyboardShortcutContainer
.keyboardShortcutTag-item--icon svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
}

.dropdownMenu-item-keyboardShortcutContainer
.keyboardShortcutTag-item--icon svg.icon--name--arrowLeft{
	width: 15px;
	height: 15px;
	fill: var(--text500);
}

// LABEL

.contextMenu-label{
	opacity: 0.72;
	cursor: default;
	pointer-events: none;
}

.contextMenu-label-visual{
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.contextMenu-label-visual--channelHash{
	width: 20px;
	margin-right: 0px;
	margin-left: -2px;

}

.contextMenu-label-visual--channelHash svg{
	width: 14px;
	height: 14px;
	opacity: 0.5;
	margin-top: 1px;

	fill: var(--text600);
}

// SEPARATOR

.dropdownMenu div[role='separator'],
.contextMenu div[role='separator'],
.popoverMenu-separator{
	height: 5px;
	border-bottom: 1px solid var(--separatorBG-dropdown);
	width: calc(100% - 14px);
	margin-left: 14px;
	margin-bottom: 5px;
	pointer-events: none;
}

.popoverMenu-separator--spacer{
	border-bottom: 0px;
	height: 3px;
	margin-bottom: 2px;
}

// SUBMENU

.dropdownMenu-subMenu,
.contextMenu-subMenu{
	margin-top: -10px;
	overflow-y: scroll;

	max-height: min(455px, 80vh);

}

// Active state on submenu item
.contextMenu div[role='menuitem'][aria-haspopup='menu'][aria-expanded='true']{
	background: var(--expandedSubBG-item-dropdown);
}
.contextMenu div[role='menuitem'][aria-haspopup='menu'][aria-expanded='true']:hover{
	background: var(--hoverBG-item-dropdown);
}

// SUBMENU ARROW

.dropdownMenu-item-subMenuArrow,
.contextMenu-item-subMenuArrow{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 2px;

	opacity: 0.7;
}

.dropdownMenu-item-subMenuArrow svg,
.contextMenu-item-subMenuArrow svg{
	width: 13px;
	height: 13px;
	fill: var(--text200);
}


//
// VISUAL

.dropdownMenu-item-iconContainer,
.contextMenu-item-visual,
.suggestionMenu-item--insert-iconContainer,
.suggestionMenu-item--emoji-emojiContainer,
.suggestionMenu-item--mention--user-avatarOuterContainer,
.suggestionMenu-item--mention--docLink--emojiContainer{
	width: $width-dropdownMenu-visual;
	height: 100%;

	margin-right: 3px;
	margin-left: -2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

// Dropdown Menu Icon
.dropdownMenu-item-iconContainer{
	margin-right: 2px;
}

.dropdownMenu-item-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
}


//


// USER MENTION AVATAR
.suggestionMenu-item.suggestionMenu-item--mention--user{
	height: 30px;
}

.suggestionMenu-item--mention--user-avatarOuterContainer{
	margin-left: -2px;
	margin-right: 8px;
}

.suggestionMenu-item--mention--user-avatarInnerContainer{
	width: 24px;
	height: 24px;
	border-radius: 4px;

	position: relative;
}

.suggestionMenu-item--mention--user-avatarInnerBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	
	border-radius: 4px;
	z-index: 5;
	border: 1px solid rgba(0,0,0,0.05);
}

.suggestionMenu-item--mention--user
.suggestionMenu-item-label{
	font-weight: $lato-medium;
	color: var(--text600);
	position: relative;
	top: -1px;
}

.suggestionMenu-item--mention--user-avatar{
	object-fit: cover;
	border-radius: 4px;
}

// .suggestionMenu-item--mention--user.suggestionMenu-item--inactive{
// 	opacity: 0.6;
// }

// .suggestionMenu--mentions--user:hover 
// .suggestionMenu-item--mention--user.suggestionMenu-item--inactive{
// 	opacity: 1;
// }


.dropdownMenu-subHeader{
	width: 100%;

	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 6px;
	margin-bottom: 6px;
	padding-left: 13px;

	box-sizing: border-box;
}

.suggestionMenu-item-assignHint{
	margin-left: auto;
	position: relative;
	// top: 1.5px;
	font-size: 11px;
	margin-right: 9px;
	text-transform: uppercase;
	font-weight: $lato-semibold;
	color: var(--text400);
	height: 100%;

	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.6;

	display: flex;
	align-items: center;
}

//
// MOVE DOC SUBMENU


.contextMenu-subMenu--moveDoc{
	// make wider
	width: 320px;
	padding-top: 10px;
} 

.contextMenu-subMenu--moveDoc-header{
	// used in a few places
	width: 100%;

	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 10px;
	margin-bottom: 6px;
	padding-left: 13px;

	box-sizing: border-box;
	user-select: none;
	cursor: default;
}
	
.contextMenu-subMenu--moveDoc-dividerContainer{
	width: 100%;
	height: 1px;
	margin-top: 8px;
	margin-bottom: 13px;

	box-sizing: border-box;
	padding-left: 13px;
	padding-right: 0px;
}
.contextMenu-subMenu--moveDoc-divider{
	background: var(--line400);
	opacity: 0.5;
	height: 100%;
	width: 100%;
}


//
// LINEAR MARKER


.contextMenu-item-visual--linearMarker{
	font-size: 16px;
}

.contextMenu-item-visual--linearMarker svg{
	width: 16px;
	height: 16px;
}


// ROADMAP ICON

.contextMenu-item-visual--roadmap svg{
	width: 17px;
	height: 17px;
	fill: var(--text600);
	opacity: 0.5;
}

// ORG LOGO
.contextMenu-item-visual--orgLogo-image{
	width: 16px;
	height: 16px;
	object-fit: cover;
	border-radius: 4px;
}





//
// BOARD 
.contextMenu-item-visual--boardEmoji{
	font-size: 17px;	
	// background: $tempr;
}

.contextMenu-item-visual--channelHash svg{
	width: 14px;
	height: 14px;

	opacity: 0.5;
	margin-top: 1px;
	fill: var(--text600);
}

.contextMenu-item-visual--channelPrivateDoc svg{
	width: 19px;
	height: 19px;

	opacity: 0.5;
	fill: var(--text600);
}

//
// SUGGESTIONS

.suggestionMenu-title{
	
	margin-top: 6px;
	margin-left: 16px;
	margin-bottom: 6px;

	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	pointer-events: none;


}



.suggestionMenu-item{
	height: 30px;
	font-size: 15.75px;
}

.suggestionMenu-item-label{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestionMenu--mentions
.suggestionMenu-item-label{
	margin-left: 2px;
}


// EMOJI

.suggestionMenu-item--emoji{
	height: 32px;
}

.suggestionMenu-item--emoji-emojiContainer{
	flex-grow: 0;
	flex-shrink: 0;
	width: 28px;
	font-size: 20px;
	margin-right: 0px;
}

.suggestionMenu-item--emoji-label-light{
	margin-right: 2px;
	margin-left: 2px;
	opacity: 0.4;
}

// [data-theme="dark"] 
// .suggestionMenu-item--emoji-label-light{
// 	opacity: 0.5;
// }
  

// INSERT 

.suggestionMenu-item--insert-iconContainer{
	margin-right: 6px;
}

.suggestionMenu-item--insert-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.8;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarTable{
	width: 16px;
	height: 16px;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarFigma{
	width: 20px;
	height: 20px;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarDrawing{
	width: 24px;
	height: 24px;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarImage{
	width: 20px;
	height: 20px;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--doc{
	width: 16px;
	height: 16px;
}


.suggestionMenu-item--insert-iconContainer svg.icon--name--codeBlock{	
	height: 15.5px;
	width: 15.5px;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--blockDecoration #outline{
	fill: var(--text400);
}
.suggestionMenu-item--insert-iconContainer svg.icon--name--blockDecoration #background{
	fill: var(--fillIconBGFloatingMenu);
}
.suggestionMenu-item--insert-iconContainer svg.icon--name--blockDecoration #main{
	fill: var(--fillIconBGFloatingMenu);
	stroke: var(--text400);
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--blockDecoration #inner{
	fill: var(--text100);
	opacity: 0.4;
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToDo{
	width: 21px;
	height: 21px;
}
	
.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToDo #check{
	fill: var(--text600);	
}

.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToDo #box{
	fill: var(--bgSideMenuTabActive);
	
	fill: var(--bg-docEditor);
	fill: var(--bg-docEditor);
	stroke-width: 2px;
	// opacity: 0.5;
}


.suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToDo #border{	
	fill: var(--text600);
	stroke: var(--text600);
	stroke-width: 0.1px;
}


.suggestionMenu-item--insert-iconContainer svg.icon--name--toggleArrow{
	width: 14px;
	height: 14px;
	position: relative;
	right: -2px;
}

// .suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToggleList #triangle{
// 	fill: var(--text600);	
// }

// .suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToggleList #bg{	
// 	// fill: var(--bg-docEditor);
// 	fill: var(--250gray);
// 	display: none;
// }

// .suggestionMenu-item--insert-iconContainer svg.icon--name--insertBarToggleList #border{	
// 	// fill: var(--text600);
// 	display: none;
// }



// MENTIONS - PAGE LINKS

@import "Dropdowns-MentionPageLinks.scss";


// MENTIONS - LINEAR ISSUES

@import "Dropdowns-MentionLinearIssues.scss";


// CHECKBOX

@import "Dropdowns-CheckboxItem";


//
// ANIMATE IN OUT 

@keyframes dropdownMenuFadeIn {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes dropdownMenuFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-2px);
  }
}

.dropdownMenu[data-state='open']{
  animation: dropdownMenuFadeIn 75ms ease-out forwards;
}
.dropdownMenu[data-state='closed']{
  animation: dropdownMenuFadeOut 75ms ease-in forwards;
}

.dropdownMenu--sideMenu{
	width: 234px;	
	// margin-top: -1px;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.06);
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	background: rgb(253,253,253);
	border-color: rgb(220,220,220);
}

.dropdownMenu--sideMenu div[role='menuitem']{
	background: rgb(253,253,253);	
}

//
// KEYBOARD SHORTCUT

.suggestionMenu-item-keyboardShortcutContainer{
	margin-left: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-items: center;
	margin-right: 3px;

	// width: 200px;
	// opacity: 0.25;
	opacity: 0;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}


// .suggestionMenu:not(:hover) .suggestionMenu-item-keyboardShortcutContainer{
// 	transition-delay: 0ms;
// 	transition-duration: 0ms;
// }


.suggestionMenu:hover .suggestionMenu-item-keyboardShortcutContainer{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.suggestionMenu-item--active .suggestionMenu-item-keyboardShortcutContainer,
.suggestionMenu:hover .suggestionMenu-item-keyboardShortcutContainer{
	opacity: 1;
	transition: opacity 0ms linear;
}

.suggestionMenu-item-keyboardShortcut{
	margin-left: auto;
	display: flex;
}

.suggestionMenu-item-keyboardShortcut
.keyboardShortcutTag{
	display: flex;
}

.suggestionMenu-item-keyboardShortcut
.keyboardShortcutTag-item{
	
	min-width: 20px;
	height: 20px;

	border-radius: 3px;
	background: var(--250gray);
	margin-left: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}




.suggestionMenu-item-keyboardShortcut
.keyboardShortcutTag-item svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 1;
}

[data-theme="dark"] 
.suggestionMenu-item-keyboardShortcut .keyboardShortcutTag-item svg{
	opacity: 0.8;
}

.suggestionMenu-item-keyboardShortcut
.keyboardShortcutTag-item--letter{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: var(--text500);
	opacity: 01;

	min-width: 12px;
	width: auto;
	padding-left: 4px;
	padding-right: 4px;
}

[data-theme="dark"] 
.suggestionMenu-item-keyboardShortcut .keyboardShortcutTag-item--letter{
	opacity: 0.9;
}

.suggestionMenu-item-keyboardShortcut
.keyboardShortcutTag-item--space{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.9;
	letter-spacing: 0.01em;	

	width: 47px;
}

[data-theme="dark"] 
.suggestionMenu-item-keyboardShortcut .keyboardShortcutTag-item--space{
	opacity: 0.7;
}




// FILE SELECT
.contextMenu-item--fileSelect,
.dropdownMenu-item--fileSelect{
	position: relative;
	cursor: pointer;
	width: 100%;
	background: $tempr;
}

.contextMenu-item--fileSelect-input,
.dropdownMenu-item--fileSelect-input{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	background: $tempr;
	visibility: hidden;
	cursor: pointer;
}


.contextMenu-item--fileSelect-input::before,
.dropdownMenu-item--fileSelect-input::before {
	content: '';
}

.contextMenu-item--fileSelect label,
.dropdownMenu-item--fileSelect label{
	cursor: pointer;
	width: 100%;
}

//
// METADATA POPOVER ITEMS
.popoverMenu-item-markerContainer,
.popoverMenu-item-avatarContainer,
.popoverMenu-item-externalAvatarContainer{
	width: 24px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
	margin-left: -5px;

	// background: $tempr;
}

.popoverMenu-item--editProjectRoadmaps
.popoverMenu-item-markerContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.75;
}

.popoverMenu-item-markerContainer svg{
	width: 15px;
	height: 15px;
}


.popoverMenu-item-avatarContainer
.popoverMenu-item-avatar{
	width: 20px;
	height: 20px;
	border-radius: 3px;
}


//

.popoverMenu-item--multipleTeams .popoverMenu-item-label,
.popoverMenu-item--unassignProjectLead .popoverMenu-item-label{
	opacity: 0.6;
}


.popoverMenu-item--multipleTeams:hover .popoverMenu-item-label,
.popoverMenu-item--unassignProjectLead:hover .popoverMenu-item-label{
	opacity: 1;
}

.popoverMenu-item--editProjectStatus
.popoverMenu-item-label{
	text-transform: capitalize;
}

.popoverMenu-item--editProjectStatus .linearProjectStatusIcon--started,
.popoverMenu-item--editProjectStatus .linearProjectStatusIcon--planned{
	background: none; // because of hover things
}

[data-theme="light"] .popoverMenu-item--editProjectStatus .linearProjectStatusIcon--started,
[data-theme="light"] .popoverMenu-item--editProjectStatus .linearProjectStatusIcon--planned{
	background: rgba(255,255,255,0.5);
}

.popoverMenu-item--active
.popoverMenu-item-label{
	font-weight: $lato-medium;
}

.editMetadataPopover--type--projectStatus
.popoverMenu-separator{
	width: calc(100% - 12px);
	margin-left: 12px;
}


.popoverMenu-item-checkboxContainer{
	height: 100%;
	width: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
	margin-left: auto;
	margin-right: 2px;
}






// .popoverMenu-item-activeDotContainer{
// 	width: 10px;
// 	height: 100%;
// 	//background: $tempr;
// 	margin-left: auto;
// 	margin-right: 12px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	display: none;
// }

// .popoverMenu-item-activeDot{
// 	width: 6px;
// 	height: 6px;
// 	border-radius: 50%;
// 	background: var(--text600);
// 	opacity: 0;
// }

// .popoverMenu-item--inactive:hover .popoverMenu-item-activeDot{
// 	opacity: 0;
// }

// .popoverMenu-item--active .popoverMenu-item-activeDot,
// .popoverMenu-item--active:hover .popoverMenu-item-activeDot{
// 	opacity: 0.6;
// }