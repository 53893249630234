.sideMenu-activeViewStatus{
	width: calc(100% - 12px); 	
	margin-left: 7px;	

	// border-radius: 8px;	
	//height: 250px;
	// height: 92px;
	
	flex-grow: 0;
	flex-shrink: 0;
	
	padding-bottom: 8px;
	box-sizing: border-box;

	position: relative;

	// background: $tempr;
	// background: rgba(0,0,0,0.03);

	// padding-right: 6px;

	display: flex;
	flex-direction: column;
}

// HEADER AREA


@import 'SideMenu-ActiveViewStatus-Header';


//
// TITLE

.sideMenu-activeViewStatus-title{
	display: flex;
	align-items: center;

	box-sizing: border-box;
	
	width: calc(100% - 5px);	
	margin-left: 1px;

	margin-top: -4px;

	padding-left: 6px;	
	padding-right: 6px;
	
	height: 36px;

	border-radius: 8px;

	

	user-select: none;
	cursor: default;

	color: var(--text500);
}

.sideMenu-activeViewStatus-title:focus{
	outline: none;
}

.sideMenu-activeViewStatus--doc .sideMenu-activeViewStatus-title:hover,
.sideMenu-activeViewStatus-title[data-state="open"]{
	background: rgba(0,0,0,0.08);
}

.sideMenu-activeViewStatus-title--doc{
	cursor: pointer;
	padding-left: 12px;	
	background: rgba(0,0,0,0.05);
}

//
// ICON
// for drafts, channels, etc
.sideMenu-activeViewStatus-title-iconContainer{
	height: 26px;
	width: 26px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	margin-right: 4px;

	// background: $tempr;
}

.sideMenu-activeViewStatus-title-iconContainer svg{
	width: 22px;
	height: 22px;

	fill: var(--text200);
}

.sideMenu-activeViewStatus-title--channel
.sideMenu-activeViewStatus-title-iconContainer svg{
	width: 16px;
	height: 16px;
	opacity: 0.5;
}

//
// LABEL
.sideMenu-activeViewStatus-title-labelContainer{
	display: flex;	
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.sideMenu-activeViewStatus-title-label{	
	// font-size: 16px;
	// font-weight: $lato-bold;

	// font-size: 16.25px;
	// font-weight: $lato-heavy;

	// letter-spacing: 0.002em;

	font-size: 16.25px;
	font-weight: $lato-bold;
	letter-spacing: 0.0018em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	color: var(--titleTextColorSideMenuStatus);
}

.sideMenu-activeViewStatus-title-dropdownIconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;

	margin-right: 8px;

	display: none;
}

.sideMenu-activeViewStatus-title-dropdownIconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--iconColorSideMenuTab);
}

.sideMenu-activeViewStatus-title:hover 
.sideMenu-activeViewStatus-title-dropdownIconContainer{
	display: flex;
}

//
// DOC SUB ROW INC. USER LIST AND POPOVERS

.sideMenu-activeViewStatus--doc-subrowContainer{
	margin-top: 6px;

	// background: $tempr;

	display: flex;
	align-items: center;
	// background: $tempr;

	box-sizing: border-box;
	padding-left: 14px;
}

.sideMenu-activeViewStatus--doc-subrow{
	display: flex;
	align-items: center;

	// background: $tempr;
}


@import 'SideMenu-ActiveViewStatus-DocChannel';  // button approach
@import 'SideMenu-ActiveViewStatus-DocUserBtn';  // button approach
@import 'SideMenu-ActiveViewStatus-DocPopover';  // popover hover in btn


//
// DOC FOOTER

.sideMenu-activeViewStatus--doc-footer{
	margin-top: auto;

	// background: $tempr;

	display: flex;
	align-items: center;

	user-select: none;
	cursor: default;

	box-sizing: border-box;
	padding-left: 12px;
}

.sideMenu-activeViewStatus--doc-footer-iconContainer{
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;	

	margin-right: 4px;
}

.sideMenu-activeViewStatus--doc-footer-iconContainer svg{
	width: 14px;
	height: 14px;

	fill: var(--text300);
	opacity: 0.7;
	position: relative;
	top: 0.5px;

}

.sideMenu-activeViewStatus--doc-footer-label{
	font-size: 13.25px;
	font-weight: $lato-medium;

	color: var(--text300);
}

// REFACTOR OUT
@import 'SideMenu-ActiveViewStatus-DocUserRow';