.roadmapsHomePage-quarter{	
	padding-bottom: 36px;

	padding-bottom: 48px;
	border-bottom: 1px solid var(--line200);

	margin-bottom: 56px;
	margin-bottom: 64px;
	margin-bottom: 72px;	

	position: relative;
}

.roadmapsHomePage-quarter--draggingTimeline,
.roadmapsHomePage-quarter--draggingTimeline * {
	cursor: grabbing !important;
}

.roadmapsHomePage-quarter-measurer{
	position: absolute;
	left: -25px;
	right: -25px;
	width: 100%;
}

@import 'Roadmaps-HomePage-Quarter-Header';

//

@import 'Roadmaps-HomePage-Quarter-Overdue';




//

.roadmapsHomePage-quarter-forwardContainer{
	display: flex;
	margin-top: 48px;	
	margin-top: 24px;	
	width: calc(100% + 24px);
	//margin-top: 64px;	
}

.roadmapsHomePage-quarter-timelineContainer{	
	flex-grow: 0;
	flex-shrink: 0;	
}

.roadmapsHomePage-quarter-timelineLabel{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.003em;

	// text-transform: uppercase;
	// letter-spacing: 0.05em;
	margin-bottom: 4px;
	color: var(--text500);
	opacity: 0.6;
	cursor: default;
	margin-left: 4px;
}

.roadmapsHomePage-quarter-timeline{
	display: flex;
	flex-direction: column;
}

.roadmapsHomePage-quarter-timeline-timelineContainer{
	margin-top: 8px;
	
	background: var(--253gray);
	border: 1px solid var(--line300);
	border-radius: 5px;

	position: relative;
	width: calc(100% + 48px);
	margin-left: -24px;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timelineContainer{
		background: rgba(255,255,255,0.01);
		border: 1px solid rgba(255,255,255,0.03);
	}	
}


.roadmapsHomePage-quarter-timeline-excluded{
	font-size: 15px;
	margin-top: 18px;
	margin-bottom: 12px;
	color: var(--text500);
	letter-spacing: 0.02em;
	font-weight: $lato-regular;
	opacity: 0.6;
	letter-spacing: 0.01em;
	//text-align: right;

	cursor: pointer;
	user-select: none;


}

.roadmapsHomePage-quarter-timeline-excluded:hover{
	opacity: 1;
}

.roadmapsHomePage-quarter-timeline-excluded span{
	opacity: 0.5;
	margin-left: 4px;
}

//

.roadmapsHomePage-quarter-upcomingContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	box-sizing: border-box;
	padding-left: calc(20px + 0.5vw);
	margin-top: -4px;
}

.roadmapsHomePage-quarter-tabList{
	//margin-top: 20px;
	display: flex;
}

.roadmapsHomePage-quarter-timeline-header-title{
	display: none !important;
}

.roadmapsHomePage-quarter-timeline-header-title{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	cursor: default;
	user-select: none;

	// border-radius: 4px;

	height: 36px;
	opacity: 0.8;

	font-size: 15px;
	color: var(--text400);
	font-weight: $lato-regular;
}

.roadmapsHomePage-quarter-plannedList-title,
.roadmapsHomePage-quarter-backlogList-title{
	margin-left: 8px;
	user-select: none;
}

.roadmapsHomePage-quarter-plannedList-title,
.roadmapsHomePage-quarter-backlogList-title{
	// font-size: 12px;
	// font-weight: $lato-semibold;
	// //color: var(--text600);
	// //color: var(--primary600);
	color: #4898ff;
	// opacity: 0.5;

	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.003em;

	// text-transform: uppercase;
	// letter-spacing: 0.05em;
	margin-bottom: 12px;
}

.roadmapsHomePage-quarter-plannedList-title{
	color: var(--text500);
	opacity: 0.6;
	cursor: default;
	margin-top: -4px;
}

.roadmapsHomePage-quarter-backlogList-title{
	color: var(--text500);
	margin-bottom: 8px;
}


.roadmapsHomePage-quarter-backlogList{
	margin-top: 8px;
	opacity: 0.6;
}

[data-theme="light"]{
	.roadmapsHomePage-quarter-backlogList:hover{
		opacity: 1;	
	}
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-backlogList:hover{
		opacity: 1;	
	}
	.roadmapsHomePage-quarter-backlogList:hover
	.roadmapsHomePage-quarter-backlogList-title{
		opacity: 0.6;
	}
}




.roadmapsHomePage-quarter-plannedList{
	position: relative;
	// margin-top: -141px;
	opacity: 0.95;
	user-select: none;
}

.roadmapsHomePage-quarter-plannedList-exampleLine{
	position: absolute;
	left: -26px;
	top: 10px;
	height: 130px;
	width: 1px;
	background: var(--text600);
	opacity: 0.05;
}



.roadmapsHomePage-quarter-backlogList-title{
	cursor: pointer;
}

.roadmapsHomePage-quarter-backlogList-title:hover{
	opacity: 1;
}

.roadmapsHomePage-quarter-timeline-header-iconContainer{
	margin-right: 6px;

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;
}

.roadmapsHomePage-quarter-timeline-header-iconContainer svg{
	width: 20px;
	height: 20px;
	fill: var(--text600);
	opacity: 0.5;
}

@import 'Roadmaps-HomePage-Quarter-Timeline';

//
//

.roadmapsHomePage-quarter-plannedList,
.roadmapsHomePage-quarter-backlogList{
	// width: calc(100% + 24px);
	margin-left: -4px;
	padding-top: 8px;
}

.roadmapsHomePage-quarter-backlogList-toggleBtn{
	height: 28px;
	padding-left: 7px;
	padding-right: 7px;
	color: var(--text600);
	opacity: 0.9;

	margin-top: 4px;
	margin-left: 1px;

	border-radius: 4px;
	font-size: 14.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.02em;

	// background: $tempr;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-backlogList-toggleBtn{
		opacity: 0.4;
	}	
	.roadmapsHomePage-quarter-backlogList-toggleBtn:hover{
		background: var(--253gray);
		opacity: 0.8;
	}
}

.roadmapsHomePage-quarter-backlogList-toggleBtn:hover{
	background: var(--253gray);
	opacity: 1;
}

//

.roadmapsHomePage-quarter-projectRow{
	display: flex;
	align-items: center;

	cursor: pointer;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	height: 30px;
	width: 100%;

	border-radius: 4px;
	// border: 1px solid var(--line300);
}

.roadmapsHomePage-quarter-projectRow:hover{
	background: var(--250gray);
}


.roadmapsHomePage-quarter-projectRow-markerContainer{
	width: 24px;
	height: 24px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

.roadmapsHomePage-quarter-projectRow-markerContainer
.linearMarker{
	font-size: 15px;
}

.roadmapsHomePage-quarter-projectRow-markerContainer
.linearMarker svg{
	width: 15px;
	height: 15px;
}

.roadmapsHomePage-quarter-projectRow-name{
	font-size: 15.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.012em;

	color: var(--text600);

		white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


[data-theme="dark"]{
	.roadmapsHomePage-quarter-projectRow-name{
		font-weight: $lato-regular;
		letter-spacing: 0.016em;	
	}	
}



// //

// // EMPTY

// .roadmapsHomePage-quarterEmpty{
// 	padding-bottom: 48px;
// 	border-bottom: 1px solid var(--line200);

// 	margin-bottom: 72px;
// }

// .roadmapsHomePage-quarterEmpty-dropdownBtn{	

// 	margin-left: -12px;

// 	height: 48px;
// 	padding-left: 12px;
// 	padding-right: 12px;

// 	border: 1px solid var(--line300);
// 	border-radius: 6px;

// 	opacity: 0.7;
// }

// .roadmapsHomePage-quarterEmpty-dropdownBtn:hover{
// 	opacity: 1;
// }

// .roadmapsHomePage-quarterEmpty-dropdownBtn-label{
// 	font-size: 24px;
// 	font-weight: $lato-heavy;
// 	color: var(--text600);

// 	opacity: 0.5;
// }

// .roadmapsHomePage-quarterEmpty-dropdownBtn-iconContainer{
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: 12px;
// 	box-sizing: border-box;
// 	padding-top: 4px;
// }

// .roadmapsHomePage-quarterEmpty-dropdownBtn-iconContainer svg{
// 	width: 28px;
// 	height: 28px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }