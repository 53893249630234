


.templateModal-details{
	display: flex;

	height: 100%;
	width: 100%;

	border-radius: 6px;
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;

	background: var(--254gray);
	background: rgb(253,253,255);
	border-top: 1px solid var(--line300);

	display: flex;

	box-sizing: border-box;	

	padding-left: 50px;
	padding-right: 45px;
}


[data-theme="dark"]{
	.templateModal-details{		
		border-top: 1px solid var(--line200);
		background: lighten(rgb(32,30,36), 2%);
	}	
}




.templateModal-details-primaryContainer{
	min-width: 10px;
	flex-grow: 10;
	flex-shrink: 10;

	max-width: 680px;
}

.templateModal-details-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

}

.templateModal-details-rightContainer{
	flex-grow: 0;
	flex-shrink: 0;
}

//



.templateModal-details-nameInputContainer{
	width: 100%;

	margin-top: 16px;

	height: 40px;
	width: 100%;

	position: relative;

	// margin-top: -6px;
	// margin-bottom: 2px;

	margin-left: -8px;
}

input.templateModal-details-nameInput{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	padding-left: 8px;
	padding-right: 8px;

	font-size: 23.5px;
	font-weight: $lato-heavy;
	color: var(--text500);		

	background: none;
	border: 1px solid $invisible;

	box-sizing: border-box;

	// background: $tempr;
	border-radius: 4px;
}

//

.templateModal-details-creatorContainer{
	margin-top: 1px;
	margin-bottom: -3px;
}

.templateModal-details-creator{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 28px;
}

.templateModal-details-creator-iconContainer,
.templateModal-details-creator-avatarContainer{
	width: 26px;
	height: 26px;	

	margin-right: 7px;
}

.templateModal-details-creator-avatarContainer{
	margin-right: 10px;
	margin-left: 2px;
	width: 24px;
	height: 24px;
}

.templateModal-details-creator-iconContainer svg{
	width: 26px;
	height: 26px;
}

.templateModal-details-creator-avatar{
	border-radius: 4px;
}

.templateModal-details-creator-label{
	font-size: 17px;
	font-weight: $lato-regular;
	// color: var(--text400);
	// opacity: 0.75;

	font-weight: $lato-semibold;
	color: var(--text500);
	//opacity: 0.75;
}


.templateModal-details-creator-iconContainer svg{
	transform-origin: 50% 50%;
}


[data-theme="dark"]{	
	.templateModal-details-creator-iconContainer svg.companyIcon--square,
	.templateModal-details-creator-iconContainer svg.companyIcon--yarn{
		filter: invert(100%);
	}	
}



.templateModal-details-creator-iconContainer svg.companyIcon--yarn{
	transform: scale(0.9);
}

.templateModal-details-creator-iconContainer svg.companyIcon--figma{
	transform: scale(1.3);
}	

.templateModal-details-creator-iconContainer svg.companyIcon--square{
	transform: scale(0.9);
}	



.templateModal-details-creator-iconContainer svg.companyIcon--mixpanel{
	transform: scale(0.95);
}	



.templateModal-details-creator-iconContainer svg.companyIcon--pagerduty path{
	transform: scale(0.95);
	transform-origin: center center;
}

.templateModal-details-creator-iconContainer svg.companyIcon--react,
.templateModal-details-creator-iconContainer svg.companyIcon--amazon{
	transform: scale(0.98);
}

.templateModal-details-creator-iconContainer svg.companyIcon--linear{
	transform: scale(0.97);
}

.templateModal-details-creator-iconContainer svg.companyIcon--google{
	transform: scale(0.97);
}

.templateModal-details-creator-iconContainer svg.companyIcon--slack{
	transform: scale(0.97);
}




//

.templateModal-details-editTemplateVSpacer{
	height: 8px;
}

//

.templateModal-details-descriptionTextAreaContainer{
	height: 98px;	
	width: 100%;

	position: relative;

	margin-left: -8px;
}

.templateModal-details-descriptionTextArea{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	padding-left: 8px;
	padding-right: 8px;
	padding-top: 8px;
	box-sizing: border-box;

	border-radius: 4px;

	resize: none;

	font-size: 16.25px;
	letter-spacing: 0.008em;
	font-weight: $lato-regular;
	color: var(--text400);
	//line-height: 1.35;
	line-height: 1.38;
	opacity: 1;

	background: none;
	border: 1px solid $invisible;

	//background: $tempr;
}


.templateModal-details-descriptionTextArea:disabled{
	pointer-events: none;


}

.templateModal-details-descriptionTextArea:disabled::placeholder{
	color: $invisible;
}

// HARDCODED

.templateModal-details-descriptionTextArea-light{
	color: var(--text200);
	opacity: 0.95;
}

.templateModal-details-descriptionTextArea-link{
	text-decoration: underline;
	cursor: pointer;
}

.templateModal-details-descriptionTextArea-link:hover{
	color: var(--text400);
}

//
// STATES

.templateModal-details--editTemplateMode input.templateModal-details-nameInput,
.templateModal-details--editTemplateMode textarea.templateModal-details-descriptionTextArea{	
	background: var(--bg-docEditor);
	border: 1px solid var(--line400);
	cursor: text;
}


.templateModal-details--editTemplateMode input.templateModal-details-nameInput:focus,
.templateModal-details--editTemplateMode textarea.templateModal-details-descriptionTextArea:focus{	
	background: var(--bg-docEditor);
	border: 1px solid var(--line600);
	border: 1px solid var(--primary400);
	cursor: text;
	outline: 3px solid var(--primary75);
}


//
.templateModal-details-useBtnContainer{	
	padding-top: 24px;
}

.templateModal-details-useBtn{
	width: 220px;
	height: 42px;
	border-radius: 4px;

	font-size: 17.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.012em;
	color: $white;

	background: var(--text400);
	border: 1px solid var(--text500);
}

.templateModal-details-useBtn:hover{	
	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]
.templateModal-details-useBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.templateModal-details-useBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}




//

.templateModal-details-cancelBtnContainer{
	width: 100%;
	margin-top: 8px;
	display: flex;
	align-items: center;
	//justify-content: flex-start;

	//margin-left: -12px;
	// padding-bottom: 18px;

	// background: $tempr;
}

.templateModal-details-cancelBtn{
	// padding-left: 12px;
	// padding-right: 12px;
	width: 100%;

	height: 38px;
	font-size: 15px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;
	color: var(--text500);
	opacity: 0.5;

	//background: var(--246gray)
}

.templateModal-details-cancelBtnContainer:hover{
	background: var(--246gray);
	opacity: 1;
}

