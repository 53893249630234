
// TEAM PANEL
// background: rgb(217,216,216);

:root{		
	--openDocMenu-bg: rgb(255,255,255);
	--openDocMenu-boxShadow: 0px 4px 8px 0px rgba(0,0,0,0.10), 0px 8px 16px 0px rgba(0,0,0,0.06);
}

[data-theme="dark"]{
	//--openDocMenu-bg: rgb(28,26,32);
	--openDocMenu-bg: rgb(32,28,34);
	--openDocMenu-boxShadow: 0px 4px 8px 0px rgba(0,0,0,0.10), 0px 8px 16px 0px rgba(0,0,0,0.06);
	
}