.docEditor-contentTabs-tabBar{
	display: flex;
	margin-top: 14px;


	width: calc(100% + 28px); // intentionally assymetric
	margin-left: -10px;
}

.docEditor-contentTabs-tabBar-tab{

	height: 30px;
	border-radius: 4px;
	cursor: pointer;

	padding-left: 12px;
	padding-right: 12px;
}

.docEditor-contentTabs-tabBar-tab--active{
	//background: var(--primary50);
	background: var(--253gray);
}

// [data-theme="dark"]{
// 	.docEditor-contentTabs-tabBar-tab--active{
// 		background: #002B5C;
// 	}	
// }

.docEditor-contentTabs-tabBar-tab--related.docEditor-contentTabs-tabBar-tab--active{
	pointer-events: none;
}

.docEditor-contentTabs-tabBar-tab--inactive{
	opacity: 0.5;
}

.docEditor-contentTabs-tabBar-tab--inactive:hover{
	opacity: 0.9;
	background: var(--253gray);
}


.docEditor-contentTabs-tabBar-tab--related{
	width: auto;
	padding-right: 14px;
	padding-left: 14px;
}

//

.docEditor-contentTabs-tabBar-tab-iconContainer{
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -4px;
}

.docEditor-contentTabs-tabBar-tab-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.75;
}

.docEditor-contentTabs-tabBar-tab--active
.docEditor-contentTabs-tabBar-tab-iconContainer svg{
	// fill: var(--primary600);
}


.docEditor-contentTabs-tabBar-tab--related-iconContainer{
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 7px;

}

.docEditor-contentTabs-tabBar-tab--related-iconContainer svg{
	width: 12px;
	height: 12px;
	fill: var(--text500);
	position: relative;
	top: 1px;
}

.docEditor-contentTabs-tabBar-tab--related-iconContainer svg.icon--name--linearIcon{
	width: 10px;
	height: 10px;
}

.docEditor-contentTabs-tabBar-tab-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	color: var(--text600);
}

.docEditor-contentTabs-tabBar-tab--active
.docEditor-contentTabs-tabBar-tab-label{
	//color: var(--primary600);
}

.docEditor-contentTabs-tabBar-tab-counter{
	font-size: 14px;
	color: var(--text500);

	margin-left: 2px;
	position: relative;
	top: 1px;
}

.docEditor-contentTabs-tabBar-tab-spacer{
	flex-grow: 1;
	flex-shrink: 1;
}

//

.docEditor-contentTabs-contentContainer{
	//padding-top: 14px; // add when has tabs
	border-bottom: 1px solid var(--lineDocDivider);
	margin-bottom: 24px;
}




