
//
//  DOC TABLE


@import 'LibraryDocTable-Header';

.libraryDocTable-tableBorder{
	width: 100%;
	height: 1px;
	background: var(--line300);
}

.libraryDocTable--roadmap
.libraryDocTable-tableBorder{
	display: none;
}

.libraryDocTable-table{
	padding-bottom: 120px;
	padding-top: 8px;
	width: 100%;
}


@import 'LibraryDocTable-Row';

