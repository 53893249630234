$sideDimension-activeViewStatus-docUserBtn: 27px;
$sideDimension-fullScreenActiveViewStatus-docUserBtn: 28px;


.sideMenu-activeViewStatus--doc-userList{
	display: flex;
	box-sizing: border-box;	

	position: relative;
	margin-left: -3px;
}

//

.sideMenu-activeViewStatus--doc-userList:hover
.sideMenu-activeViewStatus--doc-userBtn,
.fullScreenActiveViewStatusDoc:hover
.sideMenu-activeViewStatus--doc-userBtnContainer,
.sideMenu-activeViewStatus--doc-channelBtnContainer:hover ~
.sideMenu-activeViewStatus--doc-userList
.sideMenu-activeViewStatus--doc-userBtn{
	opacity: 0.5;
	transition: opacity 0ms linear;
	// transition-delay: 150ms;
}

.sideMenu-activeViewStatus--doc-userList:hover
.sideMenu-activeViewStatus--doc-userBtn:hover,
.fullScreenActiveViewStatusDoc:hover
.sideMenu-activeViewStatus--doc-userBtnContainer:hover,
.sideMenu-activeViewStatus--doc-userList:hover
.sideMenu-activeViewStatus--doc-userBtnContainer:hover
.sideMenu-activeViewStatus--doc-userBtn{
	opacity: 1;
	// transform: scale(1.1);
	transition: all 0ms linear;
	// transition-delay: 150ms;
}

.sideMenu-activeViewStatus--doc-userBtnContainer{	
	display: flex;

	box-sizing: border-box;
	
	padding-left: 1px;
	padding-right: 1px;

	cursor: pointer;

	// background: $tempb;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer{
	padding-top: 3px;
	padding-bottom: 3px;
	padding-right: 3px;
	padding-left: 16px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:first-child{
	padding-top: 16px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:last-child{
	padding-bottom: 16px;
}

.sideMenu-activeViewStatus--doc-userBtn{
	
	width: $sideDimension-activeViewStatus-docUserBtn;
	height: $sideDimension-activeViewStatus-docUserBtn;

	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	cursor: pointer;

	user-select: none;

	position: relative;	
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtn{
	width: $sideDimension-fullScreenActiveViewStatus-docUserBtn;
	height: $sideDimension-fullScreenActiveViewStatus-docUserBtn;
}

//
// AVATAR

.sideMenu-activeViewStatus--doc-userBtn-avatarContainer{
	width: $sideDimension-activeViewStatus-docUserBtn;
	height: $sideDimension-activeViewStatus-docUserBtn;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtn-avatarContainer{
	width: $sideDimension-fullScreenActiveViewStatus-docUserBtn;
	height: $sideDimension-fullScreenActiveViewStatus-docUserBtn;

}

.sideMenu-activeViewStatus--doc-userBtn-avatar{
	height: 25px;
	width: 25px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtn-avatar{
	height: 28px;
	width: 28px;
}


img.sideMenu-activeViewStatus--doc-userBtn-avatar-avatar{
	height: 100%;
	width: 100%;

	object-fit: contain;

	border-radius: 3.5px;
}

.fullScreenActiveViewStatusDoc-userList
img.sideMenu-activeViewStatus--doc-userBtn-avatar-avatar{
	border-radius: 5px;
}



// .sideMenu-activeViewStatus--doc-userBtn:hover 
// .sideMenu-activeViewStatus--doc-userBtn-sendBtn:hover{
// 	background: rgb(0,0,0,0.09);
// }

// .sideMenu-activeViewStatus--doc-userBtn:hover 
// .sideMenu-activeViewStatus--doc-userBtn-sendBtn:hover svg{
// 	opacity: 1;
// }


//
// DROPDOWN PROTOTYPE
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{

	pointer-events: none;
	// background: $tempr;

	position: absolute;

	left: -79.5px;
	//right: -10px;
	width: 300px;
	top: 26px;

	padding-top: 6px;

	// background: $tempr;
	// background: $invisible;

	z-index: 100;

	opacity: 0;

}



.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	// background: $tempr;
	padding-left: 10px;
	width: 280px;
}


.sideMenu-activeViewStatus--doc-subrow:hover 
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer,
.fullScreenActiveViewStatusDoc-userList:hover 
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	opacity: 1;

	transition: opacity 0ms linear;
	transition-delay: 150ms;
}

.fullScreenActiveViewStatusDoc-userList:hover 
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	transition-delay: 0ms;
}


.sideMenu-activeViewStatus--doc-userBtnContainer:hover
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	pointer-events: auto;
}

.sideMenu-activeViewStatus--doc-userBtnContainer:hover
.sideMenu-activeViewStatus--doc-popover{
	opacity: 1;
}




// HACKY, DO BETTER WAY
.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(1)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	left: -90px;
	left: -12px;
}

.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(2)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	left: -80px;
}

.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(3)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	left: -70px;
}

.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(4)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	left: -60px;
}

// HACKY
.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	left: 42px !important;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(1)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	top: 32px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(2)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	top: 54px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(3)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	top: 76px;
}

.fullScreenActiveViewStatusDoc-userList
.sideMenu-activeViewStatus--doc-userBtnContainer:nth-child(4)
.sideMenu-activeViewStatus--doc-userBtnPopoverContainer{
	top: 28px;
	top: -40px;
}
