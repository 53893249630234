.docEditor-meetingContent-highlights--disabled{
	display: none;
}

.docEditor-meetingContent-highlights-grid{
	padding-top: 12px;
	padding-bottom: 8px;

	min-height: 130px;

	width: 100%;


	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	column-gap: 13px;
}

.docEditor-meetingContent-highlights-grid-highlight{
	width: 208px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	flex-direction: column;

	border-radius: 4px;

	box-sizing: border-box;



	position: relative;
}


.docEditor-meetingContent-highlights-grid-highlight-videoContainer,
.docEditor-meetingContent-highlights-grid-highlight-processingPreview{	
	width: 100%;
	aspect-ratio: 215/120; 

	flex-grow: 0;
	flex-shrink: 0;

	cursor: pointer;
	position: relative;
}

.docEditor-meetingContent-highlights-grid-highlight-videoContainer-video,
.docEditor-meetingContent-highlights-grid-highlight-processingPreview-image{
	width: 100%;
	height: 100%;
	background: var(--235gray);
	border-radius: 4px;
	overflow: hidden;	

	position: relative;
}

.message-content-highlight-video-audioIcon{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.message-content-highlight-video-audioIcon,
.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio{
	// background: lighten(rgb(104,100,110),25%);
	background: var(--text500);
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

[data-theme="dark"]{
	.message-content-highlight-video-audioIcon,
	.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio{
		background: lighten(rgb(28,26,32), 2%);
	}
}


.message-content-highlight-video-audioIcon{
	border-radius: 3px;

}

.message-content-highlight-video--audio svg.icon--name--waveform,
.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio svg{
	width: 40px;
	height: 40px;
	// fill: var(--text500);
	// opacity: 0.5;
	fill: white;
	opacity: 0.75;	
}

[data-theme="dark"]{
	.message-content-highlight-video--audio svg.icon--name--waveform,
	.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio svg{
		opacity: 0.5;
	}
}


.message-content-highlight-video--audio svg.icon--name--waveform{
	position: relative;
	top: -14px;
}

.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio svg{
	width: 24px;
	height: 24px;
}

.docEditor-meetingContent-highlights-grid-highlight-videoContainer:hover
.docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio svg{
	opacity: 0.8;
}


.docEditor-meetingContent-highlights-grid-highlight-processingPreview-image{
	object-fit: cover;
}

.docEditor-meetingContent-highlights-grid-highlight-videoContainer-video-duration{
	position: absolute;
	bottom: 4px;
	left: 4px;

	padding-left: 5px;
	padding-right: 5px;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: white;

	border-radius: 3px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;

	letter-spacing: 0.008em;

	background: rgba(35,30,40,0.6);
  backdrop-filter: blur(5px) saturate(150%);
}

.docEditor-meetingContent-highlights-grid-highlight-videoContainer-video-video video{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.docEditor-meetingContent-highlights-grid-highlight-contentContainer{
	//height: 108px; // 3 lines
	height: 88px; // 2 lines
	// background: $tempr;
}

.docEditor-meetingContent-highlights-grid--autoResize
.docEditor-meetingContent-highlights-grid-highlight-contentContainer{
	max-height: 88px;
	min-height: 64px;
	height: auto;
}

.docEditor-meetingContent-highlights-grid-highlight-description{
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text500);

	// /letter-spacing: 0.011em;
	letter-spacing: 0.008em;
	line-height: 1.3;

	margin-top: 8px;
	margin-bottom: 6px;
	
	cursor: default;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  //max-height: 60px; // 3 lines
  max-height: 40px; // lines
}

.docEditor-meetingContent-highlights-grid-highlight-footer{
	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.7;
	letter-spacing: 0.008em;
	cursor: default;
}



.docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerOuterContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	border-radius: 4px;
	z-index: 5;

	background: rgba(35,30,40,0.5);
  backdrop-filter: blur(5px) saturate(150%);
}

.docEditor-meetingContent-highlights-grid-highlight-processingPreview--audio
.docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerOuterContainer{
	
	backdrop-filter: none;
	//background: lighten(rgb(104,100,110),25%);
	background: var(--text500);
}

[data-theme="dark"]{
	.docEditor-meetingContent-highlights-grid-highlight-processingPreview--audio
	.docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerOuterContainer{
		background: lighten(rgb(28,26,32), 2%);
	}
}


.docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerContainer{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	right: 0px;
	left: 0px;
}


@keyframes previewSpinnerAnimation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}


.docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinner{
	height: 32px;
	width: 32px;
	
  border-radius: 32px;

  border: 4px rgba(255,255,255,0.3) solid;
  border-top: 4px rgba(255,255,255,0.8) solid;

  opacity: 0.7;

  animation: previewSpinnerAnimation 1.5s infinite linear;
}



// .docEditor-meetingContent-highlights-grid-highlight-subtitle{
// 	// font-size: 14.5px;
// 	// font-weight: $lato-regular;
// 	// color: var(--text500);
// 	// opacity: 0.5;

// 	display: flex;
// 	align-items: center;
// }

// .docEditor-meetingContent-highlights-grid-highlight-subtitle-avatarRow{
// 	display: flex;
// 	align-items: center;
// 	margin-right: 5px;
// 	margin-top: 2px;
// }

// .docEditor-meetingContent-highlights-grid-highlight-subtitle-avatar{
// 	background: $tempr;
// 	width: 18px;
// 	height: 18px;
// 	border-radius: 3px;
// 	margin-right: 3px;
// }

// .docEditor-meetingContent-highlights-grid-highlight-subtitle-label{
// 	font-size: 13px;
// 	font-weight: $lato-medium;
// 	color: var(--text600);	
// 	letter-spacing: 0.008em;

// 	margin-right: 5px;
// 	opacity: 0.75;
// }



//

.docEditor-meetingContent-highlights-grid-seeMoreButton{
	font-size: 14.5px;
	color: var(--text500);
	opacity: 0.5;
	//margin-left: auto;
	margin-top: 8px;
	margin-bottom: -4px;
}

//

.docEditor-meetingContent-highlights-grid-addHighlightBtn{
	width: 208px;
	height: 116px; // dirty

	// border: 1px solid var(--line400);
	// border-bottom: 1px solid var(--line500);
	border: 1px solid $invisible;
	background: var(--250gray);
	border-radius: 4px;
	// /box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);

	box-sizing: border-box;

	position: relative;
}

.docEditor-meetingContent-highlights--disabled
.docEditor-meetingContent-highlights-grid-addHighlightBtn{
	opacity: 0.5;
}

.docEditor-meetingContent-highlights-grid-addHighlightBtn:hover{
	// border: 1px solid var(--line400);
	// border-bottom: 1px solid var(--line500);
	background: var(--248gray);
}


.docEditor-meetingContent-highlights-grid-addHighlightBtn-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	// margin-left: -6px;
	// margin-right: 2px;
}

.docEditor-meetingContent-highlights--disabled
.docEditor-meetingContent-highlights-grid-addHighlightBtn-iconContainer{
	display: none;
}

.docEditor-meetingContent-highlights-grid-addHighlightBtn-iconContainer svg{
	width: 24px;
	height: 24px;
	fill: var(--text500);
	opacity: 0.5;
}

.docEditor-meetingContent-highlights-grid-addHighlightBtn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;

	opacity: 0;

	position: absolute;
	left: 0px;
	width: 100%;
	bottom: 20px;
}

.docEditor-meetingContent-highlights-grid-addHighlightBtn:hover
.docEditor-meetingContent-highlights-grid-addHighlightBtn-label{
	opacity: 0.9;
}
