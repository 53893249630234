
// REACTIONS

.doc-inlineReactionBar{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	min-width: 300px;
	height: 20px;

	margin-top: 4px;
	margin-left: 0px;
	margin-bottom: 6px;

	position: relative;
	z-index: 10; // above paragraph	
}

button.doc-inlineReactionBar-reaction{

	width: 40px;
	height: 22px;
	border-radius: 10px;	

	display: flex;
	align-items: center;
	justify-content: center;	

	border: 1.5px solid $invisible;
	background: rgb(251,249,249);

	cursor: pointer;

	// I think this might be for new or something
	// border: 1.5px solid transparentize($blue, 0.5);
	// background: transparentize($blue, 0.95);
}


button.doc-inlineReactionBar-reaction--userHasReacted{
	// border: 1.5px solid transparentize($blue, 0.15);
	// background: transparentize($blue, 0.95);

	// // need to make opaque for hover effect
	// border: 1.5px solid lighten($blue, 5%);
	// background: lighten($blue, 48%);

	border: 1.5px solid var(--primary400); // inexact match, 
	background: var(--primary25);
	
}

button.doc-inlineReactionBar-reaction:hover{
	//border: 1.5px solid transparentize($blue, 0.2);
	//background: transparentize($blue, 0.9);
	border: 1.5px solid var(--primary400);
	background: var(--primary50);
}

button.doc-inlineReactionBar-reaction:active{
	//border: 1.5px solid transparentize($blue, 0.15);
	//background: transparentize($blue, 0.8);	
	border: 1.5px solid var(--primary450); // inexact match
	background: var(--primary100);
}


button.doc-inlineReactionBar-reaction--userHasReacted
.doc-inlineReactionBar-reaction-count{
	//color: darken($blue, 10%);
	color: var(--primary700);
}

button.doc-inlineReactionBar-reaction:hover
.doc-inlineReactionBar-reaction-count{
	//color: darken($blue, 15%)
	color: var(--primary800);
}

.doc-inlineReactionBar-reaction-emoji{
	margin-right: 4px;
	font-size: 15px;
}

.doc-inlineReactionBar-reaction-count{
	font-weight: $lato-bold;
	font-size: 13px;
	color: var(--text300);
}

button.doc-inlineReactionBar-reaction:hover
.doc-inlineReactionBar-reaction-count{	
	//color: darken($blue, 10%);
	color: var(--primary700);
}

//

button.doc-inlineReactionBar-addNewReactionBtn{
	width: 34px;
	height: 25px;

	padding: 0px;

	border-radius: 12.5px;

	background: none;
	border: none;
	box-shadow: none;

	background: rgb(244,244,244);

	margin-left: 5px;

	cursor: pointer;

	opacity: 0;
}

.doc-inlineReactionBar:hover
button.doc-inlineReactionBar-addNewReactionBtn{
	opacity: 1;
}

button.doc-inlineReactionBar-addNewReactionBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text400);	
	margin-top: 2px;

}

button.doc-inlineReactionBar-addNewReactionBtn:hover{
	//background: transparentize($blue, 0.9);
	background: var(--primary50)
}

button.doc-inlineReactionBar-addNewReactionBtn:hover svg{
	//fill: darken($blue, 10%);
	fill: var(--primary700);
}

// Tooltip customization
.tooltip.tooltip--inlineReactionsBar-addNewReactionBtn[data-side="top"]{
  position: relative;
  top: -5px;
  
}

.tooltip.tooltip--inlineReactionsBar-reaction{
	position: relative;
  top: -5px;

	width: 140px;
	border-radius: 10px;
}

.tooltip--inlineReactionsBar-reaction-emojiPreview{
	width: 140px;
	height: 60px;
	margin: 0 auto;

	background: rgba(0,0,0,0.2);
	border-radius: 14px;

	font-size: 44px;

	margin-top: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.tooltip--inlineReactionsBar-reaction-text{
	width: 100%;
	text-align: center;
	font-size: 13.75px;
	font-weight: $lato-bold;

	margin-top: 6px;
	margin-bottom: 2px;
}

.tooltip--inlineReactionsBar-reaction-instruction{
	width: 100%;
	text-align: center;

	font-size: 12.5px;
	font-weight: $lato-regular;
	opacity: 0.5;

	margin-bottom: 4px;
}