.tabSwitchMenu{
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);

	z-index: 999;

	background: rgb(248,248,250);
	background: rgb(255,255,255);
	border: 1px solid var(--line300);  


	display: flex;
	
	padding-top: 18px;
	padding-bottom: 14px;	

	border-radius: 20px;

}

[data-theme="dark"] .tabSwitchMenu{
	background: darken(rgb(44,40,54), 2%);
	border: 1px solid rgba(255,255,255,0.08);
}


.tabSwitchMenu-tab{
	// width: 200px;
	// background: $tempr;

	display: flex;
	flex-direction: column;
	cursor: default;
	user-select: none;
}



.tabSwitchMenu-tab-docContainer{
	width: 100%;	

	box-sizing: border-box;
	border-radius: 6px;

}

.tabSwitchMenu-tab-iconOuterContainer{
	width: 100%;
	// background: $tempr;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tabSwitchMenu-tab-iconInnerContainer{
	// background: $tempr;
	width: 80%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.tabSwitchMenu-tab-iconContainer{
	width: 100%;
	aspect-ratio: 1/1;
	border-radius: 6px;

	background: var(--248gray);

	display: flex;
	align-items: center;
	justify-content: center;
}

.tabSwitchMenu-tab-iconContainer svg{
	width: 36px;
	height: 36px;
	fill: var(--text600);
	opacity: 0.5;
}

//

.tabSwitchMenu-tab--active .tabSwitchMenu-tab-docContainer,
.tabSwitchMenu-tab--active .tabSwitchMenu-tab-iconContainer{
	background: var(--240gray);
}

[data-theme="dark"] .tabSwitchMenu-tab--active .tabSwitchMenu-tab-docContainer,
[data-theme="dark"] .tabSwitchMenu-tab--active .tabSwitchMenu-tab-iconContainer{
	background: rgba(255,255,255,0.06);
}

.tabSwitchMenu-tab--active .tabSwitchMenu-tab-iconContainer svg{
	opacity: 1;
}

.tabSwitchMenu-tab-doc{
	width: 100%;
	height: 100%;
	background: var(--bg-docEditor);
	border: 1px solid var(--line300);
	border-radius: 4px;
	position: relative;

	overflow: hidden;
	opacity: 0.75;
}



.tabSwitchMenu-tab--active
.tabSwitchMenu-tab-doc{
	border: 1px solid var(--line500);
	opacity: 1;
}

[data-theme="dark"] .tabSwitchMenu-tab-doc{
	border: 1px solid rgba(255,255,255,0.12);
}

[data-theme="dark"] .tabSwitchMenu-tab--active .tabSwitchMenu-tab-doc{
	border: 1px solid rgba(255,255,255,0.18);
}

.tabSwitchMenu-tab-doc-docPreview{
	position: absolute;
	left: 0px;
	top: 0px;
	transform-origin: top left;
	z-index: 5;

	display: flex;
	justify-content: center;

	
}

.tabSwitchMenu-tab-doc-docPreview-doc{
	width: $fullWidth-doc;

	height: 100%;

	padding-top: 96px; // hacky
}

.tabSwitchMenu-tab-doc-docPreview-doc-title{
	width: $textWidth-doc;
}

.tabSwitchMenu-tab-doc-docPreview-doc-title-innerWrapper{
	width: $textWidth-doc;
}






//
.tabSwitchMenu-tab-footerContainer{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	

  width: 100%;
  box-sizing: border-box;
  padding-left: 2px;
  padding-right: 2px;
  
}

.tabSwitchMenu-tab--active .tabSwitchMenu-tab-footerContainer{
	opacity: 1;
}

.tabSwitchMenu-tab--inactive .tabSwitchMenu-tab-footerContainer{
	opacity: 0.5;
}

.tabSwitchMenu-tab-footerContainer-titleContainer{
	margin-top: 14px;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: var(--text500);

	margin-bottom: 4px;
}

.tabSwitchMenu-tab-footerContainer-title{	
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
}

.tabSwitchMenu-tab-footerContainer-channelContainer{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;

}

.tabSwitchMenu-tab-footerContainer-channel{
	font-size: 14px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 8px;
	padding-right: 8px;

	//background: var(--250gray);
	color: var(--text300);

	border-radius: 4px;
}