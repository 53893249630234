
//
// POPOVER

.teamPanel-user-popoverContainer{
	margin-left: -5px;	
	// margin-top: -100px;
	margin-top: -96px;
}

.teamPanel-user-popoverContainer--layoutHack--ali{
	margin-top: -22px;
}

.teamPanel-user-popoverContainer--layoutHack--book{
	margin-top: -52px;	
}

.teamPanel-user-popoverContainer--layoutHack--jasper{
	margin-top: -82px;	
}



.teamPanel-user::nth-child(1)
.teamPanel-user-popoverContainer{
	margin-top: -40px;
}

.teamPanel-userPopover{
	width: 305px;

	box-sizing: border-box;
	padding: 8px;

	background: $white;
	border: 1px solid var(--line500);
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);

	padding: 14px;
	padding-top: 14px;
	padding-bottom: 12px;
}

.teamPanel-userPopover-header{
	display: flex;
}

.teamPanel-userPopover-header-avatar{
	width: 34px;
	height: 34px;

	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 10px;
}

.teamPanel-userPopover-header-avatar-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 5px;
}

.teamPanel-userPopover-header-title{
	flex-grow: 1;
	flex-shrink: 1;

	user-select: none;
	cursor: default;
}

.teamPanel-userPopover--channel
.teamPanel-userPopover-header-title{
	padding-left: 6px;
}

.teamPanel-userPopover-header-title-primary{
	display: flex;

	margin-top: -1px;
	margin-bottom: 3px;
}

.teamPanel-userPopover-header-title-primary-iconContainer{
	margin-right: 2px;
}

.teamPanel-userPopover-header-title-primary-iconContainer svg{
	width: 13px;
	height: 13px;

	position: relative;
	top: 1px;

	color: var(--text600);
	opacity: 0.8;
}


.teamPanel-userPopover-header-title-primary-label{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text600);
}



.teamPanel-userPopover-header-title-subtitle{
	font-size: 13px;
	font-weight: $lato-medium;
	color: var(--text200);
}

//
// STATUS

.teamPanel-userPopover-header-statusTagContainer{
	//background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;
}

.teamPanel-userPopover-header-statusTag{
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 4px; 

	position: relative;
	top: -2px;
	right: -1px;

	font-size: 12.75px;
	letter-spacing: 0.008em;
	font-weight: $lato-semibold;

	border: 1px solid $invisible;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}

.teamPanel-userPopover-header-statusTag--state--active{
	background: var(--accentPurple500);
	color: $white;
}

.teamPanel-userPopover-header-statusTag--state--online{
	background: $white;
	color: var(--text600);
	//border: 1px solid var(--line300);
	background: rgba(246,246,249);
}

.teamPanel-userPopover-header-statusTag--state--away{
	//background: rgba(251,251,251);
	color: var(--text300);
	opacity: 0.8;
}

.teamPanel-userPopover-header-statusTag-dot{
	width: 6px;
	height: 6px;
	border-radius: 50%;

	margin-right: 4px;
	margin-left: -2px;

	border: 1px solid $invisible;
}

.teamPanel-userPopover-header-statusTag--state--online
.teamPanel-userPopover-header-statusTag-dot{
	background: var(--accentGreen500);
}

.teamPanel-userPopover-header-statusTag--state--away
.teamPanel-userPopover-header-statusTag-dot{

	background: $white;
	border: 1px solid var(--line600);
}

//
//

.teamPanel-userPopover-slackMessage{
	margin-top: 2px;

	height: 36px;

	width: 100%;
	background: rgb(246,246,248);
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text300);
}

.teamPanel-userPopover-slackMessage:hover{
	background: rgb(240,240,244);
}

.teamPanel-userPopover-contributions{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12.5px;
	font-weight: $lato-regular;
	color: var(--text200);

	margin-top: 7px;
	margin-bottom: 2px;
}

.teamPanel-userPopover-contributions:hover{
	text-decoration: underline;
	color: var(--text400);
}



@import 'TeamPanel-Popover-ActionBtn'; 