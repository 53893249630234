
//
// COLORS
//


//
// GREY

.linearIssueWorkflowStateIconContainer--workflowColorName--grey{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--grey--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--grey--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--grey--yarn);
	}
}

//
// LIGHT GREY

.linearIssueWorkflowStateIconContainer--workflowColorName--lightGrey{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--lightGrey--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--lightGrey--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--lightGrey--yarn);
	}
}


//
// DARK GREY

.linearIssueWorkflowStateIconContainer--workflowColorName--darkGrey{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--darkGrey--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--darkGrey--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--darkGrey--yarn);
	}
}

//
// PURPLE

.linearIssueWorkflowStateIconContainer--workflowColorName--purple{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--purple--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--purple--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--purple--yarn);
	}
}


//
// TEAL

.linearIssueWorkflowStateIconContainer--workflowColorName--teal{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--teal--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--teal--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--teal--yarn);
	}
}


//
// DARK TEAL

.linearIssueWorkflowStateIconContainer--workflowColorName--darkTeal{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--darkTeal--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--darkTeal--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--darkTeal--yarn);
	}
}


//
// GREEN

.linearIssueWorkflowStateIconContainer--workflowColorName--green{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--green--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--green--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--green--yarn);
	}
}


//
// DARK GREEN

.linearIssueWorkflowStateIconContainer--workflowColorName--darkGreen{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--darkGreen--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--darkGreen--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--darkGreen--yarn);
	}
}

//
// YELLOW

.linearIssueWorkflowStateIconContainer--workflowColorName--yellow{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--yellow--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--yellow--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--yellow--yarn);
	}
}


//
// BROWN

.linearIssueWorkflowStateIconContainer--workflowColorName--brown{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--brown--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--brown--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--brown--yarn);
	}
}

//
// ORANGE

.linearIssueWorkflowStateIconContainer--workflowColorName--orange{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--orange--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--orange--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--orange--yarn);
	}
}

//
// DARK ORANGE

.linearIssueWorkflowStateIconContainer--workflowColorName--darkOrange{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--darkOrange--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--darkOrange--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--darkOrange--yarn);
	}
}


//
// PINK

.linearIssueWorkflowStateIconContainer--workflowColorName--pink{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--pink--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--pink--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--pink--yarn);
	}
}


//
// TEAL

.linearIssueWorkflowStateIconContainer--workflowColorName--red{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--red--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--red--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--red--yarn);
	}
}


//
// TEAL

.linearIssueWorkflowStateIconContainer--workflowColorName--darkRed{
	.linearIssueWorkflowStateIcon--backlog,
	.linearIssueWorkflowStateIcon--unstarted,
	.linearIssueWorkflowStateIcon--started{
		border-color: var(--darkRed--yarn);
	}

	.linearIssueWorkflowStateIcon--completed,
	.linearIssueWorkflowStateIcon--triage,
	.linearIssueWorkflowStateIcon--canceled{
		background: var(--darkRed--yarn);
	}

	.linearIssueWorkflowStateIcon--started svg{
		fill: var(--darkRed--yarn);
	}
}
