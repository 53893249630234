.playgroundPage-issuePlayground{
	width: 650px;
	position: absolute;
	top: 200px;
	left: 50%;
	transform: translateX(-50%);
}

.playgroundPage-issuePlayground .doc-para{
	margin-bottom: 12px;
}

//
//

.issue{
	// background: $tempr;
	// outline: 1px solid red;
	position: relative;

	cursor: pointer;
	user-select: none;

	border-radius: 5px;
}

$height-inlineIssue: 34px;
$height-expandedIssue: 160px;
$edgePadding-expandedIssue: 15px; // distance above and below the box

.issue--inline{
	height: $height-inlineIssue;

}

// .issue--inline:hover{
// 	background: var(--250gray);
// }



.issue--expanded{
	height: calc(#{$height-expandedIssue} + (2 * #{$edgePadding-expandedIssue}));
}


$widthStatus-inlineIssue: 30px;
$widthPriority-inlineIssue: 30px;
$widthIssueID-inlineIssue: 80px;
$widthAssignee-inlineIssue: 30px;


//
.issue--inline-priorityContainer{
	position: absolute;
	top: 0px;
	height: $height-inlineIssue;
	width: $widthPriority-inlineIssue;
	left: calc(#{$widthPriority-inlineIssue} * -1);
	display: flex;
	align-items: center;
	justify-content: center;
	// outline: 1px solid green;
}

.issue--inline-statusContainer{
	position: absolute;
	top: 0px;
	height: $height-inlineIssue;
	width: $widthStatus-inlineIssue;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 2px;
	// outline: 1px solid orange;
}


.issue--inline-issueIDContainer{
	position: absolute;
	top: 0px;
	height: $height-inlineIssue;
	width: $widthIssueID-inlineIssue;
	right: $widthAssignee-inlineIssue;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	z-index: 5;
	// outline: 1px solid purple;
}

.issue--inline-assigneeContainer{
	position: absolute;
	top: 0px;
	height: $height-inlineIssue;
	width: $widthAssignee-inlineIssue;
	right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	// outline: 1px solid blue;
}


.issue-title{
	position: absolute;
	top: 0px;
	left: $widthStatus-inlineIssue;
	height: $height-inlineIssue;
	right: calc(#{$widthIssueID-inlineIssue} + #{$widthAssignee-inlineIssue});
	display: flex;
	align-items: center;
	// background: $tempb;

	z-index: 5;

	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
}

.issue-title:hover{
	// background: $tempr;
}

$titleTranslate-expandedIssue: calc(#{$edgePadding-expandedIssue} + 10px);

.issue--expanded
.issue-title{
	transform: translateY($titleTranslate-expandedIssue); // 10px is top inner padding of box
}

//
// EXPANDED

$heightActionRow-expandedIssue: 40px;

.issue--expanded-descriptionContainer{
	position: absolute;
	left: $widthStatus-inlineIssue;
	right: calc(#{$widthIssueID-inlineIssue} + #{$widthAssignee-inlineIssue});
	top: calc(#{$titleTranslate-expandedIssue} + #{$height-inlineIssue});
	bottom: calc(#{$heightActionRow-expandedIssue} + #{$edgePadding-expandedIssue});	

	// background: $tempr;
}

.issue--expanded-actionRowContainer{
	position: absolute;
	left: $widthStatus-inlineIssue;
	right: 0px;
	top: 130px; // bad
	height: $heightActionRow-expandedIssue;

	display: flex;
	align-items: center;
}

.issue--expanded-issueIconContainer{
	position: absolute;
	left: 0px;
	top: $titleTranslate-expandedIssue;
	margin-top: 3px;
	margin-left: -2px;
	width: $widthStatus-inlineIssue;
	height: $widthStatus-inlineIssue;

	display: flex;
	align-items: center;
	justify-content: center;
}

.issue--expanded-topRightActions{
	position: absolute;
	top: $edgePadding-expandedIssue;
	right: 0px;
	width: calc(#{$widthIssueID-inlineIssue} + #{$widthAssignee-inlineIssue});
	height: 40px;

	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}


//

.issueBG{
	// background: $tempb;
	border: 1px solid $invisible;
	position: absolute;
	
	left: 0px;
	top: 0px;
	z-index: 1;
	border-radius: 4px;
}


.issue--inline
.issueBG{

	transition: border 200ms linear;	
	transition: background 200ms linear;	
}

.issue--expanded
.issueBG{	
	border: 1px solid var(--line300);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);	
	background: rgba(255,255,255,0.02);

	transition: border 500ms linear;
	transition: background 200ms linear;	
}

[data-theme="dark"]
.issue--expanded
.issueBG{
	border: 1px solid rgba(255,255,255,0.08);
}


//
// ANIMATE

.issue--inline .issue--inline-priorityContainer,
.issue--inline .issue--inline-statusContainer,
.issue--inline .issue--inline-assigneeContainer{
	opacity: 1;
	transform: translateY(0px);

	transition: all 150ms linear;
	transition-delay: 0ms;
}

.issue--expanded .issue--inline-priorityContainer,
.issue--expanded .issue--inline-statusContainer,
.issue--expanded .issue--inline-assigneeContainer{
	opacity: 0;
	transform: translateY(24px);
	pointer-events: none;

	transition: all 150ms linear;
	transition-delay: 0ms;	
}





.issue--inline .issue--expanded-actionRowContainer{
	opacity: 0;	

	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.issue--expanded .issue--expanded-actionRowContainer{
	opacity: 1;	
	pointer-events: none;

	transition: opacity 100ms linear;
	transition-delay: 100ms;	
}




//
// SMALL BITS

.issue-title{
	box-sizing: border-box;
	padding-left: 4px;

	font-size: 16.75px;		
	font-weight: $lato-regular;
	letter-spacing: 0.005em;	
	color: var(--text500);
	line-height: 1.45;
}

.issue--inline-status-circle{
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid var(--line500);
	background: var(--bg-docEditor);
}

.issue--inline-priority{
	width: 13px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.issue--inline-priority-bar{
	width: 3px;
	background: var(--line500);
	border-radius: 2px;
}

.issue--inline-priority-bar:nth-child(1){	
	height: 6px;
}

.issue--inline-priority-bar:nth-child(2){
	height: 9px;
}

.issue--inline-priority-bar:nth-child(3){
	height: 12px;
}

.issue--inline-issueIDContainer{
	font-size: 12px;
	font-weight: $lato-regular;
	text-transform: uppercase;
	letter-spacing: 0.008em;
	color: var(--text100);

	box-sizing: border-box;
	padding-right: 8px;
}

.issue--inline-assignee{
	width: 24px;
	height: 24px;
}

.issue--inline-assignee-img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;

}

.issue--expanded-descriptionContainer{
	box-sizing: border-box;
	padding-left: 4px;
	margin-top: 2px;
	font-size: 15.2px;
	font-weight: $lato-regular;
	color: rgb(140,140,150);

	letter-spacing: 0.001em;
}

[data-theme="dark"] .issue--expanded-descriptionContainer{
	color: rgb(190,190,200);
}

.issue--expanded-description-placeholder{
	color: rgb(180,180,190);
}

[data-theme="dark"] .issue--expanded-description-placeholder{
	color: rgb(120,120,130);
}



.issue--expanded-issueIcon{
	width: 22px;
	height: 22px;
	background: var(--243gray);
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.issue--expanded-issueIcon-circle{
	width: 9px;
	height: 9px;
	border-radius: 50%;
	border: 3px solid var(--bg-docEditor);
}


.issue--expanded-actionRowContainer{
	display: flex;
}

.issue--expanded-actionRow-btn{
	width: 110px;
	height: 28px;
	background: var(--253gray);
	border-radius: 4px;
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: var(--text300);
	margin-right: 5px;

	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 4px;
}

.issue--expanded-actionRow-btn--iconOnly{
	width: 50px;
}

.issue--expanded-actionRow-btn-iconContainer{
	width: 20px;
	height: 20px;
	background: rgba(0,0,0,0.04);
	border-radius: 5px;
	margin-right: 2px;
}

//

.issue--expanded-topRightActions-btn{
	width: 24px;
	height: 24px;
	margin-top: 24px;
	margin-right: -2px;
}

.issue--expanded-topRightActions-btn svg{
	width: 18px;
	height: 18px;

	fill: var(--text600);
	opacity: 0.5;
}

