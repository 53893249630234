//
// PROJECT ROW

.meetingProjectListRowContainer{

}

.meetingProjectListRow{
	width: 100%;
	
	height: 30px;	
	height: 32px;	

	display: flex;
	align-items: center;
	
	box-sizing: border-box;

	

	border-radius: 3px;

	padding-left: 10px;
	padding-right: 10px;

	position: relative;

  margin-left: -10px;
  width: calc(100% + 20px);


	border: 1px solid $invisible;
	// border-bottom: 1px solid var(--line200);
	user-select: none;

	// background: $tempr;

	
}

// .meetingProjectListRow:hover{
// 	background: var(--253gray);
// 	//outline: 1px solid vara(--line100);
// }


.meetingProjectListRow[data-state="open"],
.meetingProjectListRow--popoverOpen{
	background: var(--253gray);
}


.meetingProjectListRow-button{
	flex-grow: 0;
	flex-shrink: 0;

	margin-right: 2px;
	margin-left: 4px;

	height: 30px;
	padding-right: 8px;
	padding-left: 8px;

	border-radius: 3px;

	font-size: 14px;
}

.meetingProjectListRow-button:hover{
	background: $tempb;
}

// Status Marker



.meetingProjectListRow-statusCol{
	width: 28px;
	height: 28px;
	margin-left: -5px; // optical align column
	margin-left: -8px; // optical align column

	display: flex;
	align-items: center;

	// margin-right: 1px;

	flex-grow: 0;

	// background: $tempr;
}


.meetingProjectListRow-status{		
	height: 28px;
	width: 28px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;	
}

.meetingProjectListRow-status:hover,
.meetingProjectListRow-statusOuterContainer[data-state="open"] .meetingProjectListRow-status{
	background: var(--246gray);
}

.meetingProjectListRow-status-markerContainer{
	// width: 20px;
	// height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	// margin-right: 1px;
}

.meetingProjectListRow-status .linearProjectStatusIcon{
	// transform: scale(0.8);
	// transform-origin: 50% 50%;
}

// Project Marker



// Name

.meetingProjectListRow-nameCol{
	width: 200px;
	flex-grow: 1;
	flex-shrink: 1;
}

.meetingProjectListRow-name{
	padding-left: 2px;
	padding-right: 9px;

	height: 28px;
	cursor: pointer;
	border-radius: 3px;
}

.meetingProjectListRow-name:hover{
	background: var(--250gray);
}


.meetingProjectListRow-name-markerContainer{
	height: 24px;
	width: 24px;	

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	// margin-left: -6px;
	margin-right: 6px;
}

.meetingProjectListRow-name-markerContainer
.linearMarker{
	font-size: 17px;
}

.meetingProjectListRow-name-markerContainer
.linearMarker svg{
	width: 17px;
	height: 17px;
}

.meetingProjectListRow-name-label{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;

	font-size: 16.75px;
	font-weight: $lato-semibold;
	letter-spacing: 0.001em;
	color: var(--text500);
	position: relative;
	top: -0.5px;

	// background: $tempr;

	//text-decoration: underline;
	//text-decoration-color: var(--line600);
}

[data-theme="dark"]{
	.meetingProjectListRow-name{
		letter-spacing: 0.0025em;
		letter-spacing: 0.008em;
	}
}


//


//
// SUMMARY

.meetingProjectListRow-linearSummary{
	padding-right: 7px;
	padding-left: 7px;
	height: 28px;

	border-radius: 3px;
}


.meetingProjectListRow-linearSummary-progressCircleContainer{
	width: 14px;
	height: 14px;
	// background: $tempr;

	margin-right: 5px;
	position: relative;
	// top: 1px;
	top: -0.7px;
}

.meetingProjectListRow-linearSummary-progressLabel,
.meetingProjectListRow-linearSummary-scope-label{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: var(--text500);
	opacity: 0.8;
}

.meetingProjectListRow-linearSummary-progressLabel span{
	opacity: 0.75;
}

.meetingProjectListRow-linearSummary-scopeDot{
	width: 4px;
	height: 4px;
	border-radius: 1px;
	background: var(--text600);
	opacity: 0.5;
	margin-right: 5px;
	margin-left: 6px;
}

.meetingProjectListRow-linearSummary:hover{
	background: var(--250gray);
}

.meetingProjectListRow-linearSummary:hover .meetingProjectListRow-linearSummary-progressLabel,
.meetingProjectListRow-linearSummary:hover .meetingProjectListRow-linearSummary-scope-label{
	opacity: 0.9;
}

.meetingProjectListRow-linearSummary[data-state="active"],
.meetingProjectListRow-linearSummary[data-state="active"]:hover{
	background: var(--246gray);
}

.meetingProjectListRow-linearSummary[data-state="active"] .meetingProjectListRow-linearSummary-progressLabel,
.meetingProjectListRow-linearSummary[data-state="active"] .meetingProjectListRow-linearSummary-scope-label,
.meetingProjectListRow-linearSummary[data-state="active"]:hover .meetingProjectListRow-linearSummary-progressLabel,
.meetingProjectListRow-linearSummary[data-state="active"]:hover .meetingProjectListRow-linearSummary-scope-label{
	opacity: 1;
}

.meetingProjectListRow-linearSummary .CircularProgressbar {  
  width: 100%; 
}

.meetingProjectListRow-linearSummary .CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  //stroke: var(--primary500);
  stroke: var(--purple--yarn);
  stroke-linecap: round;

  transition: stroke-dashoffset 0.5s ease 0s;
}

.meetingProjectListRow-linearSummary .CircularProgressbar .CircularProgressbar-trail {  
  stroke: var(--purple--yarn);
  opacity: 0.35;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.meetingProjectListRow-linearSummary .CircularProgressbar .CircularProgressbar-background {
  //fill: #d6d6d6;
}






//
//

.meetingProjectListRow-targetDateCol{
	width: 102px;
	flex-grow: 0;

	box-sizing: border-box;

	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	margin-right: -10px;
}


.meetingProjectListRow-targetDateOuterContainer{
	//margin-left: auto;
	//margin-right: -16px;
	// width: 100%;
	
	display: flex;
	align-items: center;
	background: $tempr;
	display: flex;	
}

.meetingProjectListRow-targetDateContainer--add{
	opacity: 0.5;
}

.meetingProjectListRow-targetDateContainer--add:hover{
	opacity: 1;
}

.meetingProjectListRow-targetDateContainer{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.meetingProjectListRow-targetDate{
	display: flex;
	//height: 26px;
	height: 28px;

	//background: var(--253gray);

	position: relative;
	//top: 1.2px; // maybe better way of aligning

	// width: 100%;

	// background: $tempr;
	cursor: pointer;

	align-items: center;
	// margin-left: 7px;

	padding-left: 6px;
	padding-right: 8px;

	border-radius: 3px;
}

.meetingProjectListRow-targetDate-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 4px;
	margin-left: auto;
}

.meetingProjectListRow-targetDate-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text300);
	opacity: 0.6;
	position: relative;
	top: 0px;
}

.meetingProjectListRow-targetDate-label{
	// margin-left: auto;
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);

	opacity: 0.6;
}


.meetingProjectListRow-targetDate:hover,
.meetingProjectListRow-targetDateOuterContainer[data-state="open"] .meetingProjectListRow-targetDate{
	background: var(--246gray);
}

.meetingProjectListRow-targetDate:hover .meetingProjectListRow-targetDate-label,
.meetingProjectListRow-targetDateOuterContainer[data-state="open"] .meetingProjectListRow-targetDate-label{
	opacity: 0.8;
}

.meetingProjectListRow-targetDate:hover .meetingProjectListRow-targetDate-iconContainer svg,
.meetingProjectListRow-targetDateOuterContainer[data-state="open"] .meetingProjectListRow-targetDate-iconContainer svg{
	opacity: 0.8;
}



.meetingProjectListRow-targetDate--warning{
	//background: hsla(359, 83%, 68%, 0.05); // need it to be good in dark
	background: hsla(359, 83%, 68%, 0.05); // need it to be good in dark
	opacity: 1;
}

.meetingProjectListRow-targetDate--warning 
.meetingProjectListRow-targetDate-iconContainer svg{
	fill: var(--accentRed700);
	opacity: 0.5;
}

.meetingProjectListRow-targetDate--warning
.meetingProjectListRow-targetDate-label{
	color: var(--accentRed700);
	opacity: 0.8;
}


//
//

button.meetingProjectListRow-removeBtn{
	position: absolute;
	left: 100%;

	width: 20px;
	margin-left: 6px;
	
	top: 0px;
	bottom: 0px;

	//background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	transition: opacity 100ms linear;
}

.docEditor-meetingContent-linkedProjects-list:hover
button.meetingProjectListRow-removeBtn{
	opacity: 0.6;
}

.docEditor-meetingContent-linkedProjects-list:hover
button.meetingProjectListRow-removeBtn:hover{
	opacity: 1;
	transition-delay: 50ms;
}

.meetingProjectListRow-removeBtn-inner{
	height: 20px;
	width: 22px;
	
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);
}

	



.meetingProjectListRow-removeBtn-inner svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);
	opacity: 0.6;
}


//

// .meetingProjectListRow-progressCol{
// 	width: 200px;
// 	flex-grow: 0;

// 	display: flex;
// 	align-items: center;
// }


//
// STATUS





//










// //

// .meetingProjectListRow-leadAvatarCol{
// 	width: 24px;
// 	height: 24px;

// 	margin-left: 12px;

// 	border-radius: 4px;
// 	overflow: hidden;
// }


// .meetingProjectListRow-leadAvatarContainer--hasLead{
// 	//transform: scale(0.97);
// 	//transform-origin: center center;
// }

// img.selectUserPopover-item-avatar{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;	
// }

// .meetingProjectListRow-leadAvatarContainer--noLead{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .meetingProjectListRow-leadAvatarContainer--noLead:hover{
// 	//background: var(--250gray);
// }

// .meetingProjectListRow-leadAvatarContainer svg{
// 	width: 20px;
// 	height: 20px;
// 	fill: var(--text500);
// 	opacity: 0.4;
// }

// .meetingProjectListRow-leadAvatarContainer--noLead:hover svg,
// .meetingProjectListRow-leadAvatarOuterContainer[data-state="open"] .meetingProjectListRow-leadAvatarContainer--noLead svg{
// 	opacity: 0.6;
// 	transform: scale(1.02);
// }






//.meetingProjectListRow-teamTagContainer{
// 	display: flex;
// 	align-items: center;
// 	gap: 3px;
// 	height: 100%;
// }

// .meetingProjectListRow-teamTag{
// 	padding-left: 6px;
// 	padding-right: 6px;
	
// 	height: 20px;

// 	font-size: 13px;
// 	font-weight: $lato-medium;
// 	color: var(--text200);
// 	letter-spacing: 0.025em;

// 	text-transform: uppercase;

// 	//background: var(--bgSideMenuTabLight);
// 	// border: 1px solid var(--line300);
// 	background: var(--253gray);
// 	border-radius: 4px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .meetingProjectListRow-teamTag{
// 	opacity: 1;
// 	// transition: opacity 50ms linear;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag{
// 	background: var(--243gray);
// }


// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag{
// 	opacity: 1;
// 	transition: opacity 150ms linear;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team1{
// 	transition-delay: 50ms;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team2{
// 	transition-delay: 100ms;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team3{
// 	transition-delay: 150ms;
// }

// //

// .meetingProjectListRow-dropdownBtn{
// 	height: 24px;
// 	width: 24px;

// 	margin-left: 6px;

// 	border-radius: 4px;

// 	margin-right: 4px;
// }

// .meetingProjectListRow-dropdownBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .meetingProjectListRow
// .meetingProjectListRow-dropdownBtn{
// 	opacity: 0;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn{
// 	opacity: 1;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn svg{
// 	opacity: 0.5;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn:hover{
// 	background: var(--250gray);	
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn:hover svg{
// 	opacity: 1;
// }



// // DIVIDER
// .meetingProjectListRowDateDivider{
// 	font-size: 14px;	
// 	font-weight: $lato-regular;
// 	letter-spacing: 0.01em;

	
// 	text-align: left;

// 	color: var(--text600);
// 	opacity: 0.5;

// 	margin-top: 14px;
// 	margin-bottom: 6px;

// 	user-select: none;
// 	cursor: default;

// 	padding-left: 3px;
// }

// // PROJECT

// .meetingProjectListRow{
// 	width: 100%;
// 	height: 38px;
// 	// background: $tempb;
// 	// outline: 1px solid blue;

// 	display: flex;
// 	align-items: center;

// 	box-sizing: border-box;

// 	cursor: pointer;

// 	border-radius: 5px;

// 	padding-left: 8px;
// 	margin-left: -8px;
// 	width: calc(100% + 8px);

// 	border: 1px solid $invisible;

// }

// .meetingProjectListRow:hover{
// 	background: var(--253gray);
// 	border: 1px solid var(--line100);
// }

// .meetingProjectListRow-emoji{
// 	font-size: 18px;
	
// 	height: 28px;
// 	width: 28px;

// 	margin-right: 8px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	background: var(--bgSideMenuTabLight);
// 	border-radius: 4px;
// }

// .meetingProjectListRow-name{
// 	font-size: 16px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.001em;
// 	color: var(--text500);

// 	margin-right: auto;
// }

// .meetingProjectListRow-teamTag{
// 	padding-left: 4px;
// 	padding-right: 4px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

// 	font-size: 11.5px;
// 	font-weight: $lato-medium;
// 	color: var(--text300);
// 	letter-spacing: 0.025em;

// 	text-transform: uppercase;

// 	//background: var(--bgSideMenuTabLight);
// 	border: 1px solid var(--line200);
// 	border-radius: 2px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: 4px;
// }

// .meetingProjectListRow-teamTag{
// 	opacity: 0.65;
// 	transition: opacity 50ms linear;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag{
// 	opacity: 1;
// 	transition: opacity 150ms linear;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team1{
// 	transition-delay: 50ms;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team2{
// 	transition-delay: 100ms;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-teamTag--team3{
// 	transition-delay: 150ms;
// }


// .meetingProjectListRow-leadAvatarContainer{
// 	width: 26px;
// 	height: 26px;

// 	margin-left: 12px;

// 	border-radius: 4px;
// 	overflow: hidden;
// }

// img.selectUserPopover-item-avatar{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;

	
// }

// .meetingProjectListRow-dropdownBtn{
// 	height: 24px;
// 	width: 24px;

// 	margin-left: 6px;

// 	border-radius: 4px;

// 	margin-right: 4px;
// }

// .meetingProjectListRow-dropdownBtn svg{
// 	width: 16px;
// 	height: 16px;
// 	fill: var(--text600);
// 	opacity: 0.5;
// }

// .meetingProjectListRow
// .meetingProjectListRow-dropdownBtn{
// 	opacity: 0;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn{
// 	opacity: 1;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn svg{
// 	opacity: 0.5;
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn:hover{
// 	background: var(--250gray);	
// }

// .meetingProjectListRow:hover
// .meetingProjectListRow-dropdownBtn:hover svg{
// 	opacity: 1;
// }


// .projectsPage-projectRow--lastItemInGroup ~ .meetingProjectListRow-milestonesContainer{ 
// 	margin-top: -20px;
// 	margin-bottom: 8px;
// }

// fix for nonstatus grouped

.meetingProjectListRow-milestonesContainer{
	padding-bottom: 6px;
	box-sizing: border-box;

	padding-left: 31px;

	position: relative;
	//opacity: 0.9;

	// background: $tempr;
	margin-top: -2px;
	z-index: 5;
	
}

.meetingProjectListRowContainer:hover 
.meetingProjectListRow-milestonesContainer{
	opacity: 1;
	
}

.meetingProjectListRow-milestonesContainer-line{
	position: absolute;
	top: 7px;
	left: 5px;
	width: 1px;
	bottom: 12px;

	background: var(--line200);
}


.meetingProjectListRow-milestoneRow{
	width: 100%;
	
	height: 26px;	

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;

	cursor: default;

	border-radius: 4px;

	padding-left: 4px;
	padding-right: 12px;

  margin-left: -14px;
  width: calc(100% + 14px);

	border: 1px solid $invisible;
	user-select: none;

	// background: $tempr;

	// background: $tempr;
}

.meetingProjectListRow-milestoneRow--completed{
	opacity: 0.5;	
}

.meetingProjectListRowContainer:hover 
.meetingProjectListRow-milestoneRow--completed{
	opacity: 1;

}

.meetingProjectListRow-milestoneRow-iconContainer{
	width: 24px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;

	// background: $tempr;
	box-sizing: border-box;
	padding-bottom: 1px;
}

.meetingProjectListRow-milestoneRow-iconContainer
.linearMilestoneIcon{
	transform: scale(0.9);
}

.meetingProjectListRow-milestoneRow-iconContainer
.meetingProjectListRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg{
	// transition: all 200ms linear;
	// opacity: 0.75;
}

.meetingProjectListRowContainer:not(:hover)
.meetingProjectListRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg #bg{
	stroke: var(--line600) !important;
}

.meetingProjectListRowContainer:not(:hover)
.meetingProjectListRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg #progress{
	stroke: var(--line600) !important;
}

.meetingProjectListRowContainer:not(:hover)
.meetingProjectListRow-milestoneRow-iconContainer .linearMilestoneIcon--completed svg #bg{
	// stroke: var(--line600) !important;	
	// fill: var(--line600) !important;

	// dirty, opaqure version of above
	stroke: #6E6D70 !important;	
	fill: #6E6D70 !important;
	
}


.meetingProjectListRow-milestoneRow-name{
	font-size: 15.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.008em;


	display: flex;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingProjectListRow-milestoneRow-name-name{
	// background: $tempr;
	color: var(--text400);
	letter-spacing: 0.008em;
	height: 26px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 2px;
	cursor: pointer;
}

[data-theme="dark"]{
	.meetingProjectListRow-milestoneRow-name-name{
		letter-spacing: 0.017em;
	}
}


.meetingProjectListRow-milestoneRow-name-name:hover{
	background: var(--250gray);
}

.meetingProjectListRow-milestoneRow-name-milestoneStats{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text400);

	position: relative;
	top: 1px;

	margin-left: 6px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	
	flex-grow: 1;
	flex-shrink: 1;

	opacity: 0.8;
	transition: opacity 100ms linear;
}


.meetingProjectListRow-milestoneRow-name-milestoneStats-dash{
	margin-left: 3px;
	margin-right: 3px;
}

.meetingProjectListRow-milestoneRow-name-milestoneStats-total{
	// min-width: 18px;
	text-align: right;
}

//

.meetingProjectListRow-milestoneRow-name-milestoneStats-dash,
.meetingProjectListRow-milestoneRow-name-milestoneStats-total{
	opacity: 0;
}

.meetingProjectListRow-milestoneRow-name-milestoneStats:hover
.meetingProjectListRow-milestoneRow-name-milestoneStats-dash,
.meetingProjectListRow-milestoneRow-name-milestoneStats:hover
.meetingProjectListRow-milestoneRow-name-milestoneStats-total{
	opacity: 0.9;
	transition: opacity 100ms linear;
	transition-delay: 0ms;
}
