:root{	
	--outsideLine-dropdown: 						1px solid rgb(207,207,211);
	--outsideTopLine-dropdown: 					1px solid rgb(217,217,211);
	--outsideBottomLine-dropdown: 			1px solid rgb(197,197,201);
	
	--hoverBG-item-dropdown: 						rgb(242,240,247);	
	--activeBG-suggestionItem: 					rgb(238,238,243);
	--expandedSubBG-item-dropdown: 			rgb(248,246,252);

	--separatorBG-dropdown:					 		rgb(235,235,240);

	--bg-dropdown: 											rgb(255,255,255);
	--boxShadow-dropdown: 							0px 4px 8px 0px rgba(0,0,0,0.12);

	--boxShadow-dialog: 								0px 8px 16px 0px rgba(0,0,0,0.12);
}

// DARK THEME

[data-theme="dark"], .forceDarkTheme{

	--outsideLine-dropdown: 						1px solid rgb(60,60,64);
	--outsideTopLine-dropdown: 					1px solid rgb(74,74,78);
	--outsideBottomLine-dropdown: 			1px solid rgb(56,56,60);

	--hoverBG-item-dropdown: 						rgb(58,53,62);
	--activeBG-suggestionItem: 					rgb(58,53,62);
	--expandedSubBG-item-dropdown: 			rgb(48,44,54);

	--separatorBG-dropdown:					 		rgb(58,55,60);

	--bg-dropdown:											rgb(35,31,38); // need to manually update if change
	--boxShadow-dropdown: 							0px 4px 8px 0px rgba(0,0,0,0.24);

	--boxShadow-dialog: 								0px 8px 16px 0px rgba(0,0,0,0.32);

}