//
// PRIMARY COLOR (BLUE)
//

// may need semi-opaque options

:root{
	//--primary1200: TBC;
	//--primary1100: TBC;
	//--primary1000: TBC;
	//--primary900: TBC;
	--primary800: hsl(213, 100%, 35%); // 15% darken equiv
	--primary700: hsl(213, 100%, 40%); // 10% darken equiv
	--primary600: hsl(213, 100%, 45%); // 5% darken equiv
	--primary550: hsl(213, 100%, 48%); // for e.g. hover on default
	--primary500: hsl(213, 100%, 50%); // default
	--primary450: hsl(213, 100%, 55%); // 90% opacity aka transparentize 0.1 equiv
	--primary400: hsl(213, 100%, 60%); // 80% opacity aka transparentize 0.2 equiv
	//--primary300: TBC;
	--primary250: hsl(213, 100%, 55%); // 50% opacity aka transparentize 0.5 equiv
	--primary100: hsl(213, 100%, 90%); // 20% opacity aka transparentize 0.80 equiv
	--primary75: 	hsl(213, 100%, 93%); // 15% opacity aka transparentize 0.85 equiv
	--primary50: 	hsl(212, 100%, 95%); // 10% opacity aka transparentize 0.90 equiv
	--primary25: 	hsl(212, 100%, 97%); // 05% opacity aka transparentize 0.95 equiv

}

// not sure what shoudl happen, i think normal blue should get brighter and more saturated
// not sure if the color scale inverts, check radix 

[data-theme="dark"]{
	//--primary1200: TBC;
	//--primary1100: TBC;
	//--primary1000: TBC;
	//--primary900: TBC;
	// --primary800: hsl(213, 100%, 35%); // 15% darken equiv
	// --primary700: hsl(213, 100%, 40%); // 10% darken equiv
	// --primary600: hsl(213, 100%, 45%); // 5% darken equiv
	// --primary500: hsl(213, 100%, 50%); // default
	// --primary450: hsl(213, 100%, 55%); // 90% opacity aka transparentize 0.1 equiv
	--primary400: #005CCC; // 80% opacity aka transparentize 0.2 equiv
	// //--primary300: TBC;
	--primary100: #001733; // 20% opacity aka transparentize 0.80 equiv
	--primary75: 	#001126; // 15% opacity aka transparentize 0.85 equiv
	--primary50: 	#000B1A; // 10% opacity aka transparentize 0.90 equiv
	--primary25: 	#00060D; // 05% opacity aka transparentize 0.95 equiv
}