//
// APP.SCSS 
//

///NiCOLE
.tabbar {
  border: 1px solid black;
  margin: 1rem;
  padding: 2rem 2rem;
  text-align: center;
}
.tabbarChild {
  display: inline-block;
  border: 1px solid red;
  padding: 1rem 1rem;
  vertical-align: middle;
}

.figmaTestContainer{
  position: fixed;
  z-index: 999999;
  background: white;
  width: 50vw;
  height: 50vh;
}


/////Nicole Tables!!!
.ProseMirror .tableWrapper {
  overflow-x: auto;
}
.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}
.ProseMirror td,
.ProseMirror th {
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.ProseMirror .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}
.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
/* Give selected cells a blue overlay */
.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
 .ProseMirror table {
    margin: 0;
  }

  .ProseMirror th,
  .ProseMirror td {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 3px 5px;
  }

  .ProseMirror .tableWrapper {
    margin: 1em 0;
  }



/////


@import "NormalizeV8.scss";

html{
  // background: white;
}

body {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;  
  overflow-x: hidden;
}

.app{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;  

  overflow-y: scroll;
}



// $textWidth-doc: 580px;
$textWidth-doc: 650px; // maybe for editing

$bleedWidth-doc: 720px;
$fullWidth-doc: 720px;
$outsideGutter-doc: 20px;

$edgeMarginTextToDoc: calc((#{$fullWidth-doc} - #{$textWidth-doc}) / 2);
$negativeEdgeMarginTextToDoc: calc(((#{$fullWidth-doc} - #{$textWidth-doc}) / 2) * -1);

$imageWidth-doc: $bleedWidth-doc;
$videoWidth-doc: $bleedWidth-doc;

$maxWidth-comments: 480px;

$introVideoWidth-doc: 380px;
$introVideoHeight-doc: 440px;


$width-sideBar: 250px;
$height-tabBar: 40px;

//

$width-sideMenu: 250px; // probably switch to poly-fluid layout
$windowMargin-sideMenuMode: 8px; // space around edge of floating doc in sidemenu mode

//

$width-teamPanel: 200px;
$width-teamPanel--narrow: 36px;


@import "Colors.scss";
@import "Type.scss";



@import "mixins/Poly-Fluid-Sizing.scss";
@import "CoreLayout.scss";
@import "MacDots.scss"; // show grey mac dots until on hover

@import "ButtonReset.scss";
@import "StageIcons.scss";
@import "LinearIssueIcons.scss";
@import "LinearProjectIcons.scss";
@import "LinearProjectStatusTag.scss";
@import "LinearMarkers.scss";
@import "LinearMilestoneIcon.scss";
@import "MeetingTypeIcons.scss";
@import "FigmaStatusIcons.scss";

@import "KeyboardShortcutsPanel.scss";

@import "Tooltips.scss";
@import "Dropdowns.scss";
@import "insertMentionsMenu/DocInsertMentionsMenu.scss";
@import "insertMentionsMenu/MessageInsertMentionsMenu.scss";
@import "Popovers.scss";
@import "SelectUserPopover.scss";
@import "MoveDocPopover.scss";
@import "Dialogs.scss";
@import "Avatar.scss";
@import "SignIn.scss"; // new page
@import "SignInPageTest.scss"; // new page

@import "EditProjectModal.scss";
@import "SetupTeamSyncModal.scss";

@import "Login.scss";

@import "LoadingPage.scss";

@import "onboarding/Onboarding.scss";

@import "Toast.scss";

@import "SettingsDialog.scss";
@import "SettingsPage.scss";
@import "TabSwitchMenu.scss";

@import "recordingPlaybackModal/RecordingPlaybackModal.scss";

@import "VideoJS.scss";

@import "OpenDocMenu.scss";

@import "DatePicker.scss";
@import "StaticCheckbox.scss";
@import "ParticipantList.scss";

@import "ElectronTabBar.scss";
@import "sideMenu/SideMenu.scss";

@import "popovers/EditMetadataPopover.scss";

@import "TeamPanel.scss";
@import "ActiveRecordingUploadPanel.scss";

@import "templateModal/TemplateModal.scss";

@import "templateModal/TemplateModal.scss";

@import "messages/Messages.scss";

@import "libraryPages/LibraryDocTable.scss";
@import "libraryPages/LibraryPages.scss";


@import "docEditor/DocEditor.scss";
@import "doc/Doc.scss";

@import "CommandMenu.scss";
@import "ActionCommandMenu.scss"; // refactor into above
@import "DNDTest.scss";

@import "projects/Projects.scss";

@import "Playground.scss"; // something dodgy in here


@import "roadmaps/Roadmaps.scss";

@import "LinearIssuePreviewPopover.scss";
@import "LinearProjectPreviewPopover.scss";

@import "RedirectPage.scss";

@import "figma/FigmaView.scss";
@import "whiteboards/Whiteboard.scss";

//@import "MarketingCustomUI.scss";


// MAYBE
// @import "Sohne.scss";
// @import "NameSans.scss";
// @import "InterStyle.scss";
// @import "Graphik.scss";
// @import "ProximaNovaStyle.scss";

// PROBS NOT
// @import "Matter.scss";
// @import "SFStyle.scss";
// @import "AvenirNextStyle.scss";


//


.prosemirror-suggestion{
  //background: $tempr;
}


//
//

.docEditor-pendingTab{
  background: green;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}
.docEditor-pendingTab-fakeTabBar{
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  height: $height-tabBar;
  background: rgb(251,251,249);
}

.docEditor-pendingTab-contents{
  visibility: hidden !important;
}


//

.sideBar--docEditor{
  //visibility: hidden !important; // temporary
  width: 100px !important; // temporary
  // display: none !important;
}

.docEditor-sideBarSpacer{
  width: 62px;
}

.docEditor-paragraphRightPanel{
  display: none !important;
}

.collab-selection { background-color: #f242f5 }
.comment { background-color: #f242f5 }
.comment-active { background-color: #4287f5 }

// .selection-span { background-color: #f59d93 }



// //
// // MARKETING SIMPLIFY

// .sideMenu-bottomActionsContainer{
//   display: none !important;
// }

// .sideMenu-tabList-footer{
//   display: none !important;
// }

// .docEditor-topMenu-button--star{
//   display: none !important;
// }

// .docEditor-topMenu-button--star{
//   display: none !important;
// }

// .docEditor-topMenu-button--docLink{
//   display: none !important;
// }

// .sideMenu-header-top-btn--toggleInterface{
//   display: none !important;
// }

// .docEditor-topMenu-roadmapsContainer{
//   display: none !important;
// }

// .sideMenu-header-forwardBackButtons{
//   display: none !important;
// }

// .docEditor-topMenu-button--docChat{
//   display: none !important;
// }

// .docEditor-issuePanel-header-openLinearBtn{
//   display: none !important;
// }

// .docEditor-issuePanel-header-label{
//   opacity: 0.75;
//   padding-left: 14px;
// }