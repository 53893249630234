$borderRadius-doc-imageThumbnail: 2px;

//
// DOC IMAGE THUMBNAIL

.doc-imageThumbnail{
	margin-left: 2px;
	margin-right: 2px;

	height: 26px;
	width: 38px;

	position: relative;
	top: 7px;

	margin-top: -10px;

	cursor: pointer;
	// cursor: zoom-in;
	// cursor: nesw-resize;

	//border: 1px solid var(--line400);
	border-radius: $borderRadius-doc-imageThumbnail;

	overflow: hidden;

	display: inline-block;

	background: var(--bg-docEditor);

	position: relative;

	//transform: scale(1.07);
}

.doc-imageThumbnail:hover{
	// /background: $tempb;
	//border: 1px solid var(--line500);
	
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);
}

.doc-imageThumbnail img{
	width: 100%;
	height: 100%;

	object-fit: cover;

	// display: none;
}

.doc-imageThumbnail-overlayBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border: 0.5px solid rgba(0,0,0,0.3);

	border-radius: $borderRadius-doc-imageThumbnail;

	pointer-events: none;

	z-index: 5;

	
}



.doc-imageThumbnail:hover
.doc-imageThumbnail-overlayBorder{
	border: 0.5px solid rgba(0,0,0,0.5);
}


.doc-imageThumbnail-overlayIcon{
	z-index: 10;	
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	border-radius: $borderRadius-doc-imageThumbnail;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,0,0.35);

	opacity: 0;

	transition: opacity 50ms linear;


	pointer-events: none;

	
}

.doc-imageThumbnail-overlayIcon svg{
	fill: $white;

	width: 14px;
	height: 14px;

	opacity: 0.95;

}

.doc-imageThumbnail:hover
.doc-imageThumbnail-overlayIcon{
	opacity: 1;

	transition-delay: 1000ms;
}