
.docTextLinkPopoverContainer{	
	
}

.docTextLinkPopoverContainer:focus{
	outline: none;
}

.docTextLinkPopoverContainer--hidden,
.docTextLinkPopoverContainer--hidden *{
	pointer-events: none !important;
}


.doc-textLink-editMenuAnchor{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 100%;

	height: 5px;

	z-index: 10;
}

$sidePadding-editDocLinkPopover: 8px;

// .doc-textLink-popoverContainer--new{
// 	height: 225px;
// 	// background: $tempr;
// 	position: absolute;
// 	width: 402px;

// 	display: flex;
// 	flex-direction: column;

// 	pointer-events: none;
// }



// .doc-textLink-popoverContainer--edit{
// 	position: absolute;
// 	left: 50%;
// 	transform: translateX(-50%);
// }



.doc-textLink-popover{
	pointer-events: auto;

	width: 400px;
	

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;
	padding-left: $sidePadding-editDocLinkPopover;
	padding-right: $sidePadding-editDocLinkPopover;
	padding-top: 8px;
	padding-bottom: 6px;

	background: var(--bg-dropdown);

	border-radius: 5px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
}

.doc-textLink-popover--newLink,
.doc-textLink-popover--editLink--hasSearchInput{
	min-height: 225px;
}

.doc-textLink-popover-inputContainer{
	width: 100%;
	height: 32px;
	position: relative;

	margin-bottom: 5px;
}


.doc-textLink-popover-inputPreviewContainer{	
	z-index: 5;
}


.doc-textLink-popover-inputPreviewContainer,
input.doc-textLink-popover-input{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	padding: 0px;
	padding-left: 10px;
	box-sizing: border-box;

	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	background: none;
}

.doc-textLink-popover-inputContainer--title
input.doc-textLink-popover-input{
	padding-left: 27px;
}


input.doc-textLink-popover-input{	
	padding-bottom: 0.04em;
	padding-right: 6px;
}

.doc-textLink-popover-inputPreviewContainer{
	padding-top: 0.04em;
	padding-left: 6px;
}

.doc-textLink-popover-inputPreviewContainer,
input.doc-textLink-popover-input{
	border: 1px solid var(--line300);
	border-radius: 4px;
}

input.doc-textLink-popover-input:hover,
.doc-textLink-popover-inputPreviewContainer:hover{
	border: 1px solid var(--line400);
}


input.doc-textLink-popover-input:focus{
	background: var(--bg-dropdown);
	outline: 2px solid var(--primary50);
	border: 1px solid var(--primary600);
}

//

@import 'Doc-TextLink-Popover-LinkPreview';

// Icon in front of title input

.doc-textLink-popover-inputUI{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.doc-textLink-popover-inputUI-frontIcon{
	width: 22px;
	margin-left: 3px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 1px;
}

.doc-textLink-popover-inputUI svg{
	width: 19px;
	height: 19px;
	fill: var(--text600);
	opacity: 0.5;
}

// .doc-textLink-popover-inputUI svg.icon--name--link{
// 	width: 15px;
// 	height: 15px;
// 	position: relative;
// }

//
.doc-textLink-popover-actionRow{
	display: flex;
	width: 100%;
	justify-content: flex-start;
	padding-top: 0px;
}

.doc-textLink-popover-actionRow-btn{
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 4px;
	padding-left: 8px;
	padding-right: 8px;


	height: 28px;
	opacity: 0.75;
}

.doc-textLink-popover-actionRow-btn:hover{
	background: var(--250gray);
	opacity: 1;
}

.doc-textLink-popover-actionRow-btn-iconContainer{
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2px;

	margin-left: -4px;
}

.doc-textLink-popover-actionRow-btn-iconContainer svg{
	fill: var(--text600);
	opacity: 0.7;
}

.doc-textLink-popover-actionRow-btn-iconContainer svg.icon--name--trash{
	width: 14px;
	height: 18px;
}

.doc-textLink-popover-actionRow-btn-iconContainer svg.icon--name--editText{
	width: 18px;
	height: 18px;
}

.doc-textLink-popover-actionRow-btn-label{
	font-size: 14.5px;
	font-weight: $lato-regular;
	color: var(--text500);
}

// Search results container

.doc-textLink-popover-resultsContainer{
	width: calc(100% + #{$sidePadding-editDocLinkPopover} + #{$sidePadding-editDocLinkPopover}); 
	//margin-left: calc(#{$sidePadding-editDocLinkPopover} * -1);

	box-sizing: border-box;
	padding-left: 5px;
	padding-right: 5px;
	min-height: 100px;
	margin-bottom: 4px;
}