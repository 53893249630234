.teamPanel-userPopover-actionBtnList{
	width: calc(100% + 10px);
	margin-left: -5px;
	margin-top: 10px;
	margin-bottom: 11px;

	//background: $tempr;
}

.teamPanel-userPopover-actionBtnList-divider{
	width: calc(100% - 12px);
	margin-left: 8px;
	height: 1px;
	margin-top: 4px;
	margin-bottom: 4px;

	background: var(--text400);
	opacity: 0.1;
}


.teamPanel-userPopover-actionBtn{
	width: 100%;

	justify-content: flex-start;

	background: var(--bg-dropdown);
	height: 28px;
	box-sizing: border-box;
	padding: 0px;
	//padding-left: 20px;
	//font-size: 15px;
	padding-left: 8px;
	padding-left: 6px;
	font-size: 14.5px;
	margin-bottom: 0px;

	font-weight: $lato-semibold;
	color: var(--text400);
	cursor: default;	

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	position: relative;

	cursor: pointer;

	border-radius: 5px;

	// background: $tempb;

}



.teamPanel-userPopover-actionBtn:hover{
	background: rgb(242,242,242);
	color: var(--text600);
}

.teamPanel-userPopover-actionBtn--disabled{
	opacity: 0.5;
	pointer-events: none;
}

.teamPanel-userPopover-actionBtn-iconContainer{
	width: 24px;
	height: 24px;
	
	border-radius: 4px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	//background: rgba(0,0,0,0.04);
}

.teamPanel-userPopover-actionBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	color: var(--text400);
	opacity: 0.75;
}

.teamPanel-userPopover-actionBtn:hover
.teamPanel-userPopover-actionBtn-iconContainer svg{
	opacity: 1;
}

.teamPanel-userPopover-actionBtn-iconContainer svg.icon--name--headphones,
.teamPanel-userPopover-actionBtn-iconContainer svg.icon--name--hash,
.teamPanel-userPopover-actionBtn-iconContainer svg.icon--name--forwardArrow{
	height: 14px;
	width: 14px;
}

.teamPanel-userPopover-actionBtn-iconContainer svg.icon--name--forwardArrow{
	height: 13px;
	width: 13px;
}