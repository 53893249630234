.settingsDialogContainer{	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

}

.settingsDialogContainer:focus{
	outline: none;
}

.settingsDialogContainer--hidden,
.settingsDialogContainer--hidden *{
	pointer-events: none !important;
}

//

.settingsDialog{
	width: 520px;
	min-height: 530px;

	box-sizing: border-box;
	padding-top: 36px;
	padding-bottom: 24px;
	padding-left: 48px;
	padding-right: 48px;

	border-radius: 12px;

	position: relative;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	display: flex;
	flex-direction: column;
}

//
// PROFILE


.settingsDialog-profile{
	display: flex;
	align-items: center;

	padding-bottom: 18px;

	cursor: default;
}

.settingsDialog-profile-avatarContainer{
	padding-right: 16px;
	flex-grow: 0;
	flex-shrink: 0;

	width: 80px;
	height: 80px;

	user-select: none;
}

.settingsDialog-profile-avatar{
	width: 100%;
	height: 100%;

	border-radius: 8px;

	user-select: none;
}

.settingsDialog-profile-name{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: var(--text500);
	margin-left: 1px;

	margin-bottom: 4px;
	margin-top: -1px;
}

.settingsDialog-profile-org{
	font-size: 16.5px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.8;

	margin-bottom: 3px;
}

.settingsDialog-profile-email{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.5;
	margin-bottom: 1px;
}


//
// DIVIDER
.settingsDialog-divider{
	width: 100%;
	height: 1px;	

	background: var(--line300);
}

[data-theme="dark"] .settingsDialog-divider{
	opacity: 0.5;
}



//
// SECTION HEADERS
.settingsDialog-toggleTheme-header,
.settingsDialog-teammates-header{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-bottom: 3px;

	user-select: none;
	cursor: default;
}


//
// TOGGLE THEME

.settingsDialog-toggleTheme{
	padding-top: 14px;
	padding-bottom: 18px;
	user-select: none;
}

.settingsDialog-toggleTheme-description{
	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text300);
	opacity: 0.9;
}

.settingsDialog-toggleTheme-btnContainer{
	width: 100%;
	display: flex;
	margin-top: 8px;

	margin-left: -8px;

}

.settingsDialog-theme-btn{
	width: 130px;
	height: 32px;

	border-radius: 5px;

	padding-left: 8px;
	padding-right: 8px;

	align-items: center;
	justify-content: flex-start;

	user-select: none;
}

.settingsDialog-theme-btn:hover{
	background: var(--hoverBG-item-dropdown);
}

.settingsDialog-theme-btn-radioContainer{
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 8px;
}

.settingsDialog-theme-btn-radio{
	width: 14px;
	height: 14px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--bg-docEditor);
}

.settingsDialog-theme-btn.settingsDialog-theme-btn--inactive
.settingsDialog-theme-btn-radio{	
	border: 1px solid var(--line600);
}

.settingsDialog-theme-btn.settingsDialog-theme-btn--active
.settingsDialog-theme-btn-radio{	
	border: 1px solid var(--125gray);
}

.settingsDialog-theme-btn-radio-inner{	
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: var(--125gray);
}

// .settingsDialog-teammateRow[data-is-checked="true"]
// .settingsDialog-theme-btn-radio{
// 	background: rgb(24, 122, 242);
// 	border: 1px solid rgb(0, 103, 230);
// }



.settingsDialog-theme-btn-label{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: var(--text500);
	text-transform: capitalize;
}

//
// TEAMMATES

.settingsDialog-teammates{
	padding-top: 16px;
}

//
// BOTTOM

.settingsDialog-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.settingsDialog-bottom-label{
	height: 26px;

	display: flex;
	align-items: center;

	padding-left: 6px;
	padding-right: 6px;
	margin-left: -6px;

	font-size: 14px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
	color: var(--text600);
	opacity: 0.5;

	border-radius: 4px;
}

.settingsDialog-bottom-label--joinSlack{
	font-weight: $lato-semibold;
	opacity: 0.75;
}


.settingsDialog-bottom-label--joinSlack:hover,
.settingsDialog-bottom-label--signOut:hover{
	opacity: 1;
}





//
// CLOSE BUTTON
.settingsDialog-closeBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	
	width: 56px;
	height: 56px;

	box-sizing: border-box;

	display: flex;
	align-items: flex-end;
	justify-content: start;

	padding-bottom: 12px;
	padding-left: 12px;
}

.settingsDialog-closeBtn svg{
	width: 24px;
	height: 24px;
	fill: var(--text600);
	opacity: 0.25;

	transition: all 50ms linear;
}

.settingsDialog-closeBtn:hover svg{
	opacity: 0.75;
	transform: scale(1.05);

	transition: all 50ms linear;
}





// @import 'settingsDialog-Header';


//
//

.settingsDialog-teammates-list{
	width: 100%;
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 32px;

	padding-left: 1px;
	padding-right: 1px;
}

// @import 'settingsDialog-Item';

//settingsDialog-item

button.settingsDialog-teammateRow{
	display: flex;
	height: 34px;
	width: 100%;
	border-radius: 5px;

	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;

	cursor: default;
}

.settingsDialog-teammateRow-avatarContainer{
	width: 26px;
	height: 26px;

	// width: 24px;
	// height: 24px;

	margin-right: 8px;
}

.settingsDialog-teammateRow-avatar{
	border-radius: 4px;
}

.settingsDialog-teammateRow-username{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: var(--text500);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.settingsDialog-teammateRow-email{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	margin-left: auto;
	opacity: 0.5;
}