.rdp {
  --rdp-cell-size: 32px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  // --rdp-accent-color-dark: red;
  --rdp-background-color-dark: #180270;
  // --rdp-accent-color-dark: red;
  //--rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  //--rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */

  margin-top: 10px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;

  user-select: none;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;

  font-size: 15.25px;

  -moz-appearance: none;
  -webkit-appearance: none;

  color: var(--text600);
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.rdp-button {
  border: 2px solid transparent;
  //border: 1px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
  background: var(--250gray);
  border-radius: 6px;
  border: 1px solid var(--bg-dropdown);

}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  width: calc(100% + 3px);
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;

  margin-bottom: -4px;

  margin-top: -5px;
  margin-left: -5px;

  text-align: left;
  color: var(--text600);

  
  border-radius: 3px;
  //background: var(--253gray);
  // background: $tempr;
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

//
// TITLE
.rdp-caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;

  margin-left: 4px;
  
  border: 0px solid transparent;
  font-family: inherit;

  font-size: 17px;
  font-weight: $lato-bold;
  color: var(--text500);

  letter-spacing: 0.015em;

  cursor: default;

}

[data-theme="dark"] .rdp-caption_label {
  font-weight: $lato-semibold;
  letter-spacing: 0.01em;
}


.rdp-nav {
  white-space: nowrap;
  position: relative;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

//
// Next/previous month
//

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 32px;

  // background: $tempr;
  background: none;
  border-radius: 4px;

  padding: 0.25em;
}

.rdp-nav_button.rdp-button:focus-visible:not([disabled]){
  background: none;
}

.rdp-nav_button svg.rdp-nav_icon{
  height: 12px;
  width: 12px;
  //transform: scale(1.03);

  fill: var(--text500);
  opacity: 0.5;

}

// .rdp-nav:hover .rdp-button.rdp-nav_button:not(:hover){
//   background: var(--253gray);
// }

.rdp-button.rdp-nav_button:hover{
  background: var(--250gray) !important; // sorry, in a rush, don't understand this stuff ha
  outline: none;
  border: none;
  border: 1px solid var(--250gray) !important;
}

.rdp-nav_button:hover svg{
  opacity: 1  !important;
  transform: scale(1.06);
}

.rdp-nav_button_previous{
  // border-top-right-radius: 1px;
  // border-bottom-right-radius: 1px;
}

.rdp-nav_button_next{
  // border-top-left-radius: 1px;
  // border-bottom-left-radius: 1px;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  //border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row,
.rdp-row {
  height: 100%;
}


.rdp-head_cell {
  vertical-align: middle;
  
  text-align: center;
  height: 100%;
  //height: var(--rdp-cell-size);
  
  height: 36px;
  padding: 0;
  box-sizing: border-box;
  padding-top: 8px;

  text-transform: lowercase;
  color: var(--text600);

  font-size: 14.75px;
  font-weight: $lato-medium;
  opacity: 0.35;
  letter-spacing: 0.01em;


}

.rdp-head_cell:first-letter{
  text-transform: uppercase;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: 0.5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  border: 1px solid transparent;
  border-radius: 100%;

  line-height: 1.3;
  box-sizing: border-box;
  padding-bottom: 0.017em;
}

// Todays date

// Selected, even if today, always blue
.rdp-day_selected.rdp-day_today,
.rdp-day_selected.rdp-day_today:hover,
.rdp-day_selected.rdp-day_today:not(.rdp-day_outside),
.rdp-day_selected.rdp-day_today:hover:not(.rdp-day_outside){
  border-radius: 4px !important;
  background: var(--primary500) !important;
  color: white !important;
}


.rdp-day_today:not(.rdp-day_outside) {
  font-weight: $lato-semibold;
  background: $tempr;
  background: var(--accentRed700);
  color: rgb(255,255,255);
  border-radius: 100%;

  border: 1px solid var(--bg-dropdown);
  background: var(--accentRed100);
  color: var(--accentRed700);
}

.rdp-day_today:hover:not(.rdp-day_outside){
  border-radius: 4px;
  background: var(--accentRed100) !important;
  color: var(--accentRed700) !important;
}


[data-theme="dark"]
.rdp-day_today:not(.rdp-day_outside){
  color: rgb(255,67,70);
  background: rgba(255,89,89,0.12)
}

[data-theme="dark"]
.rdp-day_today:hover:not(.rdp-day_outside){
  color: rgb(255,67,70) !important;
  background: rgba(255,89,89,0.12) !important;
}


// SELECTED DAY
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white;
  opacity: 1;
  //background-color: var(--rdp-accent-color);
  background: var(--primary500);
  border-radius: 6px;
  border: 1px solid var(--bg-dropdown);

  font-weight: $lato-semibold;
}

[data-theme="dark"]{
  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover{
    background: rgb(0,84,189);
  }  
}




//

.rdp-day_outside {
  opacity: 0.5;
}

.rdp-day_selected:focus-visible {
  /* Since the background is the same use again the outline */
  //outline: var(--rdp-outline);
  //outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

/*# sourceMappingURL=style.css.map */