
// variables

:root{
	--background-whiteboard: rgb(252,249,252);	
	--background-whiteboardPreview: rgb(252,252,255);	

	--background-whiteboardsToolbar: rgb(255,255,255);
	--borderRadius-whiteboardsToolbar: 6px;

	--borderTop-whiteboardsToolbar: 1px solid hsl(240, 4%, 85%);
	--border-whiteboardsToolbar: 1px solid hsl(240, 8%, 82%);
	--borderBottom-whiteboardsToolbar: 1px solid hsl(240, 5%, 76%);	
	--boxShadow-whiteboardsToolbar: 0px 3px 6px 0px rgba(0,0,0,0.06);

	--tldrawBlack: #1d1d1d;
	--tldrawBlackMid: #1d1d1d;
	--tldrawBlackFill: #e8e8e8;

	--tldrawGrey: #adb5bd;
	--tldrawGreyMid: #adb5bd;
	--tldrawGreyFill: #eceef0;

	--tldrawBlue: #4263eb;
	--tldrawBlueMid: #6180f9;
	--tldrawBlueFill: #dce1f8;

	--tldrawLightBlue: #4dabf7;
	--tldrawLightBlueMid: #69bbfd;
	--tldrawLightBlueFill: #ddedfa;

	--tldrawOrange: #f76707;
	--tldrawOrangeMid: #ff9f5f;
	--tldrawOrangeFill: #f8e2d4;

	--tldrawRed: #e03131;
	--tldrawRedMid: #eb7878;
	--tldrawRedFill: #f4dadb;

	--tldrawGreen: #099268;
	--tldrawGreenMid: #62cb88;
	--tldrawGreenFill: #d3e9e3;

	--tldrawLightViolet: #e599f7;
	--tldrawLightVioletMid: #e599f7;
	--tldrawLightVioletFill: #f5eafa;
}


[data-theme="dark"]{
	//--background-whiteboard: rgb(28,27,31);	
	--background-whiteboard: rgb(22,20,24);	
	--background-whiteboardPreview: rgb(32,30,35);	

	--background-whiteboardsToolbar: rgb(36,34,37);	

	--borderTop-whiteboardsToolbar: 1px solid rgba(255,255,255,0.12);
	--border-whiteboardsToolbar: 1px solid rgba(255,255,255,0.10);
	--borderBottom-whiteboardsToolbar: 1px solid rgba(255,255,255,0.08);	
	--boxShadow-whiteboardsToolbar: 0px 3px 6px 0px rgba(0,0,0,0.14);

	--tldrawBlack: #e1e1e1;
	--tldrawBlackMid: #e1e1e1;
	--tldrawBlackFill: #2c3036;

	--tldrawGrey: #93989f;
	--tldrawGreyMid: #93989f;
	--tldrawGreyFill: #33373c;


}


