:root{	
	--projectBacklog-red: hsl(358, 83%, 73%);

	--projectPlanned-grey: rgb(190,185,198);

	--projectStarted-orange: rgb(246,160,42);

	--projectCompleted-purple: hsl(233, 92%, 65%);

	// canceled and paused are the same
	--projectCanceled-grey: rgb(158,150,170);
	--projectPaused-grey: rgb(158,150,170); 

	
}

// DARK THEME

[data-theme="dark"], .forceDarkTheme{

	// --projectBacklog-red: hsl(358, 83%, 73%); // red 500	

	// --projectPlanned-grey: rgb(190,185,198); // red 500

	// --projectStarted-orange: rgb(246,160,42); // red 500

}