$height-inlineVideo-header: 34px;

$height-zoomVideo-controlBar-buttonRow: 60px;
$height-zoomVideo-controlBar-commentRow: 28px;

$height-zoomVideo-controlBar: calc(#{$height-zoomVideo-controlBar-buttonRow} + #{$height-zoomVideo-controlBar-commentRow});

.doc-videoWrapper--selected{
	// background: $tempr;
}

.doc-videoContainer--inline--selectionBG{
	position: absolute;
	z-index: 0;
	top: 31px;
	left: -3px;
	right: -3px;
	bottom: -3px;

	border-radius: 7px;

	pointer-events: none;
	background: var(--mediaNodeSelectedBG);

	opacity: 0;
}

.doc-videoWrapper--selected
.doc-videoContainer--inline--selectionBG{
	opacity: 0.5;
}


.doc-videoContainer--inline{
	padding-top: $height-inlineVideo-header;
	margin-top: 18px;
	margin-bottom: 18px;
	margin-left: $negativeEdgeMarginTextToDoc;
	// position: fixed;
	// top: 0px;
	// left: 0px;
	// right: 0px;
	// bottom: 0px;

	// z-index: 99999;

	position: relative;		
	// align-items: center;
	// justify-content: center;
}

.doc-videoContainer--zoomed{
	position: fixed;
	// top: 0px;
	// left: 0px;
	// right: 0px;
	// bottom: 0px;

	z-index: 99999;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $black;
	border-radius: 5px;
}


.doc-videoContainer--inline
.doc-videoInnerContainer{
	background: rgb(250,250,255);
	border-radius: 4px;
	cursor: pointer;
}

.doc-videoContainer--zoomed
.doc-videoInnerContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: $height-zoomVideo-controlBar;
	background: $black;
	cursor: pointer;
}


//
.doc-video-captionsContainer{

}


//

.doc-videoContainer--inline
.doc-video{
	margin-left: $negativeEdgeMarginTextToDoc; // centers
}


.doc-video{
	// margin: 0 auto;
	width: $videoWidth-doc;
	height: 345px;
	//height: 365px;
	overflow: hidden;

	position: relative;
	// margin-top: 28px;
	// margin-bottom: 28px;

	padding: 0px;

	z-index: 10;	

	position: relative;	
	cursor: pointer;

	background: $black;
	
	// box-shadow:
 //  0px 0.7px 1.2px rgba(0, 0, 0, 0.016),
 //  0px 2.2px 4px rgba(0, 0, 0, 0.024),
 //  0px 10px 18px rgba(0, 0, 0, 0.04);

}

.doc-videoContainer--inline .doc-video{
	margin: 0 auto;
	border-radius: 4px;
}

.doc-videoContainer--zoomed .doc-video{
	margin: 0 auto;
	position: relative;
	z-index: 10;

	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}


$innerMargin-participant-video-ui: 10px;
$bgColor-participant-video-ui: rgba(10,0,10,0.6);


@import 'Doc-Video-InlineUI';
@import 'Doc-Video-ZoomedUI';
@import 'Doc-Video-CommentRow';
@import 'Doc-Video-Captions';


.doc-video-border{
	pointer-events: none;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	border: 1px solid rgba(10,0,10,0.08);
	border-bottom: 1px solid rgba(10,0,10,0.12);
	border-radius: 6px;
}

///
///


.doc-videoContainerZoomBG{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;	

	//background: rgb(200,200,200,0.5);
	background: var(--bg-zoomedMediaModals);

	z-index: 99990;
	// background: rgba(50,0,0,0.9);

	cursor: default;
}


















// //
// // UI

// .doc-image-ui,
// .doc-video-ui{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	z-index: 50;
// }


// .doc-image-ui-docTitleContainer,
// .doc-video-ui-titleContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 50%;
// 	height: calc(#{$innerMarginForUI-docMedia} + 32px);
// 	// background: $tempr;
// 	z-index: 10;

// 	display: flex;
// 	align-items: flex-end;
// 	justify-content: flex-start;

// 	pointer-events: none;
// }

// .doc-image-ui-docTitle,
// .doc-video-ui-title{
// 	height: 32px;
// 	padding-left: 14px;
// 	padding-right: 14px;

// 	color: $white;
// 	font-size: 17px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.01em;

// 	margin-left: $innerMarginForUI-docMedia;

// 	background: $tempr;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	box-sizing: border-box;
// 	padding-bottom: 2px;
// }

// .doc-image-ui-topRightContainer,
// .doc-video-ui-topRightContainer{
// 	position: absolute;
// 	top: 0px;
// 	right: 0px;
// 	width: calc(50% - 40px);
// 	height: calc(#{$innerMarginForUI-docMedia} + 32px);
// 	// background: $tempr;
// 	z-index: 10;

// 	box-sizing: border-box;
// 	padding-right: $innerMarginForUI-docMedia;
// 	padding-top: $innerMarginForUI-docMedia;

// 	display: flex;
// 	align-items: flex-end;
// 	justify-content: flex-end;

// 	cursor: -webkit-zoom-in;
//   cursor: zoom-in;
// }

// .doc-image-ui-btn,
// .doc-video-ui-btn{	

// 	padding-right: 14px;
// 	padding-left: 14px;

// 	height: 32px;

// 	font-size: 15px;
// 	font-weight: $lato-bold;
// 	color: $white;
// 	letter-spacing: 0.025em;

// 	display: flex;
// 	justify-content: flex-end;
// 	align-items: center;

// 	border: none;
// 	box-shadow: none;

// 	cursor: pointer;

// 	margin-left: 10px;
// 	// background etc set elsewhere
// }


// .doc-image-ui-imageZoomCoverBtn{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	z-index: 8;

// 	background: $invisible;
// 	border: none;
// 	box-shadow: none;

// 	cursor: -webkit-zoom-in;
//   cursor: zoom-in;
// }

// .doc-imageContainer--editMode
// .doc-image-ui-imageZoomCoverBtn{
// 	cursor: pointer;
// }


// .doc-video-ui-duration{
// 	// position: absolute;
// 	// top: $innerMarginForUI-docMedia;
// 	// right: $innerMarginForUI-docMedia;

// 	padding-right: 14px;
// 	padding-left: 14px;

// 	height: 32px;

// 	font-size: 17px;
// 	font-weight: $lato-bold;
// 	color: $white;
// 	letter-spacing: 0.025em;

// 	display: flex;
// 	justify-content: flex-end;
// 	align-items: center;
// }


// //



// .doc-image img{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;

// 	// visibility: hidden;
// }




// // .doc-video{
// // 	margin: 0 auto;

// // 	width: $videoWidth-doc;
// // 	height: 345px;
// // 	//height: 365px;
// // 	overflow: hidden;

// // 	position: relative;
// // 	margin-top: 28px;
// // 	margin-bottom: 28px;

// // 	padding: 0px;

// // 	z-index: 10;

// // 	// border: 4px solid white;
// // 	border-radius: 6px;

// // 	position: relative;	
// // 	cursor: pointer;

// // 	background: $black;
// // 	// background: $blue;
// // 	// background: $white;

// // 	// box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.06);
// // 	box-shadow:
// //   0px 0.7px 1.2px rgba(0, 0, 0, 0.016),
// //   0px 2.2px 4px rgba(0, 0, 0, 0.024),
// //   0px 10px 18px rgba(0, 0, 0, 0.04)
// // ;

// // }



// .doc-video-border{
// 	pointer-events: none;
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	border: 1px solid rgba(10,0,10,0.08);
// 	border-bottom: 1px solid rgba(10,0,10,0.12);
// 	border-radius: 6px;
// }



// //

// .doc-video-ui-zoomPlayBtn{
// 	position: absolute;
// 	bottom: $innerMarginForUI-docMedia;
// 	// left: $innerMarginForUI-docMedia;
// 	left: 50%;
// 	transform: translateX(-50%);

// 	width: 174px;
// 	height: 56px;

// 	border: none;
// 	outline: none;
// 	box-shadow: none;

// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	cursor: pointer;
// }

// .doc-video-ui-zoomPlayBtn svg{
// 	width: 48px;
// 	height: 48px;
// 	fill: $white;
// }

// .doc-video-ui-zoomPlayBtn:hover svg{
// 	transform: scale(1.05);
// }

// .doc-video-ui-zoomPlayBtn{

// }

// //





// // For images, feels right that controls should leave once you go off, not sure tho
// .doc-image .doc-image-ui-topRightContainer,
// .doc-image .doc-image-ui-docTitleContainer{
// 	opacity: 0;
// 	transition: 50ms;
// }


// .doc-video .doc-video-ui-zoomPlayBtn,
// .doc-image .doc-image-ui-topRightContainer,
// .doc-video .doc-video-ui-titleContainer{
// 	// opacity: 0.9;
// 	transition: opacity 50ms;
// }

// .doc-image:hover .doc-image-ui-topRightContainer,
// .doc-image:hover .doc-image-ui-docTitleContainer,
// .doc-video:hover .doc-video-ui-zoomPlayBtn,
// .doc-video:hover .doc-video-ui-titleContainer,
// .doc-video:hover .doc-video-ui-titleContainer{
// 	opacity: 1;
// 	transition: opacity 50ms;
// }

// .doc-image 				.doc-image-ui-topRightContainer--hide,
// .doc-image:hover 	.doc-image-ui-topRightContainer--hide,
// .doc-image 				.doc-image-ui-docTitleContainer--hide,
// .doc-image:hover 	.doc-image-ui-docTitleContainer--hide,
// .doc-video 				button.doc-video-ui-zoomPlayBtn--hide,
// .doc-video:hover 	button.doc-video-ui-zoomPlayBtn--hide,
// .doc-video 				.doc-video-ui-titleContainer--hide,
// .doc-video:hover 	.doc-video-ui-titleContainer--hide,
// .doc-video 				.doc-video-ui-titleContainer--hide,
// .doc-video:hover 	.doc-video-ui-titleContainer--hide{
// 	opacity: 0;
// 	transition: opacity 200ms;
// }

// //
// //

// button.doc-video-ui-zoomWhilePlayingBtn{
	
// 	position: absolute;
// 	bottom: $innerMarginForUI-docMedia;
// 	right: $innerMarginForUI-docMedia;

// 	margin-bottom: 10px;
// 	margin-left: 10px;

// 	width: 56px;
// 	height: 40px;

// 	border: none;
// 	outline: none;
// 	box-shadow: none;

// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	cursor: pointer;
// }

// button.doc-video-ui-zoomWhilePlayingBtn svg{
// 	fill: $white;
// 	width: 28px;
// 	height: 28px;
// }

// button.doc-video-ui-zoomWhilePlayingBtn{
// 	opacity: 0.6;
// 	transition: opacity 50ms;
// }

// button.doc-video-ui-zoomWhilePlayingBtn:hover{
// 	opacity: 1;
// 	transition: opacity 50ms;
// }

// button.doc-video-ui-zoomWhilePlayingBtn:hover svg{
// 	transform: scale(1.05);
// }

// //
// //

// @import 'Doc-Media-VideoControlBar';



// //


// .doc-image-ui-docTitle,
// .doc-image-ui-btn,
// .doc-video-ui-btn,
// .doc-video-ui-zoomPlayBtn,
// .doc-video-ui-duration,
// .doc-video-ui-controlBar,
// .doc-video-ui-zoomWhilePlayingBtn,
// .doc-video-ui-title,
// .doc-video-ui-feedbackPanel{
// 	border-radius: 6px;

// 	background: $bgColor-docMedia-ui;
// 	backdrop-filter: blur(10px);

// 	z-index: 10;
// }

// button.doc-video-ui-bgPlayPauseClick{
// 	position: absolute;
// 	top: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	left: 0px;

// 	background: $invisible;
// 	border: none;
// 	box-shadow: none;

// 	cursor: pointer;

// }



// @import 'Doc-Media-FeedbackPanel'; // might comment this out

// //
// // ZOOMED
// //

// .doc-imageContainer--zoomed,
// .doc-videoContainer--zoomed{
// 	position: fixed;	

// 	z-index: 99999;

// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end; // the sidepanel takes up full width

// 	margin: unset;
// 	width: unset;

// 	background: $white;
// 	border: 1px solid var(--line500);
// 	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
// 	border-radius: 14px;

// 	cursor: default;
// }

// .doc-imageContainer--zoomed .doc-image-ui-docTitleContainer,
// .doc-imageContainer--zoomed .doc-image-ui-topRightContainer,
// .doc-videoContainer--zoomed .doc-video-ui-docTitleContainer,
// .doc-videoContainer--zoomed .doc-video-ui-topRightContainer{
// 	display: none;
// }

// .doc-imageContainer--zoomed .doc-image-ui-imageZoomCoverBtn{
// 	display: none; // I think take this out
// }


// .doc-imageContainer--zoomed .doc-image{
// 	position: relative;
// 	margin: unset;
// 	z-index: 10;
// 	border-radius: 8px;
// }



// .doc-imageContainerZoomBG,
// .doc-videoContainerZoomBG{
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;	

// 	background: rgb(200,200,200,0.5);
// 	background: rgb(0,0,0,0.5);

// 	z-index: 80000;
// }


// @import 'Doc-Media-ZoomedSidePanel';
// @import 'Doc-Media-ZoomedReactionBar';















// // VIDEO, MAY NEED FIXES

// .doc-videoContainer--zoomed{
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	z-index: 99999;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .doc-videoContainer--zoomed .doc-video{
// 	margin: unset;
// 	position: relative;
// 	z-index: 10;

// 	width: calc(100vw - 40px);
// 	height: unset;
// 	aspect-ratio: 650/345;
// 	//aspect-ratio: 650/365;

// 	background: none;
// 	border-radius: 12px;

// 	overflow: hidden;

// }



// .doc-videoContainer-zoomBg{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	z-index: 5;

// 	background: rgb(0,0,0,0.85);

// 	z-index: 0;
// }

// .doc-videoContainer--zoomed
// .doc-video-ui-controlBar{
// 	width: 600px;
// }
