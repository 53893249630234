
//
// DON't think this is real anymmore


.docEditor-insertMenuTrigger{
	position: absolute;
	
	top: 0px;
	left: 0px;
	width: 10px;
	height: 24px;

	background: $tempr;

	z-index: 10;

}

.docEditor-insertMenuContainer{

}

.docEditor-insertMenu{
	margin-left: -32px;
	margin-top: 6px;

	width: 280px;
	
	background: $white;
	border: 1px solid red;

	border: 1px solid var(--outsideLineFloatingMenu);
	border-top: 1px solid var(--outsideTopLineFloatingMenu);
	border: 1px solid var(--outsideBottomLineFloatingMenu);

	box-shadow: var(--boxShadowFloatingMenuStrong);

	border-radius: 5px;

	background: var(--bgFloatingMenu);
	background: rgb(255,255,255);

	box-sizing: border-box;

	padding-top: 5px;
	padding-bottom: 10px;

}

//

.docEditor-insertMenu-btnGroup-divider{
	// border-bottom: 1px solid var(--insideLineFloatingMenu);

	width: 100%;

	box-sizing: border-box;

	padding-left: 0px;
	padding-right: 0px;

	height: 9px;
	// background: $tempr;

	position: relative;

	cursor: pointer;
}

.docEditor-insertMenu-btnGroup-divider-line{
	position: absolute;
	left: 38px;
	right: 0px;
	background: var(--line300);
	height: 1px;
	top: 4px;
}


//

.docEditor-insertMenu-item{
	width: calc(100% - 8px);
	margin-left: 4px;

	height: 28px;

	display: flex;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 4px;
	
	background: none;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	user-select: none;
	cursor: pointer;

	border-radius: 4px;
}

.docEditor-insertMenu-item:focus{
	outline: none;
}

.docEditor-insertMenu-item-iconContainer{
	width: 24px;
	height: 24px;

	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-insertMenu-item-iconContainer svg{
	width: 20px;
	height: 20px;

	fill: var(--text400);

	opacity: 0.6;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--linkArrow{
	width: 18px;
	height: 18px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--insertBarDrawing{
	height: 24px;
	width: 24px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--insertBarFigma{
	height: 21px;
	width: 21px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--insertBarScreenRecording{
	height: 21px;
	width: 21px;
	margin-left: -2px;
}


.docEditor-insertMenu-item-iconContainer svg.icon--name--insertBarImage{
	height: 21px;
	width: 21px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--insertBarFAQ{
	width: 22px;
	height: 22px;
}



.docEditor-insertMenu-item-iconContainer svg.icon--name--formatH1,
.docEditor-insertMenu-item-iconContainer svg.icon--name--formatH2{
	height: 18px;
	width: 18px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--code{
	height: 18px;
	width: 18px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--blockDecoration{
	width: 17px;
	height: 17px;
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--blockDecoration #main{
	fill: var(--fillIconBGFloatingMenu);
	stroke: var(--text400);
}

.docEditor-insertMenu-item-iconContainer svg.icon--name--blockDecoration #inner{
	fill: rgb(240,240,245);	
}

[data-theme="dark"] .docEditor-insertMenu-item-iconContainer svg.icon--name--blockDecoration #inner{
	fill: rgb(20,20,22);	
}


// Refactor?
.docEditor-insertMenu-item-iconContainer svg.icon--name--formatA #text{
	fill: var(--text100);
}
// [data-theme="dark"] button.docEditor-selectionMenu-btn  .docEditor-selectionMenu-btn-iconContainer svg.icon--name--formatA #text{
// 	fill: rgba(255,255,255,0.05);
// }

.docEditor-insertMenu-item-iconContainer  svg.icon--name--formatA #box{
	fill: rgba(0,0,0,0.05);
}
[data-theme="dark"] .docEditor-insertMenu-item-iconContainer svg.icon--name--formatA #box{
	fill: rgba(255,255,255,0.05);
}



//


.docEditor-insertMenu-item-label{
	margin-left: 6px;

	//font-weight: $lato-semibold;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.01em;
	
	font-size: 14.5px;

	flex-grow: 1;
	flex-shrink: 1;

	text-align: left;

}



.docEditor-insertMenu-item:hover{
	// background: rgba(35,5,35,0.02);
	// background: hsl(213, 100%, 50%);
	background:	hsl(212, 100%, 97%);
}

.docEditor-insertMenu-item:hover .docEditor-insertMenu-item-label{
	// color: $white;
	color: var(--text600);
}

.docEditor-insertMenu-item:hover
.docEditor-insertMenu-item-iconContainer svg{
	fill: $white;
	fill: hsl(213, 100%, 50%);
	opacity: 1;
}

.docEditor-insertMenu-item:hover
.docEditor-insertMenu-item-iconContainer{
	//background: hsl(212, 100%, 95%);

}


.docEditor-insertMenu-item-keyboardShortcutContainer{
	// width: 60px;
	height: 100%;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

//
//

//
// KEYBOARD SHORTCUTS FOR INSERT MENU
.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTagContainer{
  width: 100%;
  margin-left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTag{
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;

  font-size: 13px;

  background: var(--bgInlineKeyboardShortcutTag);
  color: var(--colorInlineKeyboardShortcutTag);

  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: $lato-semibold;

   white-space: nowrap;

  // min-width: 20px;
}

.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTag svg{
  width: 12px;
  height: 12px;
  fill: var(--colorInlineKeyboardShortcutTag);
}

.docEditor-insertMenu-item:hover
.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTag{
	background: var(--hoverBGInlineKeyboardShortcutTag);
  color: var(--hoverColorInlineKeyboardShortcutTag);
}

.docEditor-insertMenu-item:hover
.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTag svg{
  fill: var(--hoverColorInlineKeyboardShortcutTag);
}


.docEditor-insertMenu-item-keyboardShortcutContainer .keyboardShortcutTag-item{  
  height: 12px;
  min-width: 13px;

  display: flex;
  align-items: center;
  justify-content: center;
}