//$height-messageInsertMentionsMenu-item: 28px;
$height-messageInsertMentionsMenu-item: 28px;


.messageInsertMentionsMenu-item{
	height: $height-messageInsertMentionsMenu-item;
	width: calc(100% - 10px);
	margin-left: 5px;	

	display: flex;	
	align-items: center;

	box-sizing: border-box;
	padding-left: 8px;	
	padding-right: 8px;

	line-height: 1.9;

	background: var(--bg-dropdown);
	border-radius: 4px;

	cursor: pointer;

	position: relative;
}

.messageInsertMentionsMenu-item--mouseMoved.messageInsertMentionsMenu-item:hover,
.messageInsertMentionsMenu-item--active,
.messageInsertMentionsMenu-item--active:hover{
	background: var(--hoverBG-item-dropdown);
}

//

.messageInsertMentionsMenu-item--mouseMoved.messageInsertMentionsMenu-item:hover .messageInsertMentionsMenu-item-title,
.messageInsertMentionsMenu-item--active .messageInsertMentionsMenu-item-title,
.messageInsertMentionsMenu-item--active:hover .messageInsertMentionsMenu-item-title{
	color: var(--text600);
}

//

.messageInsertMentionsMenu-item--issue-workflowStateIconContainer,
.messageInsertMentionsMenu-item--project-markerContainer,
.messageInsertMentionsMenu-item--user-avatarContainer{
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 26px;
	height: 26px;

	margin-left: -4px;

	margin-right: 4px;
}

.messageInsertMentionsMenu-item--issue-workflowStateIconContainer{
	
}

.messageInsertMentionsMenu-item--project-markerContainer{

}

.messageInsertMentionsMenu-item--project-markerContainer
.linearMarker{
	font-size: 16px;
}

.messageInsertMentionsMenu-item--project-markerContainer
.linearMarker svg{
	width: 16px;
	height: 16px;
}

.messageInsertMentionsMenu-item--user-avatarContainer img{
	width: 22px;
	height: 22px;
	border-radius: 4px;
	position: relative;
	left: -1px;

}

//

.messageInsertMentionsMenu-item-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;	


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageInsertMentionsMenu-item-label{
	font-size: 15.25px;
	font-weight: $lato-medium;
	color: var(--text500);


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.messageInsertMentionsMenu-item--user-currentUserLabel{
	opacity: 0.55;
}

//

.messageInsertMentionsMenu-item--issue-identifierContainer{
	flex-grow: 0;
	flex-shrink: 0;

	margin-left: auto;

	padding-left: 2px;
	// background: $tempr;
	min-width: 55px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-size: 12.5px;
	font-weight: $lato-regular;
	color: var(--text500);

	opacity: 0.6;
}


// TAGS
// taken from doc rows
.messageInsertMentionsMenu-item--doc-projectTag,
.messageInsertMentionsMenu-item--doc-teamTag{
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	height: 22px;

	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;

	margin-left: auto;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageInsertMentionsMenu-item--active .messageInsertMentionsMenu-item--doc-projectTag,
.messageInsertMentionsMenu-item--active .messageInsertMentionsMenu-item--doc-teamTag{
	background: var(--235gray);
}


.messageInsertMentionsMenu-item--doc-projectTag-label,
.messageInsertMentionsMenu-item--doc-teamTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.9;
	font-weight: $lato-medium;

	position: relative;
	top: -0.5px;

	margin-left: 3px;

	max-width: 120px;


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageInsertMentionsMenu-item--doc-projectTag-marker{
	flex-grow: 0;
	flex-shrink: 0;
}

.messageInsertMentionsMenu-item--doc-projectTag
.linearMarkerContainer--markerType--emoji,
.messageInsertMentionsMenu-item--doc-teamTag
.linearMarkerContainer--markerType--emoji{
	font-size: 12.5px;
}

.messageInsertMentionsMenu-item--doc-projectTag
.linearMarkerContainer--markerType--icon svg,
.messageInsertMentionsMenu-item--doc-teamTag
.linearMarkerContainer--markerType--icon svg{
	width: 13px;
	height: 13px;
}




//


$popoverEdgeMargin-messageInsertMentionsMenu: 4px; // don't undesrtand this

.messageInsertMentionsMenu-item-hoverPreviewContainer{
	position: absolute;
	//top: -120px;
	height: 400px;
	bottom: -140px;
	right: calc(#{$width-messageInsertMentionsMenu} + #{$popoverEdgeMargin-messageInsertMentionsMenu});
	// background: $tempr;
	width: 400px;

	display: flex;
	align-items: flex-end;	
	justify-content: flex-end;

	padding-top: 10px;

	opacity: 0;
}

// TO DO: Put messageInsertMentionsMenu on right edge if necessary
// .messageInsertMentionsMenu--rightEdge
// .messageInsertMentionsMenu-item-hoverPreviewContainer{
// 	left: unset;
// 	right: calc(#{$width-messageInsertMentionsMenu} + #{$popoverEdgeMargin-messageInsertMentionsMenu});
// 	justify-content: flex-end;
// }

.messageInsertMentionsMenu-item--active
.messageInsertMentionsMenu-item-hoverPreviewContainer{
	opacity: 1;
}

.messageInsertMentionsMenu-item-hoverPreviewContainer
.linearIssuePreviewPopover-descriptionContainer{
	max-height: 180px; // looks tidier when doesn't overflow
}

.messageInsertMentionsMenu-item-hoverPreviewContainer
.linearIssuePreviewPopover-descriptionOverflowGradient{
	top: 180px;
}

//

$width-docPreview-messageInsertMentionsMenu: 278px;
$docOriginalScaleWidth-docPreview-messageInsertMentionsMenu: 860px;
$docScale-docPreview-messageInsertMentionsMenu: 0.345;


.messageInsertMentionsMenu-item--doc-previewInnerContainer{
	
	height: 320px;
	width: $width-docPreview-messageInsertMentionsMenu;

	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-bottom: 0px;

	overflow: hidden;
	cursor: pointer;

	background: $white;
}


.messageInsertMentionsMenu-item--doc-preview{
	transform: scale($docScale-docPreview-messageInsertMentionsMenu);
	transform-origin: top left;
	width: $docOriginalScaleWidth-docPreview-messageInsertMentionsMenu;
	display: flex;
	justify-content: center;

	pointer-events: none;	
}

.messageInsertMentionsMenu-item--doc-preview-doc{
	width: $fullWidth-doc;

	height: 100%;
	// background: $tempg;

	padding-top: 46px; // hacky
}

.messageInsertMentionsMenu-item--doc-preview-doc-title{
	width: $textWidth-doc;
}

.messageInsertMentionsMenu-item--doc-preview-doc-innerWrapper{
	width: $textWidth-doc;
}

//