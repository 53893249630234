
.docEditor-projectMentionsSheetAnchor{
	position: fixed;
	top: calc(#{$windowMargin-sideMenuMode} - 1px); // -1 to account for border
	right: calc(#{$windowMargin-sideMenuMode} - 1px);
	bottom: calc(#{$windowMargin-sideMenuMode} - 1px);

	width: 0px;
	background: red;

	z-index: 20;

	// background: $tempr;
}

.docEditor-projectMentionsSheetContainer{	
	
}

.docEditor-projectMentionsSheetContainerfocus{
	outline: none !important;
}

.docEditor-projectMentionsSheetContainer--hidden,
.docEditor-projectMentionsSheetContainer--hidden *{
	pointer-events: none !important;
}



.docEditor-projectMentionsSheet{	
	height: calc(100vh - #{$windowMargin-sideMenuMode} - #{$windowMargin-sideMenuMode});
	width: 200px; 

	box-sizing: border-box;

	border-radius: 5px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

	position: relative;

	border: 1px solid var(--appContentsBorder-sideMenuMode);
	background: var(--bg-docEditor);

	display: flex;
	flex-direction: column;
}

//
// HOVER AREA HIDDEN
.docEditor-projectMentionsSheet{
	@include poly-fluid-sizing('width', (
		
		1280px: 440px,		
		1366px: 480px,
		1440px: 520px,
		1680px: 640px,
		1920px: 660px,
		2560px: 700px)
	);
}

.docEditor-projectMentionsSheet-header{
	height: 37px;
	width: 100%;
	display: flex;
	
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;

	display: flex;
	align-items: center;

	cursor: pointer;
	
	// border-bottom: 1px solid $invisible;
	border-bottom: 1px solid var(--bottomBorder-header-messagePanel);
	box-shadow: var(--boxShadow-header-messagePanel);

	padding-left: 16px;
	
	user-select: none;

	position: relative;
	z-index: 10;
}




.docEditor-projectMentionsSheet-header-label{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	//letter-spacing: 0.001em;
	color: var(--text400);
	opacity: 1;



	color: var(--text500);
	// color: $white;
	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // opacity: 0.75;
}


//

.docEditor-projectMentionsSheet-header-closeBtn{
	// background: $tempr;
	// width: 38px;
	margin-left: auto;
	height: 100%;		
	padding-left: 6px;
	padding-right: 3px;

	//border-left: 1px solid var(--sideBorder-header-messagePanel-dndButton);
}


.docEditor-projectMentionsSheet-header-closeBtn-inner{
	width: 33px;
	height: 30px;
	border-radius: 5px;
	// background: $tempb;
	
	display: flex;
	align-items: center;
	justify-content: center;

	

	//background: var(--253gray);
}

.docEditor-projectMentionsSheet-header-closeBtn:hover
.docEditor-projectMentionsSheet-header-closeBtn-inner{
	background: var(--250gray);
}

.docEditor-projectMentionsSheet-header-closeBtn svg {
	width: 20px;
	height: 20px;

	fill: var(--text600);
	// fill: $white;

	opacity: 0.3;
	transition: opacity 50ms linear;
}

.docEditor-projectMentionsSheet-header-closeBtn:hover svg{
	opacity: 0.8;
}


//
// MENTION OBJECT

.docEditor-projectMentionsSheet-list{
	width: 100%;
	overflow-y: scroll;
	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;

	padding-top: 12px;
	padding-bottom: 24px;
}

.docEditor-projectMentionsSheet-mention{
	width: 100%;
	display: flex;
	flex-direction: column;

	min-height: 70px; // temp
	border: 1px solid var(--line200);
	border-radius: 4px;

	margin-bottom: 10px;

	cursor: default;
	user-select: none;

	color: var(--text500);

	box-sizing: border-box;
	padding-top: 6px;
	padding-left: 14px;
	padding-right: 16px;
	padding-bottom: 16px;
}

[data-theme="dark"] .docEditor-projectMentionsSheet-mention{
	border: none;
	background: rgba(255,255,255,0.03);
}

[data-theme="dark"] .docEditor-projectMentionsSheet-mention:hover{
	background: rgba(255,255,255,0.04);
}

//

.docEditor-projectMentionsSheet-mention-header{
	height: 30px;
	display: flex;
	align-items: center;

	margin-bottom: 8px;

	opacity: 0.6;
	transition: opacity 50ms linear;
}

.docEditor-projectMentionsSheet-mention:hover
.docEditor-projectMentionsSheet-mention-header{
	opacity: 1;
	transition: opacity 50ms linear;
}	

.docEditor-projectMentionsSheet-mention-header-sourceLogoContainer{
	width: 18px;
	height: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 0px;
	margin-left: -2px;
}

.docEditor-projectMentionsSheet-mention-header-sourceLogoContainer svg{
	width: 12px;
	height: 12px;
	fill: var(--text600);
	opacity: 0.6;
}

.docEditor-projectMentionsSheet-mention-header-dividerDot{
	width: 3px;
	height: 3px;
	background: var(--text500);
	opacity: 0.5;
	border-radius: 2px;
	margin-left: 4px;
	margin-right: 4px;
	display: none;
}

.docEditor-projectMentionsSheet-mention-header-channelName,
.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier{
	height: 24px;
	padding-left: 4px;
	padding-right: 4px;
}

.docEditor-projectMentionsSheet-mention-header-channelName:hover .docEditor-projectMentionsSheet-mention-header-channelName-name,
.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier:hover{
	text-decoration: underline;
}


.docEditor-projectMentionsSheet-mention-header-channelName-name,
.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: var(--text500);
}

.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier{
	font-size: 13.5px;
}

.docEditor-projectMentionsSheet-mention-header-channelName-icon{
	width: 14px;
	height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 2px;
}

.docEditor-projectMentionsSheet-mention-header-channelName-icon svg{
	width: 12px;
	height: 12px;
	fill: var(--text500);
	opacity: 0.75;
}

.docEditor-projectMentionsSheet-mention-header-timestamp,
.docEditor-projectMentionsSheet-mention-header-goToLabel{
	margin-left: 4px;
	font-size: 13px;
	font-weight: $lato-regular;
	color: var(--text300);
	opacity: 0.8;
}

.docEditor-projectMentionsSheet-mention-header-goToLabel{
	display: none;
}

.docEditor-projectMentionsSheet-mention-header-timestamp{
	display: block;
}

.docEditor-projectMentionsSheet-mention-header-channelName:hover ~ .docEditor-projectMentionsSheet-mention-header-subtitle .docEditor-projectMentionsSheet-mention-header-goToLabel,
.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier:hover ~ .docEditor-projectMentionsSheet-mention-header-subtitle .docEditor-projectMentionsSheet-mention-header-goToLabel{
	display: block;
}

.docEditor-projectMentionsSheet-mention-header-channelName:hover ~ .docEditor-projectMentionsSheet-mention-header-subtitle .docEditor-projectMentionsSheet-mention-header-timestamp,
.docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier:hover ~ .docEditor-projectMentionsSheet-mention-header-subtitle .docEditor-projectMentionsSheet-mention-header-timestamp{
	display: none;
}

.docEditor-projectMentionsSheet-mention-header-dots{
	margin-left: auto;
}

.docEditor-projectMentionsSheet-mention-header-dots svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0;
}

//

.docEditor-projectMentionsSheet-mention-messagePreview{
	width: 100%;
	display: flex;

	margin-bottom: 16px;	
}

.docEditor-projectMentionsSheet-mention-messagePreview:last-child{
	margin-bottom: 0px;
}

.docEditor-projectMentionsSheet-mention-messagePreview-avatarContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 9px;
	user-select: none;

	width: 26px;
	height: 26px;
	border-radius: 4px;
}

img.docEditor-projectMentionsSheet-mention-messagePreview-avatar{
	width: 100%;
	height: 100%;
	border-radius: 4px;
}

//

.docEditor-projectMentionsSheet-mention-messagePreview-content{
	display: flex;
	flex-direction: column;

	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;
}

.docEditor-projectMentionsSheet-mention-messagePreview-content-title{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 1px;

	cursor: default;
	user-select: none;
}

.docEditor-projectMentionsSheet-mention-messagePreview-content-title-name{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-right: 6px;
}

.docEditor-projectMentionsSheet-mention-messagePreview-content-messageContent{
	font-weight: $lato-regular;
	font-size: 15.25px;
	color: var(--text500);
	letter-spacing: 0.003em;

	line-height: 1.4;

	min-height: 12px;

	cursor: text;
	word-break: break-word; // I think this is right
	// overflow-wrap: break-word;
	// white-space: pre-wrap;

}

[data-theme="dark"] .docEditor-projectMentionsSheet-mention-messagePreview-content-messageContent span{
	color: lighten(#F2C644, 8%);
	background: darken(#6D4B26, 8%);
	box-shadow: 0px 0px 0px 2px darken(#6D4B26, 8%);
}

.docEditor-projectMentionsSheet-mention-messagePreview-content-messageContent span{	
	border-radius: 1px;

	color: darken(rgb(112,2,195), 10%);
	background: var(--searchResultBG);
	box-shadow: 0px 0px 0px 2px var(--searchResultBG);
	
}