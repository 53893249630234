.doc-figma-topbar{
	//width: 100%;
	height: 38px;
	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-right: 6px;

	cursor: default;
}

.doc-figma-topbar--placeholder{
	pointer-events: none;
}

.doc-figma-topbar-statusBtn{
	margin-left: 8px;
}


.doc-figma-topbar-titleBtn{
	position: relative;
	// background: $tempr;

	padding-left: 6px;
	padding-right: 6px;
	height: 34px;

	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//

.doc-figma-topbar-titleBtn-iconOuterContainer{
	width: 24px;
	height: 24px;
	// background: $tempr;
	margin-right: 2.5px;
	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.doc-figma-topbar-titleBtn-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

}

.doc-figma-topbar-titleBtn-iconContainer--grayscale svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.35;
}

.doc-figma-topbar-titleBtn-iconContainer--color svg{
	width: 18px;
	height: 18px;
}


// .doc-figma-topbar-titleBtn
// .doc-figma-topbar-titleBtn-iconContainer--grayscale{
// 	opacity: 1;
// 	transition: all 0ms linear;
// }



// .doc-figma-topbar-titleBtn
// .doc-figma-topbar-titleBtn-iconContainer--color{
// 	opacity: 0.5;
// 	transition: all 0ms linear;
// }

// .doc-figma-topbar-titleBtn:hover
// .doc-figma-topbar-titleBtn-iconContainer--grayscale{
// 	opacity: 0.5;
// 	transition: all 0ms linear;
// 	transition-delay: 250ms;
// }

// .doc-figma-topbar-titleBtn:hover
// .doc-figma-topbar-titleBtn-iconContainer--color{
// 	opacity: 1;
// 	transition: all 0ms linear;
// 	transition-delay: 250ms;
// }

//

.doc-figma-topbar-titleBtn-label{
	color: var(--text600);
	text-align: left;
	opacity: 0.85;

	//font-size: 16px;
	font-size: 15.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.002em;

	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  flex-grow: 1;
	flex-shrink: 1;
}

.doc-figma-topbar--placeholder
.doc-figma-topbar-titleBtn-label{
	pointer-events: none;
	opacity: 0.5;
}

.doc-figma-topbar-titleBtn-label span{
	opacity: 0.5;
	margin-left: 2px;	
}

.doc-figma-topbar-titleBtn:hover
.doc-figma-topbar-titleBtn-label{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}

.doc-figma-topbar-titleBtn .doc-figma-topbar-titleBtn-tooltipIconOuterContainer,
.doc-figma-topbar-titleBtn .doc-figma-topbar-titleBtn-tooltipContainer{
	width: 0px;
	overflow: hidden;

	position: relative;
	z-index: 20;
}

.doc-figma-topbar-titleBtn:hover .doc-figma-topbar-titleBtn-tooltipIconOuterContainer{
	margin-left: 2px;
	width: auto;
}

.doc-figma-topbar-titleBtn:hover .doc-figma-topbar-titleBtn-tooltipContainer{
	padding-left: 8px;
	width: auto;
	height: 100%;

	display: flex;
	align-items: center;
}


// .doc-figma-topbar-titleBtn-tooltipIconContainer{
// 	width: 24px;
// 	height: 24px;

// 	opacity: 0;

// 	transition: all 100ms linear;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .doc-figma-topbar-titleBtn-tooltipIconContainer svg{
// 	width: 17px;
// 	height: 17px;
// 	color: var(--text500);
// 	opacity: 0.5;
// }

// .doc-figma-topbar-titleBtn:hover
// .doc-figma-topbar-titleBtn-tooltipIconContainer{
// 	opacity: 1;	
	
// 	transition: all 100ms linear;
// 	transition-delay: 500ms;
// }

//

.doc-figma-topbar-titleBtn-tooltip{
	opacity: 0;
	transform: translateX(-5px);

	transition: all 100ms linear;

	font-size: 14.5px !important; // not sure what's overriding this

	position: relative;
	top: 0.5px;

	font-weight: $lato-regular;
	letter-spacing: 0.002em;
	color: var(--text600);
}

.doc-figma-topbar-titleBtn:hover
.doc-figma-topbar-titleBtn-tooltip{
	opacity: 0.5;
	transform: translateX(0px);
	
	transition: all 100ms linear;
	transition-delay: 500ms;
}

//
//

.doc-figma-effectsToast{
	position: absolute;
	bottom: 0px;

	height: 24px;
	z-index: 50;
	left: 50%;
	transform: translateX(-50%);

	background: $tempr;

	padding-left: 15px;
  padding-right: 15px;

  display: flex;
  align-items: center;

  height: 32px;
  border-radius: 7px;
  background: rgb(40,38,54);
  
  border: 1px solid rgb(0,0,0);

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);  

  user-select: none;


  //
  color: rgba(255,255,255,0.75);
  font-size: 15px;
  font-weight: $lato-medium;  
  letter-spacing: 0.008em;

  // position: relative;
  // top: -1px;
}

//

.doc-figma-topbar-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}


.doc-figma-topbar-toggleEffectsBtn,
.doc-figma-topbar-dropdownBtn,
.doc-figma-topbar-refreshBtn,
.doc-figma-topbar-commentBtn{	
	height: 30px;

	flex-grow: 0;
	flex-shrink: 0;
	
	border-radius: 4px;
}

.doc-figma-topbar-toggleEffectsBtn{
	width: 36px;
}

.doc-figma-topbar-dropdownBtn,
.doc-figma-topbar-refreshBtn{
	width: 32px;
}

.doc-figma-topbar-toggleEffectsBtn:hover,
.doc-figma-topbar-dropdownBtn:hover,
.doc-figma-topbar-refreshBtn:hover,
.doc-figma-topbar-commentBtn:hover{
	background: var(--250gray);
}

.doc-figma-topbar-toggleEffectsBtn svg{
	width: 20px;
	height: 20px;
}

.doc-figma-topbar-dropdownBtn svg{
	width: 18px;
	height: 18px;
}

.doc-figma-topbar-refreshBtn svg{
	width: 16px;
	height: 16px;
}

.doc-figma-topbar-commentBtn svg{
	width: 16px;
	height: 16px;
	position: relative;
	top: 1px;
}

.doc-figma-topbar-toggleEffectsBtn svg,
.doc-figma-topbar-dropdownBtn svg,
.doc-figma-topbar-refreshBtn svg,
.doc-figma-topbar-commentBtn svg{
	fill: var(--text500);
	opacity: 0.6;
}

.doc-figma-topbar-toggleEffectsBtn:hover svg,
.doc-figma-topbar-dropdownBtn:hover svg,
.doc-figma-topbar-refreshBtn:hover svg,
.doc-figma-topbar-commentBtn:hover svg{
	opacity: 0.8;
}

.doc-figma-topbar-commentBtn{
	padding-left: 8px;
	padding-right: 8px;
}

.doc-figma-topbar-commentBtn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.003em;
	margin-left: 4px;

	opacity: 0.65;
}

.doc-figma-topbar-commentBtn:hover
.doc-figma-topbar-commentBtn-label{
	opacity: 1;
}

.doc-figma-topbar-commentBtn-iconContainer{
	height: 100%;
	display: flex;
	align-items: center;
}


//
// THREAD BUTTON

//
//


.doc-figma-topbar-threadSummaryBtn{
	height: 28px;
	//background: var(--messageThreadBtnBG);
	border-radius: 5px;

	display: flex;
	align-items: center;

	padding-left: 7px;
	padding-right: 6px;

	border: 1px solid $invisible;
}

.doc-figma-topbar-threadSummaryBtn--hasUnreads{
	//background: transparentize(rgb(224,29,90), 0.95);
}

.doc-figma-topbar-threadSummaryBtn:hover{
	background: var(--250gray);
	// border: 1px solid var(--messageThreadBtnHoverBorder);
}


.doc-figma-topbar-threadSummaryBtn-unreadMarkerContainer{
	width: 17px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.doc-figma-topbar-threadSummaryBtn-unreadMarker{
	height: 8px;
	width: 8px;
	margin-left: 1px;
	border-radius: 26px;
	background: var(--unreadCountTextColor);
	opacity: 0.75;
}
//

.doc-figma-topbar-threadSummaryBtn-avatarRow{
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	
	margin-left: 5px;
}

.doc-figma-topbar-threadSummaryBtn-avatarContainer{
	width: 20px;
	height: 20px;
	margin-right: 3px;
}

.doc-figma-topbar-threadSummaryBtn-avatarContainer:last-child{
	margin-right: 0px;
}

.doc-figma-topbar-threadSummaryBtn-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 3px;
}

//

.doc-figma-topbar-threadSummaryBtn-labelRow{

	display: flex;
	align-items: baseline;
	box-sizing: border-box;
	
}

.doc-figma-topbar-threadSummaryBtn-messageCount,
.doc-figma-topbar-threadSummaryBtn-unreadCount{
	font-size: 13px;
	font-weight: $lato-medium;
	color: var(--text600);	
	letter-spacing: 0.008em;

	margin-right: 5px;
}

.doc-figma-topbar-threadSummaryBtn--noUnreads
.doc-figma-topbar-threadSummaryBtn-messageCount{
	opacity: 0.5;
}

.doc-figma-topbar-threadSummaryBtn--noUnreads:hover
.doc-figma-topbar-threadSummaryBtn-messageCount{
	opacity: 1;
}


.doc-figma-topbar-threadSummaryBtn-unreadCount{
	font-weight: $lato-semibold;
	//color: var(--unreadCountTextColor);
}

.doc-figma-topbar-threadSummaryBtn-timestampLabel{
	font-size: 12.25px;
	font-weight: $lato-regular;
	color: var(--text300);
	opacity: 0.7;
	margin-right: 3px;
}

// .doc-figma-topbar-threadSummaryBtn--hasUnreads
// .doc-figma-topbar-threadSummaryBtn-timestampLabel{
// 	//color: var(--unreadCountTextColor);
// 	opacity: 0.7;
// }

.doc-figma-topbar-threadSummaryBtn-endIconContainer{
	height: 100%;
	display: flex;
	align-items: center;

	margin-left: 7px;
	margin-left: 16px;
}

.doc-figma-topbar-threadSummaryBtn-endIconContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text600);
	opacity: 0.25;
	margin-top: 1px;
}

.doc-figma-topbar-threadSummaryBtn:hover 
.doc-figma-topbar-threadSummaryBtn-endIconContainer svg{
	opacity: 0.5;
}

// .doc-figma-topbar-threadSummaryBtn--hasUnreads
// .doc-figma-topbar-threadSummaryBtn-endIconContainer svg{
// 	//fill: var(--unreadCountTextColor);
// 	opacity: 0.5;
// }


@media screen and (max-width: 1360px){
	.doc-figma-topbar-threadSummaryBtn-timestampLabel{		
		display: none;
	}	
}
