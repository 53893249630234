.docEditor-messagePanel-footer{
	// background: $tempr;
}
.messagePanel-footerFollowBoxContainer{
	width: 100%;
	height: 100%;
	//height: calc(100% - 1px);
	//border-top: 1px solid var(--line300);
	// background: $tempr;
	
	box-sizing: border-box;
	padding-left: 12px;	
	padding-right: 12px;

	padding-top: 0px;
	padding-bottom: 12px;

	cursor: default;
	user-select: none;

	// background: var(--253gray);

	display: flex;
	align-items: center;
	justify-content: center;
}

.messagePanel-footerFollowBox{
	width: 100%;
	height: 100%;

	max-width: 390px;

	box-sizing: border-box;
	padding-left: 18px;
	padding-right: 18px;
	padding-bottom: 14px;
	padding-top: 16px;

	border-radius: 6px;
	// background: var(--250gray);
	border: 1px solid var(--line300);
	border-bottom: 1px solid var(--line400);

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);

	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

:root[data-theme="dark"]
.messagePanel-footerFollowBox{
	background: rgba(255,255,255,0.03);
	border: 1px solid var(--line200);
	border-bottom: 1px solid var(--line300);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.18);
}

.messagePanel-footerFollowBox-markerContainer{
	font-size: 26px;
	margin-bottom: 10px;
}

.messagePanel-footerFollowBox-markerContainer svg{
	width: 26px;
	height: 26px;
}

.messagePanel-footerFollowBox-title{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text500);

	margin-bottom: 6px;
}

.messagePanel-footerFollowBox-description{
	display: flex;
	flex-direction: column;
	
	gap: 4px;
}

.messagePanel-footerFollowBox-description-para{
	font-size: 14.5px;
	font-weight: $lato-regular;
	color: var(--text300);

	letter-spacing: 0.004em;
	line-height: 1.25;

	margin-bottom: 6px;

}

//

.messagePanel-footerFollowBox-vSpacer{
	flex-grow: 1;
	flex-shrink: 1;
	min-height: 10px;
}

//

.messagePanel-footerFollowBox-joinBtnContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.messagePanel-footerFollowBox-joinBtn{
	background: var(--primary500);
	border: 1px solid var(--primary600);

	background: var(--text400);
	border: 1px solid var(--text500);
	border-radius: 4px;

	font-size: 16px;
	font-weight: $lato-bold;
	color: $white;

	flex-grow: 1;
	flex-shrink: 1;
	max-width: 190px;
	height: 34px;
}

.messagePanel-footerFollowBox-joinBtn:hover{
	background: var(--primary550);
	border: 1px solid var(--primary600);

	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]
.messagePanel-footerFollowBox-joinBtn{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.messagePanel-footerFollowBox-joinBtn:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}