.projectsPage-projectRow--lastItemInGroup ~ .roadmapsProjectRow-milestonesContainer{ 
	margin-top: -20px;
	margin-bottom: 8px;
}

// fix for nonstatus grouped

.roadmapsProjectRow-milestonesContainer{
	// padding-bottom: 12px;
	box-sizing: border-box;

	padding-left: 36px;

	position: relative;
	opacity: 0.8;
	
}

.roadmapsProjectRowContainer:hover 
.roadmapsProjectRow-milestonesContainer{
	opacity: 1;
	
}

.roadmapsProjectRow-milestonesContainer-line{
	position: absolute;
	top: 4px;
	left: 14px;
	width: 1px;
	bottom: 18px;

	background: var(--line200);
}


.roadmapsProjectRow-milestoneRow{
	width: 100%;
	
	height: 28px;	

	display: flex;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;

	cursor: pointer;

	border-radius: 4px;

	padding-left: 9px;
	padding-right: 12px;

  margin-left: -14px;
  width: calc(100% + 14px);

	border: 1px solid $invisible;
	user-select: none;

	// background: $tempr;

	// background: $tempr;
}

.roadmapsProjectRow-milestoneRow:hover{
	background: var(--253gray);
	outline: 1px solid var(--line100);
}

.roadmapsProjectRow-milestoneRow--completed{
	opacity: 0.5;	
}

.roadmapsProjectRowContainer:hover 
.roadmapsProjectRow-milestoneRow--completed{
	opacity: 1;

}

.roadmapsProjectRow-milestoneRow-iconContainer{
	width: 24px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 7px;

	// background: $tempr;
	box-sizing: border-box;
	padding-bottom: 1px;
}

.roadmapsProjectRow-milestoneRow-iconContainer
.linearMilestoneIcon{
	transform: scale(0.9);
}

.roadmapsProjectRow-milestoneRow-iconContainer
.roadmapsProjectRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg{
	// transition: all 200ms linear;
	// opacity: 0.75;
}

.roadmapsProjectRowContainer:not(:hover)
.roadmapsProjectRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg #bg{
	stroke: var(--line600) !important;
}

.roadmapsProjectRowContainer:not(:hover)
.roadmapsProjectRow-milestoneRow-iconContainer .linearMilestoneIcon--incomplete.linearMilestoneIcon--inactiveMilestone svg #progress{
	stroke: var(--line600) !important;
}

.roadmapsProjectRowContainer:not(:hover)
.roadmapsProjectRow-milestoneRow-iconContainer .linearMilestoneIcon--completed svg #bg{
	// stroke: var(--line600) !important;	
	// fill: var(--line600) !important;

	// dirty, opaqure version of above
	stroke: #6E6D70 !important;	
	fill: #6E6D70 !important;
	
}


.roadmapsProjectRow-milestoneRow-name{
	font-size: 15.75px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
	color: var(--text500);

	display: flex;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;
}



.roadmapsProjectRow-milestoneRow-name-milestoneStats{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text400);

	position: relative;
	top: 1px;

	margin-left: 12px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	
	flex-grow: 1;
	flex-shrink: 1;

	opacity: 0;
	transition: opacity 100ms linear;
}

.roadmapsProjectRow:hover ~ .roadmapsProjectRow-milestonesContainer
.roadmapsProjectRow-milestoneRow-name-milestoneStats,
.roadmapsProjectRow-milestonesContainer:hover
.roadmapsProjectRow-milestoneRow-name-milestoneStats{
	opacity: 0.8;
	transition: opacity 100ms linear;
	transition-delay: 0ms;
}

// .roadmapsProjectRowContainer:hover
// .roadmapsProjectRow-milestoneRow:hover
// .roadmapsProjectRow-milestoneRow-name-milestoneStats{
// 	opacity: 1;
// 	transition: opacity 50ms linear;

// 	transition-delay: 0ms;
// }

.roadmapsProjectRow-milestoneRow-name-milestoneStats-dash{
	opacity: 0.7;
	margin-left: 3px;
	margin-right: 3px;
}
.roadmapsProjectRow-milestoneRow-name-milestoneStats-total{
	opacity: 0.7;
	// min-width: 18px;
	text-align: right;
}
	

