.projectsPage-projectCardsGrid{
	width: 100%;

	display: grid;
  grid-column-gap: 96px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) ;

  box-sizing: border-box;
  padding-right: 96px;

  margin-top: 12px;
}

.projectsPage-projectCard{
	display: flex;
	flex-direction: column;	
	// border: 1px solid var(--line300);
	// border-bottom: 1px solid var(--line400);

	// border-radius: 7px;

	box-sizing: border-box;
	// padding-left: 22px;
	// padding-right: 22px;

	padding-top: 22px;
	padding-bottom: 16px;

	height: 170px;
	// background: $tempr;

	cursor: default;
	position: relative;
	padding-left: 56px;
}

//

.projectsPage-projectCard-topLine{
	display: flex;
	align-items: center;

	width: 100%;
	margin-bottom: 8px;
}

//

.projectsPage-projectCard-topLine-markerContainer{
	flex-grow: 0;
	flex-shrink: 0;
	width: 32px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	position: absolute;
	top: 48px;
	left: 0px;
}

.projectsPage-projectCard-topLine-markerContainer
.linearMarker{
	font-size: 28px;
}

.projectsPage-projectCard-topLine-markerContainer
.linearMarker svg{
	width: 28px;
	height: 28px;
}

//

.projectsPage-projectCard-topLine-teamTagContainer{
	
	display: flex;
	align-items: center;
	justify-content: flex-start;
	// background: $tempr;
	gap: 5px;

	margin-left: auto;
	margin-right: 8px;
}

.projectsPage-projectCard-topLine-teamTag{
	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--250gray);

	height: 22px;

	border-radius: 4px;
	padding-left: 6px;
	padding-right: 6px;
}


.projectsPage-projectCard-topLine-teamTag-label{
	font-size: 14px;
	color: var(--text500);
	opacity: 0.85;
	font-weight: $lato-medium;

	position: relative;
	top: -0.5px;

	margin-left: 4px;
}

.projectsPage-projectCard-topLine-teamTag-markerContainer{
	position: relative;
	top: 1px;	
}

.projectsPage-projectCard-topLine-teamTag-markerContainer
.linearMarkerContainer--markerType--emoji{
	font-size: 13px;
}

.projectsPage-projectCard-topLine-teamTag-markerContainer
.linearMarkerContainer--markerType--icon svg{
	width: 13px;
	height: 13px;
}


//

.projectsPage-projectCard-topLine-members{
	// background: $tempr;
	position: relative;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.projectsPage-projectCard-topLine-members-lead{
	width: 24px;
	height: 24px;

	border-radius: 4px;
	overflow: hidden;
}

img.projectsPage-projectCard-topLine-lead-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//

.projectsPage-projectCard-title{
	font-size: 18.5px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-bottom: 4px;
}

.projectsPage-projectCard-description{
	font-size: 15.25px;
	font-weight: $lato-regular;
	color: var(--text400);
	letter-spacing: 0.008em;
	line-height: 1.35;
	opacity: 0.75;

	max-width: 400px;

	max-height: 80px;

	overflow: hidden;

	display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
}


//

