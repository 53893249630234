$background-tabBar: rgb(251,251,249);
$background-tabBar: $invisible;
//$background-activeTab: rgb(242,240,240);
$background-activeTab: rgba(255,255,255,0.7);

.tabBar{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: calc(#{$height-tabBar} - 1px); // account for border on bottom

	display: flex;

	-webkit-user-select: none;
	-webkit-app-region: drag;

	cursor: default !important;
  
  z-index: 100;

  background: var(--bgTabBar);  
 
  border-bottom: 1px solid var(--borderColorTabBar);
}

// HIDE OTHER BROWSER VIEWS

.tabBar.tabBar--browserViewState--inactive{
	visibility: hidden;
	pointer-events: none;
	// background: $tempr;
}

.tabBar.tabBar--browserViewState--active{
	// background: $tempb;
}


// Don't think need this anymore
// .tabBar-bgClick{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	background: none;
// 	//-webkit-app-region: no-drag;
// 	cursor: default !important;
// 	-webkit-app-region: drag;
// }



$gray-tabLine: rgb(235, 235, 234);

.tabBar-tabContainer{
	height: 100%;	
	display: flex;
	align-items: center;
	background: $invisible;

	box-sizing: border-box;
	padding-top: 2px;
	padding-bottom: 1px;
	padding-right: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  z-index: 10;

  -webkit-app-region: no-drag;

	// background: $tempr;
	// outline: 1px solid red;
}

.tabBar-tab{
	//height: 32px;
	height: 34px; // bottom test
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;

	position: relative;

	border: none;
	background: none;
	box-shadow: none;	

	box-sizing: border-box;

	padding: 0px;	
	-webkit-app-region: no-drag;
	//box-shadow: 0px 1px 0px 0px rgb(242,242,242);
	
	border-radius: 4px;

	z-index: 0;

	padding-left: 10px;
	padding-right: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: default !important;


	// outline: 1px solid blue;

	// TEST WITH THEM AT THE BOTTOM
	margin-bottom: -6px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}


.tabBar-tabContainer--docTab{
	width: 240px; // work out how to make this responsive
	flex-shrink: 1;
}

.tabBar-tabContainer--libraryTab{
	padding-left: 92px; // width of mac dots
	box-sizing: border-box;
	flex-grow: 0;
	flex-shrink: 0;
}

.tabBar-tabContainer--libraryTab
.tabBar-tab{
	width: 100%;
	justify-content: center;

	box-sizing: border-box;
	padding-right: 18px; // hacky visual centering
}

.tabBar-tabContainer--activeTab 
.tabBar-tab{
	z-index: 10;
	background: var(--bgActiveTab-tabBar);
}

.tabBar-tabContainer--activeTab 
.tabBar-tab:hover{	
	background: var(--hoverBgActiveTab-tabBar);
}

.tabBar-tabContainer--backgroundTab
.tabBar-tab{
	background: var(--bgBackgroundTab-tabBar);	
}

.tabBar-tabContainer--backgroundTab
.tabBar-tab:hover{
	background: var(--hoverBgBackgroundTab-tabBar);
}

//

.tabBar-tab-iconContainer{
	width: 20px;
	
	height: 32px;

	margin-right: 6px;

	// background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;	

	display: flex;
	align-items: center;
	justify-content: center;
}

// LIBRARY
.tabBar-tab-iconContainer .icon--name--threeLines{	
	width: 14px;
	height: 14px;	
}

.tabBar-tabContainer--activeTab
.tabBar-tab-iconContainer .icon--name--threeLines{
	fill: var(--primary500);
}

.tabBar-tabContainer--backgroundTab
.tabBar-tab-iconContainer .icon--name--threeLines{
	//fill: rgb(140,140,140);
	fill: var(--text100);
}


//

.tabBar-tab-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 0;
	font-weight: $lato-semibold;
	font-size: 14px;
	letter-spacing: 0.01em;	

	text-align: left;

	display: flex;

  box-sizing: border-box;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	// margin-top: 1px;
}

.tabBar-tabContainer--libraryTab
.tabBar-tab-titleContainer{
	flex-grow: 0;
	flex-shrink: 0;
	text-align: center;
}


//

.tabBar-tab-title{
	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabBar-tabContainer--activeTab
.tabBar-tab-title{
	color: var(--text600);
}

.tabBar-tabContainer--backgroundTab
.tabBar-tab-title{
	color: var(--text300);
	// color: red;
}


//

.tabBar-tab-divider{
	height: 50%;
	background: var(--bgDivider-tabBar);
	
	width: 1px;
	margin-right: 0px;

	flex-grow: 0;
	flex-shrink: 0;
}

.tabBar-tabContainer--activeTab
.tabBar-tab-divider{
	visibility: hidden;
}

.tabBar-tabContainer--libraryTab
.tabBar-tab-divider{
	position: absolute;
	right: 0px;
	display: none; // hacky for now;
}



// END

.tabBar-tab-closeTabBtn{
	width: 30px;
	height: 32px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 0px;

	border: none;
	background: none;
	box-shadow: none;

	// background: $tempr;
}

.tabBar-tab-closeTabBtn-inner{
	width: 20px;
	height: 20px;
	border-radius: 12px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.tabBar-tab-closeTabBtn-inner svg{
	height: 14px;
	width: 14px;

	fill: var(--text200);
	
}

.tabBar-tab-closeTabBtn:hover
.tabBar-tab-closeTabBtn-inner{
	background: rgb(240,240,240);
}

.tabBar-tab-closeTabBtn:hover
.tabBar-tab-closeTabBtn-inner svg{
	opacity: 1;
}

.tabBar-tabContainer:hover
.tabBar-tab-closeTabBtn{
	opacity: 1;
}

@import "ElectronTabBar-ActionMenu.scss";


//

.tabBarSpacer{
	// displayed during signup-sign-in;
	height: $height-tabBar;
	background: $white;
}


// Later/ tab bars
@import "ElectronTabBar-TabIndicators.scss";

