      			


.actionCommandMenu-row{
	height: 38px;
	width: 100%;
	//background: $tempr;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 12px;

	border-radius: 4px;

	user-select: none;
	cursor: pointer;

	position: relative;
}

//// TEMP GET RID OF JASPER
.actionCommandMenu-row:nth-child(8){
	display: none;
}

.actionCommandMenu-row-visual--avatar-avatar{
	width: 26px;
	height: 26px;
}

.actionCommandMenu-row--activeStatus--active{
	background: rgba(255,255,255,0.10);
}

.actionCommandMenu-row--activeStatus--active:hover{
	background: rgba(255,255,255,0.11);
}

.actionCommandMenu-row--activeStatus--inactive:hover{
	background: rgba(255,255,255,0.08);	
}


.actionCommandMenu-row--activeStatus--inactive
.actionCommandMenu-row-label{
	opacity: 0.85;
}
.actionCommandMenu-row--activeStatus--inactive
.actionCommandMenu-row-visual{
	opacity: 0.95;
}

$activatedBrightBG: rgba(255,255,255,0.18);
$activatedDarkBG: rgba(255,255,255,0.1);

@keyframes activatedFlash { 
  0% { background: $activatedBrightBG; } 
  50% { background: $activatedDarkBG; }
}

.actionCommandMenu-row--activeStatus--activated{
	animation: activatedFlash 150ms 2 linear;
}

//

.actionCommandMenu-row-visual{
	width: 26px;
	height: 26px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,0,0.05);

	border-radius: 5px;
	overflow: hidden;
	margin-right: 10px;
}

//

.actionCommandMenu-row-label{
	display: flex;
	align-items: center;

	font-size: 15.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: $white;

	margin-right: 6px;
}

.actionCommandMenu-row-actionLabel{
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	opacity: 0.75;

	margin-right: auto;
	// margin-left: auto;
	opacity: 0;
}

.actionCommandMenu-row--activeStatus--active
.actionCommandMenu-row-actionLabel{
	opacity: 0.8;
}

.actionCommandMenu-row--activeStatus--inactive:hover
.actionCommandMenu-row-actionLabel{
	opacity: 0.8;
}

// .actionCommandMenu-row-actionLabel--filteredResults{
// 	margin-right: auto;
// }

// .actionCommandMenu-row-actionLabel--emptyState{
// 	margin-left: auto;
// }

.actionCommandMenu-row--actionMenuUser:active{
	background: rgba(255,255,255,0.15);
}


.actionCommandMenu-row--actionMenuUser--actionType--ping
.actionCommandMenu-row-actionLabel{
	color: $lightBlue-ping-actionCommandMenu;
}

.actionCommandMenu--actionCommandMenuType--directMessage
.actionCommandMenu-row-actionLabel{
	color: $lightPurple-directMessage-actionCommandMenu;
}

.actionCommandMenu--actionCommandMenuType--jumpToCursor
.actionCommandMenu-row-actionLabel{
	color: $lightGreen-jumpToCursor-actionCommandMenu;
}

//

.actionCommandMenu-row-enterKeyTag{
	

	// margin-left: 8px;
	margin-left: auto;

	height: 22px;
	padding-left: 6px;
	padding-right: 6px;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
	background: $tempr;

	background: transparentize($lightBlue-ping-actionCommandMenu, 0.8);
}

.actionCommandMenu-row-enterKeyTag-label{
	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;
	color: $lightBlue-ping-actionCommandMenu;

	margin-right: 3px;
}

.actionCommandMenu-row-enterKeyTag-iconContainer{
	width: 14px;
	height: 14px;
	height: 100%;

	display: flex;	
	align-items: center;
}

.actionCommandMenu-row-enterKeyTag-iconContainer svg{
	width: 14px;
	height: 14px;
}



.actionCommandMenu-row--actionMenuUser--actionType--ping
.actionCommandMenu-row-enterKeyTag{
	background: transparentize($lightBlue-ping-actionCommandMenu, 0.85);
}

.actionCommandMenu-row--actionMenuUser--actionType--ping
.actionCommandMenu-row-enterKeyTag-label{
	color: $lightBlue-ping-actionCommandMenu;
}

.actionCommandMenu-row--actionMenuUser--actionType--ping
.actionCommandMenu-row-enterKeyTag-iconContainer svg{
	fill: $lightBlue-ping-actionCommandMenu;
}

//

.actionCommandMenu-row--actionMenuUser--actionType--directMessage
.actionCommandMenu-row-enterKeyTag{
	background: transparentize($lightPurple-directMessage-actionCommandMenu, 0.85);
}

.actionCommandMenu-row--actionMenuUser--actionType--directMessage
.actionCommandMenu-row-enterKeyTag-label{
	color: transparentize($lightPurple-directMessage-actionCommandMenu, 0.15);
	//color: $lightPurple-directMessage-actionCommandMenu;
}

.actionCommandMenu-row--actionMenuUser--actionType--directMessage
.actionCommandMenu-row-enterKeyTag-iconContainer svg{
	//fill: $lightPurple-directMessage-actionCommandMenu;
	fill: transparentize($lightPurple-directMessage-actionCommandMenu, 0.15);
}

//

.actionCommandMenu-row--actionMenuUser--actionType--jumpToCursor
.actionCommandMenu-row-enterKeyTag{
	background: transparentize($lightGreen-jumpToCursor-actionCommandMenu, 0.85);
}

.actionCommandMenu-row--actionMenuUser--actionType--jumpToCursor
.actionCommandMenu-row-enterKeyTag-label{
	color: $lightGreen-jumpToCursor-actionCommandMenu;
}

.actionCommandMenu-row--actionMenuUser--actionType--jumpToCursor
.actionCommandMenu-row-enterKeyTag-iconContainer svg{
	fill: $lightGreen-jumpToCursor-actionCommandMenu;
}

