$width-insertMentionsMenu: 400px;

.insertMentionsMenu{	
	width: $width-insertMentionsMenu;
	
	box-sizing: border-box;
	
	padding-top: 3px;
	padding-top: 6px;
	padding-bottom: 12px;
	
	

	background: var(--bg-dropdown);

	border-radius: 5px;

	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dropdown);
	
	z-index: 500;

	position: relative;
	user-select: none;
}

.insertMentionsMenu.insertMentionsMenu--emptyAssignSuggestions{
	width: 300px; // when assignment, narrower on load
}




.insertMentionsMenu:focus{
	outline: none;
}

.insertMentionsMenu-segmentHeader{
	width: 100%;
	color: var(--text500);
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.65;

	margin-top: 5px;
	margin-bottom: 5px;
	padding-left: 13px;

	box-sizing: border-box;
}
	
.insertMentionsMenu-dividerContainer{
	width: 100%;
	height: 1px;
	margin-top: 8px;
	margin-bottom: 8px;

	box-sizing: border-box;
	padding-left: 13px;
	padding-right: 0px;
}

.insertMentionsMenu-divider{
	background: var(--line400);
	opacity: 0.5;
	height: 100%;
	width: 100%;
}




//

@import 'DocInsertMentionsMenu-Item';

//
