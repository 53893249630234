

.dialogOverlay.dialogOverlay--commandMenu{
	align-items: flex-start;
	padding-top: 20vh;
	
	// for video
	// padding-top: 50vh;
	// background: #35343C;
}

.commandMenu{
	width: 480px;	

	// background: rgb(55,53,68);
	background: rgba(22,21,26,0.88);

	border: 1px solid darken(rgb(55,53,68), 5%);
	border-radius: 8px;

	box-shadow:
	  0px 2.8px 2.2px rgba(0, 0, 0, 0.028),
	  0px 6.7px 5.3px rgba(0, 0, 0, 0.04),
	  0px 12.5px 10px rgba(0, 0, 0, 0.05),
	  0px 22.3px 17.9px rgba(0, 0, 0, 0.06),
	  0px 41.8px 33.4px rgba(0, 0, 0, 0.072),
	  0px 100px 80px rgba(0, 0, 0, 0.1)
	;

	// box-shadow:
	//   0px 2px 4px 0px rgba(0,0,0,0.14),
	//   0px 4px 8px 0px rgba(0,0,0,0.12),
	//   0px 8px 16px 0px rgba(0,0,0,0.10),
	//   0px 16px 32px 0px rgba(0,0,0,0.08),
	//   0px 32px 64px 0px rgba(0,0,0,0.06);
	// ;


	display: flex;
	flex-direction: column;

	backdrop-filter: blur(24px);

	position: relative;
}

.commandMenu-input{
	width: 100%;
	height: 46px;
	position: relative;

	box-sizing: border-box;

	
	padding-left: 10px;
	padding-right: 10px;

	border-bottom: 1px solid rgba(255,255,255,0.1);

	margin-bottom: 8px;

	flex-grow: 0;
	flex-shrink: 0;

}

$indentWidth-input-specialKMenu: 52px;

input.commandMenu-input-input{
	border: none;
	border-radius: none;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;

	box-sizing: border-box;
	padding-top: 5px;

	background: none;

	padding-left: $indentWidth-input-specialKMenu;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: $white;
	letter-spacing: 0.005em;

	caret-color: #81BCFF;
	
	z-index: 10;
}

input.commandMenu-input-input:focus{
	outline: none;
	border: none;
}

input.commandMenu-input-input::placeholder{
	color: rgba(255,255,255,0.5);
	font-weight: $lato-medium;
}

.commandMenu-input-UI{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: $indentWidth-input-specialKMenu;

	pointer-events: none;

	// display: none;
}

.commandMenu-input-UI-iconContainer{
	width: 100%;
	height: 100%;

	padding-left: 8px;
	padding-top: 4px;
	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;
}

.commandMenu-input-UI-iconContainer svg{
	fill: $white;
	opacity: 0.3;
	height: 22px;
	width: 22px;	
}

.commandMenu-input--notEmpty
.commandMenu-input-UI-iconContainer svg{
	opacity: 0.5;	
}

.commandMenu-input-UI-nicoleTest{
	position: absolute;
	top: 15.2px;
	left: 52px;
	font-size: 16px;
	font-weight: $lato-semibold;
	color: white;
	opacity: 0.2;
	letter-spacing: 0.005em;

	z-index: 0;
}

.commandMenu-input-UI-avatarContainer{
	position: absolute;
	top: 12px;
	left: 16px;
	width: 26px;
	height: 26px;
	border-radius: 5px;

	overflow: hidden;

	background: $tempr;

	outline: 1px solid rgba(255,255,255,0.25);
}


.commandMenu-input-UI-status{
	position: absolute;
	left: 180px;
	top: 2px;
	bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	width: 250px;
	pointer-events: none;
}

.commandMenu-input-UI-status-timezone{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: rgba(255,255,255,0.5);
	letter-spacing: 0.01em;

	margin-right: 6px;
}

.commandMenu-input-UI-status-presenceTag{
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 6px;
	padding-right: 8px;

	height: 24px;
	background: rgba(255,255,255,0.08);
	background: transparentize(#33C99D, 0.9); 

	border-radius: 4px;

}

.commandMenu-input-UI-status-presenceTag-circle{
	border-radius: 50%;
	height: 5px;
	width: 5px;
	background: #33C99D;

	margin-right: 5px;

}

.commandMenu-input-UI-status-presenceTag-label{
	font-size: 13.75px;
	color: rgba(255,255,255,0.8);
	font-weight: $lato-semibold;
	letter-spacing: 0.005em;
}




.commandMenu-resultsList{
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;
	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;

	//min-height: 140px;
	max-height: 295px;

	overflow-y: scroll;

	padding-bottom: 24px;
}

.commandMenu-resultsList-sectionDivider{
	height: 10px;
}



// ROW

@import 'CommandMenu-Row'; 

