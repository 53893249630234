$zoomedFigmaModal-sideMargin: 20px;
$zoomedFigmaModal-topMargin: 30px;
$zoomedFigmaModal-bottomMargin: 20px;
//$headerHeight-zoomedFigmaModal: 46px;
$headerHeight-zoomedFigmaModal: 40px;
// $footerHeight-zoomedFigmaModal: 40px;
$footerHeight-zoomedFigmaModal: 56px;
$closeBtnContainerWidth-zoomedFigmaModal: 44px;

.doc-zoomedFigmaModalContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	z-index: 100;

	background: var(--bg-zoomedMediaModals);

	overflow-y: hidden;
	overflow-x: hidden;
}

.doc-zoomedFigmaModalContainer:focus{
	outline: none;
}

.doc-zoomedFigmaModal-captureOutsideClick{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $invisible;
}


.doc-zoomedFigmaModal{
	//margin-top: 10px;
	width: calc(100vw - #{$zoomedFigmaModal-sideMargin} - #{$zoomedFigmaModal-sideMargin});
	height: calc(100vh - #{$zoomedFigmaModal-topMargin} - #{$zoomedFigmaModal-bottomMargin});
	max-width: 2000px;
	max-height: 1200px;
	background: rgb(0,0,0);
	border: 1px solid lighten(rgb(36,33,42), 4%);
	border-top: 1px solid lighten(rgb(36,33,42), 10%);

	border-radius: 4px;
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 8px 16px 0px rgba(0,0,0,0.12),
	;

	display: flex;

	position: relative;
	z-index: 50;

	overflow: hidden;
}

.doc-zoomedFigmaModal-modalMeasurer{
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;	
}


.doc-zoomedFigmaModal-centerContent{
	
	position: absolute;

	top: 0px;
	left: 0px;
	bottom: 0px;
}

.doc-zoomedFigmaModal-rightPanelWrapperContainer{
	//width set in core layout
	height: 100%;

	top: 0px;
	right: 0px;
	bottom: 0px;
	position: absolute;
}

.doc-zoomedFigmaModal-rightPanelWrapperContainer--ghost{
	pointer-events: none;
	visibility: hidden;
}


.doc-zoomedFigmaModal-headerContainer,
.doc-zoomedFigmaModal-footerContainer,
.doc-zoomedFigmaModal-canvasOuterContainer{
	position: absolute;
	left: 0px;
	right: 0px;
}

.doc-zoomedFigmaModal-headerContainer{
	top: 0px;	
}

.doc-zoomedFigmaModal-footerContainer{
	bottom: 0px;	
}

.doc-zoomedFigmaModal-canvasOuterContainer{

}


@import 'Doc-Figma-ZoomedFigmaModal-HeaderFooter';

@import 'Doc-Figma-ZoomedFigmaModal-Canvas';

@import 'Doc-Figma-ZoomedFigmaModal-ThreadPanel';
@import 'Doc-Figma-ZoomedFigmaModal-UIStatus';







// //
// //

// .doc-zoomedFigmaModal-imageCanvas-cropBoxContainer{
// 	position: absolute;
// 	outline: 2px solid red;

// 	z-index: 20;
// }


// //




// .doc-zoomedFigmaModal-imageCanvas{
// 	width: 100%;
// 	height: calc(100% - #{$headerHeight-zoomedFigmaModal} - #{$footerHeight-zoomedFigmaModal});
// 	// background: $tempr;

// 	position: relative;
// }

// .doc-zoomedFigmaModal-imageCanvas--hasBackground{
// 	// background: $tempr;	
// }

// .doc-zoomedFigmaModal-imageCanvas--noBackground{
// 	// background: $tempb;	
// }

// .doc-zoomedFigmaModal-imageCanvasMeasurer{
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	visibility: hidden;
// 	pointer-events: none;
// }

// .doc-zoomedFigmaModal-imageOuterContainer{
// 	position: absolute;	
// 	overflow: hidden;
// }

// .doc-zoomedFigmaModal-imageOuterContainer{
// 	position: absolute;	
// 	//top left width and height calculated
// 	background: red;
// 	z-index: 10;
	
// 	top: 50%;
// 	left: 50%;
// 	transform: translateX(-50%) translateY(-50%);	
// }



// .doc-zoomedFigmaModal-background{
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	transform: translateX(-50%) translateY(-50%);

// 	background: var(--primary500);

// 	background: 	 	
//   	radial-gradient(
// 			circle at 50% 50%,
//     	var(--primary400) 0%, var(--primary500) 50%, var(--primary700) 100%,
//   	);  
// }



// img.doc-zoomedFigmaModal-image{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: contain; 
// 	border-radius: 5px;
// }

// .doc-zoomedFigmaModal-deviceFrameContainer{
// 	position: absolute;
// 	left: 50%;
// 	top: 50%;
// 	transform: translateX(-50%) translateY(-50%);

// 	//width and height calculated
	
// 	z-index: 20;
// }


// img.doc-zoomedFigmaModal-deviceFrame{
// 	width: 100%;
// 	height: 100%;
// 	object-fit: contain; 

// }



// img.doc-zoomedFigmaModal-image:focus{
// 	outline: none;
// }


// .doc-zoomedFigmaModal-footer-commentInputContainer--show{
// 	opacity: 1;
// 	transition: opacity 200ms linear;
// }

// .doc-zoomedFigmaModal-footer-commentInputContainer--hide{
// 	opacity: 0;
// 	transition: opacity 100ms linear;
// 	pointer-events: none;
// }

