.roadmapsHomePage-quarter-timeline-timeline{

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.roadmapsHomePage-quarter-timeline-timeline-dateContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;

	bottom: 0px;
	right: 0px;

	display: flex;
	
	pointer-events: none; // temp
}

.roadmapsHomePage-quarter-timeline-timeline-projectsContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	bottom: 0px;
	right: 0px;

	box-sizing: border-box;

	padding-top: 64px;
	
}

.roadmapsHomePage-quarter-timeline-timeline-projectsContainer-vSpacer{
	height: 0px;
}

.roadmapsHomePage-quarter-timeline-timeline-date{
	height: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempr;
	//outline: 1px solid red;

	position: relative;
}

[data-theme="light"]{
	.roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--6,
	.roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--0{
		//background: rgba(70,70,80,0.01);
	}
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--6,
	.roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--0{
		background: rgba(255,255,255,0.01);
	}
}

.roadmapsHomePage-quarter-timeline-timeline-date:hover{
	background: rgba(0,0,0,0.01);
}

.roadmapsHomePage-quarter-timeline-timeline-date-line,
.roadmapsHomePage-quarter-timeline-timeline-date-todayLine{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 1px;
}

.roadmapsHomePage-quarter-timeline-timeline-date-todayLine{
	z-index: 1;
	opacity: 0.8; // sorry
	top: 20px;
	background: var(--primary500);	
}

.roadmapsHomePage-quarter-timeline-timeline-date--isNormalDay
.roadmapsHomePage-quarter-timeline-timeline-date-line{
	background: var(--line325);
	opacity: 0;
}

// .roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--6
// .roadmapsHomePage-quarter-timeline-timeline-date-line{
// 	background: var(--line325);
// 	opacity: 1;
// }

.roadmapsHomePage-quarter-timeline-timeline-date--isMonday
.roadmapsHomePage-quarter-timeline-timeline-date-line{
	background: var(--line325);
	opacity: 1;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-timeline-timeline-date--isNormalDay
	.roadmapsHomePage-quarter-timeline-timeline-date-line{
		opacity: 1;		
		background: rgba(255,255,255,0);
	}	
	.roadmapsHomePage-quarter-timeline-timeline-date--isMonday
	.roadmapsHomePage-quarter-timeline-timeline-date-line{
		opacity: 1;
		background: rgba(255,255,255,0.04);
	}
}



.roadmapsHomePage-quarter-timeline-timeline-date--isMonday.roadmapsHomePage-quarter-timeline-timeline-date:first-child
.roadmapsHomePage-quarter-timeline-timeline-date-line{
	opacity: 0; // don't show if first day
}



.roadmapsHomePage-quarter-timeline-timeline-date-todayLabel,
.roadmapsHomePage-quarter-timeline-timeline-date-mondayLabel{
	position: absolute;
	top: 12px;
	
	height: 18px;

	font-size: 13px;
	font-weight: $lato-regular;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	pointer-events: none;
}

.roadmapsHomePage-quarter-timeline-timeline-date-todayLabel{	
	left: 0%;
	transform: translateX(-50%);

	padding-left: 5px;
	padding-right: 5px;

	background: var(--primary500);

	font-weight: $lato-medium;
	color: $white;

	border-radius: 4px;

	z-index: 5;

}

.roadmapsHomePage-quarter-timeline-timeline-date-mondayLabel{
	left: 0px;	
	padding-left: 6px;
	
	color: var(--text600);
	opacity: 0.5;

	min-width: 100px;
}

@import 'Roadmaps-HomePage-Quarter-Timeline-DraggableProject';