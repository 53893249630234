.docEditor-projectFigmasGrid{
	margin-top: 0px;
	padding-bottom: 14px;	

	width: 720px;
	margin-left: $negativeEdgeMarginTextToDoc;

}


.docEditor-projectFigmasGrid-cellContainer{
	position: relative;
	border-radius: 2px;
	overflow: hidden;
	
	border: 1px solid rgba(0,0,0,0.1);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.02);
}

[data-theme="dark"]{
	.docEditor-projectFigmasGrid-cellContainer{
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
	}
}

.docEditor-projectFigmasGrid-cell{	
	position: absolute;
	z-index: 5;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	cursor: pointer;
	// background: var(--253gray);	

}

.docEditor-projectFigmasGrid-cell:hover{
	// background: var(--240gray);	
}



.docEditor-projectFigmasGrid-explanationLabel{
	width: 100%;
	box-sizing: border-box;
	padding-right: $edgeMarginTextToDoc;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	margin-top: 6px;
	padding-top: 6px;

	opacity: 0.65;
	transition: opacity 100ms linear;

	user-select: none;
	cursor: default;

	position: relative;
	z-index: 20;
}

.docEditor-projectFigmasGrid-explanationLabel:hover{
	opacity: 0.9;
	transition: opacity 100ms linear;
}



.docEditor-projectFigmasGrid-explanationLabel-iconContainer{
	width: 16px;
	height: 16px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.docEditor-projectFigmasGrid-explanationLabel-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
}

.docEditor-projectFigmasGrid-explanationLabel-label{
	font-size: 14.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.01em;
	color: var(--text500);
}


//
// 

.docEditor-projectFigmasGrid-cell-topBar{
	position: absolute;
	top: 4px;
	left: 0px;
	right: 0px;
	width: 100%;

	
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 4px;

	
	cursor: default;

}

.docEditor-projectFigmasGrid-cell-topBar-label{
	color: var(--text600);
	text-align: left;
	opacity: 0.85;

	//font-size: 16px;
	font-size: 15.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.002em;

	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  flex-grow: 1;
	flex-shrink: 1;
}


.docEditor-projectFigmasGrid-cell-topBar-rightContainer{
	margin-left: auto;
	display: flex;
}

.docEditor-projectFigmasGrid-cell-topBar-btn{
	width: 28px;
	height: 24px;
	border-radius: 4px;

	opacity: 0;
	transition: opacity 100ms linear;
	transition-delay: 0ms;
}


.docEditor-projectFigmasGrid-cell:hover 
.docEditor-projectFigmasGrid-cell-topBar-btn{
	opacity: 0.7;
	transition: opacity 100ms linear;
	transition-delay: 300ms;
}

.docEditor-projectFigmasGrid-cell-topBar-btn:first-child{
	// border-top-right-radius: 0px;
	// border-bottom-right-radius: 0px;
	// padding-left: 4px;
}

.docEditor-projectFigmasGrid-cell-topBar-btn:last-child{
	// border-top-left-radius: 0px;
	// border-bottom-left-radius: 0px;
	// padding-right: 4px;
}

[data-theme="dark"]{ button.docEditor-projectFigmasGrid-cell-topBar-btn{
		background: rgba(28,23,28,0.85);	
	}
}

[data-theme="dark"]{ button.docEditor-projectFigmasGrid-cell-topBar-btn:hover{
		background: rgba(28,23,28,0.90);	
	}
}


// .docEditor-projectFigmasGrid-cell-topBar-btn--source{
// 	width: 28px;
// }

// .docEditor-projectFigmasGrid-cell-topBar-btn--dropdown{
// 	width: 30px;
// }

.docEditor-projectFigmasGrid-cell-topBar-btn:hover{
	background: var(--250gray);
	opacity: 1;
}

.docEditor-projectFigmasGrid-cell-topBar-btn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.9;
}