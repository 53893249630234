.onboardingModal-progressBar{
	// opacity: 0;	

	position: absolute;
	top: 24px;
	left: 0px;
	width: 100%;
	// left: 280px;	
	// width: calc(100% - 220px - 220px);

	display: flex;

	justify-content: flex-start;
	justify-content: center;

	pointer-events: none;
}

.onboardingModal-progressBar--centerAlign{
	justify-content: center;
}

.onboardingModal-progressBar-bar{
	display: flex;
	column-gap: 8px;
}

.onboardingModal-progressBar-bar-segment{
	width: 130px;
	width: 80px;
	height: 4px;

	border-radius: 1px;

	background: var(--primary500);
	background: var(--text600);
}

.onboardingModal-progressBar-bar-segment[data-state="active"]{
	opacity: 0.5;
	opacity: 0.25;
}

.onboardingModal-progressBar-bar-segment[data-state="inactive"]{
	opacity: 0.125;
	opacity: 0.075;
}

.onboardingModal-progressBar--workspaceReady
.onboardingModal-progressBar-bar-segment{
	background: var(--green-workspaceReady); // green
}