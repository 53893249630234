
//
// DOC FIGMA
// I think very similar to image, may want to consolidate

.doc-figmaEmbed{

	margin-top: 16px;
	margin-bottom: 16px;

	margin-left: calc((#{$textWidth-doc} - #{$fullWidth-doc}) / 2);  // not sure this is right

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.doc-figmaEmbed--placeholder-imageContainer{
	//background: var(--primary50);
	background: var(--250gray);
	width: 100%;
	//aspect-ratio: 16/5; // don't want it to get shorter
	//aspect-ratio: 16/3; // don't want it to get shorter
	aspect-ratio: 720/300; // don't want it to get shorter
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;
}

.doc-figmaEmbed--placeholder-imageContainer-figmaLogoContainer{
	height: 64px;
	width: 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: -32px;
	margin-bottom: 14px;
}

.doc-figmaEmbed--placeholder-imageContainer-figmaLogo{

}

.doc-figmaEmbed--placeholder-imageContainer-figmaLogo svg{
	width: 48px;
	height: 48px;
	fill: var(--primary400);
	fill: var(--125gray);
}


.doc-figmaEmbed--placeholder-imageContainer-loadingBar{
	width: 300px;
	height: 6px;

	border-radius: 10px;

	position: relative;

	background: $tempr;
	background: rgba(0,0,0,0.08);
	background: var(--bgSideMenuTabActive);
	background: var(--primary100);

	background: var(--246gray);
	// background: var(--250gray);
	
	//background: hsla(213, 100%, 50%,0.15);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.doc-figmaEmbed--placeholder-imageContainer-loadingBar-ball{
	width: 120px;
	height: 6px;
	border-radius: 4px;

	background: $tempr;
	background: var(--125gray);	
	//background: var(--primary400);
	//background: var(--primary500);
}

















// .doc-figmaContainer{	
// 	user-select: none;
// 	margin-left: calc((#{$textWidth-doc} - #{$fullWidth-doc}) / 2);  // not sure this is right
	
// 	margin-top: 16px;
// 	margin-bottom: 16px;

// 	position: relative;
// 	z-index: 5;

// 	width: 720px;

// 	background: $tempr;
// 	// outline: 2px solid red;
// }

// .doc-figma{
// 	background: $tempb;
// 	position: absolute;

// 	z-index: 10;	
// }

// .figmaViewOG{
// 	z-index: 10;
// }


















// .doc-figmaEmbed--selectionBG{
// 	position: absolute;
// 	z-index: 0;
// 	top: -3px;
// 	transform: translateX(-50%);
// 	left: 50%;
// 	bottom: -3px;

// 	border-radius: 7px;

// 	pointer-events: none;
// 	background: var(--mediaNodeSelectedBG);

// 	opacity: 0;
// }

// .doc-imageWrapper.ProseMirror-selectednode .doc-figmaEmbed--selectionBG{
// 	opacity: 0.5;
// }

// .doc-figmaEmbed--cropped--selectionBG{
// 	top: 38px - 3px; // topbar height
// 	bottom: 6px; // not sure why this is required
// }





// //
// // PLACEHOLDER
// .doc-figmaContainer--placeholder
// .doc-figma-imageContainer{
// 	//background: var(--primary50);
// 	background: var(--250gray);
// 	width: 100%;
// 	aspect-ratio: 16/5; // don't want it to get shorter
// 	//aspect-ratio: 16/3; // don't want it to get shorter
// 	border-radius: 8px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	flex-direction: column;
// }

// .doc-figma-imageContainer-figmaLogoContainer{
// 	height: 64px;
// 	width: 64px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-top: -32px;
// 	margin-bottom: 14px;
// }

// .doc-figma-imageContainer-figmaLogo{

// }

// .doc-figma-imageContainer-figmaLogo svg{
// 	width: 48px;
// 	height: 48px;
// 	fill: var(--primary400);
// 	fill: var(--125gray);
// }


// .doc-figma-imageContainer-loadingBar{
// 	width: 300px;
// 	height: 6px;

// 	border-radius: 10px;

// 	position: relative;

// 	background: $tempr;
// 	background: rgba(0,0,0,0.08);
// 	background: var(--bgSideMenuTabActive);
// 	background: var(--primary100);

// 	background: var(--246gray);
// 	// background: var(--250gray);
	
// 	//background: hsla(213, 100%, 50%,0.15);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	overflow: hidden;
// }

// .doc-figma-imageContainer-loadingBar-ball{
// 	width: 120px;
// 	height: 6px;
// 	border-radius: 4px;

// 	background: $tempr;
// 	background: var(--125gray);	
// 	//background: var(--primary400);
// 	//background: var(--primary500);
// }



// //

// .testParametersPanelContainer{
// 	position: absolute;
// 	left: 980px;
// 	width: 340px;
// 	top: 0px;
// 	height: 1000px;
// 	// background: $tempr;
// }

// .testCalcuationsPanelContainer{
// 	position: absolute;
// 	width: 400px;
// 	left: -460px;
// 	top: 0px;
// 	height: 1000px;
// 	// background: $tempb;
// }

@import 'Doc-Figma-Topbar';

// .doc-figma-imageContainer{
// 	width: 100%;
// 	position: relative;
// 	cursor: pointer;
// }

// @import 'Doc-Figma-Image';

@import 'Doc-Figma-EffectsToolbar';



// .doc-figma--cropped{
// 	width: 100%;
// 	height: 100%;
// 	position: relative;

// 	overflow: hidden;
// }

// .doc-figma-imageContainer--cropped{
// 	position: absolute;
// 	left: 0px;
// 	top: 0px;	
// }


// .recalcLayout{
// 	position: absolute;
// 	bottom: 0px;
// 	left: 1100px;
// 	z-index: 50;


	
// 	width: 200px;
	
// 	background: green;
// 	color: $white;
// 	height: 80px;
// }










// //
// //

// .doc-figma-commentsPreview{
// 	width: 100%;
// 	height: 96px;
// 	background: $tempg;
// }


// //
// // ZOOMED FIGMA MODAL


@import 'Doc-Figma-ZoomedFigmaModal';