$bg-login: rgb(21,20,23);
.login{
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
	//background: var(--background-onboarding);
	// background: none;
	background: var(--background-onboarding);
	
	//background: radial-gradient(60% 60% at 50% 50%,  lighten($bg-login, 5%), darken($bg-login, 5%));

	//background: radial-gradient(100% 100% at 50% 100%, lighten($bg-login, 0%), darken($bg-login, 4%));
	//background: radial-gradient(100% 100% at 50% 100%, red, blue);


}

.login-box{
	background: rgb(32,30,36);
	border: 1px solid rgb(255,255,255,0.08);
	border-top: 1px solid rgb(255,255,255,0.14);
	border-bottom: 1px solid rgb(255,255,255,0.06);

	background: radial-gradient(
		500px 500px at 20% 50%,
		darken(rgb(32,30,39),0%) 0%,
		darken(rgb(32,30,39),1%) 70%,
		darken(rgb(32,30,39),3%) 100%,
	);

// 	box-shadow: 0px 12px 16px 0px rgba(0,0,0,0.24);
// /* https://smoothshadows.com/#djEsMSw1LDAuMTgsMjQsMjMsMCwjMDMwNzEyLCNmM2Y0ZjYsI2ZmZmZmZiwy */
box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.04),
  0px 4px 4px rgba(3, 7, 18, 0.07),
  0px 8px 9px rgba(3, 7, 18, 0.11),
  0px 15px 15px rgba(3, 7, 18, 0.14),
  0px 23px 24px rgba(3, 7, 18, 0.18);


	//backdrop-filter: blur(20px);

	//height: 470px;
	width: 368px;
	//height: 428px;

	margin-top: -40px;

	// width: 404px;
	// height: 484px;

	border-radius: 5px;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;
	z-index: 500;


}

.login-box-logo{
	margin-top: 72px;

	width: 32px;
	
	display: flex;
	flex-direction: column;
}

.login-box-logo-bar{
	width: 100%;

	height: 5px;
	margin-bottom: 3px;

	background: var(--text500);
	border-radius: 1px;
}

.login-box-logo-bar:last-child{
	width: 50%;
}

//

.login-box-brand{
	margin-top: 24px;
}

.login-box-brand svg{
	fill: white;
}

//

.login-box-versionNumber{
	margin-top: 18px;

	font-family: 'Menlo', 'Consolas' !important;
	color: var(--text400);
	font-size: 14px;
	opacity: 0.65;
}

.login-box-loginBtn,
.login-box-openSlackBtn{

	width: 260px;
	height: 42px;

	border-radius: 4px;

	color: var(--text600);
	font-size: 15.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;

	box-sizing: border-box;
	padding-bottom: 1px;
}



.login-box-openSlackBtn{
	background: none;
	opacity: 0.35;
	font-weight: $lato-medium;
	font-size: 15.25px;
	letter-spacing: 0.012em;

	margin-top: 36px; 
	padding-bottom: 64px;
}

.login-box-openSlackBtn:hover{
	//background: rgba(255,255,255,0.03);
	opacity: 0.8;
}





// .docEditor-messagePanel-header-alphaTag{
// 	text-transform: uppercase;
	
// 	position: relative;

// 	font-size: 11.25px;
// 	padding-left: 8px;
// 	padding-right: 8px;
// 	margin-right: 4px;

// 	top: 0.5px;

// 	height: 22px;
	
// 	margin-left: 6px;

// 	font-weight: $lato-medium;
// 	letter-spacing: 0.02em;

// 	overflow: hidden;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	color: var(--aiPurple500);
// 	color: var(--text300);
// 	// background: var(--aiPurple500);
// }

.login-box-loginBtn{	
	margin-top: 150px;

	//border: 1px solid lighten(#6365BA, 2%);

	//background: linear-gradient(#5B5EF3, darken(#5458F1, 2%));
	
	position: relative;
	overflow: hidden;
	//box-shadow: 0px 0px 14px 1px transparentize(saturate(#5458F1, 10%), 0.9);

	

	background: none;
	// color: #5458F1;
	background: rgba(255,255,255,0.01);
	background: linear-gradient(darken(#5458F1, 2%), #5B5EF3);
	border: 1px solid lighten(#5458F1, 2%);
	// color: color(display-p3 0.19 0.39 0.98 / 1);
	//border: 1px solid #5458F1;
	color: white;

}

.login-box-loginBtn:hover{
	background: linear-gradient(darken(#5458F1, 3%), darken(#5B5EF3, 1%));
	border: 1px solid lighten(#5458F1, 4%);
}

.login-box-loginBtn-iconContainer{
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -4px;
	margin-right: 5px;



	// background: $tempr;
}

.login-box-loginBtn-iconContainer svg{
	fill: white;
	width: 18px;
	height: 18px;
	opacity: 0.9;
	position: relative;


	fill: white;

	transform-origin: 50% 50%;
	transform: scale(0.8);
}



//
//

.login-background{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;

  opacity: 1;

  pointer-events: none;
  
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Crect width='100%25' height='100%25' fill='rgba(255,255,255,0.02)' stroke='rgb(15,13,17)'/%3E%3C/svg%3E"); 
  background-size: 40px 40px;
  background-position: calc(50% - 20px) calc(50% - 20px); /* Adjusted */

}

.login-background-box{
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  //border: 1px solid $bg-login;
  opacity: 0;
  animation: fade 24s ease-in-out infinite;
}


@keyframes fade {
  0% { opacity: 0.0; }
  10% { opacity: 0.04; }
  20% { opacity: 0; }
  100% { opacity: 0; }
}

//
//


.login-particleContainer{
	position: absolute;
	width: 100%;
	height: 100%;
}

.login-particleContainer-particles{
	position: absolute;
	width: 100%;
	height: 100%;

	z-index: 50;
}

.login-particleContainer-overlayBG{
	position: fixed;
	width: 200vw;
	left: -50vw;
	height: 200vh;
	top: -50vh;

	transform: rotateZ(-24deg);
	z-index: 200;

	background: radial-gradient(
		750px 400px at calc(50% - 100px) calc(50% - 50px),
		rgba(28,26,32,0) 0%,
		rgba(28,26,32,1) 100%,
	);

	opacity: 1;
}


.login-particleContainer-svgContainer{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) rotateZ(-19deg);

	z-index: 100;

	opacity: 0.5;
}

// .login{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	right: 0px;

// 	z-index: 5000;

// 	// display: flex;
// 	// align-items: center;
// 	// justify-content: center;

// 	background: radial-gradient(
// 		at 25% 25%,
// 		rgba(143,63,241,0.15) 0%,
// 		rgba(51,153,198,0.15) 100%
// 	);
// 	// background: black;
// }

// .login-panelContainer{

// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	margin: auto;

// 	z-index: 50;

// 	width: 720px;
// 	height: 500px;
// }


// @keyframes wave-animation {
//     0% { transform: rotate( 0.0deg) }
//    10% { transform: rotate(12.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
//    20% { transform: rotate(-5.0deg) }
//    30% { transform: rotate(8.0deg) }
//    40% { transform: rotate(-0.5deg) }
//    50% { transform: rotate(2.0deg) }
//    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
//   100% { transform: rotate( 0.0deg) }
// }



// .login-panel-waveContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
	
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	cursor: pointer;

// 	opacity: 1;
// 	transition: opacity 50ms linear;
// }

// .login-panel-waveContainer--disable{
// 	pointer-events: none;
// 	cursor: pointer;
// }

// .login-panel-wave{
// 	font-size: 56px;
// 	position: absolute;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	width: 110px;
// 	height: 110px;

// 	top: 64px;
// 	left: 64px;

// 	animation-delay: 1s;
// 	animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
//   animation-duration: 2.5s;        /* Change to speed up or slow down */
//   animation-iteration-count: 1;  /* Never stop waving :) */
//   transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
//   display: inline-block;

//   transition: opacity 100ms linear;
// 	// transform: scale(2);

// 	opacity: 1;
// }

// .login-panel-waveContainer:hover
// .login-panel-wave{
// 	opacity: 0;
// }

// .login-panel-waveContainer--disable{
// 	opacity: 0;
// }

// .login-panel-door{
// 	font-size: 56px;
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	box-sizing: border-box;
// 	padding-bottom: 8px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	opacity: 0;
// 	transition: opacity 100ms linear;
// }

// .login-panel-waveContainer:hover
// .login-panel-door{
// 	opacity: 1;
// }
	


// .login-panel{
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translateX(-50%) translateY(-50%);

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	flex-direction: column;

// 	position: relative;
// 	z-index: 10;

// 	background: $white;
// 	border-radius: 10px;
// 	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.12);

// 	border: 1px solid rgba(40,40,50,0.1);
// 	border-top: 1px solid rgba(40,40,50,0.05);
// 	border-bottom: 1px solid rgba(40,40,50,0.15);

// 	background: rgba(253,253,255);

// 	box-sizing: border-box;

// 	//background: radial-gradient(rgb(255,255,255) 0%, rgb(254,254,255) 30%, rgb(248,248,254) 100%);
// 	background: rgb(249,249,252);

// }

// @keyframes panelShine {
//   from {
//       background-position: 0%;
//       opacity: 1;
//   }
//   to {
//       background-position: 200%;
//       opacity: 0;
//   }
// }


// .login-panel-tempButton{
// 	position: fixed;
// 	bottom: 0px;
// 	right: 0px;
// 	width: 200px;
// 	height: 200px;
// 	// background: $tempr;
// }


// // $activePanelShine: 		transparentize(#AD2CFF, 0.5);
// // $inactivePanelShine:	transparentize(#AD2CFF, 1);

// $activePanelShine: 		rgba(255,255,255);
// $inactivePanelShine:	darken(rgb(239,235,245), 10%);

 
// $loginPanelBorderWidth: 6px;






// @keyframes borderPanelGradientAnimation {
// 	0% {
// 		background-position: 0% 50%;
// 	}
// 	50% {
// 		background-position: 100% 50%;
// 	}
// 	100% {
// 		background-position: 0% 50%;
// 	}
// }



// .login-panelBG{	
// 	z-index: 50;
// 	z-index: 0;
// 	position: absolute;

// 	border-radius: 12px;
// 	top: calc(#{$loginPanelBorderWidth} * -1);
// 	left: calc(#{$loginPanelBorderWidth} * -1);
// 	width: calc(100% + (#{$loginPanelBorderWidth} * 2));
// 	height: calc(100% + (#{$loginPanelBorderWidth} * 2));

// 	// background: red;

// 	// background: linear-gradient(to left,
// 	// 	$inactivePanelShine 20%,
// 	// 	$inactivePanelShine 10%,
// 	// 	$activePanelShine 50%,
// 	// 	$activePanelShine 55%,
// 	// 	$inactivePanelShine 80%,
// 	// 	$inactivePanelShine 100%
// 	// );

// 	background: linear-gradient(60deg, 
// 		#f79533,
// 		#f37055,
// 		#ef4e7b,
// 		#a166ab,
// 		#5073b8,
// 		#1098ad,
// 		#07b39b,
// 		#6fba82
// 	);

// //  0%,
// // 		darken(rgb(199,206,209), 1%) 100%

// // background: linear-gradient(
// // 		60deg, 
// // 		#8F3FF1,
// // 		// #f37055,
// // 		// #ef4e7b,
// // 		// #a166ab,
// // 		// #5073b8,
// // 		// #1098ad,
// // 		// #07b39b,
// // 		#3399C6
// // 	);
// 	background-size: 300% 300%;

// 	opacity: 0.08;
// 	//background-size: 100% 100%;

// 	animation: borderPanelGradientAnimation 5s ease alternate infinite;	

// 	// background: linear-gradient(to right,
// 	// 	$inactivePanelShine 0%,
// 	// 	$activePanelShine 100%,	
// 	// );

// 	// background-size: 200% auto;
	
// 	// transform: scale(-1);

// 	// /background-position: 20% 0%;

// 	// animation-name: panelShine;
// 	// animation-duration: 5000ms;
// 	// // animation-duration: 5000ms;
//  //  animation-timing-function: ease;
//  //  animation-delay: 5s;
//  //  animation-iteration-count: 100;
//  //  animation-direction: alternate-reverse;
//  //  animation-fill-mode: forwards;
//  //  animation-play-state: running;  

// }


		


// // .login-panelOutlineContainer{

// // 	position: absolute;
// // 	top: 0px;
// // 	left: 0px;
// // 	right: 0px;
// // 	bottom: 0px;

// // 	width: 786px;
// // 	height: 514px;

// // 	// background: $tempr;

// // 	margin: auto;

// // 	z-index: 5;

// // 	transform: scale(0.82);

// // // }

// // // .login-panelOutlineContainer svg{
// // // 	width: 100%;
// // // 	height: 100%;

// // // 	fill: #B578DD;
// // // 	opacity: 0.25;

// // // 	fill: black;
// // // 	opacity: 0.04;

// // // }

// // .app-login-panel a{
// // 	transform: scale(1.5);
// // 	transform: scale(1);
// // }

// // .app-login-panel button{
// // 	opacity: 0.5;
// // 	font-size: 14px;

// // 	margin-top: 24px;
// // }



// // .login-panel-logoContainer svg{
// // 	width: 56px;
// // 	height: 56px;

// // 	fill: rgba(60,60,70,0.4);
// // }

// .login-panel-logoContainer{
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-bottom: 24px;
// }

// .login-panel-logo{
// 	width: 46px;	
// 	margin-left: 8px;
// }



// .login-panel-logo-lineContainer{
// 	overflow: hidden;
// 	height: 8px;
// 	margin-top: 4px;

// 	border-radius: 3px;

// 	position: relative;


// }

// .login-panel-logo-lineContainer--1{
// 	width: 100%;
// }
// .login-panel-logo-lineContainer--2{
// 	width: 100%;
// }
// .login-panel-logo-lineContainer--3{
// 	width: 70%;
// }

// .login-panel-logo-line{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	background: rgb(220,220,230);
// 	opacity: 1;
// 	border-radius: 3px;
// 	// background: red;
	
// }


// //

// .login-panel-title{
// 	margin-top: 14px;

// 	font-size: 40px;
// 	font-weight: $lato-heavy;
// 	color: var(--text500);

// 	margin-bottom: 8px;

// 	cursor: default;
// 	user-select: none;

// 	display: flex;
// }

// //

// .login-panel-version{
// 	font-size: 14.5px;
// 	font-weight: $lato-medium;
// 	color: rgba(0,0,0,0.24);

// 	margin-bottom: 20px;
// 	cursor: default;
// }


// //

// .login-panel-signInBtn{
// 	width: 270px;

// 	font-size: 17px;
// 	font-weight: $lato-bold;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	height: 42px;

// 	border-radius: 5px;
// 	border: 1px solid rgba(40,40,50,0.15);

// 	cursor: pointer;

// 	background: rgba(0,0,0,0.02);
// 	background: $white;	

// 	position: relative;
// }

// .login-panel-signInBtn-innerContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	// background: $tempr;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .login-panel-signInBtn:hover{
// 	border: 1px solid rgba(40,40,50,0.2);	
// }



// //

// .login-panel-signInBtn-iconContainer{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;	

// 	width: 42px;
// 	height: 42px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	// margin-right: 6px;
// 	// margin-left: -8px;
// }

// .login-panel-signInBtn-iconContainer svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: rgba(70,70,80,1);
// 	margin-top: -1px;
// }

// .login-panel-signInBtn:hover
// .login-panel-signInBtn-iconContainer svg{
// 	opacity: 1;
// }

// .login-panel-signInBtn-label{
// 	color: rgba(70,70,80,1);
// 	font-weight: $lato-bold;
// }

// .login-panel-signInBtn-label emph{
// 	font-weight: $lato-bold;
// }

// .login-panel-signInBtn:hover
// .login-panel-signInBtn-label{
// 	opacity: 1;
// }

// //

// .login-panel-slackInfo-header{
// 	font-size: 14.5px;
// 	font-weight: $lato-medium;
// 	margin-top: auto;

// 	color: rgba(70,70,80,0.5);

// 	cursor: pointer;

// 	margin-bottom: 16px;
// }

// .login-panel-slackInfo-header:hover{
// 	color: #167BF0;
// }


	
// //

// .login-panel-vSpacer{
// 	flex-grow: 1;
// 	flex-shrink: 1;

// 	min-height: 10px;
// }

// // .login-panel-vSpacer--1,
// // .login-panel-vSpacer--2{
// // 	flex-grow: 4;
// // 	flex-shrink: 4;
// // }