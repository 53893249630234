//
// List Item

.doc-toggleListItem{
	width: 100%;
	display: flex;

	// background: $tempr;

	// padding-top: 1px;
	// padding-bottom: 1px;

	position: relative;
	margin-top: 2px;
}

.doc-toggleListItem-contents{
	width: 100%;
}

.doc-toggleListItem-contents-title{
	//background: rgba(0,0,255,0.12);
	min-height: 30px;
	box-sizing: border-box;
	padding-top: 0.24em;
	width: 100%;
	margin-bottom: 2px;

}



.doc-toggleListItem-contents-title--empty .doc-para::before{	
	position: absolute;
	height: 0px;
	width: 300px;
	pointer-events: none;
	-webkit-text-fill-color: var(--toDoItem-placeholderColor); // to do, refactor
}

.doc-toggleListItem[data-state="closed"]
.doc-toggleListItem-contents-title--empty .doc-para::before{
	content: "Title";
}

.doc-toggleListItem[data-state="closed"]
.doc-toggleListItem-contents-title--empty.doc-toggleListItem-contents-title--focused .doc-para::before{
	content: "Command + Enter to open toggle";
}

.doc-toggleListItem[data-state="open"]
.doc-toggleListItem-contents-title--empty .doc-para::before{
	content: "Title";
}

.doc-toggleListItem[data-state="open"]
.doc-toggleListItem-contents-title--empty.doc-toggleListItem-contents-title--focused .doc-para::before{
	content: "Command + Enter to close toggle";
}


.doc-toggleListItem-contents-details{
	//background: rgba(255,0,0,0.12);
	min-height: 24px;
	width: 100%;
	padding-bottom: 6px;

}

.doc-toggleListItem-contents-details .doc-para{
	color: var(--text400);
}


.doc-toggleListItem-contents-details--empty .doc-para:first-child::before{	
	position: absolute;
	height: 0px;
	width: 200px;
	pointer-events: none;
	-webkit-text-fill-color: var(--toDoItem-placeholderColor); // to do, refactor
	content: "Content";
}


.doc-toggleListItem[data-state="closed"]{
	.doc-toggleListItem-contents-details{
		display: none;
	}	
}



.doc-contentBox
.doc-toggleListItem{
	width: unset; // fixes wrapping bug
}

.doc-para + .doc-toggleListItem{
	padding-top: 2px; // i think this is right
}

.doc-toggleListItem + .doc-para{
	padding-top: 2px; // i think this is right
}

.doc-toggleListItem-markerContainer{
	
	width: 29px;
	margin-left: -6px;
	margin-right: 6px;
	height: 30px;
	// background: $tempr;
	
	display: flex;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;	

	flex-grow: 0;
	flex-shrink: 0;	

	
	user-select: none;	
}

.doc-toggleListItem-marker{
	width: 24px;
	height: 24px;

	border-radius: 3px;

	cursor: pointer;

	// background: $tempr;
	
}

.doc-toggleListItem-marker:hover{
	background: var(--250gray);
	transition: background 50ms linear;
	transition-delay: 50ms;
}

.doc-toggleListItem-marker svg{
	width: 12px;
	height: 12px;
	fill: var(--text500);
	opacity: 0.8;
	transform: rotate(0deg);	
	transition: transform 100ms ease-in-out;
	transform-origin: 44% 50%;
}

.doc-toggleListItem-marker:hover svg{
	opacity: 1;
}

.doc-toggleListItem[data-state="open"]
.doc-toggleListItem-marker svg{
	transform: rotate(90deg);
}

.doc-toggleListItem-marker-lineContainer{
	position: absolute;
	// background: $tempr;
	top: 29px;
	left: -3px;
	width: 24px;

	box-sizing: border-box;
	padding-bottom: 6px;
	padding-top: 3px;
	bottom: 2px;
	padding-right: 1px;

	display: flex;
	justify-content: center;

	opacity: 0;
	transition: opacity 0ms linear ease-in-out;
	pointer-events: none;
}

.doc-toggleListItem-marker-line{
	height: 100%;
	width: 1px;
	background: var(--line400);

	transition: opacity 50ms linear;
	opacity: 0.5;
}

.doc-toggleListItem-marker-lineContainer:hover
.doc-toggleListItem-marker-line{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 50ms;
}

[data-theme="dark"] {
	.doc-toggleListItem-marker-line{
		background: var(--line200);
	}
}

.doc-toggleListItem[data-state="open"]
.doc-toggleListItem-marker-lineContainer{
	opacity: 1;
	transition: opacity 150ms linear ease-in-out;	
	pointer-events: auto;
}






// //
// // BULLET POINTS
// .doc-toggleListItem{
// 	//padding-top: 1px;
// 	//padding-bottom: 1px;
// }






//
// INDENTATION
.doc-toggleListItem .doc-para{
	padding-left: 0 !important;
}

.doc-toggleListItem[data-indent-level="0"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="1"]{
	padding-left: calc(1 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="2"]{
	padding-left: calc(2 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="3"]{
	padding-left: calc(3 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="4"]{
	padding-left: calc(4 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="5"]{
	padding-left: calc(5 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="6"]{
	padding-left: calc(6 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="7"]{
	padding-left: calc(7 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="8"]{
	padding-left: calc(8 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="9"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-toggleListItem[data-indent-level="10"]{
	padding-left: calc(10 * #{$doc-paragraph-indent});
}



// //
// // STRANGE NEW
// .doc-toggleListItem{
// 	padding-top: 1px;
// 	padding-bottom: 1px;
// }

// .doc-toggleListItem-markerContainer{
// 	width: 18px;
// 	padding-left: 1px;
// }

// .doc-toggleListItem-marker{
// 	width: 7px;
// 	height: 3px;
// 	border-radius: 1px;
// 	opacity: 0.25;
// }

