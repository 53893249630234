
//
// TAB BAR

// switch tabbar--systemTheme--dark to top-leftl [data-systemTheme="dark"] and [data-systemTheme="light"]
// switch to adding yarn light/dark theme data tag in tab bar windows

//
// YARN LIGHT - MAC LIGHT
// translucent
@media (prefers-color-scheme: light){
:root[data-theme="light"] .tabBar{	
	//--borderColorTabBar: rgba(245,245,248);
	--borderColorTabBar: rgba(240,240,242);

	--bgTabBar: $invisible;	

	// Tabs
	--bgActiveTab-tabBar: rgba(255,255,255,0.7);
	--hoverBgActiveTab-tabBar: rgba(255,255,255,0.8);
	--bgBackgroundTab-tabBar: $invisible;
	--hoverBgBackgroundTab-tabBar: rgba(255,255,255,0.5);

	// Divider
	--bgDivider-tabBar: rgb(200,200,200);
}
}

//
// MAC DARK - YARN LIGHT 
// opaque
@media (prefers-color-scheme: dark){
:root[data-theme="light"] .tabBar{
	
	//--borderColorTabBar: rgba(245,245,248);
	--borderColorTabBar: rgba(240,240,242);

	//--bgTabBar: rgb(243,240,247);	
	--bgTabBar: rgb(240,238,246);	

	// Tabs
	--bgActiveTab-tabBar: rgba(255,255,255,1);
	--hoverBgActiveTab-tabBar: rgba(255,255,255,1);
	--bgBackgroundTab-tabBar: $invisible;
	--hoverBgBackgroundTab-tabBar: rgba(255,255,255,0.7);

	// Divider
	--bgDivider-tabBar: rgb(200,200,200);

}
}



//
// YARN DARK - SYSTEM DARK
// opaque

@media (prefers-color-scheme: dark){
:root[data-theme="dark"] .tabBar {
	
	//--borderColorTabBar: rgba(245,245,248);
	--borderColorTabBar: rgba(54,50,58);

	// --bgTabBar: $invisible;
	//--bgTabBar: rgb(32,30,36);

	--bgTabBar: rgba(23,22,24,0.2);

	// tabs
	//--bgActiveTab-tabBar: rgba(55,54,60,0.9);
	--bgActiveTab-tabBar: rgba(255,255,255,0.04);
	--hoverBgActiveTab-tabBar: rgba(255,255,255,0.045);
	--bgBackgroundTab-tabBar: $invisible;
	--hoverBgBackgroundTab-tabBar: rgba(255,255,255,0.035);

	// Divider
	--bgDivider-tabBar: rgb(100,100,100);


}}


//
// YARN DARK - SYSTEM LIGHT
// opaque
@media (prefers-color-scheme: light){
:root[data-theme="dark"] .tabBar{
	
	//--borderColorTabBar: rgba(245,245,248);
	--borderColorTabBar: rgba(54,50,58);

	// --bgTabBar: $invisible;
	--bgTabBar: rgb(32,30,36);
	//--bgTabBar: rgba(23,22,24,0.15);

	// tabs
	
	--bgActiveTab-tabBar: rgba(255,255,255,0.04);
	--hoverBgActiveTab-tabBar: rgba(55,54,60,0.95);
	--bgBackgroundTab-tabBar: $invisible;
	--hoverBgBackgroundTab-tabBar: rgba(55,54,60,0.6);

	// Divider
	--bgDivider-tabBar: rgb(100,100,100);
	}
}






