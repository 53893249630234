
// HTML OUTPUT PANEL TEST
.docEditor-htmlOutputPanel{
	position: fixed;
	top: 40px;
	right: 10px;
	bottom: 10px;
	//bottom: 56px;

	width: 1500px;

	z-index: 9000;
	// background: $tempr;	
}

//
// OVERLAY MODE

// .docEditor-htmlOutputPanel{
// 	left: unset;
// 	right: 146px;	
// }

// .docEditor-htmlOutputPanel *{
// 	color: red !important; 
// }

// .docEditor-htmlOutputPanel-codeContainer{
// 	display: none; // overlay mode
// }

//

.docEditor-htmlOutputPanel-codeContainer{
	position: absolute;
	top: 0px;
	right: 0px;

	width: 700px;
	height: 100%;

	overflow-y: scroll;

	background: rgb(0,0,0); // refactor to color variable
	color: $white;

	box-sizing: border-box;
	padding-right: 12px;
	padding-top: 20px;
	padding-left: 12px;

}

.docEditor-htmlOutputPanel-docContainer{
	position: absolute;
	left: 0px;
	width: 720px;
	top: 0px;
	height: 100%;
	overflow-y: scroll;

	display: flex;
	justify-content: center;
}

.docEditor-htmlOutputPanel-doc{
	margin-top: 197px;
	width: 650px;
	// background: $tempr;
}



// PROSEMIRROR OUTPUT PANEL TEST
.docEditor-outputPanelOuterContainer{
	position: fixed;
	top: 40px;
	right: 10px;
	bottom: 10px;
	bottom: 56px;

	width: 34vw;
	min-width: 500px; 
	max-width: 1000px;

	z-index: 9000;
	background: $tempr;

	display: flex;
	justify-content: flex-end;
}

.docEditor-outputPanelInnerContainer{
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	padding-right: $interiorRightMarginToMenus-docEditor;
	padding-left: $interiorLeftMarginToMenus-docEditor;

	overflow-y: scroll;
	overflow-x: scroll;

	background: rgb(0,0,0); // refactor to color variable

	border-radius: 5px;

	//max-width: 900px;
}

.docEditor-outputPanel{
	width: 100%;

	padding-top: 20px;
	padding-left: 12px;
	
	color: $white;
	
	font-family: monospace;
}

.docEditor-outputPanel-title{
	font-size: 18px;
	font-weight: $lato-bold;
}