
//
// SCROLLBAR
//

// DEFAULT

.recordingPlaybackModal-transcript-contentsInnerContainer{
  // overflow-y: overlay;
  // overflow: visible;
  overflow-x: hidden;

}

.recordingPlaybackModal-transcript-contentsInnerContainer::-webkit-scrollbar {
  width: 9px;
  cursor: pointer;
  //pointer-events: none;
}

// .recordingPlaybackModal-transcript-contentsInnerContainer{
//   scrollbar-width: thin;  
//   scrollbar-color: rgba(255,255,255,0.12) $invisible;
// }

// track
.recordingPlaybackModal-transcript-contentsInnerContainer::-webkit-scrollbar-track {  
  background: $invisible;
  //background: $tempr;
}
 
//thumb
.recordingPlaybackModal-transcript-contentsInnerContainer::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.04);
  
  // background: transparentize($gray-4, 0.9);
  border-right: 3px solid $invisible;
  border-radius: 4px;
  border-radius: 3px;
  // background: black;
  // background: $tempb;

  // background: $invisible;
}

//
// HOVER ON LIST
.recordingPlaybackModal-transcript-contentsInnerContainer:hover::-webkit-scrollbar {
  width: 9px;
  // width: 10px;
}
.recordingPlaybackModal-transcript-contentsInnerContainer:hover{
  scrollbar-color: rgba(255,255,255,0.16) rgba(255,255,255,0.08);
}


// thumb
.recordingPlaybackModal-transcript-contentsInnerContainer:hover::-webkit-scrollbar-thumb{
  background: $tempg;
  background: rgba(255,255,255,0.14);
}

// track
.recordingPlaybackModal-transcript-contentsInnerContainer:hover::-webkit-scrollbar-track {  
  background: rgba(255,255,2255,0.0);
  // background: $tempr;
}

