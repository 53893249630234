.recordingPlaybackModal-media{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;

	// background: rgba(255,255,255,0.03);
	// border-left: 1px solid rgb(255,255,255,0.07);
}

.recordingPlaybackModal-media-playerContainer{
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;
}



.recordingPlaybackModal-media-player{
	// background: $tempr;
	position: relative;
	overflow: hidden;
	border-radius: 5px;
}

.recordingPlaybackModal-media-player-videoPoster{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 50;

	background: black; // cover video
}

.recordingPlaybackModal-media-player-videoPoster-img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//

.recordingPlaybackModal-media-player-audioContainer{
	width: 100%;
	height: 100%;
	// background: rgba(255,255,255,0.06);

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
}

.recordingPlaybackModal-media-player-audioContainer-speaker{
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	width: 220px;
	height: 180px;

	border-radius: 8px;

	background: rgba(255,255,255,0.06);	
	// background: none;


}

.recordingPlaybackModal-media-player-audioContainer-speaker-avatarContainer{
	margin-top: 6px;

	width: 64px;
	height: 64px;
}

.recordingPlaybackModal-media-player-audioContainer-speaker-avatar{
	border-radius: 5px;
}

.recordingPlaybackModal-media-player-audioContainer-speaker-name{
	margin-top: 18px;

	font-size: 20px;
	font-weight: $lato-semibold;
	color: var(--text500);
}

// .recordingPlaybackModal-media-player-audioContainer-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	width: 180px;
// 	height: 180px;

// 	border-radius: 8px;

// 	background: rgba(255,255,255,0.08);	
// }

// .recordingPlaybackModal-media-player-audioContainer svg{
// 	width: 96px;
// 	height: 96px;
// 	fill: var(--text500);
// 	opacity: 0.25;
// }

//

.recordingPlaybackModal-media-controlsContainer{
	width: 100%;
	// background: $tempg;

	position: relative;	
}
// contorls

.recordingPlaybackModal-media-playbackControls{
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;


}

.recordingPlaybackModal-media-playbackControls-cancelHighlightBtn{
	position: absolute;
	top: 80px; // dirty
	height: calc(100% - 80px);

	left: 0px;
	width: 100%;
	
	background: rgba(0,0,0,0.65);
	
	z-index: 200;
}

.recordingPlaybackModal-media-playbackControls-cancelHighlightBtn[data-state='visible']{
	opacity: 1;
	transition: opacity 135ms linear;
}
.recordingPlaybackModal-media-playbackControls-cancelHighlightBtn[data-state='hidden']{
	opacity: 0;
	transition: opacity 135ms linear;
	pointer-events: none !important;
}


.recordingPlaybackModal-media-playbackControls-buttonRow{
	width: 100%;
	height: 80px;
	// background: $tempg;
	display: flex;
	align-items: center;
	justify-content: center;
}

//

.recordingPlaybackModal-media-playbackControls-highlights{
	width: 100%;
	height: 14px;
	position: relative;
	//top: 28px;
	//z-index: 1000;
	//background: $tempr;
}

.recordingPlaybackModal-media-playbackControls-highlights-highlightBtn{
	position: absolute;

	top: 0px;
	bottom: 0px;
	height: 100%;
	
	cursor: pointer;
	display: flex;
	align-items: flex-end;

	box-sizing: border-box;
	padding-bottom: 4px;
}

.recordingPlaybackModal-media-playbackControls-highlights-highlightBtn:hover{
	transform: scale(1.1);
}

.recordingPlaybackModal-media-playbackControls-highlights-highlightBtn-inner{
	height: 3px;
	width: 100%;
	background: $highlightYellow;
	opacity: 0.95;
	border-radius: 2px;

	position: relative;
	
}

.recordingPlaybackModal-media-playbackControls-highlights-highlightBtn:hover
.recordingPlaybackModal-media-playbackControls-highlights-highlightBtn-inner{
	opacity: 1;
}


//

.recordingPlaybackModal-media-playbackControls-seekBarContainer{
	width: 100%;
	height: 32px;
	//background: $tempg;
}

//

@import 'RecordingPlaybackModal-Media-SpeakerTimelines';



//

.recordingPlaybackModal-media-playbackControls-buttonRow-farEdgeSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	// background: $tempb;
}

//

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight,
.recordingPlaybackModal-media-playbackControls-buttonRow-timestamps,
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeedContainer{
	
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 214px;
	width: 200px;
	height: 34px;
	// background: $tempr;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight{
	visibility: hidden;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-navHighlight{
	width: 34px;
	height: 30px;

	flex-grow: 0;
	flex-shrink: 0;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-navHighlight[data-state="inactive"]{
	opacity: 0.25;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-navHighlight svg{
	width: 16px;
	height: 16px;
	fill: $highlightYellow;
	opacity: 0.5;
	display: none;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-navHighlight:hover svg{
	opacity: 0.85;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-addHighlightBtn{
	height: 34px;
	
	flex-grow: 1;
	flex-shrink: 1;

	
	border-radius: 3px;

	font-size: 16.25px;
	font-weight: $lato-semibold;

	font-size: 17.25px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;

	
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-addHighlightBtn[data-state="active"]{
	background: var(--253gray);
	color: rgba(0,0,0,0.98);
}

.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-addHighlightBtn[data-state="inactive"]{
	background: $highlightYellow;
	color: rgba(0,0,0,0.98);
}


//


.recordingPlaybackModal-media-playbackControls-buttonRow-addHighlight-navHighlight--previous svg{
	transform: scaleX(-1);
}



.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed{
	width: 114px;
	width: 126px;
	display: flex;
	justify-content: center;
	height: 100%;

	border-radius: 4px;

	//background: var(--243gray);

	position: relative;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed:hover{
	//background: var(--240gray);
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn{
	// flex-grow: 0;
	// flex-shrink: 0;
	position: absolute;
	top: 0px;
	height: 100%;
	width: 38px;
	z-index: 40;

	box-sizing: border-box;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn--slower{
	padding-left: 7px;
	left: 0px;
}
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn--faster{
	padding-right: 7px;
	right: 0px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn svg{
	fill: white;
	width: 16px;
	height: 16px;

	opacity: 0.5;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed:hover
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn svg{
	opacity: 0.6;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed:hover
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn:hover svg{
	opacity: 0.9;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn{
	
	width: 70px;

	opacity: 0.5;
	position: relative;

	overflow: hidden;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn:disabled{
	pointer-events: none;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient{
	position: absolute;
	top: 0px;
	height: 100%;
	width: 12px;
	// background: $tempr;
	z-index: 10;

	pointer-events: none;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient--left{
	left: 0px;
	//background: linear-gradient(to right, rgba(30,29,31,1) 0%, rgba(30,29,31,0) 100%);
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient--right{
	right: 0px;
	//background: linear-gradient(to right, rgba(30,29,31,0) 0%, rgba(30,29,31,1) 100%);
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed:hover
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn{
	opacity: 0.6;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed:hover
.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn:hover{
	opacity: 0.9;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-array{
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;

	display: flex;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem{
	width: 70px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem-label{
	color: white;
	font-size: 16px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
	pointer-events: none;
	margin-left: 1px;

	position: relative;
	top: 1px;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem-iconContainer{
	width: 14px;
	height: 20px;
	margin-left: -1px;


	display: flex;
	align-items: flex-end;
	justify-content: center;

	pointer-events: none;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem-iconContainer svg{
	width: 12px;
	height: 12px;
	fill: white;

	position: relative;
	top: -1px;	
}


.recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn--faster svg{
	transform: scaleX(-1);
}

//

.recordingPlaybackModal-media-playbackControls-buttonRow-timestamps{
	display: flex;

	font-size: 16px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.006em;
	
	user-select: none;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-played{

}

.recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-dash{
	margin-left: 8px;
	margin-right: 8px;

	opacity: 0.3;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-total{
	opacity: 0.6;
}



//

.recordingPlaybackModal-media-playbackControls-buttonRow-innerEdgeSpacer{
	min-width: 10px;
	flex-grow: 5;
	flex-shrink: 5;
	max-width: 400px;
	// background: $tempb;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer{
	// width: 50px;
	// height: 30px;
	// background: $tempg;
}

.recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer{
	padding-left: 2px;
	padding-right: 2px;	

}

//
// SEEKBAR

@import 'RecordingPlaybackModal-Media-SeekBar';


//
// BOTTOM

.recordingPlaybackModal-media-playbackControls-bottom{
	width: 100%;
	height: 40px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;
}

// .recordingPlaybackModal-media-playbackControls-bottom-timestamps{
// 	display: flex;
// 	cursor: default;
// 	user-select: none;
// }

// .recordingPlaybackModal-media-playbackControls-bottom-timestamps-played,
// .recordingPlaybackModal-media-playbackControls-bottom-timestamps-duration{
// 	width: 100px;
// 	// background: $tempr;

// 	margin-right: 5px;
// 	margin-left: 5px;

// 	font-size: 15px;
// 	color: var(--text600);
// }

// .recordingPlaybackModal-media-playbackControls-bottom-timestamps-played{
// 	text-align: right;
// }

// .recordingPlaybackModal-media-playbackControls-bottom-timestamps-played{
// 	opacity: 0.8;
// }

// .recordingPlaybackModal-media-playbackControls-bottom-timestamps-duration{
// 	opacity: 0.4;
// }