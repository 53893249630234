$height-figma-effectsToolbar: 40px;

.doc-figma-effectsToolbarContainer{
	// position: absolute;
	// left: 0px;
	// right: 0px;

	width: 100%;
	margin-top: 6px;


	
	//bottom: -12px;
	// bottom: -60px; // for dev testing
	

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;

	transform-origin: 50% 50%;
}


.doc-zoomedFigmaModal-footer-centerContainer
.doc-figma-effectsToolbarContainer{
	margin-top: 0px;
}

.doc-zoomedFigmaModal-footer-centerContainer
.doc-figma-effectsToolbar{
	background: none;
}

.doc-figma-effectsToolbarContainer--hidden{
	pointer-events: none;
}	

.doc-figma-effectsToolbar{	
	height: $height-figma-effectsToolbar;
	background: $tempr;
	border-radius: 5px;

	padding-left: 6px;
	padding-right: 9px;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: rgb(40,40,55,0.85);
	
	background: rgba(35,30,40,0.9);
	backdrop-filter: blur(5px);
}

//
.doc-figma-effectsToolbar-resetBtn{
	width: 28px;
	height: 28px;

	border-radius: 4px;	

	margin-right: -3px;
	margin-left: 3px;
}

.doc-figma-effectsToolbar-resetBtn svg{
	width: 20px;
	height: 20px;
	fill: $white;
}

.doc-figma-effectsToolbar-resetBtn--disabled,
.doc-figma-effectsToolbar-resetBtn--disabled:hover{	
	opacity: 0.4;
}

.doc-figma-effectsToolbar-resetBtn--enabled{	
	opacity: 0.8;
}

.doc-figma-effectsToolbar-resetBtn--enabled:hover{
	background: rgba(255,255,255,0.1);
	opacity: 1;
}

//

.doc-figma-effectsToolbar-separator{
	width: 1px;
	height: calc(#{$height-figma-effectsToolbar} - 14px);	
	background: rgba(255,255,255,0.1);
	margin-left: 6px;
	margin-right: 6px;
}

//

.doc-figma-effectsToolbar-bgButtonCarousel{
	
	

	display: flex;
	align-items: center;	
}

.doc-figma-effectsToolbar-bgButtonCarousel{

}

.doc-figma-effectsToolbar-bgButtonCarousel-contentsContainer{
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;

	//max-width: 200px;
	overflow: hidden;
}

.doc-figma-effectsToolbar-bgButtonCarousel-contents{
	display: flex;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btnContainer{

	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px;

	position: relative;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn{
	width: 24px;
	height: 24px;

	flex-grow: 0;
	flex-shrink: 0;

	border-radius: 3px;

	overflow: hidden;

	// outline: 1px solid red;

	// background: $tempr;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btn--active{
	// background: red;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btnActiveBorder{
	position: absolute;
	top: 1px;
	left: 1px;
	right: 1px;
	bottom: 1px;
	border-radius: 5px;

	border: 1px solid rgba(255,255,255,0.5);

	opacity: 0;
	z-index: 5;
	pointer-events: none;
}

.doc-figma-effectsToolbar-bgButtonCarousel-btnContainer--active
.doc-figma-effectsToolbar-bgButtonCarousel-btnActiveBorder{
	opacity: 1;
}



//
// PADDING

.doc-figma-effectsToolbar-paddingSliderContainer{
	width: 100px;
	padding-right: 12px;
	padding-left: 4px;
}

.doc-figma-effectsToolbar-paddingSlider{
	display: flex;
	align-items: center;
}

.doc-figma-effectsToolbar-paddingSlider-iconContainer{
	width: 34px;
	height: 28px;
	margin-right: 4px;
	// background: $tempb;

	// margin-right: -4px;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.75;
	transition: opacity 100ms linear;
}

.doc-figma-effectsToolbar-paddingSliderContainer:hover 
.doc-figma-effectsToolbar-paddingSlider-iconContainer{
	opacity: 1;
	transition: opacity 50ms linear;
}


.doc-figma-effectsToolbar-paddingSlider-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: $white;
}


.doc-figma-effectsToolbar-paddingSlider-slider{
	height: 28px;
	
	width: 100%;
	position: relative;
}


.doc-figma-effectsToolbar-paddingSlider-slider{
	opacity: 0.5;
	transition: opacity 100ms linear;
	cursor: pointer;
}

.doc-figma-effectsToolbar-paddingSliderContainer--adjustingPadding
.doc-figma-effectsToolbar-paddingSlider-slider,
.doc-figma-effectsToolbar-paddingSliderContainer:hover 
.doc-figma-effectsToolbar-paddingSlider-slider{
	opacity: 1;
	transition: opacity 50ms linear;
}



.doc-figma-effectsToolbar-paddingSlider-slider-slider{
	height: 100%;
	margin-top: 1px;
	// background: $green;
}

.doc-figma-effectsToolbar-paddingSlider-slider-slider-track{
	height: 2px;
	margin-top: 11px;
}

.doc-figma-effectsToolbar-paddingSlider-slider-slider-track-0{
	background: white;	
	border-radius: 2px;
}

.doc-figma-effectsToolbar-paddingSlider-slider-slider-track-1{	
	border-radius: 2px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background: rgb(86,99,114);
}

.doc-figma-effectsToolbar-paddingSlider-slider-slider-thumb{
	width: 11px;
	height: 11px;
	background: $white;
	border-radius: 6px;
	
	margin-top: 7px;



	box-shadow: 		
		0px 0px 0px 2px transparentize(#0B2630, 0.25),
	;	

	// opacity: 0;	
	// transition: opacity 150ms linear;
	// transition-delay: 200ms;
}

.doc-figma-effectsToolbar-paddingSlider-slider-slider-thumb:focus{
	outline: none;
	border: none;
}


.doc-video-inlineUI-controlBar-controlBar-thumb-thumb{

}

//
//

.doc-figma-effectsToolbar-selectDevice{
	display: flex;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn,
.doc-figma-effectsToolbar-shadowToggleBtn{
	// width: 84px;
	height: 28px;

	border-radius: 3px;
	

	border: 1px solid rgba(255,255,255,0.5);

	opacity: 0.5;

	padding-right: 8px;
	padding-left: 8px;
}

.doc-figma-effectsToolbar-shadowToggleBtn{
	padding-left: 4px;
	padding-right: 4px;

	margin-left: 4px;
	width: 32px;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop{
	width: 80px; // a bit hardcoded, so things don't jump
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn--tablet{
	width: 70px; // a bit hardcoded, so things don't jump
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop{
	width: 84px; // a bit hardcoded, so things don't jump
}


.doc-figma-effectsToolbar-selectDevice-selectBtn{
	width: 28px;
	height: 28px;

	border: 1px solid rgba(255,255,255,0.5);
	
	border-left: none;

	border-radius: 3px;

	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;

	opacity: 0.5;
}


.doc-figma-effectsToolbar-selectDevice-toggleBtn--active,
.doc-figma-effectsToolbar-shadowToggleBtn--active{
	background: rgba(255,255,255,0.04);
	background: rgba(0,0,0,0.10);
	border: 1px solid rgba(255,255,255,0.5);
	opacity: 1;
}

// .doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop{
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// }


.doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer{
	width: 20px;
	height: 20px;

	margin-left: -4px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	top: 0.5px;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop
.doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer{	
	margin-left: -1px;
	margin-right: 4px;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn--tablet
.doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer{	
	margin-left: -2px;
	margin-right: 2px;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer svg{
	width: 20px;
	height: 20px;

	fill: $white;
}

.doc-figma-effectsToolbar-selectDevice-toggleBtn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
	letter-spacing: 0.008em;
}


.doc-figma-effectsToolbar-selectDevice-selectBtn svg{
	width: 12px;
	height: 12px;
	fill: $white;
}

.doc-figma-effectsToolbar-shadowToggleBtn svg{
	width: 16px;
	height: 16px;
	fill: $white;
}

//





//
// DONE BUTTON
.doc-figma-effectsToolbar-doneBtnContainer{
	padding-left: 2px;
	padding-right: 2px;
}
.doc-figma-effectsToolbar-doneBtn{
	width: 92px;
	height: 28px;
	background: var(--primary500);
	border: 1px solid var(--primary400);
	color: $white;

	font-size: 15px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;

	border-radius: 3px;
}

.doc-figma-effectsToolbar-doneBtn:hover{
	background: var(--primary400);
}