$leftPadding-roadmapGrid-item: 30px;
$rightPadding-roadmapGrid-item: 30px;

.roadmapsHomePage-roadmapGrid-item{
	border-radius: 6px;
	border: 1px solid var(--line300);
	box-shadow: 0px 2px 4px rgba(0,0,0,0.03);
	//background: var(--253gray);

	// background: $tempr;
	
	box-sizing: border-box;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	position: relative;
}

.roadmapsHomePage-roadmapGrid-item:hover{
	border: 1px solid var(--line325);
	box-shadow: 0px 2px 4px rgba(0,0,0,0.04);
}


[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item{
		background: rgba(255,255,255,0.01);
		border: 1px solid $invisible;
		transition: background 50ms linear;
	}
}

[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item:hover{
		background: rgba(255,255,255,0.02);
		border: 1px solid $invisible;
	}
}

//


.roadmapsHomePage-roadmapGrid-item-navBtnContainer{
	// height: 120px;
	// width: 100%;

	// background: $tempr;

	position: relative;
	box-sizing: border-box;
	padding-top: 24px;
	padding-left: $leftPadding-roadmapGrid-item;
	padding-right: $rightPadding-roadmapGrid-item;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roadmapsHomePage-roadmapGrid-item--activity
.roadmapsHomePage-roadmapGrid-item-navBtnContainer{
	width: 210px;
	flex-grow: 0;
	flex-shrink: 0;
}


.roadmapsHomePage-roadmapGrid-item-topline{
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	display: none;
}

.roadmapsHomePage-roadmapGrid-item-topline-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -2px; // optical align

	margin-bottom: 9px;

}

.roadmapsHomePage-roadmapGrid-item-topline-iconContainer svg{
	fill: var(--text600);
	opacity: 0.25;
	width: 28px;
	height: 28px;
}

.roadmapsHomePage-roadmapGrid-item:hover 
.roadmapsHomePage-roadmapGrid-item-iconContainer svg{
	opacity: 0.5;
}

.roadmapsHomePage-roadmapGrid-item-titleContainer{
	width: 100%;

}

.roadmapsHomePage-roadmapGrid-item-title{
	width: 100%;
	font-size: 24px;
	
	font-weight: $lato-heavy;
	color: var(--text600);
	user-select: none;


	margin-top: 12px;
	margin-bottom: 0px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	
}

[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item-title{
		font-weight: $lato-bold; // good for dark mode		
		letter-spacing: 0.01em;
	}
}

//

.roadmapsHomePage-roadmapGrid-item-summaryChart{
	width: 100%;	
	max-width: 100%;

	display: flex;
	align-items: center;

	margin-top: 6px;
	margin-bottom: 20px;

	flex-wrap: wrap;

}

.roadmapsHomePage-roadmapGrid-item-summaryChart-group{
	display: flex;
	padding-left: 2px;
	padding-right: 2px;
	padding-top: 6px;
	padding-bottom: 6px;
	transition: opacity 50ms linear;
	transition-delay: 0ms;

	flex-grow: 0;
	flex-shrink: 1;
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill{
	width: 6px;
	height: 6px;
	border-radius: 1px;
	margin-right: 3px;

	flex-grow: 0;
	flex-shrink: 1;
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--started{
	background: rgb(246,160,42);
	background: rgb(255,170,112);
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--planned{
	background: rgb(190,185,198);
	background: rgb(190,190,190);
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--backlog{
	background: hsl(358, 83%, 73%);
	background: rgb(255,189,197);
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--completed{
	background: hsl(233, 92%, 65%);
	background: rgb(85,104,249);
}

//

.roadmapsHomePage-roadmapGrid-item-contentContainer{
	display: flex;

	box-sizing: border-box;
	padding-bottom: 28px;
	padding-left: $leftPadding-roadmapGrid-item;
	padding-right: $rightPadding-roadmapGrid-item;
}

.roadmapsHomePage-roadmapGrid-item--projectStatus
.roadmapsHomePage-roadmapGrid-item-contentContainer{
	min-height: 220px;
	flex-direction: column;	
}

.roadmapsHomePage-roadmapGrid-item--projectStatus
.roadmapsHomePage-roadmapGrid-item-contentContainer--noActive{
	min-height: 90px;
	justify-content: flex-end;
}

.roadmapsHomePage-roadmapGrid-item--activity
.roadmapsHomePage-roadmapGrid-item-contentContainer{
	min-height: 20px;
	height: auto;
	flex-direction: row;
	// justify-content: flex-end;
	// min-width: 10px;
	// flex-grow: 1;
	// flex-shrink: 1;
	// padding-left: 0px;
	// padding-right: 0px;
	// padding-bottom: 0px;
}

.roadmapsHomePage-roadmapGrid-item-projectList-header{
	font-size: 14.75px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.5;
	letter-spacing: 0.018em;

	margin-bottom: 7px;


}


[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item-projectList-header{
		letter-spacing: 0.02em; // good for dark mode
	}
}



.roadmapsHomePage-roadmapGrid-item-projectList-project{
	display: flex;
	align-items: center;

	height: 25px;	
	
	width: calc(100% + 16px);	
	padding-left: 8px;
	margin-left: -8px;

	border-radius: 2px;

	cursor: pointer;

	box-sizing: border-box;
	padding-right: 8px;

  opacity: 0.8;
  // background: var(--bg-docEditor);
}

.roadmapsHomePage-roadmapGrid-item-projectList-project--none{
	opacity: 0.5;
	pointer-events: none;
}



.roadmapsHomePage-roadmapGrid-item-projectList-project-label{
	font-size: 15.75px;
	font-weight: $lato-regular;
	color: var(--text600);
	letter-spacing: 0.012em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item-projectList-project-label{
		letter-spacing: 0.02em; // good for dark mode
	}
}


.roadmapsHomePage-roadmapGrid-item-projectList-project:hover{
	opacity: 1;
	background: var(--252gray);
}


[data-theme="dark"] {
	.roadmapsHomePage-roadmapGrid-item-projectList-project:hover{
		background: rgba(255,255,255,0.04);		
	}
}

//

.roadmapsHomePage-roadmapGrid-item-currentQuarterBtnContainer{
	display: flex;
	align-items: center;
	justify-content: flex-end;

	height: 30px;

	margin-bottom: 12px;
}

.roadmapsHomePage-roadmapGrid-item-currentQuarterBtn{
	height: 30px;
	border-radius: 4px;

	margin-right: 12px;

	background: $tempr;

	padding-right: 12px;
	padding-left: 12px;

	background: var(--254gray);

	opacity: 0.75;
	transition: opacity 50ms linear;
}

.roadmapsHomePage-roadmapGrid-item:hover
.roadmapsHomePage-roadmapGrid-item-currentQuarterBtn{
	opacity: 1;
}

.roadmapsHomePage-roadmapGrid-item-currentQuarterBtn:hover{
	background: var(--250gray);	
}

.roadmapsHomePage-roadmapGrid-item-currentQuarterBtn-iconContainer{
	margin-right: 4px;
	font-size: 15px;

	height: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	padding-bottom: 0px;
}

.roadmapsHomePage-roadmapGrid-item-currentQuarterBtn-label{
	font-size: 15.75px;
	font-weight: $lato-regular;
	opacity: 0.75;
	color: var(--text600);
	letter-spacing: 0.012em;
}


//
//

@import 'Roadmaps-HomePage-RoadmapGrid-Item-Activity';






// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-arrowContainer{
// 	position: absolute;
// 	top: 32px;
// 	right: 20px;

// 	display: none;
// }

// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-arrowContainer svg{
// 	width: 20px;
// 	height: 20px;
// 	fill: var(--text600);
// 	opacity: 0.12;
// 	transform: translateX(-2px);
// 	transition: all 100ms linear;
// }

// .roadmapsHomePage-roadmapGrid-item--medium-topContainer:hover
// .roadmapsHomePage-roadmapGrid-item-arrowContainer svg{
// 	opacity: 0.5;
// 	transform: translateX(0px);
// 	transition: all 50ms linear;
// }

// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-iconContainer{
// 	width: 28px;
// 	height: 28px;
// 	// background: $tempr;
	
// 	margin-bottom: 6px;
// 	margin-bottom: 32px;
// 	margin-bottom: 14px;


// }

// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-iconContainer svg{
// 	width: 28px;
// 	height: 28px;
// }


// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-title{
// 	font-size: 26px;
// 	//font-weight: $lato-bold; // good for dark mode
// 	font-weight: $lato-heavy;
// 	color: var(--text600);
// }

// // TOGGLE

// $sidePadding-roadmapGridItem-statusGroup: 5px;
// .roadmapsHomePage-roadmapGrid-item-statusToggle{
// 	display: flex;
// 	margin-left: calc(-1 * #{$sidePadding-roadmapGridItem-statusGroup});
// 	//margin-bottom: -14px;
// 	//margin-top: 10px;
// 	margin-top: -10px;	
// }


// .roadmapsHomePage-roadmapGrid-item--large
// .roadmapsHomePage-roadmapGrid-item-statusToggle{
// 	position: absolute;
// 	top: 180px;
// 	left: 40px;	
// }

// .roadmapsHomePage-roadmapGrid-item--medium
// .roadmapsHomePage-roadmapGrid-item-statusToggle{
// 	position: absolute;
// 	top: 23px;
// 	right: 22px;	
// 	// opacity: 0;
// }


// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup{
// 	// background: $tempr;
// 	padding-left: $sidePadding-roadmapGridItem-statusGroup;
// 	padding-right: $sidePadding-roadmapGridItem-statusGroup;

// 	padding-top: 20px;
// 	padding-bottom: 20px;

// 	display: flex;
// 	column-gap: 3px;
// 	align-items: flex-start;
// }

// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup-item{
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 2px;

// 	// background: red;
// }



// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup:hover 
// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup-item{
// 	transform: scale(1.1);
// 	transform-origin: center center;
// }






// .roadmapsHomePage-roadmapGrid-item
// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup{
// 	opacity: 0.75;
// }

// .roadmapsHomePage-roadmapGrid-item:hover 
// .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup{
// 	opacity: 1;
// }

// // .roadmapsHomePage-roadmapGrid-item:hover 
// // .roadmapsHomePage-roadmapGrid-item-statusToggle:hover 
// // .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup{
// // 	opacity: 0.5;
// // }

// // .roadmapsHomePage-roadmapGrid-item:hover 
// // .roadmapsHomePage-roadmapGrid-item-statusToggle:hover 
// // .roadmapsHomePage-roadmapGrid-item-statusToggle-statusGroup:hover{
// // 	opacity: 1;
// // }

// //

// .roadmapsHomePage-roadmapGrid-item-statusProject{
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: flex-start;

// 	flex-grow: 1;
// 	flex-shrink: 1;

// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project--status--backlog{
// 	opacity: 0.65;
// 	opacity: 0.6;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project--status--backlog:hover{
// 	opacity: 0.8;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-title{
// 	display: flex;
// 	height: 26px;

// 	opacity: 0;
// }

// .roadmapsHomePage-roadmapGrid-item--medium-bottomContainer:hover
// .roadmapsHomePage-roadmapGrid-item-statusProject-title{
// 	opacity: 1;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-title-iconContainer{
// 	margin-left: -1px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-title-iconContainer .linearProjectStatusIconContainer{
// 	transform: scale(0.9);
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-title-label{
// 	font-size: 15px;
// 	font-weight: $lato-regular;
// 	color: var(--text400);

// 	margin-left: 4px;

// 	text-transform: capitalize;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project{
// 	display: flex;
// 	align-items: center;

// 	height: 26px;
// 	//height: 29px;
	
// 	width: calc(100% + 10px);
// 	// width: 80%;
// 	border-radius: 2px;
// 	padding-left: 8px;
// 	margin-left: -8px;


// 	cursor: pointer;

// 	box-sizing: border-box;
// 	padding-right: 8px;

//   // white-space: nowrap;
//   // overflow: hidden;
//   // text-overflow: ellipsis;

 
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project-linearMarkerContainer{
// 	margin-right: 6px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project-linearMarkerContainer .linearProjectStatusIconContainer{
// 	transform: scale(0.85);
// }

// // .roadmapsHomePage-roadmapGrid-item-statusProject-project
// // .roadmapsHomePage-roadmapGrid-item-statusProject-project-linearMarkerContainer{
// // 	visibility: hidden;
// // }


// // .roadmapsHomePage-roadmapGrid-item-statusProject-project:first-child
// // .roadmapsHomePage-roadmapGrid-item-statusProject-project-linearMarkerContainer{
// // 	visibility: visible;
// // }


// .roadmapsHomePage-roadmapGrid-item-statusProject-project-emojiContainer{
// 	margin-right: 8px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project-lead{
// 	//margin-right: 8px;
// 	width: 18px;
// 	height: 18px;
// 	margin-left: auto;
// 	//padding-left: 5px;
// 	margin-right: 5px;
// 	// margin-left: 3px;
// 	// margin-left: -3px;
// 	// margin-right: -3px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project-lead-avatar{
// 	object-fit: contain;
// 	width: 18px;
// 	height: 18px;
// 	border-radius: 3px;
// }


// .roadmapsHomePage-roadmapGrid-item-statusProject-project-simpleMarker{
// 	// width: 3px;
// 	// height: 14px;

// 	width: 6px;
// 	height: 6px;
// 	border-radius: 1px;

// 	margin-right: 0px;

// 	position: relative;
// 	left: -10px;
// 	//margin-left: -5px;
// 	// margin-left: auto;
// 	//margin-right: 8px;
// }


// .roadmapsHomePage-roadmapGrid-item-statusProject-project--status--started
// .roadmapsHomePage-roadmapGrid-item-statusProject-project-simpleMarker{
// 	background: #FFAA70;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project--status--planned
// .roadmapsHomePage-roadmapGrid-item-statusProject-project-simpleMarker{
// 	background: rgb(200,200,215);
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project--status--backlog
// .roadmapsHomePage-roadmapGrid-item-statusProject-project-simpleMarker{
// 	background: rgb(255,189,197);
// }


// .roadmapsHomePage-roadmapGrid-item-statusProject-project-label{
// 	margin-left: 2px;
// 	font-size: 15.75px;
// 	font-weight: $lato-regular;
// 	color: var(--text600);
// 	letter-spacing: 0.012em;
// 	//letter-spacing: 0.02em; // good for dark mode
// 	 opacity: 0.8;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project--null{
// 	opacity: 0.3;
// 	pointer-events: none;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-project:hover{
// 	background: var(--253gray);
// }

// .roadmapsHomePage-roadmapGrid-item:hover
// .roadmapsHomePage-roadmapGrid-item-statusProject-project-label{
// 	opacity: 1;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-section{
// 	// background: $tempr;
// 	border-top: 1px solid rgb(255,255,255,0.03);
// 	padding-top: 8px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-section--started{
// 	min-height: 105px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-section--planned{
// 	min-height: 115px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-section--backlog{
// 	height: 32px;
// 	border-top: none;
// 	padding-top: 3px;
// }

// .roadmapsHomePage-roadmapGrid-item-statusProject-spacer{
// 	height: 6px;
// }

