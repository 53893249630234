.toastContainer{
  
}

.toastContainer{
  position: fixed;
  z-index: 9999999;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);

  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.toast{
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  align-items: center;

  height: 32px;
  border-radius: 7px;
  background: rgb(40,38,54);
  
  border: 1px solid rgb(0,0,0);

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);  

  user-select: none;
}

[data-theme="dark"]{
  .toast{
    border: 1px solid var(--line200);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);  
  }
}

@keyframes toastFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes toastWiggle {
  0% {
    transform: scale(1) translateX(0px);    
  }
  30% {
    transform: scale(1) translateX(0px);    
  }
  40% {
    transform: scale(1) translateX(0px);    
  }
  45%{
    transform: scale(1.008) translateX(-2px);
  }
  50% {
    transform: scale(1.008) translateX(2px);    
  }
  55% {
    transform: scale(1.008) translateX(-2px);    
  }
  60% {
    transform: scale(1) translateX(0px);    
  }
  100% {
    transform: scale(1) translateX(0px);    
  } 
}

@keyframes toastFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}

.toast.toast--type--jumpToCursorAlert[data-state='open'],
.toast.toast--type--jumpToCursorConfirmation[data-state='open']{
  animation: toastFadeIn 120ms cubic-bezier(.64,.14,.22,1.46);
}

.toast.toast--type--jumpToCursorAlert[data-state='closed'],
.toast.toast--type--jumpToCursorConfirmation[data-state='closed'],
.toast.toast--type--pingCursorAlert[data-state='closed'],
.toast.toast--type--pingCursorConfirmation[data-state='closed'],{
  animation: toastFadeOut 300ms ease-in;
}

.toast.toast--type--pingCursorAlert[data-state='open'],
.toast.toast--type--pingCursorConfirmation[data-state='open']{
  animation: 
    toastFadeIn 120ms cubic-bezier(.64,.14,.22,1.46),
    toastWiggle 2500ms linear 75ms forwards normal 5;
}


.toast.toast--type--moveDocToProject[data-state='open'],
.toast.toast--type--moveDocToOrg[data-state='open'],
.toast.toast--type--moveDocToTeam[data-state='open'],
.toast.toast--type--moveDocToRoadmap[data-state='open'],
.toast.toast--type--saveDefaultIssueMentionDisplayProperties[data-state='open'],
.toast.toast--type--clickDeletedMessageReply[data-state='open'],
.toast.toast--type--clickDeletedMessageImage[data-state='open'],
.toast.toast--type--clickDeletedMessageFigma[data-state='open'],
.toast.toast--type--clickDeletedMessageVideo[data-state='open'],
.toast.toast--type--sentMediaModalComment[data-state='open'],
.toast.toast--type--copiedDocLink[data-state='open'],
.toast.toast--type--updatedProject[data-state='open'],
.toast.toast--type--updatedProjectError[data-state='open'],
.toast.toast--type--createdProject[data-state='open'],
.toast.toast--type--createdProjectError[data-state='open'],
.toast.toast--type--updatedRoadmaps[data-state='open'],
.toast.toast--type--addSingleRoadmapToProject[data-state='open'],
.toast.toast--type--removeSingleRoadmapFromProject[data-state='open'],
.toast.toast--type--updatedProjectTargetDate[data-state='open'],
.toast.toast--type--createdTemplate[data-state='open'],
.toast.toast--type--archivedTemplate[data-state='open'],
.toast.toast--type--addedMeetingToCal[data-state='open'],
.toast.toast--type--updatedTargetDateCalendar[data-state='open'],

{
  animation: toastFadeIn 120ms cubic-bezier(.64,.14,.22,1.46);
}

.toast.toast--type--moveDocToTeam[data-state='closed'],
.toast.toast--type--moveDocToOrg[data-state='closed'],
.toast.toast--type--moveDocToRoadmap[data-state='closed'],
.toast.toast--type--moveDocToProject[data-state='closed'],
.toast.toast--type--saveDefaultIssueMentionDisplayProperties[data-state='closed'],
.toast.toast--type--clickDeletedMessageReply[data-state='closed'],
.toast.toast--type--clickDeletedMessageImage[data-state='closed'],
.toast.toast--type--clickDeletedMessageFigma[data-state='closed'],
.toast.toast--type--clickDeletedMessageVideo[data-state='closed'],
.toast.toast--type--dndSwitchOff[data-state='closed'],
.toast.toast--type--dndSwitchOn[data-state='closed'],
.toast.toast--type--sentMediaModalComment[data-state='closed'],
.toast.toast--type--copiedDocLink[data-state='closed'],
.toast.toast--type--updatedProject[data-state='closed'],
.toast.toast--type--updatedProjectError[data-state='closed'],
.toast.toast--type--createdProject[data-state='closed'],
.toast.toast--type--createdProjectError[data-state='closed'],
.toast.toast--type--updatedRoadmaps[data-state='closed'],
.toast.toast--type--addSingleRoadmapToProject[data-state='closed'],
.toast.toast--type--removeSingleRoadmapFromProject[data-state='closed'],
.toast.toast--type--updatedProjectTargetDate[data-state='closed'],
.toast.toast--type--createdTemplate[data-state='closed'],
.toast.toast--type--archivedTemplate[data-state='closed'],
.toast.toast--type--addedMeetingToCal[data-state='closed'],
.toast.toast--type--updatedTargetDateCalendar[data-state='closed'],
{
  animation: toastFadeOut 300ms ease-in;
}

.toast.toast--type--dndSwitchOn[data-state='open'],
.toast.toast--type--dndSwitchOff[data-state='open']{
  animation: toastFadeIn 200ms cubic-bezier(.64,.14,.22,1.46);
  animation-delay: 200ms;
  animation-fill-mode: backwards;
}

//

.toast-emojiContainer{
  font-family: "Apple Color Emoji";
  margin-right: 8px;
  margin-left: -4px;

  font-size: 17px;
  position: relative;
  top: -1.5px;
}

.toast.toast--type--copiedDocLink .toast-emojiContainer{
  top: -2px; //looks more centered
}

.toast-iconContainer{
  width: 22px;
  height: 22px;
  border-radius: 4px;

  margin-right: 8px;
  margin-left: -4px;

  background: rgba(255,255,255,0.05);

  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-iconContainer svg{
  fill: rgb(143,195,255);
  width: 18px;
  height: 18px;
}

.toast-iconContainer svg.icon--name--jump{
  position: relative;
  right: -1px;
}



//

.toast-label{
  color: rgba(255,255,255,0.75);
  font-size: 15px;
  font-weight: $lato-medium;
  letter-spacing: 0.008em;

  position: relative;
  top: -1px;
}

span.toast-label-emph{
  font-weight: $lato-bold;
  color: $white;
}

span.toast-label-white{  
  color: $white;
}

//
// TOAST WITH LINEAR MARKER
.toast-label--hasMarker{
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-label--hasMarker-linearMarkerContainer{
  width: 18px;
  height: 18px;

  margin-left: 5px;
  margin-right: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $white; // so emoji is full opacity
}

.toast-label--hasMarker-linearMarkerContainer
.linearMarker{
  font-size: 15px;
}

.toast-label--hasMarker-linearMarkerContainer
.linearMarker svg{
  width: 15px;
  height: 15px;
}

.toast-label--hasMarker--roadmap{
  margin-left: 5px;
  margin-right: 3px;

}

.toast-label--hasMarker--roadmap svg{
  width: 17px;
  height: 17px;
  fill: rgba(255,255,255,0.75);

  position: relative;
  top: 2px;

}

.toast-label--hasMarker--orgLogo{
  width: 14px;
  height: 14px;
  border-radius: 4px;

  margin-left: 7px;
  margin-right: 4px;
  // margin-left: -4px;

  background: rgba(255,255,255,0.05);

  display: flex;
  align-items: center;
  justify-content: center;
}

img.toast-label--hasMarker--orgLogo-image{
  //used for org logo
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 3px;
  position: relative;
  top: 0.5px;
}



//


button.toast-actionBtn{
  background: rgba(255,255,255,0.08);
  height: calc(100% - 4px);
  border: 1px solid rgba(255,255,255,0.1);

  border-radius: 4px;
  margin-right: -8px;

  margin-left: 12px;

  padding-left: 6px;
  padding-right: 6px;
}

button.toast-actionBtn:hover{
  background: rgba(255,255,255,0.15);
}

.toast-actionBtn-iconContainer{
  margin-right: 3px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

}
.toast-actionBtn-iconContainer svg{
  fill: $white;
  width: 16px;
  height: 16px;
  opacity: 0.7;
  position: relative;
  top: 0.5px;
}

.toast-actionBtn-label{
  font-size: 14.5px;
  font-weight: $lato-bold;
  color: $white;
  letter-spacing: 0.01em;
}

.toast-actionBtn-keyboardShortcut{
  display: flex;
  align-items: center;
  height: 18px;

  padding-left: 3px;
  padding-right: 3px;

  margin-left: 8px;

  position: relative;
  top: 0.5px;

  border-radius: 3px;

  background: rgba(255,255,255,0.05);
}

.toast-actionBtn-keyboardShortcut svg{
  width: 12px;
  height: 12px;
  fill: rgba(255,255,255,0.6);
}

.toast-actionBtn-keyboardShortcut-span{
  font-size: 13px;
  color: rgba(255,255,255,0.6);
  margin-left: 1px;
  font-weight: $lato-bold;
}

// Browse Templates Button

// .toast--type--createdTemplate{
// }

// .toast--type--createdTemplate
// button.toast-actionBtn{
//   //background: rgba(255,255,255,0.08);
//   height: 100%;
//   height: calc(100% - 4px);
//   border: none;
//   background: none;
//   // border: 1px solid rgba(255,255,255,0.1);

//   border-radius: 4px;
//   // margin-right: -8px;
//   // margin-left: 12px;

//   padding-left: 6px;
//   padding-right: 6px;
// }

.toast--type--createdTemplate
.toast-actionBtn-label{
  font-weight: $lato-semibold;
}


//


.toast-closeBtn{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;

  background: red;
}

.toast-closeBtn svg{
  width: 14px;
  height: 14px;

  fill: white;
}
