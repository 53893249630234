.message-content-mainFeedHighlight-btn,
.message-content-recording-btn{
	width: calc(100% + 8px);
	
	// background: $tempr;

	justify-content: flex-start;

	padding-top: 6px;
	padding-bottom: 6px;

	padding-left: 8px;
	padding-right: 8px;
	margin-left: -8px;

	border-radius: 5px;

	position: relative;
	cursor: pointer;
	user-select: none;
}


.message-content-mainFeedHighlight-btn:hover,
.message-content-recording-btn:hover{
	background: var(--messageSelectionPreview-hoverBG);
}

[data-theme="dark"]{
	.message-content-mainFeedHighlight-btn:hover,
	.message-content-recording-btn:hover{
		background: rgba(255,255,255,0.03);
	}
}

.message-content-mainFeedHighlight-btn-previewContainer,
.message-content-recording-btn-previewContainer{
	height: 68px;
	//aspect-ratio: 16/10;
	aspect-ratio: 16/10;

	padding-right: 10px;
	flex-grow: 0;
	flex-shrink: 0;
}

.message-content-mainFeedHighlight-btn-previewContainer,
.message-content-recording-btn-previewContainer{
	@include poly-fluid-sizing('height', (		
		1280px: 40px,		
		1366px: 40px,
		1440px: 68px,
		1680px: 68px)
	);
}

.message-content-recording-btn-preview-iconContainer{
	position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translateX(-50%) translateY(-50%) scale(0.7);
	z-index: 10;

	width: 100%;
	height: 100%;
	border-radius: 5px;
	background: rgba(35,30,40,0.6);
  //backdrop-filter: blur(px) saturate(150%);

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-content-recording-btn-preview-iconContainer svg{
	width: 15px;
	height: 15px;

	fill: white;
	position: relative;
	right: -1px;
	//opacity: 0.75;
}


.message-content-mainFeedHighlight-btn-preview,
.message-content-recording-btn-preview{
	width: 100%;
	height: 100%;
}

.message-content-mainFeedHighlight-btn-preview-video{
	width: 100%;
	height: 100%;

	border-radius: 3px;
	overflow: hidden;
	background: var(--235gray);
}

 .message-content-mainFeedHighlight-btn-preview-video video{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//

.message-content-recording-btn-preview{
	background: var(--250gray);
	position: relative;
	border-radius: 3px;
	overflow: hidden;
}

.message-content-recording-btn-preview img{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

// .message-content-recording-btn-preview img:nth-child(1){
// 	left: 50%;
// 	bottom: 50%;
// }

// .message-content-recording-btn-preview img:nth-child(2){
// 	left: 50%;
// 	top: 50%;
// }

// .message-content-recording-btn-preview img:nth-child(3){
// 	right: 50%;
// 	bottom: 50%;
// }

// .message-content-recording-btn-preview img:nth-child(4){
// 	right: 50%;
// 	top: 50%;
// }


//

.message-content-mainFeedHighlight-btn-rightContainer,
.message-content-recording-btn-rightContainer{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.message-para.message-content-mainFeedHighlight-btn-description{
	text-align: left;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;

  line-height: 1.2;

  min-height: 38px;

  // background: $tempr;

  margin-top: 1px;
  margin-bottom: 2px;

  opacity: 1;
}

@media screen and (max-width: 1420px){
	.message-para.message-content-mainFeedHighlight-btn-description{
		-webkit-line-clamp: 1;
		min-height: 18px;
	}
}

//

.message-para.message-content-recording-btn-title{
	display: flex;
	opacity: 1;

	cursor: pointer;
	user-select: none;
}

.message-content-recording-btn-title-iconContainer{
	width: 22px;
	height: 22px;
	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.message-content-recording-btn-title-iconContainer svg{
	width: 18px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.5;
}

.message-content-recording-btn-title-label{
	font-weight: $lato-semibold;
	color: var(--text600);

	margin-bottom: 2px;
}

//



.message-content-mainFeedHighlight-btn-duration,
.message-content-recording-btn-duration{

	font-size: 13.5px;
	font-weight: $lato-regular;
	color: var(--text500);
	opacity: 0.7;
}


.message-content-mainFeedHighlight-btn-preview-hoverBtn,
.message-content-recording-btn-preview-hoverBtn{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	z-index: 10;
	cursor: pointer;
}