.message-content-highlight-videoContainer{
	background: var(--253gray);
	border-radius: 3px;
}



.message-content-highlight-videoContainer--zoomed{
	position: fixed;
	// top: 0px;
	// left: 0px;
	// right: 0px;
	// bottom: 0px;

	z-index: 99999;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;
	background: $invisible;
}


.message-content-highlight-video{
	background: var(--253gray);
	position: relative;
	border-radius: 3px;
	overflow: hidden;
}

.message-content-highlight-video-playbackControlsContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	z-index: 10;
	cursor: pointer;
}

.message-content-highlight-video-playbackControlsBgPlayPause{
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}
//
// Added to original inline position when zoomed so no content reflow
.message-content-highlight-videoSpacer{
	background: var(--246gray);
	border-radius: 3px;
}

.message-content-highlight-zoomedBG{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 9999;

	background: var(--messageMediaZoomedBG);
}

//
//

$innerPadding-playbackControls: 4px;

.message-content-highlight-video-playbackControls,
.message-content-highlight-video-loadState{
	position: absolute;
	left: $innerPadding-playbackControls;
	bottom: $innerPadding-playbackControls;

	display: flex;
	align-items: center;
	border-radius: 4px;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;


	// background: $tempr;

	background: rgba(2,2,8,0.75);
	background: transparentize(darken(rgb(28,26,32),8%),0.25);
	//background: rgba(2,2,8,0.85);
  backdrop-filter: blur(10px);

  box-sizing: border-box;
  padding-top: 2px;
  // padding-right: 4px;

	height: 36px;

	z-index: 10;
}

.message-content-highlight-video--audio
.message-content-highlight-video-playbackControls{
	background: none;
	backdrop-filter: none;	
}



.message-content-highlight-video-playbackControls{
	width: calc(100% - #{$innerPadding-playbackControls} - #{$innerPadding-playbackControls});
}

.message-content-highlight-video-loadState{
	position: absolute;
	height: calc(36px - 2px);

	height: 29px;
	//bottom: 50px;	

	padding-top: 0px;
	padding-right: 4px;

	border-radius: 5px;
}



// .message-content-highlight-video-playbackControlsContainer:hover
// .message-content-highlight-video-playbackControls{
// 	visibility: visible;
// }

// .message-content-highlight-video-playbackControlsContainer:hover
// .message-content-highlight-video-loadState{
// 	visibility: hidden;
// }

//

.message-content-highlight-video-playbackControls-seekBarContainer{
	position: absolute;
	top: -14px;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 28px;
	// background: $tempr;
}

.message-content-highlight-video--audio
.message-content-highlight-video-playbackControls-seekBarContainer{
	left: 5px;
	right: 5px;
	width: calc(100% - 10px);
}

.message-content-highlight-video-playbackControls-seekBar{
	position: absolute;
	height: 100%;
	width: 100%;
}

.message-content-highlight-video-playbackControls-seekBar-track-0,
.message-content-highlight-video-playbackControls-seekBar-track-1{
	margin-top: 14px;
	border-radius: 2px;
	height: 3px;
}

.message-content-highlight-video-playbackControls-seekBar-track-0{
	position: absolute;
	top: 0px;
	left: 0px;

	//background: #8579D7;
	background: saturate(#8579D7, 5%);
	background: #3578f9;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;

	z-index: 10;	
}

.message-content-highlight-video-playbackControls-seekBar-track-1{
	position: absolute;
	top: 0px;
	left: 0px;	
	
	background: rgba(255,255,255,0.08);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.message-content-highlight-video--audio
.message-content-highlight-video-playbackControls-seekBar-track-0{
	border-bottom-left-radius: 2px;
}

.message-content-highlight-video--audio
.message-content-highlight-video-playbackControls-seekBar-track-1{
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

//

.message-content-highlight-video-playbackControls-timestamp{
	font-size: 14.5px;
	color: rgba(255,255,255,1);
	letter-spacing: 0.012em;

	
	pointer-events: none;	

	position: relative;
	left: -3px;
}

.message-content-highlight-video-playbackControls-timestamp--progress{
	margin-right: 7px;
	font-weight: $lato-semibold;
}

.message-content-highlight-video-playbackControls-timestamp--total{
	opacity: 0.6;
	font-weight: $lato-medium;
}

.message-content-highlight-video-loadState
.message-content-highlight-video-playbackControls-timestamp--total{
	font-size: 15px;
	font-weight: $lato-bold;
	left: -5px;
	opacity: 1;
	margin-right: 3px;
}

//

.message-content-highlight-video-playbackControls-btn{
	width: 40px;
	height: 30px;

	position: relative;
	z-index: 40;

	//border-radius: 3px;

	// background: rgba(255,255,255,0.2);

	// background: $tempr;
}

.message-content-highlight-video-playbackControls-btn svg{
	width: 13px;
	height: 13px;

	fill: white;
}

.message-content-highlight-video-playbackControls-btn--playback svg{
	width: 12px;
	height: 12px;
}

.message-content-highlight-video-loadState
.message-content-highlight-video-playbackControls-btn{

}

.message-content-highlight-video-loadState
.message-content-highlight-video-playbackControls-btn--playback svg{
	height: 10px;
	width: 10px;
	position: relative;
	top: -2px;
}

.message-content-highlight-video-loadState
.message-content-highlight-video-playbackControls-btn{
	width: 30px;
}

.message-content-highlight-video-playbackControls-btn--play 
svg.icon--name--playbackPlay{
	position: relative;
	right: -1px;
}

.message-content-highlight-video-playbackControls-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.message-content-highlight-video-playbackControls-btn--play{
	opacity: 0.8;
	width: 40px;
}

.message-content-highlight-video-playbackControls-btn--play:hover{
	opacity: 1.0;
}

.message-content-highlight-video-playbackControls-btn--zoom{
	opacity: 0.7;
}

.message-content-highlight-video-playbackControls-btn--zoom:hover{
	opacity: 1.0;
}



.message-content-highlight-video-playbackControls[data-state="visible"]{
	opacity: 1;
	transition: opacity 50ms linear;
}

.message-content-highlight-video-playbackControls[data-state="hidden"]{
	opacity: 0;
	pointer-events: none;
	transition: opacity 150ms linear;
}

.message-content-highlight-video-playbackControlsContainer--cursor--hide button.message-content-highlight-video-playbackControlsBgPlayPause{
	cursor: none;
}



// .message-content-highlight-video-playbackControls-seekbar--show:hover,
// .message-content-highlight-video-playbackControls-btn--toggleTranscript--show:hover,
// .message-content-highlight-video-playbackControls-btn--toggleZoom--show:hover,
// .message-content-highlight-video:hover
// .message-content-highlight-video-playbackControls-btn--playInitial{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// }

// .message-content-highlight-video-playbackControls-seekbar--hide:not(:hover),
// .message-content-highlight-video-playbackControls-btn--toggleTranscript--hide:not(:hover),
// .message-content-highlight-video-playbackControls-btn--toggleZoom--hide:not(:hover){
// 	opacity: 0;
// 	pointer-events: none;
// 	transition: opacity 150ms linear;
// }





// //
// // CONTROLS



// .message-content-highlight-video-playbackControls-btn{
// 	position: absolute;
// 	height: 32px;
// 	border-radius: 4px;
// 	// background: black;
// 	// opacity: 0.9;	
// 	background: rgba(2,2,5,0.75);
//   backdrop-filter: blur(10px);

// 	z-index: 10;
// }

// .message-content-highlight-video-playbackControls-btn:hover{
// 	background: rgba(2,2,5,0.9);
// }

// .message-content-highlight-video-playbackControls-btn-iconContainer{	
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	//margin-right: 3px;
// }

// .message-content-highlight-video-playbackControls-btn-iconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: white;
// }



// .message-content-highlight-video-playbackControls-btn--toggleTranscript,
// .message-content-highlight-video-playbackControls-btn--toggleZoom{
// 	width: 32px;
// 	height: 28px;//bottom: $innerPadding-playbackControls;
// }

// .message-content-highlight-video-playbackControls-btn--toggleTranscript{
// 	top: $innerPadding-playbackControls;
// 	left: $innerPadding-playbackControls;
// }

// .message-content-highlight-video-playbackControls-btn--toggleZoom{
// 	top: $innerPadding-playbackControls;
// 	right: $innerPadding-playbackControls;
// }

	
	


// .message-content-highlight-video-playbackControls-btn--toggleTranscript .message-content-highlight-video-playbackControls-btn-iconContainer,
// .message-content-highlight-video-playbackControls-btn--toggleZoom .message-content-highlight-video-playbackControls-btn-iconContainer{
// 	width: 18px;
// 	height: 18px;
// }


// .message-content-highlight-video-playbackControls-btn--toggleTranscript svg{
// 	width: 20px;
// 	height: 20px;
// 	position: relative;	
// 	fill: white;

// 	opacity: 1;

// 	transform: scale(1.02);
// 	transform-origin: 50% 0%;
// }

// .message-content-highlight-video-playbackControls-btn--toggleZoom svg{
// 	width: 14px;
// 	height: 14px;
// 	position: relative;	
// 	fill: white;

// 	opacity: 1;
// }








// .message-content-highlight-video-playbackControls-btn--playInitial{
// 	// left: 50%;
// 	// transform: translateX(-50%);
// 	left: $innerPadding-playbackControls;
// 	bottom: $innerPadding-playbackControls;

// 	padding-left: 8px;
// 	padding-right: 8px;

// 	height: 27px;	
// }

// .message-content-highlight-video-playbackControls-btn--playInitial
// .message-content-highlight-video-playbackControls-btn-iconContainer{
// 	margin-right: 4px;
// }

// .message-content-highlight-video-playbackControls-btn--playInitial
// .message-content-highlight-video-playbackControls-btn-iconContainer svg{
// 	width: 9px;
// 	height: 9px;
// 	margin-top: 1px;
// 	fill: white;
// }

// .message-content-highlight-video-playbackControls-btn--playInitial
// .message-content-highlight-video-playbackControls-btn-label{
// 	font-size: 15px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.015em;
// 	color: white;
// }


// .message-content-highlight-video-playbackControls-seekbar{
// 	position: absolute;

// 	z-index: 10;

// 	width: calc(100% - #{$innerPadding-playbackControls} - 72px);
// 	max-width: 800px;
// 	height: 20px;
// 	border-radius: 6px;

// 	left: 50%;
// 	transform: translateX(-50%);

// 	//bottom: calc(#{$innerPadding-playbackControls} + 6px);
// 	bottom: calc(#{$innerPadding-playbackControls});

// 	background: rgba(2,2,5,0.75);
//   backdrop-filter: blur(10px);

// 	display: flex;
	
// }




// .message-content-highlight-video-playbackControls-seekbar--show,
// .message-content-highlight-video-playbackControls-btn--toggleTranscript--show,
// .message-content-highlight-video-playbackControls-btn--toggleZoom--show,
// .message-content-highlight-video-playbackControls-btn--playInitial{
// 	opacity: 0.9;
// 	transition: opacity 50ms linear;
// }

// .message-content-highlight-video-playbackControls-seekbar--show:hover,
// .message-content-highlight-video-playbackControls-btn--toggleTranscript--show:hover,
// .message-content-highlight-video-playbackControls-btn--toggleZoom--show:hover,
// .message-content-highlight-video:hover
// .message-content-highlight-video-playbackControls-btn--playInitial{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// }

// .message-content-highlight-video-playbackControls-seekbar--hide:not(:hover),
// .message-content-highlight-video-playbackControls-btn--toggleTranscript--hide:not(:hover),
// .message-content-highlight-video-playbackControls-btn--toggleZoom--hide:not(:hover){
// 	opacity: 0;
// 	pointer-events: none;
// 	transition: opacity 150ms linear;
// }


// .message-content-highlight-video-playbackControls-seekbar-seekbarContainer{
// 	position: absolute;
// 	left: 8px;
// 	right: 8px;
// 	width: calc(100% - 16px);
// 	top: 0px;
// 	height: 100%;

// 	box-sizing: border-box;
// 	// padding-left: 4px;
// 	// padding-right: 4px;

// 	// background: $tempb;
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar{
// 	height: 100%;
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar-track{
// 	height: 4px;
// 	margin-top: 8px;
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar-track-0{
// 	background: white;
// 	border-top-left-radius: 4px;
// 	border-bottom-left-radius: 4px;
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar-track-1{	
// 	border-top-right-radius: 2px;
// 	border-bottom-right-radius: 2px;
// 	background: rgb(86,99,114);
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar-thumb{
// 	display: none;

// 	width: 5px;
// 	height: 16px;
// 	background: $white;
// 	border-radius: 3px;
	
// 	margin-top: 6px;
// 	// margin-left: -6px;

// 	box-shadow: 
// 		0px 2px 4px 0px rgba(0,0,0,0.12),
// 		0px 0px 2px 0px rgba(0,0,0,0.12),
// 		0px 0px 0px 1px rgba(0,0,0,0.12),

// 		;	
// }

// .message-content-highlight-video-playbackControls-seekbar-seekbar-thumb:focus{
// 	outline: none;
// }

// .message-content-highlight-video-inlineUI-controlBar-controlBar-thumb-thumb{

// }

