//
// LINEAR MARKER
//

// may need semi-opaque options

:root{
	
	--grey--linear: #bec2c8; //rgb(190,194,200)
	//--grey--yarn: rgb(140,145,158); // should be this
	--grey--yarn: rgb(190,185,198); // default for backlog

	--lightGrey--linear: #e2e2e2; // rgb(226,226,226))	
	--lightGrey--yarn: rgb(218,212,224); // default todo

	--darkGrey--linear: #95a2b3; // rgb(149, 162, 179)
	--darkGrey--yarn: rgb(158,150,170);

	--purple--linear: #5e6ad2;
	//--purple--yarn: hsl(269, 60%, 67%); // purple 500 modified
	--purple--yarn: hsl(233, 92%, 65%); // purple 500 modified

	--teal--linear: #26b5ce;
	//--teal--yarn: hsl(210, 94%, 59%); // blue 500
	--teal--yarn: hsl(188, 87%, 46%); // teal

	--darkTeal--linear: #0f7488;
	//--darkTeal--yarn: hsl(210, 93%, 44%); // blue 700
	--darkTeal--yarn: hsl(188, 86%, 35%); // more teal

	--green--linear: #4cb782;
	--green--yarn: hsl(158, 61%, 49%); // green 500 modified to be less blue

	--darkGreen--linear: #0f783c;
	--darkGreen--yarn: hsl(158, 61%, 39%); // green 700

	--yellow--linear: #f2c94c;
	--yellow--yarn: hsl(28, 100%, 72%); // used for in progress, so might get closer to orange

	--brown--linear: #5a450d;
	--brown--yarn: #5a450d; // to do -- update

	--orange--linear: #f2994a;
	--orange--yarn: hsl(24, 100%, 74%); // orange 500

	--darkOrange--linear: #db6e1f;
	--darkOrange--yarn: hsl(24, 100%, 58%); // orange 700 a bit darker

	--pink--linear: #f7c8c1;
	--pink--yarn: hsl(327, 86%, 77%);

	--red--linear: #eb5757;
	--red--yarn: hsl(358, 83%, 73%); // red 500

	--darkRed--linear: #c52828;
	--darkRed--yarn: hsl(359, 83%, 58%); // red 700 modified

}


[data-theme="dark"] {
	--grey--linear: #bec2c8; //rgb(190,194,200)
	--grey--yarn: rgb(190,185,198); // default for backlog

	--lightGrey--linear: #e2e2e2; // rgb(226,226,226))	
	--lightGrey--yarn: rgb(218,212,224); // default todo

	--darkGrey--linear: #95a2b3; // rgb(149, 162, 179)
	--darkGrey--yarn: rgb(158,150,170);

	--purple--linear: #5e6ad2;
	//--purple--yarn: hsl(269, 60%, 67%); // purple 500 modified
	--purple--yarn: hsl(233, 92%, 65%); // purple 500 modified

	--teal--linear: #26b5ce;
	//--teal--yarn: hsl(210, 94%, 59%); // blue 500
	--teal--yarn: hsl(188, 87%, 46%); // teal

	--darkTeal--linear: #0f7488;
	//--darkTeal--yarn: hsl(210, 93%, 44%); // blue 700
	--darkTeal--yarn: hsl(188, 86%, 35%); // more teal

	--green--linear: #4cb782;
	--green--yarn: hsl(158, 61%, 49%); // green 500 modified to be less blue

	--darkGreen--linear: #0f783c;
	--darkGreen--yarn: hsl(158, 61%, 39%); // green 700

	--yellow--linear: #f2c94c;
	--yellow--yarn: hsl(28, 100%, 72%); // used for in progress, so might get closer to orange

	--brown--linear: #5a450d;
	--brown--yarn: #5a450d; // to do -- update

	--orange--linear: #f2994a;
	--orange--yarn: hsl(24, 100%, 74%); // orange 500

	--darkOrange--linear: #db6e1f;
	--darkOrange--yarn: hsl(24, 100%, 58%); // orange 700 a bit darker

	--pink--linear: #f7c8c1;
	--pink--yarn: hsl(327, 86%, 77%);

	--red--linear: #eb5757;
	--red--yarn: hsl(358, 83%, 73%); // red 500

	--darkRed--linear: #c52828;
	--darkRed--yarn: hsl(359, 83%, 58%); // red 700 modified
}