//
// ALT APPROACH

.docEditor-messagePanel-header{
	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;
	height: 37px;
	
	display: flex;
	align-items: center;
	

	border-bottom: 1px solid var(--bottomBorder-header-messagePanel);
	box-shadow: var(--boxShadow-header-messagePanel);

	box-sizing: border-box;	
	// padding-left: 12px;	
	padding-left: 0px;	

	user-select: none;

	position: relative;
	z-index: 10;

	-webkit-app-region: drag;
}


.docEditor-messagePanel-header--newThread{
	padding-left: 12px;
}


//



button.docEditor-messagePanel-header-hideBtn{
	width: 32px;
	height: 32px;

	// display: none;

	margin-left: 3px;
	margin-right: 0px;

	box-sizing: border-box;

	//background: var(--250gray);

	border-radius: 4px;

	// background: $tempr;
}

// .docEditor-messagePanel-header:hover
// button.docEditor-messagePanel-header-hideBtn{
// 	display: flex;
// }

button.docEditor-messagePanel-header-hideBtn:hover{
	background: var(--250gray);
}

.docEditor-messagePanel-header-hideBtn svg{
	width: 19px;
	height: 19px;	
	fill: var(--text600);
	opacity: 0.4;
	transform: scaleX(-1.00);

	position: relative;
	// left: 2px;
}

button.docEditor-messagePanel-header-hideBtn:hover svg{
	opacity: 1;
}

// .docEditor-messagePanel-header{
// 	padding-left: 14px;
// }
// .docEditor-messagePanel-header:hover{
// 	padding-left: 0px;
// }

.docEditor-messagePanel-header-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;


	box-sizing: border-box;
	padding-right: 10px;	

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-messagePanel-header-title{
	font-size: 15.75px;
	font-weight: $lato-semibold;
	//letter-spacing: 0.001em;
	color: var(--text400);
	opacity: 0.9;


	color: var(--text500);
	opacity: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	width: 100%;
}



//
//

@import 'Messages-Panel-Header-Follower';
@import 'Messages-Panel-Header-DNDButton';
@import 'Messages-Panel-Header-Thread';
