//
// List Item

.doc-listItem{
	width: 100%;
	display: flex;

	// background: $tempr;

	// padding-top: 1px;
	// padding-bottom: 1px;

	position: relative;
}

.doc-contentBox
.doc-listItem{
	width: unset; // fixes wrapping bug
}

.doc-para + .doc-listItem{
	padding-top: 2px; // i think this is right
}

.doc-listItem + .doc-para{
	padding-top: 2px; // i think this is right
}

.doc-listItem-markerContainer{
	width: 24px;

	height: 25px;
	// background: $tempr;
	
	display: flex;
	justify-content: flex-start;
	align-items: center;

	box-sizing: border-box;	

	flex-grow: 0;
	flex-shrink: 0;	

	pointer-events: none;
	user-select: none;	

	color: var(--text500);	
}

.doc-listItem-marker{
	background: var(--text500);	
}

.doc-listItem-contents{
	word-break: break-word;
}


// NUMBER

.doc-listItem[data-marker-type="number"] .doc-listItem-markerContainer{
	// width: 18px;
	width: 29px;
	//padding-right: 2px;
	padding-bottom: 3px; // alignnment
	// background: $tempr;
}

.doc-listItem[data-marker-type="number"] .doc-listItem-marker{	
	background: none;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;
	padding-right: 10px;

	text-align: right;

	//opacity: 0.85;

	letter-spacing: 0.005em;
}

.doc-listItem[data-marker-type="number"] .doc-listItem-marker::after{
	content: '.';
	margin-left: 0.02em;
}

// LETTER LIST

.doc-listItem[data-marker-type="letter"] .doc-listItem-markerContainer,
.doc-listItem[data-marker-type="letterLowerCase"] .doc-listItem-markerContainer{
	// width: 18px;
	width: 29px;
	//padding-right: 2px;
	padding-bottom: 3px; // alignnment
	// background: $tempr;
}

.doc-listItem[data-marker-type="letter"] .doc-listItem-marker,
.doc-listItem[data-marker-type="letterLowerCase"] .doc-listItem-marker{	
	background: none;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	text-align: right;

	box-sizing: border-box;
	padding-right: 10px;

	letter-spacing: 0.005em;

	//opacity: 0.85;
}

.doc-listItem[data-marker-type="letterLowerCase"] .doc-listItem-marker{
	text-transform: lowercase;
}

.doc-listItem[data-marker-type="letter"] .doc-listItem-marker::after,
.doc-listItem[data-marker-type="letterLowerCase"] .doc-listItem-marker::after{
	content: '.';
	margin-left: 0.02em;
}

.doc-listItem[data-marker-type="number"][data-separator-type="bracket"] .doc-listItem-marker::after,
.doc-listItem[data-marker-type="letter"][data-separator-type="bracket"] .doc-listItem-marker::after,
.doc-listItem[data-marker-type="letterLowerCase"][data-separator-type="bracket"] .doc-listItem-marker::after{
	content: ')';
	width: 0.3em;
	//background: $tempr;
	margin-left: 0em;
}



// DASH LIST

.doc-listItem[data-marker-type="dash"]{
	padding-top: 0px;
	padding-bottom: 0px;
}

.doc-listItem[data-marker-type="dash"] .doc-listItem-markerContainer{
	// width: 18px;
	width: 29px;
	//padding-right: 2px;
	padding-bottom: 3px; // alignnment
	// background: $tempr;
}

.doc-listItem[data-marker-type="dash"] .doc-listItem-marker{
	// width: 4.5px;
	// height: 1.5px;
	// opacity: 1;
	// opacity: 0.8;
	// border-radius: 0px;
	background: none;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

 .doc-listItem[data-marker-type="dash"] .doc-listItem-marker::before{
	content: '–';
}


// BULLET 

.doc-listItem[data-marker-type="bullet"]{
	padding-top: 0px;
	padding-bottom: 0px;
}

.doc-listItem[data-marker-type="bullet"] .doc-listItem-markerContainer{
	// width: 20px;	
	// padding-left: 3px;
	// width: 19px;	
	width: 29px;	
	padding-left: 11.5px;
	// width: 17px;	
	// padding-left: 0px;
	// width: 25px;	
	// padding-left: 6px;
}

.doc-listItem[data-marker-type="bullet"] .doc-listItem-marker{
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
	opacity: 0.8;	
}





// //
// // BULLET POINTS
// .doc-listItem{
// 	//padding-top: 1px;
// 	//padding-bottom: 1px;
// }






//
// INDENTATION
.doc-listItem .doc-para{
	padding-left: 0 !important;
}

.doc-listItem[data-indent-level="0"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="1"]{
	padding-left: calc(1 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="2"]{
	padding-left: calc(2 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="3"]{
	padding-left: calc(3 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="4"]{
	padding-left: calc(4 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="5"]{
	padding-left: calc(5 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="6"]{
	padding-left: calc(6 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="7"]{
	padding-left: calc(7 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="8"]{
	padding-left: calc(8 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="9"]{
	padding-left: calc(0 * #{$doc-paragraph-indent});
}

.doc-listItem[data-indent-level="10"]{
	padding-left: calc(10 * #{$doc-paragraph-indent});
}



// //
// // STRANGE NEW
// .doc-listItem{
// 	padding-top: 1px;
// 	padding-bottom: 1px;
// }

// .doc-listItem-markerContainer{
// 	width: 18px;
// 	padding-left: 1px;
// }

// .doc-listItem-marker{
// 	width: 7px;
// 	height: 3px;
// 	border-radius: 1px;
// 	opacity: 0.25;
// }

