.onboardingModal-stage--theme-device{
  width: 324px;
  height: 190px;

  background: $tempr;
  border-radius: 4px;

  box-sizing: border-box;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;

  display: flex;

  position: relative;
}

.onboardingModal-stage--theme-device-activeBorder{
  position: absolute;
  top: -3px;
  left: -3px;

  width: calc(100% + 2px);
  height: calc(100% + 2px);

  border-radius: 6px;
}

.onboardingModal-stage-selectBtn--theme[data-state="active"]
.onboardingModal-stage--theme-device-activeBorder{
  border: 2px solid var(--primary500);
}

.onboardingModal-stage-selectBtn--theme[data-state="inactive"]
.onboardingModal-stage--theme-device-activeBorder{
  border: 2px solid rgba(255,255,255,0);
}


.onboardingModal-stage--theme-device-sideMenu{
  width: 60px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  padding-top: 20px;

  padding-left: 8px;
  padding-right: 8px;
}

.onboardingModal-stage--theme-device-sideMenu-tab{
  width: 100%;
  height: 8px;
  border-radius: 1px;

  margin-bottom: 4px;

  background: $tempr;
}

.onboardingModal-stage--theme-device-page{
  min-width: 10px;
  flex-grow: 1;
  flex-shrink: 1;

  height: 100%;

  border-radius: 4px;

  background: $tempr;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.onboardingModal-stage--theme-device-page-inner{
  width: 145px;
}

.onboardingModal-stage--theme-device-page-inner-header{
  margin-top: 20px;

  width: 98px;
  height: 14px;

  border-radius: 2px;
  background: $tempr;

  margin-bottom: 10px;
}

.onboardingModal-stage--theme-device-page-inner-calendar{
  display: flex;
  width: 100%;

  column-gap: 5px;

  margin-bottom: 16px;
}

.onboardingModal-stage--theme-device-page-inner-calendar-day{
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
}

.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  width: 100%;
  height: 3px;

  margin-bottom: 4px;
  border-radius: 1px;
}

.onboardingModal-stage--theme-device-page-inner-calendar-day:nth-child(1)
.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  background: var(--primary500);
}

.onboardingModal-stage--theme-device-page-inner-calendar-day:nth-child(2)
.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  background: var(--accentOrange500);
}

.onboardingModal-stage--theme-device-page-inner-calendar-day:nth-child(3)
.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  background: var(--accentGreen500);
}

.onboardingModal-stage--theme-device-page-inner-calendar-day:nth-child(4)
.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  background: var(--accentPurple500);
}

.onboardingModal-stage--theme-device-page-inner-calendar-day:nth-child(5)
.onboardingModal-stage--theme-device-page-inner-calendar-day-header{
  background: var(--primary500);
}


.onboardingModal-stage--theme-device-page-inner-calendar-day-block{
  width: 100%;
  height: 15px;

  margin-bottom: 3px;
  border-radius: 1px;

  background: $tempr;
}
                
.onboardingModal-stage--theme-device-page-inner-row{
  width: 100%;
  height: 10px;
  margin-bottom: 3px;
  border-radius: 1px;

  background: $tempr;
}



//
// COLORS


.onboardingModal-stage--theme-device--theme--dark{
  background: #191919;
}

.onboardingModal-stage--theme-device--theme--light{
  background: #E0E0E3;
}


.onboardingModal-stage--theme-device--theme--dark{

  .onboardingModal-stage--theme-device-page-inner-header{
    background: #A9A9A9;
    opacity: 0.5;
  }  

  .onboardingModal-stage--theme-device-sideMenu-tab{
    background: #2e2e32;
  }
  
  .onboardingModal-stage--theme-device-page{
    background: #161616;
    border: 1px solid #232323;
  }

  .onboardingModal-stage--theme-device-page-inner-calendar-day-block,
  .onboardingModal-stage--theme-device-page-inner-row{
    background: #2C2C2C;
  }

}


.onboardingModal-stage--theme-device--theme--light{
  
  .onboardingModal-stage--theme-device-page-inner-header{
    background: #D1D0D4;
  }

  .onboardingModal-stage--theme-device-sideMenu-tab{
    background: #CCCBCF;
  }

  .onboardingModal-stage--theme-device-page{
    background: #FEFEFE;
    border: 1px solid #D8D8D8;
  }

  .onboardingModal-stage--theme-device-page-inner-calendar-day-block,
  .onboardingModal-stage--theme-device-page-inner-row{
    background: #E6E5E7;
  }

}
