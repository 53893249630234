.onboardingModal-deviceDevContainer{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.onboardingModal-device{
	width: 1200px;
	height: 720px;

	display: flex;

	border-radius: 6px;

	position: relative;
}

[data-theme="light"]{
	.onboardingModal-device{

		background: rgb(243,242,246);
		border: 1px solid var(--line550);
		border-top: 1px solid var(--line550);
		border-bottom: 1px solid var(--line550);

		background: radial-gradient(
			500px 500px at 20% 30%,
			darken(rgb(241,240,246),0%) 0%,
			darken(rgb(241,240,246),1%) 70%,
			darken(rgb(241,240,246),2%) 100%,
		);

		box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
	}
}

[data-theme="dark"]{
	.onboardingModal-device{

		background: rgb(32,30,36);
		border: 1px solid rgb(255,255,255,0.08);
		border-top: 1px solid rgb(255,255,255,0.14);
		border-bottom: 1px solid rgb(255,255,255,0.06);

		background: radial-gradient(
			500px 500px at 20% 30%,
			darken(rgb(32,30,39),0%) 0%,
			darken(rgb(32,30,39),1%) 70%,
			darken(rgb(32,30,39),2%) 100%,
		);

		box-shadow: 0px 12px 16px 0px rgba(0,0,0,0.24);

	}
}


.onboardingModal-device-sideMenu{
	width: 220px;
	height: 100%;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-right: 6px;
	padding-left: 10px;
}

//

.onboardingModal-device-sideMenu-header{
	width: 100%;
	display: flex;

	margin-top: 24px;

	margin-bottom: 12px;
}

.onboardingModal-device-sideMenu-header-plusBtn{
	width: 18px;
	height: 18px;
	margin-left: auto;
	pointer-events: none;
}

.onboardingModal-device-sideMenu-header-plusBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.25;
}

//

.onboardingModal-sideMenu-tab{
	width: 100%;
	height: 31px;
	border-radius: 3px;
	
	display: flex;
	align-items: center;	

	box-sizing: border-box;
	padding-left: 8px;
}

.onboardingModal-sideMenu-tab[data-state="active"]{
	background: rgba(2,2,5,0.06);
}

[data-theme="dark"]{
	.onboardingModal-sideMenu-tab[data-state="active"]{
		background: rgb(255,255,255,0.04);
	}
}

.onboardingModal-sideMenu-tab[data-state="inactive"]{
	opacity: 0.6;
}

[data-theme="dark"]{
	.onboardingModal-sideMenu-tab[data-state="inactive"]{
		background: 0.4;
	}
}


.onboardingModal-sideMenu-tab--org[data-state="inactive"]{
	//opacity: 0.75;
}

.onboardingModal-sideMenu-tab--org{
	margin-bottom: 6px;
}

//

.onboardingModal-sideMenu-tab-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	margin-right: 6px;
}

.onboardingModal-sideMenu-tab-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.8;
	transform: center center;
}

.onboardingModal-sideMenu-tab-iconContainer svg.icon--name--roadmap{
	transform: scale(1.08);
}

.onboardingModal-sideMenu-tab-iconContainer svg.icon--name--sideMenuMeetingNotes{
	transform: scale(1.2);
}

.onboardingModal-sideMenu-tab-iconContainer svg.icon--name--sideMenuDrafts{
	transform: scale(1.18);
}

.onboardingModal-sideMenu-tab-iconContainer svg.icon--name--clock{
	transform: scale(1.0);
}

//


.onboardingModal-sideMenu-tab-label{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: var(--text600);
	letter-spacing: 0.008em;
}

[data-theme="dark"]{
	.onboardingModal-sideMenu-tab-label{
		letter-spacing: 0.014em;
		font-weight: $lato-semibold;
	}
}

.onboardingModal-sideMenu-tab--org
.onboardingModal-sideMenu-tab-label{
	font-weight: $lato-heavy;
	margin-left: 5px;

	font-size: 17.5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

[data-theme="dark"]{
	.onboardingModal-sideMenu-tab--org
	.onboardingModal-sideMenu-tab-label{
		font-weight: $lato-bold;		
	}
}
//

.onboardingModal-device-sideMenu-divider{
	display: flex;
	align-items: center;

	padding-top: 16px;
	padding-bottom: 16px;
}

.onboardingModal-device-sideMenu-divider-divider{
	height: 4px;
	width: 20px;
	border-radius: 2px;
	background: var(--text600);
	opacity: 0.15;

	margin-left: 10px;
}


//

.onboardingModal-device-pageContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	box-sizing: border-box;
	padding: 8px;
}

.onboardingModal-device-page{
	width: 100%;
	height: 100%;
	border-radius: 5px;

	background: var(--bg-docEditor);
	

	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: flex-start;	

	
}

[data-theme="light"]{
	.onboardingModal-device-page{
		border: 1px solid var(--line400);
		border-bottom: 1px solid var(--line400);
		border-top: 1px solid var(--line400);
	}
}

[data-theme="dark"]{
	.onboardingModal-device-page{
		border: 1px solid rgba(255,255,255,0.09);
		border-bottom: 1px solid rgba(255,255,255,0.08);
		border-top: 1px solid rgba(255,255,255,0.10);
		box-shadow: inset 0px 0px 20px rgba(0,0,0,0.05);
	}
}


.onboardingModal-device-page-innerPage{
	width: $textWidth-doc;
	display: flex;
	flex-direction: column;

	height: 100%;

	overflow-y: hidden;

}

.onboardingModal-device--noSideMenu
.onboardingModal-device-page-innerPage{
	//width: 720px;
}

.onboardingModal-device-page-title{
	margin-top: 66px;
	margin-top: 86px;

	font-size: 38px;
	color: var(--text600);
	font-weight: $lato-black;
	opacity: 0.9;

	margin-bottom: 24px;
}

[data-theme="dark"]{
	.onboardingModal-device-page-title{
		font-weight: $lato-heavy;
	}
}

.onboardingModal-device-page-h1{
	font-size: 30px;
	color: var(--text500);
	font-weight: $lato-heavy;
	opacity: 0.8;

	margin-bottom: 18px;	
}

.onboardingModal-device-page-section{
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
}

.onboardingModal-device-page-section-line{
	width: 100%;
	height: 26px;
	border-radius: 3px;

	margin-bottom: 10px;

	background: rgba(255,255,255,0.04);
}



[data-theme="light"]{
	.onboardingModal-device-page-section-line{
		background: var(--252gray);
	}
}

[data-theme="dark"]{
	.onboardingModal-device-page{
		background: rgba(255,255,255,0.04);
	}
}



//
//

.onboardingModal-stage--figma
.onboardingModal-device-page-innerPage{
	overflow-y: unset;
}

.onboardingModal-device-page-figma{
	width: $fullWidth-doc;
	margin-left: $negativeEdgeMarginTextToDoc;

	height: 400px;

	background: rgba(255,255,255,0.04);

	margin-bottom: 24px;
	margin-top: -10px;

	display: flex;
	align-items: center;
	justify-content: center;

}

.onboardingModal-device-page-figma-device{
	width: 200px;
	height: 340px;

	background: rgb(255,255,255,0.5);
	border-radius: 8px;
}