

.meetingNotesPage-table{
	// margin-top: 24px;
	// margin-top: 32px;
	// border-top: 1px solid var(--line400);
	padding-top: 24px;
	
	width: 100%;
}


.meetingNotesPage-table-filterBar{
	display: flex;
	align-items: center;

	height: 44px;
	// padding-bottom: 4px;

	// margin-top: -36px;
	margin-bottom: 8px;

	column-gap: 0px;

	width: calc(100% + 16px);
	margin-left: -8px;

	border-bottom: 1px solid var(--line300);
}



.meetingNotesPage-table-filterBar-tabBtn{
	font-size: 15.75px;
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text600);
	letter-spacing: 0.008em;

	// margin-bottom: 5px;	
	user-select: none;
	cursor: pointer;

	// background: $tempr;

	padding-left: 8px;
	padding-right: 8px;
	border-radius: 4px;

	height: 32px;
}

.meetingNotesPage-table-filterBar-tabBtn[data-state="inactive"]{
	opacity: 0.5;	
}
.meetingNotesPage-table-filterBar-tabBtn[data-state="inactive"]:hover{
	background: var(--253gray);
	opacity: 0.75;
}

.meetingNotesPage-table-filterBar-tabBtn[data-state="active"]{
	background: var(--250gray);
}


.meetingNotesPage-table-filterBar-tabBtn-iconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
	margin-left: -2px;
}

.meetingNotesPage-table-filterBar-tabBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
}

.meetingNotesPage-table-filterBar-tabBtn-iconContainer svg.icon--name--calendarSimple,
.meetingNotesPage-table-filterBar-tabBtn-iconContainer svg.icon--name--userCircle{
	height: 18px;
	width: 18px;
}

.meetingNotesPage-table-filterBar-tabBtn[data-state="inactive"]
.meetingNotesPage-table-filterBar-tabBtn-iconContainer svg{
	filter: grayscale(100%);
}


.meetingNotesPage-table-list{

}

.meetingNotesPage-table-filterBar-header{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: var(--text500);
	// opacity: 0.8;	

	margin-left: 6px;
	margin-right: 4px;
	position: relative;
	top: -2px;

	user-select: none;
	cursor: default;
}

.meetingNotesPage-table-list-empty{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 16px;
	color: var(--text300);
	font-weight: $lato-semibold;
	padding-top: 10px;

	cursor: default;
	user-select: none;
}

.meetingNotesPage-table-list-empty-btn{
	color: var(--primary700);
	text-decoration: underline;
	cursor: pointer;

	padding-left: 5px;
}


///
///



.meetingNotesPage-table-filterBar-btn{
	// background: $tempr;	
	border-radius: 4px;

	height: 34px;

	//border-radius: 4px;
	padding-left: 8px;
	padding-right: 8px;

	opacity: 0.75;

}



// .meetingNotesPage-table-filterBar-btn:first-child{
// 	margin-left: -8px;
// }

.meetingNotesPage-table-filterBar-btn:hover{
	background: var(--250gray);
	opacity: 1;	
}

.meetingNotesPage-table-filterBar-btn--inactive{
	opacity: 0.75;
}


.meetingNotesPage-table-filterBar-btn-iconContainer{
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 2px;
}

.meetingNotesPage-table-filterBar-btn-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text600);
	opacity: 0.75;
}

.meetingNotesPage-table-filterBar-btn-label{
	letter-spacing: 0.004em;
	font-weight: $lato-medium;
	//font-weight: $lato-semibold;

	font-size: 15.5px;
	color: var(--text500);
}
	

//

.meetingNotesPage-table-filterBar-btn--typeFilter{
	padding-left: 6px;
	padding-right: 10px;

	transition: opacity 50ms linear;
	//display: none;
}

.meetingNotesPage-table-filterBar-btn--typeFilter.meetingNotesPage-table-filterBar-btn--inactive
.meetingNotesPage-table-filterBar-btn-iconContainer svg{	
	filter: grayscale(100%);
	opacity: 0.75;
}

.meetingNotesPage-table-filterBar-btn--typeFilter.meetingNotesPage-table-filterBar-btn--inactive:hover
.meetingNotesPage-table-filterBar-btn-iconContainer svg{
	filter: grayscale(0%);
	opacity: 1;
}

.meetingNotesPage-table-filterBar--typeFilter--active .meetingNotesPage-table-filterBar-btn--typeFilter.meetingNotesPage-table-filterBar-btn--inactive{
	opacity: 0.25;
}

.meetingNotesPage-table-filterBar--typeFilter--active .meetingNotesPage-table-filterBar-btn--typeFilter.meetingNotesPage-table-filterBar-btn--inactive:hover{
	opacity: 1;
	transition: opacity 0ms linear;
}


.meetingNotesPage-table-filterBar-btn--typeFilter.meetingNotesPage-menu-button--active{
	background: var(--250gray);
}


.meetingNotesPage-table-filterBar-btn--newMeeting{
	padding-left: 4px;
	padding-right: 12px;
	height: 28px;

	margin-left: 14px;
	opacity: 1;

	// position: absolute;
	// right: 0px;
	// bottom: 100%;
	// margin-bottom: 12px;
}


.meetingNotesPage-table-filterBar-btn--newMeeting{	
	background: var(--text400);
	border: 1px solid var(--text500);
}

.meetingNotesPage-table-filterBar-btn--newMeeting
.meetingNotesPage-table-filterBar-btn-label{
	color: white;
}

.meetingNotesPage-table-filterBar-btn--newMeeting
.meetingNotesPage-table-filterBar-btn-iconContainer{
	margin-right: 1px;
}

.meetingNotesPage-table-filterBar-btn--newMeeting
.meetingNotesPage-table-filterBar-btn-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: white;
	opacity: 0.9;
}


.meetingNotesPage-table-filterBar-btn--newMeeting:hover{	
	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]
.meetingNotesPage-table-filterBar-btn--newMeeting{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.meetingNotesPage-table-filterBar-btn--newMeeting:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}


///

@import 'LibraryPages-MeetingNotesPage-MeetingRow';



.meetingNotesPage-table-filterBar-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}





.newMeetingPopoverContainer{	
	
}

.newMeetingPopoverContainer:focus{
	outline: none;
}

.newMeetingPopoverContainer--hidden,
.newMeetingPopoverContainer--hidden *{
	pointer-events: none !important;
}

.newMeetingPopover{
	width: unset;	
}

.newMeetingPopover{
	width: 200px;
	width: 220px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 10px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;

	transform-origin: 80% 10%;
}


.popoverMenu-item--newMeeting[data-state="checked"]{
	background: var(--activeBG-suggestionItem); ;
}

.popoverMenu-item--newMeeting[data-state="unchecked"]:hover{
	background: var(--253gray);
}

.popoverMenu-item--newMeeting{
	height: 30px;
	height: 48px;
	//background: $tempr;
	// outline: 1px solid red;
}

.popoverMenu-item--newMeeting--blank{
	height: 30px;
}

.popoverMenu-item--newMeeting
.popoverMenu-item-iconContainer{
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	margin-left: -3px;

	margin-top: 6px;

}

.popoverMenu-item--newMeeting
.popoverMenu-item-iconContainer svg{
	width: 18px;
	height: 18px;

	margin-top: 1px;

	// transform: scale(0.9);
	// transform-origin: 50% 50%;
}

.popoverMenu-item--newMeeting
.popoverMenu-item-rightContainer{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
}

.popoverMenu-item--newMeeting
.popoverMenu-item-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: white;
	letter-spacing: 0.012em;

	margin-top: -1px;
	margin-bottom: -9px;
}

.popoverMenu-item--newMeeting--blank
.popoverMenu-item-label{
	margin-top: 0px;	
}



.popoverMenu-item-subLabel{
	font-size: 14px;
	font-weight: $lato-regular;
	color: white;
	letter-spacing: 0.012em;
	opacity: 0.7;
}

.popoverMenu-item--newMeeting--blank
.popoverMenu-item-label{
	opacity: 0.75;
}

//
//

