.docEditor--draft-channelSelectDialog{
	width: 480px;
	min-height: 480px;
	// //height: calc(100vh - 200px);
	// max-height: 600px;

	border: 1px solid var(--line500);
	border-radius: 8px;

	box-shadow:
	  0px 2.8px 2.2px rgba(0, 0, 0, 0.028),
	  0px 6.7px 5.3px rgba(0, 0, 0, 0.04),
	  0px 12.5px 10px rgba(0, 0, 0, 0.05),
	  0px 22.3px 17.9px rgba(0, 0, 0, 0.06),
	  0px 41.8px 33.4px rgba(0, 0, 0, 0.072),
	  0px 100px 80px rgba(0, 0, 0, 0.1)
	;

	display: flex;
	flex-direction: column;

}


.docEditor--draft-channelSelectDialog-header{
	height: 58px;
	width: 100%;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;

	border-bottom: 1px solid var(--line300);
}

input.docEditor--draft-channelSelectDialog-header-search{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	box-sizing: border-box;
	padding-left: 20px;

	border: none;

	border-top-right-radius: 8px;
	border-top-left-radius: 8px;

	font-size: 19px;

	font-weight: $lato-semibold;
	color: var(--text500);

	letter-spacing: 0.005em;
}

input.docEditor--draft-channelSelectDialog-header-search:focus{
	outline: none;
	border: none;
}

input.docEditor--draft-channelSelectDialog-header-search::placeholder{
	color: var(--text100);
	font-weight: $lato-medium;
	letter-spacing: 0.005em;
}



.docEditor--draft-channelSelectDialog-channelList{
	width: 100%;
	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: scroll;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;

	padding-top: 8px;
	padding-bottom: 8px;

}



// 
button.docEditor--draft-channelSelectDialog-channelList-channelRow{
	display: flex;
	width: 100%;
	height: 48px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding-left: 8px;
	padding-right: 8px;

	box-sizing: border-box;

	border: none;
	box-shadow: none;
	background: none;

	border-radius: 8px;

	cursor: pointer;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:hover{
	// background: rgb(253,250,250);
	background: $lightGray-bg;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:active{
	background: darken($lightGray-bg, 2%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--isFocused{
	background: transparentize($accentColor-purple, 0.9);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--isFocused:hover{
	background: transparentize($accentColor-purple, 0.87);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--isFocused:active{
	background: transparentize($accentColor-purple, 0.8);	
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--current{	
	background: $lightGray-bg;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--current:hover{
	background: darken($lightGray-bg, 2%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--current:active{
	background: darken($lightGray-bg, 4%);
}


.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	width: 32px;
	height: 32px;

	flex-grow: 0;
	flex-shrink: 0;

	background: $tempr;
	background: $lightGray-bg;

	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 12px;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:hover
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: darken($lightGray-bg, 4%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:active
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: darken($lightGray-bg, 7%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--isFocused
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: transparentize($accentColor-purple, 0.85);
}


button.docEditor--draft-channelSelectDialog-channelList-channelRow--current
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: darken($lightGray-bg, 4%);	
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--current:hover
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: darken($lightGray-bg, 7%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--current:active
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer{
	background: darken($lightGray-bg, 10%);
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-currentChannelDot{
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: var(--text100);
	opacity: 0.5;
}


.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: rgb(139,137,137);
	margin-right: -1px;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:hover
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer svg{
	fill: darken(rgb(139,137,137), 5%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow:active
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer svg{
	fill: darken(rgb(139,137,137), 10%);
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow--isFocused
.docEditor--draft-channelSelectDialog-channelList-channelRow-iconContainer svg{
	fill: transparentize($accentColor-purple, 0);
}


.docEditor--draft-channelSelectDialog-channelList-channelRow-name{
	min-width: 10px;
	flex-shrink: 1;
	flex-grow: 1;

	text-align: left;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 18px;
  font-weight: $lato-bold;
  color: var(--text500);

  margin-top: -1px;
  letter-spacing: 0.01em;
}



.docEditor--draft-channelSelectDialog-channelList-channelRow-memberList{
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;	

	position: relative;
}

button.docEditor--draft-channelSelectDialog-channelList-channelRow-memberList,
button.docEditor--draft-channelSelectDialog-channelList-channelRow-memberOverfull{
	border: none;
	box-shadow: none;
	background: none;
	padding: 0px;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-memberList-member{	
	background: red;

	width: 26px;
	height: 26px;

	border-radius: 6px;
	border: 2px solid var(--line000);

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-memberList-member-avatar{	
	border-radius: 6px;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-memberTooltip[data-side="right"]{
	width: 80px;
	margin-top: 72px;
	margin-left: -96px;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-memberTooltip-row{
	font-size: 14px;
	height: 18px;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-memberTooltip-rowSpacer{
	width: 100%;
	height: 4px;
	background: $invisible;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-keyboardShortcutContainer{
	margin-left: auto;
	height: 100%;
	display: flex;
	align-items: center;

}

.docEditor--draft-channelSelectDialog-channelList-channelRow-keyboardShortcut{
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 9px;
	background: darken($accentColor-purple, 0%);

	color: $white;
	border-radius: 5px;

	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	height: 28px;
	margin-right: 6px;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-keyboardShortcut-iconContainer {
	margin-left: 4px;
	height: 100%;
	display: flex;
	align-items: center;
}

.docEditor--draft-channelSelectDialog-channelList-channelRow-keyboardShortcut-iconContainer svg{
	width: 14px;
	height: 14px;

	fill: $white;
	margin-top: 1px;
}