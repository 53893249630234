// $green: rgb(88,206,148);
$green: rgb(59,209,136);
$red: #FD6E6E;








// GLOBAL
// UI BUILDING

	
:root{
	// SPECIAL
	// $notificationRed: rgb(249,69,58);
	// --notificationRed: rgb(249,69,58);

	// INSERT BAR 
	
}


[data-theme="dark"]{	

	
}

