
// MENTIONS
.message-para-mention{	
	padding-left: 2px;
	padding-right: 2px;

	height: 22px;

	cursor: pointer;
	
	border-radius: 3px;

	display: inline-block;

	cursor: pointer;

	background: var(--primary25);
	color: var(--primary700);
}

.message-para-mention:hover{	
	color: var(--primary800);
	background: var(--primary50);
}

.ProseMirror-selectednode.message-para-mention,
.ProseMirror-selectednode.message-para-mention:hover{
	color: var(--primary800);
	background: var(--primary75);
}



// MENTIONS
[data-theme="dark"]{
	.message-para-mention{	
		background: rgb(104,77,47); // stolen from slack
		color: rgb(235,201,93);
	}

	.message-para-mention:hover{	
		background: lighten(rgb(104,77,47), 5%);
		color: lighten(rgb(235,201,93), 5%);
	}

	.ProseMirror-selectednode.message-para-mention,
	.ProseMirror-selectednode.message-para-mention:hover{	
		background: lighten(rgb(104,77,47), 8%);
		color: lighten(rgb(235,201,93), 5%);
	}
}
