
//
// THREAD PANEL

.doc-zoomedFigmaModal-threadPanel{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	border-left: 1px solid lighten(rgb(36,33,42), 3%); // a bit dirty
	background: darken(rgb(36,33,42), 8%);


	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	
}

.doc-zoomedFigmaModal-threadPanel-header{
	height: $headerHeight-zoomedFigmaModal;
	//width: calc(100% - #{);
	width: 100%;	

	padding-left: 12px;
	box-sizing: border-box;

	// background: $tempr;
	border-bottom: 1px solid lighten(rgb(36,33,42), 3%); // a bit dirty

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}


.doc-zoomedFigmaModal-threadPanel-header-label{
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;

	font-size: 15.75px;
	font-weight: $lato-semibold;

	color: var(--text500);
}

// .doc-zoomedFigmaModal-threadPanel-header-hideThreadLabel{
// 	font-size: 15px;
// 	font-weight: $lato-regular;

// 	color: var(--text500);

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	padding-right: 8px;

// 	opacity: 0;
// 	transition: opacity 50ms linear;
// 	transition-delay: 0ms;
// }

// .doc-zoomedFigmaModal-threadPanel-header:hover
// .doc-zoomedFigmaModal-threadPanel-header-hideThreadLabel{
// 	opacity: 0.8;
// 	transition: opacity 100ms linear;
// 	transition-delay: 500ms;
// }

.doc-zoomedFigmaModal-threadPanel-header-iconContainer{
	width: 24px;
	height: 24px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 6px;
}

.doc-zoomedFigmaModal-threadPanel-header-iconContainer svg{
	width: 20px;
	height: 20px;

	fill: $white;
	opacity: 0.5;
	transform: scale(1.05);
	transition: all 50ms linear;	
}

.doc-zoomedFigmaModal-threadPanel-header:hover
.doc-zoomedFigmaModal-threadPanel-header-iconContainer svg{
	opacity: 0.7;
	transition: opacity 100ms linear;
	transition-delay: 500ms;
}

.doc-zoomedFigmaModal-threadPanel-header-closeBtnSpacer{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;

	width: $closeBtnContainerWidth-zoomedFigmaModal;

	border-left: 1px solid lighten(rgb(36,33,42), 1%); // a bit dirty
}

