
.meetingNotesPage-menu{
	display: flex;
	align-items: center;	
	height: 36px;

	width: calc(100% + 12px);
	margin-left: -8px;
	margin-top: -16px;
	margin-top: -12px;
	margin-bottom: 12px;

	position: relative;
}

//

.meetingNotesPage-menu-button{
	height: 30px;

	border-radius: 4px;
	padding-left: 8px;
	padding-right: 8px;
}

.meetingNotesPage-menu-button--active{
	opacity: 1;
}

.meetingNotesPage-menu-button--inactive{
	opacity: 0.65;
}

.meetingNotesPage-menu-button--docMemberFilter.meetingNotesPage-menu-button--inactive{
	opacity: 0.5;
}

.meetingNotesPage-menu-button:hover{
	background: var(--250gray);
	opacity: 1;	
}

.meetingNotesPage-menu-button-iconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 3px;
}

.meetingNotesPage-menu-button-iconContainer svg{
	width: 16px;
	height: 16px;
}


.meetingNotesPage-menu-button-label{

	letter-spacing: 0.004em;
	font-weight: $lato-medium;
	font-weight: $lato-semibold;

	font-size: 15.75px;
	color: var(--text500);
}


//
// MEETING TYPE FILTER

.meetingNotesPage-menu-button--meetingTypeFilter{
	padding-left: 6px;
	padding-right: 10px;

	transition: opacity 50ms linear;
	//display: none;
}

.meetingNotesPage-menu-button--meetingTypeFilter.meetingNotesPage-menu-button--inactive
.meetingNotesPage-menu-button-iconContainer svg{	
	filter: grayscale(100%);
	opacity: 0.75;
}

.meetingNotesPage-menu-button--meetingTypeFilter.meetingNotesPage-menu-button--inactive:hover
.meetingNotesPage-menu-button-iconContainer svg{
	filter: grayscale(0%);
	opacity: 1;
}

.meetingNotesPage-menu--meetingTypeFilter--active .meetingNotesPage-menu-button--meetingTypeFilter.meetingNotesPage-menu-button--inactive{
	opacity: 0.25;
}

.meetingNotesPage-menu--meetingTypeFilter--active .meetingNotesPage-menu-button--meetingTypeFilter.meetingNotesPage-menu-button--inactive:hover{
	opacity: 1;
	transition: opacity 0ms linear;
}


.meetingNotesPage-menu-button--meetingTypeFilter.meetingNotesPage-menu-button--active{
	background: var(--250gray);
}

.meetingNotesPage-menu-button--toggleButton{
	padding-left: 4px;
	padding-right: 4px;
}

//
// NEW MEETING BUTTON

.meetingNotesPage-menu-button--newMeeting{
	padding-left: 4px;
	padding-right: 12px;
	height: 32px;

	margin-left: 14px;

	// position: absolute;
	// right: 0px;
	// bottom: 100%;
	// margin-bottom: 12px;
}


.meetingNotesPage-menu-button--newMeeting{	
	background: var(--text400);
	border: 1px solid var(--text500);
}

.meetingNotesPage-menu-button--newMeeting
.meetingNotesPage-menu-button-label{
	color: white;
}

.meetingNotesPage-menu-button--newMeeting
.meetingNotesPage-menu-button-iconContainer{
	margin-right: 1px;
}

.meetingNotesPage-menu-button--newMeeting
.meetingNotesPage-menu-button-iconContainer svg{
	width: 14px;
	height: 14px;
	fill: white;
	opacity: 0.9;
}


.meetingNotesPage-menu-button--newMeeting:hover{	
	background: var(--text500);
	border: 1px solid var(--text600);
}

:root[data-theme="dark"]
.meetingNotesPage-menu-button--newMeeting{
	background: var(--250gray);
	border: 1px solid var(--246gray);
}

:root[data-theme="dark"]
.meetingNotesPage-menu-button--newMeeting:hover{
	background: var(--248gray);
	border: 1px solid var(--246gray);
}



//

.meetingNotesPage-menu-hSpacer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	
}


button.meetingNotesPage-menu-dropdownBtn{
	width: 32px;
	height: 32px;

	margin-right: -18px;
	margin-left: 4px;

	border-radius: 4px;
}

button.meetingNotesPage-menu-dropdownBtn:hover,
button.meetingNotesPage-menu-dropdownBtn[data-state="open"]{
	background: var(--250gray);
}

button.meetingNotesPage-menu-dropdownBtn svg{
	width: 17px;
	height: 17px;

	fill: var(--text500);
	opacity: 0.35;
}


button.meetingNotesPage-menu-dropdownBtn:hover svg{
	opacity: 0.8;
}









//
//

// .meetingNotesPage-menu-filterBtn{
// 	padding-left: 8px;
// 	padding-right: 8px;

// 	border-radius: 4px;

// 	height: 30px;
// 	opacity: 0.75;
// 	margin-left: 0px;

// 	font-size: 15px;
// 	letter-spacing: 0.008em;
// 	font-weight: $lato-medium;
// 	color: var(--text500);

// 	text-transform: capitalize;

// 	display: none;
// }



// .meetingNotesPage-menu-filterBtn:hover,
// .meetingNotesPage-menu-filterBtn[data-state="open"]{
// 	background: var(--248gray);
// 	opacity: 1;
// }


// .meetingNotesPage-menu-participantToggle--inactive{
// 	opacity: 0.5;
// }

// .meetingNotesPage-menu-participantToggle--inactive:hover{
// 	opacity: 0.7;
// 	background: var(--251gray);
// }

// .meetingNotesPage-menu-participantToggle--inactive:hover svg{
// 	opacity: 0.9;
// }


// .meetingNotesPage-menu-participantToggle--active{
// 	background: var(--251gray);
// 	opacity: 1;
// }

// .meetingNotesPage-menu-participantToggle--active:hover{
// 	background: var(--246gray);
// 	opacity: 1;
// }

// .meetingNotesPage-menu-participantToggle-activityIcon{
// 	display: flex;
// 	flex-wrap: wrap;

// 	width: 18px;
// 	margin-right: 3px;
// }

// .meetingNotesPage-menu-filterBtn--activeType{

// }

// .meetingNotesPage-menu-filterBtn-iconContainer{
// 	width: 20px;
// 	height: 20px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-right: 3px;
// }

// .meetingNotesPage-menu-filterBtn-iconContainer svg{
// 	width: 16px;
// 	height: 16px;
// 	opacity: 0.75;
// 	fill: var(--text500);
// }

// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--designReview,
// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--designReview:hover{
// // 	background: var(--accentGreen50);
// // }

// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--teamSync,
// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--teamSync:hover{
// // 	background: var(--primary50);
// // }

// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--discovery,
// // .meetingNotesPage-menu-filterBtn--activeType--meetingType--discovery:hover{
// // 	background: var(--accentPurple50);
// // }


// .meetingNotesPage-menu-filterBtn-cancelIconContainer{
// 	width: 18px;
// 	height: 18px;
// 	border-radius: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-right: -4px;
// 	margin-left: 4px;
// }

// .meetingNotesPage-menu-filterBtn-cancelIconContainer svg{
// 	width: 14px;
// 	height: 14px;
// 	fill: var(--text500);
// 	opacity: 0.5;
// }