
// DIVIDER
.projectsPage-backlogProjectDateDivider{
	font-size: 14px;	
	font-weight: $lato-regular;
	letter-spacing: 0.01em;

	
	text-align: left;

	color: var(--text600);
	opacity: 0.5;

	margin-top: 14px;
	margin-bottom: 6px;

	user-select: none;
	cursor: default;

	padding-left: 3px;
}

// PROJECT

.projectsPage-backlogProject{
	width: 100%;
	height: 38px;
	// background: $tempb;
	// outline: 1px solid blue;

	display: flex;
	align-items: center;

	box-sizing: border-box;

	cursor: pointer;

	border-radius: 5px;

	padding-left: 8px;
	margin-left: -8px;
	width: calc(100% + 8px);

	border: 1px solid $invisible;

}

.projectsPage-backlogProject:hover{
	background: var(--253gray);
	border: 1px solid var(--line100);
}

.projectsPage-backlogProject-emoji{
	font-size: 18px;
	
	height: 28px;
	width: 28px;

	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--253gray);

	border-radius: 4px;
}

.projectsPage-backlogProject-name{
	font-size: 15.75px;
	font-weight: $lato-bold;
	letter-spacing: 0.001em;
	color: var(--text500);

	margin-right: auto;
}

.projectsPage-backlogProject-teamTag{
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 2px;
	padding-bottom: 2px;

	font-size: 11.5px;
	font-weight: $lato-medium;
	color: var(--text300);
	letter-spacing: 0.025em;

	text-transform: uppercase;

	//background: var(--bgSideMenuTabLight);
	border: 1px solid var(--line200);
	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 4px;
}

.projectsPage-backlogProject-teamTag{
	opacity: 0.65;
	transition: opacity 50ms linear;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-teamTag{
	opacity: 1;
	transition: opacity 150ms linear;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-teamTag--team1{
	transition-delay: 50ms;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-teamTag--team2{
	transition-delay: 100ms;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-teamTag--team3{
	transition-delay: 150ms;
}


.projectsPage-backlogProject-leadAvatarContainer{
	width: 26px;
	height: 26px;

	margin-left: 12px;

	border-radius: 4px;
	overflow: hidden;
}

img.selectUserPopover-item-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	
}

.projectsPage-backlogProject-dropdownBtn{
	height: 24px;
	width: 24px;

	margin-left: 6px;

	border-radius: 4px;

	margin-right: 4px;
}

.projectsPage-backlogProject-dropdownBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text600);
	opacity: 0.5;
}

.projectsPage-backlogProject
.projectsPage-backlogProject-dropdownBtn{
	opacity: 0;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-dropdownBtn{
	opacity: 1;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-dropdownBtn svg{
	opacity: 0.5;
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-dropdownBtn:hover{
	background: var(--250gray);	
}

.projectsPage-backlogProject:hover
.projectsPage-backlogProject-dropdownBtn:hover svg{
	opacity: 1;
}
