$whiteboardModal-sideMargin: 20px;
$whiteboardModal-topMargin: 34px;
$whiteboardModal-bottomMargin: 20px;

.whiteboardModalContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	z-index: 50;

	background: var(--bg-zoomedMediaModals);

	// padding-top: 10px;
	// padding-left: 10px;
	// padding-right: 10px;
	// padding-bottom: 10px;

	overflow-y: hidden;
	overflow-x: hidden;
}


.docEditor-showWhiteboardModalButton{
	position: fixed;
	bottom: 80px;
	right: 500px;
	z-index: 45;
	height: 20px;
	width: 200px;
	background: $red;
}

.whiteboardModalContainer:focus{
	outline: none;
}

// .doc-whiteboardModalModal-captureOutsideClick{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;
// 	background: $invisible;
// }


.whiteboardModal{
	margin-top: 10px;
	width: calc(100vw - #{$whiteboardModal-sideMargin} - #{$whiteboardModal-sideMargin});
	height: calc(100vh - #{$whiteboardModal-topMargin} - #{$whiteboardModal-bottomMargin});
	max-width: 2400px;
	//max-height: 1200px;
	
	background: rgb(252, 249, 252);

	border-radius: 4px;
	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 8px 16px 0px rgba(0,0,0,0.12),
	;

	display: flex;
	flex-direction: column;

	position: relative;
	z-index: 50;

	overflow: hidden;
}

.whiteboardModal:focus{
	outline: none;
}

.whiteboardContainer
.tl-background{
	background: var(--background-whiteboard);
}


.whiteboardContainer-loading{
	position: absolute;
	top: 0px;
	left: 0px;	
	width: 100%;
	height: 100%;

	z-index: 1000;
	// background: blue;
	background: var(--background-whiteboard)
}




//

.whiteboardContainer{
	position: relative;
	width: 100%;
	height: 100%;
}

$innerMargin-whiteboard: 8px;

.whiteboard-header,
.whiteboard-toolbarContainer,
.whiteboard-zoomControls,
.whiteboard-multiplayer,
.whiteboardContainer-topRight{
	position: absolute;

	z-index: 2000;
}

.whiteboard-header{
	top: $innerMargin-whiteboard; 
	left: $innerMargin-whiteboard; 
}

.whiteboard-toolbarContainer{
	left: 50%;
	bottom: $innerMargin-whiteboard;
	transform: translateX(-50%);
	// margin-bottom: -2px;
	// margin-bottom: -6px; // not sure why needed
}

.whiteboard-multiplayer{
	left: $innerMargin-whiteboard;
	bottom: $innerMargin-whiteboard;
}

.whiteboard-zoomControls{
	right: $innerMargin-whiteboard;
	//bottom: $innerMargin-whiteboard;
	top: $innerMargin-whiteboard;

	margin-right: 40px;
	
	// display: none;
	//bottom: calc(44px + #{$innerMargin-whiteboard});


}

//
//




.whiteboard-zoomControls-resetZoomBtn{
	
	height: 30px;
	width: 100px;

	border-radius: 4px;

	position: relative;
	cursor: pointer;
}

.whiteboard-zoomControls-resetZoomBtn-span{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	user-select: none;
	font-size: 15px;
	letter-spacing: 0.012em;
	font-weight: $lato-semibold;
	color: var(--text400);

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	padding-left: 9px;
	padding-right: 9px;
}

.whiteboard-zoomControls-resetZoomBtn-span--tooltip{
	justify-content: center;
}

.whiteboard-zoomControls-resetZoomBtn
.whiteboard-zoomControls-resetZoomBtn-span--counter{
	opacity: 1;
}

.whiteboard-zoomControls-resetZoomBtn
.whiteboard-zoomControls-resetZoomBtn-span--tooltip{
	opacity: 0;
}

.whiteboard-zoomControls-resetZoomBtn--not100:hover
.whiteboard-zoomControls-resetZoomBtn-span--counter{
	opacity: 0;	
}

.whiteboard-zoomControls-resetZoomBtn--not100:hover
.whiteboard-zoomControls-resetZoomBtn-span--tooltip{
	opacity: 1;
}

.whiteboard-zoomControls-resetZoomBtn--not100:hover{
	background: var(--250gray);
}

.whiteboard-zoomControls-resetZoomBtn--100{
	opacity: 0.5;
}


// .whiteboard-zoomControls-resetZoomBtn{
// 	opacity: 0.6;
// }



// .whiteboard-zoomControls-resetZoomBtn:hover{
// 	opacity: 1;
// }



//

.whiteboard-zoomControls-openKeyboardShortcutPanelBtn{
	width: 30px;
	height: 30px;
	border-radius: 3px;

	margin-left: 4px;
}

.whiteboard-zoomControls-openKeyboardShortcutPanelBtn svg{
	width: 19px;
	height: 19px;
	fill: var(--text500);
	opacity: 0.7;
	position: relative;
	top: 1px;
}

.whiteboard-zoomControls-openKeyboardShortcutPanelBtn:hover{
	background: var(--240gray);
}

.whiteboard-zoomControls-openKeyboardShortcutPanelBtn:hover svg{
	opacity: 0.9;
}


//

.whiteboardContainer-topRight{
	display: flex;
	top: 6px;
	right: 6px;
}

.whiteboardContainer-topRight-btn,
.whiteboard-header-dropdownBtn{
	width: 32px;
	height: 32px;
	border-radius: 4px;
}

.whiteboard-header-dropdownBtn{
	margin-left: -4px;
	margin-top: -4px;
}

.whiteboardContainer-topRight-btn{
	margin-left: 4px;
}


.whiteboardContainer-topRight-btn svg,
.whiteboard-header-dropdownBtn svg{
	fill: var(--text600);
	opacity: 0.7;
}

.whiteboardContainer-topRight-btn--newWindow svg{
	width: 19px;
	height: 19px;
	opacity: 0.6;
}

.whiteboardContainer-topRight-btn--close svg{
	width: 20px;
	height: 20px;
}

.whiteboard-header-dropdownBtn svg{
	width: 16px;
	height: 16px;
	opacity: 0.4;
}

.whiteboardContainer-topRight-btn--close{
	background: var(--246gray);
}

.whiteboardContainer-topRight-btn:hover,
.whiteboard-header-dropdownBtn:hover,
.whiteboard-header-dropdownBtn[data-state='open']{
	background: var(--240gray);
}

.whiteboardContainer-topRight-btn:hover svg{
	opacity: 1;
}





@import 'Whiteboard-Toolbar';
@import 'Whiteboard-CustomTLDraw';

//