.roadmapsHomePage-roadmapGrid{
	width: 100%;
	display: flex;
	flex-direction: column;

	border-top: 12px; 
	
	
	// height: 200px;
	//margin-top: 36px;
	// margin-top: -36px;
	// background: $tempb;
	
	margin-bottom: 34px;
	padding-bottom: 36px;

}

.roadmapsHomePage-roadmapGrid-header{
	width: 100%;
}

.roadmapsHomePage-roadmapGrid-header{
	margin-bottom: 0px;
	padding-bottom: 16px;

	display: flex;
	user-select: none;
	cursor: default;

	width: 100%;
}

.roadmapsHomePage-roadmapGrid-header-label{
	font-size: 26px;
	font-weight: $lato-heavy;
	color: var(--text600);
}

//

.roadmapsHomePage-roadmapGrid-header-viewSelect{
	margin-left: auto;
	display: flex;
	margin-right: -4px; // optical align on ellipses
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-spacer{
	width: 10px;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn{
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 4px;

	height: 32px;
	opacity: 0.5;
	margin-left: 0px;

	font-size: 15.5px;
	font-weight: $lato-regular;
	color: var(--text600);

	text-transform: capitalize;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--iconOnly{
	padding-left: 0px;
	padding-right: 0px;
	width: 32px;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--iconOnly svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.7;
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--started{
	background: rgb(246,160,42);
	background: rgb(255,170,112);
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--planned{
	background: rgb(190,185,198);
	background: rgb(190,190,200);
}

.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--backlog{
	background: hsl(358, 83%, 73%);
	background: rgb(255,189,197);
}



.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--completed{
	background: hsl(233, 92%, 65%);
	background: rgb(85,104,249);
}


[data-theme="dark"]{
	.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--started{
		//background: #d58d5c;
	}
	.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--completed{
		//background: #505bb7;
	}
	.roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--backlog{
		// background: #9d6969;
		//background: #af838391;
		opacity: 0.7;
	}

}





.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive{
	opacity: 0.5;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive:hover{
	opacity: 0.7;
	background: var(--253gray);
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive:hover svg{
	opacity: 0.9;
}


.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active{
	background: var(--253gray);
	opacity: 1;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active:hover{
	background: var(--246gray);
	opacity: 1;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon{
	display: flex;
	flex-wrap: wrap;

	width: 18px;
	margin-right: 3px;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square{
	width: 3px;
	height: 3px;
	margin: 0.5px;

	background: var(--text600);
	opacity: 0.6;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square:nth-child(1){
	opacity: 0;
}

.roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square:nth-child(12){
	opacity: 0;
}





// .roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--completed.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active{
// 	background: transparentize(rgb(85,104,249),0.9);
// 	color: darken(rgb(85,104,249),15%);
// }

// .roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--started.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active{	
// 	background: transparentize(rgb(255,170,112),0.9);
// 	color: darken(rgb(255,170,112),25%);
// }

// .roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--planned.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active{
// 	background: var(--253gray);
// }

// .roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--backlog.roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active{
// 	background: transparentize(rgb(255,189,197),0.9);
// 	color: darken(rgb(255,189,197),25%);
// }

//
//

.roadmapsHomePage-roadmapGrid-grid{
	// width: calc(100% + 50px);
	// margin-left: -25px;
	width: calc(100% + 60px);
	margin-left: -30px;

	display: grid;
  grid-gap: 18px;

	
}

.roadmapsHomePage-roadmapGrid-grid--projectStatus{
	grid-template-columns: repeat(auto-fill,minmax(290px,1fr));
}

.roadmapsHomePage-roadmapGrid-grid--activity{
	grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
	grid-gap: 12px;
}


@import 'Roadmaps-HomePage-RoadmapGrid-Item';

