//
//

.message-openThreadBtnContainer{
	width: 100%;
	// background: $tempr;

	box-sizing: border-box;
	padding-left: 34px;
	margin-top: 4px;
}

// HIDE IF IN THREAD

.messageContainer:nth-last-child(2)
.message-openThreadBtnContainer{
	display: none;
}


//

.message-openThreadBtn{
	height: 28px;
	//background: var(--messageThreadBtnBG);
	border-radius: 5px;

	display: flex;
	align-items: center;

	padding-left: 6px;
	padding-right: 6px;

	border: 1px solid $invisible;
}

.message-openThreadBtn--hasUnreads{
	//background: transparentize(rgb(224,29,90), 0.95);
}

.message-openThreadBtn:hover{
	background: var(--messageThreadBtnHoverBG);
	border: 1px solid var(--messageThreadBtnHoverBorder);
}


.message-openThreadBtn-unreadMarkerContainer{
	width: 17px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.message-openThreadBtn-unreadMarker{
	height: 8px;
	width: 8px;
	margin-left: 1px;
	border-radius: 26px;
	background: var(--unreadCountTextColor);
	opacity: 0.75;
}
//

.message-openThreadBtn-avatarRow{
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	
	margin-right: 8px;
}

.message-openThreadBtn-avatarContainer{
	width: 20px;
	height: 20px;
	margin-right: 3px;
}

.message-openThreadBtn-avatarContainer:last-child{
	margin-right: 0px;
}

.message-openThreadBtn-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 3px;
}

//

.message-openThreadBtn-labelRow{

	display: flex;
	align-items: baseline;
	box-sizing: border-box;
	
}



.message-openThreadBtn-messageCount,
.message-openThreadBtn-unreadCount{
	font-size: 13px;
	font-weight: $lato-medium;
	color: var(--text600);	
	letter-spacing: 0.008em;

	margin-right: 5px;
}

.message-openThreadBtn-unreadCount{
	font-weight: $lato-semibold;
	//color: var(--unreadCountTextColor);
}

.message-openThreadBtn--newThreadBtn
.message-openThreadBtn-messageCount{
	margin-left: -8px;
	// font-weight: $lato-regular;
}



.message-openThreadBtn-timestampLabel{
	font-size: 12.25px;
	font-weight: $lato-regular;
	color: var(--text300);
	opacity: 0.7;
}

// .message-openThreadBtn--hasUnreads
// .message-openThreadBtn-timestampLabel{
// 	//color: var(--unreadCountTextColor);
// 	opacity: 0.7;
// }

.message-openThreadBtn-endIconContainer{
	height: 100%;
	display: flex;
	align-items: center;

	margin-left: 7px;
	margin-left: 16px;
}

.message-openThreadBtn-endIconContainer svg{
	width: 13px;
	height: 13px;
	fill: var(--text600);
	opacity: 0.25;
	margin-top: 1px;
}

.message-openThreadBtn:hover 
.message-openThreadBtn-endIconContainer svg{
	opacity: 0.5;
}

// .message-openThreadBtn--hasUnreads
// .message-openThreadBtn-endIconContainer svg{
// 	//fill: var(--unreadCountTextColor);
// 	opacity: 0.5;
// }


@media screen and (max-width: 1360px){
	.message-openThreadBtn-timestampLabel{		
		display: none;
	}	
}
