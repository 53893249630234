

.linearProjectStatusIcon{
	width: 13px;
	height: 13px;

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

// .linearProjectStatusIcon--inline{
// 	display: inline;
// 	align-items: unset;
// 	justify-content: unset;
// }

//

.linearProjectStatusIcon--backlog{
	border: 1.5px dashed var(--projectBacklog-red);
	// background: var(--bg-docEditor);
}

//

.linearProjectStatusIcon--planned{
	border: 1.5px solid var(--projectPlanned-grey);
	background: var(--bg-docEditor);
}

//

.linearProjectStatusIcon--started{	
	border: 1.5px solid var(--projectStarted-orange);
	
	background: var(--bg-docEditor);

}

.linearProjectStatusIcon--started-dot{
	width: 6px;
	height: 6px;

	border-radius: 50%;
	background: var(--yellow--yarn);
	
	background: #ff9606;
	background: var(--projectStarted-orange);
	
}

//

.linearProjectStatusIcon--completed{
	border: 1.5px solid var(--projectCompleted-purple);
	background: var(--projectCompleted-purple);
	position: relative;
}

.linearProjectStatusIcon--completed svg {
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
	stroke: var(--bg-docEditor);
	stroke-width: 10px;
	position: relative;
	top: 0.25px;
}

//  maybe add back later, it's tricky with different sized bits
// .linearProjectStatusIcon--completed--innerBorder{
// 	position: absolute;
// 	top: 1.5px;
// 	left: 1.5px;
// 	right: 1.5px;
// 	bottom: 1.5px;
	
// 	border-radius: 3px;
// 	outline: 1.5px solid rgba(0,0,0,0.05);
// }


//


.linearProjectStatusIcon--canceled{
	background: var(--projectCanceled-grey);
	border: 1.5px solid var(--projectCanceled-grey);
	position: relative;
}

.linearProjectStatusIcon--canceled svg{
	// width: 12px;
	// height: 12px;
	width: 10px;
	height: 10px;
	fill: var(--bg-docEditor);
}

// maybe add back later, it's tricky with different sized bits
// .linearProjectStatusIcon--canceled--innerBorder{
// 	position: absolute;
// 	top: 1.5px;
// 	left: 1.5px;
// 	right: 1.5px;
// 	bottom: 1.5px;
	

// 	border-radius: 50%;
// 	outline: 1.5px solid rgba(0,0,0,0.05);
// }

//

.linearProjectStatusIcon--paused{
	background: var(--projectPaused-grey);
	border: 1.5px solid var(--projectPaused-grey);
	position: relative;
}

.linearProjectStatusIcon--paused svg{
	// width: 12px;
	// height: 12px;
	width: 10px;
	height: 10px;
	fill: var(--bg-docEditor);
}

// maybe add back later, it's tricky with different sized bits
// .linearProjectStatusIcon--paused--innerBorder{
// 	position: absolute;
// 	top: 1.5px;
// 	left: 1.5px;
// 	right: 1.5px;
// 	bottom: 1.5px;
	

// 	border-radius: 50%;
// 	outline: 1.5px solid rgba(0,0,0,0.05);
// }