.loadingPage{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.loadingPage-content{
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	z-index: 5;
}

.loadingPage-bar{
	width: 300px;
	height: 6px;

	border-radius: 10px;

	position: relative;

	background: $tempr;
	background: rgba(0,0,0,0.08);
	background: var(--bgSideMenuTabActive);
	// background: var(--250gray);
	
	//background: hsla(213, 100%, 50%,0.15);

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.loadingPage-bar-ball{
	width: 120px;
	height: 6px;
	border-radius: 4px;

	background: $tempr;
	background: var(--125gray);	
	//background: var(--primary500);
}

.loadingPage-label{
	margin-top: 32px;

	font-size: 16px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: var(--text500);
	opacity: 0.7;

	position: absolute;
	bottom: 80px;

	left: 50%;
	transform: translateX(-50%);

	opacity: 0;
}

.loadingPage{

}

:root {
  --loadingGradient-1: 	rgb(250,250,255);
  --loadingGradient-2: 	hsl(213, 100%, 45%);
  --loadingGradient-3: 	hsl(213, 100%, 45%);
  --loadingGradient-4:  rgb(250,250,255);
  --blur: blur(20vw);
}

// body {
//   display: grid;
//   place-items: center;
//   height: 100vh;
//   margin: 0;
//   padding: 0;
// }

.loadingPage-gridContainer {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;
  display: flex;
  background: var(--loadingGradient-2);
  flex-wrap: wrap;
  overflow: hidden;

  opacity: 0;
}

.loadingPage-gradientColor {
  width: 50%;
  height: 50%;
  display: flex;
  border-radius: 30% 80% 75% 40% / 40% 40% 70% 50%;
  animation-name: animate;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animate {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 60%;
    transform: scale(2) rotate(0deg) translate(10%, 10%);
  }
  100% {
    border-radius: 88% 10% 22% 58% / 73% 56% 34% 77%;
    transform: scale(2) rotate(180deg) translate(10%, -10%);
  }
}

.loadingPage-gradientColor:nth-child(1) {
  background-color: var(--loadingGradient-1);
  animation-direction: backwards;
}

.loadingPage-gradientColor:nth-child(2) {
  background-color: var(--loadingGradient-2);
}

.loadingPage-gradientColor:nth-child(3) {
  background-color: var(--loadingGradient-3);
  animation-direction: backwards;
}

.loadingPage-gradientColor:nth-child(4) {
  background-color: var(--loadingGradient-4);
}

.loadingPage-gradientBackdrop {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  backdrop-filter: var(--blur);
}