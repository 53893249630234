//
// COLOR STATE

// Private Draft
.stageIcon--stage--privateDraft #dot,
.stageIcon--stage--privateDraft #border{
	fill: rgb(150,138,144);
}

.stageIcon--stage--privateDraft #background{
	fill: $white;
}

// Draft
.stageIcon--stage--draft #icon,
.stageIcon--stage--draftAlt #icon,
.stageIcon--stage--draftAlt #border{
	fill: rgb(250,167,51);
}

.stageIcon--stage--draft #background,
.stageIcon--stage--draftAlt #background{
	fill: $white;
}

// Archive
.stageIcon--stage--archived #icon{
	fill: $white;
}

.stageIcon--stage--archived #circle{
	fill: rgb(202,199,192);
}

// Active
.stageIcon--stage--active #circle{
	fill: rgb(51,201,157);
	fill: var(--primary500);
	fill: $green;
}

.stageIcon--stage--active #icon{
	fill: $white;
}

// Paused
.stageIcon--stage--paused #icon{
	fill: $white;
}

.stageIcon--stage--paused #circle{
	fill: rgb(181,120,221);
}

// Completed
.stageIcon--stage--completed #circle{
	fill: rgb(40,38,54);
	fill: $green;
}

.stageIcon--stage--completed #icon{
	fill: $white;
}

// Pass
.stageIcon--stage--pass #circle{
	fill: rgb(108,195,234);
}

.stageIcon--stage--pass #icon{
	fill: $white;
}

// Present
.stageIcon--stage--present #circle{
	fill: var(--primary500);
}

.stageIcon--stage--present #icon{
	fill: $white;
}



//
// GRAYSCALE STATE

$stageIcon-grayscale-gray: rgb(200,200,200);


.stageIcon--colorMode--grayScale.stageIcon--stage--privateDraft #dot,
.stageIcon--colorMode--grayScale.stageIcon--stage--privateDraft #border,
.stageIcon--colorMode--grayScale.stageIcon--stage--draft #icon,
.stageIcon--colorMode--grayScale.stageIcon--stage--draftAlt #icon,
.stageIcon--colorMode--grayScale.stageIcon--stage--draftAlt #border,
.stageIcon--colorMode--grayScale.stageIcon--stage--active #circle,
.stageIcon--colorMode--grayScale.stageIcon--stage--completed #circle,
.stageIcon--colorMode--grayScale.stageIcon--stage--archive #circle,
.stageIcon--colorMode--grayScale.stageIcon--stage--paused #circle,
.stageIcon--colorMode--grayScale.stageIcon--stage--pass #circle,
.stageIcon--colorMode--grayScale.stageIcon--stage--present #circle{
	fill: $stageIcon-grayscale-gray;
}



//
// DARK PARENT

.darkTest{
	background: rgb(40,38,54);
	padding: 20px;
}

.stageIcon--colorMode--darkParent.stageIcon--stage--privateDraft #dot,
.stageIcon--colorMode--darkParent.stageIcon--stage--privateDraft #border{
	fill: rgb(245,245,245);
}

.stageIcon--colorMode--darkParent.stageIcon--stage--draftAlt #background{
	fill: $invisible;	
}


.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--draft #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--active #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--completed #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--archived #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--paused #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--pass #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--present #circle{
	fill: $stageIcon-grayscale-gray;
}

.stageIcon--colorMode--darkParent.stageIcon--stage--privateDraft #background,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--completed #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--active #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--archived #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--paused #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--pass #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--grayScale.stageIcon--stage--present #icon{
	fill: $invisible;
}


.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--privateDraft #dot,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--privateDraft #border,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--draft #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--active #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--completed #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--archived #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--paused #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--pass #circle,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--present #circle{
	fill: $white;
}

.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--privateDraft #background,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--completed #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--active #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--archived #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--paused #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--pass #icon,
.stageIcon--colorMode--darkParent.stageIcon--colorMode--white.stageIcon--stage--present #icon{
	fill: $invisible;
}

