
// BG OPTIONS
// background: rgb(217,216,216);

:root{		
	--bg-docEditor: hsl(240,0%,100%);
	--bg-channelFrame: hsl(240,0%,100%);

	--bg-dialog: rgb(255,255,255);

	//--bg-zoomedMediaModals: rgba(1,0,2,0.88);	
	--bg-zoomedMediaModals: rgba(8,7,9,0.90);	
	
}

[data-theme="dark"], .forceDarkTheme{
	//--bg-docEditor: hsl(250,5%,8%);
	//--bg-channelFrame: hsl(250,5%,8%);

	// purplee
	//--bg-docEditor: rgb(20,18,22);
	--bg-docEditor: rgb(28,26,32); // need to manually update in some places if change
	--bg-channelFrame: rgb(28,26,32);
}