.docEditor-summarizerProtoContainer{
	width: 100%;
	//background: $tempr;
	padding-bottom: 10px;
}

.docEditor-summarizerProto{
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 8px;
	padding-bottom: 8px;

	//background: $tempr;

	border-radius: 5px;
}

.docEditor-summarizerProto--minimized{
	background: var(--bgSideMenuTabLight);
}

.docEditor-summarizerProto--minimized.docEditor-summarizerProto--minimized--noText{
	background: none;
	margin-left: -12px;
}

.docEditor-summarizerProto--minimized{
	opacity: 0.75;
}

.docEditor-summarizerProto--minimized:hover{
	background: var(--bgSideMenuTabHover);
	opacity: 1;
}

.docEditor-summarizerProto-headerContainer{
	width: 100%;
	height: 18px;
	position: relative;
	cursor: pointer;
}

.docEditor-summarizerProto-header{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;

	user-select: none;
}

.docEditor-summarizerProto-header-label{
	color: var(--text500);

	font-size: 12.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.025em;	
}

.docEditor-summarizerProto-header-iconContainer{
	height: 100%;
	width: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 6px;
}

.docEditor-summarizerProto-header-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
}


.docEditor-summarizerProto-header--hover{
	opacity: 0;
}

.docEditor-summarizerProto-header--static,
.docEditor-summarizerProto-header--noHover{
	opacity: 0.5;
}

.docEditor-summarizerProto-headerContainer:hover
.docEditor-summarizerProto-header--hover{
	opacity: 1;
}

.docEditor-summarizerProto-headerContainer:hover
.docEditor-summarizerProto-header--noHover{
	opacity: 0;
}


.docEditor-summarizerProto-textContainer{
	margin-top: 6px;

	font-size: 16.75px;	
	// font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.45;

	box-sizing: border-box;

	font-size: 15.75px;
	line-height: 1.35;

	cursor: text;

}

//

.docEditor-summarizerProto--expanded{	
	background: #0B2630;
	padding-bottom: 18px;
}

.docEditor-summarizerProto--expanded *{
	color: $white !important;
}

.docEditor-summarizerProto--expanded
.docEditor-summarizerProto-header-iconContainer svg{
	fill: white;
}

.docEditor-summarizerProto-divider{
	width: 100%;
	margin-top: 24px;
	margin-bottom: 24px;
	height: 1px;
	opacity: 0.2;
	background: $white;
}

.docEditor-summarizerProto-settings{
	display: flex;
	width: 100%;
}

.docEditor-summarizerProto-settings-promptInput{
	flex-grow: 1;
	flex-shrink: 1;
	height: 120px;
	
}

.docEditor-summarizerProto-settings-promptInput-title{
	font-size: 13px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.04em;		

	margin-bottom: 8px;
}

.docEditor-summarizerProto-settings-parameterContainer-header{
	display: flex;
	align-items: baseline;

	font-size: 13px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.04em;		

	margin-bottom: 12px;
}

.docEditor-summarizerProto-settings-parameterContainer-header-reset{
	margin-left: auto;
	text-transform: uppercase;
	opacity: 0.3;

	font-size: 11px;
	letter-spacing: 0.04em;
}

.docEditor-summarizerProto-settings-parameterContainer-header-reset:hover{
	opacity: 1;
}


textarea.docEditor-summarizerProto-settings-promptInput-input{
	width: 100%;
	height: 230px;

	resize: none;

	background: rgba(255,255,255,0.12);
	border: 1px solid rgba(255,255,255,0.25);

	border-radius: 2px;

	box-sizing: border box;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 8px;
	padding-bottom: 8px;

		font-size: 16.75px;	
	// font-size: 17.25px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.45;

	box-sizing: border-box;

	font-size: 15.75px;
	line-height: 1.35;

	opacity: 0.5;
	transition: opacity 50ms linear;
}

textarea.docEditor-summarizerProto-settings-promptInput-input:hover{
	opacity: 0.7;
}

textarea.docEditor-summarizerProto-settings-promptInput-input:focus{
	opacity: 1;

	outline: none;
	// background: rgba(255,255,255,0.12);
	// border: 1px solid rgba(255,255,255,0.5);
}


.docEditor-summarizerProto-settings-parameterContainer{
	width: 240px;
	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 24px;
}

.docEditor-summarizerProto-settings-parameter{
	margin-bottom: 4px;
}

.docEditor-summarizerProto-settings-parameter-header{
	display: flex;
	opacity: 0.7;
	font-size: 12px;
	transition: opacity 50ms linear;
}

.docEditor-summarizerProto-settings-parameter--adjustingParameter
.docEditor-summarizerProto-settings-parameter-header,
.docEditor-summarizerProto-settings-parameter:hover 
.docEditor-summarizerProto-settings-parameter-header{
	opacity: 1;
}

.docEditor-summarizerProto-settings-parameter-header-label{
	
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;	

	user-select: none;
	cursor: default;
}

.docEditor-summarizerProto-settings-parameter-header-value{
	margin-left: auto;	
	font-weight: $lato-semibold;
	cursor: text;
}

.docEditor-summarizerProto-settings-parameter-sliderContainer{
	height: 20px;
	// background: $tempr;
	width: 100%;
	position: relative;
}


.docEditor-summarizerProto-settings-parameter-sliderContainer{
	opacity: 0.5;
	transition: opacity 100ms linear;
	cursor: pointer;
}

.docEditor-summarizerProto-settings-parameter--adjustingParameter
.docEditor-summarizerProto-settings-parameter-sliderContainer,
.docEditor-summarizerProto-settings-parameter:hover 
.docEditor-summarizerProto-settings-parameter-sliderContainer{
	opacity: 1;
	transition: opacity 50ms linear;
}



.docEditor-summarizerProto-settings-parameter-slider{
	height: 100%;
	margin-top: 1px;
	// background: $green;
}

.docEditor-summarizerProto-settings-parameter-slider-track{
	height: 2px;
	margin-top: 7px;
}

.docEditor-summarizerProto-settings-parameter-slider-track-0{
	background: white;	
	border-radius: 2px;
}

.docEditor-summarizerProto-settings-parameter-slider-track-1{	
	border-radius: 2px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background: rgb(86,99,114);
}

.docEditor-summarizerProto-settings-parameter-slider-thumb{
	width: 9px;
	height: 9px;
	background: $white;
	border-radius: 6px;
	
	margin-top: 4px;



	box-shadow: 		
		0px 0px 0px 2px transparentize(#0B2630, 0.5),
	;	

	// opacity: 0;	
	// transition: opacity 150ms linear;
	// transition-delay: 200ms;
}

.docEditor-summarizerProto-settings-parameter-slider-thumb:focus{
	outline: none;
	border: none;
}


.doc-video-inlineUI-controlBar-controlBar-thumb-thumb{

}


//
//

.docEditor-summarizerProto-regenButton{
	width: 100%;
	border-radius: 6px;
	background: rgba(255,255,255,0.08);
	border: 1px solid rgba(255,255,255,0.15);
	color: $white;
	height: 72px;

	margin-top: 22px;

	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.1em;	

	font-size: 17px;
}


@keyframes regenCompute { 
  0% { opacity: 0.25; }
  100% { opacity: 0.5; } 
}

.docEditor-summarizerProto-regenButton--computing{
	animation: regenCompute 1s infinite alternate-reverse;
	background: rgba(255,255,255,0.08);
	border: 1px solid rgba(255,255,255,0.08);
}
