@import 'Doc-Comments-Selection';



// COMMENTS BAR

.docEditor-commentsPanelOuterContainer{
	position: absolute;
	top: 0px;
	right: 0px;

	//background: $tempb;
}


.docEditor-commentsPanelOuterContainer--ghost{
	z-index: 1000;
	
	//background: $tempr;
	pointer-events: none;
}

.docEditor-commentsPanelOuterContainer--ghost,
.docEditor-commentsPanelOuterContainer--ghost * {
	pointer-events: none !important;
	background: lighten(red, 30%) !important;
}


.docEditor-commentsPanelOuterContainer--ghost{
	visibility: hidden;
}

.docEditor-commentsPanel--devStyling
.docEditor-commentsPanelOuterContainer--ghost{
	visibility: visible;
}


.docEditor-commentsPanelInnerContainer{
	max-width: $maxWidth-comments;

	box-sizing: border-box;
	//padding-right: calc(#{$interiorRightMarginToMenus-docEditor} * 2);
	padding-right: calc(#{$interiorRightMarginToMenus-docEditor} * 3);
	padding-left: $interiorLeftMarginToMenus-docEditor;

}




.docEditor-commentsPanelInnerContainer--ghost{
	position: relative;
	left: 600px;
}

.docEditor-commentsPanel{
	width: 100%;
	position: relative;
	box-sizing: border-box;
}



.docEditor-commentsPanel-commentGroup{
	position: absolute;
	left: 0px;
	right: 36px; // inset from right, should be fancier
	right: 0px;
	
	margin-top: -10px; // to account for top-padding on first comment
	z-index: 10;

	padding-bottom: 24px; // margin between comments
}

.docEditor-commentsPanel-commentGroup--active{	
	position: relative;
	z-index: 20;
	//margin-left: -10px;
}

.docEditor-commentsPanel--devStyling
.docEditor-commentsPanel-commentGroup--active{
	outline: 2px solid blue;
}

.docEditor-commentsPanelInnerContainer--ghost--hasActiveComment
.docEditor-commentsPanel-commentGroup{
	visibility: hidden;
}

.docEditor-commentsPanel--devStyling
.docEditor-commentsPanelInnerContainer--ghost--hasActiveComment
.docEditor-commentsPanel-commentGroup--active{
	visibility: visible;
}


.docEditor-commentsPanel-commentGroup--new{
	z-index: 20;	
}
//
//

.docEditor-commentsPanel-commentGroup-comment{
	box-sizing: border-box;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 9px;
	padding-bottom: 6px;

	// border-radius: 10px;
	// background: $tempr;

	// background: $tempr;

	background: var(--bg-docEditor);

	display: flex;
	position: relative;

	border: 1px solid $invisible;
}

.docEditor-commentsPanel-commentGroup-comment--static:hover{
	background: $lightGray-bg;
	background: rgb(250,248,250);
}

.docEditor-commentsPanel-commentGroup-comment--new{
	//border: 1px solid darken($lineMid, 5%);
	//border-bottom: 1px solid darken($lineMid, 10%);

	border: 1px solid var(--line375);
	border-bottom: 1px solid var(--line400);

	//box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);

	min-height: 150px;

	// padding-left: 12px;
	// padding-top: 10px;
	// padding-right: 12px;

	// border-radius: 8px;

	// // add 6px of padding
	width: calc(100% + 6px + 6px);
	margin-left: -6px;
	margin-left: -12px;

	padding-left: 16px;
	padding-right: 16px;

	padding-top: 16px;
	margin-top: -6px;
}

// FIRST IN THREAD NEEDS EXTRA PADDING 
.docEditor-commentsPanel-commentGroup-comment:first-child{
	padding-top: 14px;
}

//

.docEditor-commentsPanel-commentGroup-comment-leftContainer{
	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 8px;
}

.docEditor-commentsPanel-commentGroup-comment-avatarContainer{

	width: 34px;
	height: 34px;

	border-radius: 4px;
}

// .docEditor-commentsPanel-commentGroup-comment--static:first-child
// .docEditor-commentsPanel-commentGroup-comment-avatarContainer--color--blue{
// 	box-shadow: 
// 		0px 0px 0px 2px $white,
// 		0px 0px 0px 5px transparentize($accentColor-blue, 0.7);
// 	;
// }

// .docEditor-commentsPanel-commentGroup-comment--static:first-child
// .docEditor-commentsPanel-commentGroup-comment-avatarContainer--color--orange{
// 	box-shadow: 
// 		0px 0px 0px 2px $white,
// 		0px 0px 0px 5px transparentize($accentColor-orange, 0.6);
// 	;
// }

// .docEditor-commentsPanel-commentGroup-comment--new
// .docEditor-commentsPanel-commentGroup-comment-avatarContainer--color--blue{
// 	box-shadow: 
// 		0px 0px 0px 2px $white,
// 		0px 0px 0px 4px transparentize($accentColor-blue, 0);
// 	;
// }

// .docEditor-commentsPanel-commentGroup-comment--new
// .docEditor-commentsPanel-commentGroup-comment-avatarContainer--color--orange{
// 	box-shadow: 
// 		0px 0px 0px 2px $white,
// 		0px 0px 0px 4px transparentize($accentColor-orange, 0);
// 	;
// }



img.docEditor-commentsPanel-commentGroup-comment-avatar{
	width: 100%;
	height: 100%;

	border-radius: 4px;

	object-fit: cover;
}

//


.docEditor-commentsPanel-commentGroup-comment-rightContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;

}

// NEW COMMENT

.docEditor-commentsPanel-commentGroup-comment-header{
	display: flex;
	align-items: baseline;

	margin-top: -2px;
	margin-bottom: 1px;

	cursor: default;
	user-select: none;
}

.docEditor-commentsPanel-commentGroup-comment-header-name{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text500);
	margin-right: 6px;
}

.docEditor-commentsPanel-commentGroup-comment-header-discussionTag{
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12px;
	font-weight: $lato-semibold;

	letter-spacing: 0.01em;

	padding-left: 4px;
	padding-right: 4px;

	padding-top: 2px;
	padding-bottom: 2px;

	border-radius: 4px;

	margin-right: 6px;
	margin-left: -1px;

	position: relative;
	// top: -1px;

	user-select: none;
}

.docEditor-commentsPanel-commentGroup-comment-header-discussionTag--reply{
	color: darken(rgb(22,123,240), 5%);
	background: rgb(231,241,253);
}

.docEditor-commentsPanel-commentGroup-comment-header-discussionTag--talkingPoints{
	color: rgb(122,55,166);
	background: rgb(247,241,251);
}

// .docEditor-commentsPanel-commentGroup-comment-header-newbieTag{
// 	display: flex;

// 	background: transparentize($accentColor-purple, 0.85);

// 	align-items: center;
// 	justify-content: center;

// 	padding-left: 3px;
// 	padding-right: 3px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

// 	border-radius: 4px;
// 	margin-right: 5px;
// 	margin-left: -2px;

// 	position: relative;
// 	top: -1px;
// }
// .docEditor-commentsPanel-commentGroup-comment-header-newbieTag-iconContainer{
// 	display: none;
// }

// .docEditor-commentsPanel-commentGroup-comment-header-newbieTag-label{
// 	font-size: 12px;
// 	color: $white;
// 	//color: darken($blue, 10%);
// 	color: var(--accentPurple600);
// 	letter-spacing: 0.005em;
// 	font-weight: $lato-semibold;

// 	text-transform: lowercase;
// }

.docEditor-commentsPanel-commentGroup-comment-header-timestamp{
	font-size: 12px;
	font-weight: $lato-medium;
	color: var(--text200);
}

.docEditor-commentsPanel-commentGroup--active
.docEditor-commentsPanel-commentGroup-comment-header-timestamp{
	color: var(--text300);
}

.docEditor-commentsPanel-commentGroup-comment-content,
.docEditor-commentsPanel-commentGroup-comment-content p{
	font-weight: $lato-regular;
	font-size: 15.25px;
	color: var(--text500);
	letter-spacing: 0.003em;

	line-height: 1.4;

	cursor: default;
	overflow-wrap: break-word;

	white-space: pre-wrap;

}

.docEditor-commentsPanel-commentGroup-comment-content p{
	margin-block-start: 0px;
	margin-block-end: 0px;
}

// if one emoji, then boost size
.docEditor-commentsPanel-commentGroup-comment-content--singleEmojiSizeBoost p{
	font-size: 28px;
	margin-top: -4px;
	margin-bottom: -6px;
}


@import 'Doc-Comments-ReplyAndNewComment';
@import 'Doc-Comments-ReactionMenu';
@import 'Doc-Comments-InlineReaction';



//
// MINIMAL
//


.docEditor-commentsPanel-minimalCommentGroup{
	position: absolute;
	left: 0px;
	right: 36px; // inset from right, should be fancier
	right: 0px;
	
	//margin-top: -10px; // to account for top-padding on first comment
	z-index: 10;	

	//background: $tempr;
	display: flex;
	justify-content: flex-start;
}

.docEditor-commentsPanel-minimalCommentGroupBtn{
	height: 28px;

	border-radius: 4px;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	box-shadow: none;

	padding-right: 12px;
	padding-left: 12px;

	background: rgb(247,245,245);
	border: 1px solid $invisible;

	font-size: 14px;
	font-weight: $lato-bold;
	color: var(--text400);

	cursor: pointer;
}

.docEditor-commentsPanel-minimalCommentGroupBtn--activeComment{
	//background: white;
	//background: transparentize($blue, 0.92);
	background: var(--primary25); // imperfect match
	color: black;
	// border: 1px solid var(--line500);
}

.docEditor-commentsPanel-minimalCommentGroupBtn--activeComment
.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer svg{
	//fill: darken($blue, 5%);
	fill: var(--primary600);
}

.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer{
	margin-left: 4px;
	height: 100%;
	display: flex;
	align-items: center;
}

.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer svg{
	width: 16px;
	height: 16px;

	margin-top: 2px;

	fill: var(--text400);
	opacity: 0.8;
}

.docEditor-commentsPanel-minimalCommentGroupBtn--notActiveComment
.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer--outline{
	display: flex;
}

.docEditor-commentsPanel-minimalCommentGroupBtn--notActiveComment
.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer--fill{
	display: none;
}

.docEditor-commentsPanel-minimalCommentGroupBtn--activeComment
.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer--outline{
	display: none;
}

.docEditor-commentsPanel-minimalCommentGroupBtn--activeComment
.docEditor-commentsPanel-minimalCommentGroupBtn-iconContainer--fill{
	display: flex;
}

// MINIMAL POPOVER
.docEditor-commentsPanel-minimalCommentGroup-popover{
	width: 400px;
	
	background: $white;
	border: 1px solid var(--line375);
	border-radius: 6px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.06)
	;

	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;

	overflow-y: scroll;

	// transform: translateX(50%);
	// margin-left: -60px;	
}