// $width-figmaStatusIcon: 15px;
$width-figmaStatusIcon: 14px;
$borderWidth-figmaStatusIcon: 1.5px;

.figmaStatusIconContainer{
	width: 20px;
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}


// CONCEPT AND STARTED 

.figmaStatusIcon--concept,
.figmaStatusIcon--started{
	display: flex;
	align-items: center;
	justify-content: center;

	width: $width-figmaStatusIcon;
	height: $width-figmaStatusIcon;

	border-radius: 50%;
	
}

.figmaStatusIcon--concept svg,
.figmaStatusIcon--started svg{
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
}

.figmaStatusIcon--concept{	
	border: $borderWidth-figmaStatusIcon solid #C56CFF;
}

.figmaStatusIcon--concept svg{
	fill: #C56CFF;
}

//

.figmaStatusIcon--started{
	border: $borderWidth-figmaStatusIcon solid var(--yellow--yarn);
}

.figmaStatusIcon--started svg{
	fill: var(--yellow--yarn);
}

//
// PROPOSED

.figmaStatusIcon--proposed{
	display: flex;
	align-items: center;
	justify-content: center;

	width: calc(#{$width-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon});
	height: calc(#{$width-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon});

	border-radius: 50%;
	//background: var(--primary600);
	position: relative;
	background: var(--darkGrey--yarn);
}

.figmaStatusIcon--proposed svg{
	// width: 12px;
	// height: 12px;
	width: 14px;
	height: 14px;
	fill: var(--bg-docEditor);
	// fill: white;
}

.figmaStatusIcon--proposed--innerBorder{
	position: absolute;
	top: $borderWidth-figmaStatusIcon;
	left: $borderWidth-figmaStatusIcon;
	right: $borderWidth-figmaStatusIcon;
	bottom: $borderWidth-figmaStatusIcon;

	border-radius: 50%;
	outline: $borderWidth-figmaStatusIcon solid rgba(0,0,0,0.05);
}


// COMPLETED AKA DONE

.figmaStatusIcon--completed{
	display: flex;
	align-items: center;
	justify-content: center;

	width: calc(#{$width-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon});
	height: calc(#{$width-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon} + #{$borderWidth-figmaStatusIcon});

	border-radius: 50%;
	background: var(--purple--yarn);
	//background: var(--activeUserGreen);
	//background: #00a776;
	position: relative;
}

.figmaStatusIcon--completed svg{
	// width: 12px;
	// height: 12px;
	width: 11px;
	height: 11px;
	stroke: $white;
	//stroke: var(--bg-docEditor);
	stroke-width: 10px;
	position: relative;
	top: 0.25px;
}

.figmaStatusIcon--completed--innerBorder{
	position: absolute;
	top: $borderWidth-figmaStatusIcon;
	left: $borderWidth-figmaStatusIcon;
	right: $borderWidth-figmaStatusIcon;
	bottom: $borderWidth-figmaStatusIcon;
	

	border-radius: 50%;
	outline: $borderWidth-figmaStatusIcon solid rgba(0,0,0,0.05);
}

