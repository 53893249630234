$height-messagePanel-inputBox-btnRow: 40px;
$height-messagePanel-inputBox-outerInset: 8px;

.docEditor-messagePanel-inputBox{
	width: calc(100% - #{$height-messagePanel-inputBox-outerInset} - #{$height-messagePanel-inputBox-outerInset});
	margin-left: $height-messagePanel-inputBox-outerInset;

	box-sizing: border-box;

	position: relative;

	display: flex;
	flex-direction: column;

	border: 1px solid var(--line400);
	border-bottom: 1px solid var(--line500);

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);

	border-radius: 5px;

	min-height: $defaultHeight-messagePanel-inputBox;
	max-height: $maxHeight-messagePanel-inputBox;
}

.docEditor-messagePanel-inputBox-inputContainer{
	width: 100%;

	min-height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;

	// background: $tempb;
}

.docEditor-messagePanel-inputBox-input{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;	

	box-sizing: border-box;

	// background: $tempb;
}

.docEditor-messagePanel-inputBox-ghostSpacer{
	background: $tempb;
	width: 100%;
	position: relative;
	z-index: 0;

	// color: red !important;
	max-height: $maxHeight-messagePanel-inputBox;
	overflow-y: hidden;
	opacity: 0;

	// opacity: 0;
	pointer-events: none;
}

.docEditor-messagePanel-inputBox-ghostSelectionMeasurement{
	

	z-index: 50;
	background: $tempb;
	opacity: 0;

	pointer-events: none;
}

.docEditor-messagePanel-inputBox-ghostSelectionMeasurement * {
	color: blue !important;
}

// .docEditor-messagePanel-inputBox-ghostSpacer * {
// 	color: red !important;
// }



.docEditor-messagePanel-inputBox-input .ProseMirror{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 50;

	overflow-y: scroll;
}

.docEditor-messagePanel-inputBox-input .ProseMirror,
.docEditor-messagePanel-inputBox-ghostSpacer,
.docEditor-messagePanel-inputBox-placeholder{
	box-sizing: border-box;
	padding-left: 12px;
	padding-top: $defaultTopInternalPadding-messagePanel-inputBox;
	padding-right: 12px;
	padding-bottom: 8px;
}

// .docEditor-messagePanel-inputBox-input .ProseMirror .message-para{
// 	font-size: 15.5px;	
// }


.docEditor-messagePanel-inputBox-input .ProseMirror{
	outline: none !important;
}

//
// SELECTION PREVIEW

$height-imagePreview-messagePanelInputBox: 40px;

.docEditor-messagePanel-inputBox-inputBox-selectionPreview{
	width: 100%;
}


.docEditor-messagePanel-inputBox--selectionPreviewType--image
.docEditor-messagePanel-inputBox-inputBox-selectionPreview{	
	height: $height-imagePreview-messagePanelInputBox;
	
	background: $tempb;
}

.docEditor-messagePanel-inputBox--selectionPreviewType--video
.docEditor-messagePanel-inputBox-inputBox-selectionPreview{	
	height: $height-imagePreview-messagePanelInputBox;
	
	background: $tempb;
}

.docEditor-messagePanel-inputBox--selectionPreviewType--message
.docEditor-messagePanel-inputBox-inputBox-selectionPreview{	
	height: $height-imagePreview-messagePanelInputBox;
	
	background: $tempg;
}

//
// placeholder

.docEditor-messagePanel-inputBox-placeholder{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	
	height: 40px;

	z-index: 500;
	pointer-events: none;

	//background: $tempr;

	opacity: 0.5;
}



//
//

.docEditor-messagePanel-inputBox-btnRow{
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;

	position: relative;
	z-index: 5;

	width: 100%;

	// background: $tempr;

	height: $height-messagePanel-inputBox-btnRow;

	border-top: 1px solid var(--line300);

	box-sizing: border-box;

	padding-right: 2px;
	padding-left: 8px;


}

//
// INSERT POPOVER BUTTON

.message-inputBox-insertPopoverBtn{
	width: 28px;
	height: 28px;
	border-radius: 50%;

	background: var(--bgSideMenuTabLight);
}

.message-inputBox-insertPopoverBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.5;
}

.message-inputBox-insertPopoverBtn:hover svg{
	opacity: 0.75;
}

.message-inputBox-insertPopoverBtn:hover{
	background: var(--bgSideMenuTabActive);
}

//
// ADD MEDIA BUTTON
// Gif, Emoji, Screen Recording

.message-inputBox-addMediaBtn{
	width: 34px;
	height: 32px;
	
	//background: $tempr;
	border-radius: 5px;
}
	
.message-inputBox-addMediaBtn svg{	
	transform: scale(0.93);
}

.message-inputBox-addMediaBtn:hover{
	background: var(--bgSideMenuTabLight);
}

.message-inputBox-addMediaBtn--GIF{
	width: 40px;	
}

.message-inputBox-addMediaBtn--GIF svg{
	width: 32px;
	height: 32px;
}

.message-inputBox-addMediaBtn--GIF svg #bg{
	fill: var(--text500);
	opacity: 0.05;
}

.message-inputBox-addMediaBtn--GIF svg #label{
	fill: var(--text500);
	opacity: 0.8;
}

.message-inputBox-addMediaBtn--GIF:hover svg #bg{
	opacity: 0.1;
}

.message-inputBox-addMediaBtn--GIF:hover svg #label{
	opacity: 1;
}

//

.message-inputBox-addMediaBtn--emoji{
	width: 32px;
}

.message-inputBox-addMediaBtn--emoji svg{
	filter: grayscale(100%);
	height: 20px;
	width: 20px;
	opacity: 1;
}

.message-inputBox-addMediaBtn--emoji:not(:hover) svg circle{
	fill: var(--text500);
	opacity: 0.15;
}

.message-inputBox-addMediaBtn--emoji:hover svg circle{
	opacity: 1;
}


.message-inputBox-addMediaBtn--emoji:hover svg{
	filter: none;
	opacity: 1;
}


//
.message-inputBox-addMediaBtn--screenRecording{
	width: 36px;
}
.message-inputBox-addMediaBtn--screenRecording svg{
	width: 24px;
	height: 24px;
	position: relative;
	right: -1px;
}

.message-inputBox-addMediaBtn--screenRecording svg #screen{
	fill: var(--text500);
	opacity: 0.1;
}

.message-inputBox-addMediaBtn--screenRecording svg #outline{
	fill: var(--text500);
	opacity: 0.6;
}

.message-inputBox-addMediaBtn--screenRecording:hover #screen{
	opacity: 0.15;
}

.message-inputBox-addMediaBtn--screenRecording:hover svg #outline{
	opacity: 1;
}

.message-inputBox-addMediaBtn--codeBlock{
	width: 36px;
}
.message-inputBox-addMediaBtn--codeBlock svg{
	width: 20px;
	height: 20px;
	fill: var(--text500);
	opacity: 0.6;
	position: relative;
	top: -1px;
	left: -2px;
}


.message-inputBox-addMediaBtn--codeBlock:hover svg {
	opacity: 1;
}


//
// IMAGE WITH INPUT
.message-inputBox-addMediaBtn--image{
	position: relative;
	width: 40px;
	cursor: pointer;
	margin-left: -2px;
}

.message-inputBox-addMediaBtn--image label{
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.message-inputBox-addMediaBtn--image svg{
	width: 30px;
	height: 30px;	
	fill: var(--text500);
	opacity: 0.62;
}

.message-inputBox-addMediaBtn--image:hover svg{
	opacity: 1;
}

.message-inputBox-addMediaBtn--image
.message-inputBox-addMediaBtn--image-hiddenFileInput{
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
	

//
// SEND BUTTON 

.docEditor-messagePanel-inputBox-btnRow-sendBtn{
	width: 38px;
	height: 36px;
	border-radius: 5px;
	
	margin-left: auto;
	
	padding-left: 2px; // optical alignment
	position: relative;
}

.docEditor-messagePanel-inputBox-btnRow-sendBtn:hover{
	background: var(--bgSideMenuTabHover);
}

.docEditor-messagePanel-inputBox-btnRow-sendBtn svg{
	width: 18px;
	height: 18px;
	fill: var(--text400);

	opacity: 0.75;
}

.docEditor-messagePanel-inputBox-btnRow-sendBtn:hover svg{
	opacity: 1;	
}

//
// DIVIDER

.docEditor-messagePanel-inputBox-btnRow-dividerContainer{
	height: 100%;
	
	box-sizing: border-box;

	padding-top: 9px;
	padding-bottom: 9px;

	
}

.docEditor-messagePanel-inputBox-btnRow-dividerContainer--primary{
	margin-left: 10px;
	margin-right: 4px;
}

.docEditor-messagePanel-inputBox-btnRow-dividerContainer--secondary{
	margin-left: 4px;
	margin-right: 6px;
}

.docEditor-messagePanel-inputBox-btnRow-divider{
	width: 1px;
	height: 100%;

	background: var(--text400);
	opacity: 0.15;



}


//@import "Messages-InputBox-ReplyPreview"; // Bar above with a reply box
@import "Messages-InputBox-HintsBar"; // Hints bar below with typing indictor
@import "Messages-InputBox-InsertPopover"; // Main Insert Btn
@import "Messages-InputBox-AddMediaPopover"; // GIF and Emoji Popover
@import "Messages-InputBox-Refactor";


