.moveDocPopoverContainer{	
	
}

.moveDocPopoverContainer:focus{
	outline: none;
}

.moveDocPopoverContainer--hidden,
.moveDocPopoverContainer--hidden *{
	pointer-events: none !important;
}

.moveDocPopoverContainer.moveDocPopoverContainer--layout--topMenu
.moveDocPopover{
	margin-right: -100px; // when it was in code, then keyboard shortcut opening seemed to cause it to jump
	//if you can hide right panel in future, we'll have issues
}


//

.moveDocPopover{
	width: 360px;
	// min-height: 440px;
	// max-height: 80vh;

	// height: 400px;

	box-sizing: border-box;
	padding-top: 12px;
	//padding-bottom: 12px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 12px;
	padding-right: 12px;

	display: flex;
	flex-direction: column;
}

//

.moveDocPopover-search{
	width: 100%;
	height: 30px;
	position: relative;

	margin-bottom: 4px;

	flex-grow: 0;
	flex-shrink: 0;
}


.moveDocPopover-list{
	width: calc(100% + 10px);
	margin-left: -5px;

	flex-grow: 1;
	flex-shrink: 1;

	max-height: min(calc(80vh - 100px), 600px);

	padding-top: 8px;
	padding-bottom: 14px;

	overflow-y: scroll;
}

.moveDocPopover-list--empty{
	min-height: 400px;
	
}

.moveDocPopover-list--results{
	min-height: 240px;
}

.moveDocPopoverContainer.moveDocPopoverContainer--layout--sideMenu
.moveDocPopover-list{
	max-height: 400px;
}


//

.moveDocPopover-search-input{
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	padding-right: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	box-sizing: border-box;

	padding-left: 28px;

	position: absolute;
	border: none;
	border-radius: 4px;

	font-size: 15.25px;
	font-weight: $lato-semibold;
	color: var(--text600);

	border: 1px solid $invisible;
	background: var(--250gray);
}

.moveDocPopover-search-input::placeholder{
	color: var(--toDoItem-placeholderColor);
}

.moveDocPopover-search-input:focus{
	background: var(--bg-docEditor);
	border: 1px solid var(--primary400);
	outline: 2px solid var(--primary25);
}

[data-theme="dark"]{
	.moveDocPopover-search-input:focus{
		outline: none;
	}
}


.moveDocPopover-search-ui-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;

	width: 28px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	box-sizing: border-box;
	padding-left: 4px;
}

.moveDocPopover-search-ui-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--toDoItem-placeholderColor);
	opacity: 0.8;
}

//



.moveDocPopover-list-groupDivider{
	width: calc(100% - 13px);
	// width: 100%;
	margin-left: 10px;
	height: 1px;
	background: var(--line300);

	margin-top: 10px;
	margin-bottom: 12px;
}

//


.moveDocPopover-list-group-header{
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	color: var(--text500);

	padding-left: 10px;
	padding-bottom: 6px;
	padding-top: 2px;
}

// stolen from select user popover

.moveDocPopover-row{
	display: flex;
	height: 30px;
	width: 100%;
	border-radius: 4px;

	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;
}

.moveDocPopover-row--active{
	background: red;
}

.moveDocPopover-row:hover{
	background: var(--hoverBG-item-dropdown);
}

.moveDocPopover-row--active,
.moveDocPopover-row--active:hover{
	background: var(--activeBG-suggestionItem);
}




.moveDocPopover-row-markerContainer{
	width: 26px;
	height: 26px;

	//background: $tempr;
	margin-right: 5px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.moveDocPopover-row-markerContainer--icon svg{
	width: 20px;
	height: 20px;
	fill: var(--text600);
	opacity: 0.5;
	position: relative;
	right: 0px;
	top: 0.5px;
}

.moveDocPopover-row-markerContainer--image{
}

.moveDocPopover-row-markerContainer--image-image{
	width: 16px;
	height: 16px;
	object-fit: cover;
	border-radius: 4px;
}

.moveDocPopover-row-markerContainer
.linearMarker{
	font-size: 16px;
}

.moveDocPopover-row-markerContainer
.linearMarker svg{
	width: 15.5px;
	height: 15.5px;
}

.moveDocPopover-row-titleContainer{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moveDocPopover-row-title{
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.004em;
	color: var(--text500);

	text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



// .moveDocPopover-row--project-teamsContainer{
// 	display: flex;
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	display: none;
// }

// .moveDocPopover-row:hover 
// .moveDocPopover-row--project-teamsContainer{
// 	display: none;
// }

// .moveDocPopover-row--project-teamTag{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	height: 20px;
// 	padding-left: 4px;
// 	padding-right: 4px;

// 	background: var(--253gray);
// 	border-radius: 3px;

// 	margin-left: 3px;
// }

// .moveDocPopover-row:hover 
// .moveDocPopover-row--project-teamTag{
// 	background: var(--235gray);
// }

// .moveDocPopover-row--project-teamTag-markerContainer
// .linearMarkerContainer{
// 	transform: scale(0.75);
// }

// .moveDocPopover-row--project-teamTag-label{
// 	font-size: 12.5px;
// 	font-weight: $lato-regular;
// 	color: var(--text400);
// 	//margin-left: 2px;
// 	opacity: 0.5;
// }

// .moveDocPopover-row:hover 
// .moveDocPopover-row--project-teamTag-label{
// 	opacity: 0.7;
// }