button{
	border: none;
	box-shadow: none;
	background: none;
	padding: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	user-select: none;
}

button:focus{
	outline: none;
}