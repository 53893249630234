// lots is taken from open feedback popover

$bucketPopoverGray: rgb(253,253,253); 

// align nicer
.docEditor-bucketPopoverOuterContainer{
	margin-left: 92px;
	margin-left: 68px;
	margin-top: 4px;
}



.docEditor-bucketSelectPopover{
	width: 360px;

	background: $white;
	border: 1px solid var(--line375);
	border-radius: 6px;

	box-shadow: 
		0px 2px 4px 0px rgba(0,0,0,0.12),
		0px 4px 8px 0px rgba(0,0,0,0.06)
	;

	display: flex;
	flex-direction: column;

}

// .docEditor-bucketSelectPopover-header{
// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	width: 100%;
// 	height: 38px;
// 	border-bottom: 1px solid var(--line300);
// 	position: relative;

// 	display: flex;
// 	align-items: center;

// 	box-sizing: border-box;
// 	padding-left: 17px;
// 	padding-right: 12px;

// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: var(--text500);
// 	letter-spacing: 0.005em;
// }



// input.docEditor-bucketSelectPopover-header-search{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	box-sizing: border-box;
// 	padding-left: 17px;

// 	border: none;

// 	border-top-right-radius: 6px;
// 	border-top-left-radius: 6px;

// 	font-size: 15px;

// 	font-weight: $lato-semibold;
// 	color: var(--text500);

// 	letter-spacing: 0.005em;

// 	padding-right: 65px;

// }

// input.docEditor-bucketSelectPopover-header-search:focus{
// 	outline: none;
// 	border: none;
// }

// input.docEditor-bucketSelectPopover-header-search::placeholder{
// 	color: var(--text400);
// 	font-weight: $lato-medium;
// 	letter-spacing: 0.005em;
// }

//



.docEditor-bucketSelectPopover-bucketList{
	
	flex-grow: 1;
	flex-shrink: 1;

	min-height: 200px;
	max-height: calc(100vh - 280px); // i think this is right...

	width: 100%;
	box-sizing: border-box;
	padding-left: 6px;
	padding-right: 6px;
	overflow-y: scroll;

	padding-top: 6px;
	padding-bottom: 6px;
}


button.docEditor-bucketSelectPopover-bucketList-bucketRow{
	display: flex;
	align-items: center;
	height: 48px;

	width: 100%;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;

	cursor: pointer;

	border-radius: 8px;

	position: relative;
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	flex-grow: 0;
	flex-shrink: 0;

	width: 34px;
	height: 34px;

	background: $tempr;
	background: $bucketPopoverGray;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: 11px;

	font-size: 21px;
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-content{
	min-width: 10;
	flex-grow: 1;
	flex-shrink: 1;

	text-align: left;

	padding-right: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-content-name{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;

  margin-bottom: 1px;



	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-content-description{
	font-size: 14px;
	font-weight: $lato-regular;
	//color: var(--text300);
	color: var(--text300);
	letter-spacing: 0.004em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
}

// STATES

button.docEditor-bucketSelectPopover-bucketList-bucketRow:hover{
	// background: rgb(253,250,250);
	background: darken($bucketPopoverGray, 2%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow:active{
	background: darken($bucketPopoverGray, 4%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused{
	background: transparentize($accentColor-purple, 0.9);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused:hover{
	background: transparentize($accentColor-purple, 0.87);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused:active{
	background: transparentize($accentColor-purple, 0.8);	
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current{	
	background: darken($bucketPopoverGray, 3%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current:hover{
	background: darken($bucketPopoverGray, 5%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current:active{
	background: darken($bucketPopoverGray, 5%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow:hover
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: darken($bucketPopoverGray, 7%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow:active
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: darken($bucketPopoverGray, 7%);
}

//

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: darken($bucketPopoverGray, 5%);	
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current:hover
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: darken($bucketPopoverGray, 7%);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--current:active
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: darken($bucketPopoverGray, 10%);
}

//

// REFACTORCOLOR TO BLUE
button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: transparentize($accentColor-purple, 0.85);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused:hover
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: transparentize($accentColor-purple, 0.8);
}

button.docEditor-bucketSelectPopover-bucketList-bucketRow--isFocused:active
.docEditor-bucketSelectPopover-bucketList-bucketRow-iconContainer{
	background: transparentize($accentColor-purple, 0.75);	
}


// CLEAR FROM BOARD
.docEditor-bucketSelectPopover-clearBtn{
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;

	box-sizing: border-box;
	padding-left: 14px;

	color: var(--text300);
	opacity: 0.75;
	height: 36px;


	// background: $tempr;
	width: 100%;

	justify-content: flex-start;
}

.docEditor-bucketSelectPopover-clearBtn:hover{
	opacity: 1;
	background: darken($bucketPopoverGray, 1%);
}









// KEYBOARD SHORTCUT
.docEditor-bucketSelectPopover-bucketList-bucketRow-keyboardShortcutContainer{
	margin-left: auto;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	flex-grow: 0;
	flex-shrink: 0;

}

.docEditor-bucketSelectPopover-bucketList-bucketRow-keyboardShortcut{
	display: flex;
	align-items: center;
	padding-left: 6px;
	padding-right: 5px;
	background: darken($accentColor-purple, 0%);

	color: $white;
	border-radius: 3px;

	font-size: 12px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	height: 20px;
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-keyboardShortcut-iconContainer {
	margin-left: 2px;
	height: 100%;
	display: flex;
	align-items: center;
}

.docEditor-bucketSelectPopover-bucketList-bucketRow-keyboardShortcut-iconContainer svg{
	width: 12px;
	height: 12px;

	fill: $white;
	margin-top: 1px;
}