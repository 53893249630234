

.meetingNotesPage-yourCalendarOuterContainer{
	width: 100%;
	display: flex;
	// justify-content: center;

	// margin-top: -10px;

	box-sizing: border-box;
	// padding-left: 40px;
	// padding-right: 40px;
}

.meetingNotesPage-yourCalendarContainer{	
	width: 100%;
	// // @include poly-fluid-sizing('width', (			
	// // 		1280px: 1000px,		
	// // 		1366px: 1080px,
	// // 		1440px: 1170px,
	// // 		1680px: 1400px,
	// // 		1920px: 1550px,
	// // 		2560px: 2000px)
	// // 	);

	// @include poly-fluid-sizing('width', (			
	// 		1280px: 1000px,		
	// 		1366px: 1080px,
	// 		1440px: 1170px,
	// 		1680px: 1400px,
	// 		1920px: 1550px,
	// 		2560px: 2000px)
	// 	);
	position: relative;
}



.meetingNotesPage-yourCalendar{
	width: calc(100% + 28px);
	margin-left: calc(-14px - 48px);

	padding-left: 48px;
	padding-right: 48px;
	//padding-top: 18px;
	padding-top: 18px;
	padding-top: 6px;
	padding-bottom: 10px;

	//background: $tempr;
	//background: var(--254gray);
	border-radius: 10px;

	position: relative;

	display: flex;

	min-height: 280px;
}

[data-theme="dark"]{
	.meetingNotesPage-yourCalendar{
		// background: rgba(255,255,255,0.01);
		background: lighten(rgb(28,26,32),1%);
		background: none;
	}
}

.meetingNotesPage-yourCalendar-returnToTodayBtn{
	position: absolute;
	top: -30px;
	margin-top: -24px; // optical align with meeting notes
	right: 54px;



	width: 90px;

	height: 30px;
	font-size: 15px;
	font-weight: $lato-medium;
	letter-spacing: 0.004em;
	color: var(--text500);

	border-radius: 4px;
	background: var(--250gray);
	// background: $tempr;
	opacity: 0.75;
}

.meetingNotesPage-yourCalendar-returnToTodayBtn--hidden{
	opacity: 0;
	pointer-events: none;
	transition: opacity 75ms;
}

.meetingNotesPage-yourCalendar-returnToTodayBtn--visible{
	opacity: 0.75;
	transition: opacity 75ms;
}

.meetingNotesPage-yourCalendar-returnToTodayBtn--hidden:hover{
	opacity: 1;
	transition: opacity 0ms;
}

.meetingNotesPage-yourCalendar-navBtn{
	position: absolute;
	top: 4px;
	height: 32px;
	width: 48px;
	border-radius: 4px;

	height: calc(100% - 8px);
	height: 240px;

	box-sizing: border-box;
	padding-top: 14px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	// background: $tempr;
}

.meetingNotesPage-yourCalendar-navBtn:hover{
	background: var(--253gray);
}

.meetingNotesPage-yourCalendar-navBtn--previous{
	//right: 100%;
	left: 0px;
	margin-left: 4px;
}

.meetingNotesPage-yourCalendar-navBtn--next{
	margin-right: 4px;
	right: 0px;
	// left: 100%;
}

.meetingNotesPage-yourCalendar-navBtn--previous svg{
	transform: scaleX(-1);
}

.meetingNotesPage-yourCalendar-navBtn svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.3;
}

.meetingNotesPage-yourCalendar-navBtn:hover svg{
	opacity: 0.8;
}


//


.meetingNotesPage-yourCalendar-day{
	width: calc(100% / 5);
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;

	display: flex;
	flex-direction: column;

	padding-bottom: 32px;
}

// .meetingNotesPage-yourCalendar-day{
// 	background: rgb(255,255,255,0.01);
// 	border-radius: 4px;
// }





.meetingNotesPage-yourCalendar-day-header{
	height: 32px;
	margin-bottom: 8px;

	display: flex;
	align-items: center;
	align-items: baseline;


	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;
	padding-left: 5px;
	padding-right: 5px;

	padding-top: 4px;

	cursor: default;
	user-select: none;

	// background: var(--254gray);

	// border-radius: 4px;

	border-bottom: 1px solid var(--line200);
}

.meetingNotesPage-yourCalendar-day--today
.meetingNotesPage-yourCalendar-day-header{
	// background: var(--250gray);
}


.meetingNotesPage-yourCalendar-day-header-dayOfWeek{
	font-size: 15.75px;
	font-size: 15.25px;
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.004em;
	
	color: var(--text500);
	opacity: 0.9;
}	

.meetingNotesPage-yourCalendar-day--today
.meetingNotesPage-yourCalendar-day-header-dayOfWeek{
	// font-weight: $lato-heavy;
}


[data-theme="dark"]{
	.meetingNotesPage-yourCalendar-day-header-dayOfWeek{
		font-size: 16.25px;
		font-weight: $lato-medium;
		opacity: 0.75;
	}
}


.meetingNotesPage-yourCalendar-day-header-date{
	margin-left: auto;
	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text200);
	letter-spacing: 0.003em;

	min-width: 26px;	
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	cursor: default;
	user-select: none;

	// background: $tempr;
}

.meetingNotesPage-yourCalendar-day--today
.meetingNotesPage-yourCalendar-day-header-date{
	background: var(--primary50);
	font-weight: $lato-semibold;
	color: var(--primary600);
	background: var(--accentRed50);
  color: var(--accentRed700);
	// color: white;
}

[data-theme="dark"]{
	.meetingNotesPage-yourCalendar-day--today
	.meetingNotesPage-yourCalendar-day-header-date{
	  //color: rgb(255,67,70) !important;
	  color: rgba(255,67,70,0.8);
	  background: rgba(255,89,89,0.12);
	  background: none;
	}	
}




.meetingNotesPage-yourCalendar-day-list{	
	// background: var(--250gray);
	border-radius: 5px;

	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 5px;
}


.meetingNotesPage-yourCalendar-day-list-meeting,
.meetingNotesPage-yourCalendar-day-list-empty{
	width: 100%;
	
	border-radius: 5px;
	
	box-sizing: border-box;

	padding-left: 13px;
	padding-right: 13px;
	padding-top: 8px;
	padding-bottom: 8px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	user-select: none;

	position: relative;

	cursor: pointer;
}

.meetingNotesPage-yourCalendar-day-list-newBtn{
	width: 100%;
	height: 26px;
	border-radius: 4px;
	background: var(--253gray);
	opacity: 0.65;
	transition: opacity 50ms linear;
}

.meetingNotesPage-yourCalendar-day:hover
.meetingNotesPage-yourCalendar-day-list-newBtn{
	opacity: 0.75;
	background: var(--250gray);
}

.meetingNotesPage-yourCalendar-day-list-newBtn svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.5;
	transition: opacity 50ms linear;
}

.meetingNotesPage-yourCalendar-day:hover
.meetingNotesPage-yourCalendar-day-list-newBtn:hover{
	background: var(--250gray);
}

.meetingNotesPage-yourCalendar-day:hover
.meetingNotesPage-yourCalendar-day-list-newBtn:hover svg{
	opacity: 0.8;
}

[data-theme="dark"]{
	.meetingNotesPage-yourCalendar-day-list-newBtn{
		opacity: 0.4;
	}
	.meetingNotesPage-yourCalendar-day:hover
	.meetingNotesPage-yourCalendar-day-list-newBtn{
		opacity: 0.6;
	}
	.meetingNotesPage-yourCalendar-day:hover
	.meetingNotesPage-yourCalendar-day-list-newBtn{
		opacity: 0.6;
	}


}


.meetingNotesPage-yourCalendar-day-list-meeting--meeting{
	// padding-left: 15px;
}


.meetingNotesPage-yourCalendar-day-list-meeting-bar{
	position: absolute;
	top: 5px;
	height: calc(100% - 10px);

	left: 4px;

	width: 2px;
	border-radius: 2px;

	// background: $tempr;
	opacity: 0.5;
	
}


[data-theme="light"]{
	.meetingNotesPage-yourCalendar-day-list-meeting{
		// border: 1px solid var(--line325);
		// border-top: 1px solid var(--line300);
		// border-bottom: 1px solid var(--line375);

		border: 1px solid var(--line300);
		border-top: 1px solid var(--line200);
		border-bottom: 1px solid var(--line325);

		background: white;
	}	
	.meetingNotesPage-yourCalendar-day-list-meeting:hover{
		border: 1px solid var(--line450);
		border-bottom: 1px solid var(--line500);
	}

	.meetingNotesPage-yourCalendar-day-list-meeting--meeting{		
		box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.02);
	}

	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal{
		background: rgba(255,255,255,0.75);
		
	  border-top: 1px solid var(--line200);
	  border-bottom: 1px solid var(--line200);
	  border: 1px solid var(--line200);
	}
	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover{
		background: white;
		border: 1px solid var(--line375);
		border-top: 1px solid var(--line375);
	  border-bottom: 1px solid var(--line375);	  
	  opacity: 1;
	}
}



[data-theme="dark"]{
	.meetingNotesPage-yourCalendar-day-list-meeting{
		background: var(--250gray);

	}	
	.meetingNotesPage-yourCalendar-day-list-meeting:hover{
		background: var(--246gray);
	}

	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal{
		background: var(--253gray);	
		opacity: 0.75;	  
	}
	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover{
		opacity: 1;
	}
}





.meetingNotesPage-yourCalendar-day-list-meeting--meeting{	
	min-height: 97px;
	min-height: 110px;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}



.meetingNotesPage-yourCalendar-day-list-empty{
	
	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.5;
	user-select: none;
}


//

.meetingNotesPage-yourCalendar-day-list-meeting-iconContainer{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 5px;
	width: 20px;
	height: 20px;

}

.meetingNotesPage-yourCalendar-day-list-meeting-iconContainer svg{
	width: 16px;
	height: 16px;
}

.meetingNotesPage-yourCalendar-day-list-meeting-iconContainer svg.icon--name--calendar{
	opacity: 0.5;
}

//

.meetingNotesPage-yourCalendar-day-list-meeting-name{
	// font-size: 15px;
	// font-weight: $lato-semibold;
	// color: var(--text500);


	text-align: left;

	font-size: 15.75px;
	//font-size: 15.2px;
	font-weight: $lato-bold;
	color: var(--text500);

	letter-spacing: 0.004em;
	line-height: 1.2;

       word-break: break-word;



  //padding-right: 10px;

  position: relative;

}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-name{
	display: flex;
	align-items: center;
	width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-name-label{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer{
	width: 18px;
	height: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	// margin-left: -4px;

	padding-left: 4px;

	margin-right: -1px;

	margin-left: auto;

	flex-grow: 0;
	flex-shrink: 0;

	display: none;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer{
	display: flex;
}

.meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);

	opacity: 0.5;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer svg{
	opacity: 1;
}





.meetingNotesPage-yourCalendar-day-list-meeting--meeting
.meetingNotesPage-yourCalendar-day-list-meeting-name{
 	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  max-height: 38px;
  overflow: hidden;
}



.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
	margin-top: 4px;
	font-size: 12.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.016em;
	color: var(--text400);	
	opacity: 0.8;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp-timestamp{
	display: flex;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp-label{
	display: none;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp-timestamp{
	display: none;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp-label{
	display: flex;
}




.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-name{
	opacity: 0.75;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-name{
	opacity: 1;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
	opacity: 0.75;
}

.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover
.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
	opacity: 1;
}

//
//



// [data-theme="dark"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal{
// 		background: var(--253gray);
// 		background: rgba(36,33,42,0.8);
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--isGCal:hover{
// 		background: var(--251gray);
// 	}
// }

// //

// [data-theme="dark"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-bar{
// 		background: desaturate(lighten($designReviewColor, 5%), 8%);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-name{
// 		color: desaturate(lighten($designReviewColor, 28%), 8%);
// 	}	

// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
// 		color: desaturate(lighten($designReviewColor, 28%), 8%);
// 		opacity: 0.75;
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview{		
// 		background: transparentize(lighten($designReviewColor, 5%), 0.95);
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview:hover{		
// 		background: transparentize(lighten($designReviewColor, 5%), 0.9);
// 	}
// }

// //

// [data-theme="light"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-bar{
// 		background: desaturate(darken($designReviewColor, 5%), 0%);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-name{
// 		color: desaturate(darken($designReviewColor, 10%), 4%);
// 	}	

// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview
// 	.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
// 		color: desaturate(darken($designReviewColor, 10%), 4%);
// 		opacity: 0.75;
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview{		
// 		background: transparentize(lighten($designReviewColor, 5%), 0.96);
// 		box-shadow: none;
// 		border: none;
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--designReview:hover{		
// 		background: transparentize(lighten($designReviewColor, 5%), 0.9);
// 		box-shadow: none;
// 		border: none;
// 	}
// }

// //

// [data-theme="dark"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--teamSync
// 	.meetingNotesPage-yourCalendar-day-list-meeting-bar{
// 		background: lighten($teamSyncColor, 5%);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--teamSync
// 	.meetingNotesPage-yourCalendar-day-list-meeting-name{
// 		color: lighten($teamSyncColor, 26%);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--teamSync
// 	.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
// 		color: lighten($teamSyncColor, 26%);
// 		opacity: 0.75;
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--teamSync{
// 		background: transparentize(saturate(lighten($teamSyncColor, 5%), 10%), 0.95);
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--teamSync:hover{
// 		background: transparentize(saturate(lighten($teamSyncColor, 5%), 10%), 0.9);
// 	}
// }

// //

// [data-theme="dark"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--discovery
// 	.meetingNotesPage-yourCalendar-day-list-meeting-bar{
// 		background: lighten($discoveryColor, 5%);		
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--discovery
// 	.meetingNotesPage-yourCalendar-day-list-meeting-name{
// 		color: lighten($discoveryColor, 22%);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--discovery
// 	.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
// 		color: lighten($discoveryColor, 22%);
// 		opacity: 0.75;
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--discovery{				
// 		background: transparentize(saturate(lighten($discoveryColor, 5%), 15%), 0.95);
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--discovery:hover{				
// 		background: transparentize(saturate(lighten($discoveryColor, 5%), 15%), 0.9);
// 	}
// }

// //

// [data-theme="dark"]{
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--blank
// 	.meetingNotesPage-yourCalendar-day-list-meeting-bar{
// 		background: var(--text200);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--blank
// 	.meetingNotesPage-yourCalendar-day-list-meeting-name{
// 		color: var(--text400);
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--blank
// 	.meetingNotesPage-yourCalendar-day-list-meeting-timestamp{
// 		color: var(--text400);
// 		opacity: 0.75;
// 	}	
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--blank{				
// 		background: var(--253gray);
// 	}
// 	.meetingNotesPage-yourCalendar-day-list-meeting--type--blank:hover{				
// 		background: var(--250gray);
// 	}
// }

