// DIVIDER

.docEditor-messagePanel-divider{
	width: 100%;
	height: 13px;

	display: flex;
	// background: $tempb;

	align-items: center;
	box-sizing: border-box;

	padding-top: 14px;
	padding-bottom: 14px;

	cursor: default;
	user-select: none;
}

.docEditor-messagePanel-divider-line{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	height: 1px;

	background: var(--line300);

}

[data-theme="dark"]
.docEditor-messagePanel-divider--date 
.docEditor-messagePanel-divider-line{
	background: var(--line200);
	opacity: 0.8;
}


.docEditor-messagePanel-divider--unreads
.docEditor-messagePanel-divider-line{
	background: var(--unreadCountBorderColor);
}

.docEditor-messagePanel-divider-label--new{
	font-size: 12px;
	font-weight: $lato-bold;
	color: var(--unreadCountTextColor);

	padding-right: 12px;
	padding-left: 8px;


}

.docEditor-messagePanel-divider--date{
	padding-top: 6px;
	padding-bottom: 6px;
}





.docEditor-messagePanel-divider-label--date{
	font-size: 12.5px;
	font-size: $lato-semibold;
	color: var(--text300);
	opacity: 0.75;
	height: 20px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 1px;
}

// HIDE IF FIRST
.docEditor-messagePanel-scrollContents
.docEditor-messagePanel-divider--date:last-child{
	display: none;
}

