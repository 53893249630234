//

.sideMenu-activeViewStatus--doc-channelBtnContainer{
	position: relative;
	// background: $tempr;
	padding-right: 2px;
	margin-left: 2px;

	display: none;

}

.sideMenu-activeViewStatus--doc-subrow:hover 
.sideMenu-activeViewStatus--doc-channelBtnContainer{
	opacity: 0.5;
}

.sideMenu-activeViewStatus--doc-subrow:hover 
.sideMenu-activeViewStatus--doc-channelBtnContainer:hover{
	opacity: 1;
}



.sideMenu-activeViewStatus--doc-channelBtn{
	display: flex;
	align-items: center;	
	justify-content: center;
	height: 26px;
	width: 48px;

	border-radius: 3.5px;

	// background: rgb(0,0,0,0.04);
	background: rgb(153,153,160);
	background: #a8a7a8;
	background: #b6b1b8;
	background: #a6a1a8;
	cursor: pointer;

	background: rgb(0,0,0,0.04);

	user-select: none;
}

// .sideMenu-activeViewStatus:hover 
// .sideMenu-activeViewStatus--doc-channelBtn{
// 	background: darken(#a8a7a8, 2%);
// }

.sideMenu-activeViewStatus:hover 
.sideMenu-activeViewStatus--doc-channelBtn:hover{
	background: darken(#a6a1a8, 5%);
	background: rgb(0,0,0,0.06);
}

.sideMenu-activeViewStatus--doc-channelBtn-iconContainer{
	// margin-right: 2px;
}

.sideMenu-activeViewStatus--doc-channelBtn-iconContainer svg{
	width: 12px;
	height: 12px;

	fill: $white;
	fill: var(--text500);
	opacity: 0.5;
}

.sideMenu-activeViewStatus--doc-channelBtn-label{
	font-size: 13.9px;
	font-weight: $lato-semibold;
	//color: var(--text500);
	color: $white;
	// opacity: 0.95;

	position: relative;
	top: -1px;

	letter-spacing: 0.018em;

}

//
// DROPDOWN PROTOTYPE
.sideMenu-activeViewStatus--doc-channelBtnPopoverContainer{
	pointer-events: none;
	// background: $tempr;

	position: absolute;

	top: 23px;
	left: -34px;
	// right: 0px;

	width: 300px;
	height: 280px;

	padding-top: 6px;

	// background: $tempr;	

	z-index: 100;

	opacity: 0;

}

.sideMenu-activeViewStatus--doc-subrow:hover 
.sideMenu-activeViewStatus--doc-channelBtnPopoverContainer{
	opacity: 1;

	transition: opacity 0ms linear;
	transition-delay: 150ms;
}


.sideMenu-activeViewStatus--doc-channelBtnContainer:hover
.sideMenu-activeViewStatus--doc-channelBtnPopoverContainer{
	pointer-events: auto;
}

.sideMenu-activeViewStatus--doc-channelBtnContainer:hover
.sideMenu-activeViewStatus--doc-popover{
	opacity: 1;
}
