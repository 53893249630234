

.library-sideBarSpacer{
	flex-grow: 0;
	flex-shrink: 0;
}

.library-content,
.docEditor-content{	
	min-width: 10px;	
	flex-grow: 1; // pick up any rounding errors
	flex-shrink: 1; // pick up any rounding errors
}


//
// MIN SUPPORTED WIDTH IS 1280
// MIN SUPPORTED HEIGHT IS 640

//
// HIDDEN INTERFACE
//

// // LEFT MARGIN WIDTH
// .docEditor-topMenu-leftSpacer,
// .openDocMenu-docPreview-leftSpacer,
// .docEditor-docLeftMarginSpacer,
// .docEditor--draft-leftMarginGhost,
// .tabBar-tabContainer--libraryTab{
// 	@include poly-fluid-sizing('width', (
// 		1020px: 240px, 
// 		1140px: 240px, 
// 		1280px: 308px,		
// 		1366px: 350px,
// 		1440px: 390px,
// 		1680px: 507px,
// 		1920px: 627px,
// 		2560px: 947px)
// 	);
// }


//
// HOVER AREA HIDDEN
.sideMenuHiddenHoverArea{
	@include poly-fluid-sizing('width', (
		1020px: 20px, 
		1140px: 20px, 
		1280px: 60px,		
		1366px: 60px,
		1440px: 70px,
		1680px: 80px,
		1920px: 160px,
		2560px: 200px)
	);
}

//
// HOVER SIDE MENU WIDTH
// note there's 24px of padding on right
.sideMenuContainer--hidden-sideMenuOuterContainer{
	@include poly-fluid-sizing('width', (
		1020px: 264px, 
		1140px: 264px, 
		1280px: 280px,
		1366px: 280px,
		1440px: 290px,
		1680px: 300px,		
		1920px: 320px,
		2560px: 320px)
	);
}



//
// VISIBLE INTERFACE
//


//
// SIDE MENU WIDTH

.sideMenuContainer--visible{
	@include poly-fluid-sizing('width', (
		1020px: 200px, 
		1140px: 200px, 
		1280px: 200px,
		1366px: 210px,
		1440px: 210px,
		1680px: 225px,
		1800px: 244px,
		1920px: 260px,
		2560px: 320px)	
	);
}

// COPY BELOW
.docEditorContainer--sideMenuState--visible,
.roadmapsHomePageContainer--sideMenuState--visible,
.roadmapPageContainer--sideMenuState--visible,
.allDocsPageContainer--sideMenuState--visible,
.recentPageContainer--sideMenuState--visible,
.meetingNotesPageContainer--sideMenuState--visible,
.projectsPageContainer--sideMenuState--visible,
.privateDocsPageContainer--sideMenuState--visible,
.openDocMenu-docPreview.openDocMenu-docPreview--sideMenuState--visible,
.openDocMenu-pagePreview.openDocMenu-pagePreview--sideMenuState--visible{
	@include poly-fluid-sizing('left', (
		1020px: 200px, 
		1140px: 200px, 
		1280px: 200px,
		1366px: 210px,
		1440px: 210px,
		1680px: 225px,
		1800px: 244px,
		1920px: 260px,
		2560px: 320px)	
	);
}

// ///////////////

// // LEFT MARGIN DOC
// .docEditorContainer--sideMenuState--visible .docEditor-docLeftMarginSpacer,
// .openDocMenu-docPreview.openDocMenu-docPreview--sideMenuState--visible .openDocMenu-docPreview-leftSpacer{
// 	@include poly-fluid-sizing('width', (
// 		1020px: 170px, 
// 		1140px: 170px, 
// 		1280px: 43px,
// 		1366px: 50px,
// 		1440px: 74px,
// 		1680px: 155px,
// 		1800px: 200px, 		
// 		1920px: 240px,
// 		2560px: 460px)
// 	);
// }

//
// STASHED PANEL WIDTH

.docEditorContainer--sideMenuState--visible .docEditor-stashedPanelContainer .docEditor-stashedPanel,
.docEditorContainer--sideMenuState--visible .docEditor-issuePanelContainer .docEditor-issuePanel{
	@include poly-fluid-sizing('width', (
		1020px: 260px, 
		1140px: 260px, 
		1280px: 300px,
		1366px: 360px,
		1440px: 390px,
		1680px: 490px,
		1800px: 530px,
		1920px: 560px,
		2560px: 640px)
	);
}

.docEditorContainer--sideMenuState--hidden .docEditor-stashedPanelContainer .docEditor-stashedPanel,
.docEditorContainer--sideMenuState--hidden .docEditor-issuePanelContainer .docEditor-issuePanel{
	@include poly-fluid-sizing('width', (
		1020px: 260px, 
		1140px: 260px, 
		1280px: 400px,
		1366px: 420px,
		1440px: 440px,
		1680px: 510px,
		1800px: 540px,
		1920px: 630px,
		2560px: 680px)
	);
}




//
// MESSAGE PANEL WIDTH
.docEditorContainer--sideMenuState--visible .docEditor-messagePanelContainer .docEditor-messagePanel,
.openDocMenu-docPreview--sideMenuState--visible .openDocMenu-docPreview-messagePanelSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 300px,
		1366px: 360px,
		1440px: 390px,
		1680px: 490px,
		1800px: 530px,
		1920px: 560px,
		2560px: 640px)
	);
}


//
// FULL SCREEN MESSAGE PANEL WIDTH
.docEditorContainer--sideMenuState--hidden .docEditor-messagePanelContainer .docEditor-messagePanel,
.openDocMenu-docPreview--sideMenuState--hidden .openDocMenu-docPreview-messagePanelSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 400px,
		1366px: 420px,
		1440px: 440px,
		1680px: 510px,
		1800px: 540px,
		1920px: 630px,
		2560px: 680px)
	);
}



// Thread preview
.commentThreadMarkerPopover{
	@include poly-fluid-sizing('width', (		
		1280px: 280px,
		1366px: 340px,
		1440px: 360px,
		1680px: 400px,
		1800px: 400px,
		1920px: 400px,
		2560px: 400px)
	);
}


//
//
// LIBRARY AND ROADMAPS


// 
// WITH SIDEPANEL
.allDocsPageContainer--sideMenuState--visible 			.allDocsPage-leftSpacer,
.privateDocsPageContainer--sideMenuState--visible 	.privateDocsPage-leftSpacer,
.meetingNotesPageContainer--sideMenuState--visible 	.meetingNotesPage-leftSpacer,
.roadmapsHomePageContainer--sideMenuState--visible 	.roadmapsHomePage-leftSpacer,
.roadmapPageContainer--sideMenuState--visible 			.roadmapPage-leftSpacer,
.roadmapPageContainer--sideMenuState--visible 			.roadmapPage-header-leftSpacer,
.recentPageContainer--sideMenuState--visible 				.recentPage-leftSpacer,
.projectsPageContainer--sideMenuState--visible 			.projectsPage-leftSpacer,
.settingsPage-leftSpacer,
.settingsPage-rightSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 96px,
		1366px: 96px,		
		1440px: 96px,
		1600px: 132px,
		1920px: 140px)
	);
}


.allDocsPageContainer--sideMenuState--visible 			.allDocsPage-rightPanel,
.privateDocsPageContainer--sideMenuState--visible 	.privateDocsPage-rightPanel,
.recentPageContainer--sideMenuState--visible 				.recentPage-rightPanel{
	@include poly-fluid-sizing('width', (		
		1280px: 120px, 
		1366px: 180px,
		1440px: 400px, 
		1600px: 440px, 
		1920px: 720px)
	);
}

// 
// NO SIDEPANEL
.allDocsPageContainer--sideMenuState--hidden 				.allDocsPage-leftSpacer,
.privateDocsPageContainer--sideMenuState--hidden 		.privateDocsPage-leftSpacer,
.recentPageContainer--sideMenuState--hidden 				.recentPage-leftSpacer,
.meetingNotesPageContainer--sideMenuState--hidden 	.meetingNotesPage-leftSpacer,
.projectsPageContainer--sideMenuState--hidden 			.projectsPage-leftSpacer,
.roadmapPageContainer--sideMenuState--hidden 				.roadmapPage-header-leftSpacer,{
	@include poly-fluid-sizing('width', (		
		1280px: 112px,
		1366px: 112px,		
		1440px: 112px,
		1600px: 132px,
		1920px: 200px)
	);
}


.allDocsPageContainer--sideMenuState--hidden 				.allDocsPage-rightPanel,
.privateDocsPageContainer--sideMenuState--hidden 		.privateDocsPage-rightPanel,
.recentPageContainer--sideMenuState--hidden 				.recentPage-rightPanel,{
	@include poly-fluid-sizing('width', (
		1020px: 60px, 
		1140px: 100px, 
		1280px: 120px, 
		1366px: 180px,
		1440px: 400px, 
		1600px: 440px, 
		1920px: 720px)
	);
}


//
// ROADMAPS and meeting notes

.roadmapsHomePageContainer--sideMenuState--visible	.roadmapsHomePage-rightPanel,
.roadmapsHomePageContainer--sideMenuState--hidden		.roadmapsHomePage-rightPanel,
.meetingNotesPageContainer--sideMenuState--visible 	.meetingNotesPage-rightPanel,
.meetingNotesPageContainer--sideMenuState--hidden 	.meetingNotesPage-rightPanel{
	@include poly-fluid-sizing('width', (		
		// 1280px: 120px, 
		// 1366px: 120px,
		// 1440px: 200px, 
		// 1600px: 220px, 
		// 1920px: 240px)
		1280px: 112px,
		1366px: 112px,		
		1440px: 112px,
		1600px: 132px,
		1920px: 200px)
	);
}

// 
// NO SIDEPANEL
.roadmapsHomePageContainer--sideMenuState--hidden 	.roadmapsHomePage-leftSpacer,
.meetingNotesPageContainer--sideMenuState--hidden 	.meetingNotesPage-leftSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 112px,
		1366px: 112px,		
		1440px: 112px,
		1600px: 132px,
		1920px: 200px)
	);
}


// .roadmapsHomePageContainer--sideMenuState--hidden		.roadmapsHomePage-leftSpacer,
// .roadmapPageContainer--sideMenuState--hidden 				.roadmapPage-leftSpacer{
// 	@include poly-fluid-sizing('width', (
// 		1020px: 60px, 
// 		1140px: 100px, 
// 		1280px: 120px, 
// 		1366px: 180px,
// 		1440px: 400px, 
// 		1600px: 440px, 
// 		1920px: 720px)
// 	);
// }



// MAYBE

.roadmapPageContainer--sideMenuState--hidden 				.roadmapPage-leftSpacer,
.roadmapPageContainer--sideMenuState--hidden 				.roadmapPage-header-leftSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 242px,
		1366px: 242px,		
		1440px: 242px,
		1600px: 362px,
		1920px: 340px)
	);
}

.roadmapPageContainer--sideMenuState--visible 			.roadmapPage-leftSpacer,
.roadmapPageContainer--sideMenuState--visible 			.roadmapPage-header-leftSpacer{
	@include poly-fluid-sizing('width', (		
		1280px: 124px,
		1366px: 138px,		
		1440px: 154px,
		1600px: 170px,
		1920px: 220px)
	);
}









// //
// // PROJECTS
// // SIDE MENU VISIBLE

// .projectsPageContainer--sideMenuState--visible .projectsPage-rightSpacer{
// 	@include poly-fluid-sizing('width', (			
// 		1280px: 38px,
// 		1366px: 48px,		
// 		1440px: 56px,
// 		1600px: 80px,
// 		1920px: 170px)
// 	);
// }

// // RIGHT PANEL
// .projectsPageContainer--sideMenuState--visible .projectsPage-list-rightSpacer--activeProject,
// .projectsPageContainer--sideMenuState--visible .projectsPage-activePanelContainer,
// .projectsPageContainer--sideMenuState--visible .projectsPage-sticky-header-rightSpacer--activeProject{
// 	@include poly-fluid-sizing('width', (		
// 		1280px: 365px,
// 		1366px: 380px,		
// 		1440px: 420px,
// 		1600px: 470px,
// 		1920px: 570px)
// 	);
// }

// // SIDE PANEL LEFT PADDING
// .projectsPageContainer--sideMenuState--visible .projectsPage-activePanelContainer{
// 	@include poly-fluid-sizing('padding-left', (		
// 		1280px: 28px,
// 		1366px: 32px,		
// 		1440px: 40px,
// 		1600px: 44px,
// 		1920px: 56px)
// 	);
// }

// //
// // PROJECTS
// // SIDE MENU HIDDEN

// .projectsPageContainer--sideMenuState--hidden .projectsPage-rightSpacer{
// 	@include poly-fluid-sizing('width', (			
// 		1280px: 38px,
// 		1366px: 112px,	
// 		1440px: 112px,
// 		1600px: 132px,
// 		1920px: 200px)
// 	);
// }

// .projectsPageContainer--sideMenuState--hidden .projectsPage-list-rightSpacer--activeProject,
// .projectsPageContainer--sideMenuState--hidden .projectsPage-activePanelContainer,
// .projectsPageContainer--sideMenuState--hidden .projectsPage-sticky-header-rightSpacer--activeProject{
// 	@include poly-fluid-sizing('width', (		
// 		1280px: 400px,
// 		1366px: 420px,		
// 		1440px: 460px,
// 		1600px: 500px,
// 		1920px: 580px)
// 	);
// }

// // SIDE PANEL LEFT PADDING
// .projectsPageContainer--sideMenuState--hidden .projectsPage-activePanelContainer{
// 	@include poly-fluid-sizing('padding-left', (		
// 		1280px: 40px,
// 		1366px: 40px,		
// 		1440px: 52px,
// 		1600px: 56px,
// 		1920px: 72px)
// 	);
// }

// //
// // NOTHING SELECTED

// .projectsPageContainer--sideMenuState--visible .projectsPage-list-rightSpacer--sideControls,
// .projectsPageContainer--sideMenuState--visible .projectsPage-sticky-sideControlsContainer,
// .projectsPageContainer--sideMenuState--visible .projectsPage-sticky-header-rightSpacer--sideControls{
// 	@include poly-fluid-sizing('width', (		
// 		1280px: 285px,
// 		1366px: 330px,		
// 		1440px: 350px,
// 		1600px: 400px,
// 		1920px: 480px)
// 	);
// }

// .projectsPageContainer--sideMenuState--visible .projectsPage-sticky-sideControlsContainer{
// 	@include poly-fluid-sizing('padding-left', (		
// 		1280px: 28px,
// 		1366px: 32px,		
// 		1440px: 40px,
// 		1600px: 44px,
// 		1920px: 56px)
// 	);
// }

// .projectsPageContainer--sideMenuState--hidden .projectsPage-list-rightSpacer--sideControls,
// .projectsPageContainer--sideMenuState--hidden .projectsPage-sticky-sideControlsContainer,
// .projectsPageContainer--sideMenuState--hidden .projectsPage-sticky-header-rightSpacer--sideControls{
// 	@include poly-fluid-sizing('width', (		
// 		1280px: 320px,
// 		1366px: 360px,		
// 		1440px: 400px,
// 		1600px: 500px,
// 		1920px: 580px)
// 	);
// }

// .projectsPageContainer--sideMenuState--hidden .projectsPage-sticky-sideControlsContainer{
// 	@include poly-fluid-sizing('padding-left', (		
// 		1280px: 40px,
// 		1366px: 40px,		
// 		1440px: 52px,
// 		1600px: 56px,
// 		1920px: 72px)
// 	);
// }






//
//
// SETTINGS


//
// SETTINGS PAGE - NORMAL
// .settingsContainer{
// 	@include poly-fluid-sizing('left', (
// 		1020px: 200px,
// 		1140px: 200px, 
// 		1280px: 200px,
// 		1366px: 210px,
// 		1440px: 210px,
// 		1680px: 225px,
// 		1800px: 244px,
// 		1920px: 260px,
// 		2560px: 320px)
// 	);
// }

.settingsPage{
	@include poly-fluid-sizing('left', (
		1020px: 450px,
		1140px: 450px, 
		1280px: 450px,
		1366px: 460px,
		1440px: 460px,
		1680px: 475px,
		1800px: 495px,
		1920px: 560px,
		2560px: 620px)
	);
}

.settings-sideMenu{
	@include poly-fluid-sizing('width', (
		1020px: 450px,
		1140px: 450px, 
		1280px: 450px,
		1366px: 460px,
		1440px: 460px,
		1680px: 475px,
		1800px: 494px,
		1920px: 560px,
		2560px: 620px)
	);
}