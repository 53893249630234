

.roadmapsHomePage-quarter-overdue-label{
	font-size: 15px;
	font-weight: $lato-semibold;
	letter-spacing: 0.003em;
	
	margin-bottom: 12px;
	color: var(--text500);

	display: flex;

	margin-left: 18px;

}

.roadmapsHomePage-quarter-overdue-label-iconContainer{
	margin-right: 6px;
}

.roadmapsHomePage-quarter-overdue-label-label{
	color: var(--text300);
}

.roadmapsHomePage-quarter-overdue{
	width: 100%;
	margin-left: -6px;
	margin-top: -6px;
	display: flex;
	// background: $tempr;

	flex-wrap: wrap;
}

.roadmapsHomePage-quarter-overdue-project{
	width: 320px;
	height: 180px;
	// background: $tempr;

	border-radius: 6px;

	margin: 6px;

	box-sizing: border-box;
	padding-left: 22px;
	padding-right: 22px;
	padding-top: 16px;
	padding-bottom: 12px;

	border: 1px solid var(--line300);

	display: flex;
	flex-direction: column;
}

[data-theme="dark"]{
	.roadmapsHomePage-quarter-overdue-project{
		border: none;
		background: rgba(255,255,255,0.01);	
	}	
}


.roadmapsHomePage-quarter-overdue-project-icon{
	background: hsla(359, 83%, 68%, 0.05); // need it to be good in dark

	width: 28px;
	height: 28px;


	display: flex;
	align-items: center;	
	justify-content: center;

	border-radius: 4px;

	margin-bottom: 6px;

	font-size: 15.5px;
	box-sizing: border-box;
	padding-bottom: 1px;

	margin-top: -2px;

	pointer-events: none;
}

.roadmapsHomePage-quarter-overdue-project-name{
	font-size: 16.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.005em;
	color: var(--text500);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;   

  cursor: pointer;

  justify-content: flex-start;
  height: 34px;

  margin-top: -6px;

  border-radius: 4px;

  //background: $tempr;

  padding-right: 8px;
  padding-left: 8px;

  margin-left: -8px;

}

.roadmapsHomePage-quarter-overdue-project-dueDate{
	font-size: 15px;
	font-weight: $lato-regular;
	letter-spacing: 0.005em;
	color: var(--text500);

	opacity: 0.7;

	user-select: none;
	cursor: default;

	margin-top: -2px;
	pointer-events: none;

}

.roadmapsHomePage-quarter-overdue-project-vSpacer{
	min-height: 2px;
	flex-grow: 1;
	flex-shrink: 1;
}

.roadmapsHomePage-quarter-overdue-project-buttonSet{
	width: 100%;
	display: flex;
	flex-direction: column;

	
}


.roadmapsHomePage-quarter-overdue-project-editBtnContainer{
	margin-bottom: 0px;
	width: 100%;
}

.roadmapsHomePage-quarter-overdue-project-editBtn{
	width: 100%;
	justify-content: flex-start;
	// background: var(--253gray);
	// background: $tempr;
	border-radius: 4px;

	margin-left: -6px;

	height: 28px;
	padding-left: 6px;	
	padding-right: 10px;

	opacity: 0.75;
}

.roadmapsHomePage-quarter-overdue-project-buttonSet:hover
.roadmapsHomePage-quarter-overdue-project-editBtn{
	opacity: 1;
}


.roadmapsHomePage-quarter-overdue-project-editBtn:hover{
	background: var(--253gray);
}

.roadmapsHomePage-quarter-overdue-project-editBtn[data-state="open"]{
	background: var(--253gray);
	opacity: 1;
}


.roadmapsHomePage-quarter-overdue-project-editBtn-iconContainer{
	height: 100%;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.roadmapsHomePage-quarter-overdue-project-editBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.75;
}

.roadmapsHomePage-quarter-overdue-project-editBtn-iconContainer svg.icon--name--groupStatus{
	width: 18px;
	height: 18px;
	position: relative;
	left: -1px;
}

.roadmapsHomePage-quarter-overdue-project-editBtn-label{
	font-size: 15px;
	font-weight: $lato-regular;
	color: var(--text500);
}