// BOTTOM RIGHT TOOLS PANEL
.docEditor--draft-bottomRightFixedMenu,
.docEditor--feedback-bottomRightFixedMenu{
	position: fixed;
	bottom: $interiorTopBottomMarginToMenus-docEditor;
	right: calc(#{$interiorRightMarginToMenus-docEditor} + #{$width-teamPanel});	
	// background: $tempr;

	z-index: 500;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.docEditor-bottomRightFixedMenu-btn{
	position: relative;
	z-index: 50;
	cursor: pointer;
	height: 40px;
	width: 40px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	box-shadow: none;
	border-radius: 8px;

	background: $tempr;
}

button.docEditor-bottomRightFixedMenu-btn:hover{
	background: blue;
}

button.docEditor-bottomRightFixedMenu-btn--active{
	background: red;
}

button.docEditor-bottomRightFixedMenu-btn svg{
	width: 20px;
	height: 20px;
}

button.docEditor-bottomRightFixedMenu-btn svg.icon--name--chevronSquare{
	height: 24px;
	width: 24px;
}

//
// STATES

// INACTIVE
button.docEditor-bottomRightFixedMenu-btn--inactive{
	
	background: var(--bg-docEditor); // for comment overlap
	color: var(--text500); // refactor color
}

button.docEditor-bottomRightFixedMenu-btn--inactive svg{
	fill: var(--text500);
	opacity: 0.7;

}

button.docEditor-bottomRightFixedMenu-btn--inactive:hover{
	background: rgb(244, 240, 240);
}

button.docEditor-bottomRightFixedMenu-btn--inactive:hover svg{
	opacity: 1;
	transform: scale(1.1);
}


button.docEditor-bottomRightFixedMenu-btn--active{
	background: transparentize(rgb(40,30,30), 0.4);
}

button.docEditor-bottomRightFixedMenu-btn--active svg{
	fill: $white;
	opacity: 1;
	transform: scale(1.1);
}

button.docEditor-bottomRightFixedMenu-btn--active:hover{
	background: transparentize(rgb(40,30,30), 0.0);
}

button.docEditor-bottomRightFixedMenu-btn--active:hover svg{
	opacity: 1;
}

// DISABLED

button.docEditor-bottomRightFixedMenu-btn:disabled svg{
	opacity: 0.5;
}