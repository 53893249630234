.recordingPlaybackModal-header{
	// width: calc(100% - 16px);
	// margin-left: 8px;

	// height: calc(100% - 8px);
	// margin-top: 8px;



	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

//	border-radius: 5px;

	width: 100%;
	height: 100%;

	position: relative;
	
	display: flex;
	align-items: center;
	justify-content: center;
	// justify-content: flex-start;
	

	box-sizing: border-box;




	// background: rgba(255,255,255,0.06);
	// // padding-left: 22px;	

	// // background: $tempr;
	// //border-bottom: 1px solid rgba(255,255,255,0.09);
	// border-bottom: 1px solid rgba(255,255,255,0.07);

	// background: $tempr;

}


.recordingPlaybackModal-header-title{

	//margin-left: 8px;
	margin-top: 4px;

	// margin-top: -6px;
	cursor: pointer;
	user-select: none;

	height: 27px;

	// opacity: 0.75;

	display: flex;
	align-items: center;
	
	// align-items: baseline;

	// background: $tempr;

	padding-left: 15px;
	padding-right: 15px;

	border-radius: 4px;

	pointer-events: none;
}

.recordingPlaybackModal-header-title:hover{
	background: rgba(255,255,255,0.12);
}



.recordingPlaybackModal-header-title-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 100%;
	margin-right: 5px;

	margin-left: -8px;
}

.recordingPlaybackModal-header-title-iconContainer svg{
	width: 17px;
	height: 17px;
	position: relative;
	top: -1px;

	position: relative;
}

.recordingPlaybackModal-header-title-meetingTitle{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	// /color: var(--text600);
	color: white;
	letter-spacing: 0.010em;
}

.recordingPlaybackModal-header-title-date{
	font-size: 15.5px;
	font-size: 16px;
	font-weight: $lato-regular;
	color: var(--text600);
	opacity: 0.6;
	letter-spacing: 0.008em;

	margin-left: 6px;
}


.recordingPlaybackModal-header-hSpacer{
	min-width: 10px;
	height: 10px;
}

.recordingPlaybackModal-header-rightContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	height: 48px;
	right: 0px;

	z-index: 20;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;

	pointer-events: auto;
}



.forceDarkTheme,
[data-theme="dark"],
[data-theme="light"]{
	.recordingPlaybackModal-header .meetingParticipantListBtn{
		background: rgba(255,255,255,0.06) !important;
	}
	.recordingPlaybackModal-header .meetingParticipantListBtn:hover,
	.recordingPlaybackModal-header .meetingParticipantListBtn[data-state="open"]{
		background: rgba(255,255,255,0.12) !important;
	}
}




.recordingPlaybackModal-header-dropdownBtn,
.recordingPlaybackModal-header-identifySpeakersBtn{

	width: 34px;
	height: 30px;

	margin-right: 4px;


	z-index: 50;

	border-radius: 4px;
}

.recordingPlaybackModal-header-dropdownBtn svg,
.recordingPlaybackModal-header-identifySpeakersBtn svg{
	width: 16px;
	height: 16px;
	fill: var(--text500);
	opacity: 0.75;
}

.recordingPlaybackModal-header-identifySpeakersBtn{
	opacity: 0.7;
}

.recordingPlaybackModal-header-identifySpeakersBtn:hover{
	opacity: 1;
	background: rgba(255,255,255,0.12);
}


.recordingPlaybackModal-header-identifySpeakersBtn{
	position: relative;
}

.recordingPlaybackModal-header-identifySpeakersBtn-tooltipContainer{
	opacity: 0;
	transition: all 0ms linear;
}

.recordingPlaybackModal-header-identifySpeakersBtn:hover
.recordingPlaybackModal-header-identifySpeakersBtn-tooltipContainer{
	opacity: 1;
	transition-delay: 0ms;
}

.recordingPlaybackModal-header-identifySpeakersBtn-tooltipContainer{
	position: absolute;
	width: 100px;
	background: $tempr;

	top: 100%;
	margin-top: 3px;
	left: 50%;
	transform: translateX(-50%);
	
	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;


}

.recordingPlaybackModal-header-closeBtnOuterContainer{
	width: 44px;
	height: 44px;

	display: flex;
	align-items: center;
	justify-content: center;
	
}

.recordingPlaybackModal-header-closeBtnOuterContainer 
.doc-zoomedImageModal-btn{	
	width: 38px;
	height: 38px;
}

.recordingPlaybackModal-header-closeBtnOuterContainer 
.doc-zoomedImageModal-btn
.doc-zoomedImageModal-btn-iconContainer svg{
	width: 12px;
	height: 12px;
}

