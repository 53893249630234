$leftOverhang-docProjectDocsRow: 12px;
$rightOverhang-docProjectDocsRow: 10px;

.docEditor-projectSubDocsTableContainer{
	width: 100%;

	margin-top: 4px;
	padding-bottom: 10px;
	
	
	// cursor: pointer;
}

.docEditor-projectSubDocsTableContainer--hasDocs{
	margin-top: 0px;
}

.docEditor-projectSubDocsTableContainer--empty{
	margin-top: 0px;
	margin-top: -8px;
}

.docEditor-projectSubDocsTable-header{
	display: flex;
	align-items: center;
	margin-bottom: 0px;

	width: 100%;

	padding-bottom: 12px;

	// border-bottom: 1px solid var(--line300);
}

// .docEditor-projectSubDocsTable-header-title{
// 	display: flex;
// 	align-items: center;
// 	opacity: 0.6;
// }

// .docEditor-projectSubDocsTable-header-title-iconContainer{
// 	width: 24px;
// 	height: 100%;
// 	margin-right: 0px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;
// }

// .docEditor-projectSubDocsTable-header-title-iconContainer svg{
// 	margin-top: 2px;
// 	width: 22px;
// 	height: 22px;
// 	fill: var(--text600);
// 	opacity: 0.6;
// 	margin-left: -2px;
// }

// .docEditor-projectSubDocsTable-header-title-label{

// }



.docEditor-projectSubDocsTable-row{
	width: calc(100% + #{$leftOverhang-docProjectDocsRow} + #{$rightOverhang-docProjectDocsRow});
	display: flex;
	align-items: center;

	margin-left: calc(-1 * #{$leftOverhang-docProjectDocsRow});

	height: 32px;
	border-radius: 4px;

	cursor: pointer;
	position: relative;
}

.docEditor-projectSubDocsTable-row:hover{
	background: var(--253gray);
}

.docEditor-projectSubDocsTable-row-startSpacer{
	width: $leftOverhang-docProjectDocsRow;
	flex-grow: 0;
	flex-shrink: 0;
}

.docEditor-projectSubDocsTable-row-endSpacer{
	width: $rightOverhang-docTableRow;
	flex-grow: 0;
	flex-shrink: 0;
}
//

.docEditor-projectSubDocsTable-row-milestoneIcon,
.docEditor-projectSubDocsTable-row-meetingDocIcon,
.docEditor-projectSubDocsTable-row-subDocIcon{
	width: 24px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;


	margin-left: -4px;
	margin-right: 4px;
}


.docEditor-projectSubDocsTable-row-milestoneIcon{
	
	//margin-right: 10px;
	margin-top: -1.5px;
}



.docEditor-projectSubDocsTable-row-meetingDocIcon svg,
.docEditor-projectSubDocsTable-row-subDocIcon svg{
	width: 17px;
	height: 17px;

	position: relative;
}

.docEditor-projectSubDocsTable-row-subDocIcon svg{
	fill: var(--text500);
	opacity: 0.6;
}

.docEditor-projectSubDocsTable-row-meetingDocIcon svg{
	width: 18px;
	height: 18px;
}

// .docEditor-projectSubDocsTable:not(:hover)
// .docEditor-projectSubDocsTable-row-meetingDocIcon svg #bg{
// 	fill: var(--text500) !important;
// }


// // .docEditor-projectSubDocsTable
// // .docEditor-projectSubDocsTable-row-milestoneIcon .linearMilestoneIcon--incomplete svg{
// // 	transition: all 200ms linear;
// // 	// opacity: 0.75;
// // }

// .docEditor-projectSubDocsTable:not(:hover)
// .docEditor-projectSubDocsTable-row-milestoneIcon .linearMilestoneIcon--incomplete svg #bg{
// 	stroke: var(--line600) !important;
// }

// .docEditor-projectSubDocsTable:not(:hover)
// .docEditor-projectSubDocsTable-row-milestoneIcon .linearMilestoneIcon--incomplete svg #progress{
// 	stroke: var(--line600) !important;
// }

// .docEditor-projectSubDocsTable:not(:hover)
// .docEditor-projectSubDocsTable-row-milestoneIcon .linearMilestoneIcon--completed svg #bg{
// 	// stroke: var(--line600) !important;	
// 	// fill: var(--line600) !important;

// 	// dirty, opaqure version of above
// 	stroke: #6E6D70 !important;	
// 	fill: #6E6D70 !important;
	
// }

// [data-theme="light"]{
// 	.docEditor-projectSubDocsTable:not(:hover)
// 	.docEditor-projectSubDocsTable-row-milestoneIcon .linearMilestoneIcon--completed{
// 		opacity: 0.4;
// 	}
// }






//

.docEditor-projectSubDocsTable-row-docName,
.docEditor-projectSubDocsTable-header-title-label{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;	

	font-size: 16.5px;
	font-weight: $lato-bold;
	color: var(--text500);

	letter-spacing: 0.002em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//

.docEditor-projectSubDocsTable-row-docName span.docEditor-projectSubDocsTable-row-docName-dateTime{
	opacity: 0.65;
	font-weight: $lato-semibold;
	margin-left: 4px;
}
//

.docEditor-projectSubDocsTable-row-milestoneStats{
	font-size: 14px;
	font-weight: $lato-regular;
	color: var(--text400);

	margin-left: 7px;
	position: relative;
	top: 1px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	
	flex-grow: 0;
	flex-shrink: 0;

	opacity: 0.5;
	transition: opacity 50ms linear;
}


.docEditor-projectSubDocsTable:hover
.docEditor-projectSubDocsTable-row-milestoneStats{
	opacity: 0.5;
	transition: opacity 50ms linear;
}

.docEditor-projectSubDocsTable:hover
.docEditor-projectSubDocsTable-row:hover
.docEditor-projectSubDocsTable-row-milestoneStats{
	opacity: 1;
	transition: opacity 50ms linear;
}

.docEditor-projectSubDocsTable-row-milestoneStats-progress{

}

.docEditor-projectSubDocsTable-row-milestoneStats-dash{
	opacity: 0.7;
	margin-left: 3px;
	margin-right: 3px;
}

.docEditor-projectSubDocsTable-row-milestoneStats-total{
	opacity: 0.7;
	// min-width: 18px;
	text-align: right;
}

//

.docEditor-projectSubDocsTable-row-avatarGroup{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.docEditor-projectSubDocsTable-row-avatarGroup-avatarContainer{
	width: 22px;
	height: 22px;
	margin-right: 4px;
	border-radius: 4px;
	overflow: hidden;
}

.docEditor-projectSubDocsTable-row-avatarGroup-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//

.docEditor-projectSubDocsTable-row-timestampLabel{
	width: 140px;

	flex-grow: 0;
	flex-shrink: 0;

	font-size: 12px;
	font-weight: $lato-regular;
	letter-spacing: 0.02em;

	//color: rgb(125,124,124);
	color: var(--text200);

	box-sizing: border-box;
	padding-left: 10px;
	padding-top: 2px;

	text-align: right;
}

//
// NEW DOC BUTTON

.docEditor-projectSubDocsTable-newDocBtnContainer{
	display: flex;
	align-items: center;
	// justify-content: flex-end;
}

.docEditor-projectSubDocsTable-newDocBtn{	
	margin-left: -6px; // optical to icon
	padding-left: 4px;
	
	padding-right: 11px;
	// width: 50%;

	border-radius: 5px;

	height: 32px;

	justify-content: flex-start;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text500);

	margin-right: 6px;
}

// .docEditor-projectSubDocsTable-newDocBtn--noIcon{
// 	padding-left: 12px;
// 	margin-left: -12px;
// }

// .docEditor-projectSubDocsTableContainer--hasDocs
// .docEditor-projectSubDocsTable-newDocBtn{
// 	opacity: 0;
// 	transition: opacity 200ms;
// }

.docEditor-projectSubDocsTable
.docEditor-projectSubDocsTable-newDocBtn{
	opacity: 0.5;	
	transition-delay: 200ms;
	transition: opacity 100ms;
}

.docEditor-projectSubDocsTable
.docEditor-projectSubDocsTable-newDocBtn:hover{
	opacity: 0.8;	
	transition: opacity 50ms;
	background: var(--253gray);
}

.docEditor-projectSubDocsTable-newDocBtn-iconContainer{
	margin-right: 3px;

	width: 20px;
	height: 20px;

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

}

.docEditor-projectSubDocsTable-newDocBtn--meeting
.docEditor-projectSubDocsTable-newDocBtn-iconContainer{
	margin-right: 4px; // optically better
	margin-left: 2px;
}

.docEditor-projectSubDocsTable-newDocBtn-iconContainer svg{
	width: 15px;
	height: 15px;
	fill: var(--text500);
	opacity: 0.8;
}

.docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut{
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 2px;

	// background: $tempr;
	
	padding-right: 4px;
	padding-left: 4px;

	border-radius: 3px;

	// background: var(--253gray);
	
	margin-left: 8px;

	opacity: 0;
	transition: all 100ms;
	transform: translateX(-4px);
}

.docEditor-projectSubDocsTable-newDocBtn:hover 
~ .docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut{
	opacity: 0.8;
	transform: translateX(0px);
	transition: all 100ms;
	transition-delay: 50ms;
}

.docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut .keyboardShortcutTag {
	display: flex;
	align-items: center;
}

.keyboardShortcutTag-item{
	margin-right: 1px;
}

.docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut .keyboardShortcutTag svg{
	width: 13px;
	height: 13px;
	position: relative;
	top: 1px;
	fill: var(--text400);
}

.docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut .keyboardShortcutTag .keyboardShortcutTag-item--letter{
	font-weight: $lato-bold;
	font-size: 13px;
	margin-left: 1px;
	color: var(--text500);
}

