
.recordingPlaybackModal-transcript-highlightPanelContainer{
	position: absolute;
	bottom: 6px;
	left: 32px;

	width: calc(100% - 4px - 32px);

	// padding-left: 12px;
	// padding-left: 32px;

	// background: $tempr;

	z-index: 40;


	

	display: flex;
	align-items: flex-start;
	justify-content: center;

	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-top: 50px;



	// background: rgba(12,10,13, 0.8);
	
}

.recordingPlaybackModal-transcript-highlightPanelContainer--hide,
.recordingPlaybackModal-transcript-highlightPanelContainer--hide *{
	pointer-events: none !important;
}

.recordingPlaybackModal-transcript-highlightPanel{
	width: 100%;
	max-width: 500px;
	//height: 120px;
	// border: 1px solid var(--line300);
	// border-top: 1px solid var(--line400);
	border: 1px solid rgba(255,255,255,0.24);
	border-top: 1px solid rgba(255,255,255,0.34);
	border-bottom: 1px solid rgba(255,255,255,0.22);

	border-radius: 5px;

	background: lighten(rgb(12,10,13), 5%);

	position: relative;

	//box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.12);
	box-shadow: 0px 0px 18px 5px rgba(0,0,0,0.56), 0px 8px 16px 0px rgba(0,0,0,0.64);

}

.recordingPlaybackModal-transcript-highlightPanel-textAreaContainer{	
	position: relative;
}

textarea.recordingPlaybackModal-transcript-highlightPanel-textArea{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

textarea.recordingPlaybackModal-transcript-highlightPanel-textArea,
.recordingPlaybackModal-transcript-highlightPanel-textAreaGhost{
		
	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 12px;
	padding-bottom: 12px;

	// padding: 10px;
	font-size: 16.5px;
	line-height: 1.45;

	letter-spacing: 0.008em;

	resize: none;

	background: none;
	border: none;

	color: var(--text500);

}

.recordingPlaybackModal-transcript-highlightPanel-textAreaGhost{
	// color: red !important;
	//min-height: 93px; // 3 lines
	min-height: 74px;
	color: $invisible;
	pointer-events: none;
}

.recordingPlaybackModal-transcript-highlightPanel-textArea:focus{
	outline: none;
}

.recordingPlaybackModal-transcript-highlightPanel-textArea::placeholder{
	color: rgba(255,255,255,0.6);
}

.recordingPlaybackModal-transcript-highlightPanel-footer{
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-bottom: 8px;

	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 8px;
}

.recordingPlaybackModal-transcript-highlightPanel-footer-label{

	font-size: 12.5px;
	font-weight: $lato-regular;
	color: var(--text600);
	transition: opacity 75ms linear;

	position: relative;
	bottom: -5px;
}

.recordingPlaybackModal-transcript-highlightPanel-footer-label-shortcut{
	font-weight: $lato-semibold;
}

.recordingPlaybackModal-transcript-highlightPanel-footer-label[data-state='visible']{
	opacity: 0.4;
}

.recordingPlaybackModal-transcript-highlightPanel-footer-label[data-state='hidden']{
	opacity: 0;
}


.recordingPlaybackModal-transcript-highlightPanel-submitBtn{
	// position: absolute;
	// bottom: 8px;
	// right: 8px;
	// margin-right: 8px;
	
	width: 160px;
	height: 32px;
	background: var(--primary500);
	background: rgba(255,255,255,0.08);
	color: $white;

	background: $highlightYellow;
	
	border-radius: 3px;
}

.recordingPlaybackModal-transcript-highlightPanel-submitBtn-iconContainer{
	width: 20px;
	height: 20px;
	

	margin-left: -4px;
	margin-right: 2px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.recordingPlaybackModal-transcript-highlightPanel-submitBtn-iconContainer svg{
	width: 16px;
	height: 16px;
	fill: $highlightYellow;
	fill: black;

}


.recordingPlaybackModal-transcript-highlightPanel-submitBtn-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
	color: black;

}
