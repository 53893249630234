


//
//
//

.projectsPage-inProgressProject{
	display: flex;
	flex-direction: column;

	width: calc(50% - 60px);
	margin-right: 30px;
	height: 192px;

	box-sizing: border-box;

	padding-top: 18px;
	padding-bottom: 18px;



	cursor: pointer;
	user-select: none;

	padding-left: 24px;
	// padding-left: 44px;
	padding-right: 24px;
	
	border: 1px solid $invisible;

	border-radius: 7px;

	position: relative;

	// background: $tempr;

}

.projectsPage-inProgressProjectsOuterContainer
.projectsPage-sectionHeader{
	margin-bottom: 4px;
}

.projectsPage-inProgressProject:hover{
	// background: rgb(252,252,255);
	background: var(--254gray);
	//background: var(--253gray);
	//border: 1px solid var(--line100);
}


.projectsPage-inProgressProject:hover{

}




//

.projectsPage-inProgressProject-name{
	display: flex;
	align-items: center;

	margin-bottom: 6px;
	height: 24px;

	margin-top: 38px;
}

.projectsPage-inProgressProject-name-emojiContainer{
	font-size: 24px;
	width: 24px;
	height: 24px;
	margin-right: 8px;

	position: absolute;
	top: 18px;
	left: 24px;
}

.projectsPage-inProgressProject-name-label{
	display: flex;
	align-items: center;
}

.projectsPage-inProgressProject-name-label-label{
	font-size: 19px;
	font-weight: $lato-bold;
	color: var(--text500);
	letter-spacing: 0.01em;
}

.projectsPage-inProgressProject-name-label-tag{
	margin-left: 10px;
	margin-top: 3px;
	background: $tempr;
	color: red;

	padding-left: 6px;
	padding-right: 6px;
	padding-top: 3px;
	padding-bottom: 3px;

	font-size: 11px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.02em;

	border-radius: 4px;

	display: inline-block;


}

.projectsPage-inProgressProject-name-label-tag--justStarted{
	background: var(--primary25);
	color: var(--primary600);

	// color: $white;
	// background: var(--primary250);
}

.projectsPage-inProgressProject-name-label-tag--shippingSoon{
	background: var(--accentGreen25);
	color: var(--accentGreen600);	

	// color: $white;
	// background: var(--accentGreen300);
}



//

.projectsPage-inProgressProject-summary{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 14.75px;
	font-weight: $lato-regular;
	color: var(--text400);
	letter-spacing: 0.012em;
	opacity: 0.8;

	line-height: 1.35;

	box-sizing: border-box;
	padding-right: 12px;



}

//

.projectsPage-inProgressProject-footer{
	height: 32px;
	display: flex;
	align-items: center;
}

//

.projectsPage-inProgressProject-footer-healthStatus{
	width: 22px;
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	
	position: relative;

	opacity: 0.75;
}

.projectsPage-inProgressProject-footer-healthStatus svg{
	width: 14px;
	height: 14px;
}

.projectsPage-inProgressProject-footer-healthStatus-bg{
	position: absolute;
	border-radius: 4px;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.projectsPage-inProgressProject-footer-healthStatus--good
.projectsPage-inProgressProject-footer-healthStatus-bg{
	background: var(--accentGreen600);
	opacity: 0.1;
}

.projectsPage-inProgressProject-footer-healthStatus--good svg{
	fill: var(--accentGreen600);
}

.projectsPage-inProgressProject-footer-healthStatus--bad
.projectsPage-inProgressProject-footer-healthStatus-bg{
	background: var(--accentOrange600);
	opacity: 0.1;
}

.projectsPage-inProgressProject-footer-healthStatus--bad svg{
	fill: var(--accentOrange600);
}

//

.projectsPage-inProgressProject-footer-targetDateContainer{
	display: flex;
	margin-right: auto;
	align-items: center;
	//margin-right: 10px;
}

.projectsPage-inProgressProject-footer-targetDateContainer-iconContainer{
	width: 22px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 0px;
	margin-left: -2px;
}

.projectsPage-inProgressProject-footer-targetDateContainer-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text600);
	opacity: 0.55;
	position: relative;
	top: 0px;
}

.projectsPage-inProgressProject-footer-targetDateContainer-label{
	font-size: 14px;
	font-weight: $lato-medium;
	color: var(--text400);
	opacity: 0.9;
}

.projectsPage-inProgressProject-footer-teamTag{
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 2px;
	padding-bottom: 2px;

	font-size: 11.5px;
	font-weight: $lato-medium;
	color: var(--text300);
	letter-spacing: 0.025em;

	text-transform: uppercase;

	//background: var(--bgSideMenuTabLight);
	border: 1px solid var(--line200);
	border-radius: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 4px;
}


.projectsPage-inProgressProject-footer-teamTag{
	opacity: 0.65;
	transition: opacity 50ms linear;
}

.projectsPage-inProgressProject:hover
.projectsPage-inProgressProject-footer-teamTag{
	opacity: 1;
	transition: opacity 150ms linear;
}


.projectsPage-inProgressProject-avatarGroup{
	display: flex;
	// margin-right: 24px;
	margin-left: auto;

	margin-top: -69px;
}

.projectsPage-inProgressProject-avatarGroup-avatar{
	width: 22px;
	height: 22px;
	border-radius: 6px;

	overflow: hidden;
	//margin-right: 0px;
	margin-right: -1px;

	border: 2px solid var(--bg-docEditor);
	background: var(--bg-docEditor);

}

img.projectsPage-inProgressProject-avatarGroup-avatarImg{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(1){
	z-index: 10;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(2){
	z-index: 9;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(3){
	z-index: 8;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(4){
	z-index: 7;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(5){
	z-index: 6;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(6){
	z-index: 5;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member:nth-child(7){
	z-index: 4;
}

.projectsPage-inProgressProject-avatarGroup-avatar--lead{
	z-index: 20;
}

.projectsPage-inProgressProject-avatarGroup-avatar--member
.projectsPAge-inProgressProject-avatarGroup-avatarImg{
	opacity: 0.5;
}