// PROJECT LIST

.roadmapsHomePage-projectList{
	width: 100%;
	position: relative;


}

.roadmapsHomePage-projectList-header{
	border-bottom: 1px solid var(--line200);
	margin-bottom: 8px;
	padding-bottom: 16px;

	display: flex;

	width: 100%;
}

.roadmapsHomePage-projectList-header-label{
	font-size: 26px;
	font-weight: $lato-heavy;
	color: var(--text600);
}

.roadmapsHomePage-projectList-content{
	display: flex;
	padding-bottom: 64px;
}

.roadmapsHomePage-projectList-content-list{
	min-width: 10px;
	flex-grow: 10;
	flex-shrink: 10;
}

.roadmapsHomePage-projectList-content-list-completedToggle{
	margin-left: 18px;
	padding-left: 12px;
	padding-right: 12px;
	height: 32px;
	font-weight: $lato-medium;
	color: var(--text600);
	opacity: 0.6;
	font-size: 15.5px;

	// background: $tempr;
	border-radius: 4px;	
}

.roadmapsHomePage-projectList-content-list-completedToggle:hover{
	background: var(--250gray);
}

.roadmapsHomePage-projectList-content-controls{
	min-width: 240px;
	flex-grow: 2;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-left: 24px;

	position: relative;
}

.roadmapsHomePage-projectList-content-controls-newProjectBtnContainer{
	position: absolute;
	bottom: calc(100% + 24px);
	right: 100%;
	width: 200px;
	height: 40px;

	// background: $tempr;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	padding-right: 12px;
}


//
// SORT CONTROLS

.roadmapsHomePage-projectList-sortOptions{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
	padding-top: 10px;

	position: sticky;
	top: calc(#{$height-stickyHeader-sticky} + 12px);
	//width: 300px;
	// width: 100%;
	// margin-bottom: 14px;

	// // dirty
	// position: absolute;
	// left: 700px;
	// margin-left: 45px;
	// margin-top: 14px;
}

.roadmapsHomePage-projectList-sortOptions-group{

}

.roadmapsHomePage-projectList-sortOptions-group--group-label{
	opacity: 0;
}




//

.roadmapsHomePage-projectList-sortOptions-btn{
	height: 28px;
	display: flex;
	align-items: center;	
	justify-content: flex-start;

	border-radius: 3px;
	// background: $tempr;

	padding-left: 4px;

	width: 210px;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 28px;
	width: 24px;

	margin-right: 4px;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg{
	width: 18px;
	height: 18px;
	fill: var(--text500);
	opacity: 0.8;
}
.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortManual{
	transform: scale(0.95);
	transform-origin: center center;
}

.roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortAZ{
	width: 16px;
	height: 16px;
}

.roadmapsHomePage-projectList-sortOptions-btn-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: var(--text500);
}

//
// INFO 

.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer{
	width: 28px;
	height: 28px;

	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: auto;
}

.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
	width: 18px;
	height: 18px;

	fill: var(--text500);

	opacity: 0;
}

.roadmapsHomePage-projectList-sortOptions-btn--active
.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
	opacity: 0.5;
}

.roadmapsHomePage-projectList-sortOptions-btn--inactive:hover
.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
	opacity: 0.5;
}

.roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer:hover svg{
	opacity: 0.8
}

//

.roadmapsHomePage-projectList-sortOptions-btn--active{
	background: var(--253gray);
	opacity: 1;
}

.roadmapsHomePage-projectList-sortOptions-btn--inactive{
	//background: var(--250gray);
	opacity: 0.5;
}

.roadmapsHomePage-projectList-sortOptions-btn--inactive:hover{
	background: var(--253gray);
	opacity: 0.8;
}






// // HORIZONTAL

// .roadmapsHomePage-projectList-sortOptions{
// 	display: flex;
// 	width: 100%;
// 	margin-bottom: 14px;
// }

// .roadmapsHomePage-projectList-sortOptions-group{
// 	display: flex;
// }

// .roadmapsHomePage-projectList-sortOptions-group--group{
// 	margin-left: auto;
// }

// .roadmapsHomePage-projectList-sortOptions-group--group-label{
// 	font-size: 15px;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	margin-right: 8px;

// 	color: var(--text600);
// 	opacity: 0.75;
// }

// //

// .roadmapsHomePage-projectList-sortOptions-btn{
// 	height: 30px;
// 	display: flex;
// 	align-items: center;	
// 	justify-content: flex-start;

// 	border-radius: 3px;
// 	// background: $tempr;

// 	padding-left: 3px;
// 	padding-right: 8px;

// 	// width: 210px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	height: 28px;
// 	width: 24px;

// 	margin-right: 4px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg{
// 	width: 18px;
// 	height: 18px;
// 	fill: var(--text500);
// 	opacity: 0.8;
// }
// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortManual{
// 	transform: scale(0.95);
// 	transform-origin: center center;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-iconContainer svg.icon--name--sortAZ{
// 	width: 16px;
// 	height: 16px;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-label{
// 	font-size: 15px;
// 	font-weight: $lato-medium;
// 	color: var(--text500);
// }

// //
// // INFO 

// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer{
// 	width: 28px;
// 	height: 28px;

// 	// background: $tempr;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: auto;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
// 	width: 18px;
// 	height: 18px;

// 	fill: var(--text500);

// 	opacity: 0;
// }

// .roadmapsHomePage-projectList-sortOptions-btn--active
// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
// 	opacity: 0.5;
// }

// .roadmapsHomePage-projectList-sortOptions-btn--inactive:hover
// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer svg{
// 	opacity: 0.5;
// }

// .roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer:hover svg{
// 	opacity: 0.8
// }

// //

// .roadmapsHomePage-projectList-sortOptions-btn--active{
// 	background: var(--253gray);
// 	opacity: 1;
// }

// .roadmapsHomePage-projectList-sortOptions-btn--inactive{
// 	//background: var(--250gray);
// 	opacity: 0.5;
// }

// .roadmapsHomePage-projectList-sortOptions-btn--inactive:hover{
// 	background: var(--253gray);
// 	opacity: 0.8;
// }

