
.editMeetingParticipantsPopoverContainer,
.viewMeetingParticipantsPopoverContainer{	
	z-index: 500; // so in recording modal apepars on top	
}

.editMeetingParticipantsPopoverContainer:focus,
.viewMeetingParticipantsPopoverContainer:focus{
	outline: none;
}

.editMeetingParticipantsPopoverContainer--hidden,
.editMeetingParticipantsPopoverContainer--hidden *,
.viewMeetingParticipantsPopoverContainer--hidden,
.viewMeetingParticipantsPopoverContainer--hidden *{
	pointer-events: none !important;
}

.editMeetingParticipantsPopover,
.viewMeetingParticipantsPopover{
	width: unset;	
	pointer-events: auto;
}

.editMeetingParticipantsPopover-contents,
.viewMeetingParticipantsPopover-contents{
	width: 295px;

	min-height: 140px;
	max-height: 380px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding-top: 8px;
	padding-bottom: 0px;
	//padding-bottom: 14px;
	border-radius: 6px;

	padding-left: 2px;
	padding-right: 2px;
}

.viewMeetingParticipantsPopover-contents{
	width: auto;
	min-width: 180px;
	min-height: 40px;

}

.editMeetingParticipantsPopover-scrollList,
.viewMeetingParticipantsPopover-scrollList{
	width: 100%;
	overflow-y: scroll;
	flex-grow: 1;
	flex-shrink: 1;

	padding-bottom: 12px;

	display: flex;
	flex-direction: column;
}
//

.editMeetingParticipantsPopover-searchContainer{
	width: calc(100% - 10px);
	margin-left: 5px;
	height: 28px;
	position: relative;

	flex-grow: 0;
	flex-shrink: 0;

	margin-bottom: 6px;
}

input.editMeetingParticipantsPopover-search{
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	padding-right: 0;
	padding-top: 0px;
	padding-bottom: 0px;
	box-sizing: border-box;

	padding-left: 23px;

	position: absolute;
	border: none;
	border-radius: 4px;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text600);

	border: 1px solid $invisible;
	background: var(--250gray);
}

.editMeetingParticipantsPopover-search::placeholder{
	color: var(--toDoItem-placeholderColor);
}

.editMeetingParticipantsPopover-search:focus{
	background: var(--bg-docEditor);
	border: 1px solid var(--primary400);
	outline: 2px solid var(--primary25);
}

.forceDarkTheme, [data-theme="dark"]{
	.editMeetingParticipantsPopover-search:focus{
		outline: none;
	}
}

.editMeetingParticipantsPopover-searchBarUI-searchBarIcon{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	height: 100%;

	width: 25px;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

	box-sizing: border-box;
	padding-left: 2px;
}

.editMeetingParticipantsPopover-searchBarUI-searchBarIcon svg{
	width: 16px;
	height: 16px;
	fill: var(--toDoItem-placeholderColor);
	opacity: 0.8;
}




//

.editMeetingParticipantsPopover-participantList,
.editMeetingParticipantsPopover-addList{
	width: 100%;
}

.editMeetingParticipantsPopover-addList{
	margin-top: 12px;
}

.editMeetingParticipantsPopover-addList-header{
	font-size: 11.5px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	opacity: 0.5;

	color: var(--text500);

	padding-left: 10px;
	padding-bottom: 6px;
	padding-top: 10px;

	user-select: none;
	cursor: default;
}

.editMeetingParticipantsPopover-addList-border{
	width: calc(100% - 20px);
	margin-left: 10px;
	height: 1px;
	background: var(--line300);
}

.forceDarkTheme, [data-theme="dark"]{
	.editMeetingParticipantsPopover-addList-border{
		background: var(--line150);
	}
}


//

.viewMeetingParticipantsPopover-participantList
.popoverMenu-item--meetingParticipantRow{
	padding-right: 8px;
	pointer-events: none;
}


.editMeetingParticipantsPopover-addList
.popoverMenu-item--meetingParticipantRow{
	opacity: 0.7;
}

.editMeetingParticipantsPopover-addList
.popoverMenu-item--meetingParticipantRow:hover{
	opacity: 0.85;
	opacity: 0.95;
}

//
//

.popoverMenu-item--meetingParticipantRow-contactAvatar{
	border-radius: 3px;
}

//

// SELECTED STATE

.popoverMenu-item--meetingParticipantRow[data-state="selected"]{
	background: var(--activeBG-suggestionItem) !important; // dangerous;
}



//

.popoverMenu-item--meetingParticipantRow .popoverMenu-item-label,
.popoverMenu-item--meetingGroupRow .popoverMenu-item-label{
	margin-left: 2px;
	font-weight: $lato-medium;

	display: flex;
	align-items: center;


	white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
  
}

.popoverMenu-item--meetingParticipantRow-primaryLabel{
	flex-grow: 0;
	flex-shrink: 0;
}

.popoverMenu-item--meetingParticipantRow-secondaryLabel{
	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;

	margin-left: 4px;

	opacity: 0.5;
}

//

.popoverMenu-item--meetingParticipantRow-editBtnContainer{
	margin-left: auto;
	display: flex;
	margin-right: 8px;
	padding-left: 3px;

	height: 100%;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

.popoverMenu-item--meetingParticipantRow-editBtnContainer svg{
	width: 14px;
	height: 14px;
	fill: var(--text500);

	position: relative;
	top: 1px;
}


.popoverMenu-item--meetingParticipantRow
.popoverMenu-item--meetingParticipantRow-addBtn{
	opacity: 0.5;
}

.popoverMenu-item--meetingParticipantRow:hover
.popoverMenu-item--meetingParticipantRow-addBtn,
.popoverMenu-item--meetingParticipantRow[data-state="selected"]
.popoverMenu-item--meetingParticipantRow-addBtn{
	opacity: 0.75;
}




.popoverMenu-item--meetingParticipantRow
.popoverMenu-item--meetingParticipantRow-removeBtn{
	opacity: 0;
}

.popoverMenu-item--meetingParticipantRow:hover
.popoverMenu-item--meetingParticipantRow-removeBtn{
	opacity: 0.5;
}

.popoverMenu-item--meetingParticipantRow:hover
.popoverMenu-item--meetingParticipantRow-removeBtn,
.popoverMenu-item--meetingParticipantRow[data-state="selected"]
.popoverMenu-item--meetingParticipantRow-removeBtn{
	opacity: 0.75;
}


.editMeetingParticipantsPopover-participantList-emptyLabel{
	user-select: none;

	height: 30px;

	display: flex;
	align-items: center;


	font-size: 14.5px;
	font-weight: $lato-regular;
	letter-spacing: 0.012em;
	color: var(--text500);
	opacity: 0.6;

	box-sizing: border-box;
	padding-right: 2px;
	width: 100%;
	justify-content: center;
}

.editMeetingParticipantsPopover-participantList-emptyLabel--invited{
	margin-bottom: -2px;
}

//

.popoverMenu-item--meetingGroupRow[data-state="checked"]{	
	background: var(--activeBG-suggestionItem); 
}

.popoverMenu-item--meetingGroupRow[data-state="unchecked"]:hover{
	// background: $tempr;
}

.popoverMenu-item--meetingGroupRow
.popoverMenu-item-markerContainer svg{
	width: 18px;
	height: 18px;
	opacity: 0.4;
}

//

@import "ParticipantList-Popover-NewContact.scss";