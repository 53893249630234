.sideMenu-activeViewStatus--doc-popover{

	// height: 180px;

	// background: $tempr;
	opacity: 0;
	// opacity: 1;
} 


.sideMenu-activeViewStatus--doc-popover{
	box-sizing: border-box;
	padding: 8px;

	background: $white;
	border: 1px solid var(--line500);
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);

	padding: 14px;
	padding-top: 14px;
	padding-bottom: 8px;
}

.sideMenu-activeViewStatus--doc-popover--channel{
	padding-bottom: 12px;	
}

.sideMenu-activeViewStatus--doc-popover-header{
	display: flex;
}

.sideMenu-activeViewStatus--doc-popover-header-avatar{
	width: 34px;
	height: 34px;

	flex-grow: 0;
	flex-shrink: 0;

	padding-right: 10px;
}

.sideMenu-activeViewStatus--doc-popover-header-avatar-avatar{
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: 5px;
}

.sideMenu-activeViewStatus--doc-popover-header-title{
	flex-grow: 1;
	flex-shrink: 1;

	user-select: none;
	cursor: default;
}

.sideMenu-activeViewStatus--doc-popover--channel
.sideMenu-activeViewStatus--doc-popover-header-title{
	padding-left: 6px;
}

.sideMenu-activeViewStatus--doc-popover-header-title-primary{
	display: flex;

	margin-top: -1px;
	margin-bottom: 3px;
}

.sideMenu-activeViewStatus--doc-popover-header-title-primary-iconContainer{
	margin-right: 2px;
}

.sideMenu-activeViewStatus--doc-popover-header-title-primary-iconContainer svg{
	width: 13px;
	height: 13px;

	position: relative;
	top: 1px;

	color: var(--text600);
	opacity: 0.8;
}


.sideMenu-activeViewStatus--doc-popover-header-title-primary-label{
	font-size: 16px;
	font-weight: $lato-bold;
	color: var(--text600);
}



.sideMenu-activeViewStatus--doc-popover-header-title-subtitle{
	font-size: 13px;
	font-weight: $lato-medium;
	color: var(--text200);
}

//
// STATUS

.sideMenu-activeViewStatus--doc-popover-header-statusTagContainer{
	//background: $tempr;

	flex-grow: 0;
	flex-shrink: 0;
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag{
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 4px; 

	position: relative;
	top: -2px;
	right: -1px;

	font-size: 12.75px;
	letter-spacing: 0.008em;
	font-weight: $lato-semibold;

	border: 1px solid $invisible;

	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag--state--active{
	background: var(--accentPurple500);
	color: $white;
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag--state--online{
	background: $white;
	color: var(--text600);
	//border: 1px solid var(--line300);
	background: rgba(246,246,249);
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag--state--away{
	//background: rgba(251,251,251);
	color: var(--text300);
	opacity: 0.8;
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag-dot{
	width: 6px;
	height: 6px;
	border-radius: 50%;

	margin-right: 4px;
	margin-left: -2px;

	border: 1px solid $invisible;
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag--state--online
.sideMenu-activeViewStatus--doc-popover-header-statusTag-dot{
	background: var(--accentGreen500);
}

.sideMenu-activeViewStatus--doc-popover-header-statusTag--state--away
.sideMenu-activeViewStatus--doc-popover-header-statusTag-dot{

	background: $white;
	border: 1px solid var(--line600);
}

//
//

.sideMenu-activeViewStatus--doc-popover-btnList{
	width: calc(100% + 10px);
	margin-left: -5px;
	margin-top: 10px;
	margin-bottom: 11px;

	//background: $tempr;
}

.sideMenu-activeViewStatus--doc-popover--channel
.sideMenu-activeViewStatus--doc-popover-btnList{
	margin-top: 7px;
}


.sideMenu-activeViewStatus--doc-popover-slackMessage{
	margin-top: 2px;

	height: 30px;

	width: 100%;
	background: rgb(246,246,248);
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: var(--text300);
}

.sideMenu-activeViewStatus--doc-popover-slackMessage:hover{
	background: rgb(240,240,244);
}

.sideMenu-activeViewStatus--doc-popover-contributions{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12.5px;
	font-weight: $lato-regular;
	color: var(--text200);

	margin-top: 7px;
	margin-bottom: 2px;
}

.sideMenu-activeViewStatus--doc-popover-contributions:hover{
	text-decoration: underline;
	color: var(--text400);
}

//
//

.sideMenu-activeViewStatus--doc-popover-btn{
	width: 100%;

	justify-content: flex-start;

	background: var(--bg-dropdown);
	height: 28px;
	box-sizing: border-box;
	padding: 0px;
	//padding-left: 20px;
	//font-size: 15px;
	padding-left: 8px;
	padding-left: 6px;
	font-size: 14.5px;
	margin-bottom: 0px;

	font-weight: $lato-semibold;
	color: var(--text400);
	cursor: default;	

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	position: relative;

	cursor: pointer;

	border-radius: 5px;

	// background: $tempb;

}



.sideMenu-activeViewStatus--doc-popover-btn:hover{
	background: rgb(242,242,242);
	color: var(--text600);
}

.sideMenu-activeViewStatus--doc-popover-btn-iconContainer{
	width: 24px;
	height: 24px;
	
	border-radius: 4px;

	margin-right: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	//background: rgba(0,0,0,0.04);
}

.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg{
	width: 16px;
	height: 16px;
	color: var(--text400);
	opacity: 0.75;
}

.sideMenu-activeViewStatus--doc-popover-btn:hover
.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg{
	opacity: 1;
}

.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg.icon--name--headphones,
.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg.icon--name--hash,
.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg.icon--name--forwardArrow{
	height: 14px;
	width: 14px;
}

.sideMenu-activeViewStatus--doc-popover-btn-iconContainer svg.icon--name--forwardArrow{
	height: 13px;
	width: 13px;
}