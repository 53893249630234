.recordingPlaybackModal-media-playbackControls-speakerTimelinesContainer{
	width: 100%;
	height: 46px;

	// background: $tempg;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	// padding-top: 15px;
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline:first-child{
	margin-top: 0px;
}

.recordingPlaybackModal-media-playbackControls-speakerTimelineContainer{
	width: 100%;
	height: 10px;
	margin-top: 13px;
	position: relative;
	// background: $tempb;
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline{
	position: absolute;
	top: 2px;
	left: 0px;
	right: 0px;
	width: 100%;
	height: 8px;	
	background: rgba(255,255,255,0.12);
	
	// background: $tempr;
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn{
	position: absolute;
	top: 0px;
	bottom: 0px;
	height: calc(100% + 6px);

	padding-top: 3px;
	padding-bottom: 3px;
	margin-top: -3px;


	cursor: pointer;

	opacity: 0.8;

	//background: $tempr;

	transition: all 50ms linear;
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn:hover{
	opacity: 1;
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{
	width: 100%;
	height: 100%;
	border-radius: 1px;
}


.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--teal
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{	
	background: var(--accentTeal600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--blue
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{		
	background: var(--accentBlue600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--purple
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{		
	background: var(--accentPurple600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--red
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{			
	background: var(--accentRed600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--orange
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{			
	background: var(--accentOrange600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--green
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{				
	background: var(--accentGreen600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--sky
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{				
	background: var(--accentSky600);
}

.recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--pink
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn
.recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner{			
	background: var(--accentPink600);
}
