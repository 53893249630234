
.docEditor-messagePanel-header-followerList{
	margin-left: auto;
	display: flex;
	height: 100%;
	align-items: center;

}

.docEditor-messagePanel-header-followerList--popoverOpen{
	pointer-events: none;
}


.docEditor-messagePanel-header-follower{
	width: 29px;
	height: 100%;
	cursor: default;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	// outline: 1px solid red;

	position: relative;

	cursor: pointer; // doesn't do anything because of top cursor electron thing, but feels weird without it
	-webkit-app-region: no-drag;
	-webkit-user-select: none;
}

.docEditor-messagePanel-header-follower:hover{
	// background: $tempb;
}


.docEditor-messagePanel-header-follower-avatarContainer{
	width: 26px;
	height: 26px;	
	// width: 27px;
	// height: 27px;	
	// width: 25px;
	// height: 25px;	
}

.docEditor-messagePanel-header-follower-avatar{
	width: 100%;
	height: 100%;
	border-radius: 4px;
}


//
// MENU

@import 'Messages-Panel-Header-Follower-Menu';



.docEditor-messagePanel-header-followerList:hover
.docEditor-messagePanel-header-follower
.docEditor-messagePanel-header-follower-avatarContainer{
	opacity: 0.5;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}

.docEditor-messagePanel-header-followerList:hover
.docEditor-messagePanel-header-follower:hover
.docEditor-messagePanel-header-follower-avatarContainer{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}