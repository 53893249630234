.docEditor-projectUpdatesContainer{
	width: 100%;

	//margin-top: -8px;
	margin-top: 4px;
	margin-bottom: 12px;
	cursor: default;

	position: relative;
	z-index: 10;
}


.docEditor-projectUpdate{
	width: calc(100% + 36px);
	margin-left: -18px;

	background: var(--253gray);
	border-radius: 6px;

	box-sizing: border-box;
	
	padding-left: 20px;
	padding-right: 20px;
	
	padding-top: 12px;
	padding-bottom: 14px;	

	cursor: pointer;
	position: relative;
	transition: opacity 75ms linear;
}


//

.docEditor-projectUpdate-header{
	display: flex;
	align-items: center;

	margin-bottom: 10px;
	margin-top: 4px;
}

.docEditor-projectUpdate-header-avatarContainer{
	width: 26px;
	height: 26px;
	margin-right: 10px;
}

.docEditor-projectUpdate-header-avatar{
	width: 26px;
	height: 26px;
	border-radius: 4px;
}

.docEditor-projectUpdate-header-name{
	font-size: 16.75px;
	font-weight: $lato-bold;
	color: var(--text600);
	margin-right: 8px;
	position: relative;
	top: -0.024em;
}

.docEditor-projectUpdate-header-timestamp{
	font-size: 14px;
	font-weight: $lato-medium;
	color: var(--text200);
	position: relative;
	top: 0.1em;
}

.docEditor-projectUpdate-content{
	// font-size: 17.5px;
	// font-weight: $lato-regular;
	// color: var(--text500);
	// line-height: 1.35;
	// letter-spacing: 0.01em;
	//font-size: 16.5px;	
	font-size: 16.5px;		
	font-weight: $lato-regular;
	// font-weight: $lato-medium;
	letter-spacing: 0.005em;
	//color: var(--text600);
	color: var(--text500);
	line-height: 1.42;

	margin-block-start: 0em;
	margin-block-end: 0em;

	

}


.docEditor-projectUpdate-header-historyTabs{
	margin-left: auto;

	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	column-gap: 4px;
	position: relative;
	top: -4px;
}

.docEditor-projectUpdate-header-historyTabs-tab{
	width: 11px;
	height: 4px;
	border-radius: 1px;
	background: #33C99D;
}

.docEditor-projectUpdate-header-historyTabs-tab:not(:last-child){
	opacity: 0.25;
}



// .docEditor-projectUpdate-header-avatarContainer{
// 	width: 26px;
// 	height: 26px;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	padding-right: 8px;
// }

// .docEditor-projectUpdate-header-avatar{
// 	width: 26px;
// 	height: 26px;
// 	object-fit: cover;
// 	border-radius: 5px;
// }

// .docEditor-projectUpdate-header-label{
// 	font-size: 16px;
// 	font-weight: $lato-semibold;
// 	color: var(--text600);
// 	opacity: 0.5;
// }

// .docEditor-projectUpdate:hover{
// 	// background: var(--250gray);
// }

// .docEditor-projectUpdate-textContainer-title{
// 	color: var(--text500);

// 	font-size: 12.5px;
// 	font-weight: $lato-semibold;
// 	text-transform: uppercase;
// 	letter-spacing: 0.025em;
// 	opacity: 0.5;
// 	font-size: 12px;

// 	margin-bottom: 4px;

// }
