.docEditor-docTitle{
	margin-top: $topPadding-doc;
	width: $textWidth-doc;	

	position: relative;
	z-index: 5;

	flex-grow: 0;
	flex-shrink: 0;
}


$width-docEditorTitleRecordingIndicator: 58px;

.docEditor-docTitle--showRecordingIndicator{
	width: calc(#{$textWidth-doc} - #{$width-docEditorTitleRecordingIndicator});
	margin-left: $width-docEditorTitleRecordingIndicator;
}


textarea.docEditor-docTitle-input,
.docEditor-docTitle-ghost{
	font-size: 42px;
	font-weight: $lato-black;	
	letter-spacing: -0.005em;
}

textarea.docEditor-docTitle-input{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	
	color: var(--text600);

	height: 100%;

	overflow-x: hidden; // firefox bug
	overflow-y: hidden;

	cursor: text;

	vertical-align: top;

	padding: 0px;
	

	border: none;

	z-index: 20;

	resize: none;	

	pointer-events: auto;

	background: var(--bg-docEditor);
}

textarea.docEditor-docTitle-input--projectRootDoc{
	background: none; // new doc in project thing
	cursor: default !important;
	z-index: 20;
}

textarea.docEditor-docTitle-input:focus{
	outline: none;
	border: none;
}

textarea.docEditor-docTitle-input::placeholder{
	//color: #C1C1C1;
	color: var(--docTitlePlaceholderColor);
}

textarea.docEditor-docTitle-input:disabled{
	cursor: default;
}

.docEditor-docTitle-ghost{
	color: red;
	width: 100%;

	min-height: 48.3px;
	padding-bottom: 18px;


	overflow-wrap: break-word;

	visibility: hidden;

	pointer-events: none;
}

button.docEditor-docTitle-focusTrap{
	background: none;
	box-shadow: none;
	border: none;
	//background: $tempb;
	position: absolute;
	bottom: 0px;
	top: -2000px;
	right: -2000px;
	left: -2000px;

	cursor: text;
}


//
// Project/Milestone title

.docEditor-docTitle-projectDocTitle,
.docEditor-docTitle-milestoneDocTitle{
	position: relative;
	cursor: pointer;
}

.docEditor-docTitle-milestoneDocTitle{
	cursor: default;
}

.docEditor-docTitle-projectDocTitle-title,
.docEditor-docTitle-milestoneDocTitle-title,
.docEditor-GCalMeetingTitle-title{
	position: relative;
	z-index: 5;
	user-select: none;
}

.docEditor-docTitle-projectDocTitle-bg
.docEditor-docTitle-milestoneDocTitle-bg{
	position: absolute;
	top: -6px;
	left: -12px;
	right: -12px;
	bottom: -6px;
	border-radius: 4px;

	cursor: pointer;

	background: var(--253gray);
	opacity: 0;
	transition: opacity 75ms linear;

	// opacity: 1;
	// background: $tempr;
}

.docEditor-docTitle-projectDocTitle:hover
.docEditor-docTitle-projectDocTitle-bg,
.docEditor-docTitle-milestoneDocTitle:hover
.docEditor-docTitle-milestoneDocTitle-bg{
	opacity: 1;
	transition: opacity 75ms linear;
	transition-delay: 100ms;
}

// Milestone marker

.docEditor-milestoneDocMarker{
	width: 48px;
	height: 48px;

	position: absolute;
	right: 100%;
	top: 1.5px;
	margin-right: 10px;

	pointer-events: none;;

	// position: absolute;
	// left: 0px;
	// top: -56px;
	// margin-right: 10px;


	// margin-top: -32px;
	// margin-bottom: 12px;

	// background: $tempr;
}


@media screen and (max-width: 1420px){
	.docEditorContainer--sideMenuState--visible
	.docEditor-milestoneDocMarker{
		margin-right: 4px;
	}
}

@media screen and (max-width: 1380px){
	.docEditorContainer--sideMenuState--visible
	.docEditor-milestoneDocMarker{
		margin-right: 0px;
	}
}


@media screen and (max-width: 1310px){
	.docEditorContainer--sideMenuState--visible
	.docEditor-milestoneDocMarker{
		margin-right: -4px;
	}
}



//
//

.docEditor-GCalMeetingTitle-title-date{
	font-weight: $lato-black;
	font-weight: $lato-heavy;
	opacity: 0.3;
	margin-left: 5px;
}


//
// RECORDING INDICATOR
.docEditor-docTitle-recordingIndicator{
	position: absolute;
	top: 0px;
	height: 100%;
	left: calc(#{$width-docEditorTitleRecordingIndicator} * -1);
	width: $width-docEditorTitleRecordingIndicator;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	z-index: 10;
	pointer-events: none;

	// background: $tempr;
}

.docEditor-docTitle-recordingIndicator-playBtn{
	width: 56px;
	height: 40px;
	// background: $tempr;

	margin-top: 5px;
	cursor: pointer;

	position: relative;
	left: -6px;

	pointer-events: auto;
}

.docEditor-docTitle-recordingIndicator-playBtn svg{
	height: 48px;
	width: 48px;

	fill: var(--text500);
	opacity: 0.2;
}

.docEditor-docTitle-recordingIndicator-playBtn:hover svg{
	opacity: 0.24;	
}


//


.docEditor-docTitle-recordingIndicator-uploading,
.docEditor-docTitle-recordingIndicator-processing{
	width: 42px;
	height: 42px;

	margin-top: 4px;

	position: relative;

	pointer-events: auto;

	// background: $tempr;	
}


.docEditor-docTitle-recordingIndicator-uploading-cancelButton{
	position: absolute;
	top: -3px;
	right: -3px;

	width: 16px;
	height: 16px;
	border-radius: 50%;

	//background: var(--bg-dropdown);
	background: var(--text100);
	outline: 3px solid var(--bg-docEditor);

	z-index: 10;

	cursor: pointer;

	opacity: 0;

	transition: opacity 50ms linear;
}


.docEditor-docTitle-recordingIndicator-uploading:hover 
.docEditor-docTitle-recordingIndicator-uploading-cancelButton{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 100ms;
}

.docEditor-docTitle-recordingIndicator-uploading-cancelButton:hover{
	background: var(--text400);
}

.docEditor-docTitle-recordingIndicator-uploading-cancelButton svg{
	width: 12px;
	height: 12px;

	fill: white;
}

[data-theme="dark"]{
	.docEditor-docTitle-recordingIndicator-uploading-cancelButton{
		background: lighten(rgb(28,26,32), 8%);
	}
	.docEditor-docTitle-recordingIndicator-uploading-cancelButton:hover{
		background: lighten(rgb(28,26,32), 14%);
	}
	.docEditor-docTitle-recordingIndicator-uploading-cancelButton svg{
		opacity: 0.5;
	}
	.docEditor-docTitle-recordingIndicator-uploading-cancelButton:hover svg{
		opacity: 1;
	}
}



.docEditor-docTitle-recordingIndicator-uploading-progressBar .CircularProgressbar {
  width: 42px;  
  height: 42px;
}

.docEditor-docTitle-recordingIndicator-uploading-progressBar .CircularProgressbar .CircularProgressbar-path {  
  stroke: var(--primary500);
 
  transition: stroke-dashoffset 0.5s ease 0s;
}


.docEditor-docTitle-recordingIndicator-uploading-progressBar .CircularProgressbar .CircularProgressbar-trail {  
  stroke: var(--primary100);
  stroke-linecap: round;
}

[data-theme="dark"]{
	.docEditor-docTitle-recordingIndicator-uploading-progressBar .CircularProgressbar .CircularProgressbar-trail{
		stroke: rgba(255,255,255,0.10);
	}
}

.docEditor-docTitle-recordingIndicator-uploading-progressBar .CircularProgressbar .CircularProgressbar-background {
  
}

.docEditor-docTitle-recordingIndicator-uploading-progressBar-iconContainer{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}


.docEditor-docTitle-recordingIndicator-uploading-progressBar-iconContainer svg{
	width: 24px;
	height: 24px;

	fill: var(--text500);
	//opacity: 0.65;

	opacity: 0.35;
	transition: opacity 50ms linear;
}

.docEditor-docTitle-recordingIndicator-uploading-bg:hover ~
.docEditor-docTitle-recordingIndicator-uploading-contents
.docEditor-docTitle-recordingIndicator-uploading-progressBar-iconContainer svg{
	opacity: 0.65;
}


//


.docEditor-docTitle-recordingIndicator-processing{
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
}


@keyframes processheaderSpinnerAnimation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}


.docEditor-docTitle-recordingIndicator-processing-spinner{
	height: 34px;
	width: 34px;
	
  border-radius: 38px;

  border: 4px var(--primary100) solid;
  border-top: 4px var(--primary500) solid;

  opacity: 1;

  animation: processheaderSpinnerAnimation 1.8s infinite linear;
}



[data-theme="dark"]{
	.docEditor-docTitle-recordingIndicator-processing-spinner{		
		border: 4px rgba(255,255,255,0.10) solid;
		border-top: 4px var(--primary500) solid;
	}
}