.signInPageTest{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: white;

	z-index: 99999;

	display: flex;
	align-items: center;
	justify-content: center;
}

$widthBlock: 490px;
$heightBlock: 480px;


.signInPageTest-macDots{
	position: absolute;
	z-index: 2000;

	top: 18px;
	left: 15px;

	display: flex;

	pointer-events: none;
}

.signInPageTest-macDots-dot{
	width: 12px;
	height: 12px;
	border-radius: 50%;

	margin-right: 8px;

	background: rgba(0,0,10,0.08);
}


.signInPageTest-line{
	position: absolute;
	z-index: 20;
	background: rgba(0,0,0,0.06);
}

.signInPageTest-line--top,
.signInPageTest-line--bottom{
	left: 0px;
	right: 0px;
	height: 1px;
}

.signInPageTest-line--left,
.signInPageTest-line--right{
	top: 0px;
	bottom: 0px;
	width: 1px;
}

.signInPageTest-line--top{
	top: calc((100vh - #{$heightBlock})/2);
}

.signInPageTest-line--bottom{
	bottom: calc((100vh - #{$heightBlock})/2);
}

.signInPageTest-line--left{
	left: calc((100vw - #{$widthBlock})/2);
}

.signInPageTest-line--right{
	right: calc((100vw - #{$widthBlock})/2);
}




.signInPageTest-toggleLoadBtn{
	position: absolute;
	bottom: 0px;
	right: 0px;
	height: 250px;
	width: 250px;

	z-index: 1000;
}

.signInPageTest-blockContainer{
	width: $widthBlock;
	height: $heightBlock;

	display: flex;
	position: relative;
	z-index: 10;
}

//

.signInPageTest-block{
	width: calc(#{$widthBlock} - 2px); // take off border
	height: calc(#{$heightBlock} - 2px); // take off border
	background: rgb(255,255,255);

	border: 1px solid rgba(206,206,220,0.8);
	border-radius: 10px;

	box-shadow:
	  0px 3.4px 3.6px rgba(0, 0, 0, 0.021),
	  0px 9.4px 10px rgba(0, 0, 0, 0.03),
	  0px 22.6px 24.1px rgba(0, 0, 0, 0.039),
	  0px 75px 80px rgba(0, 0, 0, 0.06)
	;

	display: flex;
	flex-direction: column;
	align-items: center;
}

//

.signInPageTest-block-icon{
	margin-top: 64px;

	display: flex;
	flex-direction: column;

	width: 60px;
	gap: 6px;
}

.signInPageTest-block-icon-bar{
	width: 100%;
	height: 10px;
	border-radius: 3px;
	background: rgba(206,206,220,0.5);
}

.signInPageTest-block-icon-bar:last-child{
	width: 70%;
}

.signInPageTest-block-title{
	margin-top: 54px;

	width: 100%;
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	opacity: 0.9;

	cursor: default;
}

.signInPageTest-block-title-row1{
	margin-bottom: 8px;
}



.signInPageTest-block-button{
	margin-top: 106px;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 44px;
	width: 280px;

	user-select: none;

	cursor: pointer;

	background: linear-gradient(
		to right,
		#656BFF, #595FFC
	);

	border-radius: 4px;
	border: 1px solid darken(#656BFF, 5%);

	box-shadow: 0px 2px 5px 0px transparentize(#656BFF, 0.55);
}

.signInPageTest-block-button:hover{	

	background: linear-gradient(
		to right,
		darken(#656BFF, 2%), darken(#595FFC, 2%)
	);

	border: 1px solid darken(#656BFF, 6%);

	box-shadow: 0px 2px 5px 0px transparentize(#656BFF, 0.55);
}

.signInPageTest-block-button-label{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.008em;
	text-shadow: 0px 1px 1px darken(#656BFF, 5%);
	// text-shadow: 0px 2px 2px red;
	user-select: none;

	margin-top: -1px;
}

.signInPageTest-block-button-iconContainer{
	margin-left: -6px;
	margin-right: 9px;

	margin-top: 2.5px;

	opacity: 0.9;
}

.signInPageTest-block-button-iconContainer svg{
	fill: $white;
	width: 18px;
	height: 18px;
}

.signInPageTest-block-slackButton{
	margin-top: 18px;
	width: 200px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;

	border-radius: 5px;

	font-size: 14.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.017em;
	color: hsl(240, 9%, 28%);
	opacity: 0.5;

	cursor: pointer;
	transition: all 50ms linear;
}

.signInPageTest-block-slackButton:hover{
	background: rgb(248,248,253);
	// opacity: 0.8;
	transition: all 50ms linear;
	color: hsl(240, 41%, 7%);
}


.signInPageTest-versionNumber{
	position: absolute;
	bottom: 24px;
	left: 50%;
	font-size: 15px;
	z-index: 5;
	font-weight: $lato-semibold;
	color: rgb(37,37,42);
	letter-spacing: 0.05em;
	transform: translateX(-50%);
}
	

//

.signInPageTest-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;

	z-index: 1;

	background: rgb(250,250,255);

	background: radial-gradient(
    80vw 100vh at 50vw -50vh,
    rgb(255,255,255), darken(rgb(248,248,253), 1%)
  );
	// background: $tempr;
}

