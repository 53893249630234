.doc-threadGutter{
	position: absolute;
	right: 0px;	
	top: 0px;

	background: $tempr;

	z-index: 20;
}

.docEditorContainer--figmaModalIsZoomed
.doc-threadGutter{
	z-index: 0;
}


.doc-threadGutter-marker{
	position: absolute;
	top: 0px;
	right: 0px;

	width: 60px;



	display: flex;
	
	align-items: flex-start;
	justify-content: flex-start;

}

.doc-threadGutter-marker--indented{
	width: 6px;
}

.doc-threadGutter-marker--hovered{
	cursor: pointer
}




.doc-threadGutter-marker-indicator{
	display: flex;
	align-items: center;
	//justify-content: flex-end;
	justify-content: flex-start;

	
	height: 22px;
	// width: 42px;

	// margin-right: 16px;
	

	margin-left: 10px;

	box-sizing: border-box;
	//padding-right: 5px;
	padding-left: 4px;
	padding-right: 5px;

	border-radius: 3px;

	position: relative;

	// background: var(--250gray);
}

.doc-threadGutter-marker-indicator--isMultiLine{
	margin-top: 4px;
}

.doc-threadGutter-marker--indented
.doc-threadGutter-marker-indicator{
	display: none;	
}

// .doc-threadGutter-marker--notHovered
// .doc-threadGutter-marker-indicator{
// 	transform: translateX(0px);
// 	transition: all 50ms linear;
// }

// .doc-threadGutter-marker--hovered.doc-threadGutter-marker--inactive
// .doc-threadGutter-marker-indicator{
// 	transform: translateX(2px);	
// }

// // .doc-threadGutter-marker{
// 	transform: translateX(6px);
// 	transition: all 50ms linear;
// }

// .doc-threadGutter-marker:hover{
// 	transform: translateX(0px)
// }

.doc-threadGutter-marker-indicator-label{
	font-size: 14px;
	font-weight: $lato-regular;
	margin-left: 3px;
}

.doc-threadGutter-marker--active
.doc-threadGutter-marker-indicator-label{
	font-weight: $lato-medium;
}


.doc-threadGutter-marker-indicator-iconContainer svg{
	width: 14px;
	height: 14px;
	position: relative;
	top: 0.07em;
}

//

.doc-threadGutter-marker-edge{
	position: absolute;
	
	left: 2px;
	top: 0px;
	bottom: 0px;
	width: 2px;



	border-radius: 3px;
}

.doc-threadGutter-marker--singleLine.doc-threadGutter-marker--notIndented
.doc-threadGutter-marker-edge{
	visibility: hidden;
}


//
// Comment - AI


//
// COLORS

//
// NO UNREADS 

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--active
.doc-threadGutter-marker-indicator{
	background: var(--250gray);
}

// Label

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive
.doc-threadGutter-marker-indicator-label{
	color: var(--text500);
	opacity: 0.8;
}

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive:hover
.doc-threadGutter-marker-indicator-label{	
	opacity: 1;
}

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--active
.doc-threadGutter-marker-indicator-label{
	color: var(--text600);
	opacity: 1;
}

// Default SVG

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive
.doc-threadGutter-marker-indicator-iconContainer svg{
	fill: var(--text600);
	opacity: 0.5;
}

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive:hover
.doc-threadGutter-marker-indicator-iconContainer svg{
	fill: var(--text600);
	opacity: 0.8;
}

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--active
.doc-threadGutter-marker-indicator-iconContainer svg{
	fill: var(--text600);
	opacity: 1;
}


[data-theme="dark"]{
	.doc-threadGutter-marker--noUnreads{
		opacity: 0.8;
	}
}

//
// Unreads

.doc-threadGutter-marker--hasUnreads
.doc-threadGutter-marker-indicator{
	background: var(--accentRed50);
}

.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator,
.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator{
	background: var(--accentRed75);
}

[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads
	.doc-threadGutter-marker-indicator{
		background: rgba(255,89,89,0.08);
		//background: rgba(255,255,255,0.5);
	}
}

[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator,
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator{
		background: rgba(255,89,89,0.12);
		//background: rgba(255,255,255,0.5);
	}
}

//

.doc-threadGutter-marker--hasUnreads
.doc-threadGutter-marker-indicator-label{
	color: var(--accentRed700);
}

.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator-label,
.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator-label{
	color: hsl(359, 83%, 58%);
}

//

.doc-threadGutter-marker--hasUnreads
.doc-threadGutter-marker-indicator-iconContainer svg{
	fill: var(--accentRed600);
	opacity: 0.8;
}

.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator-iconContainer svg,
.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator-iconContainer svg{
	opacity: 1;
}


[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads
	.doc-threadGutter-marker-indicator-label{
		color: rgb(255,67,70);
		//color: rgb(255,255,255);
	}
}

[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator-label,
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator-label{
		color: rgb(255,70,74);
	}
}



[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads
	.doc-threadGutter-marker-indicator-iconContainer svg{
		fill: rgb(255,67,70);	
		//fill: rgb(255,255,255);

	}
}


[data-theme="dark"]{	
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--inactive:hover .doc-threadGutter-marker-indicator-iconContainer svg,
	.doc-threadGutter-marker--hasUnreads.doc-threadGutter-marker--active .doc-threadGutter-marker-indicator-iconContainer svg{
		fill: rgb(255,70,74);	
		//fill: rgb(255,255,255);
	}
}




//
// Edge

.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive
.doc-threadGutter-marker-edge{
	background: var(--line400);
}

.doc-threadGutter-marker--indented.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--inactive:hover
.doc-threadGutter-marker-edge{
	background: var(--text200);
}

.doc-threadGutter-marker--indented.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--active
.doc-threadGutter-marker-edge{
	background: var(--text300);
}

.doc-threadGutter-marker--notIndented.doc-threadGutter-marker--noUnreads.doc-threadGutter-marker--active
.doc-threadGutter-marker-edge{
	background: var(--text200);
}

.doc-threadGutter-marker--hasUnreads
.doc-threadGutter-marker-edge{
	background: var(--accentRed200);
}


[data-theme="dark"]{
	.doc-threadGutter-marker--hasUnreads
	.doc-threadGutter-marker-edge{
		background: rgba(255,89,89,0.32);
	}
}






//
// POPOVER


//

.doc-threadGutter-marker-popoverContainer{
	position: absolute;
	top: -50px;
	left: 150px;

	width: 300px;
	height: 200px;
	background: $tempr;


}

.commentThreadMarkerPopoverContainer:focus{
	outline: none;
}

.commentThreadMarkerPopoverContainer,
.commentThreadMarkerPopoverContainer *{
	pointer-events: none !important;
}


.commentThreadMarkerPopover{
	// width set in core layout
	max-height: 70vw;

	overflow: hidden;
	
	//min-height: 370px;

	box-sizing: border-box;

	//padding-top: 12px;
	padding-top: 8px;
	//padding-bottom: 16px;
	padding-bottom: 8px;

	padding-left: 4px;
	padding-right: 4px;

	cursor: default;
	user-select: none;

	border-radius: 4px;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

}

.commentThreadMarkerPopover-moreMessagesHeader{

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 8px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-top: 4px;
	padding-bottom: 4px;
}

.commentThreadMarkerPopover-moreMessagesHeader-line{
	min-width: 10px;
	height: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	height: 1px;
	background: var(--line300);
}

[data-theme="dark"]
.commentThreadMarkerPopover-moreMessagesHeader-line{
	background: var(--line200);
	opacity: 0.8;
}


.commentThreadMarkerPopover-moreMessagesHeader-label{
	font-size: 12.5px;
	font-size: $lato-semibold;
	color: var(--text300);
	opacity: 0.75;

	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 1px;
}


//

.commentThreadMarkerPopover
.messageContainer{
	background: var(--bg-dropdown);
}







//
// OLD AI STUFF


// .doc-threadGutter-marker--bot
// .doc-threadGutter-marker-edge{
// 	background: var(--accentPurple100);
// }

// [data-theme="dark"]{
// 	.doc-threadGutter-marker--bot
// 	.doc-threadGutter-marker-edge{
// 		background: rgba(59,34,118,1);
// 	}
// }


// //

// .doc-threadGutter-marker--bot
// .doc-threadGutter-marker-indicator{
// 	width: 28px;	
// }

// .doc-threadGutter-marker--bot
// .doc-threadGutter-marker-indicator svg{
// 	width: 16px;
// 	height: 16px;
// }

// .doc-threadGutter-marker--bot
// .doc-threadGutter-marker-indicator{
// 	background: var(--accentPurple50);

// }


// [data-theme="dark"]{
// 	.doc-threadGutter-marker--bot
// 	.doc-threadGutter-marker-indicator{
// 		background: rgba(59,34,118,0.59);
// 		//background: rgba(255,255,255,0.5);
// 	}
// }

// .doc-threadGutter-marker--bot
// .doc-threadGutter-marker-indicator-iconContainer svg{
// 	fill: var(--accentPurple600);
// 	opacity: 0.8;
// }


// [data-theme="dark"]{
// 	.doc-threadGutter-marker--bot
// 	.doc-threadGutter-marker-indicator-iconContainer svg{
// 		fill: rgb(255,67,70);	
// 		fill: #c877ff;
// 		opacity: 1;
// 		//fill: rgb(255,255,255);
		
// 	}
// }
