.activeRecordingUploadPanelContainer{
	position: absolute;
	// left: 8px;
	// bottom: 14px;

	left: 10px;
	bottom: 12px;

	z-index: 20;
}

.activeRecordingUploadPanelContainer:focus{
	outline: none;
}

.activeRecordingUploadPanelContainer--hidden,
.activeRecordingUploadPanelContainer--hidden *{
	pointer-events: none !important;
}

.activeRecordingUploadPanel{
	position: absolute;
	bottom: 0px;
	left: 0px;

	height: 60px;
	background: $tempr;	
}

.activeRecordingUploadPanel-bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 60px;
	height: 100%;

	background: var(--bg-dropdown);
	border: var(--outsideLine-dropdown);
	border-top: var(--outsideTopLine-dropdown);
	border-bottom: var(--outsideBottomLine-dropdown);

	box-shadow: var(--boxShadow-dialog);

	border-radius: 8px;
}



.activeRecordingUploadPanel-contents{	
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 235px;

	z-index: 10;
	pointer-events: none;

}

.activeRecordingUploadPanel-progressBarContainer{
	position: absolute;
	top: 0px;
	height: 100%;
	left: 0px;
	width: 60px;

	// for borders
	margin-left: 1px;
	margin-top: 1px;

	flex-grow: 0;
	flex-shrink: 0;

	// background: $tempr;
}

.activeRecordingUploadPanel-rightContainerContainer{
	position: absolute;
	top: 0px;
	height: 100%;
	left: 62px;
	width: calc(235px - 60px);

}


//
// PROGRESS BAR
.activeRecordingUploadPanel-progressBar{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.activeRecordingUploadPanel-progressBar .CircularProgressbar {
  width: 42px;  
  height: 42px;
}

.activeRecordingUploadPanel-progressBar .CircularProgressbar .CircularProgressbar-path {  
  stroke: var(--primary500);
 
  transition: stroke-dashoffset 0.5s ease 0s;
}


.activeRecordingUploadPanel-progressBar .CircularProgressbar .CircularProgressbar-trail {  
  stroke: var(--primary100);
  stroke-linecap: round;
}

[data-theme="dark"]{
	.activeRecordingUploadPanel-progressBar .CircularProgressbar .CircularProgressbar-trail{
		stroke: rgba(255,255,255,0.10);
	}
}

.activeRecordingUploadPanel-progressBar .CircularProgressbar .CircularProgressbar-background {
  
}

.activeRecordingUploadPanel-progressBar-iconContainer{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}


.activeRecordingUploadPanel-progressBar-iconContainer svg{
	width: 24px;
	height: 24px;

	fill: var(--text500);
	//opacity: 0.65;

	opacity: 0.35;
	transition: opacity 50ms linear;
}

.activeRecordingUploadPanel-bg:hover ~
.activeRecordingUploadPanel-contents
.activeRecordingUploadPanel-progressBar-iconContainer svg{
	opacity: 0.65;
}

//
// CONTENTS


.activeRecordingUploadPanel-rightContainerContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;

	height: 100%;
	box-sizing: border-box;
	padding-left: 0px;
}

.activeRecordingUploadPanel-title{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: var(--text500);

	margin-bottom: 3px;
	letter-spacing: 0.008em;
}

.activeRecordingUploadPanel-subtitle{
	display: flex;
	font-size: 15px;
	letter-spacing: 0.008em;
}

.activeRecordingUploadPanel-subtitle-percentage{
	font-weight: $lato-semibold;
	color: var(--text500);
}

.activeRecordingUploadPanel-subtitle-filesLeft{
	font-weight: $lato-medium;
	color: var(--text500);
	opacity: 0.6;

	margin-left: 4px;
}