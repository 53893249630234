.meetingTypeIconContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}

svg.icon--name--meetingStandup--layered #core,
svg.icon--name--meetingTeamSync--layered #core,
svg.icon--name--meetingAdhoc--layered #core,
svg.icon--name--meetingDiscovery--layered #core,
svg.icon--name--meetingSprintPlanning--layered #core,
svg.icon--name--meetingDesignReview--layered #core,
svg.icon--name--meetingNoType--layered #core{
	fill: white;
}

[data-theme="dark"], .forceDarkTheme{
	svg.icon--name--meetingStandup--layered #core,
	svg.icon--name--meetingTeamSync--layered #core,
	svg.icon--name--meetingAdhoc--layered #core,
	svg.icon--name--meetingDiscovery--layered #core,
	svg.icon--name--meetingSprintPlanning--layered #core,
	svg.icon--name--meetingDesignReview--layered #core,
	svg.icon--name--meetingNoType--layered #core{
		fill: black; // erg, this is a bit messed
	}
}

.meetingTypeIconContainer svg.icon--name--calendar{
	transform: scale(0.97);
	fill: var(--text300);
}




svg.icon--name--meetingStandup--layered #bg{
	fill: var(--accentOrange600);
}

$teamSyncColor: #1978ed;

svg.icon--name--meetingTeamSync--layered #bg{
	//fill: var(--primary600);
	fill: var(--primary500);
	fill: #1978ed;
}

svg.icon--name--meetingAdhoc--layered #bg{
	fill: var(--accentGreen600);
	fill: var(--line600);
}

svg.icon--name--meetingNoType--layered #bg{	
	fill: var(--text300);
}

$discoveryColor: #8778DD;

svg.icon--name--meetingDiscovery--layered #bg{
	fill: var(--accentPurple600);
	fill: var(--purple--yarn);
	fill: #8778DD;
}

svg.icon--name--meetingSprintPlanning--layered #bg{
	fill: var(--accentSky600);
}

$designReviewColor: #35a384;

svg.icon--name--meetingDesignReview--layered #bg{
	fill: var(--accentGreen600);
	fill: #35a384;
}